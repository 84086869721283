import React, { Component } from "react";
import Slider from "react-slick";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import our_trainers from "../../../../graphql/queries/ourTrainers";
import { Query } from "@apollo/client/react/components";
import { Loader, Noconnection } from "components";

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className="absolute right-0 top-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
			onClick={onClick}
		>
			<BsArrowRight
				size={30}
				strokeWidth={1.2}
				className={onClick !== null ? "primary" : "gray"}
			/>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className="absolute z-10 top-1/2 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
			onClick={onClick}
		>
			<BsArrowLeft
				size={30}
				strokeWidth={1.2}
				className={onClick !== null ? "primary" : "gray"}
			/>
		</div>
	);
}
class trainers extends Component {
	render() {
		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			initialSlide: 0,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						centerMode: false,
					},
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: false,
					},
				},
				{
					breakpoint: 680,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: false,
					},
				},
			],
		};
		return (
			<div className="relative blog-posts equal-height">
				<div className="container">
					<h4 className="lg:text-5xl text-4xl font-light Poppins text-center">
						Our Trainers
					</h4>
				</div>
				<Query query={our_trainers}>
					{({ loading, error, data }) => {
						if (loading) {
							return <Loader />;
						}
						if (error) {
							return (
								<div>
									<Noconnection />
								</div>
							);
						}
						return (
							<Slider
								{...settings}
								className="important:static lg:px-10 pl-[40px] pr-[14px]"
							>
								{(data?.alltrainers || [])?.filter((t) => !!t?.image_path).map((team, index) => {
									return (
										<div className="h-full p-2 pt-3">
											<Link
												to={"/trainners"}
												key={index}
												className="flex flex-col h-full pt-3"
											>
												<div className="h-full">
													<img
														alt={team.name}
														src={team.image_path}
														className="object-contain h-40 grayscale"
													/>
													<h4 className="mt-3 text-3xl font-light Poppins primary">
														{team.name}
													</h4>
													<p className="black Poppins text-3xl font-light">
														{team.title}
													</p>
													<p className="mt-3 line-clamp-3 font-semibold text-lg text-new-color text-left Poppins">
														Company: {team.company}
													</p>
													<p>
														<BsArrowRight size={30} className="primary" />
													</p>
												</div>
											</Link>
										</div>
									);
								})}
							</Slider>
						);
					}}
				</Query>
			</div>
		);
	}
}

export default trainers;
