import { Listbox, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";

export const RfqFilters = ({data, filterData, setFilterData}) => {

    const filters = [
        {name: 'Categories', key: 'category', tname: 'categories', tval: 'name'},
        {name: 'Tags', key: 'tag', tname: 'all_tags', tval: 'tag_title'},
    ]

	return (
		<div className="p-5 mb-4 space-y-5 border rounded-xl">
			{filters?.map((filter) => {
				return (
					<div className="flex flex-col gap-2">
						<p className="text-new-color font-semibold text-[18px] Light">
							{filter?.name}
						</p>
						<Listbox
							value={filterData?.[filter?.key]?.[filter?.tval]}
							onChange={(e) =>
								setFilterData({ ...filterData, [filter?.key]: e })
							}
						>
							<div className="relative mt-1">
								<Listbox.Button className="relative justify-between border-2 border-[#D1CFCB] w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-white focus:z-10 sm:text-sm text-left flex items-center gap-2">
									<span className={"text-new-color"}>
										{filterData?.[filter?.key]?.[filter?.tval] || "select"}
									</span>
									{!filterData?.[filter?.key]?.[filter?.tval] ? (
										<BsArrowDown size={18} className="primary" />
									) : (
										<div
											onClick={(e) => {
												e.stopPropagation();
												let shalowCopy = { ...filterData };
												delete shalowCopy?.[filter?.key];
												setFilterData(shalowCopy);
											}}
											className="border-2 cursor-pointer rounded-full p-0.5 border-[#D1CFCB]"
										>
											<IoCloseOutline size={18} className="primary" />
										</div>
									)}
								</Listbox.Button>
								<Transition
									as={React.Fragment}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Listbox.Options className="!list-none !pl-3 absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
										{([...data?.[filter?.tname] || []])?.sort((a, b) => a?.[filter?.tval]?.localeCompare(b?.[filter?.tval])).map((dataa) => (
											<Listbox.Option
												key={dataa?.[filter?.tval]}
												value={dataa}
												className={
													"relative cursor-pointer select-none py-2 pr-4 text-gray-900"
												}
											>
												{dataa?.[filter?.tval]}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
				);
			})}
		</div>
	);
};
