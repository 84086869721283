import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { BsPlusLg } from "react-icons/bs";

const PricingFaq = () => {
    const OfferData = [
        {
            id: "panel4",
            title: "What payment methods do you accept?",
            content:[`We accept major credit cards, Visa, Mastercard, PayPal, and bank transfers.`]
        },
        {
            id: "panel5",
            title: "Are there any hidden fees or extra costs?",
            content:[`No hidden fees or extra costs. What you see is what you pay.`]
        },
        {
            id: "panel6",
            title: "Do you offer any discounts for multiple courses?",
            content:[`Yes, we offer discounts for multiple course enrollments. Contact us for details.`]
        },
        {
            id: "panel7",
            title: "Is there a refund policy if I'm not satisfied with the course?",
            content:[`While we don't offer refunds, we're committed to ensuring your satisfaction with the course.`]
        },
        {
            id: "panel8",
            title: "Are there any time limitations to accessing the course content?",
            content:[`Access to the course content is Monthly and Yearly as per the model you will choose.`]
        },
        {
            id: "panel9",
            title: "Do you provide certificates upon course completion?",
            content:[`Yes, certificates are provided upon course completion.`]
        },
        {
            id: "panel10",
            title: "Can I switch courses after purchase?",
            content:[`Yes, you can switch courses within a certain timeframe after purchase. Contact support for assistance.`]
        },
        {
            id: "panel11",
            title: "How do I get technical support if I encounter issues during the course?",
            content:[`Yes, you can switch courses within a certain timeframe after purchase. Contact support for assistance.`]
        },
        {
            id: "panel12",
            title: "Can I access the course on mobile devices?",
            content:[`Yes, the course is accessible on all devices including mobile phones and tablets.`]
        },
        {
            id: "panel13",
            title: "Do you offer corporate or group pricing for businesses and organizations?",
            content:[`Yes, we offer corporate and group pricing for businesses and organizations. Contact us for customized solutions.`]
        },
    ];
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
            <div className="grid grid-cols-12 gap-5">
                <div className="divide-y col-span-12">
                    {OfferData.map((item) => (
                        <div key={item.id}>
                            <Accordion
                                expanded={expanded === item.id}
                                onChange={handleChange(item.id)}
                                className="p-2 m-0 overflow-hidden !shadow-none"
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <BsPlusLg
                                            className="primary"
                                            size={24}
                                        />
                                    }
                                >
                                    <p className="text-lg Poppins leading-6 font-bold text-new-color">
                                        {item.title}
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul className="list-disc space-y-2">
                                        {item.content.map((content, index) => (
                                            <li key={index} className="Poppins text-new-color text-lg font-normal">
                                                {content}
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PricingFaq;
