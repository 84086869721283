const easeInOut = (start, distance, progress, duration) => {
    progress /= duration / 2;
    if (progress < 1) return (distance / 2) * progress * progress + start;
    progress--;
    return (-distance / 2) * (progress * (progress - 2) - 1) + start;
};

export const scrollToContent = (id, duration) => {
    const section = document.getElementById(id);
    const sectionPosition = section.getBoundingClientRect().top - 75; // Adjusted for the header height
    const start = window.scrollY;
    let startTime = null;

    const scroll = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const elapsedTime = currentTime - startTime;
        window.scrollTo(0, easeInOut(start, sectionPosition, elapsedTime, duration));

        if (elapsedTime < duration) {
            requestAnimationFrame(scroll);
        }
    };

    requestAnimationFrame(scroll);
};