import { gql } from "@apollo/client";

export default gql`
  mutation ENROLL_COURSE($course_id: Int) {
    course_enrollment(course_id: $course_id) {
      message
      error
    }
  }
`;
