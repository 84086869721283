import { AUTH_USER_TOKEN, GET_AUTH_USER_INFORMATION, UPDATE_AUTH_USER_PROPERTY, GET_AUTH_USER_SOCKET } from "../actions/types";
import { produce } from "immer";
const state = {
  authUserInformation: {},
  authUserSocket: {},
  authUserToken: ""
};

function AuthUserReducer(mState = { ...state }, action) {
  switch (action.type) {
    case GET_AUTH_USER_INFORMATION:
      return produce(mState, draftState => {
        draftState.authUserInformation = action.payload;
      });
    case GET_AUTH_USER_SOCKET:
      return produce(mState, draftState => {
        draftState.authUserSocket = action.payload;
      });
    case AUTH_USER_TOKEN:
      return produce(mState, draftState => {
        draftState.authUserToken = action.payload;
      });
    case UPDATE_AUTH_USER_PROPERTY:
      return produce(mState, (draftState) => {
        // Update the specified property based on the payload
        const { key, value } = action.payload;
        draftState.authUserInformation[key] = value;
      });
    default:
      return { ...mState };
  }
}

export default AuthUserReducer;
