import { Buttonlink, Getintouch } from "components";
import { seo } from "helpers/seo";
import React, { Component } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import LeagueImage from "assets/images/League.webp";

class DevelopmentLeague extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Development League | Procurement League",
    });
  }

  render() {
    return (
      <div className="container">
        <div className="relative flex flex-col justify-between lg:items-center lg:justify-center lg:flex-row lg:-mt-4">
          <Buttonlink
            Text="back"
            Icon={<IoIosArrowBack />}
            onClick={() => this.props.history.goBack()}
            className="left-0 z-10 flex items-center justify-center px-4 border-0 shadow-none lg:absolute bg--lightGray hover:bg--lightGray focus:bg--lightGray Regular h-9 rounded--full darkGray w-28 hover:darkGray hover:opacity-80"
          />
          <h1 className="my-3 text-4xl text-center Medium lg:my-0">
            Development League
          </h1>
          <Buttonlink
            Text="Media Solutions"
            Lasticon={<IoIosArrowForward />}
            to="/media-solutions"
            className="right-0 flex items-center justify-center border-0 shadow-none lg:absolute fs-15 primary hover:primary Regular rounded--xl "
          />
        </div>
        <div className="my-10">
          <img
            src={LeagueImage}
            alt="Development League"
            className="object-cover m-auto h-72"
          />
        </div>
        <div className="lg:grid lg:grid-cols-6 lg:gap-4">
          <div className="lg:col-start-2 lg:col-span-4">
            <p className="text-center Regular">
              Development League is our one-stop solution for the holistic
              development of procurement professionals. We provide individual
              mentee-mentor support, and we offer enterprise solutions for team
              mentorships. The highly extensive mentorship sessions will help
              you become better leaders.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center mt-5">
          <Getintouch
            header={"want a development league mentorship sessions"}
          />
        </div>
      </div>
    );
  }
}

export default DevelopmentLeague;
