import { gql } from "@apollo/client";

export default gql`
  query businessCategories($search: String) {
    businessCategories(
      first: 1000
      search: $search
      orderBy: [{ column: "name", order: ASC }]
    ) {
      data {
        id
        name
        parent_id
      }
      paginatorInfo {
        total
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
`;
