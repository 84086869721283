import React, { Component } from "react";
import Slider from "react-slick";
import pocket from "assets/images/pocket.png";

class verticalslick extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      vertical: true,
      verticalSwiping: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: false,
      prevArrow: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            vertical: false,
            verticalSwiping: false,
            nextArrow: false,
            prevArrow: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            vertical: false,
            verticalSwiping: false,
            nextArrow: false,
            prevArrow: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            vertical: false,
            verticalSwiping: false,
            nextArrow: false,
            prevArrow: false,
          },
        },
      ],
    };
    return (
      <div className="relative vertical-slides">
        <Slider {...settings} className="important:static">
          <div className="p-2" style={{ height: "280px" }}>
            <div className="p-10 shadow--sm rounded-xl">
              <div className="grid gap-4 lg:grid-cols-2">
                <div className="my-auto">
                  <img src={pocket} alt="pocket" className="mx-auto w-60" />
                </div>
                <div className="my-auto">
                  <div className="h-1 max-w-xs mx-auto rounded-full bg--primary" />
                  <p className="my-3 xl:pr-20">
                    Modernize workflows from farm to fork - Our Digital
                    Agriculture Platform enhances traditional CTRM software,
                    enabling you to extend beyond trading into origination,
                    supply chain, and food and beverage manufacturing in a way
                    you’ve never seen before.
                  </p>
                  <div className="h-1 max-w-xs mx-auto rounded-full bg--primary" />
                </div>
              </div>
            </div>
          </div>
          <div className="p-2" style={{ height: "280px" }}>
            <div className="p-10 shadow--sm rounded-xl">
              <div className="grid gap-4 lg:grid-cols-2">
                <div className="my-auto">
                  <img src={pocket} alt="pocket" className="mx-auto w-60" />
                </div>
                <div className="my-auto">
                  <div className="h-1 max-w-xs mx-auto rounded-full bg--primary" />
                  <p className="my-3 xl:pr-20">
                    Modernize workflows from farm to fork - Our Digital
                    Agriculture Platform enhances traditional CTRM software,
                    enabling you to extend beyond trading into origination,
                    supply chain, and food and beverage manufacturing in a way
                    you’ve never seen before.
                  </p>
                  <div className="h-1 max-w-xs mx-auto rounded-full bg--primary" />
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default verticalslick;
