import { gql } from "@apollo/client";

export default gql`
    mutation update_subscription(
        $id: ID!
    ) {
        update_user_subscription(
            id: $id
        ) {
            id
            username
            firstname
            lastname
            is_pro
            is_subscribe
            is_authorized
            profile_photo
            tagline
            email
            address
            country
            timezone
            description
            timestamp
            color
            is_follower
            last_login
            categories {
                id
                name
                code
            }
            followers(first:1){
                paginatorInfo {
                    total
                }
            }
            companies(first: 10) {
                data {
                    id
                    title
                    address
                    city
                    state
                    country
                    history
                    postal_code
                    linked_in_profile
                    is_active
                }
                paginatorInfo {
                    total
                }
            }
            questions(first: 1) {
                paginatorInfo {
                    total
                }
            }
            answers(first: 1) {
                paginatorInfo {
                    total
                }
            }
        }
    }
`;
