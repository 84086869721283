import React, { Component } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Alert from "@mui/material/Alert";
import client from "../../../graphql/client";
import FORGOT_PASSWORD from "../../../graphql/mutations/web_forget_password";
import VERIFT_RESET_TOKEN from "../../../graphql/mutations/codeVerificationMutation";
import CHANGE_CUEERNT_PASSWORD from "../../../graphql/mutations/changePasswordMutation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { seo } from "../../../helpers/seo";
import { Backdroploader, Buttonlink } from "components";

class Forgotpswd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      error: false,
      errors: "",
      loading: false,
      send_email: true,
      enterCode: false,
      change_password: false,
      res_from_token: "",
      new_password: "",
      loader_msg: "Sending code for verfication please check your email",
      redirect: "/login",
    };
  }

  componentDidMount() {
    seo({
      title: "Forgot-password | Procurement League",
    });
  }

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (e, type) => {
    e.preventDefault();
    const { email, token, new_password, res_from_token } = this.state;
    if (type === "send_email") {
      if (email !== "") {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(email)) {
          this.setState({
            error: true,
            errors: "Please enter valid email address",
          });
          return;
        }
      }

      if (email.length < 1) {
        this.setState({
          error: true,
          errors: "Email address is required",
        });
      }

      if (email !== "") {
        this.setState({
          error: false,
          errors: "",
          loader_msg: "Sending code for verfication please check your email",
          loading: true,
        });
        client
          .mutate({
            mutation: FORGOT_PASSWORD,
            variables: {
              email: email,
            },
          })
          .then((response) => {
            let res = response.data.web_forget_password;
            if (res.status) {
              this.setState({
                error: true,
                errors: "Please enter your registered email address",
                loading: false,
              });
            } else {
              this.setState({
                send_email: false,
                enterCode: true,
                error: false,
                errors: "",
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              error: true,
              errors: "oops something went wrong",
              loading: false,
            });
          });
      }
    }
    if (type === "reset_password") {
      if (token.length < 1 && token.length > 6) {
        this.setState({
          error: true,
          errors: "Please Enter Valid Code",
        });
      }
      if (token !== "") {
        this.setState({
          error: false,
          errors: "",
          loader_msg: "Verifying code for Procurement League",
          loading: true,
        });
        client
          .mutate({
            mutation: VERIFT_RESET_TOKEN,
            variables: {
              email: email,
              reset_token: token,
            },
          })
          .then((response) => {
            let res = response.data.verifyResetToken;
            if (res.status) {
              this.setState({
                error: true,
                errors: "Please Enter Valid Code",
                loading: false,
              });
            } else {
              this.setState({
                send_email: false,
                enterCode: false,
                change_password: true,
                error: false,
                errors: "",
                loading: false,
                res_from_token: res.message,
              });
            }
          })
          .catch((error) => {
            this.notify("oops something went wrong", "error");
          });
      }
    }
    if (type === "change_password") {
      if (new_password.length < 4) {
        this.setState({
          error: true,
          errors: "The password length must be greater than 4 characters",
        });
      }

      if (res_from_token !== "login_token_verified") {
        this.setState({
          error: true,
          errors: "Sorry we could not able to verify your identity",
        });
      }
      if (new_password && res_from_token) {
        this.setState({
          error: false,
          errors: "",
          loader_msg: "Updating Your Password",
          loading: true,
        });
        client
          .mutate({
            mutation: CHANGE_CUEERNT_PASSWORD,
            variables: {
              email: email,
              token: res_from_token,
              new_password: new_password,
            },
          })
          .then((response) => {
            let res = response.data.change_current_user_password;
            if (res.status) {
              this.setState({
                error: true,
                errors: "Something went wrong please try again",
                loading: false,
              });
            } else {
              this.setState({
                send_email: true,
                enterCode: false,
                change_password: false,
                error: false,
                errors: "",
                email: "",
                loading: false,
                new_password: "",
              });
              this.notify("Password updated successfully", "success");
            }
          })
          .catch((error) => {
            this.notify("oops something went wrong", "error");
          });
      }
    }
  };

  notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    toast.success(message, { position: "bottom-right" });
  };

  render() {
    const {
      error,
      errors,
      email,
      loading,
      send_email,
      enterCode,
      change_password,
      token,
      new_password,
      loader_msg,
    } = this.state;
    return (
      <div className="container">
        <ToastContainer />
        {loading ? (
          <Backdroploader message={loader_msg} hideSpinner={false} />
        ) : null}
        <div className="grid gap-4 xl:grid-cols-7 lg:grid-cols-5">
          <div className="xl:col-start-3 xl:col-span-3 lg:col-start-2 lg:col-span-3">
            <Buttonlink
              Text="back"
              Icon={<IoIosArrowBack />}
              to="/login"
              className="flex items-center justify-center px-4 rounded-full bg--lightGray h-9 darkGray w-28 hover:darkGray hover:opacity-80"
            />
            {send_email ? (
              <form onSubmit={(e) => this.handleSubmit(e, "send_email")}>
                <div className="mt-5">
                  <h2 className="mb-4 text-2xl text-center Poppins">
                    FORGOTTEN PASSWORD
                  </h2>
                  <input
                    placeholder="E-mail"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                    onChange={this.handleInputChange}
                    name="email"
                    value={email}
                    maxLength={64}
                  />
                  {error ? <div style={{ height: 15 }} /> : false}
                  {error ? <Alert severity="error">{errors}</Alert> : ""}
                  <Buttonlink
                    type={"submit"}
                    Text="Password Reset"
                    onClick={(e) => this.handleSubmit(e, "send_email")}
                    className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-12 w-full"
                  />
                </div>
              </form>
            ) : null}
            {enterCode ? (
              <>
                <form
                  onSubmit={(event) =>
                    this.handleSubmit(event, "reset_password")
                  }>
                  <div className="mt-5">
                    <h2 className="mb-4 text-2xl text-center Poppins">
                      ENTER THE CODE
                    </h2>
                    <input
                      placeholder="Enter Code"
                      className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                      onChange={this.handleInputChange}
                      name="token"
                      value={token}
                      maxLength={7}
                    />
                    {error ? <div style={{ height: 15 }} /> : false}
                    {error ? <Alert severity="error">{errors}</Alert> : ""}

                    <Buttonlink
                      type={"submit"}
                      Text="Verify"
                      onClick={(e) => this.handleSubmit(e, "reset_password")}
                      className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-12 w-full"
                    />
                  </div>
                </form>
              </>
            ) : null}
            {change_password ? (
              <form
                onSubmit={(event) =>
                  this.handleSubmit(event, "change_password")
                }>
                <div className="mt-5">
                  <h2 className="mb-4 text-2xl text-center Poppins">
                    PLEASE CHANGE YOUR PASSWORD
                  </h2>
                  <input
                    placeholder="New Password"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                    onChange={this.handleInputChange}
                    name="new_password"
                    value={new_password}
                    maxLength={16}
                  />
                  {error ? <div style={{ height: 15 }} /> : false}
                  {error ? <Alert severity="error">{errors}</Alert> : ""}

                  <Buttonlink
                    type={"submit"}
                    Text="Change my password"
                    onClick={(e) => this.handleSubmit(e, "change_password")}
                    className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-12 w-full"
                  />
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Forgotpswd;
