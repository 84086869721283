import { gql } from "@apollo/client";

export default gql`
query allteams {
    allteams{
        id
        name
        title
        description
        quote
        order_by
        image_path
        linkedin
        is_active
    }
  }
`;
