import { gql } from "@apollo/client";

export default gql`
    mutation add_product_actions($user_id:ID!){
        add_product_actions(user_id:$user_id){
            message
            error
        }
    }
`;
