import { gql } from "@apollo/client";

export default gql`
    mutation CreateContactus(
        $name:String!
        $email:String!
        $description:String!
        $reason:String!
        $ticket_type:String!
    ){
        create_contactus(
            user_name:$name
            user_email:$email,
            description:$description,
            reason:$reason,
            ticket_type:$ticket_type,
        ){
            id
            user_name
            user_email
            description
            reason
            ticket_type
            is_active
        }
    }
`;
