import React from "react";
import { Buttonlink } from "components";
import { IoIosArrowBack } from "react-icons/io";

export default function Pagenotfound({data}) {
  return (
    <div
      className="px-40 py-20 shadow-xl bg-gradient-to-r from-yellow-500 to-yellow-100">
      <div className="flex flex-col items-center">
        {!data && <h1 className="text-4xl Medium lg:text-9xl">404</h1>}

        <h6 className="mb-2 text-2xl text-center Medium md:text-3xl">
          {!data && <span className="text-red-500 Regular mr-1">Oops!</span> }
          {data?.title || "Page not found"}
        </h6>

        <p className="mb-8 text-center text-gray-500 Regular md:text-lg">
          {data?.text || "The page you're looking for doesn't exist."}
        </p>

        <Buttonlink
          Text={data?.btn || "Go to Home"}
          Icon={<IoIosArrowBack />}
          to={data?.url || "/"}
          className="flex items-center h-12 px-10 text-yellow-900 bg-gradient-to-r from-yellow-500 to-yellow-400 hover:text-yellow-900 rounded-xl hover:opacity-60"
        />
      </div>
    </div>
  );
}
