import { gql } from "@apollo/client";

export default gql`
    mutation CreateQuestionAnswer($question_id:Int!,$reply:String!){
        createAnswer(
            question_id:$question_id,
            reply:$reply,
        ){
            id
            answer
            questions{
                user_id
                id
            }
        }
    }
`;
