import { gql } from "@apollo/client";

export default gql`
 mutation logout {
  logout {
    error
    message
    __typename
  }
}
`;
