import React, { Component } from "react";
import Profilesidebar from "../../../components/profile/profilesidebar";
import client from "../../../graphql/client";
import UPDATE_PASSWORD from "../../../graphql/mutations/updatePasswordMutation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { seo } from "helpers/seo";
import { connect } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import cookie from "js-cookie";
import { AUTH_USER, GRAPH_USER_AUTH_TOKEN } from "../../../config/constants";

class ChangePswd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_password: "",
      new_password: "",
      confirm_password: "",
      message: "",
      error: false,
      errorClass: "",
      pswdType: {
        current_password: true,
        new_password: true,
        confirm_password: true
      },
      disabled: true,
    };
  }

  componentDidMount() {
    seo({
      title: "Change Password |Procurement League ",
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.current_password !== "" &&
      prevState.new_password !== "" &&
      prevState.confirm_password !== "" &&
      this.state.disabled
    ) {
      this.setState({
        disabled: false,
      });
    } else if (
      (prevState.current_password === "" && !this.state.disabled) ||
      (prevState.new_password === "" && !this.state.disabled) ||
      (prevState.confirm_password === "" && !this.state.disabled)
    ) {
      this.setState({
        disabled: true,
      });
    }
  }

  handleSubmit = () => {
    let { current_password, new_password, confirm_password } = this.state;
    if (current_password === new_password) {
      this.setState({
        new_password: "",
        confirm_password: "",
        disabled: false,
      });
      return this.notify(
        "The new password must be different from the old password",
        "error"
      );
    }
    if (new_password.length < 4) {
      this.setState({
        new_password: "",
        confirm_password: "",
        disabled: false,
      });
      return this.notify(
        "The password length must be greater than 4 characters",
        "error"
      );
    }
    if (
      current_password !== "" &&
      new_password !== "" &&
      confirm_password !== ""
    ) {
      client
        .mutate({
          mutation: UPDATE_PASSWORD,
          variables: {
            current_password: current_password,
            new_password: new_password,
            confirm_password: confirm_password,
          },
        })
        .then((res) => {
          let { error, message } = res.data.updatePassword;
          if (error) {
            this.setState({
              new_password: "",
              confirm_password: "",
              disabled: false,
            });
            if (message === "Your account is not active") {
              setTimeout(() => {
                cookie.remove(GRAPH_USER_AUTH_TOKEN);
                cookie.remove(AUTH_USER);
                cookie.remove("AUTH_TOKEN_EXPIRY");
                localStorage.removeItem("AUTH_USER");
                client.clearStore();
                window.location.href = "/";
              }, 3000);
            }
            return this.notify(message, "error");
          } else {
            this.setState({
              current_password: "",
              new_password: "",
              confirm_password: "",
              disabled: true,
            });
            this.notify(message, "success");
          }
        });
    } else {
      this.notify("Please fill all required fields", "error");
    }
  };

  notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    toast.success(message, { position: "bottom-right" });
  };

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  showEyePassword = (value) => {
    this.setState((prevState) => ({
      pswdType: {
        ...prevState.pswdType,
        [value]: !prevState.pswdType[value],
      },
    }));
  };

  render() {
    const { authUser } = this.props;
    return (
      <div className="container">
        <ToastContainer />
        <div className="grid gap-4 lg:grid-cols-7">
          <Profilesidebar authUser={authUser} />
          <div className="lg:col-span-5">
            <div className="p-8 border rounded-xl">
              <form onSubmit={this.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-5">
                  <div className="space-y-5 md:col-start-2 md:col-span-3">
                    <div>
                      <div className="flex items-center justify-between">
                        <label className="lg:ml-1 fs-13 Regular">
                          CURRENT PASSWORD
                          <span className="text-red-500">*</span>
                        </label>
                      </div>
                      <div className="flex items-center bg-gray-200 rounded-xl">
                        <input
                          type={this.state.pswdType.current_password ? "password" : "text"}
                          placeholder="Current Password"
                          className="w-full px-3 bg-transparent border-0 h-11 Regular rounded-xl"
                          name="current_password"
                          maxLength={16}
                          value={this.state.current_password}
                          onChange={this.handleInputChange}
                        />
                        <div
                          onClick={() => this.showEyePassword('current_password')}
                          className="flex items-center justify-center w-12 h-12 cursor-pointer">
                          {!this.state.pswdType.current_password ? (
                            <BsEye size={20} />
                          ) : (
                            <BsEyeSlash size={20} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="lg:ml-1 fs-13 Regular">
                        NEW PASSWORD
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center bg-gray-200 rounded-xl">
                        <input
                          type={this.state.pswdType.new_password ? "password" : "text"}
                          placeholder="New Password"
                          className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                          name="new_password"
                          maxLength={16}
                          minLength={5}
                          value={this.state.new_password}
                          onChange={this.handleInputChange}
                        />
                        <div
                          onClick={() => this.showEyePassword('new_password')}
                          className="flex items-center justify-center w-12 h-12 cursor-pointer">
                          {!this.state.pswdType.new_password ? (
                            <BsEye size={20} />
                          ) : (
                            <BsEyeSlash size={20} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="lg:ml-1 fs-13 Regular">
                        CONFIRM PASSWORD
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center bg-gray-200 rounded-xl">
                        <input
                          type={this.state.pswdType.confirm_password ? "password" : "text"}
                          placeholder="Confirm Password"
                          className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                          name="confirm_password"
                          maxLength={16}
                          minLength={5}
                          value={this.state.confirm_password}
                          onChange={this.handleInputChange}
                        />
                        <div
                          onClick={() => this.showEyePassword('confirm_password')}
                          className="flex items-center justify-center w-12 h-12 cursor-pointer">
                          {!this.state.pswdType.confirm_password ? (
                            <BsEye size={20} />
                          ) : (
                            <BsEyeSlash size={20} />
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      disabled={this.state.disabled}
                      onClick={() => {
                        this.setState({
                          disabled: true,
                        });
                        this.handleSubmit();
                      }}
                      className={`flex items-center justify-center white Regular ${this.state.disabled
                        ? "bg--gray hover:bg--gray focus:bg--lightGray"
                        : "bg--primary hover:bg--primary hover:color-white focus:bg--primary"
                        } hover:opacity-70 h-11 rounded--xl border-0 shadow-none w-full`}
                    >
                      Update Password
                    </button>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(ChangePswd);
