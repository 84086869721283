import React, { useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import "./styles.css";
import { Link } from "react-router-dom";
import { ProfileAvatar, IsLoader, Noconnection, Buttonlink } from "components";

import { Query } from "@apollo/client/react/components";
import GET_NOTIFICATIONS from "../../../graphql/queries/getNotifications";
import Pagenotfound from "../../allcomponents/pagenotfound";
import Nocontent from "../../allcomponents/nocontent";
import moment from "moment";
import { IoCheckmarkDone } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useMutation } from "@apollo/client";
import ReadNotificationMutation from "../../../graphql/mutations/readNotificationMutation";
import { Dropdown } from "semantic-ui-react";
import "../../../assets/css/semantic.css";
import client from "../../../graphql/client";
import DELETE_NOTIFICATION from "../../../graphql/mutations/deleteNotification";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import { useLocation } from "react-router-dom";

const topics = [
  {
    text: "Default",
    value: 1,
  },
  {
    text: "Liked By",
    value: 2,
  },
  {
    text: "Answers",
    value: 3,
  },
  {
    text: "Comments",
    value: 4,
  },
];

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 1));
}

function Notification(props) {
  console.log('props', props)
  const [loadMoreNotifications, setLoadMoreNotifications] = useState(false);
  const [selectNotifications, setSelectNotifications] = useState(1);
  const [changeCount, setChangeCount] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (loadMoreNotifications) {
      simulateNetworkRequest().then(() => {
        setLoadMoreNotifications(false);
      });
    }
  }, [loadMoreNotifications]);

  const _deleteNotificationsMutation = (event) => {
    client.mutate({
      mutation: DELETE_NOTIFICATION,
      variables: {
        id: event,
      },
      optimisticResponse: (vars) => {
        return {
          deleteNotification: {
            id: event,
            __typename: "Notifications",
          },
        };
      },
      update: (cache, result) => {
        // console.log("result", result)
        const removeQuestion = cache.identify(result.data.deleteNotification);
        // console.log("unsaveQuestion", removeQuestion)
        cache.modify({
          fields: {
            Question: (existingQuestionRefs) => {
              console.log("existingQuestionRefs", existingQuestionRefs);
              return existingQuestionRefs?.data?.filter((questionRef) => {
                return cache.identify(questionRef) !== removeQuestion;
              });
            },
          },
        });
        cache.evict({ id: removeQuestion });
      },
    });
  };

  const [addReadAt] = useMutation(ReadNotificationMutation, {
    onCompleted(data) {
      // console.log("useMutation", data);
      if (props?.showAllAlerts === false) {
        props.getAlerts();
      } else {
        setChangeCount(true)
      }

    },
  });

  const handleFilters = (event, data) => {
    setSelectNotifications(data.value);
  };

  const _handleLoadMore = (e) => {
    e.preventDefault();
    // console.log("here")
    setLoadMoreNotifications(true);
    console.log("loadMoreNotifications", loadMoreNotifications);
  };
  let variables = {};

  if (selectNotifications === 1) {
    console.log('location.pathname === "/notifications"', location.pathname === "/notifications")
    if (location.pathname === "/notifications" && !props?.showAllAlerts === false) {
      variables.getAll = true;
    } else {
      variables.getAll = false;
    }

  } else if (selectNotifications === 2) {
    variables.getLikes = true;
  } else if (selectNotifications === 3) {
    variables.getAnswers = true;
  } else if (selectNotifications === 4) {
    variables.getComments = true;
  } else {
    variables.getAll = true;
  }
  const formatDateTime = (date) => {
    const hoursDifference = new Date().getTimezoneOffset() / 60;
    const inputDate = moment(date, "YYYY-MM-DD HH:mm:ss").subtract(
      hoursDifference,
      "hours"
    );
    const today = moment();
    const diffHours = today.diff(inputDate, "hours");

    if (diffHours < 1) {
      // Less than 1 hour ago
      const diffMinutes = today.diff(inputDate, "minutes");
      const diffSeconds = today.diff(inputDate, "seconds");
      if (diffMinutes < 1 && diffSeconds > 0) {
        return `${diffSeconds} second${diffSeconds !== 1 ? "s" : ""} ago`;
      } else {
        return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""} ago`;
      }
    } else if (diffHours < 24) {
      // Today: X hours ago
      return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
    } else if (diffHours < 48) {
      // Yesterday: Yesterday at hh:mm A
      return `Yesterday at ${inputDate.format("hh:mm A")}`;
    } else if (inputDate.year() !== today.year()) {
      // Previous year: D MMM YYYY at hh:mm A
      return inputDate.format("D MMM YYYY [at] hh:mm A");
    } else {
      // Current year, more than 2 days ago: D MMM at hh:mm A
      return inputDate.format("D MMM [at] hh:mm A");
    }
  };

  return (

    <div className="space-y-1 Notification">
      <Query query={GET_NOTIFICATIONS} variables={variables} fetchPolicy="cache-and-network">
        {({ loading, error, data, fetchMore, refetch }) => {
          if (changeCount) {
            refetch();
            setChangeCount(false);
          }
          if (props.refetch) {
            refetch();
            return null;
          }
          if (loading) {
            return (
              <div className="my-10">
                <IsLoader className="w-32 h-32 mx-auto" />
              </div>
            );
          }
          if (error) {
            extractValidationErrors(error)
            return <Noconnection />;
          }

          if (!data) {
            return Pagenotfound;
          }
          
          if (data.me.notifications.data.length === 0) {
            return (
              <>
                {props.showAllAlerts && (
                  <div className="flex flex-wrap items-center justify-between">
                    <p className="text-[16px] Medium">Filter By</p>
                    <div className="my-3 ml-auto text-right">
                      <Dropdown
                        className="bg-transparent border-0 gray Regular rounded--lg"
                        placeholder="Select Topics"
                        fluid
                        selection
                        value={selectNotifications}
                        options={topics}
                        onChange={handleFilters}
                      />
                    </div>
                  </div>
                )}
                <div className="primary text-center opacity-70">Looks like there's nothing new for now!</div>
              </>
            );
          }

          if (loadMoreNotifications) {
            fetchMore({
              variables: {
                cursor: data.me.notifications.paginatorInfo.currentPage + 1,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newQuestions = fetchMoreResult.me.notifications.data;
                const pageInfo = fetchMoreResult.me.notifications.paginatorInfo;
                /* if (
                                    data.me.notifications.paginatorInfo.total !== pageInfo.total
                                ) {
                                    refetch();
                                }*/
                return newQuestions.length
                  ? {
                    me: {
                      id: parseInt(previousResult.me.id),
                      __typename: previousResult.me.__typename,
                      notifications: {
                        __typename:
                          previousResult.me.notifications.__typename,
                        data: [
                          ...previousResult.me.notifications.data,
                          ...newQuestions,
                        ],
                        paginatorInfo: pageInfo,
                      },
                    },
                  }
                  : previousResult;
              },
            });
          }

          if (data !== undefined) {
            let slicedArray = !props.showAllAlerts
              ? data.me.notifications.data
                .filter((o) => o.read_at === null)
                .slice(0, 7)
              : data.me.notifications.data;
            return (
              <>

                {props.showAllAlerts && (
                  <div className="flex flex-wrap items-center justify-between">
                    <p className="text-[16px] Medium">Filter By</p>
                    <div className="my-3 ml-auto text-right">
                      <Dropdown
                        className="bg-transparent border-0 gray Regular rounded--lg"
                        placeholder="Select Topics"
                        fluid
                        selection
                        value={selectNotifications}
                        options={topics}
                        onChange={handleFilters}
                      />
                    </div>
                  </div>
                )}


                <Popover>
                  {!props.showAllAlerts && (
                    <div className="flex items-center justify-between mb-4">
                      <p className={`${props.size} Medium`}>Notification</p>
                      <div className="flex items-center">
                        <Popover.Button>
                          <Link
                            onClick={() => props.setIsOpen()}
                            to="/notifications"
                            className="p-1 text-sm rounded-lg primary hover:bg-gray-100">
                            See All
                          </Link>
                        </Popover.Button>
                      </div>
                    </div>
                  )}
                  {slicedArray.length === 0 ? (
                    <Nocontent />
                  ) : (
                    slicedArray.map((notification) => {
                      const formattedDateTime = formatDateTime(notification.created_at);
                      // let array = notification?.data?.link.split('/');
                      // console.log(array)
                      // console.log("/" + array[3] + "/" + array[4])
                      return (
                        <Popover.Button as="div" className={"cursor-pointer"}>
                          <div className="-mx-3 space-y-1">
                            <div className="relative flex justify-between items-center px-3 py-2 rounded-lg  hover:bg-gray-100 notifi_items gap-x-5">
                              <Link
                                to={
                                  notification?.data?.link
                                    ? notification?.data?.link
                                    : "/"
                                }
                                onClick={() => {
                                  props.setIsOpen()
                                  addReadAt({
                                    variables: { id: notification.id },
                                  })
                                }}
                              >
                                <div className="flex items-center gap-3 cursor-pointer">
                                  <ProfileAvatar
                                    platform={"PL"}
                                    singleUser={notification.sender}
                                    className="bg-gray-300 rounded-full h-11 w-11"
                                  />
                                  <div className="flex flex-wrap flex-col">
                                    <strong className="mr-1 text-base Medium black">
                                      {notification.sender?.firstname}{" "}
                                      {notification.sender?.lastname}
                                    </strong>
                                    <p className="fs-11 gray">
                                      {notification.message}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <div className="flex-shrink-0">
                                <p className="ml-auto darkGray fs-12 Light">
                                  {formattedDateTime}
                                  {/* {moment(notification.created_at).format(
                                    "DD-MM-YYYY"
                                  )} */}
                                </p>
                                {notification.read_at !== null && (
                                  <div className="flex items-center justify-end">
                                    <IoCheckmarkDone
                                      size={20}
                                      className="mr-3 text-sky-500"
                                    />
                                    <MdDelete
                                      size={20}
                                      className="cursor-pointer text-sky-500 hover:text-red-500"
                                      onClick={() =>
                                        _deleteNotificationsMutation(
                                          notification.id
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Popover.Button>
                      );
                    })
                  )}
                </Popover>
                {props.showAllAlerts &&
                  data.me.notifications.paginatorInfo.hasMorePages && (
                    <div className="mt-8 text-center">
                      <Buttonlink
                        onClick={(e) => _handleLoadMore(e)}
                        Text="Load More"
                        className="relative z-20 px-6 py-2 leading-5 rounded-lg shadow-md fs-11 Regular hover:opacity-70 bg--primary white hover:text-white"
                      />
                    </div>
                  )}
              </>
            );
          }
        }}
      </Query>
    </div>
  );
}

export default Notification;
