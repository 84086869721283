import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FiChevronDown } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Auth from "../../../Auth";
import { Signinpopup } from "../../index";
import Buttonlink from "../../allcomponents/buttonlink";
const auth = new Auth();

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <h4 className="mb-8 text-2xl Medium">FAQs And Tips To Get Started</h4>
      <div className="grid gap-4 lg:grid-cols-2">
        <div className="flex flex-col gap-4">
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="p-2 overflow-hidden important:shadow--sm rounded--xl">
              <AccordionSummary
                expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <p>Why should I register as a Member?</p>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Most whitepapers, articles, free access to events and classes,
                  and other material on procurementleague.com are exclusively
                  available to our members. Most of the time, we will ask you to
                  register or log in to our site before registering for an event
                  or downloading the whitepapers. As a registered member of
                  procurementleague.com, you can also sign up for our regular
                  email newsletters and receive alerts when new content is
                  available. Your registration credentials will also work on the
                  Procurement League app, offering mobile access to our content
                  and additional personalization options. To take advantage of
                  these services.
                  {auth.isAuthenticated() ? (
                    <> But you are already Login</>
                  ) : (
                    <>
                      Please register now{" "}
                      <Buttonlink
                        onClick={() => setOpen(true)}
                        Text={"LogIn"}
                        className={
                          "primary bg-transparent border-0 shadow-none p-0 Regular hover:primary--text"
                        }
                      />
                    </>
                  )}
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className="p-2 overflow-hidden important:shadow--sm rounded--xl">
              <AccordionSummary
                expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header">
                <p>
                  What is the membership fee for joining Procurement League?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Procurement League is an education platform where procurement
                  and supply chain enthusiasts can come and learn about how to
                  be a better procurement professional, and we do it all for
                  FREE.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className="p-2 overflow-hidden important:shadow--sm rounded--xl">
              <AccordionSummary
                expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <p>
                  What makes a great discussion on the Procurement League
                  Discussion Forum?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  A great Procurement League Discussion would meet some of the
                  following attributes:
                </p>
                <ul className="pt-3 list-disc">
                  <li>
                    It addresses a Procurement problem that many practitioners
                    would like to see solved.
                  </li>
                  <li>
                    It excites the community to jump in and solve the problem
                    while still seeming achievable.
                  </li>
                  <li>
                    It encourages community engagement, collaboration, and
                    competition.
                  </li>
                  <li>
                    It addresses a problem area where there is a scope for
                    improvement.
                  </li>
                  <li>
                    Inspire a new way to think about a procurement and sourcing
                    problem.
                  </li>
                  <li>
                    Spark discussions, imagination, and encourage many people to
                    search for the solution.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className="p-2 overflow-hidden important:shadow--sm rounded--xl">
              <AccordionSummary
                expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <p>
                  What are the advantages of online learning at ProcureClass?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  ProcureClass is an online learning platform of the future that
                  blends time-tested pedagogy with innovative training
                  experiences and executive content tailored to the busy lives
                  of procurement professionals on the go. With academically
                  robust and application-oriented frameworks, learners get a
                  chance to build skills to help them navigate their first job
                  or the most crucial career cusps. Our unique curriculum allows
                  learners to build skills to succeed at every stage of their
                  careers. Our development programs majorly target different
                  career stages. It includes interactive elements like
                  hands-on-tool workshops, industry mentorship, and practice
                  exercises to apply course concepts.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              className="p-2 overflow-hidden important:shadow--sm rounded--xl">
              <AccordionSummary
                expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <p>
                  How does ProcureClass customize its programs to specific
                  company needs?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  The ProcureClass is known for outcome-based online learning.
                  At the beginning of the program, we benchmark learner skills
                  to set the baseline. The benchmarking exercise considers a
                  learner's specific context and workplace environment. The
                  benchmark helps us map a learner's/team's progress throughout
                  the program.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Link to="/faqs" className="inline-flex items-center black Regular">
          See more <IoIosArrowForward className="ml-2" />
        </Link>
      </div>
      {open ? (
        <Signinpopup open={open} closePopup={() => setOpen(false)} />
      ) : (
        false
      )}
    </>
  );
}
