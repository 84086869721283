export const GRAPH_USER_AUTH_TOKEN = "GRAPH_USER_AUTH_TOKEN";
export const GET_AUTH_USER_SOCKET = "GET_AUTH_USER_SOCKET";
export const AUTH_USER = "AUTH_USER";
export const PROFILE_PHOTO_URL = "/uploads/profile_images/";

// let path = window.location.hostname.split(".")
/*LIVE HOST*/
export const WEB_URL = "https://dev.procurementleague.com";
export const SOURCING_GENIE = "https://.sourcinggenie.io";
export const MAIN_URL = "https://procurementleague.com";
export const GRAPH_URL = "https://procurementleague.com/graphql";
export const MRO_URL = "https://mro.procurementleague.com";
/*------LIVE HOST END------*/

/*LOCALHOST*/
// export const WEB_URL = "http://localhost:3001";
// export const SOURCING_GENIE = "http://localhost:3000";
// export const MAIN_URL = "http://192.168.18.217:9000";
// export const GRAPH_URL = "http://192.168.18.217:9000/graphql";
// export const MRO_URL = "http://192.168.18.217:8000";
/*------LOCALHOST END------*/

/*linkedIn redirect_uri*/
// export const LINKEDIN_URL_CALLBACK = "https://web.procurementleague.com/linkedin/callback";
// export const LINKEDIN_URL_CALLBACK = 'http://localhost:3000/linkedin/callback';
export const LINKEDIN_URL_CALLBACK = 'https://dev.procurementleague.com/linkedin/callback';

/*IMAGE UPLOAD API*/
// export const POST_IMAGE_UPLOAD_API = "https://procurementleague.com/api/s3upload";
export const POST_IMAGE_UPLOAD_API = "http://192.168.110.106:9000/api/s3upload";
/*--------IMAGE UPLOAD API---------*/

/*SOCIAL LINKS*/
export const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.pro.procurementleague&hl=en";
export const IOS_APP_URL = "https://apps.apple.com/us/app/procurement-league/id1494128878";
export const FACEBOOK_URL = "https://www.facebook.com/procurementLeague/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/procurement-league/";
export const TWITTER_URL = "https://twitter.com/procurementlea";
export const YOUTUBE_URL = "https://www.youtube.com/@procurementleague3289";
export const INSTAGRAM_URL = "https://www.instagram.com/procurementleague/?hl=en";
/*------SOCIAL LINKS END------*/

/*GOOGLE PAYMENT CONFIGURATION*/
export const PAYEEZY_PAY_PAYMENT_GATEWAY_NAME = "firstdata";

/*Live Payment*/
export const PAYMENT_GATEWAY_ENVIRONMENT = "PRODUCTION";
export const PAYMENT_GATEWAY_MERCHANT_ID = "374251806880";

/*Sandbox Payment*/
// export const PAYMENT_GATEWAY_ENVIRONMENT = "TEST";
// export const PAYMENT_GATEWAY_MERCHANT_ID = "3176752955";

/*GOOGLE PAY ACCOUNT DETAILS*/
export const GOOGLE_PAY_MERCHANT_ID = "BCR2DN4T3DA2VPYB";
export const GOOGLE_PAY_MERCHANT_NAME = "Procurement League Inc";
/*------GOOGLE PAYMENT CONFIGURATION END------*/
