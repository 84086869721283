import React, { Component } from "react";
import Slider from "react-slick";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Signinpopup } from "components";
import EnrollPopup from "components/allcomponents/enrollPopup";
// import Auth from "../../Auth";
import encryptfunction from "../../helpers/Encrypt";
// const auth = new Auth();

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute Poppins right-0 top-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
      onClick={onClick}
    >
      <BsArrowRight size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute Poppins z-10 top-1/2 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
      onClick={onClick}
    >
      <BsArrowLeft size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

class resourcecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchased: false,
      openModal: false,
    };
  }

  onClickOk = (e) => {
    e.preventDefault();
    this.setState({ purchased: true });
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    const { products, type } = this.props;
    return (
      <div className="relative blog-posts slider-left equal-height">
        <h3 className="mb-20 text-3xl Poppins md:mb-5">
          Get World Class Products &#38; Knowledge Assets
        </h3>
        <Slider {...settings} className="important:static lg:px-10 px-[14px]">
          {products.map((product) => (
            <div className="h-full p-5 pt-3" key={product.id}>
              <Link
                to={{
                  pathname: `/product/${encryptfunction(product.id)}/${product.course_slug
                    }`,
                  state: { item: product, type: type },
                }}
                className="block bg-white p-5 h-full transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl">
                <div
                  className="relative overflow-hidden">
                  <img
                    alt={product.course_name}
                    src={product.course_cover_pic}
                    className="object-contain w-full mx-auto h-full rounded-t-xll"
                  />
                </div>
                <div className="px-3 py-4">
                  <h4 className="mb-1 text-2xl font-light Poppins text-orange-500 ">
                    {/* {product.course_name} */}
                    {product.users.data[0]?.firstname +
                      " " +
                      product.users.data[0]?.lastname}
                  </h4>
                  {product.users.data && (
                    <p className="my-1 Poppins fs-13">
                      {product.course_name}
                    </p>
                  )}
                  <div className="flex items-center justify-between mt-5">
                    <Link
                      to={{
                        pathname: `/product/${encryptfunction(product?.id)}/${product?.course_slug
                          }`,
                        state: { item: product, type: type },
                      }}
                      className="flex items-center Poppins primary fs-13">
                      Read more {'>>'}
                    </Link>

                    {product?.is_enroll ? (
                      <a
                        href
                        onClick={this.onClickOk}
                        className="z-10 flex items-center gray Poppins fs-14">
                        {`$${product?.course_objective || "0"}`}
                      </a>
                    ) : (
                      <Link
                        to={{
                          pathname: `/payment`,
                          state: {
                            item: {
                              id: product.id,
                              price: product.course_objective,
                              name: product.course_name,
                              data_type: product.data_type,
                              is_shippable: product.is_shippable,
                            },
                            type: type,
                          },
                        }}
                        className="flex items-center primary Poppins fs-14">
                        {`$${product.course_objective || "0"}`}
                      </Link>
                    )}
                  </div>
                </div>
              </Link>
              {/* {auth.isAuthenticated() ? (
                <Link
                  to={{
                    pathname: `/product/${encryptfunction(product.id)}/${product.course_slug}`,
                    state: { item: product, type: type },
                  }}
                  className="justify-start block h-full transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--gray-800 shadow--sm rounded-xl"
                >
                  <div
                    className="relative bg-gray-200 rounded-t-xl"
                    style={{ minHeight: 150 }}
                  >
                    <img
                      alt={product.course_name}
                      src={product.course_cover_pic}
                      className="object-contain object-top w-full h-full mx-auto hover:bg-gray-200 rounded-t-xl"
                    />
                  </div>
                  <div className="p-4">
                    <h4 className="mb-1 Poppins fs-14 ">
                      {product.course_name}
                    </h4>
                    {product.users.data && (
                      <p className="my-3 Poppins fs-13">
                        {product.users.data[0].firstname +
                          " " +
                          product.users.data[0].lastname}
                      </p>
                    )}
                    <div className="flex items-center justify-between">
                      <Link
                          to={{
                            pathname: `/product/${encryptfunction(product.id)}/${product.course_slug}`,
                            state: { item: product, type: type },
                          }}
                        className="flex items-center black Poppins fs-14"
                      >
                        Read more <IoIosArrowForward className="ml-2" />
                      </Link>

                      {product.is_enroll ? (
                        <a
                          href
                          onClick={this.onClickOk}
                          className="z-10 flex items-center gray Poppins fs-14"
                        >
                          {`$${product.course_objective}`}
                        </a>
                      ) : (
                        <Link
                          to={{
                            pathname: `/payment`,
                            state: {
                              item: {
                                id: product.id,
                                price: product.course_objective,
                                name: product.course_name,
                                is_shippable: product.is_shippable,
                              },
                              type: type,
                            },
                          }}
                          className="flex items-center primary Poppins fs-14"
                        >
                          {`$${product.course_objective}`}
                        </Link>
                      )}
                    </div>
                  </div>
                </Link>
              )
                  :
                  (
                <div
                  onClick={() => this.setState({ openModal: true })}
                  className="block h-full p-0 transition duration-500 ease-in-out transform bg-transparent border-0 cursor-pointer shadow--sm rounded-xl hover:-translate-y-3 important:text-left"
                >
                  <div
                    className="relative bg-gray-200 rounded-t-xl"
                    style={{ minHeight: 150 }}
                  >
                    <img
                      alt={product.course_name}
                      src={product.course_cover_pic}
                      className="object-cover object-top w-full h-full mx-auto hover:bg-gray-200 rounded-t-xl"
                    />
                  </div>
                  <div className="p-4">
                    <h4 className="mb-1 Poppins fs-14 ">
                      {product.course_name}
                    </h4>
                    {product.users.data && (
                      <p className="my-3 Poppins fs-13">
                        {product.users.data[0].firstname +
                          " " +
                          product.users.data[0].lastname}
                      </p>
                    )}
                    <div className="flex items-center justify-between">
                      <Link
                        to={"#"}
                        className="flex items-center black Poppins fs-14"
                      >
                        Read more <IoIosArrowForward className="ml-2" />
                      </Link>

                      {product.is_enroll ? (
                        <a
                          href
                          onClick={this.onClickOk}
                          className="z-10 flex items-center gray Poppins fs-14"
                        >
                          {`$${product.course_objective}`}
                        </a>
                      ) : auth.isAuthenticated() ? (
                        <Link
                          to={{
                            pathname: `/payment`,
                            state: {
                              item: {
                                id: product.id,
                                price: product.course_objective,
                                name: product.course_name,
                                is_shippable: product.is_shippable,
                              },
                              type: type,
                            },
                          }}
                          className="flex items-center primary Poppins fs-14"
                        >
                          {`$${product.course_objective}`}
                        </Link>
                      ) : (
                        <a
                          href
                          onClick={() => this.setState({ openModal: true })}
                          className="flex items-center primary Poppins fs-14"
                        >
                          {`$${product.course_objective}`}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}*/}
            </div>
          ))}
        </Slider>

        <EnrollPopup
          product={true}
          show={this.state.purchased}
          onHide={() => this.setState({ purchased: false })}
        />
        {this.state.openModal ? (
          <Signinpopup
            open={this.state.openModal}
            closePopup={() => this.setState({ openModal: false })}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}

export default resourcecard;
