import React, { Component } from "react";
import Slider from "react-slick";
import BlobBg from "assets/images/Home/Vector.png";
import BlobBgBlue from "assets/images/Home/Blogbgblue.png";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 bottom-1/4 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
      onClick={onClick}>
      <BsArrowRight size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 bottom-1/4 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
      onClick={onClick}>
      <BsArrowLeft size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

class ourpartners extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="relative">
        {this.props.changeTitle ? (
          <h3 className="mb-20 lg:text-5xl text-4xl text-center text-new-color font-light Poppins">
            We are blessed to work with leading brands and companies
          </h3>
        ) : (
          <>
            <h2 className="mb-10 lg:text-5xl text-4xl text-center text-new-color font-light Poppins">Our Corporate Clients</h2>
            <h3 className="mb-20 lg:text-3xl text-2xl text-center text-new-color font-light Poppins">Leading buyers and suppliers offering services and products</h3>
          </>
        )}
        <Slider {...settings} className="important:static lg:px-20">
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBg}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-full text-center">Leading Lifestyle Company</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBg}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-full text-center">Global Research & Development Company</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBgBlue}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-full text-center">E-Sourcing Solution Company</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBg}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-full text-center">Contract Research</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBg}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-11/12 text-center">Business Development Company</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBgBlue}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-11/12 text-center">Globle Strategy & Operations Company</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBgBlue}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-11/12 text-center">Global Contract Development and Manufacturing Organization</p>
            </div>
          </div>
          <div>
            <div className="m-auto w-52 relative">
              <img
                alt="LifeScience"
                src={BlobBgBlue}
                className="object-contain w-full h-full"
              />
              <p className="absolute flex justify-center items-center text-lg font-normal Poppins inset-0 h-full w-11/12 text-center">Intake-to-Pay Solution Provider</p>
            </div>
          </div>

        </Slider>
      </div>
    );
  }
}

export default ourpartners;
