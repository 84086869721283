import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import "../../../assets/css/semantic.css";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineBlock } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../../../graphql/client";
import DELETE_QUESTION_MUTATION from "../../../graphql/mutations/deleteQuestionMutation";
import DELETE_QUESTION_ANSWER_MUTATION from "../../../graphql/mutations/deleteAnswerMutation";
import DELETE_ANSWER_COMMENT_MUTATION from "../../../graphql/mutations/deleteCommentMutation";
import { Buttonlink, Fullscreenpopup } from "../../index";
import { connect } from "react-redux";
import { IoTrashOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

class QuickActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: false,
      title: "",
    };
  }

  openPopup = (e) => {
    this.SelectFullPopup.openPopup();
    this.setState({
      title: e,
    });
  };

  _handleDelete = (data, type) => {
    if (type === "question") {
      client
        .mutate({
          mutation: DELETE_QUESTION_MUTATION,
          variables: {
            question_id: parseInt(data.id),
          },
          optimisticResponse: (vars) => {
            return {
              deleteQuestion: {
                error: false,
                message: "Question has been deleted successfully",
                __typename: "DeleteQuestion",
                payload: {
                  id: parseInt(vars.question_id),
                  __typename: "Question",
                },
              },
            };
          },
          update: (cache, result) => {
            // console.log(result);
            if (result.data.deleteQuestion.error === false) {
              const deletedQuestion = cache.identify(
                result.data.deleteQuestion.payload
              );
              // console.log("deletedQuestion", deletedQuestion);
              cache.modify({
                fields: {
                  Question: (existingQuestionRefs) => {
                    // console.log("existingQuestionRefs", existingQuestionRefs);
                    return existingQuestionRefs?.data?.filter((questionRef) => {
                      return cache.identify(questionRef) !== deletedQuestion;
                    });
                  },
                },
              });
              cache.evict({ id: deletedQuestion });
              if (window.location.href.includes(data.slug)) {
                this.props.history.push("/discussions")
              }
            }
          },
        })
        .then((response) => {
          // console.log("response", response);
          if (response.data.deleteQuestion.error === false) {
            this.notify("Deleted successfully", "success");
          }
          // this.props.handleDeleteQuestion(data.id);
        })
        .catch((error) => {
          console.log("error", error);
          this.notify("oops something went wrong", "error");
        });
    }
    if (type === "answer") {
      client
        .mutate({
          mutation: DELETE_QUESTION_ANSWER_MUTATION,
          variables: {
            answer_id: parseInt(data.id),
          },
        })
        .then((response) => {
          this.notify("Answer Deleted successfully", "success");
          this.props.handleDeleteQuestionAnswer(data.id);
        })
        .catch((error) => {
          this.notify("oops something went wrong", "error");
        });
    }
    if (type === "comment") {
      client
        .mutate({
          mutation: DELETE_ANSWER_COMMENT_MUTATION,
          variables: {
            comment_id: parseInt(data.id),
          },
        })
        .then((response) => {
          this.notify("Comment Deleted successfully", "success");
          this.props.handleDeleteQuestionAnswer(data.id);
        })
        .catch((error) => {
          this.notify("oops something went wrong", "error");
        });
    }
  };

  notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    toast.success(message, { position: "bottom-right" });
  };

  callBackResponse = (type, data) => {
    let text = type === 'answer' ? data.answer : data.comment;
    this.props.callBackResponse({ type: type, id: data.id, text: text })
  }

  render() {
    const { authUserInformation } = this.props;
    const CustomTrigger = () => {
      return (
        <div className="z-10 items-center justify-center w-10 h-10 m-auto rounded-full !flex hover:bg-gray-200">
          <BsThreeDots size={30} className="gray" />
        </div>
      );
    };

    return (
      <div>
        <ToastContainer />
        {!window.location.href.includes("profile") &&
          <Dropdown
            icon={false}
            wrapSelection={true}
            direction="left"
            pointing="left"
            className={'z-10 items-center justify-center w-10 h-10 ml-auto rounded-full !flex hover:bg-gray-200'}
            text={<CustomTrigger />}>
            <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
              <div className="relative p-2">
                {/*<div*/}
                {/*    className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl">*/}
                {/*    <div*/}
                {/*        className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">*/}
                {/*        <IoMdShareAlt size={18} className="darkGray"/>*/}
                {/*    </div>*/}
                {/*    <p>Share</p>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl">*/}
                {/*    <div*/}
                {/*        className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">*/}
                {/*        <IoIosCopy size={18} className="darkGray"/>*/}
                {/*    </div>*/}
                {/*    <p>Copy</p>*/}
                {/*</div>*/}
                {authUserInformation.id === this.props.data?.users?.id ? (
                  <>
                    <div
                      key={this.props.data.id}
                      onClick={() =>
                        toast.warn(
                          <div>
                            <p>Are you sure you want to delete?</p>
                            <button className={'mx-2'}
                              onClick={() => {
                                this._handleDelete(this.props.data, this.props.type);
                                toast.dismiss();
                              }}
                            >
                              Yes
                            </button>
                            <button className={'mx-2'} onClick={() => toast.dismiss()}>No</button>
                          </div>,
                          {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false,
                            closeOnClick: false,
                            draggable: false,
                            closeButton: false,
                            theme: "light",
                          }
                        )
                      }
                      className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl"
                    >
                      <div className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                        <IoTrashOutline size={18} className="danger" />
                      </div>
                      <p className="danger">Delete</p>
                    </div>
                    {this.props.type === 'question' ?
                      < Link
                        to={{
                          pathname: `/post-discussion`,
                          state: {
                            question: this.props.data,
                          },
                        }}
                        className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl">
                        <div className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                          <FaEdit size={18} className="primary" />
                        </div>
                        Edit
                      </Link> :
                      <Buttonlink
                        onClick={() => this.callBackResponse(this.props?.type, this.props?.data)}
                        className={'flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl'}
                        Icon={
                          <div className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                            <FaEdit size={18} className="primary" />
                          </div>}
                        Text={`${this.props.type === 'answer' ? "Edit Comment" : "Edit Reply on Comment"}`}
                      />
                    }
                    {/*<div className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl">
                    <div className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                      <FaEdit size={18} className="primary" />
                    </div>
                    <p className="primary">Edit</p>
                  </div>*/}
                  </>
                ) : (
                  <div
                    key={this.props.data.id}
                    className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl"
                    onClick={() => this.openPopup("Report & Block")}>
                    <div className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                      <MdOutlineBlock size={18} className="danger" />
                    </div>
                    <p className="danger">Report & Block</p>
                  </div>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        }
        <Fullscreenpopup
          ref={(ref) => (this.SelectFullPopup = ref)}
          title={this.state.title}
          direction="left"
          type={this.props.type}
          data={this.props.data}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInformation: state.AuthUserReducer.authUserInformation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteQuestion: (id) =>
      dispatch({ type: "REMOVE_QUESTION", payload: id }),
    handleDeleteQuestionAnswer: (id) =>
      dispatch({ type: "REMOVE_QUESTION_ANSWER", payload: id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickActions);
