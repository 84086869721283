import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import Subtract from "assets/images/Subtract.png";
import Disscussion from "assets/images/Community/Disscussion.png";
import Event from "assets/images/Community/Event.png";
import banner from "assets/images/Community/Community.webp";
import Resources from "assets/images/Community/Resources.png";
import { Buttonlink, Knowledgepocket } from "components";
import { Link, useHistory } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";

const CommunityPage = () => {

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="bg--lightBlue pb-28 -mt-[50px]">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden relative">
                        <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] CommunityBanner relative">
                        <div className="flex w-full max-w-3xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Connect. Learn. Thrive Together.
                            </p>
                            <p className="white font-light Poppins md:text-[48px] leading-[58px] text-[30px]">
                                Join Our Thriving Community of Knowledge and
                                Connection
                            </p>
                            <p className="white Poppins font-normal">
                                Welcome to our vibrant community where knowledge
                                meets connection.
                            </p>
                            <div className="h-px bg-white mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={Subtract}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>
                <div className="pt-28 container mx-auto lg:relative">
                    <div className="absolute lg:block hidden left-0 top-0">
                        <svg
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            />
                        </svg>
                    </div>

                    <div className="grid pt-10 gap-10 lg:grid-cols-3 md:grid-cols-2 grid-cols-1" id="contentSection">
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Disscussion}
                                className="w-[80px] h-[66px] mb-4"
                                alt="Disscussion"
                            />
                            <h1 className="Poppins font-light text-3xl leading-6">
                                Connection Forum
                            </h1>
                            <p className="Poppins my-5 text-[#16283A] text-[18px] font-normal leading-6">
                                Connect, collaborate, and grow your network in
                                our open and free discussion forum. Engage with
                                industry experts, ask questions, and forge
                                valuable connections to enhance your
                                professional journey."
                            </p>
                            <Link to={"/discussions"} className="Poppins primary text-[18px] font-normal leading-6">
                                Join the Conversation Now {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Event}
                                className="w-[77px] h-[77px] mb-4"
                                alt="Event"
                            />
                            <h1 className="Poppins font-light text-3xl leading-6">
                                Events
                            </h1>
                            <p className="Poppins my-4 text-[#16283A] text-[18px] font-normal leading-6">
                                Expand your knowledge and gain inspiration from
                                industry leaders through our free podcasts and
                                webinars. Stay up to date with the latest
                                trends, insights, and best practices to excel in
                                your field.
                            </p>
                            <Link to="/events" className="Poppins primary text-[18px] font-normal leading-6">
                                Explore {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Resources}
                                className="w-[74px] h-[73px] mb-4"
                                alt="Resources"
                            />
                            <h1 className="Poppins font-light text-3xl leading-6">
                                Knowledge Bank
                            </h1>
                            <p className="Poppins my-4 text-[#16283A] text-[18px] font-normal leading-6">
                                Access a wealth of knowledge and insights
                                through our free newsletters and blog resources.
                                Stay informed, discover valuable tips, and
                                empower yourself with actionable content to
                                drive your success.
                            </p>
                            <Link to={"/knowledge-bank"} className="Poppins primary text-[18px] font-normal leading-6">
                                Get Insights {">>"}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-28">
                <div className="grid grid-cols-12 relative">
                    <div className="lg:col-span-7 space-y-5 col-span-12">
                        <h1 className="primary text-3xl font-light Poppins">
                            Connect. Learn. Thrive Together.
                        </h1>
                        <p className="text-lg text-new-color font-normal Poppins">
                            {" "}
                            Engage in meaningful discussions, ask questions, and
                            gain valuable insights from experts and fellow
                            enthusiasts. Attend our free events to expand your
                            horizons, and dive into our rich collection of blog
                            resources and newsletters for valuable information
                            and inspiration. Join our community and embark on a
                            journey of continuous learning and growth.
                        </p>
                        <Buttonlink
                            onClick={() => history.push({
                                pathname: "/B2B",
                                state: {
                                    caseStudy: true
                                },
                            })}
                            Lasticon={<BsArrowDown size={23} />}
                            Text="View Case Studies"
                            className="flex items-center px-3 justify-between mt-3 rounded-full lg:w-56 md:w-60 w-full Poppins white bg--primary hover:color-white hover:bg--primary focus:bg--primary hover:opacity-70 h-11"
                        />
                    </div>
                    <div className="absolute right-0 top-0 lg:block hidden">
                        <svg
                            width="214"
                            height="214"
                            viewBox="0 0 214 214"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_572_2955)">
                                <path
                                    d="M90.1865 29.3941L85.6539 81.6878L33.3602 77.1551C20.2868 76.0219 9.63191 84.9771 8.49874 98.0505C7.36558 111.124 16.3208 121.779 29.3942 122.912L81.6878 127.445L77.1551 179.738C76.022 192.812 84.9771 203.467 98.0506 204.6C111.124 205.733 121.779 196.778 122.912 183.704L127.445 131.411L179.738 135.943C192.812 137.077 203.467 128.121 204.6 115.048C205.733 101.975 196.778 91.3197 183.704 90.1865L131.411 85.6538L135.943 33.3602C137.077 20.2868 128.121 9.63187 115.048 8.49871C101.975 7.36554 91.3197 16.3207 90.1865 29.3941Z"
                                    fill="#B5E2FF"
                                    fillOpacity="0.4"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_572_2955">
                                    <rect
                                        width="196.836"
                                        height="196.836"
                                        fill="white"
                                        transform="translate(0 196.101) rotate(-85.0461)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="mt-28 -mb-36">
                    <Knowledgepocket isImageHide={true} />
                </div>
            </div>
        </>
    );
};

export default CommunityPage;
