import {
  Noconnection,
  Postfooter,
  Posttags,
  ProfileAvatar,
  Discussionpost,
  IsLoader, Signinpopup, DraftEditor,
} from "components";
import React, { Component } from "react";
import GET_QUESTION_DETAIL from "../../../graphql/queries/getQuestionBySlug";
import { Query } from "@apollo/client/react/components";
import QuickActions from "../../allcomponents/quickActions";
import client from "../../../graphql/client";
import CREATE_QUESTION_ANSWER_MUTATION from "../../../graphql/mutations/createQuestionAnswer";
import CREATE_QUESTION_ANSWER_COMMENT_MUTATION from "../../../graphql/mutations/createQuestionAnswerComment";
import UPDATE_ANSWER_COMMENT_MUTATION from "../../../graphql/mutations/UpdateAnswerOrComment";
import AnswerFooter from "../answerfooter";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import Auth from "../../../Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagenotfound from "../../allcomponents/pagenotfound";
const auth = new Auth();

class postdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolledBottom: false,
      textInputValue: "",
      loadData: false,
      redirect: "/discussions",
      answer_id: 0,
      is_reply: true,
      is_comment: false,
      loading: false,
      username: "",
      rows: 3,
      editingObject: { type: null, id: null, text: null }
    };
    this.goToComment = React.createRef();
    this.openSignIn = React.createRef();
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      loadData: false,
      loading: false,
    });
  }

  handleInputChange = (value) => {
    auth.isAuthenticated() ?
      this.setState({
        textInputValue: value,
      })
      : this.openPopupModal()
  };

  handleCommentInputChange = (key, value) => {
    auth.isAuthenticated() ?
      this.setState({
        ...this.state,
        answer_id: key,
        textInputValue: value,
      }
        // , function() {
        //   this.goToComment.current.scrollIntoView();
        // }
      ) : this.openPopupModal()
  };

  handleOnSubmitComment = async (question_id) => {
    const { textInputValue, answer_id } = this.state;

    this.setState({
      loading: true,
    });

    if (textInputValue.trim().replace(/<[^>]*>/g, '').length < 5 || !question_id) {
      this.setState({
        loading: false,
      });
      this.notify("Comment must be at least 6 characters", "error");
      return;
    }

    try {
      const response = await client.mutate({
        mutation: CREATE_QUESTION_ANSWER_COMMENT_MUTATION,
        variables: {
          question_id: parseInt(question_id),
          answer_id: parseInt(answer_id),
          reply: textInputValue,
        },
      });

      if (response.data?.createAnswerComment.id) {
        if (this.props.authUserSocket?.connected) {
          this.props.authUserSocket?.emit("notify", response?.data?.createAnswerComment?.questions?.user_id);
        }
        this.setState((prevState) => ({
          loadData: true,
          loading: false,
          textInputValue: "",
          is_reply: true,
          is_comment: false,
        }), () => {
          this.editorRef.current.clearEditor();
        });
      }
    } catch (error) {
      console.log("QUESTION_ANSWER_COMMENT", error);
    }
  };


  UpdateAnswerOrCommentMutation = async () => {
    const { textInputValue, editingObject } = this.state;

    if (textInputValue === editingObject.text) {
      this.notify("Nothing Has Been Changed", "error");
      return;
    }

    if (textInputValue.trim().replace(/<[^>]*>/g, '').length < 5) {
      this.notify("At least 6 characters required", "error");
      return;
    }

    const variables = {
      dataType: editingObject.type,
      id: parseInt(editingObject.id),
      text: textInputValue,
    };

    try {
      const response = await client.mutate({
        mutation: UPDATE_ANSWER_COMMENT_MUTATION,
        variables,
      });

      if (!response.data?.updateAnswerOrComment?.error) {
        this.setState((prevState) => ({
          loadData: true,
          loading: false,
          textInputValue: "",
          is_reply: true,
          is_comment: false,
          editingObject: { type: null, id: null, text: null },
        }), () => {
          this.editorRef.current.clearEditor();
        });
      }
    } catch (error) {
      console.log("QUESTION_ANSWER_COMMENT", error);
    }
  };


  handleOnSubmit = async (question_id) => {
    this.setState({
      loading: true,
    });
    const plainText = this.htmlToText(this.state.textInputValue);

    // Check if the plain text contains only whitespace characters
    const isAllSpaces = /^\s*$/.test(plainText);
    if (isAllSpaces) {
      this.setState({
        loading: false,
      });
      this.notify("Can't Submit Spaces Only", "error");
    } else if (plainText.length < 6) {
      this.setState({
        loading: false,
      });
      this.notify("Answer must be at least 6 characters", "error");
    }
    else {
      try {
        const response = await client.mutate({
          mutation: CREATE_QUESTION_ANSWER_MUTATION,
          variables: {
            question_id: parseInt(question_id),
            reply: this.state.textInputValue,
          },
        });

        if (response.data?.createAnswer?.id) {
          if (this.props.authUserSocket?.connected) {
            this.props.authUserSocket?.emit("notify", response?.data?.createAnswer?.questions?.user_id);
          }
          this.setState({
            loading: false,
            loadData: true,
            textInputValue: "",
            answer_id: 0,
          }, () => {
            this.setState({
              loading: false,
            });
            this.editorRef.current.clearEditor();
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  notify = (message = "", type = "error") => {
    const toastOptions = {
      position: "bottom-right",
    };

    type === "error" ? toast.error(message, toastOptions) : toast.success(message, toastOptions);
  };

  handleBackToQuestion = () => {
    auth.isAuthenticated() ?
      this.setState({
        is_reply: true,
        is_comment: false,
      }, function () {
        this.goToComment.current.scrollIntoView();
      }) : this.openPopupModal()
  };

  goToCommentScroll = (answer_id) => {
    if (answer_id !== 0) {
      this.setState({
        is_reply: false,
        is_comment: true,
        answer_id: answer_id,
        editingObject: { type: null, id: null, text: null }
      }
        // , function() {
        //   this.goToComment.current.scrollIntoView();
        //   this.editorRef.current.clearEditor();
        // }
      );
    }
  };

  openPopupModal = () => {
    this.openSignIn.current.openPopup();
  };

  updateObjHandler = (obj, answers, isReply) => {
    if (isReply) {
      this.setState({ is_reply: true });
    }
    else {
      this.setState({ is_reply: false });
    }
    if (obj) {
      this.setState({ editingObject: obj, textInputValue: obj?.text }, function () {
        //it will focus on last comment or last reply
        const lastAnswer = answers[answers.length - 1];
        if (lastAnswer && lastAnswer.comments && lastAnswer.comments.data.length !== 0) {
          const lastComment = lastAnswer.comments.data[lastAnswer.comments.data.length - 1];
          document.getElementById(`a_${lastComment.id}`).scrollIntoView();
        } else if (lastAnswer) {
          document.getElementById(`${lastAnswer.id}`).scrollIntoView();
        }

        // this.goToComment.current.scrollIntoView();
        // window.scrollTo(500, 1000);
        this.editorRef.current.setEditorState();
      })
    } else {

      const lastAnswer = answers[answers.length - 1];
      if (lastAnswer && lastAnswer.comments && lastAnswer.comments.data.length !== 0) {
        const lastComment = lastAnswer.comments.data[lastAnswer.comments.data.length - 1];
        document.getElementById(`a_${lastComment.id}`).scrollIntoView();
      } else if (lastAnswer) {
        document.getElementById(`${lastAnswer.id}`).scrollIntoView();
      }

      // this.goToComment.current.scrollIntoView();
      // window.scrollTo(500, 1000);
      this.editorRef.current.setEditorState();
    }

  }
  htmlToText(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }
  render() {

    const editorStyle = {
      width: '900px',
      height: '250px',
      overflow: 'auto',
    };

    const wrapperStyle = {
      height: '250px',
      overflow: 'hidden',
    };

    let variables = {};
    variables.slug = this.props.slug;
    const authenticated = auth.isAuthenticated();

    const notFoundDiscussion = {
      title: "Discussion Not Found!",
      text: "It appears that current discussion is currently unavailable.",
      btn: "Explore Other Discussions",
      url: "/discussions"
    }

    return (
      <div>
        <ToastContainer />
        <Query query={GET_QUESTION_DETAIL} variables={variables} key={this.props.slug} fetchPolicy="cache-and-network">
          {({ loading, error, data, fetchMore, refetch }) => {
            if (loading) {
              return <Discussionpost />;
            }
            if (error) {
              return (
                <div>
                  {authenticated ? (
                    <>
                      <Noconnection />
                    </>
                  ) : (
                    <>
                      <div style={{ fontSize: 19 }}>
                        <Alert severity="warning">
                          <AlertTitle>
                            <strong>Anonymous User</strong>
                          </AlertTitle>
                          Please{" "}
                          <button
                            onClick={() => this.setState({ openModal: true })}
                            className={"primary"}>
                            Login
                          </button>{" "}
                          to connect with other Procurement practitioners and
                          participate in discussion.
                        </Alert>
                      </div>
                    </>
                  )}
                </div>
              );
            }
            if (this.state.loadData) {
              this.setState({ loadData: false })
              refetch();
            }
            if (this.props.deletedQuestionsAnswer > 0) {
              refetch();
              this.props.handleDeleteQuestionAnswer(0);
            }
            if (this.props.deletedQuestions > 0) {
              if (this.state.redirect) {
                this.props.handleDeleteQuestion(0);
                return <Redirect to={this.state.redirect} />;
              }
            }
            if (
              !data ||
              data?.questionWithSlug?.question === null ||
              data?.questionWithSlug?.question === undefined
            ) {
              return <Pagenotfound data={notFoundDiscussion} />;
            }
            if (data !== undefined) {
              let question = data.questionWithSlug;

              function makeUrlsClickable(text) {
                const urlRegex = /(http[s]?:\/\/[^\s<]+)/g;
                const transformedText = text.replace(urlRegex, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
                return transformedText;
              }
              // let htmlString = this.state.textInputValue;
              return (

                <div className="border pb-3 rounded-xl">
                  <div className="sm:p-4 xs:p-2 important:pb-0">
                    {question?.users ? (
                      <div className="flex items-center justify-between" key={question.id}>
                        <div className="flex items-center">
                          <ProfileAvatar
                            singleUser={question?.users}
                            key={question?.users?.id}
                            className="object-cover w-20 h-20 mr-3 rounded-full"
                            sizeStyle={{ height: 48, width: 48 }}
                            platform={"PL"}
                          />
                          <div>
                            <p className="text-lg Medium lg:text-xl">
                              {question?.users?.firstname}{" "}
                              {question?.users?.lastname}
                            </p>
                            <p className="Light gray fs-12">
                              @{question?.users?.username}{" "}
                            </p>
                          </div>
                        </div>
                        {
                          authenticated && <QuickActions data={question} type="question" key={question.id} history={this.props.history} />
                        }
                      </div>
                    ) : null}
                    <div className="mt-2 mb-3 divider" />
                    <b className="mt-5 cursor-pointer">
                      {question?.description}
                    </b>
                    {question?.question !== null ? (
                      <div className="my-3 leading-normal text-color Light" >
                        <div className="list-disc" dangerouslySetInnerHTML={{ __html: makeUrlsClickable(question?.question) }}>
                        </div>
                      </div>
                    ) : null}

                    <div className={'grid grid-cols-3 gap-2'}>
                      {question.attachments.length > 0
                        ? question.attachments.map((attachment) => (
                          <img
                            alt="Description"
                            src={attachment.public_image}
                            key={attachment.id}
                            width={300}
                            className={'rounded-2xl'}
                          />
                        ))
                        : null}
                    </div>
                    <div className="my-3">
                      {question.tags.length > 0 ? (
                        <Posttags tags={question.tags} key={this.props.slug} />
                      ) : null}
                    </div>
                    <div className="mt-4 divider" />
                    <div className="my-3">
                      <Postfooter
                        question={question}
                        type="question"
                        answers={question.answers}
                        likes={question.likes}
                        key={this.props.slug}
                        callBackResponse={(obj) => this.updateObjHandler(obj, question?.answers?.data, true)}
                      />
                    </div>
                  </div>
                  <div className="divider !divide-gray-900 w-2" />
                  {question?.answers?.data.length > 0
                    ? question?.answers?.data?.map((answer) => (
                      <>
                        <div className="p-4 pb-0 ml-10" id={answer?.id} key={answer.id}>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <ProfileAvatar platform={"PL"}
                                singleUser={answer?.users}
                                className="object-cover w-16 h-16 mr-3 rounded-full" key={answer?.users.id}
                              />
                              <div>
                                <p className="text-xl Medium">
                                  {answer?.users?.firstname}{" "}
                                  {answer?.users?.lastname}{" "}
                                </p>
                                <p className="Light gray">
                                  @{answer?.users?.username}{" "}
                                </p>
                              </div>
                            </div>
                            {
                              authenticated &&
                              <QuickActions
                                data={answer && answer}
                                type="answer"
                                key={answer?.id}
                                callBackResponse={(obj) => this.updateObjHandler(obj, question?.answers?.data)}
                              />
                            }
                          </div>
                          <div className="my-4" />
                          <div className="leading-normal text-color whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{ __html: answer.answer }}
                          />
                          <div className="mt-4 divider" />
                          <div className="my-3 post-footer">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-x-10">
                                <div className="flex items-center gap-x-3">
                                  <AnswerFooter
                                    question={question}
                                    answer={answer}
                                    comments={answer.comments}
                                    likes={answer.likes}
                                    isAuthenticated={authenticated}
                                    unathenticated={(v) => v && this.openPopupModal()}
                                    key={answer?.id}
                                  />
                                </div>
                                <div
                                  className={"flex cursor-pointer"}
                                  onClick={() => {
                                    this.goToCommentScroll(answer.id);
                                    this.updateObjHandler(null, question?.answers.data);
                                  }
                                  }>
                                  <p className="gray Light ">Add Comment</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="divider" />
                          {answer?.comments?.data?.map((data) => (
                            <div className="ml-10" id={`a_${data.id}`} key={data.id}>
                              <div className="flex items-start justify-between mt-3">
                                <div className="flex ">
                                  <ProfileAvatar
                                    platform={"PL"}
                                    singleUser={data.users}
                                    className="object-cover w-12 h-12 mr-3 rounded-full"
                                    key={data?.users.id}
                                  />
                                  <div>
                                    <div>
                                      <p className="text-lg Medium">
                                        {data.users.firstname}{" "}
                                        {data.users.lastname}{" "}
                                      </p>
                                      <p className="text-sm Light gray">
                                        @{data.users.username}{" "}
                                      </p>
                                    </div>
                                    <div className="my-3" />
                                    <div className="list-disc leading-normal text-color whitespace-pre-wrap"
                                      dangerouslySetInnerHTML={{
                                        __html: data.comment
                                      }}
                                    />
                                  </div>
                                </div>
                                {
                                  authenticated && <QuickActions data={data} type="comment" key={data.comment.id} callBackResponse={(obj) => this.updateObjHandler(obj, question?.answers?.data)} />
                                }
                              </div>
                              <div className="mt-3 divider" />
                            </div>
                          ))}
                        </div>
                      </>
                    ))
                    : null}
                  <div className="px-4" id="goToScroll" ref={this.goToComment}>
                    <div className="flex items-end flex-col gap-4">
                      <div className="w-full rounded-xl">
                        <DraftEditor path={'question_details'}
                          ref={this.editorRef}
                          title={this.state.editingObject}
                          editorStyle={editorStyle}
                          wrapperStyle={wrapperStyle}
                          placeholder={
                            this.state.is_reply
                              ? `Add a comment...`
                              : "Add reply on comment..."
                          }
                          className={'mt-5'}
                          onChange={(html) => {
                            this.state.is_reply
                              ? this.handleInputChange(html)
                              : this.handleCommentInputChange(this.state.answer_id, html)
                          }}
                          parentCallback={(v) => this.notify('Not allowed to upload media')}
                        />
                        {/*<textarea
                          ref={this.goToComment}
                          autoFocus={true}
                          rows={this.state.rows}
                          placeholder={
                            this.state.is_reply
                              ? `Add a comment...`
                              : "Add reply on comment..."
                          }
                          className="w-full text-dark h-20 mt-2 focus-visible:border-0 focus-visible:outline-none "
                          id={this.state.answer_id}
                          key={this.state.answer_id}
                          onChange={(event) => {
                            this.state.is_reply
                              ? this.handleInputChange(event)
                              : this.handleCommentInputChange(event)
                          }}
                          name={
                            this.state.is_reply
                              ? "questionAnswer"
                              : `answerComment${this.state.answer_id}`
                          }
                          value={
                            this.state.is_reply
                              ? this.state.questionAnswer
                              : this.state.comment
                          }
                          maxLength={1000}
                          style={{resize : 'none'}}
                        />*/}
                      </div>
                      <button
                        disabled={this.state.loading}
                        onClick={() => {
                          {
                            authenticated ?
                              this.state.is_reply && this.state.editingObject?.type === null
                                ? this.handleOnSubmit(question.id)
                                : this.state.editingObject?.type !== null ? this.UpdateAnswerOrCommentMutation()
                                  : this.handleOnSubmitComment(question.id)
                              : this.openPopupModal()
                          }
                        }}
                        className="flex items-center justify-center px-4 rounded-xl h-11 bg--primary text-white">
                        {this.state.loading ? (
                          <IsLoader className="h-full" white={true} />
                        ) : (
                          this.state.is_reply && this.state.editingObject?.type === null
                            ? "Post Comment"
                            : this.state.editingObject?.type !== null ? this.state.editingObject?.type === 'answer' ? "Update Comment" : "Update Comment Reply"
                              : "Post comment Reply"
                          // <HiOutlineArrowUp color="white" size={20} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          }}
        </Query>
        <Signinpopup ref={this.openSignIn} protect={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  deletedQuestions: state.DiscussionSearchReducer.deletedQuestions,
  deletedQuestionsAnswer: state.DiscussionSearchReducer.deletedQuestionsAnswer,
  authUserInformation: state.AuthUserReducer.authUserInformation,
  authUserSocket: state.AuthUserReducer.authUserSocket,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteQuestion: (id) =>
      dispatch({ type: "REMOVE_QUESTION", payload: id }),
    handleDeleteQuestionAnswer: (id) =>
      dispatch({ type: "REMOVE_QUESTION_ANSWER", payload: id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(postdetail);
