import loadable from "@loadable/component";
import { componentDelay } from "helpers";
import { Mainpageskeleton } from "components";

// auth screens
import LoginScreen from "./Auth/LoginScreen";
import RegisterScreen from "./Auth/RegisterScreen";
import ForgotpswdScreen from "./Auth/ForgotpswdScreen";
import ActivateUserAccount from "./Auth/ActivateUserAccount";

// HomeScreen screens
const HomeScreen = loadable(
    () => componentDelay(componentDelay(import("./HomeScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const OfferingsScreen = loadable(
    () => componentDelay(componentDelay(import("./OfferingsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// classes screen
const ProcureHomeScreen = loadable(
    () => componentDelay(componentDelay(import("./ProcureHomeScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ClassDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./ClassDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ClassesAllScreen = loadable(
    () => componentDelay(componentDelay(import("./ClassesAllScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ClassesMyScreen = loadable(
    () => componentDelay(componentDelay(import("./ClassesMyScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const ChapterDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./ChapterDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const PricingScreen = loadable(
    () => componentDelay(componentDelay(import("./PricingScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// NewsletterScreen events
const NewsletterScreen = loadable(
    () => componentDelay(componentDelay(import("./NewsletterScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const NewsArticleDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./NewsArticleDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// upcoming events
const EventsScreen = loadable(
    () => componentDelay(componentDelay(import("./EventsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const EventsAllScreen = loadable(
    () => componentDelay(componentDelay(import("./EventsAllScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const EventsDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./EventsDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// additional resource
const AdditionalResourceScreen = loadable(
    () => componentDelay(componentDelay(import("./ResourceAdditionalScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// additional resource
const ResourceDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./ResourceDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// additional resource
const ResourceAllScreen = loadable(
    () => componentDelay(componentDelay(import("./ResourceAllScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// SolutionScreen
const SolutionScreen = loadable(
    () => componentDelay(componentDelay(import("./SolutionScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ExpertScreen = loadable(
    () => componentDelay(componentDelay(import("./ExpertScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// Discussins
const RFQsDiscussionScreen = loadable(
    () => componentDelay(componentDelay(import("./RFQsDiscussionScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const DiscussionsScreen = loadable(
    () => componentDelay(componentDelay(import("./DiscussionsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const DiscussionDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./DiscussionDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const RecommendationDetailScreen = loadable(
    () =>
        componentDelay(componentDelay(import("./RecommendationDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const PostDiscussionScreen = loadable(
    () => componentDelay(componentDelay(import("./PostDiscussionScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// job listings
const JobslistingScreen = loadable(
    () => componentDelay(componentDelay(import("./JobslistingScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// profile screens
const Profile = loadable(
    () => componentDelay(componentDelay(import("./ProfileScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const SellerProfileScreen = loadable(
    () =>
        componentDelay(componentDelay(import("./Seller/SellerProfileScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const SellersScreen = loadable(
    () => componentDelay(componentDelay(import("./Seller/SellersScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const SellerProductDetail = loadable(
    () =>
        componentDelay(componentDelay(import("./Seller/SellerProductDetail"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ProfileCompanyScreen = loadable(
    () => componentDelay(componentDelay(import("./ProfileCompanyScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const CategoryExpertiseScreen = loadable(
    () => componentDelay(componentDelay(import("./CategoryExpertiseScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ChangePswdScreen = loadable(
    () => componentDelay(componentDelay(import("./ChangePswdScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const UserProfileScreen = loadable(
    () => componentDelay(componentDelay(import("./UserProfileScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const UserPaymentHistoryScreen = loadable(
    () => componentDelay(componentDelay(import("./UserPaymentHistoryScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const UserLoginHistoryScreen = loadable(
    () => componentDelay(componentDelay(import("./UserLoginHistoryScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// PaymentPageScreen
const PaymentPageScreen = loadable(
    () => componentDelay(componentDelay(import("./PaymentPageScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// TeamDetailScreen
const TeamDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./TeamDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// ServicesScreens
const ConsultingScreen = loadable(
    () => componentDelay(componentDelay(import("./Services/ConsultingScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const DevelopmentLeagueScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/DevelopmentLeagueScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);

const MediaSolutionsScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/MediaSolutionsScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);

const OutsourcingScreen = loadable(
    () =>
        componentDelay(componentDelay(import("./Services/OutsourcingScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const ResearchScreen = loadable(
    () => componentDelay(componentDelay(import("./Services/ResearchScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const TrainingScreen = loadable(
    () => componentDelay(componentDelay(import("./Services/TrainingScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const EventsUpdateScreen = loadable(
    () =>
        componentDelay(componentDelay(import("./Services/EventsUpdateScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const SubscribersTrainingScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/SubscribersTrainingScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);
const DigitalProcurementScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/DigitalProcurementScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);
const HappySubscribersScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/HappySubscribersScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ProcurementContentScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/ProcurementContentScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);
const ConferencePassesScreen = loadable(
    () =>
        componentDelay(
            componentDelay(import("./Services/ConferencePassesScreen"))
        ),
    {
        fallback: <Mainpageskeleton />,
    }
);

// BlogDetailScreen;

const BlogDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./BlogDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// FaqsScreen;
const FaqsScreen = loadable(
    () => componentDelay(componentDelay(import("./FaqsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// PrivacyPolicyScreen
const PrivacyPolicyScreen = loadable(
    () => componentDelay(componentDelay(import("./PrivacyPolicyScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const DisclaimerScreen = loadable(
    () => componentDelay(componentDelay(import("./DisclaimerScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const TermsServiceScreen = loadable(
    () => componentDelay(componentDelay(import("./TermsServiceScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// ContactUsScreen  container
const ContactUsScreen = loadable(
    () => componentDelay(componentDelay(import("./ContactUsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// LeaderboardScreen  container
const LeaderboardScreen = loadable(
    () => componentDelay(componentDelay(import("./LeaderboardScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// NotificationScreen  container
const NotificationScreen = loadable(
    () => componentDelay(componentDelay(import("./NotificationScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// DeactivateScreen  container
const DeactivateScreen = loadable(
    () => componentDelay(componentDelay(import("./DeactivateScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// Industries Screen
const IndustriesScreen = loadable(
    () => componentDelay(componentDelay(import("./IndustriesScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const IndustriesSolutionsScreen = loadable(
    () => componentDelay(componentDelay(import("./IndustriesSolutionsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const IndustrySolutionsB2BScreen = loadable(
    () => componentDelay(componentDelay(import("./IndustrySolutionsB2BScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//Buyer Screen
const BuyerScreen = loadable(
    () => componentDelay(componentDelay(import("./BuyerScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//Module Screen
const ModuleScreen = loadable(
    () => componentDelay(componentDelay(import("./ModuleScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//Community
const CommunityScreen = loadable(
    () => componentDelay(componentDelay(import("./CommunityScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//GrowthSolutions
const GrowthSolutionScreen = loadable(
    () => componentDelay(componentDelay(import("./GrowthSolutionScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//Blog
const BlogScreen = loadable(
    () => componentDelay(componentDelay(import("./BlogScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//SubmitForm
const SubmitForm = loadable(
    () => componentDelay(componentDelay(import("./SubmitForm"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//buyer Learn
const BuyerLearnScreen = loadable(
    () => componentDelay(componentDelay(import("./BuyerLearnScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//success stories
const SuccessStoryScreen = loadable(
    () => componentDelay(componentDelay(import("./SuccessStoryScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);
const SuccessStorySellSideScreen = loadable(
    () => componentDelay(componentDelay(import("./SuccessStorySellSideScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//hire expert
const HireExpertScreen = loadable(
    () => componentDelay(componentDelay(import("./HireExpertScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//Training Certificate
const TrainingCertificateScreen = loadable(
    () => componentDelay(componentDelay(import("./TrainingCertificateScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//Submit Offer
const SubmitOfferScreen = loadable(
    () => componentDelay(componentDelay(import("./SubmitOfferScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//TrainnersScreen
const TrainnersScreen = loadable(
    () => componentDelay(componentDelay(import("./TrainnersScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//AboutUs
const AboutUsScreen = loadable(
    () => componentDelay(componentDelay(import("./AboutUsScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// KnowLedgeBank
const KnowLedgeBankScreen = loadable(
    () => componentDelay(componentDelay(import("./KnowLedgeBankScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const KnowLedgeBankDetailScreen = loadable(
    () => componentDelay(componentDelay(import("./KnowledgeBankDetailScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// JobCentralScreen
const JobCentralScreenScreen = loadable(
    () => componentDelay(componentDelay(import("./JobCentralScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

const EmailComposerScreen = loadable(
    () => componentDelay(componentDelay(import("./EmailComposerScreen"))),
    {
        fallback: <Mainpageskeleton />,
    });

// BuyerSourceScreen
const BuyerSourceScreen = loadable(
    () => componentDelay(componentDelay(import("./BuyerSourceScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// JoinResearchScreen
const JoinResearchScreen = loadable(
    () => componentDelay(componentDelay(import("./JoinResearchScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// AppliedJobScreen
const AppliedJobScreen = loadable(
    () => componentDelay(componentDelay(import("./AppliedJobScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

//CompanyScreen
const CompanyScreen = loadable(
    () => componentDelay(componentDelay(import("./CompanyScreen"))),
    {
        fallback: <Mainpageskeleton />,
    }
);

// Industries Screen
export {
    // JobCentralScreenScreen,
    JobCentralScreenScreen,
    // auth screens
    LoginScreen,
    RegisterScreen,
    ForgotpswdScreen,
    ActivateUserAccount,
    // HomeScreen screens
    HomeScreen,
    //Offerings Screen
    OfferingsScreen,
    // classes screens
    ProcureHomeScreen,
    ClassDetailScreen,
    ClassesAllScreen,
    ClassesMyScreen,
    ChapterDetailScreen,
    PricingScreen,
    // upcoming events
    EventsScreen,
    EventsAllScreen,
    EventsDetailScreen,
    // AdditionalResourceScreen
    AdditionalResourceScreen,
    ResourceDetailScreen,
    ResourceAllScreen,
    // NewsletterScreen
    NewsletterScreen,
    NewsArticleDetailScreen,
    // DiscussionsScreen
    DiscussionsScreen,
    DiscussionDetailScreen,
    PostDiscussionScreen,
    RecommendationDetailScreen,
    RFQsDiscussionScreen,
    // SolutionScreen
    SolutionScreen,
    // ExpertScreen
    ExpertScreen,
    // job listings
    JobslistingScreen,
    // profile screens
    Profile,
    ProfileCompanyScreen,
    CategoryExpertiseScreen,
    ChangePswdScreen,
    UserProfileScreen,
    UserPaymentHistoryScreen,
    SellerProfileScreen,
    SellersScreen,
    SellerProductDetail,
    UserLoginHistoryScreen,
    // PaymentPageScreen
    PaymentPageScreen,
    TeamDetailScreen,
    // ServicesScreen
    ConsultingScreen,
    DevelopmentLeagueScreen,
    MediaSolutionsScreen,
    OutsourcingScreen,
    ResearchScreen,
    TrainingScreen,
    EventsUpdateScreen,
    SubscribersTrainingScreen,
    DigitalProcurementScreen,
    HappySubscribersScreen,
    ProcurementContentScreen,
    ConferencePassesScreen,
    // BlogDetailScreen
    BlogDetailScreen,
    // FaqsScreen
    FaqsScreen,
    // PrivacyPolicyScreen
    PrivacyPolicyScreen,
    DisclaimerScreen,
    TermsServiceScreen,
    // ContactUsScreen
    ContactUsScreen,
    // LeaderboardScreen
    LeaderboardScreen,
    //   NotificationScreen,
    NotificationScreen,
    // DeactivateScreen,
    DeactivateScreen,
    //Industries
    IndustriesScreen,
    IndustriesSolutionsScreen,
    IndustrySolutionsB2BScreen,
    //Buyer
    BuyerScreen,
    BuyerLearnScreen,
    //Modules
    ModuleScreen,
    //Community
    CommunityScreen,
    //GrowthSolutions
    GrowthSolutionScreen,
    //Blog
    BlogScreen,
    //SubmitForm
    SubmitForm,
    //success stories
    SuccessStoryScreen,
    SuccessStorySellSideScreen,
    //Hire Expert
    HireExpertScreen,
    //Training
    TrainingCertificateScreen,
    //Submit Offer
    SubmitOfferScreen,
    //TrainnersScreen
    TrainnersScreen,
    //AboutUsScreen
    AboutUsScreen,
    // KnowLedgeBankScreen
    KnowLedgeBankScreen,
    KnowLedgeBankDetailScreen,
    EmailComposerScreen,
    // BuyerSourceScreen
    BuyerSourceScreen,
    // JoinResearchScreen
    JoinResearchScreen,
    AppliedJobScreen,
    CompanyScreen
};
