import { gql } from "@apollo/client";

export default gql`
    mutation verifyResetToken($email: String!,$reset_token:String!) {
        verifyResetToken(email: $email,reset_token:$reset_token){
            message
            status
        }
    }
`;
