import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Listingsskeleton } from "components";

export default function Postskeleton() {
  return (
    <div className="container">
      <div className="grid grid-cols-7 gap-4">
        <div className="col-span-2">
          <Listingsskeleton />
        </div>
        <div className="col-span-5">
          <div className="p-3 pb-0 bg-white rounded-lg shadow-sm">
            <div className="flex items-center mb-3">
              <Skeleton
                animation="pulse"
                variant="circle"
                width={52}
                height={52}
              />
              <div className="flex items-center justify-between flex-grow">
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={240}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={50}
                  />
                </div>
              </div>
            </div>
            <Skeleton variant="rect" height={6} className="rounded-full" />
            <Skeleton variant="rect" height={6} className="my-2 rounded-full" />
            <Skeleton variant="rect" height={6} className="rounded-full" />
            <div className="-mx-4">
              <Skeleton variant="rect" height={250} className="my-3" />
            </div>
            <div className="flex items-center justify-between flex-grow my-3">
              <div className="ml-3">
                <Skeleton
                  animation="pulse"
                  variant="rect"
                  height={8}
                  className="rounded-full"
                  width={100}
                />
              </div>
              <div className="flex items-center justify-between">
                <Skeleton
                  variant="rect"
                  height={8}
                  className="rounded-full"
                  width={100}
                />
                <Skeleton
                  variant="rect"
                  height={8}
                  className="ml-4 rounded-full"
                  width={100}
                />
              </div>
            </div>
            <div className="py-4 border-t">
              <div className="flex items-center justify-between">
                <Skeleton
                  variant="rect"
                  height={8}
                  className="rounded-full"
                  width={100}
                />
                <Skeleton
                  variant="rect"
                  height={8}
                  className="rounded-full"
                  width={100}
                />
                <Skeleton
                  variant="rect"
                  height={8}
                  className="ml-4 rounded-full"
                  width={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
