import { Buttonlink, Signinpopup, Socialshare } from "components";
import React, { useState, useEffect } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { IoMdShareAlt } from "react-icons/io";
import moment from "moment";
import ClassPreviewSlider from "../classpreviewslider";
import attendCourseMutation from "graphql/mutations/attendCourseMutation";
import client from "../../../../graphql/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ENROLL_COURSE from "graphql/mutations/enrollCourseMutation";
import { connect } from "react-redux";
import {Alert, AlertTitle, Box} from "@mui/material";
import { Link } from "react-router-dom";
import Auth from "../../../../Auth";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from "react-router-dom";

const auth = new Auth();

const DetailHead = (props) => {
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    type: props.type,
    event: props.event,
    new_user:true
  });
  const [openModal, setopenModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const history = useHistory();

  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 10000));
  }

  useEffect(() => {
    if (state.firstname !== "" && state.lastname !== "" && state.email !== "") {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  }, [state.firstname, state.lastname, state.email]);

  const handleEnroll = () => {
    let type = props.type;
    let item = props.event;
    let type2 = "";
    if (type === "Upcoming") {
      type2 = "upcoming";
    } else if (type === "Newest") {
      type2 = "newest";
    } else if (type === "Popular") {
      type2 = "popular";
    } else if (type === "Featured") {
      type2 = "featured";
    }

    client.mutate({
      mutation: ENROLL_COURSE,
      variables: { course_id: parseInt(item.id) },

      optimisticResponse: {
        __typename: "Mutation",
        course_enrollment: {
          __typename: "Response",
          message: "",
          error: "",
        },
      },
      // update: (cache, { data: { getClass } }) => {
      //   // let item = props.navigation.getParam("item");
      //
      //   let data = cache.readQuery({
      //     query: GET_CLASS_BY_ID,
      //     variables: { id: item.id,cursor:1 },
      //   });
      //   // let course = data.courses.data.find(data => data.id === item.id);
      //   // data.is_enroll = "true";
      //   // item.is_enroll = true;
      //
      //   cache.writeQuery({ query: GET_CLASS_BY_ID, data ,variables: { id: item.id,cursor:1 }});
      // },
    });
    props.handleClassReset(true);
    setTimeout(() => {
      window.location.href = window.location.href;
    }, 1000);
  };

  const doNothing = () => {};

  const handleAttendEvent = (id) => {
    if (state.firstname !== "" && state.lastname !== "" && state.email !== "") {
      setLoading(true);

      client
        .mutate({
          mutation: attendCourseMutation,
          variables: {
            course_id: Number(id),
            email: state.email,
            firstname: state.firstname,
            lastname: state.lastname,
            new_user:state.new_user
          },
        })
        .then((res) => {
          setLoading(false);
          setState({firstname: "", lastname: "", email: "", new_user: true});
          notify("Form Submitted Successfully.", "success");
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      notify("Please fill in all the required fields.", "error");
    }
  };

  const notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    toast.success(message, { position: "bottom-right" });
  };

  let Stars = [];

  if (state.type === "Upcoming") {
    for (var i = 1; i <= 1; i++) {
      Stars.push(AiOutlineStar);
    }
  } else {
    let rating = props.event.course_feedback_avg;
    for (let i = 1; i <= 1; i++) {
      let Name = "";
      if (i <= rating) {
        Name = AiFillStar;
      } else if (i > rating && rating > i - 1) {
        Name = BsStarHalf;
      } else if (i > rating) {
        Name = AiOutlineStar;
      }
      Stars.push(Name);
    }
  }

  const { authUser } = props;
  const { event, type } = state;
  // console.log("event",event?.is_deactivated)
  // console.log("type",type)
  // console.log("state",state)
  let categoryName = [];
  event &&
    event.categories &&
    event.categories.data.map((category) => categoryName.push(category.name));
  let validate = true
  validate = !(state.firstname !== "" && state.lastname !== "" && state.email !== "");

  const isRegister = props?.event?.register ?? false;

  console.log(event)

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-col">
        <div className="bg-white p-10 space-y-3 rounded-[50px]" style={{boxShadow : '0px 4px 40px rgba(0, 0, 0, 0.1)'}}>
          <ClassPreviewSlider event={props.event} />
          {type && type !== "" ? (
            <>
              <div className="flex w-full h-full">
                <div className="w-full">
                  {type === "item_based" ? (
                    <>
                      <div className="w-full flex justify-between flex-wrap items-center">
                        <div className="flex items-center">
                          <p className="primary text-lg font-normal">Comments</p>
                          <p className="primary text-lg">
                            ({props.event.course_feedback_count})
                          </p>
                        </div>
                        <div className="flex items-center gap-4">
                          <p className="primary text-lg">Share</p>
                          <Socialshare
                             size={30}
                             url={new URL(`${process.env.REACT_APP_MAIN_URL}/share/product/${event?.id}`)}
                             title={event && event.course_name}
                             subject={event && event.course_name}
                             quote={event && event.course_name}
                             source={process.env.REACT_APP_WEB_URL}
                             via={process.env.REACT_APP_WEB_URL}
                             summary={{
                               __html: event && event.course_description,
                             }}
                             hashtags={categoryName}
                          />{" "}
                        </div>
                      </div>
                    </>
                  ) : authUser.is_pro ? (
                    <>
                      <div className="flex flex-wrap items-center justify-between gap-1">
                        <div className="flex flex-wrap items-center gap-1 my-2">
                          <p className="primary text-lg">Comments</p>
                          <p className="ml-1 primary text-lg">
                            ({event && event.course_feedback_count})
                          </p>
                        </div>
                        <div className="flex items-center gap-4">
                          <p className="primary text-lg">Share</p>
                          <Socialshare
                            size={30}
                            url={new URL(`${process.env.REACT_APP_MAIN_URL}/share/classes/${event?.id}`)}
                            title={event.course_name}
                            subject={event.course_name}
                            quote={event.course_name}
                            source={process.env.REACT_APP_WEB_URL}
                            via={process.env.REACT_APP_WEB_URL}
                            summary={{ __html: event.course_description }}
                            hashtags={categoryName}
                          />{" "}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full flex justify-between flex-wrap items-center">
                        <div className="flex items-center">
                          <p className="primary text-lg font-normal">Comments</p>
                          <p className="primary text-lg">
                            ({props.event.course_feedback_count})
                          </p>
                        </div>
                        <div className="flex items-center gap-4">
                          <p className="primary text-lg">Share</p>
                          <Socialshare
                            size={30}
                            url={new URL(`${process.env.REACT_APP_MAIN_URL}/share/classes/${event?.id}`)}
                            title={event.course_name}
                            subject={event.course_name}
                            quote={event.course_name}
                            source={process.env.REACT_APP_WEB_URL}
                            via={process.env.REACT_APP_WEB_URL}
                            summary={{ __html: event.course_description }}
                            hashtags={categoryName}
                          />{" "}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
              <>
                <div className="w-full flex justify-between flex-wrap items-center">
                  <div className="flex items-center">
                    <p className="primary text-lg font-normal">Comments</p>
                    <p className="primary text-lg">
                      ({props.event.course_feedback_count})
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <p className="primary text-lg">Share</p>
                    <Socialshare
                        size={30}
                        url={new URL(`${process.env.REACT_APP_MAIN_URL}/share/event/${event?.id}`)}
                        title={props.event && props.event.course_name}
                        subject={props.event && props.event.course_name}
                        quote={props.event && props.event.course_name}
                        source={process.env.REACT_APP_WEB_URL}
                        via={process.env.REACT_APP_WEB_URL}
                        summary={{
                          __html: props.event && props.event.course_description,
                        }}
                        hashtags={categoryName}
                    />{" "}
                  </div>
                </div>
            </>
          )}
        </div>
        <div className="mt-5">
          {type && type !== "" ? (
            <>
              <div className="flex w-full h-full">
                <div className="w-full">
                  {type === "item_based" ? (
                    <>
                      {event && event.is_enroll ? (
                        <Buttonlink
                          className="items-center justify-center w-full h-10 px-5 text-white border-0 rounded-full shadow-none bg--gray important:bg--gray Regular hover:bg--primary hover:opacity-70 focus:bg--primary"
                          onClick={(e) => {
                            window.scrollTo(0, 1500);
                          }}
                          Text={"Already Purchased"}
                        />
                      ) : (
                        <Buttonlink
                          className="flex items-center justify-center w-full h-10 px-5 text-white border-0 rounded-full shadow-none bg--primary Regular hover:bg--primary hover:text-white hover:opacity-70 focus:bg--primary"
                          to={{
                            pathname: `/payment`,
                            state: {
                              item: {
                                id: event && event.id,
                                price: event && event.course_objective,
                                name: event && event.course_name,
                                is_shippable: event && event.is_shippable,
                                data_type: event && event.data_type,
                              },
                              type: type,
                            },
                          }}
                          Text={`Get Premium Access In Just $${
                            event ? event.course_objective: "0"
                          }`}
                        />
                      )}
                    </>
                  ) : authUser.is_pro ? (
                    <>
                      <button
                        onClick={() => {
                          event && event.is_enroll
                            ? doNothing()
                            : handleEnroll();
                        }}
                        className="items-center justify-center w-full h-12 px-5 text-white border-0 rounded-full shadow-none bg--primary Regular hover:bg--primary hover:opacity-70 focus:bg--primary">
                        {event && event.is_enroll ? (
                          event?.is_completed ? 
                          <div className="cursor-pointer">
                            Download Certificate
                          </div>:
                          <div className="cursor-pointer" onClick={() => history.push('/my-classes')}>
                            Go to my courses
                          </div>
                        ) : (
                          "Enroll Now"
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <Buttonlink
                        to={{
                          pathname: `/pricing`,
                          state: false,
                        }}
                        className="flex items-center justify-center w-full h-12 px-5 text-white border-0 rounded-full shadow-none bg--primary hover:color-white Regular hover:bg--primary hover:opacity-70 focus:bg--primary"
                        Text="To access, Buy a plan"
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
              <>
              {event && event?.is_deactivated ? (
                <Alert severity="warning">
                  <AlertTitle className="Poppins text-lg">Event is closed</AlertTitle>
                  <p>
                    "You can either watch whole of this event by clicking on
                    play button on left"
                  </p>
                  <p>or</p>
                  <p>
                    "You can participate on other{" "}
                    <Link to={"/events"}>
                      <strong>Upcoming events</strong>
                    </Link>
                    "
                  </p>
                </Alert>
              ) : (
                <div className="grid gap-3 lg:grid-cols-2 md:grid-cols-2">
                  <div>
                    <input
                      placeholder="First name"
                      className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                      value={state.firstname}
                      onChange={(ev) =>
                        setState({ ...state, firstname: ev.target.value })
                      }
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Last name"
                      className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                      value={state.lastname}
                      onChange={(ev) =>
                        setState({ ...state, lastname: ev.target.value })
                      }
                    />
                  </div>
                  <div className="col-start-1 col-end-3">
                    <input
                      placeholder="Email"
                      className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                      value={state.email}
                      onChange={(ev) =>
                        setState({ ...state, email: ev.target.value })
                      }
                    />
                  </div>
                  {!auth.isAuthenticated() &&
                    <FormGroup>
                      <FormControlLabel
                          control={<Checkbox checked={state.new_user} onClick={() => {
                            setState({...state, new_user: !state.new_user})
                          }}/>} label="Create A New Account"/>
                    </FormGroup>
                  }
                </div>
              )}

              <div className="flex items-center mt-4">
                {loading ? (
                  <div className="flex justify-center items-center gap-x-2 w-full">
                    <div className="flex items-center justify-center w-full h-12 px-5 border-0 shadow-none bg--primary white hover:color-white Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full">
                      <div className="w-5 h-5 border-b-2 rounded-full animate-spin border-white-1000" />
                    </div>
                    {isRegister ? 
                    <a
                      href={props?.event?.register_url}
                      target="_blank"
                      rel="noreferrer"
                      className={`bg--primary hover:bg--primary white hover:color-white w-full flex items-center justify-center Regular hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5`}
                    >Register Here  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-3 w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                    </a>: null}
                  </div>
                ) : event && event.is_deactivated ? null : (
                  // auth.isAuthenticated() ?
                  <div className="flex justify-center gap-x-2 w-full">
                  <Buttonlink
                    disabled={validate}
                    className={`${
                        validate
                        ? "bg--gray hover:bg--gray"
                        : "bg--primary hover:bg--primary"
                    } white hover:color-white ${isRegister ? 'w-1/2':'w-full'} flex items-center justify-center Regular  hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5`}
                    Text={isRegister ? "Register Now": "Register"}
                    onClick={() => handleAttendEvent(event?.id ?? props.event?.id)}
                  />
                  {isRegister ? 
                  <a
                    href={props?.event?.register_url}
										target="_blank"
										rel="noreferrer"
                    className={`bg--primary hover:bg--primary white hover:color-white w-1/2 flex items-center justify-center Regular hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5`}
                  >Register Here  
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-3 w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                  </svg>
                  </a>: null}
                  </div>
                  // :
                  //     <Buttonlink
                  //         className="flex items-center justify-center w-full h-12 px-5 border-0 shadow-none bg--primary white hover:color-white Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                  //         Text="Register"
                  //         onClick={() =>setopenModal(true)}
                  //     />
                )}
              </div>
            </>
          )}
          {openModal ? (
            <Signinpopup
              open={openModal}
              closePopup={() => setopenModal(false)}
            />
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
  event: state.CourseReducer.currentClass.course,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleClassReset: (data) =>
      dispatch({ type: "COURSE_RECALL", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailHead);
