import React from 'react';
import { BsDot } from "react-icons/bs";
import { Buttonlink } from "components";

const BigSaleBanner = () => {
    const currentDate = new Date();
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const endOfMonthDate = endOfMonth.getDate();
    const endOfMonthMonth = endOfMonth.toLocaleString('en-US', { month: 'long' });
    const endOfMonthYear = endOfMonth.getFullYear();
    return (
        <div className=" mt-28">
            <div className="flex  w-full items-center justify-end bg-gradient-to-r from-[#5831A2] to-[#1C1D62] sm:p-10 xs:p-4 rounded-xl">
                <h3 className="text-[160px] text-[white]/20 font-black absolute z-10 uppercase  whitespace-nowrap">
                    75 % Sale
                </h3>
                <div className="grid w-full lg:grid-cols-2">
                    <div>
                        <h3 className="text-3xl Medium white">Knowledge Big Sale</h3>
                        <p className="mt-4 white Regular">
                            Hurry up to get a 75% discount on all payment plans till
                        </p>
                        <div className="flex items-center mt-4">
                            <h4 className="text-2xl Medium white">{endOfMonthDate}</h4>
                            <BsDot className="mx-2 primary" />
                            <h4 className="text-2xl Medium white">{endOfMonthMonth}</h4>
                            <BsDot className="mx-2 primary" />
                            <h4 className="text-2xl Medium white">{endOfMonthYear}</h4>
                        </div>
                    </div>
                    <div className="flex justify-center mt-5 lg:mt-auto lg:items-end lg:justify-end">
                        <Buttonlink
                            to={"/pricing"}
                            className="z-20 flex items-center justify-center w-full h-12 px-5 border-0 shadow-none cursor-pointer Regular white hover:color-white bg--primary md:w-auto hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                            Text={"Choose Your Plan"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BigSaleBanner;
