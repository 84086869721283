import React, { useEffect, useState } from "react";
import { BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import client from "../../../graphql/client";
import userLikesThisQuestion from "../../../graphql/queries/userLikesThisQuestion";
import updateLikeQuestion from "../../../graphql/mutations/likeQuestionMutation";
import SAVE_QUESTION_MUTATION from "../../../graphql/mutations/saveQuestionMutation";
import UN_SAVE_QUESTION_MUTATION from "../../../graphql/mutations/unsaveQuestionMutation";
import { Link } from "react-router-dom";
import heart from "assets/images/heart.png";
import heartColor from "assets/images/heartColor.png";
import commentoutline from "assets/images/commentoutline.png";
import share from "assets/images/share.png";
import { Signinpopup, Socialshare } from "components";
import Auth from "../../../Auth";
import { gql } from "@apollo/client";
import { connect } from "react-redux";
const auth = new Auth();

function postfooter(props) {
  const [currentImage, setCurrentImage] = useState(heart);
  const [likes, setLikes] = useState(props.likes.paginatorInfo.total);
  const [loading, setLoading] = useState(false);
  const [saveForCurrentUser, setSaveForCurrentUser] = useState(props.saveForCurrentUser);
  const [shareBtn, setShareBtn] = useState(false);
  const openSignIn = React.createRef();
  useEffect(() => {
    // if (auth.isAuthenticated()) {
    //   client
    //     .query({
    //       query: userLikesThisQuestion,
    //       fetchPolicy: 'network-only',
    //       variables: {
    //         question_id: parseInt(props.question.id),
    //       },
    //     })
    //     .then((res) => {
    //       console.log('first', res.data.userLikesThisQuestion);
    //       const { error } = res.data.userLikesThisQuestion;
    //       if (error) {
    //         setCurrentImage(heartColor)
    //       } else {
    //         
    //       }
    //     });
    // }
    const isCurrentUserLiked = props.question.likes.data.some(vote => vote.users.id === props.authUserInformation.id);
    if (isCurrentUserLiked) {
      setCurrentImage(heartColor);
    }
    else {
      setCurrentImage(heart);
    }
    // console.log('props.authUserInformation', props.question.likes.data, props.authUserInformation.id, isCurrentUserLiked);


  }, []);

  const handleLikeCLicked = (e, event) => {
    if (loading) {
      return; // Prevent multiple clicks while the previous response is still loading
    }

    setLoading(true); // Set loading state to true to prevent further clicks

    if (props.type === "question") {
      if (auth.isAuthenticated()) {
        _questionLikeMutation(event);
      } else {
        openSignIn.current.openPopup()
        setLoading(false); // Reset loading state when user is not authenticated
      }
    }
  };

  const _questionLikeMutation = (event) => {
    client
      .mutate({
        mutation: updateLikeQuestion,
        variables: {
          question_id: parseInt(event),
        },
      })
      .then((res) => {
        if (props.authUserSocket?.connected) {
          props.authUserSocket?.emit("notify", parseInt(res.data?.likeQuestion?.users.id));
        }

        if (res.data.likeQuestion.like_type === "unlike") {
          setCurrentImage(heart);
          setLikes(likes - 1); // Use functional form to update likes
        } else {
          setCurrentImage(heartColor);
          setLikes(likes + 1); // Use functional form to update likes
        }

        setLoading(false); // Reset loading state after response is completed
      });
  };

  const _questionSaveMutation = (event) => {
    setSaveForCurrentUser(true);

    client
      .mutate({
        mutation: SAVE_QUESTION_MUTATION,
        variables: {
          question_id: parseInt(event),
        },
        optimisticResponse: {
          saveQuestion: {
            error: false,
            message: "Question Saved successfully.",
            __typename: "saveQuestion",
            payload: {
              id: event,
              saveForCurrentUser: true,
              __typename: "Question",
            },
          },
        },
        update: (cache, result) => {
          const updatedQuestion = result.data.saveQuestion.payload;
          cache.writeFragment({
            id: cache.identify(updatedQuestion),
            fragment: gql`
            fragment SaveForCurrentUser on Question {
              saveForCurrentUser
            }
          `,
            data: updatedQuestion,
          });
        },
      })
      .then((res) => {
        if (res.data.saveQuestion.message === "Question Saved successfully.") {
          setSaveForCurrentUser(true);
        }
      });
  };


  const _questionUnSaveMutation = (event) => {
    setSaveForCurrentUser(false);

    client
      .mutate({
        mutation: UN_SAVE_QUESTION_MUTATION,
        variables: {
          question_id: parseInt(event),
        },
        optimisticResponse: (vars) => {
          return {
            unsaveQuestion: {
              error: false,
              message: "Question unsaved.",
              __typename: "unsaveQuestion",
              payload: {
                id: vars.question_id,
                saveForCurrentUser: false,
                __typename: "Question",
              },
            },
          };
        },
        update: (cache, result) => {
          if (result.data.unsaveQuestion.error === false) {
            const updatedQuestion = {
              id: result.data.unsaveQuestion.payload.id,
              saveForCurrentUser: false,
              __typename: "Question",
            };
            cache.writeFragment({
              id: cache.identify(updatedQuestion),
              fragment: gql`
              fragment SaveForCurrentUser on Question {
                saveForCurrentUser
              }
            `,
              data: updatedQuestion,
            });
          }
        },
      })
      .then((res) => {
        if (res.data.unsaveQuestion.message === "Question unsaved.") {
          setSaveForCurrentUser(false);
        }
      });
  };

  /*  const _questionUnSaveMutation = (event) => {
      setSaveForCurrentUser(false)

      client
        .mutate({
          mutation: UN_SAVE_QUESTION_MUTATION,
          variables: {
            question_id: event,
          },
          optimisticResponse: (vars) => {
            return {
              unsaveQuestion: {
                error: false,
                message: "Question unsaved.",
                __typename: "unsaveQuestion",
                payload: {
                  id: vars.question_id,
                  saveForCurrentUser:false,
                  __typename: "Question",
                },
              },
            };
          },
          update: (cache, result) => {
            // console.log("result", result);
            if (result.data.unsaveQuestion.error === false) {
              const removeQuestion = cache.identify(
                result.data.unsaveQuestion.payload
              );
              console.log("unsaveQuestion", removeQuestion);
              cache.modify({
                fields: {
                  Question: (existingQuestionRefs) => {
                    console.log("existingQuestionRefs", existingQuestionRefs);
                    return existingQuestionRefs?.data?.filter((questionRef) => {
                      return cache.identify(questionRef) !== removeQuestion;
                    });
                  },
                },
              });
              cache.evict({ id: removeQuestion });
            }
          },
        })
        .then((res) => {
          if (res.data.unsaveQuestion.message === "Question unsaved.") {
            setSaveForCurrentUser(false)
          }
        });
    };*/

  const { id, slug, description, question, tags } = props.question;
  let tagName = [];
  tags.map((tag) => tagName.push(tag.tag_title));
  return (
    <div className="post-footer">
      <div className="flex items-center justify-between">
        <div className="flex items-center lg:gap-x-10 gap-x-6">
          <Link
            to={`/discussions/${slug}`}
            className="flex items-center cursor-pointer gap-x-2"
            onClick={() => {
              !auth.isAuthenticated() && openSignIn.current.openPopup();
              props?.callBackResponse && props?.callBackResponse(null);
            }}
          >
            <img
              alt="comment"
              src={commentoutline}
              className="object-contain w-5 h-5 lg:h-6 lg:w-6"
            />
            <p className="gray fs-13">
              {props.answers.data.length
                ? props.answers.paginatorInfo.total
                : 0}
            </p>
          </Link>
          <div className="flex items-center cursor-pointer gap-x-2">
            <img
              alt="likes"
              src={currentImage}
              className="object-contain w-5 h-5 lg:h-6 lg:w-6"
              onClick={(e) => handleLikeCLicked(e, id)}
            />
            <p className="gray fs-13">{likes}</p>
          </div>
          {/* <div className="flex items-center cursor-pointer gap-x-2">
            <img
              alt="share"
              src={share}
              className="object-contain w-5 h-5 lg:h-6 lg:w-6"
              onClick={() =>
                setShareBtn(!shareBtn)
              }
            />
            {shareBtn ? (
              <div>

                <Socialshare
                  size={22}
                  className="w-6 h-6"
                  subject={description}
                  url={new URL(`${process.env.REACT_APP_MAIN_URL}/share/discussions/${id}`)}
                  title={description}
                  quote={description}
                  summary={{ __html: question }}
                  source={process.env.REACT_APP_WEB_URL}
                  via={process.env.REACT_APP_WEB_URL}
                  hashtags={tagName}
                />
              </div>
            ) : null}

          </div> */}
        </div>
        <div>
          {saveForCurrentUser ? (
            <BsFillBookmarkFill
              className="w-4 h-4 cursor-pointer primary lg:h-5 lg:w-5"
              onClick={() =>
                auth.isAuthenticated()
                  ? _questionUnSaveMutation(id)
                  : openSignIn.current.openPopup()
              }
            />
          ) : (
            <BsBookmark
              className="w-4 h-4 cursor-pointer gray lg:h-5 lg:w-5"
              onClick={() =>
                auth.isAuthenticated() ? _questionSaveMutation(id) : openSignIn.current.openPopup()
              }
            />
          )}
        </div>
      </div>
      <Signinpopup ref={openSignIn} protect={true} />
    </div>
  );
}
const mapStateToProps = (state) => ({
  authUserInformation: state.AuthUserReducer.authUserInformation,
  authUserSocket: state.AuthUserReducer.authUserSocket,
});

export default connect(mapStateToProps, null)(postfooter);


// export default postfooter;
