import {
    Buttonlink,
    Discussionpost,
    Noconnection,
    ProfileAvatar,
} from "components";
import React, { useEffect, useState, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { seo } from "../../../helpers/seo";
import { Query } from "@apollo/client/react/components";
import Alert from "@mui/material/Alert";
import { AlertTitle, Box, Modal, Typography } from "@mui/material";
import Auth from "../../../Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "@mui/material/Avatar";
import { PROFILE_PHOTO_URL } from "../../../config/constants";
import commentoutline from "assets/images/commentoutline.png";
import { AiFillEdit } from "react-icons/ai";
import { HiOutlineArrowUp } from "react-icons/hi";
import get_expert_opinion_by_id from "../../../graphql/SgQueries/getExpertOpinionById";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import "../../../assets/css/semantic.css";
import { BsThreeDots } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { FiX } from "react-icons/fi";
import decryptUrl from "../../../helpers/Decrypt";
import { useMutation } from "@apollo/client";
import DELETE_EXPERT_OPINION from "../../../graphql/SgMutations/deleteExpertOpinionByID";
import ADD_OR_UPDATE_EXPERT_OPINION from "../../../graphql/SgMutations/addExpertOpinionAnswer";
import { extractValidationErrors } from "../../../helpers/extractValidationErrors";

const auth = new Auth();

function RecommendationDetail(props) {
    const [comment, setComment] = useState("");
    const [loadData, setLoadData] = useState(false);
    const [id, setId] = useState(0);
    const [becomePro, setBecomePro] = useState(false);
    const [disable, setDisable] = useState(true);

    const ref = useRef();
    const HandleFocus = (value, id) => {
        ref.current.focus();
        setComment(value);
        setId(id)
        setLoadData(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        seo({
            title: `${props.slug} | Procurement League`,
        });
    }, []);

    useEffect(() => {
        if (comment.length > 1 && disable) {
            setDisable(false)
        }
        if (comment.length === 0) {
            setDisable(true)
        }
    }, [comment])

    const notify = (message = "", type = "error") => {
        if (type === "error") {
            return toast.error(message, { position: "bottom-right" });
        }
        toast.success(message, { position: "bottom-right" });
    };


    const [deleteExpertOpinion] = useMutation(DELETE_EXPERT_OPINION, {
        onCompleted(data) {
            console.log("comment delete");
        },
        update: (cache, result) => {
            // console.log(result.data.delete_sg_offer_comment_by_id);
            const deletedQuestion = cache.identify(result.data.delete_sg_offer_comment_by_id);
            // console.log("deletedQuestion", deletedQuestion);
            cache.modify({
                fields: {
                    expert_opinion_by_id: (existingQuestionRefs) => {
                        // console.log("existingQuestionRefs", existingQuestionRefs);
                        return existingQuestionRefs?.data?.comments.data.filter((questionRef) => {
                            return cache.identify(questionRef) !== deletedQuestion;
                        });
                    },
                },
            });
            cache.evict({ id: deletedQuestion });
        },
    });

    const [add_or_update_ExpertOpinion] = useMutation(ADD_OR_UPDATE_EXPERT_OPINION, {
        onError(data) {
            setDisable(false)
            extractValidationErrors(data).forEach((error) => notify(error, "error"));
        },
        onCompleted(data) {
            setComment("")
            setId(0)
            setLoadData(false)
            const textarea = ref.current; // Get the textarea reference
            adjustTextareaHeight(textarea); // Call the function with the textarea reference
            // console.log("comment added");
        },
    });

    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight) + 'px';
    }

    const handleClick = () => {
        if (props.location.state?.filters !== undefined || props.location.state?.topics !== undefined) {
          props.history.push({
            pathname: "/discussions",
            state: {
              filters: props.location.state?.filters,
              topics: props.location.state?.topics,
            },
          });
        } else {
          props.history.goBack();
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 3,
    };
    // console.log("authUser", this.props.authUser);
    let variables = {};
    variables.id = decryptUrl(props.id);

    return (
        <div className="container">
            <ToastContainer />
            <div className="xs:px-0 sm:px-4 xl:px-28 lg:px-5">
                <div className="flex items-center justify-between mb-10">
                    <Buttonlink
                        Text="back"
                        Icon={<IoIosArrowBack />}
                        onClick={handleClick}
                        className="flex items-center justify-center px-4 rounded-full bg--lightGray h-9 darkGray w-28 hover:darkGray hover:opacity-80"
                    />
                    <h1 className="text-3xl text-center Medium lg:text-5xl">Detail</h1>
                    <div className="w-28" />
                </div>
                <div className="">
                    <ToastContainer />
                    <Query query={get_expert_opinion_by_id} variables={variables}>
                        {({ loading, error, data, fetchMore, refetch }) => {
                            if (loading) {
                                return <Discussionpost />;
                            }
                            if (error) {
                                console.log("error", error);
                                return (
                                    <div>
                                        {auth.isAuthenticated() ? (
                                            <>
                                                <Noconnection />
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ fontSize: 19 }}>
                                                    <Alert severity="warning">
                                                        <AlertTitle>
                                                            <strong>Anonymous User</strong>
                                                        </AlertTitle>
                                                        Please{" "}
                                                        <button
                                                            onClick={() =>
                                                                true
                                                                // this.setState({openModal: true})
                                                            }
                                                            className={"primary"}>
                                                            Login
                                                        </button>
                                                        {" "}
                                                        to connect with other Procurement practitioners
                                                        and participate in discussion.
                                                    </Alert>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }

                            if (data !== undefined) {
                                // console.log("data", data);
                                let question = data.expert_opinion_by_id;
                                if (!question) {
                                    // Data is defined, but question is not available yet
                                    return <Discussionpost />;
                                }
                                // let user = JSON.parse(question.comments[0].user);
                                // console.log("expert_opinion_by_id", question);
                                return (
                                    <div className="border rounded-xl">
                                        <div className="sm:p-4 xs:p-2 important:pb-0">
                                            {question?.user ? (
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        {question?.user ? (
                                                            <Avatar
                                                                alt={question.user.firstname}
                                                                src={
                                                                    question.user.profile_photo ? (
                                                                        process.env.REACT_APP_MRO_URL +
                                                                        PROFILE_PHOTO_URL +
                                                                        `/${question.user.profile_photo}`
                                                                    ) : (
                                                                        <Avatar
                                                                            alt={question.user.firstname}
                                                                            className={
                                                                                "h-20 w-20 object-cover rounded-full mr-3"
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                                sx={{ height: 48, width: 48 }}
                                                                className={
                                                                    "h-20 w-20 object-cover rounded-full mr-3"
                                                                }
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div>
                                                            <p className="text-lg Medium lg:text-xl">
                                                                {question.user.firstname}{" "}
                                                                {question.user.lastname}
                                                            </p>
                                                            <p className="Light gray fs-12">
                                                                @{question.user.username}{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="mt-2 mb-3 divider" />
                                            <b className="mt-5">{question.title}</b>
                                            {question.question !== null ? (
                                                <div className="my-2 leading-normal text-color Light">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: question.description,
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                            {/* {
                                                        question.attachments.length > 0 ?
                                                            question.attachments.map(attachment => (
                                                                <img src={attachment.public_image} key={attachment.id} width={450}/>
                                                            ))
                                                            :null
                                                    }*/}
                                            <div className="my-3">
                                                {question.tags?.length > 0 ? (
                                                    <div className="flex flex-wrap items-center gap-2">
                                                        {question.tags.map((tag) => (
                                                            <div
                                                                className="flex items-center h-8 px-4 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300 lg:rounded-xl lg:h-9"
                                                                key={tag.id}>
                                                                <p className="Light darkGray fs-13">
                                                                    {tag.name}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="mt-4 divider" />
                                            <div className="py-3">
                                                <div className="post-footer">
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center lg:gap-x-10 gap-x-6">
                                                            <div
                                                                className="flex items-center cursor-pointer gap-x-2">
                                                                <img
                                                                    alt="comment"
                                                                    src={commentoutline}
                                                                    className="object-contain w-5 h-5 lg:h-6 lg:w-6"
                                                                />
                                                                <p className="gray fs-13">
                                                                    {question.comments?.paginatorInfo?.total}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider " />
                                        {question.comments?.data?.length > 0
                                            ? question.comments.data.map((answer) => (
                                                <>
                                                    <div className="p-4 pb-0 ml-10" key={answer.id}>
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex items-center">
                                                                <ProfileAvatar
                                                                    platform={"PL"}
                                                                    singleUser={JSON.parse(answer.user)}
                                                                    className="object-cover w-16 h-16 mr-3 rounded-full"
                                                                />
                                                                <div>
                                                                    <p className="text-xl Medium">
                                                                        {JSON.parse(answer.user).firstname}{" "}
                                                                        {JSON.parse(answer.user).lastname}{" "}
                                                                    </p>
                                                                    <p className="Light gray">
                                                                        @{JSON.parse(answer.user).username}{" "}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {props.authUser.id == JSON.parse(answer.user).id ? (
                                                                <div>
                                                                    <Dropdown
                                                                        icon={false}
                                                                        wrapSelection={true}
                                                                        direction="left"
                                                                        pointing="left"
                                                                        text={
                                                                            <div
                                                                                className="z-10 items-center justify-center w-10 h-10 m-auto rounded-full !flex hover:bg-gray-200">
                                                                                <BsThreeDots
                                                                                    size={22}
                                                                                    className="gray"
                                                                                />
                                                                            </div>
                                                                        }>
                                                                        <Dropdown.Menu
                                                                            className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                                                                            <div className="relative p-2">
                                                                                <div
                                                                                    onClick={() =>
                                                                                        deleteExpertOpinion({ variables: { id: answer.id } })
                                                                                    }
                                                                                    className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl">
                                                                                    <div
                                                                                        className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                                                                                        <IoTrashOutline
                                                                                            size={18}
                                                                                            className="danger"
                                                                                        />
                                                                                    </div>
                                                                                    <p className="danger">Delete</p>
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => HandleFocus(answer.comment_body, answer.id)}
                                                                                    className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl">
                                                                                    <div
                                                                                        className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                                                                                        <AiFillEdit
                                                                                            size={18}
                                                                                            className="danger"
                                                                                        />
                                                                                    </div>
                                                                                    <p className="danger">Edit</p>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="my-4" />
                                                        <p className="leading-normal text-color">
                                                            {answer.comment_body}
                                                        </p>
                                                        <div className="divider" />
                                                    </div>
                                                </>
                                            ))
                                            : null}
                                        <div className="px-4">
                                            <div className="flex items-center">
                                                <div className="flex items-center w-full rounded-xl border">
                                                    <textarea
                                                        ref={ref}
                                                        placeholder={`Add reply @${question.user.username} Question`}
                                                        className="w-full text-dark border-0 focus:outline-none focus:ring focus:ring-blue-300 resize-none overflow-hidden"
                                                        onChange={(e) => {
                                                            setComment(e.target.value);
                                                            adjustTextareaHeight(e.target);
                                                        }}
                                                        value={comment}
                                                        maxLength={225}
                                                    />
                                                </div>

                                                {
                                                    loadData ?
                                                        <button
                                                            disabled={disable}
                                                            onClick={() => {
                                                                setDisable(true)
                                                                add_or_update_ExpertOpinion({
                                                                    variables: {
                                                                        id: parseInt(id),
                                                                        comment: comment,
                                                                        offer_id: parseInt(question.id)
                                                                    }
                                                                })
                                                            }}
                                                            className={`flex items-center justify-center w-12 rounded-full h-11 ${disable ? "bg--gray" : "bg--primary"}`}>
                                                            <HiOutlineArrowUp color="white" size={20} />
                                                        </button> :
                                                        <button
                                                            disabled={disable}
                                                            onClick={() => {
                                                                setDisable(true)
                                                                add_or_update_ExpertOpinion({
                                                                    variables: {
                                                                        comment: comment,
                                                                        offer_id: parseInt(question.id)
                                                                    }
                                                                })
                                                            }}
                                                            className={`flex items-center justify-center w-12 rounded-full h-11 ${disable ? "bg--gray" : "bg--primary"}`}>
                                                            <HiOutlineArrowUp color="white" size={20} />
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }}
                    </Query>
                    <Modal
                        open={becomePro}
                        onClose={() => setBecomePro(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <div className="flex items-center justify-between">
                                <Typography variant="h6" component="h2">
                                    Note!!
                                </Typography>
                                <div
                                    onClick={() => setBecomePro(false)}
                                    className="p-2 -mr-4 rounded-full cursor-pointer hover:bg-gray-200 bg-gray-50">
                                    <FiX size={22} />
                                </div>
                            </div>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Only Pro Users can answer this question.
                                <a
                                    href={`${process.env.REACT_APP_WEB_URL}/pricing`}
                                    className="flex items-center mt-2 black Regular fs-12">
                                    Click me to become Pro User
                                </a>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(RecommendationDetail);
