import { gql } from "@apollo/client";

export default gql`
    query Tags{
        alltags{
            id
            tag_title
        }
    }
`;
