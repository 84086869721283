import React, { Component } from "react";
import { ClassCard } from "components";

class SeeAllClasses extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
  }

  handleOnScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      this.props.onLoadMore();
    }
  };

  render() {
    const { classes, type } = this.props;
    return (
      <div className="relative blog-posts">
        <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2">
          {classes.map((_class) => (
            <ClassCard _class={_class} type={type} key={_class.id} />
          ))}
        </div>
      </div>
    );
  }
}

export default SeeAllClasses;
