import React, { useState } from "react";
import "./styles.css";


const SelectTags = ({ categoryItems, onCategoryChange, propsCategories }) => {

  const [categories, setCategories] = useState([]);
  const selectedIds = propsCategories?.map((cat) => cat?.id)

  const onSelectSkill = (category) => {
    if (!selectedIds?.includes(category?.id) && (selectedIds || [])?.length === 5) {
      return
    }
    // Copy the current state of categories using the spread operator
    const updatedCategories = [...categories];

    // Find the index of the selected category in the updatedCategories array
    const categoryIndex = updatedCategories.findIndex((item) => item.id === category.id);

    if (categoryIndex !== -1) {
      // Category already exists, remove it from the array (unselect it)
      updatedCategories.splice(categoryIndex, 1);
    } else {
      // Category doesn't exist, add it to the array (select it)
      updatedCategories.push(category);
    }

    // Update the state with the updatedCategories array
    setCategories(updatedCategories);

    // Filter out selected and unselected categories
    const selectedCategories = updatedCategories.filter((item) =>
      updatedCategories.some((selectedCategory) => selectedCategory.id === item.id)
    );

    // Find unselected categories by filtering the original categories array
    const unselectedCategories = categories.filter(
      (item) => !updatedCategories.some((selectedCategory) => selectedCategory.id === item.id)
    );

    // Call the onCategoryChange function and pass the selectedCategories and unselectedCategories arrays
    onCategoryChange(selectedCategories, unselectedCategories);
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {categoryItems.map((item) => (
        <div className="relative" key={item.id}>
          <input
            type="checkbox"
            className="absolute left-0 z-0 invisible"
            id={`item${item.id}`}
          />
          <label
            onClick={() => onSelectSkill(item)}
            htmlFor={`item${item.id}`}
            className={`${selectedIds?.includes(item.id) ? "bg--primary":"bg-gray-200"} hover:opacity-70 rounded-full px-4 py-1.5 cursor-pointer inline-block`}
          >
            <p className={`fs-13 ${selectedIds?.includes(item.id) ? "text-white":"text-black"}`}>{item.name}</p>
          </label>
        </div>
      ))}
    </div>
  );
};

export default SelectTags;
