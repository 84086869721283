import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Avatar from "assets/images/blog.webp";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer top-20 md:top-0 slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 top-20 md:top-0 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class resourcecard extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts slider-left equal-height">
        <Slider {...settings} className="ml-0 important:static">
          <div className="h-full p-2 pt-3">
            <Link
              to={{
                pathname: `/product/id`,
              }}
              className="justify-start block h-full transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--gray-800 shadow--sm rounded-xl">
              <div
                className="relative bg-gray-200 rounded-t-xl"
                style={{ minHeight: 150 }}>
                <img
                  alt="Avatar"
                  src={Avatar}
                  className="object-contain object-top w-full h-full mx-auto hover:bg-gray-200 rounded-t-xl"
                />
              </div>
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Course name</h4>
                <p className="my-3 Regular fs-13">Username</p>
                <div className="flex items-center justify-between">
                  <Link
                    to={{
                      pathname: `/product/id`,
                    }}
                    className="flex items-center black Regular fs-14">
                    Read more <IoIosArrowForward className="ml-2" />
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        </Slider>
      </div>
    );
  }
}

export default resourcecard;
