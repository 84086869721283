import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const AnimateButton = ({ text, onClick, to,className }) => {
    if (onClick) {
        return (
            <button
                onClick={onClick}
                className={`${className} group hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out relative !font-normal rounded-full overflow-hidden bg--primary`}
            >
                <div className="absolute h-full rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                <span className="relative text-white w-full flex Poppins items-center justify-between group-hover:text-white">
                    {text} <BsArrowRight size={23} />
                </span>
            </button>
        );
    }

    if (to) {
        return (
            <Link
                to={to}
                className={`${className} group hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out relative !font-normal rounded-full overflow-hidden bg--primary`}
            >
                <div className="absolute h-full rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                <span className="relative text-white w-full flex Poppins items-center justify-between group-hover:text-white">
                    {text} <BsArrowRight size={23} />
                </span>
            </Link>
        );
    }
};

export default AnimateButton;
