import React, { Component } from "react";
import Slider from "react-slick";
import "./styles.css";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class blogpost extends Component {
  render() {
    const settings = {
      dots: true,
      className: "center",
      centerMode: false,
      infinite: true,
      centerPadding: "50px",
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts">
        <div className="container">
          <h3 className="mb-4 text-2xl text-center Medium lg:text-3xl">
            Blog posts
          </h3>
        </div>
        <Slider {...settings} className="important:static">
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://media.istockphoto.com/photos/put-more-in-get-more-out-picture-id1291318636?b=1&k=20&m=1291318636&s=170667a&w=0&h=UvVIk7wwkN3X9OFm8gBlWWviV5vAjfrq2ejYP30JmnA="
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://media.istockphoto.com/photos/there-must-be-a-easier-way-to-pay-all-our-bills-at-once-picture-id1294144617?b=1&k=20&m=1294144617&s=170667a&w=0&h=fmSns5NkSJRU6KEbw2FeJR-wfmmuKv0lMaPYxEQb-W4="
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://media.istockphoto.com/photos/asian-chinese-female-doctor-working-on-medical-report-with-laptop-and-picture-id1309783183?b=1&k=20&m=1309783183&s=170667a&w=0&h=M9TeQBEjvv5b0E4SINbc5oFkTBYmi7-eo3RTTBDHjdk="
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcHV0ZXJ8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="overflow-hidden shadow--sm rounded-xl">
              <img
                src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Y29tcHV0ZXJ8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                className="object-cover object-top w-full mx-auto h-60"
                alt="headline"
              />
              <div className="p-4">
                <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
                <p className="my-3 Regular fs-13">
                  Lillevik. Ground Level detailed training which I can correlate
                  how.
                </p>
                <Link
                  to={"#"}
                  className="flex items-center black Regular fs-14">
                  Read more <IoIosArrowForward className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </Slider>
        <div className="flex justify-end mt-4">
          <Link to={"#"} className="flex items-center black Regular">
            Load more <IoIosArrowForward className="ml-2" />
          </Link>
        </div>
      </div>
    );
  }
}

export default blogpost;
