import { gql } from "@apollo/client";

export default gql`
    mutation change_current_user_password(
        $token: String!
        $new_password: String!
        $email: String!
    ) {
        change_current_user_password(
            token: $token
            email: $email
            new_password: $new_password
        ) {
            message
            status
        }
    }
`;