import {
  REMOVE_QUESTION, REMOVE_QUESTION_ANSWER, REMOVE_QUESTION_ANSWER_COMMENT, REMOVE_TAGS, SELECTED_CATEGORY, SELECTED_TAGS,
  SELECTED_USER, TYPE_EMAIL, TYPE_SEARCH
} from "../actions/types";
import { produce } from "immer";

const state = {
  tags_ids: [],
  selectedTags: [],
  typedEmail:[],
  typeSearch:"",
  openFullscreenPopup: false,
  categories: [],
  selectedUsers: [],
  deletedQuestions: 0,
  deletedQuestionsAnswer: 0,
  deleteQuestionAnswerComment: 0,
};

function DiscussionSearchReducer(mState = { ...state }, action) {
  switch (action.type) {
    case TYPE_SEARCH:
      return produce(mState, draftState => {
        draftState.typeSearch = action.payload
      });
      case TYPE_EMAIL:
      return produce(mState, draftState => {
        // console.log(action.payload)
        if (action.payload === ""){
          draftState.typedEmail = [];
        }else {
          draftState.typedEmail = [...mState.typedEmail,action.payload]
        }
      });
      case SELECTED_TAGS:
      return produce(mState, draftState => {
        draftState.selectedTags = action.payload
      });
    case REMOVE_TAGS:
      return produce(mState, draftState => {
        if (typeof action.payload === "object"){
          draftState.selectedTags = draftState.selectedTags.filter(tag => !action.payload.includes(tag.id));
        }else {
          draftState.selectedTags = draftState.selectedTags.filter(i=>i.id !== action.payload)
        }
      });
    case SELECTED_CATEGORY:
      return produce(mState, draftState => {
          draftState.categories = action.payload
      });
    case SELECTED_USER:
      return produce(mState, draftState => {
        draftState.selectedUsers = action.payload
      });
    case REMOVE_QUESTION:
      return {
        ...state,
        deletedQuestions: action.payload,
      };
    case REMOVE_QUESTION_ANSWER:
      return {
        ...state,
        deletedQuestionsAnswer: action.payload,
      };
      case REMOVE_QUESTION_ANSWER_COMMENT:
      return {
        ...state,
        deleteQuestionAnswerComment: action.payload,
      };
    default:
      return { ...mState };
  }
}

export default DiscussionSearchReducer;
