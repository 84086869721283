import { Chapters, ProfileAvatar } from "components";
import React, { Component } from "react";
import { BsFileEarmark } from "react-icons/bs";
import { Markup } from "interweave";
import moment from "moment";
import Sessions from "../sessions";
import { Box, Modal, Typography } from "@mui/material";

class detailabout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      becomePro: false,
      is_enroll: false,
      downloadFile: false,
    };
  }

  render() {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 460,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
    const { event, authUser, type } = this.props;

    return (
      <div>
        {event?.users?.data && (
          <h4 className="lg:text-[48px] text-[36px] lg:leading-[60px] leading-[40px] text-new-color !font-normal Poppins pb-5">{event.course_name}</h4>
        )}
        <div className="flex">
          <ProfileAvatar className="object-cover w-20 h-20 mr-3 rounded-full" singleUser={authUser} />
          <div className="flex flex-col justify-evenly">
            <div>
              {(event?.data_type === "EVENT" || event?.data_type === "PAST_EVENT") && event?.is_external_event ? 
                  <div className="flex items-center">
                    <p className="text-lg text-new-color font-bold Poppins">Company:</p>
                    <p className="ml-2 text-lg text-new-color Poppins primary">
                      {event?.company_name}
                    </p>
                  </div>:
                <div className="flex items-center">
                  <p className="text-lg text-new-color font-bold Poppins">Trainer:</p>
                  <p className="ml-2 text-lg text-new-color Poppins primary">
                    {event.users && event.users?.data[0]?.firstname +
                      " " +
                      event?.users?.data[0]?.lastname}
                  </p>
                </div>
              }
              <div className="flex items-center">
                <p className="text-lg text-new-color font-bold Poppins ">Event Date:</p>
                <p className="ml-2 text-lg text-new-color black Poppins">
                  {moment(event.publishing_on).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="flex items-center">
                {type === "" ? (
                  <p className="text-lg font-bold whitespace-nowrap text-new-color Poppins">
                    Number of people attending:
                  </p>
                ) : (
                  <p className="text-lg font-bold whitespace-nowrap text-new-color Poppins">
                    Number of people purchased:
                  </p>
                )}
                <p className="lg:ml-2 ml-1 text-lg text-new-color Poppins">
                  {event?.total_purchased_by_users}
                </p>
              </div>
              {event?.data_type === 'PRODUCT' &&
              <div className="flex items-center">
                <p className="text-lg text-new-color font-bold Poppins ">Product Shippable:</p>
                <p className="ml-2 text-lg text-new-color black Poppins">
                  {event?.is_shippable ? 'Yes':'No'}
                </p>
              </div>
              }
            </div>
          </div>
        </div>
        <div className="my-5 bg-[#A2A2B6] w-full h-px" />
        {event?.company_name && 
          <div className="mt-4 Poppins-all">
            <h4 className="Poppins text-3xl primary mb-1">Company</h4>
              <h4 className="Poppins text-lg font-bold text-new-color mb-1">Name: <span className="font-normal">{event?.company_name}</span></h4>
              <h4 className="Poppins text-lg font-bold text-new-color mb-1">Description</h4>
              <div className="important:Poppins-all custom-lists Poppins text-lg" dangerouslySetInnerHTML={{ __html: event?.company_description || '' }}>
              </div>
          </div>
        }
        {event?.speaker_name &&
        <div className="mt-4 Poppins-all">
          <h4 className="Poppins text-3xl primary mb-1">Speaker</h4>
            <h4 className="Poppins text-lg font-bold text-new-color mb-1">Name: <span className="font-normal">{event?.speaker_name}</span></h4>
            <h4 className="Poppins text-lg font-bold text-new-color mb-1">Description</h4>
            <div className="important:Poppins-all custom-lists Poppins text-lg" dangerouslySetInnerHTML={{ __html: event?.speaker_description }}>
            </div>
        </div>
        }
        <div className="mt-4 Poppins-all">
          <h4 className="Poppins text-3xl primary mb-1">{(event?.data_type === "EVENT" || event?.data_type === "PAST_EVENT") ? 'Event Description' : event?.data_type === 'PRODUCT' ? 'Product Description' : 'Course Description'}</h4>
          {event?.course_requirement?.data.map((r) => (
            <div className="important:Poppins-all custom-lists Poppins text-lg" dangerouslySetInnerHTML={{ __html: r.requirements }}>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <h4 className="Poppins text-lg font-bold text-new-color mb-1">Description</h4>
          <div className="important:Poppins-all Poppins custom-lists text-lg text-lg-all" dangerouslySetInnerHTML={{ __html: event.course_description }}>
          </div>
        </div>
        {event?.course_support_files.length > 0 && (
          <div className="mt-4">
            <h4 className="Poppins text-lg font-bold text-new-color mb-2">
              Downloadable Support files.
            </h4>

            {event?.course_support_files.map((file) => (
              <div
                className="border rounded-xl p-2.5 flex flex-wrap items-center mb-3"
                key={file.id}>
                <div className="flex items-start cursor-pointer ">
                  <BsFileEarmark className="primary mt-1" size={22} />

                  {this.state.downloadFile ? (
                    <a
                      href={file.file_path}
                      rel="noreferrer"
                      target="_blank"
                      download
                      className="primary text-lg ml-1.5"
                      style={{ marginTop: 1 }}>
                      {file.name}
                    </a>
                  ) : (
                    <p
                      className="primary text-lg ml-1.5"
                      onClick={() => {
                        type === "item_based"
                          ? event?.is_enroll
                            ? this.setState({ downloadFile: true })
                            : this.setState({ becomePro: true })
                          : authUser.is_pro
                            ? event.is_enroll
                              ? this.setState({ downloadFile: true })
                              : this.setState({ is_enroll: true })
                            : this.setState({ becomePro: true });
                      }}
                      style={{ marginTop: 1 }}>
                      {file.name}
                    </p>
                  )}
                </div>
              </div>
            ))}
            {/* <Becomeapropopup
              show={this.state.becomePro}
              onHide={() => this.setState({ becomePro: false })}
              item={event}
              type={type}
            /> */}

            <Modal
              open={this.state.becomePro}
              onClose={() => this.setState({ becomePro: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Note!!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please sign-up for Pro level
                </Typography>
              </Box>
            </Modal>
            {/*<EnrollPopup*/}
            {/*  show={this.state.is_enroll}*/}
            {/*  onHide={() => this.setState({ is_enroll: false })}*/}
            {/*/>*/}

            <Modal
              open={this.state.is_enroll}
              onClose={() => this.setState({ is_enroll: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Note!!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please enroll in this class first to download this attachment.
                </Typography>
              </Box>
            </Modal>
          </div>
        )}

        {event.course_session.length > 0 && (
          <div className="mt-4">
            <Sessions item={event} authUser={authUser} type={type} />
          </div>
        )}

        {event.contents.data.length > 0 && (
          <div className="mt-4">
            <Chapters
              item={event}
              type={type}
              authUser={authUser}
              chapter_count={event.chapter_count}
              lecture_count={event.lecture_count}
              contents={event.contents}
            />
          </div>
        )}
      </div>
    );
  }
}

export default detailabout;
