import {
  Reviews,
  Featured,
  Companies,
  Verticalslick,
  Services,
} from "components";
import React, { Component } from "react";

class Solution extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container">
        <div className="mt-28">
          <Companies />
        </div>
        <div className="mt-28">
          <Verticalslick />
        </div>
        <div className="mt-28">
          <Services />
        </div>
        <div className="mt-28">
          <Reviews />
        </div>
        <div className="mt-28">
          <Featured />
        </div>
      </div>
    );
  }
}

export default Solution;
