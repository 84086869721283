import { gql } from "@apollo/client";

export default gql`
    query {
        deactivate {
            status
            message
        }
    }
`;
