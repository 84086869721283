import {
  Buttonlink,
  Fullscreenpopup,
  Signinpopup,
  RfqPost,
  KeepChecking,
  SearchJobsFilter
} from "components";
import { useEffect, useState } from "react";
import "./styles.css";
import React from "react";
import { FiPlus } from "react-icons/fi";
import { Dropdown } from "semantic-ui-react";
import "../../../assets/css/semantic.css";
import { connect } from "react-redux";
import Auth from "../../../Auth";
import { seo } from "helpers/seo";
import {useHistory} from "react-router-dom";
import discussions from "assets/images/rfq.png";
import circles from "assets/images/circles.png";
import client from "graphql/client";
import All_Tags from "../../../graphql/queries/getAllTags";
import All_CATEGORIES from "../../../graphql/queries/businessCategories";
import { RfqFilters } from "./RfqFilters";

const auth = new Auth();


const options = [
  { key: "1", text: "All" },
  {
    key: "2",
    text: "Search by Category",
  },
  {
    key: "3",
    text: "Search by Tags",
  },
];

function RFQsDiscussion(props){
  const initialState = ''
  const [open, setOpen] = React.useState(false);
  const [table, setTable] = useState({});
  const [filterData, setFilterData] = useState({});
  const SelectFullPopup = React.createRef();
  const openSignIn = React.createRef();
  const KeepCheckingPopup = React.createRef();
  let timeoutValue = null;
  let newObject = localStorage.getItem("AUTH_USER");
  let authUser = JSON.parse(newObject);
  const history = useHistory();
  useEffect(()=>{
    seo({
      title: "RFQs | Procurement League",
    });
    window.scrollTo(0,0)
  },[])

  const openPopupModal = () => {
    auth.isAuthenticated()
      ? SelectFullPopup.current.openPopup()
      : KeepCheckingPopup.current.openPopup();
    // : openSignIn.current.openPopup();
  };

  const handleTimingChange = (time) => {
    setFilterData({...filterData, dateRange: time});
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagsResult = await client.query({
          query: All_Tags,
          fetchPolicy: "cache-first"
        });
        setTable(prevTable => ({
          ...prevTable,
          all_tags: tagsResult.data.alltags
        }));

        const categoriesResult = await client.query({
          query: All_CATEGORIES,
          fetchPolicy: "cache-first"
        });
        setTable(prevTable => ({
          ...prevTable,
          categories: categoriesResult?.data?.businessCategories?.data
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

    return (
      <>
        <div className="h-auto">
          <div className=" bg-[#EAF2F9] -mt-14">
            <div className="h-auto py-14 container w-[100%] grid grid-cols-12 relative ">
              <div className="lg:col-span-7 col-span-12">
                <p className="text-[#FF6600] text-[18px] leading-[26px]">
                  Search. Quote. Collaborate
                </p>
                <h1 className="text-new-color lg:text-5xl my-2 font-light text-4xl Poppins">
                  RFQ's
                </h1>
                <p className="text-[18px] leading-[25px] ">
                  Whether you're looking to source new products, compare prices, or find the best suppliers, our platform is designed to help you connect and collaborate efficiently.
                </p>
                <div className="mt-10">
                  {/* Searchbar with filter Icon View */}
                  <div className="flex flex-col items-center gap-3 lg:flex-row">
                    <input
                      placeholder="Search by title"
                      className="flex-grow px-4 w-full pr-4 bg-white border-0 h-14 Regular rounded-full"
                      name="search"
                      onChange={(e)=> {
                        clearTimeout(timeoutValue);
                        timeoutValue = setTimeout(() => {
                          auth.isAuthenticated() ? setFilterData({ ...filterData, search: e.target.value}) : openSignIn.current.openPopup()
                        }, 2000);
                      }}
                    />
                    <div className="relative w-full group lg:w-20">
                      <Buttonlink
                        href={`${process.env.REACT_APP_SOURCING_GENIE}/auto-login/${authUser?.cross_token}/buyer/${props.authUser?.sg_badge?1:0}`}
                        className="flex items-center justify-center w-full h-12 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 lg:h-14 rounded--xl"
                        Icon={<FiPlus size={22} />}
                      />
                      <div className="absolute z-50 md:left-0 left-[39%] items-center top-full transform translate-x-[-50%] mt-[0.71428571em] before:top-[-.30714286em] before:left-1/2 before:translate-x-[-50%] hidden mt-1 group-hover:flex before:absolute before:content-[''] before:w-[.71428571em] before:h-[.71428571em] before:transform before:rotate-[45deg]  before:bg-[#1b1c1d]">
                        <p className="relative font-medium text-center z-10 p-2 text-[12px] rounded leading-none text-white whitespace-no-wrap bg-[black]/90 w-20 shadow-lg">
                          Post Rfq
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src={discussions}
                alt="logo"
                className="absolute top-20 lg:block hidden -right-10 sm:right-10 lg:right-40 h-48"
              />
              <img
                src={circles}
                alt="circles"
                className="absolute -bottom-10 lg:block hidden -right-10 sm:right-10 lg:right-40 h-24"
              />
            </div>
          </div>
          <div className="container mt-28">
            <div className="mb-5">
              <SearchJobsFilter jobCentral={true} handleTimingChange={handleTimingChange} activeFilter={filterData?.dateRange} />
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-7">
              <div className="lg:col-span-2">
                <RfqFilters data={table} filterData={filterData} setFilterData={setFilterData} />
                {/* <div className="hidden lg:block">
                  <div className="p-5 border rounded-xl">
                    <p className="mb-3 text-lg font-semibold Poppins">Filters</p>
                    <div className="relative flex items-center -mx-3 select-list">
                      <input
                        type="radio"
                        className="absolute left-0 z-0 invisible"
                        id="All"
                        name="filters"
                      />
                      <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                      <label
                        htmlFor="All"
                        className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-3 py-2.5">
                        <p className="text-new-color text-lg Poppins">All</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="grid w-full gap-4 sm:grid-cols-2 lg:hidden">
                  <div>
                    <Dropdown
                      className="border gray Regular rounded--xl"
                      placeholder="Filter"
                      fluid
                      selection
                      options={options}
                    />
                  </div>
                </div> */}
              </div>
              <div className="lg:col-span-5">
                <div className="relative">
                  <RfqPost filterData={filterData} authUser={authUser} callback={()=>openSignIn.current.openPopup()}/>
                  {!auth.isAuthenticated() && (
                    <div className="gap-x-5 flex items-center justify-center w-full z-50">
                      <Buttonlink
                        to="/signup"
                        Text="Sign Up"
                        className="flex items-center justify-center w-full h-12 rounded-full bg--lightGray black hover:black focus:black hover:opacity-70 hover:bg--lightGray focus:bg--lightGray Regular"
                      />
                      <Buttonlink
                        onClick={() => openSignIn.current.openPopup()}
                        Text="Login"
                        className="w-full h-12 text-white rounded-full bg--primary hover:opacity-70 hover:bg--primary focus:bg--primary fs-15 Regular"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Fullscreenpopup ref={SelectFullPopup} title={"Select Tags"} /> */}
        <Signinpopup ref={openSignIn} protect={true} />
        <KeepChecking open={open} history={history} path={`/discussions`}/>
      </>
    );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(RFQsDiscussion);
