import {gql} from "@apollo/client";

export default gql`
    mutation createQuestion(
        $id:Int
        $title: String!
        $description: String
        $categories: [Int!]!
        $user_ids: [Int]
        $tag_ids: [Int!]!
        $emails: [String]
        $status: Int!
        $course_id: Int
        $meta_text: String
        $pictures: [Object]
        $videos: [Object]
    ) {
        createQuestion(
            id:$id
            question: $title
            description: $description
            categories: $categories
            tagged_users: $user_ids
            tagged_keywords: $tag_ids
            invite_users: $emails
            status: $status
            meta_text: $meta_text
            pictures: $pictures
            videos: $videos
            course_id: $course_id
        ) {
            id
            question
            description
            created_at
            voteStatus
            saveForCurrentUser
            status
            slug
            meta_text
            tags {
                id
                tag_title
            }
            categories{
                id
                name
            }
            invites{
                id
                email
            }
            attachments {
                id
                url
                type
                width
                height
                public_image
            }
            users {
                id
                firstname
                lastname
                profile_photo
                color
                sg_badge
                sg_paid_user
                is_follower
                username
            }
            likes(first: 1) {
                data {
                    users {
                        id
                    }
                }
                paginatorInfo {
                    total
                }
            }
            dislikes(first: 1) {
                data {
                    users {
                        id
                    }
                }
                paginatorInfo {
                    total
                }
            }
            answers(first: 1) {
                data {
                    id
                    answer
                    voteStatus
                    created_at
                    likes(first: 1) {
                        paginatorInfo {
                            total
                        }
                    }
                    users {
                        id
                        username
                        firstname
                        lastname
                        profile_photo
                        is_pro
                    }
                    comments(first: 1) {
                        paginatorInfo {
                            total
                        }
                    }
                }
                paginatorInfo {
                    total
                }
            }
        }
    }
`;
