import React, { useEffect, useRef, useState } from "react";
import { Buttonlink, IsLoader, Noconnection, Pagenotfound } from "components";
import { FiPlusCircle } from "react-icons/fi";
import { Query } from "@apollo/client/react/components";
import GET_SG_USER_BY_ID from "../../graphql/SgQueries/SgGetUserByID";
import Avatar from "@mui/material/Avatar";
import Auth from "../../Auth";
import { connect } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import Item from "./item";
import { Box, Checkbox, LinearProgress, TextareaAutosize } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useMutation } from "@apollo/client";
import UPDATE_PRODUCT_MUTATION from "../../graphql/mutations/addSgProduct";
import GET_SG_USER_PRODUCT from "../../graphql/SgQueries/SgUserProducts";
import convertToSlug from "../../helpers/convertToSlug";
import DialogBox from "./DialogBox";
import encryptfunction from "../../helpers/Encrypt";
import { IoCloseCircle } from "react-icons/io5";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/semantic.css";
import CategoryDropDown from "../../components/CategoryDropDown";
import CategoriesPopup from "./Categories";
import CompanyDetailPopup from "./CompanyDetail";
import ChartsData from "./Charts";
import cookie from "js-cookie";
import { GRAPH_USER_AUTH_TOKEN } from "../../config/constants";
import Resizer from "react-image-file-resizer";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import { BsArrowRight } from "react-icons/bs";
import { Switch } from "@headlessui/react";

const SellerProfile = (props) => {
  let variables = {};
  if (props?.id) {
    variables.id = props?.id;
  }
  const auth = new Auth();

  const [disable, setdisable] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [disabled, setdisabled] = useState(false);
  const [productAttachmentArray, setProductAttachmentArray] = useState([]);
  const ref = useRef(null);

  // const [loadMoreQuestions, SetLoadMoreQuestions] = useState(true);
  const [searchProduct, SetSearchProduct] = useState({
    slug: "",
    stock: null,
    category_ids: [],
  });

  const [Product, SetProduct] = useState({
    name: "",
    slug: "",
    description: "",
    attachment: "",
    type: "",
    id: 0,
    category_ids: [],
    stock: 0,
    edit: false,
  });

  const clearProduct = () => {
    SetProduct({
      name: "",
      slug: "",
      description: "",
      attachment: "",
      type: "",
      id: 0,
      category_ids: [],
      stock: 0,
      edit: false,
    });
    setSelectedImage(null);
  };

  const EditProduct = (product) => {
    // console.log("edit", product);
    let categories = [];
    product.categories.map((category) => categories.push(parseInt(category.id)));
    SetProduct({
      id: product.id,
      name: product.name,
      description: product.description,
      stock: product.in_stock,
      type: product.type,
      attachment: product.attachment,
      category_ids: categories,
      edit: true,
    });
    setTimeout(() => {
      ref.current.focus();
    });
  };

  useEffect(() => {
    if (progress !== 0) {
      setProgress(progress);
    }
  }, [progress]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Product.name) {
      SetProduct({ ...Product, slug: convertToSlug(Product.name) });
    }
  }, [Product.name]);

  const onLoadMore = (data, fetchMore, refetch) => {
    setdisabled(true);
    // if (data?.sgUserProducts?.paginatorInfo?.hasMorePages) {
    //   SetLoadMoreQuestions(true);
    // } else {
    //   SetLoadMoreQuestions(false);
    // }
    fetchMore({
      variables: {
        cursor: data.sgUserProducts.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.sgUserProducts.data;
        const pageInfo = fetchMoreResult.sgUserProducts.paginatorInfo;
        setdisabled(false);
        return newQuestions.length
          ? {
            sgUserProducts: {
              __typename: previousResult.sgUserProducts.__typename,
              data: [...previousResult.sgUserProducts.data, ...newQuestions],
              paginatorInfo: pageInfo,
            },
          }
          : previousResult;
      },
    });
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = [
      "png",
      "jpeg",
      "jpg",
      "tif",
      "mp4",
      "avi",
      "webm",
      "mov",
      "mpeg4",
    ];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const imageChange = (e) => {
    const imageCount = productAttachmentArray.filter((item) => item?.type.startsWith('image')).length;
    const videoCount = productAttachmentArray.filter((item) => item?.type.startsWith('video')).length;
    const newFiles = Array.from(e.target.files);
    const totalImageCount = imageCount + newFiles.filter((item) => item.type.startsWith('image/')).length;
    const totalVideoCount = videoCount + newFiles.filter((item) => item.type.startsWith('video/')).length;

    if (totalVideoCount > 1) {
      notify("You can select only 1 Video", "error");
      return;
    } else if (totalImageCount > 5) {
      notify("You can select 5 Images max", "error");
      return;
    }

    newFiles.forEach((file) => {
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

      if (sizeInMB > 10) {
        notify(`Your file size is ${sizeInMB} MB. Allowed size is 10MB`, "error");
        return;
      } else if (!isValidFileUploaded(file)) {
        notify("File format supports only images and videos", "error");
        return;
      }

      // Determine the type of the new file
      const fileType = file.type.startsWith('image') ? 'image' : file.type.startsWith('video') ? 'video' : null;
      // Check if adding the new file exceeds the allowed limits
      if ((fileType === 'image' && totalImageCount > 5) || (fileType === 'video' && totalVideoCount > 1)) {
        notify(`You can only upload ${fileType === 'image' ? '5 images' : '1 video'}.`, "error");
      } else {
        // Check if adding the new file exceeds the overall limits
        if (totalImageCount + totalVideoCount <= 6) {
          // Create a new array with the existing values and the new file
          setProductAttachmentArray((prevArray) => [...prevArray, file]);
        } else {
          notify("You can only upload a total of 5 images and 1 video.", "error");
        }
      }
    });
  };






  const removeSelectedImage = (index) => {
    setProductAttachmentArray((prevArray) => {
      // Directly modify the array in place
      prevArray.splice(index, 1);
      return [...prevArray];
    });
  };


  const changeHandler = (e) => {
    SetProduct({
      ...Product,
      [e.target.name]: e.target.value.replace(/[^\w-]+/g, " "),
    });
  };

  const notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    clearProduct();
    toast.success(message, { position: "bottom-right" });
  };

  const fileChangedHandler = async (file) => {
    if (!file) return null;

    try {
      const compressedFile = await Resizer.imageFileResizer(
        file,
        300,
        300,
        "webp",
        100,
        0,
        (uri) => uri,
        "file",
        150,
        150
      );

      // console.log('Compressed file:', compressedFile);
      return compressedFile;
    } catch (err) {
      console.error(err);
      return null;
    }
  };


  const onImageChange = (file) => {
    let category_id = Product.category_ids.map(value => parseInt(value, 10))
    // let compressedImage = {};
    // let compressedArray = [];
    // console.log('file', file);
    // file.forEach((file) => {
    //   compressedImage = fileChangedHandler(file);
    //   compressedArray.push(compressedImage);
    // });
    // console.log('file2222222222222222222222', typeof (compressedArray[0]));
    let token = cookie.get(GRAPH_USER_AUTH_TOKEN);
    if (file.length !== 0) {
      let ImageDataObject = new FormData();
      // ImageDataObject.append("file", file);
      for (let i = 0; i < file.length; i++) {
        ImageDataObject.append(`file[${i}]`, file[i]);
      }
      // ImageDataObject.append("name", "file");
      // ImageDataObject.append("type", file.type);
      ImageDataObject.append("email", props?.authUser?.email);
      ImageDataObject.append("user_id", props?.authUser?.id);
      if (Product.edit) {
        ImageDataObject.append("product_id", parseInt(Product.id));
      }
      // console.log("ImageDataObject",ImageDataObject)
      axios
        .post(
          `${process.env.REACT_APP_MRO_URL}/api/product/upload`,
          ImageDataObject,
          {
            onUploadProgress: (data) => {
              // console.log(Math.round((data.loaded/data.total) * 100))
              setProgress(Math.round((data.loaded / data.total) * 100));
            },
          }
        )
        .then((res) => {
          // console.log('res', res);
          let data = {};
          if (res.data?.success) {
            data.name = Product.name;
            data.slug = Product.slug;
            data.description = Product.description;
            data.attachment = res.data.file;
            data.type = res.data.type;
            data.id = res.data?.product_id;  //parseInt(res.data?.product_id);
            data.in_stock = Product.stock ? 1 : 0;
            data.categories_ids = category_id;
            setProgress(0);
            if (res?.data?.product_id) {
              addProduct({ variables: data });
            } else {
              notify("oops something went wrong please try again!", "error");
            }
            notify("Media is uploaded!!", "success");
          } else {
            return notify(
              "Image is not uploaded please try again later",
              "error"
            );
          }
          // Don't forget to return something
        })
        .catch((error) => {
          return notify(error, "error");
          // console.log("image error", error);
        });
      // SetProduct({attachment:img});
    }
  };

  const ProductList = (user) => {
    let variables = {};
    variables.user_id = props?.id;
    if (searchProduct.slug !== "") {
      variables.name = searchProduct.slug;
    }
    if (searchProduct.stock !== null) {
      variables.stock = searchProduct.stock;
    } else {
      delete variables.stock;
    }
    if (searchProduct.category_ids.length > 0) {
      variables.categories = searchProduct.category_ids;
    }
    return (
      <Query
        query={GET_SG_USER_PRODUCT}
        variables={variables}
        // fetchPolicy={"cache-and-network"}
        id={2}
      >
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading)
            return (
              <div className="h-[80vh] flex items-center justify-center">
                <IsLoader className="w-32 h-32 mx-auto " />
              </div>
            );
          if (error) {
            extractValidationErrors(error)
            return (
              <div>
                <Noconnection />
              </div>
            );
          }
          if (data?.sgUserProducts?.data?.length === 0)
            return <div className={"pt-4"}>{/* <Nocontent /> */}</div>;
          if (data) {
            let products = data?.sgUserProducts?.data;
            // console.log("products", products);
            // console.log('first', productAttachmentArray, products);
            return (
              <>
                <div className="grid grid-cols-1 gap-4 mt-10 lg:grid-cols-3 sm:grid-cols-2">
                  {products.map((product) => {
                    return (
                      <Item
                        product={product}
                        user={user}
                        localState={Product}
                        authUser={props.authUser}
                        EditProduct={(product) => {
                          EditProduct(product);
                        }}
                        alert={(val) => notify(val, "success")}
                        callback={(v) => {
                          if (
                            v ===
                            "Please Update Previous Selected Product First"
                          ) {
                            notify(v, "error");
                          } else {
                            setdisable(false);
                            v !== "load"
                              ? notify(
                                "Product deleted successfully",
                                "success"
                              )
                              : refetch();
                          }
                        }}
                      />
                    );
                  })}
                </div>
                {data?.sgUserProducts?.paginatorInfo?.hasMorePages && (
                  <Buttonlink
                    onClick={() => onLoadMore(data, fetchMore, refetch)}
                    disabled={disabled}
                    Text="Load More >>"
                    className="border-0 font-semibold primary mt-10 Poppins"
                  />
                )}
              </>
            );
          }
        }}
      </Query>
    );
  };

  const updateCache = (cache, { data }) => {
    const existingData = cache.readQuery({
      query: GET_SG_USER_PRODUCT,
      variables: { user_id: props?.id },
    });
    const newData = data.createProduct;
    cache.writeQuery({
      query: GET_SG_USER_PRODUCT,
      variables: { user_id: props?.id },
      data: {
        sgUserProducts: {
          data: [newData, ...existingData.sgUserProducts.data],
        },
      },
    });
  };

  const [addProduct] = useMutation(UPDATE_PRODUCT_MUTATION, {
    update: updateCache,
    onCompleted(pro) {
      setSelectedImage(null);
      setdisable(false);
      setProductAttachmentArray([]);
      notify(
        `${Product.edit ? "Product Updated" : "Product Created"}`,
        "success"
      );
      setTimeout(() => {
        SetProduct({
          name: "",
          slug: "",
          description: "",
          attachment: "",
          type: "",
          id: 0,
          category_ids: [],
          stock: 0,
          edit: false,
        });
      });
    },
  });

  const onClose = () => {
    setOpen(false);
    setCategoryOpen(false);
    setDetailOpen(false);
  };

  let filled = false;
  if (
    Product.name !== "" &&
    Product.description !== "" &&
    Product.category_ids.length > 0
  ) {
    if (Product.edit) {
      filled = true;
    }
    if (productAttachmentArray.length !== 0 && !Product.edit) {
      filled = true;
    }
  } else {
    filled = false;
  }

  let cat_ids = [];
  if (Product.category_ids) {
    for (let i = 0; i < Product.category_ids.length; i++) {
      cat_ids.push(parseInt(Product.category_ids[i]));
    }
    // console.log("cat_ids", cat_ids);
  }

  function selectStockSearch(event, data) {
    SetSearchProduct({ ...searchProduct, stock: data });
  }

  let timeoutValue = null;
  return (
    <div>
      <ToastContainer />
      <Query
        query={GET_SG_USER_BY_ID}
        variables={variables}
        fetchPolicy={"cache-and-network"}
        id={2}
      >
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading)
            return (
              <div className="h-[80vh] flex items-center justify-center">
                <IsLoader className="w-32 h-32 mx-auto " />
              </div>
            );
          if (error) {
            return (
              <div>
                <Noconnection />
              </div>
            );
          }
          if (data) {
            let user = data.sgUser;
            let sgFeatures = user.remaining_counts[0];
            // if (!user?.is_seller) {
            //   return <Pagenotfound />;
            // }
            let datatype = productAttachmentArray.map((image) => (image ? image.type.split("/") : []));

            return (
              <div className="container pt-10 -mt-[50px]">
                <Buttonlink
                  Text="<< Back"
                  to={'/sellers'}
                  className="flex px-4 rounded-full Poppins primary !text-lg"
                />
                <div className="mt-5">
                  <h1 className="text-new-color font-light Poppins md:text-[50px] text-[30px]">Discover Unique Sellers and <br /> Their Extraordinary Services</h1>
                  <p className="text-lg text-new-color pt-5 Poppins">Experience personalized services from sellers who take the time to understand your needs and preferences. <br /> Maximize Efficiency, Minimize Cost: Unlock the secrets of Procurement Outsourcing!</p>
                </div>
                <div className="mt-20">
                  <div className="px-5 py-10 shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl">
                    <div className="flex justify-start md:flex-nowrap flex-wrap flex-1 items-center">
                      <div className="relative flex-none w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 image-fit">
                        {user?.profile_photo ? (
                          <img
                            className="object-cover w-full h-full mr-3 rounded-full"
                            onError={(e) => {
                              e.target.src = `${process.env.REACT_APP_MAIN_URL}/images/user_images/nobody_m.original.jpg`;
                            }}
                            src={`${process.env.REACT_APP_MRO_URL}/uploads/profile_images/${user.profile_photo}`}
                            alt={user.username}
                          />
                        ) : (
                          <Avatar
                            id={user?.id}
                            alt={user?.firstname}
                            className={
                              "w-full h-full rounded-full object-cover mr-3"
                            }
                            sx={{ height: 118, width: 118 }}
                          />
                        )}
                      </div>
                      <div className="ml-5">
                        <div className="flex items-center gap-x-1">
                          <h4 className="text-2xl Poppins font-semibold text-new-color">
                            {user.firstname} {user.lastname}
                          </h4>
                          <div className="-mt-4 bg--lightPrimary rounded-md py-1 cursor-pointer flex items-center justify-center w-20 text-[12px]">
                            Seller
                          </div>
                        </div>
                        <p className="text-gray-600 py-1 truncate sm:whitespace-normal Poppins text-md">
                          @{user.username}
                        </p>
                        <p>{user?.tagline}</p>
                        <div className="flex flex-col gap-3 mt-3 lg:items-center lg:flex-row">
                          <Buttonlink
                            Text="Company Detail"
                            Lasticon={<BsArrowRight size={22} />}
                            onClick={() => setDetailOpen(true)}
                            className="bg--primary Poppins text-white hover:text-white hover:opacity-80 flex items-center justify-center gap-3 h-10 px-10 white rounded-full text-[15px]"
                          />
                          <Buttonlink
                            Text="Categories"
                            Lasticon={<BsArrowRight size={22} />}
                            onClick={() => setCategoryOpen(true)}
                            className="bg--primary Poppins text-white hover:text-white hover:opacity-80 flex items-center justify-center gap-3 h-10 px-10 white rounded-full text-[15px]"
                          />
                          <Buttonlink
                            Text="Reviews"
                            Lasticon={<BsArrowRight size={22} />}
                            onClick={() => setOpen(true)}
                            className="bg--primary Poppins text-white hover:text-white hover:opacity-80 flex items-center justify-center gap-3 h-10 px-10 white rounded-full text-[15px]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 intro-y">
                  <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                    {auth.isAuthenticated() &&
                      props.authUser?.email === user?.email && (
                        <>
                          <div className="shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl p-5">
                            <div className="flex items-center px-5 py-1">
                              <input
                                ref={ref}
                                className="w-full h-12 px-3 border-0 Poppins focus:bg-gray-100 rounded-lg bg-[#F2F2F2]"
                                value={Product.name}
                                placeholder={"Add Product Name"}
                                name={"name"}
                                maxLength={50}
                                onChange={changeHandler}
                              />
                            </div>
                            <div className="p-5 space-y-5">
                              <TextareaAutosize
                                minRows={6}
                                maxLength={500}
                                placeholder="Type your product description..."
                                className="w-full h-full p-3 border-0 rounded-lg shadow-none resize-none bg-[#F2F2F2] outline-0"
                                name="description"
                                value={Product.description}
                                onChange={changeHandler}
                              />
                               <div className="flex items-center gap-3">
                                <Switch
                                    checked={Product.stock}
                                    onChange={() => {
                                      SetProduct({
                                        ...Product,
                                        stock: !Product.stock,
                                      });
                                    }}
                                    className={`important:bg--lightGray relative inline-flex h-10 w-20 items-center rounded-full`}
                                  >
                                    <span className="sr-only">
                                        Enable notifications
                                    </span>
                                    <span
                                        className={`${
                                          Product.stock
                                                ? "translate-x-11 bg--primary"
                                                : "translate-x-1 bg-white"
                                        } inline-block h-8 w-8 transform rounded-full transition-all duration-500`}
                                    />
                                </Switch>
                                {Product.stock ? (
                                  <p className="primary Poppins text-lg font-normal">In Stock</p>
                                  ) : (
                                    <p className="Poppins darkGray text-lg font-normal">Out of Stock</p>
                                )}
                              </div>
                              <CategoryDropDown
                                selected={Product.category_ids}
                                edit={Product.edit}
                                categories={user.categories}
                                className={`!border-none lg:!rounded-lg !bg-[#F2F2F2] gray Poppins`}
                                placeholder={"Category Expertise"}
                                callback={(array) => {
                                  SetProduct({
                                    ...Product,
                                    category_ids: array,
                                  });
                                }}
                              />
                              {Product.attachment !== "" && (
                                <div>
                                  <p className="text-[12px] text-gray-700 Light mt-[3px]">
                                    Previous Select Media
                                  </p>
                                  {Product.type === "mp4" ? (
                                    <video
                                      width="24"
                                      height="24"
                                      className="relative object-contain w-24 h-24 bg-white border rounded-lg"
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_MRO_URL +
                                          "/" +
                                          Product.attachment
                                        }
                                        type={"video/" + Product.type}
                                      />
                                    </video>
                                  ) : (
                                    <img
                                      alt={Product.slug}
                                      src={`${process.env.REACT_APP_MRO_URL}/${Product.attachment}`}
                                      className="relative object-contain w-24 h-24 bg-white border rounded-lg"
                                    />
                                  )}
                                </div>
                              )}
                              <div>
                                <label
                                  htmlFor="dropzone-file"
                                  className="flex items-center px-5 w-full h-12 rounded-lg bg-[#F2F2F2] cursor-pointer"
                                >
                                  <p className="font-light text-[15px] Poppins gray">
                                    Upload {Product.edit && "New One"}
                                  </p>
                                  <input
                                    id="dropzone-file"
                                    type="file"
                                    accept="image/*,video/*"
                                    max={5}
                                    // value={Product.attachment}
                                    className="hidden"
                                    name={"attachment"}
                                    // onChange={(event) => onImageChange(event)}
                                    onChange={imageChange}
                                    multiple
                                  />
                                </label>
                                <p className="text-[12px] text-new-color Poppins mt-[3px]">
                                  Upload limit 10MB*
                                </p>
                              </div>
                              <div className="flex gap-3 items-center flex-wrap">
                                {productAttachmentArray.map((image, index) => (
                                  <div key={index} className="relative w-24 h-24 bg-white border rounded-lg mr-2">
                                    <IoCloseCircle
                                      onClick={() => removeSelectedImage(index)}
                                      className="absolute z-50 text-red-600 bg-white rounded-full cursor-pointer -right-1 -top-1"
                                      size={20}
                                    />
                                    {datatype && datatype[index][0] === "image" ? (
                                      <img
                                        alt={`product-${index}`}
                                        src={URL.createObjectURL(image)}
                                        className="object-contain w-full h-full rounded-lg"
                                      />
                                    ) : (
                                      <video
                                        width="750"
                                        height="500"
                                        className="object-contain w-full h-full rounded-lg"
                                        controls
                                      >
                                        <source src={URL.createObjectURL(image)} type={image.type} />
                                      </video>
                                    )}
                                  </div>
                                ))}
                              </div>

                              {progress > 0 && (
                                <Box sx={{ width: "100%" }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                  />
                                </Box>
                              )}

                              {filled ? (
                                <div className="flex items-start justify-start mt-5">
                                  <Buttonlink
                                    disabled={disable}
                                    Lasticon={<BsArrowRight size={20} />}
                                    onClick={() => {
                                      setdisable(true);
                                      setTimeout(() => {
                                        Product?.edit && productAttachmentArray.lenth === 0
                                          ? addProduct({
                                            variables: {
                                              id: parseInt(Product.id),
                                              name: Product.name,
                                              slug: Product.slug,
                                              description:
                                                Product.description,
                                              in_stock: Product.stock ? 1 : 0,
                                              categories_ids: cat_ids,
                                            },
                                          })
                                          : onImageChange(productAttachmentArray);
                                      });
                                    }}
                                    className={`${disable
                                      ? "bg--gray hover:bg--gray focus:bg--gray"
                                      : "bg--primary hover:bg--primary focus:bg--primary"
                                      } h-10 px-10 text-white mr-3 Poppins hover:opacity-90 flex justify-center items-center gap-3 rounded-full border-0 shadow-none`}
                                    Text={Product?.edit ? "Update" : "Post"}
                                  />
                                </div>
                              ) : (<div className="flex items-start justify-start mt-5">
                                <Buttonlink
                                  disabled={true}
                                  Lasticon={<BsArrowRight size={20} />}
                                  onClick={() => {
                                    setdisable(true);
                                    setTimeout(() => {
                                      Product?.edit && productAttachmentArray.lenth === 0
                                        ? addProduct({
                                          variables: {
                                            id: parseInt(Product.id),
                                            name: Product.name,
                                            slug: Product.slug,
                                            description:
                                              Product.description,
                                            in_stock: Product.stock ? 1 : 0,
                                            categories_ids: cat_ids,
                                          },
                                        })
                                        : onImageChange(productAttachmentArray);
                                    });
                                  }}
                                  className={`${"bg--gray hover:bg--gray focus:bg--gray"} h-10 px-10 text-white flex justify-center items-center gap-3 mr-3 Poppins hover:opacity-90 rounded-full border-0 shadow-none`}
                                  Text={Product?.edit ? "Update" : "Post"}
                                />
                              </div>)}
                            </div>
                          </div>
                          <div className="shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl h-max">
                            <div className="flex items-center px-5 py-3">
                              <h4 className="mr-auto text-3xl font-semibold Poppins">
                                Purchase Plan Statistics
                              </h4>
                            </div>
                            <div className="p-5 space-y5">
                              <div className="grid grid-cols-1 gap-5 divide-y-2 md:divide-y-0 md:gap-20 md:grid-cols-2">
                                <div>
                                  <h4 className="mx-auto text-3xl text-center Poppins font-semibold">
                                    Products
                                  </h4>
                                  <div className="flex items-center mt-5 justify-center">
                                    <ChartsData sgFeaturesTotal={sgFeatures.total_products} sgFeaturesRemaining={sgFeatures.used_products} />
                                  </div>
                                </div>
                                <div>
                                  <h4 className="mx-auto text-3xl text-center Poppins font-semibold">
                                    Videos
                                  </h4>
                                  <div className="flex items-center mt-5 justify-center">
                                    <ChartsData sgFeaturesTotal={sgFeatures.total_videos} sgFeaturesRemaining={sgFeatures.used_videos} />
                                  </div>
                                </div>
                                {/* <div>
                                  <h4 className="mr-auto text-md Medium">
                                    Submit Quotes
                                  </h4>
                                  <div className="flex items-center justify-center">
                                    <ChartsData />
                                  </div>
                                </div>
                                <div>
                                  <h4 className="mr-auto text-md Medium">
                                    Visitors
                                  </h4>
                                  <div className="flex items-center justify-center">
                                    <ChartsData />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                  {/*{user.products?.paginatorInfo?.total > 0 && (*/}
                  <div>
                    <div className="mt-5">
                      <h4 className="text-new-color font-light Poppins md:text-[50px] text-[30px]">
                        Search Products / Services
                      </h4>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mt-5">
                      <div className="rounded-lg h-max bg-[#F2F2F2]">
                        <input
                          className="relative block bg-transparent w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none focus:z-10 sm:text-sm"
                          placeholder={"Product / Service name"}
                          name={"name"}
                          defaultValue={searchProduct.slug}
                          onChange={(e) => {
                            clearTimeout(timeoutValue);
                            timeoutValue = setTimeout(() => {
                              SetSearchProduct({
                                ...searchProduct,
                                slug: e.target.value,
                              });
                            }, 1000);
                          }}
                          maxLength={200}
                        />
                      </div>
                      <div className="rounded-lg h-max bg-[#F2F2F2]">
                        <Dropdown
                          clearable
                          className="w-full !border-0 !rounded-lg !bg-transparent !min-h-12 gray Poppins"
                          placeholder="Stock"
                          fluid
                          selection
                          defaultValue={searchProduct.stock}
                          options={[
                            { key: "1", value: true, text: "In Stock" },
                            { key: "2", value: false, text: "Out of Stock" },
                          ]}
                          onChange={(event, data) => {
                            selectStockSearch(event, data.value);
                          }}
                        />
                      </div>
                      {auth.isAuthenticated() && (
                        <div className={`rounded-lg bg-[#F2F2F2] h-max`}>
                          <CategoryDropDown
                            categories={user.categories}
                            className="w-full !border-0 !rounded-lg !bg-transparent !min-h-12 gray Poppins"
                            placeholder={"Search by Category"}
                            callback={(array) => {
                              clearTimeout(timeoutValue);
                              timeoutValue = setTimeout(() => {
                                const integerArray = array.map((element) => parseInt(element, 10));

                                SetSearchProduct({
                                  ...searchProduct,
                                  category_ids: integerArray,
                                });
                              }, 1000);
                            }}
                          />
                        </div>
                      )}
                      {/*  <div className="flex justify-center col-span-2">
                    <Buttonlink onClick={()=>sendRequest} className={"bg--primary text-white hover:text-white hover:opacity-80 flex items-center justify-center h-[40px] px-10 white rounded-full text-[15px] mt-10 md:mt-0"} Text={"Search"} />
                    </div>*/}
                    </div>
                  </div>
                  {/*)}*/}
                  {ProductList(user)}
                </div>
                <DialogBox show={open} close={onClose} user={user} />
                <CategoriesPopup
                  show={categoryOpen}
                  close={onClose}
                  user={user}
                />
                <CompanyDetailPopup
                  show={detailOpen}
                  close={onClose}
                  user={user}
                  authUser={props?.authUser}
                />
              </div>
            );
          }
        }}
      </Query>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});
export default connect(mapStateToProps, null)(SellerProfile);
