import React from "react";
import knowledge from "assets/images/knowledge.png";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const TrainingModuleFooter = ({ type }) => {
	return (
		<div className="mt-28 -mb-36">
			<div
				className={`knowledge-pocket lg:h-[400px] md:h-[400px] h-[350px] w-full relative`}
			>
				<img
					src={knowledge}
					alt="knowledge"
					className="w-full h-full object-cover md:object-fill"
				/>
				<div className="absolute w-full h-full inset-0">
					<div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
						<div className="col-span-12 sm:col-span-10 md:col-span-8 lg:col-span-8 space-y-4 h-full flex justify-center flex-col">
							<p
								className={`white Poppins ${
									type === "job"
										? "lg:text-3xl md:text-2xl text-xl"
										: "lg:text-6xl md:text-4xl text-2xl"
								} font-extralight lg:text-left md:text-left text-center`}
							>
								{type === "job"
									? "Unlock Top Talent in Procurement & Supply Chain"
									: "Training Modules"}
							</p>
							<div className="h-px w-full bg-white" />
							{type === "job" ? (
								<div className="text-white flex flex-col justify-start items-start gap-y-2 text-lg">
									<div className="ml-6">
										<li>
											Post your jobs where the best candidates are looking
										</li>
										<li>Accelerate your hiring process</li>
										<li>Find specialists who speak your industry language</li>
									</div>
									<div className="text-lg">
										Reach the right candidates. Streamline your hiring.
									</div>
									<div className="text-lg">Contact us to post your Jobs!</div>
								</div>
							) : (
								<p className="text-lg font-normal text-white">
									Let's get this conversation started. We are looking for your
									queries, feedback, and everything else you want to discuss.
								</p>
							)}
							<Link
								to={type === "job" ? "/contact-us" : "/module"}
								className="py-2 text-xl Lato border-2 font-bold self-start Poppins !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-60"
							>
								{type === "job" ? "Contact Us" : "Find Out More"}{" "}
								<BsArrowRight size={24} />{" "}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrainingModuleFooter;
