import React, { useEffect } from "react";
import { BsArrowDown, BsArrowRight, BsPlusLg } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Subtract from "assets/images/Subtract.png";
import Growth from "assets/images/Sellers/Growth.png";
import Offer from "assets/images/Sellers/Offer.png";
import Community from "assets/images/Sellers/Community.png";
import { Knowledgepocket } from "components";
import { Link } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";
import banner from "assets/images/Sellers/banner.webp"
import quotes from "assets/images/our-team/quotes.png";
import saas_product from "assets/images/Sellers/saas_product.png";
import supplier from "assets/images/Sellers/supplier.png";
import doctor from "assets/images/Sellers/noun-doctor.png";
import research from "assets/images/Sellers/research.png";
import events from "assets/images/Sellers/noun-events.png";
import forum from "assets/images/Sellers/noun-forum.png";
import knowledge from "assets/images/Sellers/noun-knowledge.png";
import Vineeta from "assets/images/Sellers/Vineeta.jpg";
import BillHuber from "assets/images/Sellers/BillHuber.png";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

const SellerNewDesign = () => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const history = useHistory();

    const accordionData = [
        {
            id: "panel1",
            title: "Content",
            heading: "Unlock our Content Hub's potential",
            content: [
                "Enhanced Brand Visibility",
                "Brand Differentiation",
                "Increased Audience Engagement",
                "Competitive Advantage",
                "Lead Generation",
                "Revenue Growth",
            ],
            testimonials: [
                { description: "Procurement League produces some of the best supply chain documents and summaries and this isn't just a statement but rather a fact.", author: 'Gerald R. Ford' },
                { description: 'Procurement League on research initiatives has been highly productive. Their team is responsive, diligent, and delivers top-quality work—a fantastic partner indeed.', author: 'Geoff Talbot' },
            ]
        },
        {
            id: "panel2",
            title: "Advisory",
            heading: "Perks of our Advisory Solution",
            content: [
                "Strategic Guidance",
                "Problem-Solving Expertise",
                "Actionable Solutions",
                "Proactive approach to business development",
                "Maximize impact and ROI",
                "Outshine Competitors",
            ],
            slider: [
                { name: 'Vineeta Bhan', designation: 'Procurement Expert', image: Vineeta },
                { name: 'Bill Huber', designation: 'Partner at ISG', image: BillHuber },
            ]
        },
        {
            id: "panel3",
            title: "Community Technology",
            heading: "Perks of Community Technology",
            image: saas_product,
            content: [
                "Build stronger relationships with your audience",
                "Monetize your community",
                "Create a source of feedback for new ideas",
                "Strengthen your brand identity",
                "Increased Engagement and Loyalty",
                "Opportunities for Collaboration",
            ],
        },
    ];
    const OfferData = [
        {
            id: "panel4",
            title: "As Supplier",
            image: supplier,
            heading: "Perks of being Supplier",
            content: [
                "Gain access to a broad network of potential customers",
                "Increased Sales Opportunities",
                "Trusted Platform",
                "Streamlined Process",
                "Grow Your Business",
            ],
        },
        {
            id: "panel5",
            title: "As practitioners",
            heading: "Perks of being a Practitioner",
            image: doctor,
            content: [
                "Client Connectivity",
                "Unlock Gig Projects",
                "Seize New Opportunities",
                "Visibility",
            ],
        },
        {
            id: "panel6",
            title: "Join Research",
            heading: "Perks of Join Research",
            image: research,
            content: [
                "Professional Development",
                "Contribute to Advancement",
                "Contribute to Advancement Connect with fellow researchers and experts",
                "Engage on real-world applications",
            ],
        },
    ];
    const communityData = [
        {
            id: "panel7",
            title: "Discussion Forum",
            heading: 'Perks for Connection Forum',
            image: forum,
            btn: { text: 'Join The Conversation Now', link: '/discussions' },
            content: [
                "Networking Hub",
                "Knowledge Sharing",
                "Interactive Learning",
                "Community Support",
                "Professional Growth",
            ],
        },
        {
            id: "panel8",
            title: "Events",
            heading: 'Perks for attending Events',
            image: events,
            btn: { text: 'Explore', link: '/events' },
            content: [
                "Gain valuable insights",
                "Networking Opportunities",
                "Free Access",
                "Stay Informed",
                "Inspiration",
            ],
        },
        {
            id: "panel9",
            title: "Resources",
            heading: 'Perks of our Knowledge Bank',
            image: knowledge,
            btn: { text: 'Get Inspired Today', link: '/knowledge-bank' },
            content: [
                "Rich Information Repository",
                "Practical Tips",
                "Exclusive Content",
                "Professional Development",
                "Free Access",
            ],
        },
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="bg--lightBlue pb-28 -mt-[50px]">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden relative">
                        <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] SellerBanner relative">
                        <div className="flex z-10 w-full max-w-4xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Connect Connection Commerce
                            </p>
                            <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Unlock Your Business Potential: Grow, Sell, and Thrive
                            </p>
                            <p className="white Poppins font-normal">
                                Platform where seller meet buyers <br /> 
                                Transform your selling experience and take your business to new heights today.
                            </p>
                            <div className="h-px bg-white mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={Subtract}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>
                <div className="pt-28 container mx-auto lg:relative">
                    <div className="absolute lg:block hidden left-0 top-0">
                        <svg
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            />
                        </svg>
                    </div>
                    <div className="absolute lg:block hidden right-0 -top-10">
                        <svg
                            width="108"
                            height="108"
                            viewBox="0 0 108 108"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_144_559)">
                                <path
                                    d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_144_559">
                                    <rect
                                        width="81"
                                        height="81"
                                        fill="white"
                                        transform="translate(0 33.8882) rotate(-24.7319)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="grid pt-10 gap-10 lg:grid-cols-3 md:grid-cols-2 grid-cols-1" id="contentSection">
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Growth}
                                className="w-14 h-14 mb-4"
                                alt="Growth"
                            />
                            <h1 className="Poppins font-light text-[30px] leading-6">
                                Growth Solutions
                            </h1>
                            <p className="Poppins my-5 text-[#16283A] text-[18px] font-normal leading-6">
                                Elevate your business with our Growth Solutions:
                                a powerhouse combination of content, expert
                                advice, and cutting-edge community technology
                                services.
                            </p>
                            <Link to="/growth-solutions" className="Poppins primary text-[18px] font-normal leading-6">
                                Unlock Potential {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Offer}
                                className="w-14 h-14 mb-4"
                                alt="Growth"
                            />
                            <h1 className="Poppins font-light text-[30px] leading-6">
                                Submit Offer
                            </h1>
                            <p className="Poppins my-4 text-[#16283A] text-[18px] font-normal leading-6">
                                Expand your reach and showcase your expertise by
                                easily submitting your offers. Whether you're a
                                skilled practitioner, supplier, or research
                                collaborator, seize the opportunity to connect
                                with potential clients and partners
                            </p>
                            <Link to="/submit-offer" className="Poppins primary text-[18px] font-normal leading-6">
                                Discover more {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Community}
                                className="w-14 h-14 mb-4"
                                alt="Growth"
                            />
                            <h1 className="Poppins font-light text-[30px] leading-6">
                                Community
                            </h1>
                            <p className="Poppins my-4 text-[#16283A] text-[18px] font-normal leading-6">
                                Connect, learn, and collaborate in our thriving
                                Community. Engage in insightful Q&A discussions,
                                gain valuable knowledge from experts.
                            </p>
                            <Link to="/community" className="Poppins primary text-[18px] font-normal leading-6">
                                Join & Succeed {">>"}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="mt-28 container space-y-20">
                    <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                        <div className="flex items-center gap-2">
                            <p className="Poppins text-[15px] text-[#16283A]">
                                Growth Solutions Services
                            </p>
                            <img src={Growth} alt="Growth" className="w-6 h-6" />
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                            <div className="divide-y">
                                {accordionData.map((item) => (
                                    <div key={item.id}>
                                        <Accordion
                                            expanded={expanded === item.id}
                                            onChange={handleChange(item.id)}
                                            className="p-2 m-0 overflow-hidden !shadow-none"
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <BsPlusLg
                                                        className="primary"
                                                        size={24}
                                                    />
                                                }
                                            >
                                                <p className="text-lg Poppins font-semibold text-new-color">
                                                    {item.title}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="!pt-0 grid lg:grid-cols-2 gap-3 grid-cols-1">
                                                <div>
                                                    <p className="text-xl pb-3 primary font-normal">{item.heading}</p>
                                                    <ul className="list-disc space-y-2">
                                                        {item.content.map(
                                                            (content, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="Poppins text-lg font-normal text-new-color"
                                                                >
                                                                    {content}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="w-full max-w-lg mx-auto">
                                                    {
                                                        item?.testimonials &&
                                                        <>
                                                            <img src={quotes} alt="quotes" className="w-[72px] pb-5 mx-auto" />
                                                            <p className="text-center text-3xl text-new-color font-light">Testimonials</p>
                                                            <Slider {...settings} className="slider_dots">
                                                                {
                                                                    item.testimonials.map((testimonial, index) => {
                                                                        return (
                                                                            <div key={index} className="text-center space-y-3">
                                                                                <p className="text-lg text-new-color">
                                                                                    {testimonial.description}
                                                                                </p>
                                                                                <p className="italic text-lg text-new-color font-bold">
                                                                                    {testimonial.author}
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </>
                                                    }
                                                    {
                                                        item?.slider &&
                                                        <Slider {...settings} className="slider_dots">
                                                            {
                                                                item.slider.map((slide, index) => {
                                                                    return (
                                                                        <div key={index} className="text-center">
                                                                            <img src={slide.image} alt={slide.name} className="mx-auto rounded-full grayscale object-cover object-top h-[121px] w-[121px]" />
                                                                            <p className="text-3xl font-light text-new-color">
                                                                                {slide.name}
                                                                            </p>
                                                                            <p className="text-lg text-new-color font-normal">
                                                                                ({slide.designation})
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Slider>
                                                    }
                                                    {
                                                        item?.image &&
                                                        <div className="flex justify-center items-center">
                                                            <img src={item?.image} alt={item?.title} />
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                        <div className="flex items-center gap-2">
                            <p className="Poppins text-[15px] text-[#16283A]">
                                Submit Offer
                            </p>
                            <img src={Offer} alt="Offer" className="w-[35px] h-[32px]" />
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                            <div className="divide-y">
                                {OfferData.map((item) => (
                                    <div key={item.id}>
                                        <Accordion
                                            expanded={expanded === item.id}
                                            onChange={handleChange(item.id)}
                                            className="p-2 m-0 overflow-hidden !shadow-none"
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <BsPlusLg
                                                        className="primary"
                                                        size={24}
                                                    />
                                                }
                                            >
                                                <p className="text-lg Poppins font-semibold text-new-color">
                                                    {item.title}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="!pt-0 grid lg:grid-cols-2 gap-3 grid-cols-1">
                                                <div>
                                                    <p className="text-xl pb-3 primary font-normal">{item.heading}</p>
                                                    <ul className="list-disc space-y-2">
                                                        {item.content.map(
                                                            (content, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="Poppins text-lg font-normal text-new-color"
                                                                >
                                                                    {content}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div>
                                                    {
                                                        item?.image &&
                                                        <div className="flex justify-center items-center">
                                                            <img src={item?.image} alt={item?.title} />
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                        <div className="flex items-center gap-2">
                            <p className="Poppins text-[15px] text-[#16283A]">
                                Community
                            </p>
                            <img src={Community} alt="Community" className="w-[38px] h-[29px]" />
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                            <div className="divide-y">
                                {communityData.map((item) => (
                                    <div key={item.id}>
                                        <Accordion
                                            expanded={expanded === item.id}
                                            onChange={handleChange(item.id)}
                                            className="p-2 m-0 overflow-hidden !shadow-none"
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <BsPlusLg
                                                        className="primary"
                                                        size={24}
                                                    />
                                                }
                                            >
                                                <p className="text-lg Poppins font-semibold text-new-color">
                                                    {item.title}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="!pt-0 grid lg:grid-cols-2 gap-3 grid-cols-1">
                                                <div>
                                                    <p className="text-xl pb-3 primary font-normal">{item.heading}</p>
                                                    <ul className="list-disc space-y-2">
                                                        {item.content.map(
                                                            (content, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="Poppins text-lg font-normal text-new-color"
                                                                >
                                                                    {content}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div>
                                                    {
                                                        item?.image && item.btn &&
                                                        <div className="flex items-center gap-5 flex-col">
                                                            <img src={item?.image} alt={item?.title} />
                                                            <button onClick={() => history.push(item.btn.link)} className="bg--primary hover:opacity-80 text-white px-5 rounded-full w-full max-w-xs md:h-12 md:py-0 py-2 flex justify-between capitalize items-center">{item.btn.text} <BsArrowRight color="white" size={24} /></button>
                                                        </div >
                                                    }
                                                </div >
                                            </AccordionDetails >
                                        </Accordion >
                                    </div >
                                ))}
                            </div >
                        </div >
                    </div >
                </div >
            </div >

            <div className="bg-white relative container pt-28">
                <div className="absolute lg:block hidden -left-20 bottom-36">
                    <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF" />
                    </svg>
                </div>
                <div className="absolute lg:block hidden right-0 bottom-0">
                    <svg
                        width="112"
                        height="112"
                        viewBox="0 0 112 112"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_30_843)">
                            <path
                                d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
                                fill="#B5E2FF"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_30_843">
                                <rect
                                    width="102.698"
                                    height="102.698"
                                    fill="white"
                                    transform="translate(0 102.315) rotate(-85.0461)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -left-20 top-48">
                    <svg
                        width="200"
                        height="200"
                        viewBox="0 0 258 258"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M258 129C258 200.245 200.245 258 129 258C57.7553 258 0 200.245 0 129C0 57.7553 57.7553 0 129 0C200.245 0 258 57.7553 258 129Z"
                            fill="#FFFAEF"
                        />
                    </svg>
                </div>
                <div className="text-center space-y-10">
                    <h1 className="font-light lg:text-[48px] text-[36px] lg:leading-[60px] Poppins">
                        Why Choose Our Showcase Opportunity:
                    </h1>
                    <p className="font-normal text-[18px] leading-6 text-[#16283A] Poppins w-full max-w-[700px] mx-auto">
                        Choose us for accelerated growth, expanded reach,
                        diverse opportunities, an engaging community, and a
                        seamless experience.
                    </p>
                </div>
                <div className="grid mt-24 grid-cols-12 gap-5">
                    <div className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Comprehensive Growth Solutions:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Power your business with strategic content,
                            benchmarking, and social media strategies.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Diverse Opportunities:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Engage as a practitioner, supplier, or research
                            collaborator.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Expanded Reach:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Showcase your expertise and connect with potential
                            clients and partners.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Engaging Community:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Connect, learn, and collaborate with like-minded
                            professionals.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Brand Exposure:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Increase visibility and gain recognition among
                            industry professionals.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Seamless Experience:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            User-friendly platform for easy navigation and
                            access.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                </div>
            </div>

            <div className="h-screen plSaysBannerSeller mt-28">
                <div className="flex justify-end items-center h-[calc(100%-150px)] flex-col px-5">
                    <div className="mb-4">
                        <svg
                            width="110"
                            height="83"
                            viewBox="0 0 110 83"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.5966 42.3267C18.3041 37.4056 24.172 35.2118 30.8864 35.2118C43.2935 35.2118 52.2558 43.72 52.2558 58.0683C52.2558 71.5569 43.8774 83 26.2739 83C10.1008 83 0 71.2605 0 52.1986C0 26.7036 15.0929 6.6931 42.3885 0.082202C43.9942 -0.303186 45.7749 0.704753 46.3296 2.3056L46.8843 3.90644C47.439 5.50728 46.5924 7.07848 44.9867 7.55281C26.9745 12.6814 16.0563 23.8281 13.3413 41.882C13.1077 43.5422 13.5748 43.6904 14.5966 42.3267ZM72.3408 42.3267C76.0483 37.4056 81.9161 35.2118 88.6306 35.2118C101.038 35.2118 110 43.72 110 58.0683C110 71.5569 101.622 83 84.018 83C67.845 83 57.7442 71.2605 57.7442 52.1986C57.7442 26.7036 72.8371 6.6931 100.162 0.082202C101.768 -0.303186 103.548 0.704753 104.103 2.3056L104.658 3.90644C105.212 5.50728 104.366 7.07848 102.76 7.55281C84.7479 12.6814 73.8296 23.8281 71.1146 41.882C70.8519 43.5422 71.3482 43.6904 72.3408 42.3267Z"
                                fill="#F5BA45"
                            />
                        </svg>
                    </div>
                    <h1 className="lg:text-[50px] text-[36px] lg:leading-[60px] mb-7 leading-10 font-light Poppins text-black">
                        What Procurement Leaguers Have To Say
                    </h1>
                    <p className="text-[#16283A] w-full max-w-4xl text-center mx-auto mb-5 Poppins font-normal text-lg">
                        “We completed our IT and Telecom sourcing projects, and
                        contract renewals on time without having to hire a
                        permanent FTE”
                    </p>
                    <p className="font-bold text-lg Poppins text-black">
                        Chief Procurement Officer Global Financial Leader, US
                    </p>
                </div>
            </div>

            <div className="mt-28 -mb-36 container">
                <Knowledgepocket BsArrowRight={BsArrowRight} />
            </div>
        </>
    );
};

export default SellerNewDesign;
