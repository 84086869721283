import { FaUpload } from "react-icons/fa";

export const UploadFile = ({id, children}) => {
	return (
		<div className="relative">
			<label
				title="Click to upload"
				htmlFor={id}
				className="cursor-pointer flex items-center gap-4 px-3 py-2 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-lg before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
			>
				<div className="relative">
					<span className="relative flex justify-center items-center gap-x-4">
                        <FaUpload size={14} />
						<span className="shrink-0">Upload Resume</span>
					</span>
				</div>
			</label>
            {children}
		</div>
	);
};
