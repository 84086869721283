import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FiChevronDown, FiX } from "react-icons/fi";
import { Box, Modal, Typography } from "@mui/material";

export default function Sessions(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [becomePro, setBecomePro] = React.useState(false);
  const [is_enroll, set_is_enroll] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { type, item, authUser } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };
  return (
    <div>
      <div className="flex items-center mb-2">
        <p className="Poppins text-lg font-bold text-new-color">Sessions</p>
      </div>
      {item.course_session.map((session, index) => (
        // <div className="mb-3">
        <Accordion
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          className="p-0 mb-3 overflow-hidden border important:shadow--none rounded--xl">
          <AccordionSummary
            expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
            aria-controls={`panelbh-content-${index}`}
            id={`panelbh-content-${index}`}>
            {
              session?.title ? <p className="text-lg text-new-color">
                {session?.title}
              </p> : 
              <p className="text-lg text-new-color">
                {`${item.course_name + " " + "Course Session#" + " "}`}
                {index + 1}
              </p>
            }
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  alt="Course Session"
                  src={item.course_cover_pic}
                  className="rounded-full w-9 h-9"
                />
                <p className="ml-3">Course Session</p>
                <p className="ml-2">-</p>
                {session.is_paid ? (
                  <p style={{ color: "red" }} className="ml-2">
                    Paid
                  </p>
                ) : (
                  <p style={{ color: "green" }} className="ml-2">
                    Free
                  </p>
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  session.is_paid
                    ? type === "item_based"
                      ? item.is_enroll
                        ? window.open(session.url, "_blank")
                        : setBecomePro(true)
                      : authUser.is_pro
                      ? item.is_enroll
                        ? window.open(session.url, "_blank")
                        : set_is_enroll(true)
                      : setBecomePro(true)
                    : window.open(session.url, "_blank");
                }}>
                Join
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
        // </div>
      ))}
      {/* <Becomeapropopup
        type={type}
        item={item}
        show={becomePro}
        onHide={() => setBecomePro(false)}
      /> */}
      {/*<EnrollPopup show={is_enroll} onHide={() => set_is_enroll(false)} />*/}
      <Modal
        open={becomePro}
        onClose={() => setBecomePro(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex items-center justify-between">
            <Typography variant="h6" component="h2">
              Note!!
            </Typography>
            <div
              onClick={() => setBecomePro(false)}
              className="p-2 -mr-4 rounded-full cursor-pointer hover:bg-gray-200 bg-gray-50">
              <FiX size={22} />
            </div>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please sign-up for Pro level.
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={is_enroll}
        onClose={() => set_is_enroll(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex items-center justify-between">
            <Typography variant="h6" component="h2">
              Note!!
            </Typography>
            <div
              onClick={() => set_is_enroll(false)}
              className="p-2 -mr-4 rounded-full cursor-pointer hover:bg-gray-200 bg-gray-50">
              <FiX size={22} />
            </div>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please become a pro user first
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
