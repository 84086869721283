import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { FaQuoteRight } from "react-icons/fa";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class reviews extends Component {
  render() {
    const settings = {
      dots: true,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative">
        <h4 className="mb-10 text-2xl lg:text-3xl Medium">Reviews</h4>
        <Slider {...settings} className="important:static">
          <div className="lg:pr-20">
            <div className="flex gap-3">
              <div>
                <FaQuoteRight />
              </div>
              <div>
                <p>
                  One of the best insights I ever got through the training
                  series on "S2P/P2P Software Selection, Sourcing, and
                  Implementation" by Anders Lillevik. Ground Level detailed
                  training which I can correlate how the procurement can make an
                  impact in this digital era in the nearer future.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                    className="object-cover mr-3 rounded-full h-11 w-11"
                    alt="Viktor Voros"
                  />
                  <div>
                    <p className="Medium fs-14">Viktor Voros</p>
                    <p className="gray fs-12 Light">Co-Founder</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:pr-20">
            <div className="flex gap-3">
              <div>
                <FaQuoteRight />
              </div>
              <div>
                <p>
                  One of the best insights I ever got through the training
                  series on "S2P/P2P Software Selection, Sourcing, and
                  Implementation" by Anders Lillevik. Ground Level detailed
                  training which I can correlate how the procurement can make an
                  impact in this digital era in the nearer future.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                    className="object-cover mr-3 rounded-full h-11 w-11"
                    alt="Viktor Voros"
                  />
                  <div>
                    <p className="Medium fs-14">Viktor Voros</p>
                    <p className="gray fs-12 Light">Co-Founder</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:pr-20">
            <div className="flex gap-3">
              <div>
                <FaQuoteRight />
              </div>
              <div>
                <p>
                  One of the best insights I ever got through the training
                  series on "S2P/P2P Software Selection, Sourcing, and
                  Implementation" by Anders Lillevik. Ground Level detailed
                  training which I can correlate how the procurement can make an
                  impact in this digital era in the nearer future.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                    className="object-cover mr-3 rounded-full h-11 w-11"
                    alt="Viktor Voros"
                  />
                  <div>
                    <p className="Medium fs-14">Viktor Voros</p>
                    <p className="gray fs-12 Light">Co-Founder</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default reviews;
