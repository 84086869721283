import React from "react";
import { BsArrowRight } from "react-icons/bs";
import Buttonlink from "../buttonlink";
import contact from "../../../assets/images/pricing/contact.webp";

const ContactUsCardPricing = () => {
    return (
        <div className="knowledge-pocket lg:h-[400px] md:h-[400px] h-[300px] w-full relative">
            <img
                src={contact}
                alt="contact"
                className="w-full h-full object-cover"
            />
            <div className="absolute w-full h-full inset-0">
                <div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
                    <div className="lg:col-span-6 md:col-span-6 col-span-12 space-y-4 h-full flex justify-center flex-col">
                        <p className="black Poppins lg:text-5xl md:text-4xl text-3xl font-extralight lg:text-left md:text-left text-center">
                            Contact Us
                        </p>
                        <div className="lg:block md:block hidden space-y-4">
                            <p className="text-black Poppins text-lg">
                                Don't let your potential go unnoticed! The stage
                                is set, the spotlight awaits. Reach out to us
                                today and take center stage like never before!
                            </p>
                            <div className="h-px bg-white w-full" />

                            <Buttonlink
                                href={"/contact-us"}
                                Text={"Contact us"}
                                Lasticon={<BsArrowRight size={23} />}
                                className="py-2 font-bold text-xl border-2 self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-48"
                            />
                        </div>
                    </div>
                    <div className="lg:my-auto md:my-auto lg:col-span-6 md:col-span-6 col-span-12">
                        <div className="space-y-4 lg:hidden md:hidden block">
                            <p className="text-black Poppins text-lg">
                                Don't let your potential go unnoticed! The stage
                                is set, the spotlight awaits. Reach out to us
                                today and take center stage like never before!
                            </p>
                            <Buttonlink
                                to={"/contact-us"}
                                Text={"Contact us"}
                                Lasticon={<BsArrowRight size={23} />}
                                className="py-2 font-bold text-xl border-2 self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-48"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsCardPricing;
