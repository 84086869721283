import React, { Component } from "react";
import "./styles.css";
import { Knowledgepocket, Loader, Noconnection } from "components";
import Subtract from "assets/images/Subtract.png";
import quotes from "assets/images/our-team/quotes.png";
import linkedin from "assets/images/our-team/linkedin.png";
import pattern1 from "assets/images/SuccessStories/pattern_one.png";
import pattern2 from "assets/images/SuccessStories/pattern_two.png";
import banner from "assets/images/our-team/teamBanner.webp";
import our_team from "../../graphql/queries/ourTeams";
import { Query } from "@apollo/client/react/components";

class TeamDetail extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <div className="bg--lightBlue -mt-[50px]">
                    <div className="lg:bg-transparent md:bg-transparent bg-black">
                        <div className="lg:hidden md:hidden relative">
                            <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                        </div>
                        <div className="lg:h-[90vh] md:h-[100vh] TeamsBanner !lg:bg-[center_center] !bg-left relative">
                            <div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                                <p className="text-[#FBE4D3] Poppins font-normal">
                                    About Us - Our Team
                                </p>
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    The People Behind Procurement League
                                </p>
                                <div className="h-px bg-white mt-3" />
                            </div>
                            <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                                <img
                                    src={Subtract}
                                    alt="Subtract"
                                    className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                                />
                            </div>
                        </div>
                    </div>
                    <Query query={our_team}>
                        {({ loading, error, data }) => {
                            if (loading) {
                                return <Loader />;
                            }
                            if (error) {
                                return (
                                    <div>
                                        <Noconnection />
                                    </div>
                                );
                            }
                            return (
                                <>
                                    {data !== undefined && (
                                        <div className="mt-28 relative">
                                            <img
                                                src={pattern1}
                                                alt="Subtract"
                                                className="absolute w-[350px] h-[350px] top-[310px] right-0 z-20 lg:block hidden"
                                            />
                                            <img
                                                src={pattern2}
                                                alt="Subtract"
                                                className="absolute w-[350px] h-[350px] bottom-80 left-0 lg:block hidden"
                                            />
                                            <div className="absolute lg:block hidden left-20 top-44">
                                                <svg
                                                    width="39"
                                                    height="39"
                                                    viewBox="0 0 39 39"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="19.5"
                                                        cy="19.5"
                                                        r="19.5"
                                                        fill="#B5E2FF"
                                                    ></circle>
                                                </svg>
                                            </div>
                                            <div className="absolute lg:block hidden left-[20%] -top-32">
                                                <svg
                                                    width="108"
                                                    height="108"
                                                    viewBox="0 0 108 108"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_2_811)">
                                                        <path
                                                            d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                                            fill="#FFEFE5"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2_811">
                                                            <rect
                                                                width="81"
                                                                height="81"
                                                                fill="white"
                                                                transform="translate(0 33.8882) rotate(-24.7319)"
                                                            ></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="absolute lg:block hidden right-[20%] -top-28">
                                                <svg
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 112 112"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_30_843)">
                                                        <path
                                                            d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
                                                            fill="#B5E2FF"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_30_843">
                                                            <rect
                                                                width="102.698"
                                                                height="102.698"
                                                                fill="white"
                                                                transform="translate(0 102.315) rotate(-85.0461)"
                                                            ></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="absolute lg:block hidden right-20 top-1/4">
                                                <svg
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 112 112"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_30_843)">
                                                        <path
                                                            d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
                                                            fill="#ffffff"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_30_843">
                                                            <rect
                                                                width="102.698"
                                                                height="102.698"
                                                                fill="white"
                                                                transform="translate(0 102.315) rotate(-85.0461)"
                                                            ></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="absolute lg:block hidden right-20 top-1/2">
                                                <svg
                                                    width="144"
                                                    height="144"
                                                    viewBox="0 0 144 144"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="72"
                                                        cy="72"
                                                        r="57"
                                                        fill="white"
                                                        stroke="#FFEFE5"
                                                        strokeWidth="30"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="container flex relative flex-col justify-center items-center space-y-20">
                                                <div className="space-y-20">
                                                    {data?.allteams.map(
                                                        (team) => (
                                                            <div
                                                                key={
                                                                    team?.name
                                                                }
                                                                className="bg-white rounded-[35px] shadow-[0px_4px_40px_0px_#0000001A] md:h-max p-12 z-[10]"
                                                            >
                                                                <div className="grid md:grid-cols-8 gap-5">
                                                                    <div className="md:col-span-2 col-span-8">
                                                                        <img
                                                                            src={
                                                                                team?.image_path
                                                                            }
                                                                            alt={
                                                                                team?.name
                                                                            }
                                                                            className="rounded-full w-[240px] h-[240px] object-cover"
                                                                        />
                                                                    </div>
                                                                    <div className="md:col-span-4 col-span-8 flex flex-col h-max space-y-6">
                                                                        <div className="flex flex-col -space-y-2">
                                                                            <div className="primary Poppins text-[30px] font-[300]">
                                                                                {
                                                                                    team?.name
                                                                                }
                                                                            </div>
                                                                            <div className="Poppins text-[30px] font-[300] flex flex-col uppercase leading-10">
                                                                                {
                                                                                    team?.title
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="Poppins text-[18px] font-[400]">
                                                                            {
                                                                                team?.description
                                                                            }
                                                                        </div>
                                                                        {team?.linkedin && (
                                                                            <a
                                                                                href={
                                                                                    team.linkedin
                                                                                }
                                                                                target="_blank"
                                                                                className="flex justify-start items-center space-x-5"
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        linkedin
                                                                                    }
                                                                                    alt="linkedin"
                                                                                    className="w-[40px] h-[40px]"
                                                                                />
                                                                                <div className="Poppins text-[18px] font-[400]">
                                                                                    {
                                                                                        team?.name
                                                                                    }
                                                                                </div>
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                    <div className="md:col-span-2 col-span-8 flex flex-col space-y-4">
                                                                        <img
                                                                            src={
                                                                                quotes
                                                                            }
                                                                            alt="img"
                                                                            className="w-[102px] h-[77px]"
                                                                        />
                                                                        <div className="Poppins text-[18px] font-[700]">
                                                                            {
                                                                                team?.quote
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    </Query>
                    <div className="mt-28 -mb-36 container">
                        <Knowledgepocket isImageHide={false} />
                    </div>
                </div>
            </>
        );
    }
}

export default TeamDetail;
