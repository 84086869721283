import { Buttonlink } from "components";
import React, { useEffect, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import "./styles.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Iframe from "react-iframe";
import viewVideo from "graphql/mutations/topicVideoViewedMutation";
import { useMutation } from "@apollo/client";

const ChapterDetail = (props) => {
	const videoRef = useRef(null);
	const [videoViewed] = useMutation(viewVideo);
  let watched = 0;
  let total_watched = 0;

  const handleViewed = async () => {
    try {
      const { module_id, content_id, course_id } = props;
      let response = await videoViewed({
        variables: {
          id: Number(module_id),
          content_id: Number(content_id),
          course_id: Number(course_id),
        },
      });
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  };
  
	useEffect(() => {
		const video = videoRef.current;

		const handleTimeUpdate = () => {
      if ((video.currentTime - watched > 2)) {
        if (video.currentTime > total_watched) {
          video.currentTime = total_watched;
        }
      }
      if (total_watched < video.currentTime) {
        total_watched = video.currentTime;
      } 
      watched = video.currentTime;
		};

		const handleEnded = () => {
      handleViewed();
			console.log("Video completed!");
		};

		video.addEventListener("timeupdate", handleTimeUpdate);
		video.addEventListener("ended", handleEnded);

		return () => {
			video.removeEventListener("timeupdate", handleTimeUpdate);
			video.removeEventListener("ended", handleEnded);
		};
	}, []);

	const handleContextMenu = (event) => {
		event.preventDefault();
		return false;
	};

	const history = useHistory();
	const { item, video_path, title, is_scorm, audio_path } = props;

	return (
		<div className="ChapterDetailVideo" onContextMenu={handleContextMenu}>
			<div className="container mt-4">
				<Buttonlink
					Text="back"
					Icon={<IoIosArrowBack className="mr-2" />}
					onClick={history.goBack}
					className="flex items-center justify-center h-8 mb-5 border-0 shadow-none bg--lightGray focus:bg--lightGray hover:bg--lightGray rounded--full Regular darkGray w-28 hover:darkGray hover:opacity-80"
				/>
				{is_scorm && true ? (
					<Iframe
						url={`${process.env.REACT_APP_MAIN_URL}/api/module-view/${title}`}
						width="100%"
						height="800px"
						id="myId"
						className={"myId"}
						overflow={"hidden"}
						display="initial"
						position="relative"
					/>
				) : (
					<div>
						{video_path ?
							<video
								ref={videoRef}
								download={false}
								controlsList="nodownload noplaybackrate"
								controls
								className="w-full rounded-lg"
								src={video_path}
							/>:
							<audio
								ref={videoRef}
								download={false}
								controlsList="nodownload noplaybackrate"
								controls
								className="w-full rounded-lg"
								src={audio_path}
							/>
						}
					</div>
				)}
				<div className="flex flex-col justify-evenly mt-10">
					<div>
						<h2 className="my-2 text-3xl Regular">{item?.course_name}</h2>
						<h5 className="my-2 Regular">{title}</h5>
						<div className="flex items-center">
							<p className="darkGray Light">Trainer:</p>
							<p className="ml-2 primary">
								{item.users.data[0].firstname +
									" " +
									item.users.data[0].lastname}
							</p>
						</div>
						<div className="flex items-center">
							<p className="darkGray Light ">Event Date:</p>
							<p className="ml-2 black">
								{moment(item.publishing_on).format("DD-MM-YYYY")}
							</p>
						</div>
						<div className="flex items-center">
							<p className="darkGray Light">Number of people attending:</p>
							<p className="ml-2 black">{item.total_purchased_by_users}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChapterDetail;
