import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import Devangana from "assets/images/team/Devangana.webp";
import Samantha from "assets/images/team/Samantha.webp";
import Rajiv from "assets/images/team/Rajiv.webp";
import Prerna from "assets/images/team/Prerna.webp";
import Saheena from "assets/images/team/Saheena.webp";
import James from "assets/images/team/james.webp";
import Zain from "assets/images/team/zain.webp";
import Hardik from "assets/images/team/Hardik.webp";
import Naureen from "assets/images/team/Naureen.webp";
import Hamza from "assets/images/team/Hamza.webp";
import Joanna from "assets/images/Joanna.webp";
import Pauline from "assets/images/Pauline.jpeg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer lg:top-8 lg:bottom-auto -bottom-10 slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 lg:top-8 lg:bottom-auto -bottom-10 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class Peoplebehind extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchased: false,
      openModal: false,
    };
  }

  onClickOk = (e) => {
    e.preventDefault();
    this.setState({ purchased: true });
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts slider-left dots-top">
        <div className="container mb-10">
          <h4 className="text-3xl text-center Medium">
            The People Behind Procurement League
          </h4>
        </div>
        <Slider {...settings} className="ml-0 important:static">

          <div className="p-3">
            <div className="relative block overflow-hidden rounded-xl">
              <img
                alt="Rajiv Gupta"
                src={Rajiv}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/procurementtransformation"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Rajiv Gupta</h4>
                <p className="opacity-50 white fs-11">Founder, USA</p>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="relative block overflow-hidden rounded-xl">
              <img
                  alt="James Moran"
                  src={James}
                  className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/procurementtransformation"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">James Moran</h4>
                <p className="opacity-50 white fs-11">Procurement Head, USA</p>
              </div>
            </div>
          </div>
          {/*<div className="p-3">
            <div className="relative block">
              <img
                alt="Samantha"
                src={Samantha}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                 Will be Add Later, Don't Remove :(
                 <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/hardikpandey/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div>
                <h4 className="mb-1 Regular fs-15 white ">Samantha</h4>
                <p className="opacity-50 white fs-11">
                  Client Success & Development AssociateClient & Development
                  Associate, USA
                </p>
              </div>
            </div>
          </div>*/}

          <div className="p-3">
            <div className="relative block">
              <img
                alt="Hardik Pandey"
                src={Hardik}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/hardikpandey/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Hardik Pandey</h4>
                <p className="opacity-50 white fs-11">
                  Senior Research Analyst, India
                </p>
              </div>
            </div>
          </div>

          <div className="p-3">
            <div className="relative block">
              <img
                alt="Saheena"
                src={Saheena}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/hardikpandey/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Saheena Parween Ansari</h4>
                <p className="opacity-50 white fs-11">
                  Marketing & Community Engagement Executive
                </p>
              </div>
            </div>
          </div>

         {/* <div className="p-3">
            <div className="relative block">
              <img
                alt="Naureen Shabnam"
                src={Naureen}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                 Will be Add Later, Don't Remove :(
                 <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/naureen-shabnam-2661b7113/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div>
                <h4 className="mb-1 Regular fs-15 white ">Naureen Shabnam</h4>
                <p className="opacity-50 white fs-11">
                  Senior Research Analyst, India
                </p>
              </div>
            </div>
          </div>*/}

          <div className="p-3">
            <div className="relative block">
              <img
                alt="Hamza"
                src={Hamza}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/hamzakamal/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Hamza</h4>
                <p className="opacity-50 white fs-11">
                  Procurement Category Manager, Australia
                </p>
              </div>
            </div>
          </div>
          {/*<div className="p-3">
            <div className="relative block">
              <img
                alt="Devangana Mishra"
                src={Devangana}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                 Will be Add Later, Don't Remove :(
                 <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/hamzakamal/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div>
                <h4 className="mb-1 Regular fs-15 white ">Devangana Mishra</h4>
                <p className="opacity-50 white fs-11">
                  Asia Head Community Engagement, India
                </p>
              </div>
            </div>
          </div>*/}
          <div className="p-3">
            <div className="relative block">
              <img
                alt="Joanna Gosling"
                src={Joanna}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/joanna-gosling-mcips-she-her-071ab516/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Joanna Gosling</h4>
                <p className="opacity-50 white fs-11">
                  Global Sourcing Professional, UK
                </p>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="relative block">
              <img
                alt="Pauline King"
                src={Pauline}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/pauline-h-king/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Pauline King</h4>
                <p className="opacity-50 white fs-11">
                  Indirect Procurement Leader, Switzerland
                </p>
              </div>
            </div>
          </div>
          {/*<div className="p-3">
            <div className="relative block">
              <img
              alt='Brigette'
                src={Brigette}
                className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                 Will be Add Later, Don't Remove :(
                 <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/pamela-brigitte-esparza-valerio-000603104/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div>
                <h4 className="mb-1 Regular fs-15 white ">Brigette</h4>
                <p className="opacity-50 white fs-11">
                  Procurement Associate, Ireland
                </p>
              </div>
            </div>
          </div>*/}
          <div className="p-3">
            <div className="relative block">
              <img
                  alt="Zain ul Abideen"
                  src={Zain}
                  className="object-cover mx-auto h-80 rounded-xl"
              />
              <div className="absolute bottom-0 z-10 w-full p-3 bg-gray-800 rounded-xl">
                {/* Will be Add Later, Don't Remove :( */}
                {/* <div className="absolute flex items-center w-12 px-1 bg-gray-500 rounded-full justify-evenly h-7 right-5 -mt-7">
                  <a
                    href="https://www.linkedin.com/in/bjssoftsolution/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      className="cursor-pointer white"
                      size={14}
                    />
                  </a>
                  <FaFacebookF className="cursor-pointer white" size={12} />
                  <AiOutlineTwitter
                    className="cursor-pointer white"
                    size={14}
                  />
                </div> */}
                <h4 className="mb-1 Regular fs-15 white ">Zain ul Abideen</h4>
                <p className="opacity-50 white fs-11">
                  Chief Technology Officer, Pakistan
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default Peoplebehind;
