import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Buttonlink, IsLoader } from "components";
import "./styles.css";
import { FiX } from "react-icons/fi";
import client from "../../../graphql/client";
import LOGIN from "../../../graphql/mutations/loginMutation";
import cookie from "js-cookie";
import {
  GRAPH_USER_AUTH_TOKEN,
} from "../../../config/constants";
import Alert from "@mui/material/Alert";
import { BsArrowRight, BsEye, BsEyeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";
import GoogleLoginButton from "../GoogleLoginButton";
import LinkedInPage from "../LinkedInPage";
import logo from "assets/images/register.png";
import { setAuthExpiry } from "helpers";

class Signinpopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: false,
      open: this.props.open ? this.props.open : false,
      message: "",
      pswdType: true,
    };
  }

  handleLoginSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;

    if (email === "" || password === "") {
      this.setState({
        ...this.state,
        loading: false,
        error: true,
        message: "Email or password cannot be empty.",
      });
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
      error: false,
      message: "",
    });

    client
      .mutate({
        mutation: LOGIN,
        variables: {
          email: email,
          password: password,
        },
      })
      .then((response) => {
        let { error, message, token } = response.data.login;
        if (error === true) {
          this.setState({
            ...this.state,
            loading: false,
            error: error,
            message: message,
          });
        } else {
          this.setState({
            ...this.state,
            loading: false,
            error: false,
            message: "",
          });

          cookie.set(GRAPH_USER_AUTH_TOKEN, token);
          setAuthExpiry();
          window.location.href = window.location.href;
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
          error: true,
          message: "Oops something went wrong please try again later",
        });
      });
  };

  openPopup = () => {
    this.setState({ open: true });
  };

  closePopup() {
    this.setState({ open: false });
  }

  showEyePswd = () => {
    this.setState({ pswdType: !this.state.pswdType });
  };

  render() {
    const { email, password, error, message, open } = this.state;
    const { protect, closePopup } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        className="relative overflow-auto sign-in-popup">
        <div
          onClick={() => (protect ? this.closePopup() : closePopup())}
          className="absolute z-10 flex items-center justify-center rounded-full cursor-pointer right-4 top-4">
          <FiX className="primary" size={25} />
        </div>
        <DialogContent className="p-10 rounded-3xl">
          <div className="space-y-5">
            <div className="relative">
              <h1 className="text-4xl font-light text-center lg:text-5xl Poppins">
                Sign in
              </h1>
              <img
                src={logo}
                alt="logo"
                className="absolute top-0 right-32 md:block hidden"
              />
            </div>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-5 md:pt-32 pt-10">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  type={this.state.pswdType ? "password" : "text"}
                  variant="standard"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                <div
                  onClick={this.showEyePswd}
                  className="absolute top-0 right-0 z-10 flex items-center justify-center w-12 h-full cursor-pointer">
                  {this.state.pswdType ? (
                    <BsEye size={20} />
                  ) : (
                    <BsEyeSlash size={20} />
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end">
              <Link
                to={"/forgot-password"}
                className="primary text-lg font-normal Poppins hover:opacity-70">
                Forgot Password?
              </Link>
            </div>
            <div>
              {this.state.loading ? (
                <div className="bg--primary text-white flex items-center justify-center Regular text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-11 w-full">
                  <IsLoader white={true} className="h-full" />
                </div>
              ) : (
                <Buttonlink
                  onClick={this.handleLoginSubmit}
                  Text={'Login'}
                  Lasticon={<BsArrowRight size={22} />}
                  className="text-white border-0 flex justify-center gap-5 items-center px-5 Poppins text-lg rounded-full shadow-none bg--primary hover:opacity-70 hover:bg--primary focus:bg--primary w-full h-11 fs-14 Poppins"
                />
              )}
            </div>
            <div className="space-y-5">
              <div>
                <p className="mt-2 text-center text-color">
                  Don't have an account?
                  <Link
                    to="/signup"
                    className="ml-1 primary hover:opacity-70 Poppins underline"
                  >
                    SignUp
                  </Link>
                </p>
              </div>
              <div className="flex flex-col items-center gap-3 mt-5 ">
                <GoogleLoginButton />
                <LinkedInPage />
              </div>
            </div>
          </div>
          <div>
            {error ? <div style={{ height: 15 }} /> : false}
            {error ? <Alert severity="error">{message}</Alert> : ""}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Signinpopup;
