import {
  Blogskeleton,
  Buttonlink,
  ClassCard,
  Noconnection,
  Nocontent,
} from "components";
import React, { Component } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Query } from "@apollo/client/react/components";
import { GoSearch } from "react-icons/go";
import MY_CLASSES from "graphql/queries/getMyClasses";
import { seo } from "../../../helpers/seo";

class MyClasses extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "MyClasses | Procurement League",
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
    this.timeoutValue = 0;
  }

  render() {
    let variables = {};

    if (this.state.search !== "") {
      variables.search = this.state.search;
    }
    return (
      <>
        <div className="container">
          <div className="flex items-center w-full">
            <Buttonlink
              Text="back"
              Icon={<IoIosArrowBack />}
              to="/classes"
              className="flex items-center justify-center px-4 rounded-full bg--lightGray h-9 darkGray w-28 hover:darkGray hover:opacity-80"
            />
            <div className="flex-grow w-full ml-4">
              <div className="flex items-center col-span-2">
                <div className="flex items-center w-full px-3 bg-gray-100 rounded-xl">
                  <input
                    placeholder="Search..."
                    className="flex-grow w-full bg-gray-100 border-0 h-14 Regular rounded-xl"
                    // value={this.state.search}
                    onChange={(e) => {
                      clearTimeout(this.timeoutValue);
                      this.timeoutValue = setTimeout(() => {
                        this.setState({ search: e.target.value });
                      }, 2000);
                    }}
                  />
                  <GoSearch size={22} className="gray" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20">
            <div className="container">
              <h4 className="mb-2 text-2xl Medium">My Classes</h4>
            </div>

            <div className="relative blog-posts">
              <Query query={MY_CLASSES} variables={variables}>
                {({ loading, error, data, fetchMore, refetch }) => {
                  if (loading)
                    return (
                      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                        <Blogskeleton />
                        <Blogskeleton />
                        <Blogskeleton />
                      </div>
                    );

                  if (error) {
                    return (
                      <div>
                        <Noconnection />
                      </div>
                    );
                  }
                  if (data !== undefined) {
                    let item = data.me.courses.data?.[0];
                    let type = "";
                    if (item?.course_objective !== null) {
                      type = "item_based";
                    } else if (item?.course_objective === null) {
                      type = "Newest";
                    } else if (item?.is_training === "1") {
                      type = "Featured";
                    } else if (item?.reviews?.paginatorInfo?.total > 0) {
                      type = "Popular";
                    }
                    return data.me.courses.data.length >= 1 ? (
                      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2">
                        {data.me.courses.data.map((_class) => (
                          <div className="p-2 pt-3">
                            <ClassCard _class={_class} type={type} key={_class.id}/>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Nocontent />
                    );
                  }
                  return null;
                }}
              </Query>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyClasses;
