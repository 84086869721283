import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function Listingsskeleton() {
  return (
    <div className="p-3 overflow-hidden bg-white rounded-lg shadow-sm">
      <div className="items-center mb-3">
        <div className="flex flex-col flex-grow mt-3">
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />

          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />

          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
          <Skeleton
            variant="rect"
            height={8}
            className="mt-4 rounded-full"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}
