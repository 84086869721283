import { Expertslick, Expertstrainer, Expertquestions } from "components";
import React, { Component } from "react";
import buildImage from "assets/images/build.png";

class Expert extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container">
        <div className="xs:px-4 sm:px-4 xl:px-28 lg:px-10 md:px-5">
          <div className="mt-28">
            <Expertstrainer />
          </div>
          <div className="xs:px-4 sm:px-4 xl:px-28 lg:px-10 md:px-5">
            <div className="mt-28">
              <div className="mb-4">
                <Expertquestions />
              </div>
              <div className="mb-4">
                <Expertquestions />
              </div>
              <div className="mb-4">
                <Expertquestions />
              </div>
              <div className="mb-4">
                <Expertquestions />
              </div>
            </div>
          </div>
          <div className="mt-28">
            <Expertslick />
          </div>
          <div className="mt-28">
            <div className="xs:px-4 sm:px-4 xl:px-28 lg:px-10 md:px-5">
              <div className="flex flex-col items-center mx-auto lg:max-w-md">
                <img
                  alt=" Build awareness, create trust"
                  src={buildImage}
                  className="max-w-md mx-auto"
                />
                <h3 className="mt-4 mb-3 text-3xl text-center">
                  Build awareness, create trust
                </h3>
                <div className="h-1 mx-auto rounded-full bg--primary w-52" />
                <p className="mt-4 text-center text-color">
                  An opportunity to showcase your company and its value
                  proposition. Engage with your target audience and create top
                  of mind awareness. Establish credibility and build a
                  following.
                </p>
                <button
                  type="button"
                  className="h-12 mt-4 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 rounded--xl w-96">
                  Get in touch
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Expert;
