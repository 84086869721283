import { gql } from "@apollo/client";

export default gql`
  query CourseCategories{
    course_categories {
      id
      name
      parent_id
      items {
        id
        name
        parent_id
      }
    }
  }
`;
