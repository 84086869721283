import React from "react";
import { Modal } from "react-bootstrap";

export default function EnrollPopup(props) {
  return (
    <div>
      <Modal {...props} centered>
        <Modal.Body>
          <div className="mb-4 text-center">
            {props.product
              ? "Product already has been purchased."
              : "Please become a pro user first."}
          </div>
          <div className="flex justify-center">
            <button
              onClick={props.onHide}
              className="h-10 mr-3 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary Regular hover:opacity-90 rounded--xl ">
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
