import {gql} from "@apollo/client";

export default gql`
    mutation CreateCompany(
        $company_id:Int
        $title:String
        $link:String
        $logo:String
        $address:String
        $history:String
        $executives:[Int]
    ) {
        createCompany(
            company_id:$company_id
            title: $title
            link: $link
            logo: $logo
            executives:$executives
            address:$address
            history:$history
        ) {
            id
            title
            slug
            logo
            address
            city
            history
            country
            state
            linked_in_profile
            executives{
                id
                firstname
                lastname
                email
                username
                profile_photo
            }
        }
    }
`;

