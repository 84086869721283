import { Buttonlink } from "components";
import { seo } from "helpers/seo";
import React, { Component } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Privacy Policy | Procurement League ",
    });
  }
  render() {
    return (
      <div className="container">
        <div className="xs:px-4 sm:px-4 xl:px-28 lg:px-10 md:px-5">
          <div className="relative flex flex-col justify-between mb-10 lg:items-center lg:justify-center lg:flex-row">
            <Buttonlink
              Text="back"
              Icon={<IoIosArrowBack />}
              onClick={() => this.props.history.goBack()}
              className="left-0 z-10 flex items-center justify-center px-4 border-0 shadow-none lg:absolute bg--lightGray hover:bg--lightGray focus:bg--lightGray Regular h-9 rounded--full darkGray w-28 hover:darkGray hover:opacity-80"
            />
            <h1 className="my-3 text-4xl text-center Medium lg:my-0">
              Privacy Policy
            </h1>
            <Buttonlink
              Text="Disclaimer"
              Lasticon={<IoIosArrowForward />}
              to="/disclaimer"
              className="right-0 flex items-center justify-center border-0 shadow-none lg:absolute fs-15 primary hover:primary Regular rounded--xl "
            />
          </div>
          <p className="mb-3">Last updated: 25th May 2018</p>
          <div className="flex items-center flex-wrap mb-4">
            <p className="mr-1 flex-shrink-0">Welcome to the</p>
            <Link to="/" className="primary flex-shrink-0">
              Procurementleague.com
            </Link>{" "}
            <Link to="/privacy-policy" className="ml-1 flex-shrink-0 primary">
              Privacy Policy.
            </Link>
          </div>
          <p className="mb-4">
            At{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            (hereinafter referred to as the “Website”) protecting your personal
            data is important to us. We are committed all the time to ensure
            that your privacy is respected and protected all times. This{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>{" "}
            explains how we collect personal information and how we maintain,
            use and disclose that information. It also provides some details
            about your privacy rights, along with our general rights and
            obligations in relation to the personal information we keep on
            record for our use.
          </p>
          <p className="mb-4">
            We assure you that your personal data would be considered as our own
            personal information and we would protect it like one of our own
            data.
          </p>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and Procurement League Inc. reserves all the right to make changes
            in the{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>{" "}
            without prior notice to you. Such changes will be posted on this
            page and will come to effect on the date of posting of such changes
            in this
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>{" "}
            page.
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            assures that such changes would not lessen the protection promised
            to you for securing your privacy.
          </p>
          <h2 className="mb-3 text-xl Medium">Objective of this policy</h2>
          <p className="mb-4">The objective of this policy is:</p>
          <p className="mb-4">
            To define personal details with respect to or in relation to our
            Website and our obligation to collect, use, and disclose the
            personal data of the users who are associated or come in contact
            with our Website; and To assure you that we recognise and fully
            respect the privacy and personal data of the visitors to this
            Website.
          </p>
          <p className="mb-4">
            This
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>{" "}
            governs this Website “
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>
            ” (referred to in this{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>{" "}
            as the "Website"). Other sites to which the Website may be linked
            are not covered by this{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>
          </p>
          <h2 className="mb-3 text-xl Medium">Scope of this Policy</h2>
          <p className="mb-4">
            “Personal Data” refers to data, whether true or not, about an
            individual who can be identified from that data, or in combination
            with other information to which the Website may have access during
            the course of business and operation. Under the new General Data
            Protection Regulation (GDPR) “Personal Data” includes any
            information relating to an identified or identifiable natural
            person, such as a name, an identification number, location data, an
            online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity of that natural person. This information is provided
            by you to us voluntarily in course of using of{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and any service thereon.
          </p>
          <p className="mb-4">
            For the purpose of this{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>
            , the terms “we”, “our”, “us” “Procurement League Inc.” refers to
            the owner{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and the terms “you”, “your”, refers to the users of this Website.
          </p>
          <h2 className="mb-3 text-xl Medium">
            Collection and use of Information
          </h2>
          <p className="mb-4">
            We may collect certain personal information from you while providing
            you with our services like your name, email address, contact no,
            your company name including its registered office address, function,
            department, areas of interest or expertise, phone number, website
            URLs as may be applicable, information about your preferences while
            submitting question and answers or taking part in the contest.
          </p>
          <p className="mb-4">
            You also provide us information and materials in the User Content
            you post to the Website. Your questions, answers, and other
            contributions on the Service, and metadata about them (such as when
            you posted them), are publicly viewable on the Service, along with
            your name. This information may be searched by search engines and be
            republished elsewhere on the Web in accordance with our{" "}
            <Link className="primary">Terms of Service </Link>.
          </p>
          <p className="mb-4">
            Apart from this, we automatically collect certain information about
            you including IP address; browser type and operating system;
            referring URL (or the webpage you were visiting before you came to
            our Website); location information; pages of our Website that you
            visit and the time spent on those pages; the links you click;
            information you search for; access times and dates, device ID or
            other identifier; language information; and other statistics, via
            cookies, log files, pixel tags, third party analytics tools, and
            other methods.
          </p>
          <p className="mb-4">
            The “Personal Data” which are acquired by us are strictly for
            specified, explicit and legitimate purpose and are not further
            processed in any manner that is incompatible with those purposes.
          </p>
          <p className="mb-4">
            These information are used by us in the following ways:
          </p>
          <p>
            To personalise your experience and ensure that content from the
            Website is presented in the most effective manner for you and for
            your computer. Store your user name and password.
          </p>
          <p>
            The contact information and e-mail address are used by us for
            sending you alerts regarding the posted Question/Answers, contest
            and other relevant information or promotional codes.
          </p>
          <p>To notify you about the changes or updates to our service.</p>
          <p className="mb-4">
            The information about your preference in participating in the
            Question/Answers sessions, will be used for sending you such
            promotional links or services as may be useful to you.
          </p>
          <p className="mb-4">
            We may collect different types of information about your location,
            information you provide from time to time while registration and
            updating those thereafter, including general information (e.g., IP
            address, zip code) and more specific information (e.g., GPS-based
            functionality on mobile devices used to access the Service), and may
            use that information to customize the Service with location-based
            information and features. If you access the Service through a mobile
            device and you do not want your device to provide us with
            location-tracking information, you can disable the GPS or other
            location-tracking functions on your device, provided your device
            allows you to do this.
          </p>
          <p className="mb-4">
            By using the Service you consent to the transfer of your information
            to the United States and/or to other countries for storage,
            processing and use by{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            in accordance with our{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>
            .
          </p>
          <p className="mb-4">
            We may aggregate and/or anonymize information collected through the
            Service so that the information does not identify you. We may use
            aggregated, anonymized, and other de-identified information for any
            purpose, including for research and marketing purposes, and our use
            and disclosure of such information is not subject to any
            restrictions under this{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>
            .
          </p>
          <p className="mb-4">
            The data provided by you will be stored by us till the time you
            continue taking our services, the moment you would like to
            discontinue or delete your account, all the data provided by you in
            your account will be erased completely.
          </p>
          <h2 className="mb-3 text-xl Medium">Securing of Information</h2>
          <p className="mb-4">
            Although no data transmission can be guaranteed to be 100% secure,
            we take all reasonable steps to ensure that the data we collect, use
            or disclose is protected and are not misused with or exposed to the
            third party. The personal details collected by us are secured
            through a secure server and we use all the up-to-date industry
            procedure and standards including the use of appropriate
            administrative, physical and technical safeguards in order protect
            the personal information against loss, unauthorized disclosure or
            access.{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            does not engage in selling, renting, transferring or trading of
            personal information to any third parties.
          </p>
          <h2 className="mb-3 text-xl Medium">Exceptional Disclosure</h2>
          <p className="mb-4">
            We may provide your personal information to our business partners or
            other trusted entities for the purpose of providing you with
            information on goods and services we believe will be of interest to
            you. We also disclose your information to other companies who
            provide services on our behalf like the web servers, customer care,
            and payment processing and marketing services and these companies
            would not disclose your information to any third party. We may share
            your personal information with our affiliates to help detect illegal
            activities on our database but on the condition that they agree to
            keep your personal information secret. Apart from this, we may
            disclose the information if we believe in good faith that such
            disclosure is necessary for protecting our rights, protecting the
            security and integrity of the nation, protecting our user interest
            or right or for complying with the legal process. We may disclose
            your information if ordered by court or competent authority or
            required by the law to protect our right or comply with legal
            processed served on{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>
          </p>
          <p className="mb-4">
            We may also share your information with third parties offering
            combined services with us. For example, we may share your
            information with third-party partners that display
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            questions and answers through their services so that such partners
            can tailor content and advertising to you. If we do this, such third
            parties’ use of your information will be bound by this{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>
            .
          </p>
          <h2 className="mb-3 text-xl Medium">Cookies</h2>
          <p className="mb-4">
            We use cookies for improving and customizing the services provided
            under the Website. Cookies is a piece of a data which is sent by the
            website and stored in the user web browser. It helps the website to
            load user’s previous activity anytime when the website is reloaded.
            We may combine this information with other personal information we
            collect from you. We use cookies to keep a track on user’s activity
            so that it can recall your given information and preferences, every
            time when you load the Website thereby making your use of the
            Website faster and convenient. Use of cookies may also store your
            password for accessing the Website so that you can automatically be
            directed to your account on the Website. Cookies do not carry
            viruses nor install malware on the host computer. However, you can
            choose to have your computer warn you each time a cookie is being
            sent, or you can choose to turn off all cookies. You do this through
            your browser settings. Each browser is a little different, so look
            at your browser's Help menu to learn the correct way to modify your
            cookies.
          </p>
          <p className="mb-4">
            If you opt out or disable cookies, some features will be disabled
            and some of our services will not function properly, thereby
            restricting your use of the site.
          </p>
          <p className="mb-4">
            For additional information on how to block cookies, please refer to
            the privacy or security settings of your browser.
          </p>
          <h2 className="mb-3 text-xl Medium">Third Party Links</h2>
          <p className="mb-4">
            The Website may allow third party ad servers or ad networks to serve
            advertisement on the third party services (Services controlled by
            companies other than{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>
            . The third party services are regulated by their respective{" "}
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>{" "}
            and are not under the control of{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>
            . User willing to access the third party website or services shall
            do it at their own discretion and are requested to go through their
            respective
            <Link to="/privacy-policy" className="primary">
              {" "}
              Privacy Policy
            </Link>
            . At no instance,{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and Procurement League Inc. shall be held responsible for any
            contents or default arising out of linked service or ads.{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            does not provide any information that can identify you personally to
            these third-party ad servers or ad networks without your consent.
          </p>
          <h2 className="mb-3 text-xl Medium">Merger and Acquisition</h2>
          <p className="mb-4">
            In case of change in ownership of our business through any viable
            means like a merger, an acquisition, takeover, or any similar event,
            your personal information may also be transferred to the new owner.
            The rights and obligations with respect to your personal information
            shall also be transferred to the new owner.
          </p>
          <p className="mb-4">
            Please note that the likeliness of such an event is totally
            unpredictable. Rest assured that your personal information will
            remain completely safe with us.
          </p>
          <h2 className="mb-3 text-xl Medium">
            Your Choices About Your Information
          </h2>
          <p className="mb-4">
            You may, of course, decline to submit information through the
            Service, in which case{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            may not be able to provide certain services to you. You may update
            or correct your account information and email preferences at any
            time by logging in to your account.
          </p>
          <h2 className="mb-3 text-xl Medium">Children’s Privacy</h2>
          <p className="mb-4">
            The use of the Website is limited to the users above 13 years of
            age. If the user is a minor and wish to use the Website, it must be
            done under the supervision of parent or legal guardian provided that
            such parent or guardian accepts the terms and conditions.
          </p>
          <p className="mb-4">
            Naturally, the safety of children is very important to us. We will
            not knowingly collect any information from children under the age of
            13. If you have discovered that your child who is below 13 years has
            submitted his/her own personal information without obtaining prior
            consent or permission from you, you can write us for the removal of
            same at the contact information provided on the “Contact us” page or
            the contact information provided at the end of this Policy. Upon
            valid request, we would take all the necessary steps to remove such
            information from our database.
          </p>
          <h2 className="mb-3 text-xl Medium">
            Your California Privacy Rights
          </h2>
          <p className="mb-4">
            California law permits users who are California residents to request
            and obtain from us once a year, free of charge, a list of the third
            parties to whom we have disclosed their personal information (if
            any) for their direct marketing purposes in the prior calendar year,
            as well as the types of personal information disclosed to those
            parties. If you are a California resident and would like to request
            this information, please submit your request in an email to{" "}
            <a className="primary" href="mailto:admin@procurementleague.com">
              admin@procurementleague.com
            </a>
          </p>
          <h2 className="mb-3 text-xl Medium">GDPR Compliance:-</h2>
          <p className="mb-4">
            Starting from 25th May, 2018,{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            is going to comply with GDPR i.e. General Data Protection
            Regulation. Under this regulation we are legally bound to protect
            the personal data which are provided by you in return of the
            services provided by us. Under this regulation:
          </p>
          <p className="mb-4">
            “Personal Data” includes any information relating to an identified
            or identifiable natural person, such as a name, an identification
            number, location data, an online identifier or to one or more
            factors specific to the physical, physiological, genetic, mental,
            economic, cultural or social identity of that natural person;
          </p>
          <p className="mb-4">
            The “Personal Data” which are acquired by us are strictly for
            specified, explicit and legitimate purpose and are not further
            processed in any manner that is incompatible with those purposes.
          </p>
          <p className="mb-4">
            It becomes your duty that the data given by you are true, accurate
            and kept up-to-date. Whenever you find that the data is not correct,
            you can rectify or remove such data.
          </p>
          <p className="mb-4">
            If you want to cancel or delete your account, procuremantleague.com
            will permanently remove your account and all information associated
            with it. If, you feel that any of the personal information acquired
            by us has been used unlawfully, you may request limited use of that
            personal data.
          </p>
          <p className="mb-4">
            We ensure you that, your data is processed in a manner in which it
            gets, appropriate security, including protection against
            unauthorised or unlawful processing and against accidental loss,
            destruction or damage, using appropriate technical or organisational
            measures (‘integrity and confidentiality’).
          </p>
          <p className="mb-4">
            The data provided by you will be stored by us till the time you
            continue taking our services, the moment you would like to
            discontinue or delete your account, all the data provided by you in
            your account will be erased completely. After you delete your
            account or post we may hold backup copies of such account and posts
            up to 14 days after you delete it.
          </p>
          <p className="mb-4">
            If under any circumstances we need to further process your “Personal
            Data” for a purpose other than those mentioned above, we will notify
            you about what “Personal Data” has been further processed, the
            purpose and the manner and any other further relevant information
            regarding how your data is being used.
          </p>
          <p className="mb-4">
            You shall receive the personal data, which you have provided to us,
            in a structured, commonly used and machine-readable format and you
            shall have the right to transmit the data to another without any
            hindrance from our side. You have the right to object to the
            processing of the personal data, once the objection has been raised
            by you, we shall no longer process the personal data.
          </p>
          <h2 className="mb-3 text-xl Medium">User Consent</h2>
          <p className="mb-4">
            By continuing to use the services of{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and providing us with your information, you consent to our{" "}
            <Link to="/privacy-policy" className="primary">
              Privacy Policy
            </Link>
            and permit us to use your information in the manner as set out in
            this{" "}
            <Link to="/privacy-policy" className="primary">
              Privacy Policy
            </Link>
            .
          </p>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and Procurement League Inc. reserves its right to immediately
            terminate the access or usage rights of those users who fail to
            comply with the terms of this{" "}
            <Link to="/privacy-policy" className="primary">
              Privacy Policy
            </Link>
            .
          </p>
          <h2 className="mb-3 text-xl Medium">Contact</h2>
          <p className="mb-4">
            If you have any queries regarding the{" "}
            <Link to="/privacy-policy" className="primary">
              Privacy Policy
            </Link>{" "}
            or feedback or suggestion, feel free to contact us at:{" "}
            <a className="primary" href="mailto:admin@procurementleague.com">
              admin@procurementleague.com
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
