import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import keepChanging from '../../../assets/images/keepchanging.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function KeepChecking(props) {
    const [open, setOpen] = React.useState(props?.open ? props.open : false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        let path = props.path;
        props.history.push(path);
        setTimeout(() => {
            setOpen(false);
        })
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className='relative'
            aria-describedby="alert-dialog-slide-description"
        >
            <div onClick={handleClose}>
                <div className="ml-auto absolute top-2 right-2 flex items-center justify-center cursor-pointer w-[42px] h-[42px] bg-gray-100 hover:bg-gray-200 rounded-full"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></div>
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <img src={keepChanging} alt="keepChanging" />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}