import { gql } from "@apollo/client";

// if error is true then selected user question is liked by auth user

export default gql`
    query UserLikesThisAnswer($question_id:ID!,$answer_id:ID!){
        adminLikesThisAnswer(question_id:$question_id,answer_id:$answer_id){
            message
            error
        }
    }
`;
