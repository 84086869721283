import { gql } from "@apollo/client";

export default gql`
    mutation activateUserAccount($token: String!) {
        activateUserAccount(token: $token) {
            message
            status
            title
        }
    }
`;
