import React from "react";
import { BsArrowRight } from "react-icons/bs";
import pocketImage from "assets/images/pocketBanner.png";
import { ANDROID_APP_URL } from "../../../config/constants";
import Buttonlink from "../buttonlink";
import knowledgeImage from "../../../assets/images/Home/knowledge.webp";

const KnowledgePocket = ({ isImageHide, data }) => {
    return (
        <div className={`knowledge-pocket lg:h-[400px] md:h-[400px] ${!isImageHide ? 'h-[580px]' : 'h-[350px]'} w-full relative`}>
            <img
                src={knowledgeImage}
                alt="Knowledge"
                className="w-full h-full object-cover"
            />
            <div className="absolute w-full h-full inset-0">
                <div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
                    <div className="lg:col-span-6 md:col-span-6 col-span-12 space-y-4 h-full flex justify-center items-center md:items-start flex-col">
                        <p className="white Poppins lg:text-6xl md:text-4xl text-2xl font-extralight lg:text-left md:text-left text-center">
                            {data?.heading || "All The Knowledge In Your Pocket"}
                        </p>
                        <div className="h-px bg-white w-full" />
                        <div className="lg:block md:block hidden space-y-4">
                            <p className="white text-xl font-light Poppins">
                                {data?.text || "Connect with worldwide Procurement and Supply Chain professionals from home, at work, or while traveling."}
                            </p>
                            <Buttonlink
                                href={data?.link || ANDROID_APP_URL}
                                Text={data?.btn || "Download"}
                                Lasticon={<BsArrowRight size={23} />}
                                className="py-2 text-xl border-2 self-start font-bold Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center gap-x-3 w-max"
                            />
                        </div>
                    </div>
                    <div className="lg:my-auto md:my-auto lg:col-span-6 md:col-span-6 col-span-12">
                        {
                            !isImageHide &&
                            <img
                                alt="Pocket"
                                src={pocketImage}
                                className="m-auto object-contain lg:h-[315px] md:h-[315px] h-60"
                            />
                        }
                        <div className="space-y-4 mt-5 lg:hidden md:hidden block">
                            <p className="white text-xl font-light Poppins">
                                {data?.text || "Connect with worldwide Procurement and Supply Chain professionals from home, at work, or while traveling."}
                            </p>
                            <Buttonlink
                                href={data?.link || ANDROID_APP_URL}
                                Text={data?.btn || "Download"}
                                Lasticon={<BsArrowRight size={23} />}
                                className="py-2 text-xl border-2 font-bold self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center gap-x-3 w-max"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KnowledgePocket;
