import { gql } from "@apollo/client";

export default gql`
 mutation updateLikeQuestion($question_id: Int!) {
  likeQuestion(question_id: $question_id) {
    id
    like_type
    users{
      id
    }
    likes(first: 1) {
        data {
          users {
            id
          }
        }
        paginatorInfo {
          total
        }
      }
      dislikes(first: 1) {
        data {
          users {
            id
          }
        }
        paginatorInfo {
          total
        }
      }
    # total_like_votes
    # total_unlike_votes
    # success
    # error
  }
}

`;
