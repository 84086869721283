import { gql } from "@apollo/client";

export default gql`
    mutation get_user_token($email: String!, $firstname: String, $lastname: String){
        get_user_token(email: $email, firstname: $firstname, lastname: $lastname){
            token
            error
            message
        }
    }
`;
