import {
  Publicdiscussion,
  Detailhead,
  Detailabout,
  Buttonlink,
  Noconnection,
  Pagenotfound,
  Productdetail,
  EventsFooter
} from "components";
import React, { Component } from "react";
import { connect } from "react-redux";
import GET_CLASS_BY_ID from "../../../graphql/queries/getCourse";
import { Query } from "@apollo/client/react/components";

class EventsDetail extends Component {
  state = {
    authUser: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    let { latestEvent, authUser } = this.props;
    let variables = {};
    if (latestEvent) {
      variables.id = latestEvent;
    }
    variables.cursor = 1;
    return (
      <div className="container">
        <Buttonlink
          Text="<< Back"
          to="/events"
          className="flex primary items-center uppercase justify-start px-4 mb-3 rounded-full text-lg h-9"
        />
        <Query query={GET_CLASS_BY_ID} variables={variables}>
          {({ loading, error, data, fetchMore, refetch }) => {
            if (loading) return <Productdetail />;

            if (error) {
              return (
                <div>
                  <Noconnection />
                </div>
              );
            }
            if (this.props.course_recall) {
              refetch();
              setTimeout(() => {
                this.props.handleClassReset(false);
              }, 10);
            }
            if (data) {
              this.props.handleClassDetails(data);
              return (
                <>
                  <div className="grid lg:gap-20 gap-5 mt-5 lg:grid-cols-2">
                    <div>
                      <Detailabout
                        type={""}
                        event={data.course}
                        authUser={authUser}
                      />
                    </div>
                    {/* <div>
                      <Publicdiscussion
                        type={""}
                          course={data.course}
                          authUser={authUser}
                          loading={loading}
                      />
                    </div> */}
                    <Detailhead type={""} />
                  </div>
                </>
              );
            } else {
              return <Pagenotfound />;
            }
          }}
        </Query>
        <div className="mt-28 -mb-36">
          <EventsFooter />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
  course_recall: state.CourseReducer.course_recall,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleClassDetails: (data) =>
      dispatch({ type: "GET_SINGLE_CLASS_INFORMATION", payload: data }),
    handleClassReset: (data) =>
      dispatch({ type: "COURSE_RECALL", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsDetail);
