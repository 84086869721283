import React from "react";
import scientist_women from "../../assets/images/industries/scientist-women.webp";
import { BsArrowDown } from "react-icons/bs";
import { Link } from "react-router-dom";

const TechnologyService = () => {
	return (
		<div className="grid grid-cols-12 gap-6">
			<h1 className="col-span-12 Poppins text-new-color lg:text-5xl text-4xl font-light pb-3">
				Community Technology Services
			</h1>
			<div className="lg:col-span-8 md:col-span-6 col-span-12">
				<p className="font-normal text-new-color Poppins text-lg">
					Build trust and credibility in your niche with our tailored
					community-based technology solutions.
				</p>
				<div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap gap-8 pt-5">
					<div className="space-y-3">
						<h4 className="success Poppins text-3xl font-light">
							Our Services:
						</h4>

						<ul className="list-disc space-y-2">
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color">
									Advanced App Platform:
								</span>{" "}
								Share product updates, launches, and customer stories
							</li>
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color">
									White-Label SaaS:
								</span>{" "}
								Customizable software to elevate your brand identity
							</li>
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color">
									Product Marketing:
								</span>{" "}
								Impactful launches and promotions for your target audience
							</li>
						</ul>
					</div>
					<div className="space-y-3">
						<h4 className="success Poppins text-3xl font-light">
							Key Benefits:
						</h4>

						<ul className="list-disc space-y-2">
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color"></span>{" "}
								Foster strong audience connections
							</li>
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color"></span>{" "}
								Address unique sector challenges
							</li>
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color"></span>{" "}
								Construct robust client and prospect communities
							</li>
							<li className="text-lg Poppins font-normal text-new-color">
								<span className="text-lg Poppins font-bold text-new-color"></span>{" "}
								Showcase impactful use cases
							</li>
						</ul>
					</div>
				</div>

				<p className="mt-14 font-normal text-new-color Poppins text-lg">
					<span className="font-bold">
						From molecule to market, we empower your digital journey.
					</span>{" "}
					Connect with us to reshape your Biotech & Pharma marketing strategies
					and achieve unparalleled success.
				</p>
				<Link
					to={"/contact-us"}
					className="flex mt-8 justify-between bg--lightBlue px-10 py-4 rounded-full items-center"
				>
					<p className="font-normal text-new-color Poppins text-lg">
						Discover what a 6-month plan looks like for your company.
					</p>
					<BsArrowDown className="primary" size={22} />
				</Link>
			</div>
			<div className="lg:col-span-4 md:col-span-6 col-span-12">
				<div className="h-full">
					<img
						src={scientist_women}
						alt="scientist_women"
						className="h-full w-full object-cover"
					/>
				</div>
			</div>
		</div>
	);
};

export default TechnologyService;
