import { Buttonlink } from "components";
import { seo } from "helpers/seo";
import React, { Component } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

class TermsService extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Terms of Service | Procurement League  ",
    });
  }
  render() {
    return (
      <div className="container">
        <div className="xs:px-4 sm:px-4 xl:px-28 lg:px-10 md:px-5">
          <div className="relative flex flex-col justify-between mb-10 lg:items-center lg:justify-center lg:flex-row">
            <Buttonlink
              Text="back"
              Icon={<IoIosArrowBack />}
              onClick={() => this.props.history.goBack()}
              className="left-0 z-10 flex items-center justify-center px-4 border-0 shadow-none lg:absolute bg--lightGray hover:bg--lightGray focus:bg--lightGray Regular h-9 rounded--full darkGray w-28 hover:darkGray hover:opacity-80"
            />
            <h1 className="my-3 text-4xl text-center Medium lg:my-0">
              Terms of Service
            </h1>
            <Buttonlink
              Text="Privacy Policy"
              Lasticon={<IoIosArrowForward />}
              to="/privacy-policy"
              className="right-0 flex items-center justify-center border-0 shadow-none lg:absolute fs-15 primary hover:primary Regular rounded--xl "
            />
          </div>
          <p className="mb-4">
            Welcome to{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            The following{" "}
            <Link to="/term-of-services" className="primary">
              Terms of Service
            </Link>{" "}
            (“Terms”) contained in this User Agreement regulates the use of our
            website “
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            ” (hereinafter referred to as the “Website”), accepting upon which
            offers you access to use or sign up to our services. The Website is
            owned and operated by{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. a company incorporated by the laws of United States, having its
            registered office at 16192 Coastal Highway, Lewes, Delaware 19958.
          </p>
          <p className="mb-4">
            This is an electronically generated document that doesn’t require
            any physical or digital signature for its authenticity. These terms
            have a bearing on your legal rights and duties. Users of this
            Website are thus requested to read the following terms very
            carefully.
          </p>
          <p className="mb-4">
            Our Services are diverse, so sometimes additional terms requirements
            (including age requirements) may apply. Additional terms will be
            available with the relevant Services, and those additional terms
            become part of your Agreement with us if you use those Services. By
            using or continuing to use the Website or its services you agree
            that you have read, understood and are bound by the terms and
            conditions. By impliedly or expressly accepting these{" "}
            <Link to="/term-of-services" className="primary">
              Terms of Service
            </Link>
            , you also accept and agree to be bound by other applicable policies
            and terms including but not limited to{" "}
            <Link to="/privacy-policy" className="primary">
              privacy policy
            </Link>{" "}
            as amended from time to time.
          </p>
          <p className="mb-4">
            If at any time you find these Terms and Conditions of service or use
            unacceptable or violating your rights Under GDPR (General Data
            Protection Regulation), you must immediately cease accessing the
            Website and all use of the service provided by or through
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            . or inform us about the kind of rights which are getting violated,
            so that we will look into the matter and find a possible solution as
            soon as possible.
          </p>
          <p className="mb-4">
            For the purpose of this Agreement, The term “
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc.”, “we”, “us” refers to the owner of this Website and the term
            “you” or “user” refers to the user of the Website.
          </p>
          <h2 className="mb-3 text-xl Medium">Use of the Service</h2>
          <p className="mb-4">
            Our Services are provided for the personal use by you or your
            organization. You agree and represent that you will comply with all
            applicable federal, state, and local laws including, without
            limitation, copyright law, while using our services. Except as
            expressly permitted in these Terms of Use or as we may otherwise
            permit, you may not use, reproduce, duplicate, distribute, create
            derivative works based upon, publicly display, publicly perform,
            publish, transmit, or otherwise exploit Service Content for any
            purpose whatsoever without obtaining prior written consent from us
            or, in the case of third-party content, its applicable owner. In
            certain instances, we may suggest, ask or otherwise permit you to
            download, install or print Service Content. In such a case, you may
            do so only in the manner authorized and for your non-commercial use
            only. You acknowledge that you do not acquire any ownership rights
            by downloading, installing or printing Service Content.
          </p>
          <p className="mb-4">
            Any software that is made available to view and/or download in
            connection with our Website is owned or controlled by us and/or
            licensors, affiliates and suppliers and is protected by copyright
            laws and international treaty provisions. Your use of the software
            is governed by the terms of the end user license agreement, if any,
            which accompanies or is included with the software. We accept no
            responsibility or liability in connection with any software owned or
            controlled by third parties.
          </p>
          <p className="mb-4">
            We may, without prior notice, change the Service; stop providing the
            Service or features of the Service, to you or to users generally; or
            create usage limits for the Service.
          </p>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            may contain links to third party services and resources. The
            services and resources provided by the third party are not
            controlled by us and therefore, we do not ensure the availability
            and content of those third party resources. Any concerns regarding
            any such service or resource, or any link thereto, should be
            directed to that particular service or resource. These resources or
            links are provided to the user only as a convenience and the
            inclusion of any third party resources, services or link doesn’t
            imply any endorsement by{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc.
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            may also display sponsored links or advertising link sourced from
            third party networks. If you click on one of the links, you will be
            taken off{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>
            . We are not responsible for websites you visit by clicking on these
            links, and you should carefully review their{" "}
            <Link to="/privacy-policy" className="primary">
              privacy policy
            </Link>
            and conditions of use.
          </p>
          <h2 className="mb-3 text-xl Medium">User Registration</h2>
          <p className="mb-4">
            For availing the services provided under our website, you may be
            required to register your own personal account. You are required to
            provide certain information for successful registration of the
            Account and would receive a username and password upon the
            completion of such registration. It becomes your duty to provide us
            with true and accurate data. Whenever you find that the data is not
            correct, you can rectify or remove such data.
          </p>
          <p className="mb-4">
            If you are under the age of thirteen (13), then you are not
            permitted to register as a user, sign up for any promotional
            messages, or otherwise provide us any personal information. If the
            user is a minor and wish to transact or use the website, it must be
            done under the supervision of parent or legal guardian provided that
            such parent or guardian accepts the terms and conditions.
          </p>
          <p className="mb-4">
            You should take all the necessary measures for maintaining the
            privacy of the username and password created upon registration and
            shall also be responsible for all the activities that occur under
            your account. We encourage you to use "strong" passwords (that use a
            combination of upper and lower case letters, numbers and symbols)
            with your account. You agree to immediately notify{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. of any unauthorized use of the account or password. In case of
            any damage or loss arising out of user’s failure to comply with this
            provision,{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. shall not be held liable.
          </p>
          <h2 className="mb-3 text-xl Medium">Fees and Payment</h2>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. charges fees for the services (service fee) provided to the
            users. The users are required to pay all the applicable fees as
            described with the services selected by the users along with any
            related taxes or additional charges. All the service fees are
            non-refundable and{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. has no obligation to offer refund of the fee, unless expressly
            stated otherwise.
          </p>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. reserves the right to change the price for the service fee at
            its own discretion at any time by providing a reasonable notice
            period to the users. Such notice shall be posted on the website or
            mailed to the users to their registered email ids. If you do not
            agree to the revised fee, then you can cancel the undertaken
            services prior the effective date of such revised price.
          </p>
          <p className="mb-4">
            You are responsible for any service charges incurred under your
            account. We are not liable for any loss that you may incur as a
            result of someone else using your password or account, whether with
            or without your knowledge.
          </p>
          <p className="mb-4">
            In the event, you choose to pay the service fee through credit card,
            you authorize us to charge your credit card by providing us with the
            credit card details. If any of the information provided is untrue,
            inaccurate, not current or incomplete or we have reasonable grounds
            to suspect that such information is untrue, inaccurate, not current
            or incomplete, or not in accordance with the this{" "}
            <Link to="/term-of-services" className="primary">
              Terms of Service
            </Link>{" "}
            , we shall have the right to indefinitely suspend or terminate or
            block access of our services.
          </p>
          <p className="mb-4">
            In no event, unless required under any law for the time being in
            force,{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Inc. shall hold any obligation to disclose to anyone the service fee
            charged to any users, sponsors or benefactor.
          </p>
          <h2 className="mb-3 text-xl Medium">User Content</h2>
          <p className="mb-4">
            You may be permitted to post, upload, transmit through, or otherwise
            make available through our Services messages, text, illustrations,
            data, files, images, graphics, photos, comments, feedbacks,
            suggestions, sounds, music, videos, information, content, and/or
            other materials (“User Content”). Subject to the rights and license
            you grant herein, you retain the ownership in all right, title and
            interest in your User Content. We do not guarantee any
            confidentiality with respect to User Content even if it is not
            published through our Services. It is solely your responsibility to
            monitor and protect any intellectual property rights that you may
            have in your User Content, and we do not accept any responsibility
            for the same.
          </p>
          <p className="mb-4">
            By uploading, posting or submitting “User Content” to{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            , you grant us a worldwide, non-exclusive, royalty free license to
            use, copy, modify, create derivate, publish, display and distribute
            such content on{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            services.
          </p>
          <p className="mb-4">
            We reserve the right to display advertisements in connection with
            your User Content and to use your User Content for advertising,
            marketing, promotional, and other commercial purposes. You
            acknowledge and agree that your User Content may be included on the
            Website and advertising networks of our distribution partners,
            marketing partners, accounts, and third-party service providers. We
            will never sell your content to third parties without your explicit
            permission. You also agree to grant us the right to use the name
            that has been submitted with the User Content, in connection with
            such User Content. However, all such uses will be consistent with
            the terms of our{" "}
            <Link to="/privacy-policy" className="primary">
              privacy policy
            </Link>{" "}
            .
          </p>
          <p className="mb-4">
            Additionally, by uploading content to the site, you warrant,
            represent and agree that you have the right to grant{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. the license described above. You undertake and warrant that you
            will not host, display, upload, modify, publish, transmit, update or
            share any “User Content” that:
          </p>
          <p className="mb-4">
            Violates/infringes anyone else’s patent, trademark, copyright or any
            other proprietary right; Belongs to any other person over which you
            don’t have any right; Is grossly harmful, harassing, blasphemous
            defamatory, obscene, pornographic, paedophilic, libellous, invasive
            of another's privacy, hateful, or racially, ethnically
            objectionable, disparaging, relating or encouraging money laundering
            or gambling, or otherwise unlawful in any manner whatsoever;
            Encourages conduct that would constitute a criminal offense, gives
            rise to civil liability or otherwise violates any law;
          </p>
          <p className="mb-4">
            Includes personal information such as messages which identify phone
            numbers, social security numbers, account numbers, addresses, or
            employer references; Is harmful or inappropriate for minors in any
            manner whatsoever;
          </p>
          <p className="mb-4">
            Is misleading or deceptive in terms of origin of the message or
            communicates any information that is grossly offensive or misleading
            in nature. Impersonates other person; Violates any local, state,
            federal, or international laws; reveals any trade secret, unless the
            trade secret belongs to you or you have the owner's permission to
            disclose it;
          </p>
          <p className="mb-4">
            You are responsible for your use of the Service, for any content you
            provide, and for any consequences thereof, including the use of your
            Content by other users and third party partners. You understand that
            your Content may be republished and if you do not have the right to
            submit Content for such use, it may subject you to liability.{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. will not be held responsible or liable for any use of your
            Content by{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. in accordance with these Terms.
          </p>
          <p className="mb-4">
            We have the right, but not the obligation, to monitor User Content.
            We have the right in our sole discretion and for any reason
            whatsoever to edit, refuse to post, remove, or disable access to any
            User Content.
          </p>
          <p className="mb-4">
            Your Content will be viewable by other users of the Service and
            through third party services and websites. You should only provide
            Content that you are comfortable sharing with others under these
            Terms. You have the right to delete any of your posts, or your
            account, anytime. We will try to complete the processing of the
            deletion as soon as possible. However, we may keep backup copies of
            your deleted post or account on our servers for up to 14 days after
            you delete it.
          </p>
          <p className="mb-4">
            The Contents posted by the users are for informational purposes
            only, with no assurance that the Content is true, correct, or
            accurate. No action should be taken, delayed, or deferred based on
            the Content. The Content shall not be considered as a professional
            advice and it shall be the responsibility of the user to consult a
            professional before relying on a content.{" "}
          </p>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. has no special relationship with or fiduciary duty to the User.
            User acknowledges that{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. has no control over, and no duty to take any action regarding
            what effects the Content may have on User and how user may interpret
            or use the Content; or what actions user may take as a result of
            having been exposed to the Content.
          </p>
          <h2 className="mb-3 text-xl Medium">Grant of License</h2>
          <p className="mb-4">
            At all instances, the services provided by the Website are not being
            sold to the users. Rather, the users are being granted a limited
            license to use the services. Subject to the compliance of the terms
            of this Agreement,{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. grants you a limited, royalty free, non-exclusive,
            non-transferable license to use and install one copy of the software
            on your device solely for the purpose of availing the services of
            the Website. The services are made available for the user’s personal
            use. The user agrees not to sub-license, sell, rent, lease, copy,
            modify or otherwise extract the source code of the Website or any
            part thereof. The user further agrees not to translate the Website
            into other language or make derivative versions of the Website.
          </p>
          <p className="mb-4">
            Subject to these Terms,{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. gives you a worldwide, royalty-free, revokable, non-assignable
            and non-exclusive license to re-post any of the Content on{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            anywhere on the rest of the web provided that the Content was added
            to the Service after June 1st 2017, and provided that the user who
            created the content has not explicitly marked the content as not for
            reproduction, and provided that you: (a) do not modify the Content;
            (b) attribute{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            by name in readable text and with a human and machine-followable
            link (an HTML anchor tag) linking back to the page displaying the
            original source of the content on the Website (c) upon request,
            either by Procurement League Inc. or by a user who contributed to
            the Content, make a reasonable effort to update a particular piece
            of Content to the latest version on the Website; and (d) upon
            request, either by{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. or by a user who contributed to the Content, make a reasonable
            attempt to delete Content that has been deleted or marked as not for
            reproduction on the Website.
          </p>
          <p className="mb-4">
            You may only use the attribution required by this Section in the
            manner set out above. In exercising these rights, you may not
            implicitly or explicitly assert or imply any connection with,
            sponsorship or endorsement by{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. or any other user of the Website, without the separate, express
            prior written permission of{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. or the user.
          </p>
          <p className="mb-4">
            We reserve the right at all times (but will not have an obligation)
            to remove or refuse to distribute any Content on the Service and to
            terminate users or reclaim usernames. We also reserve the right to
            access, read, preserve, and disclose any information we believe in
            good faith is reasonably necessary to (i) satisfy any applicable
            law, regulation, legal process or governmental request (for example,
            subpoenas or warrants), (ii) enforce or administer our agreements
            with users (such as these Terms), including investigation of
            potential violations hereof, (iii) detect, prevent, or otherwise
            address fraud, security or technical issues, (iv) respond to user
            support requests, or (v) protect the rights, property or safety of{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc., its users and the public.
          </p>
          <h2 className="mb-3 text-xl Medium">Prohibited Activities</h2>
          <p className="mb-4">
            Except as expressly permitted in these Terms of Use, you confirm and
            undertake that you will not:
          </p>
          <p className="mb-4">
            Use the Website in any illegal manner or any manner which encourages
            or promotes illegal activities. Modify, adapt, translate or reverse
            engineer or re-format or frame whole or any part of the Website.
            Upload or distribute any virus, bugs, trap doors, harmful codes or
            other similar software or programs that may damage the functioning
            of the Website or other user’s device.
          </p>
          <p className="mb-4">
            Violate, abuse, manipulate or exploit any terms and conditions of
            this Agreement or any code of conduct or other guideline as provided
            under the website. Make any defamatory, derogatory, abusive,
            inappropriate, indecent comment or statement about Procurement
            League Inc.,{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            and its associates and partners or any property owned by{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc..
          </p>
          <p className="mb-4">
            Circumvent, disable or otherwise interfere with our security-related
            features including, without limitation, any features that prevent or
            restrict the use of or copying of any software or other Service
            Content;
          </p>
          <p className="mb-4">
            Collect or harvest any personally identifiable information or
            non-personally identifiable information from our Services including,
            without limitation, user names, passwords, email addresses;
          </p>
          <p className="mb-4">
            Attempt to crawl, scrap, extract, and harvest or otherwise access
            any data or content of the website via any automated means or
            systematic means; Attempt to test the vulnerability of the Website
            by using unfair means to disproportionately put load on the
            website’s infrastructure. Make efforts to subvert the website’s
            navigational structure by hacking into the Website or Website
            database to obtain information about our business practices that is
            not available for personal use
          </p>
          <p className="mb-4">
            Engage in any conduct that restricts or inhibits any other user from
            using or enjoying our Services.
          </p>
          <p className="mb-4">
            Encourage conduct that violates any local, state or federal law,
            either civil or criminal, or impersonate another user, person, or
            entity;
          </p>
          <p className="mb-4">
            We may make available one or more APIs for interacting with the
            Service. Your use of any{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            API is subject to these terms and the{" "}
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            API Rules, which will be posted before we make these APIs available
            (as part of these Terms).
          </p>
          <h2 className="mb-3 text-xl Medium">Intellectual Property</h2>
          <p className="mb-4">
            All the contents including without limitation to information,
            graphics, data, text, layout, music, sound, photograph,
            arrangements, source code, software compilations, concept, methods
            of operation, processes, system logic , key terms or any other
            materials, contained or available in the website and App (excluding
            the content posted by the users) are the protected work of{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc.. We reverse all the intellectual property rights over all our
            works that is available through the services or the website. The
            user agrees not to do any of the following without the prior written
            consent of_{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc.:
          </p>
          <ul className="list-decimal">
            <li>
              Make copies of the Website for purpose of distribution to others
              or distribute the Website to others, whether directly or
              indirectly;
            </li>
            <li>
              Decompile, reverse engineer, disassemble or otherwise reduce the
              software to human-perceivable form;
            </li>
            <li>
              Modify, adapt, translate, rent or sublicense, assign, loan, resell
              for profit or distribute the Website or create derivative works
              based thereon.
            </li>
          </ul>
          <p className="mb-4">
            The trademark, logo and service mark displayed on or in the Website
            is the exclusive property of{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. as it reserves all the intellectual property right in these
            contents. The user agree not to use, reproduce, sell, transmit,
            create derivate work or distribute any content from the Website.
            Additionally, you agree not to use our trade dress, or copy the look
            and feel of our Website or its design, without our prior written
            consent. Use of the website does not entitle the user to make any
            unauthorized use of any protected content. User may use the
            protected content solely for personal use and will make no other use
            of the content without express written permission of{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc..
          </p>
          <h2 className="mb-3 text-xl Medium">Privacy</h2>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. values your privacy. Please review our{" "}
            <Link to="/privacy-policy" className="primary">
              privacy policy
            </Link>{" "}
            to learn more about how we collect and use information about you via
            the Website. By using the Website and service you consent to the
            transfer of your information to the United States and/or to other
            countries for storage, processing and use by{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. in accordance with our{" "}
            <Link to="/privacy-policy" className="primary">
              privacy policy
            </Link>{" "}
            .
          </p>
          <h2 className="mb-3 text-xl Medium">
            Indemnity and limitation of liability
          </h2>
          <p className="mb-4">
            You shall indemnify and hold harmless{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc., its officers, directors, employees, parents, partners,
            successors, agents, distribution partners, affiliates, subsidiaries,
            and their related companies (collectively, the “Website Parties”)
            from any claim or demand, or actions including reasonable attorneys'
            fees, made by any third party or penalty imposed due to or arising
            out of your breach of this{" "}
            <Link to="/term-of-services" className="primary">
              Terms of Service
            </Link>{" "}
            , and other Policies, or your violation of any law, rules or
            regulations or the rights including infringement of intellectual
            property rights of any third party.
          </p>
          <p className="mb-4">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            INC. AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
            LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT
            LIMITATION, LOSS OF PROFITS, DATA, USE, GOOD-WILL, OR OTHER
            INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR
            INABILITY TO ACCESS OR USE THE SERVICE; (ii) ANY CONDUCT OR CONTENT
            OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY
            DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD
            PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICE; AND (iv)
            UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR
            CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            INC. HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN
            IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
            ESSENTIAL PURPOSE.
          </p>
          <p className="mb-4">
            Some jurisdictions do not allow the exclusion of certain warranties
            or the exclusion or limitation of liability for consequential or
            incidental damages, so the limitations above may not apply to you.
          </p>
          <p className="mb-4">
            In the event you encounter any user or user content, that you
            believe violates any of our policy, you have the right to flag the
            account, user or content for our attention. Once a notification of
            reasonable complaint (with or without evidence) has been received,
            we reserve the full authority to block the content or user or take
            appropriate action against such user. The users understands that we
            are not in a position to investigate the legality of each case and
            therefore we shall not be held liable for any such action taken by
            us.
          </p>
          <p className="mb-4">
            The ratings and reviews linked with the questions and answers
            submitted by the users solely depends upon the other users reading
            or viewing such question and answers. We do not rate or control the
            ratings submitted by the users viewing your content. You agree that
            in no case we shall be made liable or questionable, if your rating
            are lower or you have been not rated as an ‘expert’.
          </p>
          <p className="mb-4">
            The system that makes available the services, uses proprietary
            algorithms (in addition to and based on the users reaction to
            content) and methods to evaluate and determine the most featured,
            popular, latest ‘topics of discussion’, top contributors and experts
            . You agree that in no case we shall be made liable or questionable
            if your content is not listed in the top rated one.
          </p>
          <h2 className="mb-3 text-xl Medium">
            Procurementleague.com Contest Policy Requirements
          </h2>
          <p className="mb-4">
            <Link to="/" className="primary">
              Procurementleague.com
            </Link>{" "}
            Contest allows contests to the extent they are permitted by law,
            subject to the following policy requirements and legal terms laid
            out below.
          </p>
          <p className="mb-4">
            Generating spam or encouraging individuals to engage in spam or
            abuse is strictly prohibited. Please note that the definition of
            spam as per the Contest may develop and change over time, and that{" "}
            <Link to="/" className="primary">
              Procurement League
            </Link>{" "}
            Inc. reserves the right to determine what qualifies as spam. In
            order to play a part in helping us fight spam and abuse, we have the
            following specific requirements for Contests:
          </p>
          <p className="mb-4">
            The official rules for the Contest must include a clear statement
            that anyone found to have created multiple accounts in order to
            increase their chances of winning, or anyone who posts entries in
            excess of an entry limit you have set, will be disqualified from the
            Contest. You must set an entry limit for your promotion below a
            level that is likely to encourage spam.
          </p>
          <p className="mb-4">
            If you ask participants to use hashtags in their Contest entries,
            they must be relevant to the kind of content users are posting. For
            example, do not ask for photos of sunsets with #spaghetti.
          </p>
          <p className="mb-4">
            Contests should not be used to try to circumvent any applicable
            terms or policies.
          </p>
          <p className="mb-4">
            Do not run Promotions that offer potential rewards in exchange for
            posting content, following a user, adding a user to one’s circles,
            mentioning a user (other than the contest creator or sponsor or an
            affiliate of the contest creator or sponsor), having other users
            enter the promotion, or voting in polls. Also, don’t run Promotions
            that encourage users to post unoriginal or repetitive content.
          </p>
          <p className="mb-4">
            Please note that some Contest entries may be filtered, hidden, or
            removed from the Website for spam or quality reasons, or if the
            entries violate this Contest Policy or any of our other applicable
            policies or{" "}
            <Link to="/term-of-services" className="primary">
              {" "}
              Terms of Service
            </Link>
            .
          </p>
          <h2 className="mb-3 text-xl Medium">
            Procurement League.com Contest Legal Terms
          </h2>
          <p className="mb-4">
            All Contests that run on{" "}
            <Link to="/" className="primary">
              {" "}
              Procurementleague.com
            </Link>{" "}
            or that use{" "}
            <Link to="/" className="primary">
              {" "}
              Procurementleague.com
            </Link>{" "}
            functionality are subject to the below rules.
          </p>
          <ul className="mb-4 list-decimal">
            <li>
              General Requirements.
              <ul className="mb-4 list-decimal">
                <li>
                  You are responsible for all the aspect of your Contest,
                  including all the official rules, offer terms, eligibility
                  requirements, and all requirements of your Contest
                  administration.
                </li>
                <li>
                  You are solely responsible for ensuring that Your Contest on
                  <Link to="/" className="primary">
                    {" "}
                    Procurementleague.com
                  </Link>{" "}
                  complies with all applicable federal (including trade
                  sanctions regulations), state, and local laws, as well as
                  rules, regulations, and applicable codes of conduct or
                  guidance in the jurisdiction(s) where your Contest is being
                  offered or advertised, including registering your Contest,
                  obtaining necessary regulatory approvals, language
                  requirements, and disclosure requirements. Please consult with
                  an attorney if you have questions about legal compliance.
                </li>
                <li>
                  You warrant that you will take all necessary measure to
                  instruct participants and make it clear that, the participants
                  are posting content or otherwise using{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurementleague.com
                  </Link>{" "}
                  as part of a Contest where required.
                </li>
                <li>
                  Your Contest cannot be run or conducted in a way that
                  conflicts with any of our policies or{" "}
                  <Link to="/term-of-services" className="primary">
                    {" "}
                    Terms of Service
                  </Link>{" "}
                  , including this Contest Policy, our privacy policy, Terms of
                  Service, and you must not encourage participants in your
                  Contest to engage in any activity that would violate any of
                  our policies or{" "}
                  <Link to="/term-of-services" className="primary">
                    {" "}
                    Terms of Service
                  </Link>{" "}
                  . Please review our policies before conducting any Contest on{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurementleague.com
                  </Link>{" "}
                  . If your contest or its promotion is deemed to violate any of
                  our policies or{" "}
                  <Link to="/term-of-services" className="primary">
                    {" "}
                    Terms of Service
                  </Link>{" "}
                  , we reserve the right to take appropriate action, including
                  prohibiting you from running future Contests on{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurementleague.com
                  </Link>{" "}
                  or disabling your account.
                </li>
              </ul>
            </li>
            <li>
              Liability
              <ul className="mb-4 list-decimal">
                <li>
                  You agree to clearly state that your Contest is in no way
                  sponsored, endorsed, administered by, or otherwise associated
                  with{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc.
                </li>
                <li>
                  You agree that in the official rules set for the contest, each
                  participant must completely release,{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc. to the full extent permitted by law, from all liability
                  arising out of or in relation to your Contest and services or
                  products offered by you through your contests or your company.
                  All the participants should be instructed to direct all
                  questions and comments regarding the Contest to you (the host
                  of the contest) and not to{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc.
                </li>
                <li>
                  The competitors for each contest contract directly with the
                  host of the contest, including with respect to the award of
                  prizes. We are not liable in any manner with respect to any
                  matters relating to the distribution of prizes.
                </li>
                <li>
                  {" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc. is not a party to any contracts between the competitors
                  and the host of the contest or otherwise between the users. If
                  you have any dispute with other users (either the host or a
                  competitor), you agrees to resolve such dispute directly with
                  such user without the involvement of{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc.
                </li>
                <li>
                  You hereby release{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc. from any liability associated with your Contest and you
                  agree to defend, indemnify, and hold harmless{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc., its parent corporation, affiliates, officers, directors,
                  employees, and agents, from and against any and all claims,
                  damages, obligations, losses, liabilities, costs, or debt and
                  expenses (including attorney's fees) arising from: (i) your
                  use of and access to our Website for your Contest; (ii) your
                  violation of any term of this Contest Policy or any other
                  policies of our Website; (iii) any violation within your
                  Contest of any third party right, including any copyright,
                  property, publicity (including defamation), or privacy right;
                  (iv) any claim from any third party relating to or arising
                  from your Contest including for any prizes awarded or the
                  failure to award such prizes; or (v) any claim that your
                  Contest violates any law, rule, or regulation. This defense
                  and indemnification obligation will survive the termination of
                  the service, changes to this Contest Policy or any other{" "}
                  <Link to="/" className="primary">
                    {" "}
                    Procurementleague.com
                  </Link>{" "}
                  policies.
                </li>
              </ul>
            </li>
            <li>
              {" "}
              <Link to="/" className="primary">
                {" "}
                Procurementleague.com
              </Link>{" "}
              services and Site Functionality.
              <ul className="mb-4 list-decimal">
                <li>
                  You agree and understand that we will not assist you in the
                  administration of your Contest.
                </li>
                <li>
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc. is not responsible for any issues with your Contest, in
                  the event there arises a problem or interruption on the
                  Website or with any functionality of the Website.
                </li>
                <li>
                  <Link to="/" className="primary">
                    {" "}
                    Procurement League
                  </Link>{" "}
                  Inc. is not liable to you for termination of any account, the
                  removal or reposting of any content, or any action or inaction
                  related to user content or submissions related to Contest or
                  otherwise.
                </li>
                <li>
                  You (the host of the contest) assume all risks associated with
                  the administration of your Contest on our Website.
                </li>
              </ul>
            </li>
          </ul>
          <h2 className="mb-3 text-xl Medium">General Terms</h2>
          <p className="mb-4">
            We may modify these terms or any additional terms that apply to a
            Service to, for example, reflect changes to the law or changes to
            our Services. You should look at the terms regularly. We’ll post
            notice of modifications to these terms on this page. We’ll post
            notice of modified additional terms in the applicable Service.
            Changes will not apply retroactively and will become effective no
            sooner than fourteen days after they are posted. However, changes
            addressing new functions for a Service or changes made for legal
            reasons will be effective immediately. If you do not agree to the
            modified terms for a Service, you should discontinue your use of
            that Service.
          </p>
          <p className="mb-4">
            If there is a conflict between these terms and the additional terms,
            the additional terms will control for that conflict.
          </p>
          <p className="mb-4">
            These terms control the relationship between{" "}
            <Link to="/" className="primary">
              {" "}
              Procurement League
            </Link>{" "}
            Inc. and you. They do not create any third party beneficiary rights.
          </p>
          <p className="mb-4">
            If you do not comply with these terms, and we don’t take action
            right away, this doesn’t mean that we are giving up any rights that
            we may have (such as taking action in the future).
          </p>
          <p className="mb-4">
            If it turns out that a particular term is not enforceable, this will
            not affect any other terms.
          </p>
          <p className="mb-4">
            This User Agreement will be governed by and construed in accordance
            with the applicable laws of the United states and any dispute
            arising out of or in connection to these terms of use shall be
            subject to the exclusive jurisdiction of the applicable court of
            General Corporation Law of Delaware. And you and{" "}
            <Link to="/" className="primary">
              {" "}
              Procurement League
            </Link>{" "}
            Inc. consent to personal jurisdiction in those courts.
          </p>
          <p>
            The Service is operated and provided by{" "}
            <Link to="/" className="primary">
              {" "}
              Procurement League
            </Link>{" "}
            Inc. If you have questions about these Terms, please contact us at{" "}
            <a className="primary" href="mailto:procurement@pl.com">
              admin@procurementleague.com
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default TermsService;
