import {
  Publicdiscussion,
  Detailhead,
  Detailabout,
  Buttonlink,
  Noconnection,
  Productdetail,
} from "components";
import React, { Component } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { connect } from "react-redux";
import GET_CLASS_BY_ID from "../../../graphql/queries/getCourse";
import { Query } from "@apollo/client/react/components";
import TrainingModuleFooter from "components/allcomponents/trainingModule";

class ResourceDetail extends Component {
  render() {
    let { product, type, authUser } = this.props;
    let variables = {};
    if (product) {
      variables.id = product;
    }
    variables.cursor = 1;
    return (
      <div className="container">
        <Buttonlink
          Text="<< Back"
          to="/products"
          className="flex primary items-center uppercase justify-start px-4 mb-3 rounded-full text-lg h-9"
        />
        <Query query={GET_CLASS_BY_ID} variables={variables}>
          {({ loading, error, data, fetchMore, refetch }) => {
            if (loading) return <Productdetail />;

            if (error) {
              return (
                <div>
                  <Noconnection />
                </div>
              );
            }
            if (this.props.course_recall) {
              refetch();
              setTimeout(() => {
                this.props.handleClassReset(false);
              }, 20);
            }
            if (data) {
              this.props.handleClassDetails(data);
            }
            return (
              <>
                <div className="grid lg:gap-20 gap-5 mt-5 lg:grid-cols-2">
                  <div>
                    <Detailabout
                      type={type}
                      event={data.course}
                      authUser={authUser}
                    />
                  </div>
                  {/* <div>
                    <Publicdiscussion
                      type={type}
                      course={data.course}
                      authUser={authUser}
                    />
                  </div> */}
                  <Detailhead type={type} />
                </div>
              </>
            );
          }}
        </Query>
        <TrainingModuleFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
  course_recall: state.CourseReducer.course_recall,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleClassDetails: (data) =>
      dispatch({ type: "GET_SINGLE_CLASS_INFORMATION", payload: data }),
    handleClassReset: (data) =>
      dispatch({ type: "COURSE_RECALL", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDetail);
