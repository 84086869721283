import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import "./styles.css";
import client from "../../../graphql/client";
import All_Tags from "../../../graphql/queries/getAllTags";
import All_CATEGORIES from "../../../graphql/queries/getCategories";
import SEARCH_USERS from "../../../graphql/queries/searchUsers";
import QUESTION_ABUSE_REPORT from "../../../graphql/mutations/reportMessageMutation";
import { connect } from "react-redux";
import { TextArea } from "semantic-ui-react";
import "../../../assets/css/semantic.css";
import { Buttonlink, IsLoader, Noconnection, Nocontent } from "components";
import Auth from "../../../Auth";
import UseInput from "../useInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagenotfound from "../pagenotfound";
import { Query } from "@apollo/client/react/components";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { extractValidationErrors } from "helpers/extractValidationErrors";
const auth = new Auth();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class Fullscreenpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      all_tags: [],
      categories: [],
      users: [],
      results: [],
      q: "",
      selected_tags: [],
      selected_topics: [],
      typedEmail: "",
      emailHasError: "",
      loading : false
    };
    this.timeoutValue = 0;
    if (auth.isAuthenticated()) {
      client.query({ query: All_Tags, fetchPolicy: "cache-first" }).then((res) => {
        this.setState({ all_tags: res.data.alltags });
      })
        .catch((error) => {
          extractValidationErrors(error);
        });

      client.query({ query: All_CATEGORIES, fetchPolicy: "cache-first" }).then((res) => {
        this.setState({ categories: res.data.categories });
      })
        .catch((error) => {
          extractValidationErrors(error);
        });
    } else {

    }
  }

  _handleUserChange = () => {
    if (this.props.title === "Select Member") {
      clearTimeout(this.timeoutValue);
      this.timeoutValue = setTimeout(() => {
        this.setState({ q: this.props.typeSearch });
      }, 1000);
    }
  };

  openPopup = () => {
    this.setState({ open: true });
  };

  closePopup = () => {
    this.setState({ open: false });
    this.props.handleSearch("");
  };

  _handleTagChange = (e, tag_id, tag_title) => {
    let checked = e.target.checked;
    let tags = [...this.props.selectedTags];
    if (checked) {
      if (!tags.some((tag) => tag.id === tag_id)) {
        tags.push({ id: tag_id, title: tag_title });
      }
    } else {
      tags = tags.filter((tag) => tag.id !== tag_id);
    }

    this.setState({ selected_tags: tags });
    this.props.handleTagSearch(tags);
  };

  _handleCatagoryChange = (e, id) => {
    let checked = e.target.checked;
    let topics = [...this.props.selectedTopics];
    if (checked) {
      topics.push(id);
    } else {
      topics = topics.filter((i) => i !== id);
    }

    this.props.handleCategorySearch(topics);
  };

  _handleUsersChange = (e, id) => {
    let checked = e.target.checked;
    let Users = [...this.props.selectedUsers];
    if (checked) {
      Users.push(id);
    } else {
      Users = Users.filter((i) => i !== id);
    }
    this.props.handleUsersChange(Users);
  };

  report = () => {
    if (this.props.title === "Report & Block") {
      if (this.state.q === "") {
        this.notify("Title field is required", "error");
      } else {
        this.setState({ loading: true });
        client
          .mutate({
            mutation: QUESTION_ABUSE_REPORT,
            variables: {
              title: this.state.q,
              question_id: this.props.data ? parseInt(this.props.data.id) : "No data found",
              tag: this.props.type ? this.props.type : "No type found",
            },
          })
          .then((res) => {
            if (res.data.questionAbuseReport.error === "false") {
              this.setState({ q: "", open: false }, () => {
                this.setState({ loading: false });
                this.notify(res.data.questionAbuseReport.message, "success");
              });
            } else {
              this.setState({ loading: false });
              this.notify(res.data.questionAbuseReport.message, "error");
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            extractValidationErrors(err);
          });
      }
    }
  };

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    } else {
      return toast.success(message, { position: "bottom-right" });
    }
  };

  startsWith = (array, title, key) => {
    const matcher = new RegExp(`^${key}`, "g");
    if (title === "Select Topic") {
      return array.filter((word) => word.name.match(matcher));
    } else {
      return [];
    }
  };

  startsWithTags = (array, title, key, all_tags) => {
    const matcher = new RegExp(`^${key}`, "g");
    return title === "Select Tags"
      ? array.filter((word) => word.tag_title.match(matcher))
      : all_tags;
  };

  renderTagsList = () => {
    const { all_tags } = this.state;
    const { title, typeSearch, selectedTags } = this.props;

    // If the title is not "Select Tags", don't show any content
    if (title !== "Select Tags") {
      return null;
    }

    // If there's a search term and filtered tags exist
    if (typeSearch && typeSearch.length > 1) {
      const filteredTags = this.startsWithTags(all_tags, title, typeSearch, all_tags);

      if (filteredTags.length > 0) {
        return filteredTags.map((tag) => (
          <ListItem disablePadding className="overflow-hidden rounded-lg" key={tag.id}>
            <ListItemButton dense>
              <ListItemText>
                <div className="darkGray">{tag.tag_title}</div>
              </ListItemText>
              <ListItemIcon>
                <Checkbox
                  className="primaryCheckboxes"
                  value={tag.id}
                  onClick={(e) => this._handleTagChange(e, tag.id, tag.tag_title)}
                  checked={selectedTags.some((selectedTag) => selectedTag.id === tag.id)}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ));
      } else {
        // No matching tags found
        return <Nocontent />;
      }
    }

    // If no search term, show all tags
    return all_tags.map((tag) => (
      <ListItem disablePadding className="overflow-hidden rounded-lg" key={tag.id}>
        <ListItemButton dense>
          <ListItemText>
            <div className="darkGray">{tag.tag_title}</div>
          </ListItemText>
          <ListItemIcon>
            <Checkbox
              className="primaryCheckboxes"
              value={tag.id}
              onClick={(e) => this._handleTagChange(e, tag.id, tag.tag_title)}
              checked={selectedTags.some((selectedTag) => selectedTag.id === tag.id)}
            />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    ));
  };


  render() {
    const { open, all_tags, categories } = this.state; // Destructure categories from state
    const { title } = this.props;

    let variables = { cursor: 1 };
    if (this.props.title === "Select Member" && this.props.typeSearch !== "") {
      variables.q = this.state.q;
    } else {
      delete variables.q;
    }
    return (
      <div>
        <ToastContainer />
        <Dialog
          TransitionComponent={Transition}
          fullScreen
          open={open}
          onClose={this.closePopup}
          className="fullScreenDialog"
          id="scrollbar"
        >
          <div className="bg-gray-100 shadow-none" sx={{ position: "relative" }}>
            <Toolbar className="flex items-center justify-between">
              <h3 className="text-xl black Medium">{title}</h3>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.closePopup}
                aria-label="close"
              >
                <IoIosClose size={32} className="primary" />
              </IconButton>
            </Toolbar>
          </div>
          <div className="p-3">
            {title !== "Send by email" && title !== "Report & Block" ? (
              <UseInput
                title={title}
                _handleUserChange={this._handleUserChange}
              />
            ) : null}

            {/* Render Select Topic */}
            {title === "Select Topic" &&
              (this.props.typeSearch.length === 1 ||
                this.startsWith(categories, title, this.props.typeSearch).length) ? (
              this.startsWith(categories, title, this.props.typeSearch).map((data) => (
                <ListItem disablePadding className="overflow-hidden rounded-lg" key={data.id}>
                  <ListItemButton dense>
                    <ListItemText>
                      <p className="darkGray">{data.name}</p>
                    </ListItemText>
                    <ListItemIcon>
                      <Checkbox
                        className="primaryCheckboxes"
                        value={data.id}
                        onClick={(e) => this._handleCatagoryChange(e, data.id)}
                        checked={this.props.selectedTopics.includes(data.id)}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))
            ) : title === "Select Topic" && <Nocontent />}

            {/* Render Select Tags */}
            {title === "Select Tags" && (
              this.renderTagsList()
            )}

            {/* Render Select Member */}
            {title === "Select Member" ? (
              <Query query={SEARCH_USERS} variables={variables}>
                {({ loading, error, data, fetchMore, refetch }) => {
                  if (loading) {
                    return (
                      <div className="my-10">
                        <IsLoader className="w-32 h-32 mx-auto" />
                      </div>
                    );
                  }
                  if (error) {
                    extractValidationErrors(error);
                    return <Noconnection />
                  };

                  if (!data) {
                    return Pagenotfound;
                  }

                  if (data.UserSearch.data.length === 0) return <Nocontent />;

                  if (data !== undefined) {
                    return (
                      <div>
                        {data.UserSearch?.data?.map((user) => (
                          <ListItem
                            disablePadding
                            className="overflow-hidden rounded-lg"
                            key={user.id}
                          >
                            <ListItemButton dense>
                              <ListItemText>
                                <div className="darkGray">
                                  {user.firstname} {user.lastname}
                                </div>
                              </ListItemText>
                              <ListItemIcon>
                                <Checkbox
                                  className="primaryCheckboxes"
                                  value={user.id}
                                  onClick={(e) => this._handleUsersChange(e, user.id)}
                                  checked={this.props.selectedUsers.includes(user.id)}
                                />
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </div>
                    );
                  }
                }}
              </Query>
            ) : null}

            {/* Render Send by email */}
            {title === "Send by email" && (
              <>
                <div className={'flex'}>
                  <input
                    id="standard-bare"
                    placeholder="Search email"
                    value={this.state.typedEmail}
                    className="w-full h-12 px-3 mb-3 bg-gray-200 border-0 rounded-lg Regular"
                    onChange={(e) => this.setState({ typedEmail: e.target.value })}
                  />
                  <Buttonlink
                    onClick={() => {
                      this.props.handleEmailChange(this.state.typedEmail);
                      this.setState({ typedEmail: "" });
                    }}
                    Icon={<AiOutlineUserAdd size={35} className={'primary'} />}
                  />
                </div>
                {this.props.typedEmail &&
                  this.props.typedEmail.map((email, index) => (
                    <ListItem disablePadding className="overflow-hidden rounded-lg" key={index}>
                      <ListItemButton dense>
                        <ListItemText>
                          <p className="darkGray">{email}</p>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </>
            )}

            {/* Render Report & Block */}
            {title === "Report & Block" ? (
              <div className="flex flex-col h-full p-3 -mt-5">
                <div className="flex-grow">
                  <p className="block ml-2 Medium">Title:</p>
                  <div className="flex items-center w-full px-3 bg-gray-100 rounded-xl">
                    <input
                      value={this.state.q}
                      onChange={(event) => this.handleInputChange(event)}
                      name="q"
                      className="flex-grow w-full bg-gray-100 border-0 h-11 Regular rounded-xl"
                    />
                  </div>
                  <div>
                    <p className="block mt-3 ml-2 Medium">Reason: </p>
                    <div className="flex items-center w-full p-3 bg-gray-100 rounded-xl">
                      <TextArea
                        className="w-full bg-transparent border-0 rounded-md shadow-none resize-none outline-0"
                        rows={5}
                      />
                    </div>
                  </div>
                </div>
                <Buttonlink
                  onClick={this.report}
                  Text={"Submit"}
                  disabled={this.state.loading}
                  className="flex items-center justify-center w-full border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 mt-2 py-2 rounded--xl"
                />
              </div>
            ) : null}
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTopics: state.DiscussionSearchReducer.categories,
  selectedTags: state.DiscussionSearchReducer.selectedTags,
  selectedUsers: state.DiscussionSearchReducer.selectedUsers,
  typedEmail: state.DiscussionSearchReducer.typedEmail,
  typeSearch: state.DiscussionSearchReducer.typeSearch,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleTagSearch: (tags) => dispatch({ type: "SELECTED_TAGS", payload: tags }),
    handleCategorySearch: (data) => dispatch({ type: "SELECTED_CATEGORY", payload: data }),
    handleUsersChange: (tags) => dispatch({ type: "SELECTED_USER", payload: tags }),
    handleEmailChange: (email) => dispatch({ type: "TYPE_EMAIL", payload: email }),
    handleSearch: (search) => dispatch({ type: "TYPE_SEARCH", payload: search }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  { forwardRef: true }
)(Fullscreenpopup);
