import { gql } from "@apollo/client";

export default gql`
    mutation AddFreeAccess($orderIdOrPlainId:Int,$order_type:CourseStatus,$coupon_code:String,$discount:String,$amount:Float,$email:String) {
        addFreeAccess(orderIdOrPlainId:$orderIdOrPlainId,order_type:$order_type,coupon_code:$coupon_code,discount:$discount,amount:$amount,email:$email){
             error
            message
            redirect
            method
            amount
        }
    }

`;
