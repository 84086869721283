import React, { useEffect } from "react";
import Subtract from "assets/images/Subtract-white.png";
import businessb2b from "assets/images/industries/businessb2b.webp";
import business_two from "assets/images/industries/business_two.webp";
import casestudy1 from "assets/images/industries/casestudy1.png";
import casestudy2 from "assets/images/industries/casestudy2.png";
import business_three from "assets/images/industries/business_three.webp";
import banner from "assets/images/industries/Industryb2b.webp";
import man_arrow from "assets/images/industries/man_arrow.webp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import contactgroup from "assets/images/industries/contactgroupprimary.webp";
import { BsArrowDown, BsPlusLg } from "react-icons/bs";
import { ContactUsCard, IndustrySliderB2B } from "components";
import { useLocation } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";
import { Link } from "react-router-dom";

const IndustriesSolutionsB2BPage = () => {
    const { state } = useLocation();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (state?.advisory) {
            scrollToContent('advisory', 2000)
        } else if (state?.community) {
            scrollToContent('community', 3000)
        } else if (state?.caseStudy) {
            scrollToContent('caseStudy', 3000)
        } else {
            window.scrollTo(0, 0);
        }
    }, []);



    return (
        <>
            <div className="pb-28 -mt-[50px]">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden relative">
                        <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] IndustriesSolutionsB2BBanner relative">
                        <div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Industry Solutions: B2B Solutions
                            </p>
                            <p className="white capitalize font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Sell more with targeted solutions for B2B
                                Buyers, Procurement and Finance Executives
                            </p>
                            <p className="white Poppins font-normal">
                                Discover Tailored Strategies to Maximize Your
                                Growth and Impact with us!
                            </p>
                            <div className="h-px bg-white mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={Subtract}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>
                <div className="container relative">
                    <div className="absolute z-[-1] top-0 -left-28 lg:block hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="266"
                            height="265"
                            viewBox="0 0 266 265"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_967_2840)">
                                <path
                                    d="M46.9956 132.866L101.857 148.997L85.8594 203.401C81.8601 217.001 89.1471 230.227 102.862 234.26C116.578 238.293 129.863 231.117 133.863 217.516L149.86 163.112L204.721 179.244C218.436 183.277 231.722 176.101 235.721 162.5C239.72 148.899 232.433 135.674 218.718 131.641L163.857 115.509L179.854 61.1059C183.854 47.5051 176.567 34.2797 162.851 30.2468C149.136 26.2139 135.85 33.3899 131.851 46.9907L115.854 101.394L60.9931 85.2625C47.2778 81.2296 33.992 88.4056 29.9927 102.006C25.9934 115.607 33.2804 128.833 46.9956 132.866Z"
                                    fill="#B5E2FF"
                                    fillOpacity="0.7"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_967_2840">
                                    <rect
                                        width="214.438"
                                        height="212.65"
                                        fill="white"
                                        transform="translate(205.727 264.506) rotate(-163.614)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="absolute top-0 right-0 lg:block hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            />
                        </svg>
                    </div>
                    <div className="mt-28" id="contentSection">
                        <div className="space-y-8">
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="Poppins capitalize font-semibold text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                                    Decoding Procurement's Language: Messages
                                </h1>
                                <h1 className="Poppins capitalize font-semibold text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                                    That Truly Resonate
                                </h1>
                            </div>
                            <p className="text-3xl Poppins w-full max-w-5xl mx-auto font-light text-center text-new-color">
                                Our procurement expertise spans diverse industries, markets, and selling scenarios - empowering you with proven strategies from day one, no trial-and-error required.
                            </p>
                            <div className="space-y-10 w-full max-w-4xl mx-auto">
                                <p className="text-3xl Poppins font-light primary">
                                    Our Exclusive Services
                                </p>
                                <p className="text-lg Poppins font-normal text-new-color">
                                    Embrace the thrill of selling to procurement with a partner that understands the game from the inside out. Common hurdles include:
                                </p>
                                <ul className="!mt-5">
                                    <li className="text-lg Poppins font-normal text-new-color">
                                        Navigating the Complex B2B Selling Landscape
                                    </li>
                                    <li className="text-lg Poppins font-normal text-new-color">
                                        The Procurement Expertise Gap
                                    </li>
                                    <li className="text-lg Poppins font-normal text-new-color">
                                        Crafting effective messaging
                                    </li>
                                    <li className="text-lg Poppins font-normal text-new-color">
                                        Boosting RFQ responses
                                    </li>
                                </ul>
                                <p className="text-lg Poppins font-normal text-new-color">
                                    But here's where the excitement begins: Unlock the power of procurement-focused selling strategies with us.
                                </p>
                                <p className="text-3xl Poppins font-light primary">
                                    Proven Results, Measurable Impact
                                </p>
                                <p className="text-lg Poppins font-normal text-new-color">
                                    Our procurement-focused strategies have helped numerous service providers break through barriers, boost RFQ response rates, and secure crucial sales with B2B buyers, procurement, and finance executives. We'll equip you with the tools, knowledge, and mindset to achieve measurable growth and lasting impact.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-28">
                        <div className="bg-[#233862] lg:h-[338px] h-[400px] relative p-5">
                            <div className="absolute lg:w-[146px] w-[100px] h-[108px] inset-x-0 mx-auto -top-14">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 146 108"
                                    fill="none"
                                >
                                    <path
                                        d="M19.2708 55.0287C24.1656 48.6308 31.9125 45.7787 40.7771 45.7787C57.1573 45.7787 68.9896 56.8402 68.9896 75.4944C68.9896 93.0308 57.9281 107.908 34.6875 107.908C13.3354 107.908 0 92.6454 0 67.8631C0 34.7173 19.926 8.70166 55.9625 0.10687C58.0823 -0.394171 60.4333 0.916245 61.1656 2.9975L61.8979 5.07875C62.6302 7.16 61.5125 9.2027 59.3927 9.81937C35.6125 16.4871 21.1979 30.9787 17.6135 54.4506C17.3052 56.609 17.9219 56.8017 19.2708 55.0287ZM95.5063 55.0287C100.401 48.6308 108.148 45.7787 117.013 45.7787C133.393 45.7787 145.225 56.8402 145.225 75.4944C145.225 93.0308 134.164 107.908 110.923 107.908C89.5708 107.908 76.2354 92.6454 76.2354 67.8631C76.2354 34.7173 96.1615 8.70166 132.236 0.10687C134.356 -0.394171 136.707 0.916245 137.44 2.9975L138.172 5.07875C138.904 7.16 137.786 9.2027 135.667 9.81937C111.886 16.4871 97.4719 30.9787 93.8875 54.4506C93.5406 56.609 94.1958 56.8017 95.5063 55.0287Z"
                                        fill="#FF6600"
                                    />
                                </svg>
                            </div>
                            <p className="font-light text-3xl Poppins text-white w-full max-w-5xl text-center mx-auto flex justify-center items-center h-full">
                                Everything we do — from crafting compelling
                                content to strategic media planning — is driven
                                by a deep understanding of your target audience
                                and their preferences.
                            </p>
                        </div>
                    </div>
                    <div className="mt-28 space-y-28">
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-[64px] gap-y-5">
                            <div className="lg:col-span-2 space-y-5">
                                <h1 className="Poppins font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                                    Welcome To Our Top-of-The-Marketing Funnel
                                    Content Hub Services
                                </h1>
                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <h1 className="Poppins mb-5 font-light primary text-3xl">
                                            Educational Content
                                        </h1>
                                        <ul>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Articles:
                                                </span>{" "}
                                                From research to compelling brand narratives
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Whitepapers:
                                                </span>{" "}
                                                From industry insights to thought leadership
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    eBooks:
                                                </span>{" "}
                                                From concept to comprehensive procurement guides
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Guides:
                                                </span>{" "}
                                                From expertise to audience-captivating content
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Playbooks:
                                                </span>{" "}
                                                From strategy to customer-focused action plans
                                            </li>
                                        </ul>
                                        <h1 className="Poppins my-5 font-light primary text-3xl">
                                            Informative Communication
                                        </h1>
                                        <ul>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Newsletters:
                                                </span>{" "}
                                                From industry updates to audience engagement
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Blogs:
                                                </span>{" "}
                                                From expert insights to thought leadership positioning
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Email Magic:
                                                </span>{" "}
                                                From campaign design to brand reinforcement
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h1 className="Poppins mb-5 font-light primary text-3xl">
                                            Engaging Stories and Testimonials
                                        </h1>
                                        <ul>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Case Studies:
                                                </span>{" "}
                                                From client success to impactful RFQ responses
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Success Stories:
                                                </span>{" "}
                                                From achievements to service potential showcases
                                            </li>
                                        </ul>
                                        <h1 className="Poppins my-5 font-light primary text-3xl">
                                            Comparative Analysis
                                        </h1>
                                        <ul>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Comparison Guides:
                                                </span>{" "}
                                                From data compilation to informed decision tools
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="h-[calc(100%-20px)]">
                                <img
                                    src={businessb2b}
                                    alt="businessb2b"
                                    className="h-full object-cover"
                                />
                            </div>
                            <Link to={'/contact-us'} className="flex mt-8 lg:col-span-2 col-span-1 justify-between bg--lightBlue px-10 py-4 rounded-full items-center">
                                <p className="font-normal text-lg Poppins text-new-color">
                                    Discover what a 6-month plan looks like for
                                    your company.
                                </p>
                                <BsArrowDown className="primary" size={24} />
                            </Link>
                        </div>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-[64px] gap-y-5" id="advisory">
                            <div className="h-full">
                                <img
                                    src={business_two}
                                    alt="business_two"
                                    className="h-full object-cover"
                                />
                            </div>
                            <div className="lg:col-span-2">
                                <h1 className="Poppins font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                                    Advisory
                                </h1>
                                <div className="mt-5">
                                    <p className="font-normal text-lg Poppins text-new-color">
                                        <span className="font-bold text-lg Poppins text-new-color">
                                            Picture this:
                                        </span>{" "}
                                        The business world is like a bustling
                                        marketplace, and you're in the middle of
                                        it all. But here's the challenge - you
                                        have limited resources, and finding the
                                        right marketing help can be tough.
                                    </p>
                                    <p className="font-normal text-lg Poppins text-new-color">
                                        <span className="font-bold text-lg Poppins text-new-color">
                                            Now, imagine a solution:
                                        </span>{" "}
                                        We step in to help. Using our expertise,
                                        we'll create plans for 1-3 years to
                                        boost your brand, help you grow, and
                                        come up with exciting ways to promote
                                        your products. - Let's make it happen
                                        together.
                                    </p>
                                </div>
                                <h1 className="Poppins my-10 font-light primary text-3xl">
                                    Our Specialized Services
                                </h1>
                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <h1 className="Poppins mb-5 font-light primary text-3xl">
                                            Data-Driven Insights
                                        </h1>
                                        <ul>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Price Benchmarking:
                                                </span>{" "}
                                                From market analysis to strategic pricing
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Surveys:
                                                </span>{" "}
                                                From questionnaire design to insight extraction
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Data Analysis:
                                                </span>{" "}
                                                From raw data to actionable intelligence
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Skill-Building Workshops:
                                                </span>{" "}
                                                From concept learning to practical application
                                            </li>
                                        </ul>
                                        <h1 className="Poppins my-5 font-light primary text-3xl">
                                            Market Navigation
                                        </h1>
                                        <p className="Poppins font-normal text-new-color text-lg">
                                            <span className="Poppins font-bold text-new-color text-lg">
                                                Trend Analysis:
                                            </span>{" "}
                                            From data collection to future forecasting
                                        </p>
                                        <p className="Poppins font-normal text-new-color text-lg">
                                            <span className="Poppins font-bold text-new-color text-lg">
                                                Opportunity Identification:
                                            </span>{" "}
                                            From market gaps to growth strategies
                                        </p>
                                    </div>
                                    <div>
                                        <h1 className="Poppins mb-5 font-light primary text-3xl">
                                            Competitive Edge
                                        </h1>
                                        <ul>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Competitor Analysis:
                                                </span>{" "}
                                                From industry mapping to strategic positioning
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Sales Enablement:
                                                </span>{" "}
                                                From content creation to team empowerment
                                            </li>
                                            <li className="Poppins font-normal text-new-color text-lg">
                                                <span className="Poppins font-bold text-new-color text-lg">
                                                    Brand Strategy:
                                                </span>{" "}
                                                From identity development to market resonance
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-28 lg:h-[504px] bg--primary">
                    <div className="grid lg:grid-cols-2 gap-4 md:grid-cols-2 place-content-center items-center grid-cols-1 h-full">
                        <div>
                            <img src={man_arrow} alt="man_arrow" />
                        </div>
                        <div className="relative">
                            <h1 className="Poppins text-left self-start w-full max-w-xl lg:text-[48px] lg:leading-[60px] text-[36px] text-white font-light">
                                Navigating Success Together: Your Brand, Our
                                Expertise.
                            </h1>
                            <div className="lg:block hidden absolute right-10 inset-y-0">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="309"
                                    height="306"
                                    viewBox="0 0 309 306"
                                    fill="none"
                                >
                                    <path
                                        d="M27.5674 153.676L108.776 177.555L85.0961 258.087C79.1761 278.22 89.9628 297.796 110.265 303.766C130.567 309.736 150.234 299.114 156.154 278.981L179.834 198.449L261.042 222.328C281.345 228.298 301.011 217.676 306.931 197.543C312.851 177.41 302.065 157.833 281.762 151.863L200.554 127.984L224.234 47.4528C230.154 27.3199 219.367 7.74295 199.065 1.77318C178.763 -4.1966 159.096 6.42575 153.176 26.5586L129.496 107.09L48.2873 83.211C27.9851 77.2412 8.31853 87.8636 2.39854 107.996C-3.52145 128.129 7.26519 147.706 27.5674 153.676Z"
                                        fill="white"
                                        fillOpacity="0.1"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#233862] py-20 relative" id="caseStudy">
                    <div className="absolute bottom-[17%] right-0 lg:block hidden">
                        <svg
                            width="170"
                            height="488"
                            viewBox="0 0 339 488"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M487 244.388C487 378.869 377.981 487.888 243.5 487.888C109.019 487.888 0 378.869 0 244.388C0 109.906 109.019 0.887695 243.5 0.887695C377.981 0.887695 487 109.906 487 244.388Z"
                                fill="#FFFAEF"
                                fillOpacity="0.1"
                            />
                        </svg>
                    </div>
                    <div className="absolute -top-44 left-[15%] lg:block hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="268"
                            height="268"
                            viewBox="0 0 268 268"
                            fill="none"
                        >
                            <path
                                d="M268 134C268 208.006 208.006 268 134 268C59.9938 268 0 208.006 0 134C0 59.9938 59.9938 0 134 0C208.006 0 268 59.9938 268 134Z"
                                fill="#FFFAEF"
                                fillOpacity="0.1"
                            />
                        </svg>
                    </div>
                    <div className="container">
                        <h1 className="text-center text-white Poppins my-4 lg:text-5xl text-4xl font-light pb-3">
                            Case Studies
                        </h1>
                        <div>
                            <h1 className="Poppins success-new font-semibold text-2xl text-center mt-4 pb-2">
                                Case Study1: Amplifying Reach and Influence
                            </h1>
                            <div className="flex flex-col gap-4 mt-5 bg-white custom-shadow p-5 rounded-3xl">
                                <p className="Poppins text-lg font-normal text-[#16283A]">Case Studies</p>
                                <Accordion
                                    expanded={expanded === "panel1"}
                                    onChange={handleChange("panel1")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Background</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="gap-4 flex flex-col">
                                            <p className="text-new-color text-lg Poppins font-normal">Global Sourcing Association, a prominent organization in the procurement & sourcing industry, partnered with Procurement League to elevate its online presence and engage with a broader audience through strategic cross-promotion and content creation.</p>
                                            <img src={casestudy1} alt="casestudy1" />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel2"}
                                    onChange={handleChange("panel2")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Objectives</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4">
                                            <ul className="!p-0 !ml-10">
                                                <li className="text-new-color text-lg Poppins font-normal">Enhance Global Sourcing Association's brand visibility and recognition within the sourcing & procurement community.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">Drive traffic to Global Sourcing Association's digital platforms and generate leads.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">Position Global Sourcing Association as a thought leader in the sourcing industry.</li>
                                            </ul>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel3"}
                                    onChange={handleChange("panel3")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Our Approach</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="gap-4 flex flex-col">
                                            <p className="text-new-color text-lg Poppins font-normal">Procurement League collaborated exclusively with Global Sourcing Association to implement a comprehensive strategy focusing on cross-promotion and content creation.</p>
                                            <p className="text-new-color text-lg Poppins font-normal">This involved:</p>
                                            <p className="text-new-color text-lg Poppins font-normal">
                                                <strong className="text-new-color text-lg Poppins font-bold">1. Cross-Promotion:</strong> Procurement League promoted Global Sourcing Association's content and services across its social media platforms, including articles, webinars, events, and industry insights tailored to sourcing professionals.
                                            </p>
                                            <p className="text-new-color text-lg Poppins font-normal">
                                                <strong className="text-new-color text-lg Poppins font-bold">2. Content Creation and Promotion:</strong> Procurement League developed compelling content for Global Sourcing Association, highlighting emerging trends, research custom reports, educational contents and case studies in the sourcing & procurement domain. This content was strategically promoted across Procurement League's social media channels to maximize visibility and engagement.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel4"}
                                    onChange={handleChange("panel4")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Results</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <ul className="!p-0 !ml-10">
                                                <li className="text-new-color text-lg Poppins font-normal">Over three months, the Global Sourcing Association experienced a 30% increase in website traffic attributed to the Procurement League's promotional efforts.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">The collaborative content created by Procurement League received high engagement rates on social media, including likes, shares, and comments, indicating a positive response from the target audience.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">Global Sourcing Association witnessed a significant improvement in brand visibility and recognition within the sourcing & procurement community, resulting in increased inquiries and leads.</li>
                                            </ul>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel5"}
                                    onChange={handleChange("panel5")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel5bh-content"
                                        id="panel5bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Conclusion</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <p className="text-new-color text-lg Poppins font-normal">Through an exclusive partnership with the Procurement League, the Global Sourcing Association successfully elevated its influence and engagement within the sourcing industry. By leveraging Procurement League's expertise in cross-promotion and content creation, they achieved their objectives of enhancing brand visibility, driving website traffic, and positioning themselves as a thought leader. This collaboration underscores Procurement League's commitment to delivering impactful marketing solutions tailored to the unique needs of its clients.</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="mt-10">
                            <h1 className="Poppins success-new font-semibold text-2xl text-center mt-4 pb-2">
                                Case Study2: Custom Research Reports
                            </h1>
                            <div className="flex flex-col gap-4 mt-5 bg-white custom-shadow p-5 rounded-3xl">
                                <p className="Poppins text-lg font-normal text-[#16283A]">Case Studies</p>
                                <Accordion
                                    expanded={expanded === "panel6"}
                                    onChange={handleChange("panel6")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel6bh-content"
                                        id="panel6bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Background</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4">
                                            <p className="text-new-color text-lg Poppins font-normal">A leading procurement company recognized the growing importance of digital transformation within the industry and sought to deepen its understanding of digital procurement trends and strategies. Procurement League conducted comprehensive research on digital procurement, utilizing surveys, one-on-one interviews, and desktop research to deliver actionable insights and recommendations.</p>
                                            <img src={casestudy2} alt="casestudy2" />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel7"}
                                    onChange={handleChange("panel7")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel7bh-content"
                                        id="panel7bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Objectives</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4">
                                            <ul className="!p-0 !ml-10">
                                                <li className="text-new-color text-lg Poppins font-normal">Explore the evolving landscape of digital procurement and its impact on traditional procurement practices.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">Identify key technologies, trends, industry leaders' insights on software adoption and best practices driving digital procurement adoption.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">Provide practical recommendations to help the client leverage digital tools and strategies to optimize procurement processes and outcomes.</li>
                                            </ul>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel8"}
                                    onChange={handleChange("panel8")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel8bh-content"
                                        id="panel8bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Our Approach</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4">
                                            <p className="text-new-color text-lg Poppins font-bold">Procurement League employed a structured approach to research on digital procurement:</p>
                                            <p className="text-new-color text-lg Poppins font-normal">
                                                <strong className="text-new-color text-lg Poppins font-bold">1. Surveys:</strong> Conducted surveys among procurement professionals to gather quantitative data on digital procurement adoption, challenges, and benefits.
                                            </p>
                                            <p className="text-new-color text-lg Poppins font-normal">
                                                <strong className="text-new-color text-lg Poppins font-bold">2. One-on-One Interviews:</strong> Engaged in one-on-one interviews with industry experts and technology vendors to gain qualitative insights into emerging trends and innovative solutions in digital procurement.
                                            </p>
                                            <p className="text-new-color text-lg Poppins font-normal">
                                                <strong className="text-new-color text-lg Poppins font-bold">2. Desktop Research:</strong> Conducted extensive desktop research to supplement primary data collection, analyzing industry reports, case studies, and academic literature to validate findings and identify best practices.
                                            </p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel9"}
                                    onChange={handleChange("panel9")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel9bh-content"
                                        id="panel9bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Results</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4">
                                            <ul className="!p-0 !ml-10">
                                                <li className="text-new-color text-lg Poppins font-normal">Procurement League's research on digital procurement revealed insights into the rapid adoption of digital technologies such as AI, blockchain, and robotic process automation within procurement processes.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">The custom research reports provided by Procurement League highlight the strategic approaches, emphasizing the importance of a top-down strategy for successful AI integration, data privacy concerns to the intricacies of real-time analysis, each challenge mirrors the complexities of managing a diverse and interconnected ecosystem and much more.</li>
                                                <li className="text-new-color text-lg Poppins font-normal">The client utilized the research findings to inform its digital transformation roadmap, implementing innovative solutions and practices to streamline procurement operations and drive value for the organization.</li>
                                            </ul>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel10"}
                                    onChange={handleChange("panel10")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel10bh-content"
                                        id="panel10bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Conclusion</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4">
                                            <p className="text-new-color text-lg Poppins font-normal">Through in-depth research on digital procurement, Procurement League empowered the leading procurement company to navigate the complexities of digital transformation and capitalize on emerging opportunities. The custom research reports delivered actionable insights and recommendations, enabling the client to optimize procurement processes, enhance strategic decision-making, and maintain a competitive edge in today's digital landscape.</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === "panel11"}
                                    onChange={handleChange("panel11")}
                                    className="p-0 overflow-hidden bg-transparent important:shadow--none rounded--xl">
                                    <AccordionSummary
                                        expandIcon={<BsPlusLg className="ml-5 success-new" size={28} />}
                                        aria-controls="panel11bh-content"
                                        id="panel11bh-header">
                                        <p className="text-[#3B455A] text-lg Poppins font-semibold">Testimonials from client</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="flex flex-col gap-4 pt-5">
                                            <p className="text-new-color text-lg Poppins font-normal">Our partnership with the Procurement League has exceeded expectations. Their agility, responsiveness, innovative approach, and, most importantly, their expertise transformed our initiative and propelled us across the finish line. As a result, we delivered a competitive, dynamic product in our marketplace</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-28">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-[64px] gap-y-5" id="community">
                        <div className="lg:col-span-2">
                            <h1 className="Poppins font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                                Community Technology Services
                            </h1>
                            <div className="mt-5">
                                <p className="font-normal text-lg Poppins text-new-color">
                                    Navigate the digital landscape with powerful community-building tools. Our cutting-edge technology empowers your brand to create meaningful connections.
                                </p>
                            </div>
                            <div className="grid mt-5 grid-cols-2 gap-5">
                                <div>
                                    <h1 className="Poppins mb-5 font-light primary text-3xl">
                                    Product Marketing
                                    </h1>
                                    <p className="Poppins font-normal text-new-color text-lg">
                                        Collaborate with us to showcase your
                                        products through impactful launches and
                                        promotions, capturing your audience's
                                        attention.
                                    </p>
                                </div>
                                <div>
                                    <h1 className="Poppins mb-5 font-light primary text-3xl">
                                        White Labeled - SaaS Product
                                    </h1>
                                    <p className="Poppins font-normal text-new-color text-lg">
                                        Leverage our white-label SaaS product to
                                        enhance your brand identity and
                                        streamline user experiences.
                                    </p>
                                </div>
                            </div>
                            <div className="grid mt-5 grid-cols-2 gap-5">
                                <div>
                                    <h1 className="Poppins mb-5 font-light primary text-3xl">
                                    Key Benefits
                                    </h1>
                                    <ul>
                                        <li className="Poppins font-normal text-new-color text-lg">
                                            Watch your community grow exponentially with our proven strategies
                                        </li>
                                        <li className="Poppins font-normal text-new-color text-lg">
                                            Transform passive followers into active
                                        </li>
                                        <li className="Poppins font-normal text-new-color text-lg">
                                            Streamline your marketing efforts
                                        </li>
                                        <li className="Poppins font-normal text-new-color text-lg">
                                            See tangible results
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="h-full">
                            <img
                                src={business_three}
                                alt="business_three"
                                className="h-full object-cover"
                            />
                        </div>
                    </div>
                    <div className="mt-28">
                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                            <div className="rounded-3xl bg-white p-5 custom-shadow flex justify-center items-center flex-col gap-4">
                                <h1 className="lg:text-[100px] text-[70px] Poppins font-semibold text-new-color">
                                    80<sup className="!-top-[0.2em]">%</sup>
                                </h1>
                                <div className="flex flex-col justify-start items-center flex-grow">
                                    <p className="text-3xl text-new-color Poppins font-light">
                                        increase
                                    </p>
                                    <p className="text-lg text-center text-new-color Poppins font-light">
                                        in key performance indicators (KPIs)
                                    </p>
                                </div>
                            </div>
                            <div className="rounded-3xl bg-white p-5 custom-shadow flex justify-center items-center flex-col gap-4">
                                <h1 className="lg:text-[100px] text-[70px] Poppins font-semibold text-new-color">
                                    10x
                                </h1>
                                <div className="flex flex-col justify-start items-center flex-grow">
                                    <p className="text-3xl text-new-color Poppins font-light">
                                        increase
                                    </p>
                                    <p className="text-lg text-center text-new-color Poppins font-light">
                                        in high-quality content output
                                    </p>
                                </div>
                            </div>
                            <div className="rounded-3xl bg-white p-5 custom-shadow flex justify-center items-center flex-col gap-4">
                                <h1 className="lg:text-[100px] text-[70px] Poppins font-semibold text-new-color">
                                    5x
                                </h1>
                                <div className="flex flex-col justify-start items-center flex-grow">
                                    <p className="text-3xl text-new-color Poppins font-light">
                                        increase
                                    </p>
                                    <p className="text-lg text-center text-new-color Poppins font-light">
                                        in online reach
                                    </p>
                                </div>
                            </div>
                            <div className="rounded-3xl bg-white p-5 custom-shadow flex justify-center items-center flex-col gap-4">
                                <h1 className="lg:text-[100px] text-[70px] Poppins font-semibold text-new-color">
                                    7x
                                </h1>
                                <div className="flex flex-col justify-start items-center flex-grow">
                                    <p className="text-3xl text-new-color Poppins font-light">
                                        boost
                                    </p>
                                    <p className="text-lg text-center text-new-color Poppins font-light">
                                        in brand awareness
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-10 mt-20 bg--lightBlue">
                    <h1 className="text-center lg:text-[50px] font-light text-new-color lg:leading-[60px] text-4xl Poppins pb-3">
                        Procurement League’s Potential
                    </h1>
                    <div className="container relative">
                        <div className="absolute -left-32 top-1/3 lg:block hidden">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="258"
                                height="258"
                                viewBox="0 0 258 258"
                                fill="none"
                            >
                                <path
                                    d="M258 129C258 200.245 200.245 258 129 258C57.7553 258 0 200.245 0 129C0 57.7553 57.7553 0 129 0C200.245 0 258 57.7553 258 129Z"
                                    fill="white"
                                    fillOpacity="0.5"
                                />
                            </svg>
                        </div>
                        <div className="absolute right-0 top-1/4 lg:block hidden">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="197"
                                height="197"
                                viewBox="0 0 197 197"
                                fill="none"
                            >
                                <path
                                    d="M82.1865 21.3941L77.6539 73.6878L25.3602 69.1551C12.2868 68.0219 1.63191 76.9771 0.498744 90.0505C-0.634422 103.124 8.32076 113.779 21.3942 114.912L73.6878 119.445L69.1551 171.738C68.022 184.812 76.9771 195.467 90.0506 196.6C103.124 197.733 113.779 188.778 114.912 175.704L119.445 123.411L171.738 127.943C184.812 129.077 195.467 120.121 196.6 107.048C197.733 93.9746 188.778 83.3197 175.704 82.1865L123.411 77.6538L127.943 25.3602C129.077 12.2868 120.121 1.63187 107.048 0.498705C93.9746 -0.634461 83.3197 8.32073 82.1865 21.3941Z"
                                    fill="#B5E2FF"
                                    fillOpacity="0.4"
                                />
                            </svg>
                        </div>
                        <div className="lg:w-1/3 lg:h-1/4 md:w-1/2 md:h-1/2 mx-auto pt-5">
                            <img
                                src={contactgroup}
                                alt="contactgroup"
                                className="w-full h-full"
                            />
                        </div>
                        <div className="flex lg:flex-row md:flex-row flex-col gap-10 justify-center mt-10">
                            <div className="text-center">
                                <h6 className="pb-3 font-light Poppins text-3xl text-new-color">
                                    Without Us
                                </h6>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Missed Growth Potential
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Limited Reach
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Untapped Markets
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Reduced ROI
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Business Stagnation
                                </p>
                            </div>
                            <div className="text-center">
                                <h6 className="pb-3 font-light Poppins text-3xl text-new-color">
                                    With Us
                                </h6>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Accelerated Growth
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Effective Marketing Strategies
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Increased ROI
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Innovative Solutions
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Resource Efficiency
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Enhanced Brand Visibility
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Market Dominance
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Revenue Maximization
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Simplified Success
                                </p>
                                <p className="pb-3 font-normal Poppins text-lg text-new-color">
                                    Reliable Support
                                </p>
                            </div>
                        </div>

                        <IndustrySliderB2B />
                        <ContactUsCard type={"b2b"} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndustriesSolutionsB2BPage;
