import { Blogskeleton, Buttonlink, Chooseskills, IsLoader, Noconnection, Nocontent, } from "components";
import SeeAllClasses from "components/classes/procure/seeAllClasses";
import React, { Component, Fragment } from "react";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward, IoMdClose } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import "./styles.css";
import { Query } from "@apollo/client/react/components";
import GET_CLASSES from "../../../graphql/queries/getClasses";
import GET_COURSE_CATEGORIES from "graphql/queries/getCourseCategories";
import { Menu, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import { extractValidationErrors } from "helpers/extractValidationErrors";


class AllClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      categories: [],
      selectedCategories: [],
      selectedId : null
    };
    this.timeoutValue = 0;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onCategoryChange = (selectedCategories, unselectedCategories) => {
    // Clone the current state of selectedCategories
    const updatedSelectedCategories = [...this.state.selectedCategories];

    // Remove the unselectedCategories from the updatedSelectedCategories
    unselectedCategories.forEach((category) => {
      const categoryIndex = updatedSelectedCategories.findIndex((item) => item.id === category.id);
      if (categoryIndex !== -1) {
        updatedSelectedCategories.splice(categoryIndex, 1);
      }
    });

    // Filter out any duplicate selectedCategories from the selectedCategories and updatedSelectedCategories
    const filteredSelectedCategories = selectedCategories.filter(
      (category) => !updatedSelectedCategories.some((item) => item.id === category.id)
    );

    this.setState({
      selectedCategories: [...updatedSelectedCategories, ...filteredSelectedCategories],
    });

    // Do something with the unselectedCategories if needed.
  };

  renderCategories = () => {
    return (
      <Query query={GET_COURSE_CATEGORIES}>
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading) {
            return (
              <div className="my-10">
                <IsLoader className="w-32 h-32 mx-auto" />
              </div>
            );
          }
          if (error) {
            extractValidationErrors(error);
            return (
              <div>
                <Noconnection />
              </div>
            );
          }
          if (data !== undefined) {
            return data.course_categories.map((category) => (
              <div
                className="relative px-3 cursor-pointer hover:bg-white "
                key={category.id}>
                <div className="flex items-center justify-between py-2.5 px-1" onClick={()=> this.setState({selectedId : category.id})}>
                  <p className="black text-nowrap w-full truncate rounded-xl relative">
                    {category.name}
                  </p>
                  <IoIosArrowForward />
                </div>
                {
                  this.state.selectedId === category.id && 
                  <div className="absolute top-0 z-50 w-full p-3 bg-white border shadow-lg lg:left-full left-0 rounded-xl">
                    <div className="flex justify-end items-end">
                        <IoMdClose size={22} color="black" className="cursor-pointer" onClick={()=> this.setState({selectedId : null})} />
                    </div>
                    <Chooseskills
                      propsCategories={this.state.selectedCategories}
                      onCategoryChange={this.onCategoryChange}
                      categoryItems={category.items}
                      parent={category}
                    />
                  </div>
                }
              </div>
            ));
          }
        }}
      </Query>
    );
  };

  renderAllClasses = (type) => {
    let variables = {};
    variables.typeInfo = type.toLowerCase();
    if (this.state.searchText !== "") {
      variables.search = this.state.searchText;
    }
    if (this.state.selectedCategories.length !== 0) {
      const idList = this.state.selectedCategories.flatMap((item) => {
        // Check if the item has the "items" property
        if (item.items && Array.isArray(item.items)) {
          // Map over the nested items and extract the id values
          return item.items.map((nestedItem) => nestedItem.id);
        }
        // Return the id of the current item
        return item.id;
      });

      variables.categories = [...new Set(idList)];
    }
    // console.log("type",this.props.type)
    // console.log("selectedCategories",this.state.selectedCategories)
    return (
      <Query query={GET_CLASSES} variables={variables}>
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading) {
            return (
              <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4">
                <Blogskeleton />
                <Blogskeleton />
                <Blogskeleton />
              </div>
            );
          }
          if (error)
            return (
              <div>
                <Noconnection />
              </div>
            );

          if (!data || data.courses.data.length < 1) {
            return <Nocontent />;
          }
          if (data !== undefined) {
            return (
              <div>

                <SeeAllClasses
                  classes={Array.from(new Set(data.courses.data))}
                  type={type}
                  onLoadMore={() =>
                    fetchMore({
                      variables: {
                        cursor: data.courses.paginatorInfo.currentPage + 1,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        const newOffers = fetchMoreResult.courses.data;
                        const pageInfo = fetchMoreResult.courses.paginatorInfo;
                        if (data.courses.paginatorInfo.total !== pageInfo.total) {
                          refetch();
                        }
                        return newOffers.length
                          ? {
                            courses: {
                              __typename: previousResult.courses.__typename,
                              data: [
                                ...previousResult.courses.data,
                                ...newOffers,
                              ],
                              paginatorInfo: pageInfo,
                            },
                          }
                          : previousResult;
                      },
                    })
                  }
                />
              </div>
            );
          }
        }}
      </Query>
    );
  };

  render() {

    const { type, histroy } = this.props;
    // console.log("selectedCategories", this.state.selectedCategories)
    return (
      <div className="container">
        {histroy && (
          <Buttonlink
            Text="back"
            Icon={<IoIosArrowBack />}
            onClick={() => {
              histroy.goBack();

            }}
            className="flex items-center justify-center px-4 mb-3 rounded-full bg--lightGray Regular h-9 darkGray w-28 hover:darkGray hover:opacity-80"
          />
        )}
        <div className="grid gap-4 grid-cols-12">
          <div className="flex items-center lg:col-span-5 col-span-12 w-full bg-gray-100 rounded-xl">
            <Menu as="div" className="relative w-full">
              <Menu.Button onClick={()=> this.setState({selectedId : null})} className="flex items-center justify-between w-full black text-nowrap">
                <p className="black w-full whitespace-normal flex items-center justify-between py-2.5 px-3">
                  {this.state.selectedCategories.length !== 0
                    ? this.state.selectedCategories.map((c) => c.name + ",")
                    : "Select Categories"}
                  {/*Resources*/}
                  <IoIosArrowDown />
                </p>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute left-0 z-50 w-full py-3 mt-2 origin-top-right bg-gray-100 divide-y divide-gray-100 rounded-xl shadow--sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {/* <div className="hidden md:block"> */}
                  {this.renderCategories()}
                  {/* </div> */}
                  {/* <div className="block md:hidden">
                      <Mobilecategories />
                    </div> */}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          {this.state.selectedCategories.length !== 0 &&
            <div className="lg:col-span-1 md:col-span-6 col-span-12">
              <Buttonlink
                Text=""
                Icon={<HiOutlineX size={25} />}
                onClick={() => {
                  this.setState({ selectedCategories: [] })

                }}
                className="flex items-center justify-center px-4 h-14 w-full rounded-xl bg--lightGray Regular darkGray hover:darkGray hover:opacity-80"
              />
            </div>
          }
          <div className="flex items-center h-max lg:col-span-6 col-span-12">
            <div className="flex items-center w-full h-max px-3 bg-gray-100 rounded-xl">
              <input
                placeholder="Search..."
                className="flex-grow w-full bg-gray-100 border-0 h-14 Regular rounded-xl"
                // value={this.state.searchText}
                onChange={(e) => {
                  clearTimeout(this.timeoutValue);
                  this.timeoutValue = setTimeout(() => {
                    this.setState({ searchText: e.target.value });
                  }, 2000);
                }}
              />
              <GoSearch size={22} className="gray" />
            </div>
          </div>
        </div>
        <div>
          <div className="mt-20">
            <div>
              <h4 className="mb-4 text-2xl capitalize Medium">
                {type === ""
                  ? "Events"
                  : type === "item_based"
                    ? "Products"
                    : type === "expired"
                      ? "Past Events"
                      : type === "upcoming" ? "Upcoming Events" : `${type} Classes`}
              </h4>
            </div>
            {this.renderAllClasses(type)}
          </div>
        </div>
      </div>
    );
  }
}

export default AllClasses;
