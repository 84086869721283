import React from "react";
import Slider from "react-slick";
import sliderPhoto from "../../../assets/images/pricing/men.png";

const EventSubscribers = ({ isImageHide }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true
    };
    const testimonials = [
        {
            text: "Thank you so much for being a sponsor of Optimal. It was a huge success and we are thrilled with the outcome. The procurement community heard from Best of Breed startups, thought leaders, innovators, and customers using AI in procurement.",
            author: "Digital Marketing Manager, E-Sourcing Company",
        },
        {
            text: "Our partnership with the Procurement League has exceeded expectations. Their agility, responsiveness, innovative approach, and, most importantly, their expertise transformed our initiative and propelled us across the finish line. <br /> As a result, we delivered a competitive, dynamic product in our marketplace, helping us build awareness and credibility. I cannot wait for the next initiative to start so we can work together again and benefit from their hard work, analysis, insight, and experience.",
            author: "Vice President - Global Sourcing Association",
        },
        {
            text: "Was great to work with Procurement League in 2021 and the wider procurement network. I’m grateful to have led the Development League with Rajiv Gupta and Hamza Kamal and for the opportunity to mentor Helen Melian.",
            author: "Leading Procurement Practitioner",
        },
    ];
    return (
        <div className="flex justify-center container items-center flex-col h-full gap-20">
            <h1 className="lg:text-5xl text-4xl font-light text-new-color text-center Poppins">
                What Procurement Leaguers Have To Say
            </h1>

            <div
                className={`rounded-3xl bg-white w-[90%] relative sm:w-[80%] md:w-[90%] lg:w-full ${!isImageHide ? "lg:px-20" : "lg:px-0"
                    } md:px-10 shadow-lg px-5 max-w-4xl mx-auto home_slider`}
            >
                {!isImageHide && (
                    <>
                        <div className="absolute -top-14 md:max-[840px]:!-left-24 -left-28 w-48 h-[305px] lg:block md:block sm:block hidden">
                            <img
                                src={sliderPhoto}
                                alt="sliderPhoto"
                                className="w-full h-full"
                            />
                        </div>
                        <div className="absolute -top-24 w-16 h-28 lg:hidden md:hidden sm:hidden block">
                            <img
                                src={sliderPhoto}
                                alt="sliderPhoto"
                                className="w-full h-full"
                            />
                        </div>
                    </>
                )}
                <div className="absolute w-[72px] h-[54px] inset-x-0 mx-auto -top-10">
                    <svg
                        width="72"
                        height="54"
                        viewBox="0 0 72 54"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                            fill="#FF6600"
                        />
                    </svg>
                </div>
                <Slider {...settings} className="">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="p-2 md:flex !block">
                            <div className="p-4 md:p-10 md:flex-1 md:mx-2">
                                <div className="Poppins">
                                    <p className="lg:text-lg md:text-lg sm:text-lg text-base Poppins text-new-color font-normal" dangerouslySetInnerHTML={{__html:testimonial.text}}></p>
                                    <p className="block mt-2 md:mt-4 lg:text-lg md:text-lg sm:text-sm text-base Poppins text-new-color font-bold">
                                        {testimonial.author}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default EventSubscribers;
