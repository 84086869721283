import { gql } from "@apollo/client";

export default gql`
  mutation login($email: String!, $password: String!, $userInformation:[Object]) {
    login(email: $email, password: $password , userInformation:$userInformation) {
      token
      error
      message
    }
  }
`;
