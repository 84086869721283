import jwt from 'jsonwebtoken';
import cookie from 'js-cookie';
import { AUTH_USER, GRAPH_USER_AUTH_TOKEN } from './config/constants';
import client from 'graphql/client';

export default class Auth {
  isAuthenticated = () => {
    let token = cookie.get(GRAPH_USER_AUTH_TOKEN);
    let auth = false;
    if (token) {
      auth = true;
    }
    else {
      cookie.remove(GRAPH_USER_AUTH_TOKEN);
      cookie.remove(AUTH_USER);
      cookie.remove("AUTH_TOKEN_EXPIRY");
      localStorage.removeItem(AUTH_USER);
      // client.clearStore();
      return auth
    }

    // if (token) {
    //   try {
    //     // Decode the token without verification to get the payload
    //     const decodedToken = jwt.decode(token);

    //     // Check if the token is not expired
    //     if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
    //       auth = true;
    //       debugger;
    //     }
    //     else {
    //       debugger;
    //       cookie.remove(GRAPH_USER_AUTH_TOKEN);
    //       cookie.remove(AUTH_USER);
    //       localStorage.removeItem(AUTH_USER);
    //       client.clearStore();
    //       return auth
    //     }
    //   } catch (error) {
    //     // Handle decoding errors
    //     console.error('Error decoding token:', error);
    //   }
    // }

    return auth;
  };
}
