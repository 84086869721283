import React, { useEffect } from "react";
import Profilesidebar from "../../../components/profile/profilesidebar";
import { seo } from "helpers/seo";
import { connect } from "react-redux";
import { IsLoader, Nocontent } from "../../../components";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";

const MY_QUERY = gql`
  query MyQuery($orderDirection: SortOrder!) {
    me {
      id
      username
      firstname
      lastname
      profile_photo
      tagline
      email
      userLoginHistories(
        first: 14
        orderBy: [{ column: "created_at", order: $orderDirection }]
      ) {
        data {
          id
          ip_address
          user_browser
          operating_system
          created_at
        }
      }
    }
  }
`;

function UserLoginHistory() {
  useEffect(() => {
    seo({
      title: "Login History | Procurement League ",
    });
  }, []);

  const { loading, error, data } = useQuery(MY_QUERY, {
    variables: {
      orderDirection: "DESC", // Provide the order direction variable value here
    },
  });

  if (loading)
    return (
      <div className="my-10">
        <IsLoader className="w-32 h-32 mx-auto" />
      </div>
    );

  if (error) return <p>Error: {error.message}</p>;

  // Access your data and render your component based on the query result
  const { me } = data;

  console.log("me", me);

  return (
    <div className="container">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-7">
        <Profilesidebar authUser={me} />
        <div className="lg:col-span-5">
          <div className="p-3 mb-3 border rounded-xl lg:p-8">
            <div className="flex flex-col">
              <div
                className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                id="scrollbar"
              >
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap"
                          >
                            IP Address
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap"
                          >
                            Operating System
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap"
                          >
                            Browser
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap"
                          >
                            Created at
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {me?.userLoginHistories?.data.map((person) => (
                          <tr key={person.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {person.ip_address}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {person.operating_system}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {person.user_browser}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                {moment(person.created_at).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLoginHistory;
