import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { RiDoubleQuotesR } from "react-icons/ri";
import JuliaLaMarra from "assets/images/pricing/testimonial/JuliaLaMarra.webp";
import HelenMelian from "assets/images/pricing/testimonial/HelenMelian.webp";
import BrigetteEsparza from "assets/images/Brigette.webp";
import HariharanLaxminarayan from "assets/images/pricing/testimonial/HariharanLaxminarayan.webp";
import ZoranKecman from "assets/images/pricing/testimonial/ZoranKecman.webp";
import VRShankar from "assets/images/VRShankar.webp";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer -top-16 slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 -top-16 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class pricingtestimonials extends Component {
  render() {
    const testimonials = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          testimonials: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          testimonials: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          testimonials: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts clients-testimonials">
        <Slider {...testimonials} className="ml-0 important:static">
          <div>
            <div className="relative flex w-full p-4 bg-gray-200 rounded-xl ">
              <div className="hidden md:flex">
                <RiDoubleQuotesR size={22} className="darkGray" />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-300 rounded-full h-18 md:flex" />
              <div>
                <p>
                  I have developed many skills through my internship with
                  Procurement League and have a much greater concept of what I
                  was to expect after graduation. My internship has given me a
                  greater understanding of what it is I have learned in the
                  classroom and given the opportunity to apply it to real
                  solutions. One of the greatest benefits that I have received
                  is the knowledge of social media and building a brand.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    alt="JuliaLaMarra"
                    src={JuliaLaMarra}
                    className="object-cover w-12 h-12 rounded-full md:h-20 md:w-20"
                  />
                  <p className="ml-3 Medium md:text-xl text-md">
                    Julia LaMarra, USA
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="relative flex w-full p-4 bg-gray-200 rounded-xl ">
              <div className="hidden md:flex">
                <RiDoubleQuotesR size={22} className="darkGray" />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 md:flex" />
              <div>
                <p>
                  It was a pleasure to be a part of the Procurement League
                  program and to have Joanna Gosling as my mentor. She shared
                  her experience and expertise to help up and coming procurement
                  professionals. Learned many great lessons from you. Thank you
                  for the wonderful opportunity and ongoing support.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    alt="HelenMelian"
                    src={HelenMelian}
                    className="object-cover w-12 h-12 rounded-full md:h-20 md:w-20"
                  />
                  <p className="ml-3 text-lg Medium">Helen Melian, USA</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="relative flex w-full p-4 bg-gray-200 rounded-xl ">
              <div className="hidden md:flex">
                <RiDoubleQuotesR size={22} className="darkGray" />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 md:flex" />
              <div>
                <p>
                  Procurement League encourage me to learn more about the supply
                  chain, the classes they have are of excellent quality. The
                  professionals giving the classes are open to questions after
                  the class which makes them very dynamic. I also had the
                  opportunity to work in the company and this contributed to
                  develop my career and helped me to connect with other
                  professionals around the world.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    alt="Brigette Esparza"
                    src={BrigetteEsparza}
                    className="object-cover w-12 h-12 rounded-full md:h-20 md:w-20"
                  />
                  <p className="ml-3 Medium md:text-xl text-md">
                    Brigette Esparza, Ireland
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="relative flex w-full p-4 bg-gray-200 rounded-xl ">
              <div className="hidden md:flex">
                <RiDoubleQuotesR size={22} className="darkGray" />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 md:flex" />
              <div>
                <p>
                  It's always a pleasure to be talking for the Procurement
                  League. It is a very interesting, engaging, enlightening, and
                  professional platform. Here the procurement professionals can
                  raise the bar of thoughts, their experiences, their exposures
                  their learnings by asking and answering questions, sharing
                  best practices, and learning opportunities from around the
                  world in different working environments in various business
                  sectors. That's what makes Procurement League a better, unique
                  platform.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    alt="Hariharan Laxminarayan"
                    src={HariharanLaxminarayan}
                    className="object-cover w-12 h-12 rounded-full md:h-20 md:w-20"
                  />
                  <p className="ml-3 Medium md:text-xl text-md">
                    Hariharan Laxminarayan, UAE
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="relative flex w-full p-4 bg-gray-200 rounded-xl ">
              <div className="hidden md:flex">
                <RiDoubleQuotesR size={22} className="darkGray" />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 md:flex" />
              <div>
                <p>
                  Even after you left for another half an hour and discussed all
                  the things that we learnt during this On-Demand class it shows
                  us two things, first how little procurement teams know about
                  finance and how finance works. Secondly it also came clear to
                  us how procurement decisions affect finance and income
                  statement and the balance sheet you opened. believe that our
                  thinking about what we do and how we do will change
                  significantly after this On-Demand Session. I really love that
                  I would love to see more of similar classes where we go into
                  details and get more benefits from having finance and
                  procurement working really as one.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    alt="Zoran Kecman"
                    src={ZoranKecman}
                    className="object-cover w-12 h-12 rounded-full md:h-20 md:w-20"
                  />
                  <p className="ml-3 Medium md:text-xl text-md">
                    Zoran Kecman, UAE
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="relative flex w-full p-4 bg-gray-200 rounded-xl ">
              <div className="hidden md:flex">
                <RiDoubleQuotesR size={22} className="darkGray" />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 md:flex" />
              <div>
                <p>
                  Always available, extremely knowledgeable, and a tremendous
                  "can-do" attitude. Our company was on a tight timeline for an
                  E-Sourcing project and Procurement League was the perfect
                  partner. The support was invaluable which easily exceeded my
                  expectations... the entire company was thrilled with it!
                  Without any hesitation... highly recommended.
                </p>
                <div className="flex items-center mt-3">
                  <img
                    alt="V.R. Shankar"
                    src={VRShankar}
                    className="object-cover w-12 h-12 rounded-full md:h-20 md:w-20"
                  />
                  <p className="ml-3 Medium md:text-xl text-md">
                    V.R. Shankar, India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default pricingtestimonials;
