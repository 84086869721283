import { Buttonlink, IsLoader } from "components";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import Profilesidebar from "../../components/profile/profilesidebar";
import "./styles.css";
import { connect } from "react-redux";
import { Alert } from "@mui/material";
import client from "../../graphql/client";
import sendDeactivateEmail from "../../graphql/queries/sendDeactivateEmail";
import { gql } from "@apollo/client";
import cookie from "js-cookie";
import { AUTH_USER, GRAPH_USER_AUTH_TOKEN } from "../../config/constants";
import { toast } from "react-toastify";

function DeactivateAccount(props) {
  const { authUser } = props;
  const [code, setCode] = useState("");
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (code.length === 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [code]);

  const sendcode = () => {
    setDisable(true);
    client
      .query({
        query: gql`
          query ($otp: String) {
            confirm_deactivation(otp: $otp) {
              status
              message
            }
          }
        `,
        variables: {
          otp: code,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res.data.confirm_deactivation.status) {
          cookie.remove(GRAPH_USER_AUTH_TOKEN);
          cookie.remove(AUTH_USER);
          cookie.remove("AUTH_TOKEN_EXPIRY");
          localStorage.removeItem(AUTH_USER);
          client.clearStore();
          window.location.href = "/";
        } else {
          setCode("");
          setDisable(false);
          toast.error(res.data.confirm_deactivation.message)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container">
      <div className="grid gap-4 lg:grid-cols-7">
        <Profilesidebar authUser={authUser} />
        <div className="lg:col-span-5">
          <div className="p-8 border rounded-xl">
            <div className="gap-4 md:grid md:grid-cols-6">
              <div className="space-y-10 md:col-start-2 md:col-span-4">
                <div className="report-box">
                  <div className="p-5 border intro-y box">
                    <h4 className="text-2xl text-center text-red-500 Medium">
                      Account Deactivation
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="gap-4 md:grid md:grid-cols-6">
              <div className="space-y-10 md:col-start-2 md:col-span-4">
                <div className="report-box">
                  <div className="p-10 space-y-5 border intro-y box">
                    <div className="gap-4 lg:grid lg:grid-cols-6">
                      <div className="space-y-5 lg:col-start-2 lg:col-span-4">
                        <div className="intro-y">
                          <label htmlFor="password" className="Regular">
                            Enter Code
                          </label>
                          <ReactCodeInput
                            type="text"
                            fields={6}
                            inputMode={"full-width-latin"}
                            value={code}
                            onChange={(e) => setCode(e)}
                          />
                        </div>
                        <div className="intro-y">
                          <Buttonlink
                            disabled={disable}
                            onClick={sendcode}
                            className={`${disable ? "bg--gray" : "bg--primary"
                              } Regular text-white hover:text-white hover:opacity-80 flex-1 grow w-full flex items-center justify-center h-11 rounded-xl`}
                            Text={"Submit code"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <DeactivateAccountEmail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DeactivateAccountEmail(props) {
  const [request, setRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmail = () => {
    setLoading(true);
    client
      .query({
        query: sendDeactivateEmail,
      })
      .then((res) => {
        // console.log(res);
        if (res.data.deactivate.status) {
          setRequest(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="intro-y">
      {!request ? (
        loading ? (
          <IsLoader className="w-32 h-32 mx-auto" />
        ) : (
          <Buttonlink
            onClick={sendEmail}
            className="flex items-center justify-center flex-1 w-full text-white bg--primary Regular hover:text-white hover:opacity-80 grow h-11 rounded-xl"
            Text={"Send Me The Code"}
          />
        )
      ) : (
        <Alert severity="success">
          Account deactivation code is send to your registered email.
        </Alert>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(DeactivateAccount);
