import { Buttonlink, Getintouch } from "components";
import { seo } from "helpers/seo";
import React, { Component } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Outsourcing from "assets/images/Outsourcing.webp";

class DigitalProcurement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Digital Procurement | Procurement League",
    });
  }

  render() {
    return (
      <div className="container">
        <div className="relative flex flex-col justify-between lg:items-center lg:justify-center lg:flex-row lg:-mt-4">
          <Buttonlink
            Text="back"
            Icon={<IoIosArrowBack />}
            onClick={() => this.props.history.goBack()}
            className="left-0 z-10 flex items-center justify-center px-4 border-0 shadow-none lg:absolute bg--lightGray hover:bg--lightGray focus:bg--lightGray Regular h-9 rounded--full darkGray w-28 hover:darkGray hover:opacity-80"
          />
          <h1 className="my-3 text-4xl text-center Medium lg:my-0">
            Digital Procurement
          </h1>
          <Buttonlink
            Text="Happy Subscribers"
            Lasticon={<IoIosArrowForward />}
            to="/happy-subscribers"
            className="right-0 flex items-center justify-center border-0 shadow-none lg:absolute fs-15 primary hover:primary Regular rounded--xl "
          />
        </div>
        <div className="my-10">
          <img
            src={Outsourcing}
            alt="Digital Procurement"
            className="object-cover m-auto h-72"
          />
        </div>
        <div className="lg:grid lg:grid-cols-6 lg:gap-4">
          <div className="lg:col-start-2 lg:col-span-4">
            <p className="text-center Regular">
              We are promising you the World of Technology, Automation and AI
              will make it to your inboxes.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center mt-5">
          <Getintouch header={"want a digital procurement updates"} />
        </div>
      </div>
    );
  }
}

export default DigitalProcurement;
