import React, { useEffect } from "react";
import Hireexpert from "../../assets/images/Hireexpert.webp";
import { Buttonlink, Knowledgepocket } from "components";
import mask_one from "../../assets/images/SuccessStories/JamesBW.png";
import Mask_two from "../../assets/images/team/KaurBW.png";
import Mask_three from "../../assets/images/team/Hamza.webp";
import Amrish from "../../assets/images/HireExpert/AmrishBW.png";
import { BsArrowRight } from "react-icons/bs";

const HireExpertPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const data = {
        heading: "Hire An Expert", 
        text: "Our experts are here to guide you every step of the way, ensuring your procurement processes are smooth, strategic, and successful.",
        link: `${process.env.REACT_APP_WEB_URL}/submit-form`,
        btn: "Submit Form"
    }
    
    return (
        <div className="bg--lightBlue pt-10 -mt-[50px]">
            <div className="container relative">
                <div className="absolute top-[10%] lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="258"
                        height="258"
                        viewBox="0 0 258 258"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_742_2919)">
                            <path
                                d="M64.3955 94.4469L98.7398 128.791L64.3955 163.135C55.8094 171.722 55.8094 184.601 64.3955 193.187C72.9815 201.773 85.8607 201.773 94.4467 193.187L128.791 158.842L163.135 193.187C171.721 201.773 184.6 201.773 193.187 193.187C201.773 184.601 201.773 171.722 193.187 163.135L158.842 128.791L193.187 94.4469C201.773 85.8608 201.773 72.9817 193.187 64.3957C184.6 55.8096 171.721 55.8096 163.135 64.3957L128.791 98.7399L94.4467 64.3957C85.8606 55.8096 72.9815 55.8096 64.3955 64.3957C55.8094 72.9817 55.8094 85.8609 64.3955 94.4469Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_742_2919">
                                <rect
                                    width="182.138"
                                    height="182.138"
                                    fill="white"
                                    transform="translate(128.791 257.582) rotate(-135)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute top-[18%] left-48 lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_742_2860)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FF6600"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_742_2860">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="flex gap-1 items-center">
                    <p className="font-normal Poppins primary text-lg text-new-color">
                        Hiring Expert
                    </p>
                </div>
                <div className="grid mt-3 lg:grid-cols-2 grid-cols-1">
                    <div>
                        <h1 className="lg:text-5xl text-4xl text-new-color Poppins font-light">
                            Streamline Procurement with Expert Guidance
                        </h1>
                        <div className="h-px bg--gray w-full my-8" />
                        <p className="text-lg Poppins text-new-color font-normal">
                            Navigate through the complexities of sourcing,
                            negotiation, and vendor management while optimizing
                            costs and ensuring quality. Our experts are here to
                            guide you every step of the way, ensuring your
                            procurement processes are smooth, strategic, and
                            successful.
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <img
                            src={Hireexpert}
                            alt="Hireexpert"
                            className="h-[318px]"
                        />
                    </div>
                </div>
                <div className="mt-48">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
                        <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="lg:flex-row flex-col  flex gap-10 items-center">
                                <img
                                    src={mask_one}
                                    alt="mask_one"
                                    className="w-[125px] h-[125px] rounded-full grayscale"
                                />
                                <div>
                                    <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                        James Moran
                                    </h1>
                                    <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                        Member
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 space-y-5">
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Project:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Market Research and Analysis for a
                                        Procurement Strategy Enhancement
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Expertise:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Data analysis, market research,
                                        strategic planning
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Testimonial:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        "James' meticulous data analysis and
                                        market insights helped us optimize our
                                        procurement strategy, resulting in
                                        significant cost savings and improved
                                        supplier relationships."
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-8">
                                <Buttonlink
                                    to={"/submit-form"}
                                    Text={"Hire me"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                />
                            </div>
                        </div>
                        <div className="bg-white rounded-3xl h-max lg:mt-44 shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="lg:flex-row flex-col  flex gap-10 items-center">
                                <img
                                    src={Mask_two}
                                    alt="Mask_two"
                                    className="w-[125px] h-[125px] object-contain"
                                />
                                <div>
                                    <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                        Annabel K Barry
                                    </h1>
                                    <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                        Member
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 space-y-5">
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Project:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Digital Marketing Campaign for Supplier
                                        Acquisition
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Expertise:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Digital marketing, lead generation,
                                        campaign management
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Testimonial:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        "Annable 's expertise in digital
                                        marketing strategies drove targeted
                                        leads for our supplier acquisition
                                        campaign, resulting in a substantial
                                        increase in qualified leads and new
                                        partnerships.
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-8">
                                <Buttonlink
                                    to={"/submit-form"}
                                    Text={"Submit Form"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                />
                            </div>
                        </div>
                        <div className="bg-white rounded-3xl lg:-mt-44 h-max shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="lg:flex-row flex-col  flex gap-10 items-center">
                                <img
                                    src={Mask_three}
                                    alt="Mask_three"
                                    className="w-[125px] h-[125px] object-contain rounded-full grayscale"
                                />
                                <div>
                                    <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                        Hamza Kamal
                                    </h1>
                                    <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                        Member
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 space-y-5">
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Project:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Supply Chain Optimization and Cost
                                        Reduction
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Expertise:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Supply chain management, process
                                        optimization, cost analysis
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Testimonial:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        "Hamza's extensive knowledge of supply
                                        chain management led to streamlined
                                        processes and cost reductions across our
                                        entire supply chain network, enhancing
                                        our operational efficiency."
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-8">
                                <Buttonlink
                                    to={"/submit-form"}
                                    Text={"Submit Form"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                />
                            </div>
                        </div>
                        <div className="bg-white rounded-3xl mt-0 h-max shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="lg:flex-row flex-col  flex gap-10 items-center">
                                <img
                                    src={Amrish}
                                    alt="Amrish"
                                    className="w-[125px] h-[125px] rounded-full grayscale"
                                />
                                <div>
                                    <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                        Amrish Chowdhary
                                    </h1>
                                    <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                        Member
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 space-y-5">
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Project:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Strategic Vendor Sourcing for Raw
                                        Materials
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Expertise:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        Vendor sourcing, negotiation, supplier
                                        relationship management
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg Poppins text-new-color font-bold">
                                        Testimonial:
                                    </p>
                                    <p className="text-lg Poppins text-new-color font-normal">
                                        "Amrish's vendor sourcing and
                                        negotiation expertise helped us identify
                                        reliable suppliers and negotiate
                                        favorable terms, ensuring a stable
                                        supply of high-quality raw materials.
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-8">
                                <Buttonlink
                                    to={"/submit-form"}
                                    Text={"Submit Form"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-28 -mb-36">
                    <Knowledgepocket data={data} />
                </div>
            </div>
        </div>
    );
};

export default HireExpertPage;
