import React, {useRef, useState} from "react";
import { EmailEditor } from "react-email-editor";
import {
    Buttonlink,
} from "components";
import {gql, useMutation} from "@apollo/client";
import {toast} from "react-toastify";


const CREATE_EMAIL_TEMPLATE = gql`
    mutation CreateEmailTemplate($fileName: String!, $content: String!) {
        createEmailTemplate(fileName: $fileName, content: $content)
    }
`;

const EmailComposer = () => {
    const emailEditorRef = useRef(null);
    const [option1, setOption1] = useState(0);
    const [option2, setOption2] = useState(0);
    const [option3, setOption3] = useState(0);

    const calculateTotal = () => {
        const price1 = 10; // Set your price per quantity for option 1
        const price2 = 15; // Set your price per quantity for option 2
        const price3 = 20; // Set your price per quantity for option 3

        const total = option1 * price1 + option2 * price2 + option3 * price3;
        return total;
    };

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            console.log('exportHtml', html);
        });
    };

    const onLoad = () => {
        // Editor instance is created
        // You can load your template here if needed
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const notify = (message = "", type = "error") => {
        if (type === "error") {
            return toast.error(message, { position: "bottom-right" });
        }
        toast.success(message, { position: "bottom-right" });
    };

    const onReady = () => {
        // Editor is ready
        console.log('onReady');
    };

     const generateRandomFileName = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 100000); // You can adjust the range as needed
        return `${timestamp}_${random}`;
    }

    const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE);

    const handleCreateEmailTemplate = () => {
        let template = "" ;
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            const encodedHtml = btoa(html);
            const randomFileName = generateRandomFileName();

            setTimeout(()=>{
                createEmailTemplate({
                    variables: {
                        fileName: randomFileName,
                        content: encodedHtml,
                    },
                })
                    .then((result) => {
                        // Handle the result after the mutation is complete
                        console.log(result);
                        notify("Email is Send",'success')
                    })
                    .catch((error) => {
                        // Handle any errors
                        console.error(error);
                        notify("Email is not Send",'error')
                    });
            },1000)

        });
    };

    return (
        <div className="container">
            <h1 className="text-5xl Poppins font-light text-center">How many email need to send</h1>
            <div className="space-y-5 mb-4 mt-10">
                <div className="flex justify-between gap-4 md:flex-nowrap flex-wrap">
                    <div className="flex-grow">
                        <label htmlFor="option1" className="block text-lg font-medium text-gray-700">Number of CEO:</label>
                        <input type="number" id="option1" value={option1} onChange={(e) => setOption1(e.target.value)} className="mt-1 p-2 rounded-md border border-gray-300 w-full" />
                    </div>

                    <div className="flex-grow">
                        <label htmlFor="option2" className="block text-lg font-medium text-gray-700">Number of Procurement professional:</label>
                        <input type="number" id="option2" value={option2} onChange={(e) => setOption2(e.target.value)} className="mt-1 p-2 rounded-md border border-gray-300 w-full" />
                    </div>

                    <div className="flex-grow">
                        <label htmlFor="option3" className="block text-lg font-medium text-gray-700">Number of Employer:</label>
                        <input type="number" id="option3" value={option3} onChange={(e) => setOption3(e.target.value)} className="mt-1 p-2 rounded-md border border-gray-300 w-full" />
                    </div>
                </div>
                <p className="text-lg font-semibold Poppins">Total Payable Amount: ${calculateTotal()}</p>
            </div>
            <div className="overflow-x-auto">
                <EmailEditor
                    ref={emailEditorRef}
                    id="editor-container"
                    onLoad={onLoad}
                    onReady={onReady}
                    options={{
                        appearance: {
                            theme: 'dark',
                        }
                    }}
                />
            </div>
            <div className="flex mt-5 lg:flex-nowrap flex-wrap gap-4">
                <Buttonlink
                    type={"submit"}
                    Text="Export Template"
                    onClick={exportHtml}
                    className="bg--primary text-white flex items-center justify-center Regular text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--lg shadow-none h-12 w-full"
                />
                <Buttonlink
                    Text="Save it as a draft / Pay later"
                    onClick={exportHtml}
                    className="bg--gray text-white flex items-center justify-center Regular text-[17px] hover:bg--gray hover:opacity-70 focus:bg--gray border-0 rounded--lg shadow-none h-12 w-full"
                />
                <Buttonlink
                    Text="Send Now"
                    onClick={handleCreateEmailTemplate}
                    className="bg--primary text-white flex items-center justify-center Regular text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--lg shadow-none h-12 w-full"
                />
            </div>

        </div>
    );
};

export default EmailComposer;
