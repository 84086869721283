import React from "react";
import {Redirect, Route} from "react-router-dom";
import Auth from "./Auth";
const auth = new Auth();

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <>
            <Component {...props}/>
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          </>
        )
      }
    />
  );
};

export default ProtectedRoute;
