import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function Postskeleton() {
  return (
    <div className="p-3 pb-0 overflow-hidden border rounded-lg">
      <div className="flex items-center mb-3">
        <Skeleton
          animation="pulse"
          variant="circle"
          width={52}
          height={52}
          className="rounded-full"
        />
        <div className="flex items-center justify-between flex-grow">
          <div className="ml-3">
            <Skeleton
              animation="pulse"
              variant="rect"
              height={8}
              className="rounded-full"
              width={240}
            />
            <Skeleton
              variant="rect"
              height={8}
              className="mt-2 rounded-full"
              width={100}
            />
          </div>
          <div>
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={50}
            />
          </div>
        </div>
      </div>
      <Skeleton variant="rect" height={10} className="rounded-full" />
      <Skeleton
        variant="rect"
        height={10}
        className="w-1/2 my-3 rounded-full"
      />
      <div className="flex flex-wrap items-center gap-2 my-5">
        <Skeleton
          variant="rect"
          height={40}
          className="rounded-lg"
          width={100}
        />
        <Skeleton
          variant="rect"
          height={40}
          className="rounded-lg"
          width={150}
        />
        <Skeleton
          variant="rect"
          height={40}
          className="rounded-lg"
          width={100}
        />
        <Skeleton
          variant="rect"
          height={40}
          className="rounded-lg"
          width={200}
        />
        <Skeleton
          variant="rect"
          height={40}
          className="rounded-lg"
          width={100}
        />
        <Skeleton
          variant="rect"
          height={40}
          className="rounded-lg"
          width={100}
        />
      </div>
      <div className="py-6 border-t">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-6">
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={50}
            />
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={50}
            />
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={50}
            />
          </div>
          <Skeleton
            variant="rect"
            height={8}
            className="ml-4 rounded-full"
            width={50}
          />
        </div>
      </div>
      <div className="-mx-3">
        <Skeleton variant="rect" height={50} />
      </div>
    </div>
  );
}
