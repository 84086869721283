import { gql } from "@apollo/client";

export default gql`
  mutation CourseDecisionCreate(
    $user_id: Int!
    $course_id: Int!
    $message: String!
    $is_author: Boolean
  ) {
    course_decision_create(
      user_id: $user_id
      course_id: $course_id
      message: $message
      is_author: $is_author
    ) {
      id
      message
      is_author
      user {
        id
        username
        firstname
        lastname
      }
      course{
        id
      }
    }
  }
`;
