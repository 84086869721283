import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import client from "../../../graphql/client";
import get_user_token from "../../../graphql/mutations/get_user_token";
import cookie from "js-cookie";
import { connect } from "react-redux";
import { GRAPH_USER_AUTH_TOKEN } from "../../../config/constants";
import { FcGoogle } from "react-icons/fc";
import { IsLoader } from "components";
import axios from "axios";

function GoogleLoginButton() {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleLogin(codeResponse);
      setLoader(true);
      setError('');
    },
    onError: (error) => {
      setError(error);
    }
  });

  const handleLogin = (codeResponse) => {
    if (codeResponse) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const googleData = res.data;
          localStorage.setItem("GoogleLoginData", googleData);
          setTimeout(() => {
            if (googleData) {
              client
                .mutate({
                  mutation: get_user_token,
                  variables: {
                    email: googleData.email,
                    firstname: googleData.given_name,
                    lastname: googleData.family_name,
                  },
                })
              
              .then((response) => {
                  let { error, message, token } = response.data.get_user_token;
                if (error === true) {
                    setError(message);
                    setLoader(false);
                  } else {
                    cookie.set(GRAPH_USER_AUTH_TOKEN, token);
                    window.location.href = "/";
                    setLoader(false);
                    props.handleAuthUser(token);
                  }
                })
              }
          });
        })
        .catch((err) => {
          setLoader(false);
          setError(err);
        });
    }
  };

  return (
    <>
    <button
      type="button"
      disabled={loader}
      onClick={() => login()}
      className="flex items-center capitalize justify-center gap-4 w-full h-12 !text-lg px-4 bg-[#F2F2F2] border rounded-full shadow-none hover:black darkGray focus:black hover:opacity-70 hover:bg-[#F2F2F2] text-new-color focus:bg-[#F2F2F2] rounded--full Poppins border-none"
    >
      {loader ? 
        <IsLoader
        className="h-full"
        />:
      <>
      <FcGoogle size={22} />
      <span>Continue With Google</span>
      </>
      }
      </button >
      {error &&
        <div className="primary">{error}</div>
      }
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      handleAuthUser: (data) =>
      dispatch({ type: "AUTH_USER_TOKEN", payload: data }),
    };
};

export default connect(null, mapDispatchToProps)(GoogleLoginButton);
