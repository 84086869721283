import * as React from "react";
import { Link, NavLink,useLocation } from "react-router-dom";
import logo from "assets/images/new_logo.svg";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import SubscribeButton from "../allcomponents/subscribebutton";
import { FACEBOOK_URL, LINKEDIN_URL } from "../../config/constants";
import Buttonlink from "components/allcomponents/buttonlink";
import { connect } from "react-redux";
import Notification from "components/header/notification";
import { ToastContainer } from "react-toastify";
import AnimateButton from "components/allcomponents/AnimteButton";

function Footer(props) {
    //   const [ranOnce, setRanOnce] = React.useState(true);
    //   const [notify, setNotify] = React.useState(false);
    //   React.useEffect(() => {
    //     if (!ranOnce) {
    //       if (props.authUserSocket?.connected) {
    //         console.log("K");
    //         props.authUserSocket?.on("receive_notification", (data) => {
    //           if (data == props?.authUserInformation?.id) {
    //             setNotify(true);
    //           }
    //         });
    //       }
    //     } else {
    //       // After 5 seconds, run the code again.
    //       const timer = setTimeout(() => {
    //         console.log("Running again after 5 seconds");
    //         setRanOnce(false);
    //       }, 15000); // 15000 milliseconds = 15 seconds

    //       // Clear the timer when the component unmounts or when 'props.socket' changes.
    //       return () => clearTimeout(timer);
    //     }
    //   }, [props.socket, ranOnce]);
    const currentYear = new Date().getFullYear();
    const [isHideFooter, setIsHideFooter] = React.useState(false);
    const { pathname } = useLocation();

    React.useEffect(() => {
        if (pathname.includes('offerings')) {
            setIsHideFooter(true);
        } else {
            setIsHideFooter(false);
        }
    },[pathname])

    return (
        <>
            {/* {notify && (
                <Notification refetch={notify} callback={(value) => setNotify(false)} />
            )} */}
            <ToastContainer />
            <div className={`${isHideFooter ? 'hidden' : ""} mt-20 bg-[#F6FAFD] pt-20 pb-10`}>
                <div className="container lg:mt-14 md:mt-14">
                    <NavLink
                        to="/"
                        className="flex items-center pr-3 mb-10 rounded-full black Medium fs-13"
                    >
                        <img
                            className="object-contain sm:h-12 h-[43px] w-[254px]"
                            src={logo}
                            alt="logo"
                        />
                    </NavLink>
                    <div className="grid grid-cols-12 lg:mt-14 md:mt-14">
                        <div className="lg:col-span-4 md:col-span-6 col-span-12 flex flex-col">
                            <p className="font-light capitalize text-lg">
                                A digital development platform exclusively for <br />{" "}
                                procurement & supply chain community
                            </p>
                            <div className="flex-grow flex flex-col justify-end items-start">
                                <a
                                    href="mailto:admin@procurementleague.com"
                                    className="primary"
                                >
                                    admin@procurementleague.com
                                </a>
                                <a href="tel:+1 (267) 516-0279" className="primary">
                                    +1 (267) 516-0279
                                </a>
                                <div className="bg--primary h-px w-full mt-7" />
                            </div>
                        </div>
                        <div className="lg:flex md:flex flex-col hidden col-span-2 gap-2">
                            <Link
                                to={"/"}
                                className="darkGray hover:primary--text fs-14 hidden"
                            >
                                Home
                            </Link>
                            <Link
                                to={"/discussions"}
                                className="hidden darkGray hover:primary--text fs-14"
                            >
                                Discussion Forum
                            </Link>
                            <Link
                                to={"/leaderboard"}
                                className="hidden darkGray hover:primary--text fs-14"
                            >
                                Leaders
                            </Link>
                        </div>
                        <div className="lg:flex md:flex flex-col hidden col-span-4 font-semibold">
                            <div className="grid grid-cols-4">
                                <div className="col-span-2 border-r space-y-2">
                                    <div className="text-[#3B455A] mb-4 text-lg font-bold Poppins">Useful Links</div>
                                    <Link
                                        to={"/about-us"}
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        About
                                    </Link>
                                    <Link
                                        to={"/our-team"}
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Our Teams
                                    </Link>
                                    <Link
                                        to={"/success-stories-buyside"}
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Success Stories
                                    </Link>
                                    <Link
                                        to="/hire-expert"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Hiring Expert
                                    </Link>
                                    <Link
                                        to="/events"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Events
                                    </Link>
                                    <Link
                                        to="/privacy-policy"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Privacy
                                    </Link>
                                    <Link
                                        to="/disclaimer"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Disclaimer
                                    </Link>
                                    <Link
                                        to="/term-of-services"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Terms of Service
                                    </Link>
                                </div>
                                <div className="col-span-2 border-r px-4 space-y-3">
                                    <div className="text-[#3B455A] mb-4 text-lg font-bold Poppins">Other Links</div>
                                    <Link
                                        to={"/buyers"}
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Buyers
                                    </Link>
                                    <Link
                                        to={"/sellers"}
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Sellers
                                    </Link>
                                    <Link
                                        to={"/industry-solution"}
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Industry Solution
                                    </Link>
                                    <Link
                                        to="/knowledge-bank"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Knowledge Bank
                                    </Link>
                                    <Link
                                        to="/faqs"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        FAQs
                                    </Link>
                                    <Link
                                        to="/blog/sketch-map-illustrating-the-evolution-of-procurement-from-the-stone-age-to-the-modern-age"
                                        className="block text-[#3B455A] font-normal hover:primary--text text-lg"
                                    >
                                        Blog
                                    </Link>
                                    <AnimateButton to={'/newsletters'} text={'Subscribe'} className={'flex bg--primary rounded-full white hover:text-white Poppins justify-between items-center h-12 px-5 w-40'} />
                                </div>
                            </div>
                        </div>
                        <div className="flex pl-4 flex-col lg:mt-0 md:mt-0 mt-6 lg:col-span-2 md:col-span-6 col-span-12 gap-2">
                            <div
                                className="block text-[#3B455A] md:font-bold md:text-lg text-3xl md:text-left text-center"
                            >
                                Stay Connected
                            </div>
                            <p className="font-medium lg:text-left md:text-left text-center text-lg">
                                Stay informed and inspired with our exclusive content
                            </p>
                            <SubscribeButton location={"footer"} />
                            <div className="w-full h-0.5 bg-gray-100" />
                            <Link
                                to="/contact-us"
                                className="block text-[#3B455A] md:font-bold md:text-lg text-3xl md:text-left text-center"
                            >
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    <div className="bg--primary lg:hidden md:hidden block h-px w-full my-7" />
                    <div className="flex lg:hidden md:hidden items-center justify-center flex-wrap gap-4">
                        <Buttonlink
                            to={"/buyers"}
                            Text={"Buyers"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/seller"}
                            Text={"Sellers"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/about-us"}
                            Text={"About"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/our-team"}
                            Text={"Our Team"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/success-stories-buyside"}
                            Text={"Success Stories"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/pricing"}
                            Text={"Membership"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/faqs"}
                            Text={"FAQs"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                        <Buttonlink
                            to={"/blog/sketch-map-illustrating-the-evolution-of-procurement-from-the-stone-age-to-the-modern-age"}
                            Text={"Blog"}
                            className="text-lg text-new-color Poppins font-normal"
                        />
                    </div>
                    <div className="bg--primary lg:hidden md:hidden block h-px w-full my-7" />
                    <div className="flex gap-4 lg:flex-nowrap md:flex-nowrap flex-wrap lg:justify-start md:justify-start justify-center items-center">
                        <div className="flex flex-row items-center justify-start">
                            <p className="flex items-center tracking-wide darkGray Light fs-13">
                                Copyright © {currentYear} Procurement League Inc. All Rights
                                Reserved
                            </p>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-4">
                            <a
                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                href={LINKEDIN_URL}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <FaLinkedinIn
                                    size={18}
                                    className="cursor-pointer white hover"
                                />
                            </a>
                            <a
                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                href={FACEBOOK_URL}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <FaFacebookF size={18} className="cursor-pointer white hover" />
                            </a>
                            <a
                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                href="https://twitter.com/procurementlea"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <svg stroke="currentColor" className="cursor-pointer white hover w-5 h-5" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                                </svg>
                            </a>
                            <a
                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                href="https://www.instagram.com/procurementleague/"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <AiFillInstagram
                                    size={18}
                                    className="cursor-pointer white hover"
                                />
                            </a>
                            <a
                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                href="https://www.youtube.com/@procurementleague"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <AiFillYoutube
                                    size={18}
                                    className="cursor-pointer white hover"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({
    authUserInformation: state.AuthUserReducer.authUserInformation,
    authUserSocket: state.AuthUserReducer.authUserSocket,
});

export default connect(mapStateToProps, null)(Footer);
