import { Fullscreenpopup } from "components";
import React, { Component } from "react";
import { BsFillPersonPlusFill, BsLayersFill } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";
import { FiHash } from "react-icons/fi";
import { connect } from "react-redux";

class SelectTags extends Component {
  constructor(props) {
    super(props);
    this.SelectFullPopup = React.createRef();
    this.state = {
      title: "",
    };
  }

  openPopup = (e) => {
      this.setState({ title: e }, () => {
        this.SelectFullPopup.current.openPopup();
      });
  }

  render() {
    return (
      <>
        <div className="flex flex-wrap items-center py-8 sm:justify-between gap-y-8 sm:gap-y-auto lg:px-5">
          <div
            className="flex items-center w-1/2 cursor-pointer sm:w-auto"
            onClick={()=>this.openPopup("Select Topic")}>
            <BsLayersFill
              size={20}
              className={
                this.props.selectedTopics.length > 0
                  ? "primary mr-2"
                  : "gray mr-2"
              }
            />
            <p
              className={
                this.props.selectedTopics.length > 0 ? "primary" : "text-color"
              }>
              Select Topic
            </p>
          </div>
          <div
            className="flex items-center w-1/2 cursor-pointer sm:w-auto"
            onClick={()=>this.openPopup("Select Tags")}>
            <FiHash
              size={22}
              className={
                this.props.selectedTags.length > 0
                  ? "primary mr-2"
                  : "gray mr-2"
              }
            />
            <p
              className={
                this.props.selectedTags.length > 0 ? "primary" : "text-color"
              }>
              Tags
            </p>
          </div>
          <div
            className={
              this.props.selectedUsers.length > 0
                ? "primary flex items-center cursor-pointer sm:w-auto w-1/2"
                : "flex items-center cursor-pointer sm:w-auto w-1/2"
            }
            onClick={()=>this.openPopup("Select Member")}>
            <BsFillPersonPlusFill size={22} className="mr-2 gray" />
            <p
              className={
                this.props.selectedUsers.length > 0 ? "primary" : "text-color"
              }>
              Member
            </p>
          </div>
          <div
            className="flex items-center cursor-pointer "
            onClick={()=>this.openPopup("Send by email")}>
            <FaEnvelope size={20} className="mr-2 gray" />

            <p
              className={
                this.props.typedEmail.length > 0 ? "primary" : "text-color"
              }>
              Send by email
            </p>
          </div>
        </div>
        <Fullscreenpopup
          ref={this.SelectFullPopup}
          title={this.state.title}
          direction="right"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTopics: state.DiscussionSearchReducer.categories,
  selectedTags: state.DiscussionSearchReducer.selectedTags,
  selectedUsers: state.DiscussionSearchReducer.selectedUsers,
  typedEmail: state.DiscussionSearchReducer.typedEmail,
});

export default connect(mapStateToProps, null)(SelectTags);
