import React, { useEffect } from "react";
import Subtract from "assets/images/Subtract.png";
import { BsArrowDown, BsArrowRight } from "react-icons/bs";
import Solutions from "assets/images/industries/Solutions.png";
import Biotech from "assets/images/industries/biotech.png";
import banner from "assets/images/industries/industryLanding.webp";
import industryVector2 from "assets/images/industries/industry-vector2.png";
import business from "assets/images/industries/business-team.webp";
import { Buttonlink, Knowledgepocket } from "components";
import { IoMdCheckmark } from "react-icons/io";
import { scrollToContent } from "helpers/ScrollToContent";
import { useHistory } from 'react-router-dom'

const IndustriesSolutionsPage = () => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const Navigation = (link, caseStudy) => {
        history.push({
            pathname: link,
            state: { caseStudy: caseStudy }
        });
    }

    return (
        <>
            <div className="pb-28 -mt-[50px] bg--lightBlue">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden relative">
                        <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] IndustriesSolutionsBanner relative">
                        <div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Industry Solutions
                            </p>
                            <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Unlock Business Opportunities with Expert
                                Procurement Solutions
                            </p>
                            <p className="white Poppins font-normal">
                                Connect with Key Executives and Sellers, Gain
                                Market Intelligence, and Access Skilled Experts
                            </p>
                            <div className="h-px bg-white mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button
                                    onClick={() =>
                                        scrollToContent("contentSection", 1000)
                                    }
                                    className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                                >
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={Subtract}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>
                <div id="contentSection" className="container relative mt-28">
                    <div className="absolute left-0 -top-20 lg:block hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            />
                        </svg>
                    </div>
                    <div className="absolute right-0 -top-20 lg:block hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="108"
                            height="108"
                            viewBox="0 0 108 108"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_820_3502)">
                                <path
                                    d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_820_3502">
                                    <rect
                                        width="81"
                                        height="81"
                                        fill="white"
                                        transform="translate(0 33.8882) rotate(-24.7319)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="flex lg:flex-row flex-col justify-center gap-10 items-center">
                        <div className="w-full lg:h-[370px] custom-shadow max-w-md rounded-3xl space-y-5 bg-white p-8">
                            <img
                                src={Solutions}
                                alt="Solutions"
                                className="w-[99px] h-[91px]"
                            />
                            <p className="text-3xl Poppins font-light text-new-color">
                                B2B Solutions
                            </p>
                            <p className="text-lg Poppins font-normal text-new-color">
                                Targeted solutions crafted for B2B buyers,
                                procurement, and finance executives, amplifying
                                your sales potential and enhancing brand
                                awareness.
                            </p>
                            <Buttonlink
                                className="primary font-normal text-lg Poppins"
                                to={"/B2B"}
                                Text={"Unlock Potential >>"}
                            />
                        </div>
                        <div className="w-full lg:h-[370px] custom-shadow max-w-md rounded-3xl space-y-5 bg-white p-8">
                            <img
                                src={Biotech}
                                alt="Biotech"
                                className="w-[42px] h-[99px]"
                            />
                            <p className="text-3xl Poppins font-light text-new-color">
                                Pharma/Biotech Solution
                            </p>
                            <p className="text-lg Poppins font-normal text-new-color">
                                Leverage data-powered solutions designed
                                specifically for the pharmaceutical and biotech
                                industries to drive growth and innovation.
                            </p>
                            <Buttonlink
                                className="primary font-normal text-lg Poppins"
                                to={"/pharma-league"}
                                Text={"Discover the Best Options >>"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* <div className="mt-28">
                    <div className="grid lg:grid-cols-3 gap-5 md:grid-cols-2 grid-cols-1">
                        <div className="lg:col-span-2">
                            <h1 className="text-3xl Poppins primary font-light">
                                Decision Maker:
                            </h1>
                            <h1 className="my-3 text-3xl Poppins primary font-light">
                                Connect with the Right Executives & Seller
                            </h1>
                            <div className="space-y-3">
                                <p className="text-lg Poppins text-new-color font-bold">
                                    Are you a buyer looking to find and connect
                                    with key executives or sellers for specific
                                    categories?
                                </p>
                                <p className="text-lg Poppins text-new-color font-bold">
                                    Are you a seller searching for buyers of
                                    certain services or products?
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal">
                                    Our community market makers specialize in
                                    connecting you with the executives you're
                                    looking for.
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal">
                                    Discover the tangible benefits and
                                    transformative outcomes we have delivered
                                    for our clients. Get inspired and unlock
                                    your own business opportunities today.
                                </p>
                                <button
                                    onClick={()=>Navigation("/pharma-league",true)}
                                    className="py-2 lg:w-64 whitespace-nowrap text-lg font-normal hover:opacity-80 bg--primary self-start Poppins !hover:text-white !text-white px-5 rounded-full flex justify-between items-center"
                                >
                                    View Case Studies <BsArrowRight size={23} />
                                </button>
                            </div>
                        </div>
                        <div className="lg:order-last md:order-last order-first">
                            <img src={industryVector} alt="industryVector" />
                        </div>
                    </div>
                </div>
                <div className="mt-28">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="332"
                                height="332"
                                viewBox="0 0 332 332"
                                fill="none"
                            >
                                <path
                                    d="M138.077 35.7591L130.434 123.939L42.2537 116.296C20.2087 114.385 2.24179 129.486 0.330993 151.531C-1.57981 173.576 13.5209 191.543 35.5659 193.453L123.746 201.097L116.103 289.277C114.192 311.322 129.293 329.289 151.338 331.199C173.383 333.11 191.349 318.01 193.26 295.965L200.903 207.784L289.084 215.428C311.129 217.338 329.095 202.238 331.006 180.193C332.917 158.148 317.816 140.181 295.771 138.27L207.591 130.627L215.234 42.4469C217.145 20.4018 202.045 2.435 180 0.524206C157.955 -1.38659 139.988 13.714 138.077 35.7591Z"
                                    fill="#B5E2FF"
                                    fillOpacity="0.7"
                                />
                            </svg>
                        </div>
                        <div className="lg:col-span-2">
                            <h1 className="text-3xl Poppins primary font-light">
                                Market Intelligence:
                            </h1>
                            <h1 className="my-3 text-3xl Poppins primary font-light">
                                Gain Valuable Insights for Informed
                                Decision-Making
                            </h1>
                            <div className="space-y-3">
                                <p className="text-lg Poppins text-new-color font-normal">
                                    Stay ahead of the competition with our
                                    comprehensive market intelligence services.
                                    We provide detailed reports and analysis to
                                    help you make informed decisions.
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal">
                                    Identify trends, opportunities, and
                                    potential risks to optimize your procurement
                                    strategies.
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal">
                                    Our Market Intelligence services provide
                                    valuable data and analysis to drive your
                                    procurement strategies. Benefit from our
                                    expertise in areas such as:
                                </p>
                                <ul className="!mt-3">
                                    <li className="text-lg Poppins text-new-color font-normal">
                                        Price Benchmarking: Gain insights into
                                        market pricing trends and ensure
                                        competitive pricing for your products or
                                        services.
                                    </li>
                                    <li className="text-lg Poppins text-new-color font-normal">
                                        Capability Benchmarking: Assess and
                                        compare the capabilities of suppliers,
                                        helping you identify the best partners
                                        for your procurement needs.
                                    </li>
                                </ul>
                                <p className="text-lg Poppins text-new-color font-bold">
                                    From a buyer perspective, we offer tailored
                                    projects such as:
                                </p>
                                <ul className="!my-3">
                                    <li className="text-lg Poppins text-new-color font-normal">
                                        Sales Outsourcing: Optimize your sales
                                        operations by outsourcing to trusted
                                        partners who excel in generating revenue
                                        and driving growth.
                                    </li>
                                    <li className="text-lg Poppins text-new-color font-normal">
                                        Agency Rates Benchmarking: Ensure you're
                                        getting the best value from your agency
                                        partnerships by benchmarking rates and
                                        negotiating competitive terms.
                                    </li>
                                    <li className="text-lg Poppins text-new-color font-normal">
                                        Packaging Supplier Search: Find reliable
                                        and innovative packaging suppliers to
                                        meet your unique product packaging
                                        requirements.
                                    </li>
                                </ul>
                                <Buttonlink
                                    to={"/contact-us"}
                                    Text={"Contact Us"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 lg:w-44 whitespace-nowrap text-lg font-normal hover:opacity-80 bg--primary self-start Poppins !hover:text-white !text-white px-5 rounded-full flex justify-between items-center"
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="mt-28 relative">
                    <div class="absolute z-[-1] top-0 -right-28 lg:block hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="266" height="265" viewBox="0 0 266 265" fill="none"><g clipPath="url(#clip0_967_2840)"><path d="M46.9956 132.866L101.857 148.997L85.8594 203.401C81.8601 217.001 89.1471 230.227 102.862 234.26C116.578 238.293 129.863 231.117 133.863 217.516L149.86 163.112L204.721 179.244C218.436 183.277 231.722 176.101 235.721 162.5C239.72 148.899 232.433 135.674 218.718 131.641L163.857 115.509L179.854 61.1059C183.854 47.5051 176.567 34.2797 162.851 30.2468C149.136 26.2139 135.85 33.3899 131.851 46.9907L115.854 101.394L60.9931 85.2625C47.2778 81.2296 33.992 88.4056 29.9927 102.006C25.9934 115.607 33.2804 128.833 46.9956 132.866Z" fill="#B5E2FF" fill-opacity="0.7"></path></g><defs><clipPath id="clip0_967_2840"><rect width="214.438" height="212.65" fill="white" transform="translate(205.727 264.506) rotate(-163.614)"></rect></clipPath></defs>
                        </svg>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-5 md:grid-cols-2 grid-cols-1">
                        <div>
                            <h1 className="text-3xl Poppins primary font-light">
                                Hire Experts:
                            </h1>
                            <h1 className="my-3 text-3xl Poppins primary font-light">
                                Join Our Team for Gig Projects
                            </h1>
                            <div className="space-y-3 mt-10">
                                <p className="text-lg Poppins text-new-color font-bold">
                                    Are you a skilled professional looking for
                                    exciting gig projects in the procurement
                                    industry?
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal">
                                    Join our team of experts and collaborate
                                    with us on a range of engaging projects.
                                    Showcase your expertise and contribute to
                                    meaningful initiatives with industry
                                    leaders. Enjoy the flexibility of <br className="lg:block hidden" /> gig-based
                                    work while leveraging our platform for
                                    project opportunities.
                                </p>
                                <Buttonlink
                                    to={"/hire-expert"}
                                    Text={"Meet Some Our Experts"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 lg:w-72 whitespace-nowrap text-lg font-normal hover:opacity-80 bg--primary self-start Poppins !hover:text-white !text-white px-5 !mt-10 rounded-full flex justify-between items-center"
                                />
                            </div>
                        </div>
                        <div className="lg:order-last md:order-last order-first">
                            <img
                                src={industryVector2}
                                alt="industryVector2"
                                className="lg:w-[400px] lg:h-[400px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-28 -mb-36">
                    <img
                        src={business}
                        alt="business"
                        className="lg:h-[calc(100vh-150px)] object-contain w-full"
                    />
                </div>
            </div>
            <div className=" bg--lightBlue">
                <div className="pt-56 container">
                    <div className="space-y-5">
                        <p className="lg:text-5xl text-4xl Poppins text-new-color font-light">
                            Why Choose Our Services:
                        </p>
                        <p className="text-lg text-new-color w-full max-w-5xl font-normal Poppins">
                            Make a smart investment in your future by choosing
                            our buyer opportunity. Experience high-quality
                            services, practical application, flexibility,
                            ongoing support, networking opportunities, and a
                            proven track record. Start your journey towards
                            success today.
                        </p>
                    </div>
                    <div className="bg-white relative rounded-2xl p-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
                        <div className="divide-y">
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Extensive Network:
                                    </span>{" "}
                                    Access a wide network of key executives and
                                    sellers.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Actionable Insights:
                                    </span>{" "}
                                    Gain comprehensive market intelligence for
                                    informed decisions.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Top-Quality Experts:
                                    </span>{" "}
                                    Connect with highly skilled and vetted
                                    professionals.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Seamless Project Management:
                                    </span>{" "}
                                    Experience efficient project management and
                                    support.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Trusted Reputation:
                                    </span>{" "}
                                    Benefit from our established and reliable
                                    track record.
                                </p>
                            </div>
                        </div>
                        <div className="absolute left-0 lg:block hidden -bottom-56">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="214"
                                height="214"
                                viewBox="0 0 214 214"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_820_3467)">
                                    <path
                                        d="M90.3545 29.8741L85.8218 82.1677L33.5282 77.6351C20.4548 76.5019 9.79988 85.4571 8.66671 98.5305C7.53355 111.604 16.4887 122.259 29.5621 123.392L81.8558 127.925L77.3231 180.218C76.1899 193.292 85.1451 203.947 98.2185 205.08C111.292 206.213 121.947 197.258 123.08 184.184L127.613 131.891L179.906 136.423C192.98 137.557 203.635 128.601 204.768 115.528C205.901 102.455 196.946 91.7997 183.872 90.6665L131.579 86.1338L136.111 33.8402C137.245 20.7668 128.289 10.1119 115.216 8.97869C102.143 7.84552 91.4877 16.8007 90.3545 29.8741Z"
                                        fill="#B5E2FF"
                                        fillOpacity="0.4"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_820_3467">
                                        <rect
                                            width="196.836"
                                            height="196.836"
                                            fill="white"
                                            transform="translate(0.167969 196.581) rotate(-85.0461)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>

                    <div className="lg:mt-60 mt-28 -mb-36">
                        <Knowledgepocket isImageHide={true} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndustriesSolutionsPage;
