import {gql} from "@apollo/client";

export default gql`

    mutation productActions(
        $id:ID
        $product_id:ID!
        $liked:Boolean
        $book_mark:Boolean
        $visit:Boolean
    ){
        productActions(
            id:$id
            product_id:$product_id
            liked:$liked
            book_mark:$book_mark
            visit:$visit
        ){
            id
            user_id
            product_id
            liked
            book_mark
            visit
            product_like
            created_at
        }
    }
`;