import {
    Eventscard,
    IsLoader,
    Noconnection,
    EventSubscribers,
    EventsFooter,
} from "components";
import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import UPCOMING_EVENTS from "../../graphql/queries/getClasses";
import PROTECTED_EVENTS from "../../graphql/queries/getMinimalClasses";
import Auth from "../../Auth";
import { Link } from "react-router-dom";
import { seo } from "helpers/seo";
import { BsArrowDown } from "react-icons/bs";
import SubtractWhite from "../../assets/images/Subtract-white.png";
import businessImage from "../../assets/images/Events/business.png";
import { IoMdCheckmark } from "react-icons/io";
import { scrollToContent } from "helpers/ScrollToContent";
import banner from "assets/images/Events/eventsbanner.webp"

const auth = new Auth();

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshing: false,
        };
        this.refetch = [];
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        seo({
            title: "Events | Procurement League",
        });
    }

    _onRefresh = () => {
        this.refetch.map((item, _) => {
            item();
        });
        this.setState({ refreshing: false });
    };

    render() {
        let variables = {};
        let variables2 = {};
        let type = "upcoming";
        variables.typeInfo = type;
        variables2.typeInfo = "expired";

        return (
            <>
                <div className="pb-28 -mt-[50px]">
                    <div className="lg:bg-transparent md:bg-transparent bg-black">
                        <div className="lg:hidden md:hidden relative">
                            <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                        </div>
                        <div className="lg:h-[90vh] md:h-[100vh] EventsBanner relative">
                            <div className="flex w-full max-w-3xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                                <p className="text-[#FBE4D3] Poppins font-normal">
                                    Events
                                </p>
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Empower Your Leadership, Shape Your
                                    Industry: Lead The Way With Insights From
                                    Events
                                </p>
                                <p className="white Poppins font-normal">
                                    Inspire, Collaborate, Create Connection with
                                    us!
                                </p>
                                <div className="h-px bg-white mt-3" />
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                    <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                        <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                            Discover <BsArrowDown size={23} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                                <img
                                    src={SubtractWhite}
                                    alt="SubtractWhite"
                                    className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container relative" id="contentSection">
                    <div className="absolute lg:block hidden left-0 top-0">
                        <svg
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            ></circle>
                        </svg>
                    </div>
                    <div>
                        <Query
                            query={
                                auth.isAuthenticated()
                                    ? UPCOMING_EVENTS
                                    : PROTECTED_EVENTS
                            }
                            variables={variables}
                        >
                            {({ loading, error, data, fetchMore, refetch }) => {
                                // this.refetch.push(refetch);
                                if (loading) {
                                    return (
                                        <>
                                            <IsLoader className="w-32 h-32 mx-auto" />
                                        </>
                                    );
                                }
                                if (error)
                                    return (
                                        <div>
                                            <Noconnection />
                                        </div>
                                    );

                                if (
                                    auth.isAuthenticated()
                                        ? data?.courses?.data?.length === 0
                                        : data?.open_courses?.data?.length === 0
                                ) {
                                    return null;
                                }

                                if (data !== undefined) {
                                    return (
                                        <>
                                            <Eventscard
                                                eventType={"Upcoming Events"}
                                                latestEvent={
                                                    auth.isAuthenticated()
                                                        ? data.courses.data
                                                        : data.open_courses.data
                                                }
                                            />
                                            <div className="flex justify-end mt-4 ">
                                                <Link
                                                    to={{
                                                        pathname: `/search/${type.toLowerCase()}/all`,
                                                        state: {
                                                            type: type,
                                                        },
                                                    }}
                                                    className="flex items-center black Poppins"
                                                >
                                                    {" "}
                                                    View all Upcoming Events{" "}
                                                    {">>"}
                                                </Link>
                                            </div>
                                        </>
                                    );
                                }
                            }}
                        </Query>
                    </div>
                </div>
                <div className="container mt-5">
                    <Query
                        query={
                            auth.isAuthenticated()
                                ? UPCOMING_EVENTS
                                : PROTECTED_EVENTS
                        }
                        variables={variables2}
                    >
                        {({
                            loading,
                            error,
                            data: pastEvent,
                            fetchMore,
                            refetch,
                        }) => {
                            // this.refetch.push(refetch);
                            if (loading) {
                                return <div>{null}</div>;
                            }
                            if (error) return <div>{null}</div>;

                            if (
                                auth.isAuthenticated()
                                    ? pastEvent?.courses?.data?.length === 0
                                    : pastEvent?.open_courses?.data?.length ===
                                    0
                            ) {
                                return null;
                            }

                            if (pastEvent !== undefined) {
                                // console.log("type",type)
                                return (
                                    <>
                                        <Eventscard
                                            eventType={"Past Events"}
                                            latestEvent={
                                                auth.isAuthenticated()
                                                    ? pastEvent.courses.data
                                                    : pastEvent.open_courses
                                                        .data
                                            }
                                        />
                                        <div className="flex justify-end mt-4 ">
                                            <Link
                                                to={{
                                                    pathname:
                                                        "/search/expired/all",
                                                    state: {
                                                        type: "expired",
                                                    },
                                                }}
                                                className="flex items-center black Poppins"
                                            >
                                                {" "}
                                                View all Past Events {">>"}
                                            </Link>
                                        </div>
                                    </>
                                );
                            }
                        }}
                    </Query>
                </div>
                <div className="bg--lightBlue lg:mt-56 mt-28 pb-72">
                    <div className="container relative">
                        <div>
                            <img src={businessImage} alt="businessImage" className="lg:absolute lg:h-[750px] lg:pt-0 py-16 w-full lg:-top-24" />
                        </div>

                        <div className="mt-10 relative lg:pt-[650px]">
                            <p className="lg:text-5xl text-4xl text-new-color font-light Poppins">
                                Amplify Your Influence As A Speaker!
                            </p>
                            {/* <p className="text-lg Poppins pt-4 w-full max-w-4xl">
                                Make a smart investment in your future by
                                choosing our buyer opportunity. Experience
                                high-quality services, practical application,
                                flexibility, ongoing support, networking
                                opportunities, and a proven track record. Start
                                your journey towards success today.
                            </p> */}

                            <div className="bg-white z-50 relative rounded-2xl p-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
                                <div className="divide-y">
                                    <div className="flex p-3 items-center gap-4 w-full">
                                        <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                            <IoMdCheckmark
                                                color="#B5E2FF"
                                                size={24}
                                            />
                                        </div>
                                        <p className="lg:text-[20px] md:text-[18px] text-[16px] py-4 font-bold Poppins">
                                            1. Forge Connections, Fuel Success:
                                            Embrace Collaboration.
                                        </p>
                                    </div>
                                    <div className="flex p-3 items-center gap-4 w-full">
                                        <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                            <IoMdCheckmark
                                                color="#B5E2FF"
                                                size={24}
                                            />
                                        </div>
                                        <p className="lg:text-[20px] md:text-[18px] text-[16px] py-4 font-bold Poppins">
                                            2. Inspire, Empower, transform: Join
                                            us on the Stage of Possibility,
                                            Guiding Others to Success!
                                        </p>
                                    </div>
                                    <div className="flex p-3 items-center gap-4 w-full">
                                        <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                            <IoMdCheckmark
                                                color="#B5E2FF"
                                                size={24}
                                            />
                                        </div>
                                        <p className="lg:text-[20px] md:text-[18px] text-[16px] py-4 font-bold Poppins">
                                            3. Build Your Brand, Shine Bright:
                                            Cement Your Thought Leadership.
                                        </p>
                                    </div>
                                    <div className="flex p-3 items-center gap-4 w-full">
                                        <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                            <IoMdCheckmark
                                                color="#B5E2FF"
                                                size={24}
                                            />
                                        </div>
                                        <p className="lg:text-[20px] md:text-[18px] text-[16px] py-4 font-bold Poppins">
                                            4. Position your company as a
                                            trusted and influential industry
                                            leader.
                                        </p>
                                    </div>
                                    <div className="flex p-3 items-center gap-4 w-full">
                                        <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                            <IoMdCheckmark
                                                color="#B5E2FF"
                                                size={24}
                                            />
                                        </div>
                                        <p className="lg:text-[20px] md:text-[18px] text-[16px] py-4 font-bold Poppins">
                                            5. Spotlight Your Solutions:
                                            Showcase Your Services on the Event
                                            Stage.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute -bottom-32 -right-32 lg:block hidden">
                            <svg
                                width="258"
                                height="258"
                                viewBox="0 0 258 258"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M258 129C258 200.245 200.245 258 129 258C57.7553 258 0 200.245 0 129C0 57.7553 57.7553 0 129 0C200.245 0 258 57.7553 258 129Z"
                                    fill="#FFFAEF"
                                />
                            </svg>
                        </div>
                        <div className="absolute lg:block hidden -left-20 -bottom-32">
                            <svg
                                width="112"
                                height="112"
                                viewBox="0 0 112 112"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_30_843)">
                                    <path
                                        d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
                                        fill="#B5E2FF"
                                    ></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_30_843">
                                        <rect
                                            width="102.698"
                                            height="102.698"
                                            fill="white"
                                            transform="translate(0 102.315) rotate(-85.0461)"
                                        ></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="h-screen -mt-36 event_testinomails">
                    <EventSubscribers />
                </div>
                <div className="mt-28 -mb-36 container">
                    <EventsFooter />
                </div>
            </>
        );
    }
}

export default Events;
