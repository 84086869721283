import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react"; // Import only what's needed
import "../../../assets/css/semantic.css";
import Profilesidebar from "../../../components/profile/profilesidebar";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import BUSINESS_CATEGORIES from "../../../graphql/queries/businessCategories";
import UPDATE_CATEGORY_MUTATION from "../../../graphql/mutations/updateCategoriesMutation";
import AuthUserAction from "../../../store/actions/authUserAction";
import { IsLoader, Noconnection } from "components";
import { IoIosCloseCircle } from "react-icons/io"; // Import only what's needed
import { extractValidationErrors } from "helpers/extractValidationErrors";

function CategoryExpertise(props) {
  const { authUser } = props;

  const [search, setSearch] = useState(null);
  const [selected, setselected] = useState([]);
  const [disable, setdisable] = useState(true);

  // const variables = search?.length > 3 ? { search } : {};
  const variables = {};

  useEffect(() => {
    setdisable(selected?.length === 0);
  }, [selected]);

  const { loading, error, data } = useQuery(BUSINESS_CATEGORIES, {
    variables,
  });

  const [addCategories] = useMutation(UPDATE_CATEGORY_MUTATION, {
    onCompleted(data) {
      setdisable(true);
      setselected([]);
      AuthUserAction.getAuthUserInformation();
    },
  });
  const selectCategories = (_, data) => {
    setdisable(false);
    setselected(data.value);
  };

  const handleRemoveCategory = (value) => {
    setSearch(value);
  };

  if (loading) return <IsLoader className="mx-auto h-28 w-28" />;
  if (error) {
    extractValidationErrors(error);
    return <Noconnection />;
  }

  const authUserCategories = authUser?.categories || [];
  const filteredKeys = authUserCategories?.map((cate) => cate?.id);

  const options = data?.businessCategories?.data
  .filter(category => !filteredKeys.includes(category.id))
  .map((category, index) => ({
    key: index,
    text: category.name,
    value: category.id,
  })) || [];

  return (
    <div className="container">
      <div className="grid lg:grid-cols-7 gap-4">
        <Profilesidebar authUser={authUser} />
        <div className="lg:col-span-5">
          <div className="p-3 mb-3 border rounded-xl lg:p-8">
            <h3 className="Medium text-center text-[22px]">
              Category Expertise
            </h3>
            <div className="my-4" />
            <div>
              <Dropdown
                className="border gray Regular rounded--xl"
                placeholder="Category Expertise"
                fluid
                multiple
                search={true}
                selection
                value={selected}
                options={options}
                onChange={selectCategories}
                onSearchChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              <div className="my-4" />
              {!disable && (
                <button
                  onClick={
                    () =>
                      addCategories({ variables: { categories: selected.map((category) => parseInt(category, 10)), action: "update" } })
                  }
                  type="submit"
                  className="flex items-center justify-center w-full mx-auto border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-11 rounded--xl lg:w-1/2"
                >
                  Save
                </button>
              )}
            </div>
            <div className="my-4" />
            <div className="flex flex-wrap items-center gap-3 mt-4">
              {authUser?.categories?.map((category) => (
                <div className="flex items-center px-3 border-2 rounded-full border--primary h-11" key={category.id}>
                  <p className="Regular">{category.name}</p>
                  <IoIosCloseCircle className="ml-2 cursor-pointer primary" size={22} onClick={() =>
                    addCategories({ variables: { categories: parseInt([category.id]), action: "delete" } })
                  } />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(CategoryExpertise);
