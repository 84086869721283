import { gql } from "@apollo/client";

export default gql`
    mutation add_google_pay_payment(
        $coupon_code: String
        $card_type: String!
        $package_id: Int
        $course_id: Int
    ) {
        add_google_pay_payment(
            coupon_code:$coupon_code
            package_id: $package_id
            course_id: $course_id
            card_type: $card_type
        ) {
            status
            error_code
            error_description
            transaction_status
            validation_status
            transaction_type
            method
            amount
            currency
        }
    }
`;
