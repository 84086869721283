import React, { useState, useEffect, useRef } from "react";
import { Buttonlink, IsLoader, Signinpopup } from "components";
import { useHistory, useParams } from "react-router-dom";
import decryptUrl from "helpers/Decrypt";
import axios from "axios";
import moment from "moment";
import { BsArrowRight } from "react-icons/bs";
import JobFormScreen from "navigation/JobFormScreen";
import Auth from "../../Auth";
import Api from "services/api";
import logo from "assets/images/pllogo.png";
import save from "assets/images/save.svg";
import saved from "assets/images/saved.svg";
import { FaEye, FaLinkedin } from "react-icons/fa";
import { MdOutlineTimer } from "react-icons/md";

const Jobslisting = () => {
    const auth = new Auth();
    const history = useHistory()
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const decryptId = decryptUrl(id);
    const modalRef = useRef(null);
    const isLogin = auth.isAuthenticated();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchJob(decryptId);
    }, [decryptId]);

    const setApply = () => {
        setData({...data, applied: true, applied_candidates: [{status: 0}]})
    }

    const openModal = (data) => {
        modalRef.current.open(data, setApply);
    }

    const fetchJob = async (id) => {
        const response = await Api.fetchJobDetails(id);
        if (response?.status === 200) {
            setData({...response?.data?.job, save: response?.data?.job?.saveStatus});
            setLoading(false);
        }
    }; 

    const changeJobStatus = async () => {
        const response = await Api.changeSaveStatus({job_id: data?.id, save: data?.save ? 0: 1});
        if (response?.status === 200) {
            setData({...data, save: data?.save ? 0: 1 });
            setLoading(false);
        }
    }; 
    
    let descriptionWithLineBreaks = '';
    if (data?.description) {
        descriptionWithLineBreaks =  data?.description?.replace(/\n/g, '<br />');
    }

    const currentStatus = data?.applied_candidates?.[0]?.status;

    return (
        <>
            <div className="bg-[#EAF2F9] -mt-14 pb-28">
                <div className="h-auto py-14 container w-[100%] grid grid-cols-12 relative ">
                    <div className="lg:col-span-7 col-span-12">
                        <Buttonlink
                            Text={"<< BACK TO JOB CENTRAL"}
                            onClick={() => history.push('/job-central')}
                            className="primary uppercase text-[18px] leading-[26px]"
                        />
                        <h1 className="text-[50px] leading-8 text-new-color lg:text-5xl my-2 font-light text-4xl Poppins">
                            Job Central
                        </h1>
                        <p className="text-[18px] leading-[25px] ">
                            Unlock Your Next Career Move: Explore Opportunities
                            on Job Central Now!
                        </p>
                    </div>

                    <div className="right-0 absolute lg:block hidden">
                        <svg
                            width="265"
                            height="265"
                            viewBox="0 0 265 265"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_3715_6603)">
                                <path
                                    d="M192.254 79.2798L143.884 101.56L121.604 53.19C116.034 41.0975 102.787 36.2056 90.6941 41.7757C78.6015 47.3458 73.7097 60.5928 79.2798 72.6854L101.56 121.056L53.19 143.336C41.0975 148.906 36.2057 162.153 41.7758 174.246C47.3459 186.338 60.5929 191.23 72.6854 185.66L121.056 163.379L143.336 211.75C148.906 223.842 162.153 228.734 174.246 223.164C186.338 217.594 191.23 204.347 185.66 192.254L163.379 143.884L211.75 121.604C223.842 116.034 228.734 102.787 223.164 90.694C217.594 78.6015 204.347 73.7097 192.254 79.2798Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_3715_6603">
                                    <rect
                                        width="199.706"
                                        height="199.706"
                                        fill="white"
                                        transform="translate(0 83.5515) rotate(-24.7319)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            {loading ? (
                <IsLoader className="w-32 h-32 mx-auto" />
            ) : (
                <>
                    <div className="container">
                        <div className="bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl -mt-20 lg:p-10 p-5 flex lg:flex-nowrap flex-wrap items-start gap-5">
                            <img src={data?.companies?.logo_url || logo} className="w-32 h-32 flex-shrink-0 rounded-full " />
                            <div className="w-full">
                                <div className="flex justify-between items-start w-full">
                                    <div>
                                        <h1 className="text-3xl Poppins font-semibold text-new-color">
                                            {data?.title}
                                        </h1>
                                        <p className="text-lg Poppins text-new-color font-normal pt-2">
                                            Posted{" "}
                                            {moment(
                                                data?.start_date,
                                                "YYYY-MM-DD HH:mm:ss"
                                            ).fromNow()}{" "}
                                            in {data?.city},{data?.country}
                                        </p>
                                    </div>
                                    {isLogin && 
                                    <img className="cursor-pointer w-10" src={data?.save ? saved: save} onClick={() => changeJobStatus()} />
                                    }
                                </div>
                                <div className="pt-3">
                                    <h1 className="text-2xl Poppins font-semibold text-new-color">
                                        Job Detail
                                    </h1>
                                    <div className="flex justify-between mt-3 items-center flex-wrap gap-5">
                                        <div>
                                            <h6 className="text-lg Poppins font-normal text-new-color">
                                                Employment Type
                                            </h6>
                                            <p className="text-lg Poppins text-[#26a9e0] font-normal">
                                                {data?.employment?.name}
                                            </p>
                                        </div>
                                        <div>
                                            <h6 className="text-lg Poppins font-normal text-new-color">
                                                Job Location
                                            </h6>
                                            <p className="text-lg Poppins font-normal text-[#26a9e0]">
                                                {data?.city}, {data?.country}
                                            </p>
                                        </div>
                                        {data?.seniority?.seniority_name && 
                                            <div>
                                                <h6 className="text-lg Poppins font-normal text-new-color">
                                                    Job Seniority
                                                </h6>
                                                <p className="text-lg Poppins text-[#26a9e0] font-normal">
                                                    {data?.seniority?.seniority_name}
                                                </p>
                                            </div>
                                        }
                                        <div>
                                            <h6 className="text-lg Poppins font-normal text-new-color">
                                                Education Level
                                            </h6>
                                            <p className="text-lg Poppins text-[#26a9e0] font-normal">
                                                {data?.education?.education_level}
                                            </p>
                                        </div>
                                        {data?.external_link && 
                                            <div>
                                                <p onClick={() => window.open(data?.external_link, '_blank')} className="text-lg cursor-pointer Poppins text-[#26a9e0] font-normal">
                                                    <FaLinkedin size={35} />
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    <div className="mt-5">
                                        <Buttonlink
                                            Lasticon={
                                                currentStatus === 0 ? <MdOutlineTimer size={20} /> :currentStatus === 1 || currentStatus === 2 ? <FaEye size={22} /> :  <BsArrowRight size={22} />
                                            }
                                            onClick={() => openModal(data)}
                                            Text={currentStatus === 0 ? "Pending" :currentStatus === 1 ? "Accepted" : currentStatus === 2 ? "Rejected" :  "Apply"}
                                            className="bg--primary hover:text-white items-center px-5 justify-between w-full max-w-[180px] flex rounded-full  text-lg py-2 text-white"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container grid grid-cols-12 gap-5 mt-10">
                        <div className="lg:col-span-6 col-span-12 shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl lg:p-10 p-5">
                            <div className="flex flex-col gap-3">
                                {/* <img
                                    src={data?.external_companies?.logo}
                                    alt={data?.external_companies?.title}
                                /> */}
                                <h1 className="text-3xl Poppins font-semibold text-new-color">
                                    {data?.companies?.title || data?.external_companies?.title}
                                </h1>
                                <div  className="text-lg Poppins text-new-color font-normal"
                                    dangerouslySetInnerHTML={{ __html: data?.companies?.history || data?.external_companies?.description }} 
                                 />
                            </div>
                        </div>
                        <div className="lg:col-span-6 col-span-12 shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl lg:p-10 p-5">
                            <h6 className="text-3xl Poppins font-semibold text-new-color">
                                About
                            </h6>
                            <p
                                className="text-lg Poppins space-y-3 handleDescription text-new-color font-normal pt-2"
                                dangerouslySetInnerHTML={{ __html: descriptionWithLineBreaks }}
                            ></p>
                        </div>
                    </div>
                </>
            )}
            <JobFormScreen ref={modalRef} setApply={setApply} />
            {
                open &&
                <Signinpopup open={open} closePopup={() => setOpen(false)} />
            }
        </>
    );
};

export default Jobslisting;
