import React, { Component } from "react";
import Slider from "react-slick";
import {BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { ClassCard } from "components";
import Auth from "../../../../Auth";
const auth = new Auth();

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 top-[40%] z-10 flex items-center justify-center cursor-pointer slick-arrows next"
      onClick={onClick}>
      <BsArrowRight size={30}  strokeWidth={1.2}className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 top-[40%] left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
      onClick={onClick}>
      <BsArrowLeft size={30} strokeWidth={1.2} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

class classesslider extends Component {
  constructor(props) {
    super(props);
    this.openSignIn = React.createRef();
    this.state = {};
  }


  openPopup = () => {
    this.openSignIn.current.openPopup();
  };

  render() {

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 624,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    const { classes, type } = this.props;
    // console.log("mila",type)
    return (
      <>

        <div className="relative blog-posts slider-left equal-height">
          <Slider {...settings} className="important:static mt-6 lg:px-10 px-[14px]">
            {classes.map((_class) => (
              <div className="h-full p-5 pt-3">
                <ClassCard _class={_class} type={type} key={_class.id}/>
              </div>
            ))}
          </Slider>
          <div className="flex justify-end mt-4 ">
            {classes.length <= 2 ? (
              ""
            ) : (
              <Link
                to={{
                  pathname: `/search/${type.toLowerCase()}/all`,
                  state: {
                    type: type,

                  },
                }}
                className="flex items-center black Poppins">
                {" "}
                Load more <IoIosArrowForward className="ml-2" />{" "}
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default classesslider;
