import { gql } from "@apollo/client";

export default gql`
  mutation update_knowledge_bank_like($id: Int) {
    updateKnowledgeBankVote(knowledge_bank_id:$id){
        id
        is_paid
        additional_votes
        check_for_vote
    }
  }
`;
