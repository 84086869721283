import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { FaQuoteRight } from "react-icons/fa";
import { connect } from "react-redux";
import { ProfileAvatar } from "components";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer top-14 md:top-0 slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 top-14 md:top-0 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const settings = {
      dots: false,
      className: "center",
      centerMode: false,
      infinite: false,
      centerPadding: "60px",
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    const { allCoursesReviews } = this.props;

    return (
      <div className="relative">
        <h3 className="mb-20 text-3xl Medium md:mb-10">
          Subscribers are raving!
        </h3>
        <Slider {...settings} className="important:static">
          {allCoursesReviews.map((data) => (
            <div className="lg:pr-20" key={data.toString()}>
              <div className="flex gap-3">
                <FaQuoteRight />
                <div>
                  <p>{data.review}</p>
                  <div className="flex items-center mt-3">
                    <ProfileAvatar
                        platform={"PL"}
                      singleUser={data.user}
                      className="object-cover mr-3 rounded-full h-11 w-11"
                    />
                    <div>
                      <p className="Medium fs-14">
                        {" "}
                        {data.user.firstname + " " + data.user.lastname}
                      </p>
                      <p className="gray fs-12 Light"> @{data.user.username}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allCoursesReviews: state.CourseReviewsReducer.allCoursesReviews,
});

export default connect(mapStateToProps, null)(testimonials);
