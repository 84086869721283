import { gql } from "@apollo/client";

export default gql`
  mutation questionAbuseReport(
      $title: String! 
      $question_id: Int! 
      $tag: String!
  ) {
    questionAbuseReport(title: $title question_id: $question_id tag: $tag){
        error
    	message
    }
  }
`;
