import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import BUSINESS_CATEGORIES from "../../graphql/queries/businessCategories";
import { IsLoader, Noconnection } from "../index";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/semantic.css";
import * as PropTypes from "prop-types";
import { extractValidationErrors } from "helpers/extractValidationErrors";

CategoryDropDown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default function CategoryDropDown(props) {
  const [search, setSearch] = useState("");
  let timeoutValue = 0;
  let variables = {};
  if (search !== "" && search.length > 3) {
    variables.search = search;
  }

  useEffect(() => {
    if (props?.selected) {
      props.callback(props?.selected)
    }
  }, [props.edit]);


  const { loading, error, data } = useQuery(BUSINESS_CATEGORIES, {
    variables,
  });

  if (loading) return <IsLoader className="w-12 h-12 mx-auto" />;
  if (error) {
    extractValidationErrors(error);
    return <Noconnection />
  };

  let options = [];
  props?.categories.forEach((e, index) => {
    options.push({ key: index, text: e.name, value: e.id });
  });
  /*data?.businessCategories?.data.forEach(e => {
        options.push({ key: e.id, text: e.name, value: e.id });
    });*/

  function selectCategoroes(event, data) {
    props.callback(data.value);
  }

  function searchCategoroes(event, data) {
    clearTimeout(timeoutValue);
    timeoutValue = setTimeout(() => {
      setSearch(data);
    }, 500);
  }
  return (
    <div>
      <Dropdown
        className={props.className}
        placeholder={props?.placeholder}
        clearable
        fluid
        multiple
        search={(options, data) => {
          searchCategoroes(options, data);
        }}
        selection
        value={props?.selected}
        options={options}
        onChange={(event, data) => {
          selectCategoroes(event, data);
        }}
      />
    </div>
  );
}
