import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
} from "@apollo/client";
import cookie from "js-cookie";
import { GRAPH_USER_AUTH_TOKEN } from "../config/constants";

let token = cookie.get(GRAPH_USER_AUTH_TOKEN);

let accessToken;

(async () => {
  // eslint-disable-next-line no-param-reassign
  accessToken = token || (await cookie.get(GRAPH_USER_AUTH_TOKEN));
})();

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPH_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {
    'X-Requested-With': 'XMLHttpRequest'
  } }) => ({
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),

});

export default client;
