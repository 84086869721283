import { gql } from "@apollo/client";

export default gql`
  query Auth_User_Info {
    me {
      id
      username
      firstname
      lastname
      is_pro
      cross_token
      is_active
      is_subscribe
      sg_badge
      sg_paid_user
      is_authorized
      profile_photo
      tagline
      email
      address
      country
      timezone
      description
      timestamp
      linkedin_profile
      tagline
      address
      notice_period
      phone
      color
      is_follower
      last_login
      open_notification_count
      subscriptionsPurchased(first: 20) {
        data {
          id
          user_id
          contest_id
          billing_id
          total_payment
          user_account_id
          status
          short_message
          long_message
          currency_code
          invoice_num
          email_address
          step
          created_at
          plan {
            name
            validity
          }
        }
        paginatorInfo {
          total
        }
      }
      productPurchased(first: 20) {
        data {
          id
          user_id
          contest_id
          billing_id
          total_payment
          user_account_id
          status
          short_message
          long_message
          currency_code
          invoice_num
          email_address
          step
          created_at
          course {
            course_name
          }
        }
        paginatorInfo {
          total
        }
      }
      categories {
        id
        name
        code
      }
      followers(first: 1) {
        paginatorInfo {
          total
        }
      }
      companies(first: 10) {
        data {
          id
          title
          address
          city
          state
          country
          history
          postal_code
          linked_in_profile
          is_active
        }
        paginatorInfo {
          total
        }
      }
      questions(first: 1) {
        paginatorInfo {
          total
        }
      }
      answers(first: 1) {
        paginatorInfo {
          total
        }
      }
    }
  }
`;
