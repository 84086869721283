import { gql } from "@apollo/client";

export default gql`
  query blogs {
    blogs(first: 10){
        data{
            id
            title
            slug
            content
            user_id
            is_active
            created_at
            updated_at
            categories{
                name
                slug
            }
        }
    }
  }
`;
