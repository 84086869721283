import React, { Component } from "react";
import Slider from "react-slick";
import { GrLinkedinOption } from "react-icons/gr";
import { ImFacebook } from "react-icons/im";
import company from "assets/images/company.png";
import { AiOutlineTwitter } from "react-icons/ai";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 z-10 flex items-center justify-center h-12 px-4 cursor-pointer -top-16 rounded-xl shadow--sm hover:bg--primary hover:color-white-all"
      onClick={onClick}>
      <p>Next Solution</p>
      <FiChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute left-0 z-10 flex items-center justify-center h-12 px-4 cursor-pointer -top-16 rounded-xl shadow--sm hover:bg--primary hover:color-white-all"
      onClick={onClick}>
      <FiChevronLeft />
      <p className="ml-2">Prev Solution</p>
    </div>
  );
}

class companyslick extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            nextArrow: false,
            prevArrow: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts">
        <Slider {...settings} className="important:static">
          <div className="p-2 pt-3">
            <div className="items-center md:flex">
              <div className="flex items-center justify-center mr-10 shadow--sm rounded-3xl h-44 w-96">
                <img
                  alt="Eka Software Solution"
                  src={company}
                  className="object-contain w-40 "
                />
              </div>
              <div className="mt-5 md:mt-0 ">
                <h4 className="mb-4 text-4xl Medium">Eka Software Solution</h4>
                <div className="flex items-center gap-3">
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 cursor-pointer rounded-xl">
                    <GrLinkedinOption className="primary" size={22} />
                  </div>
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 cursor-pointer rounded-xl">
                    <ImFacebook className="primary" size={22} />
                  </div>
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 cursor-pointer rounded-xl">
                    <AiOutlineTwitter className="primary" size={22} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <p className="leading-normal text-color">
                Eka is a global leader in providing digital commodity management
                solutions driven by Cloud, Blockchain, Machine Learning and
                Analytics. The company’s best-of-breed solutions serve the
                entire trading value chain across agriculture, energy, metals
                and mining and manufacturing markets. Eka is reimagining
                commodity management with a new age architecture that breaks
                monolithic ETRM and CTRM systems into bite-sized chunks of
                powerful, enterprise-grade apps, thereby enabling faster
                implementation, adoption and decision making. Eka’s
                mobile-first, enterprise apps are embedded with
                commodity-specific algorithms that empower businesses to stay on
                top of volatile markets by letting them scale at will, go live
                faster, and achieve better value for their investments. Eka has
                offices across the Americas, Asia, Australia, and EMEA serving
                100+ customers globally across multiple commodity segments. For
                more information, visit https://www.ekaplus.com
              </p>
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="items-center md:flex">
              <div className="flex items-center justify-center mr-10 shadow--sm rounded-3xl h-44 w-96">
                <img
                  alt="Eka Software Solution"
                  src={company}
                  className="object-contain w-40 "
                />
              </div>
              <div className="mt-5 md:mt-0">
                <h4 className="mb-4 text-4xl Medium">Eka Software Solution</h4>
                <div className="flex items-center gap-3">
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 cursor-pointer rounded-xl">
                    <GrLinkedinOption className="primary" size={22} />
                  </div>
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 cursor-pointer rounded-xl">
                    <ImFacebook className="primary" size={22} />
                  </div>
                  <div className="flex items-center justify-center w-12 h-12 bg-gray-100 cursor-pointer rounded-xl">
                    <AiOutlineTwitter className="primary" size={22} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <p className="leading-normal text-color">
                Eka is a global leader in providing digital commodity management
                solutions driven by Cloud, Blockchain, Machine Learning and
                Analytics. The company’s best-of-breed solutions serve the
                entire trading value chain across agriculture, energy, metals
                and mining and manufacturing markets. Eka is reimagining
                commodity management with a new age architecture that breaks
                monolithic ETRM and CTRM systems into bite-sized chunks of
                powerful, enterprise-grade apps, thereby enabling faster
                implementation, adoption and decision making. Eka’s
                mobile-first, enterprise apps are embedded with
                commodity-specific algorithms that empower businesses to stay on
                top of volatile markets by letting them scale at will, go live
                faster, and achieve better value for their investments. Eka has
                offices across the Americas, Asia, Australia, and EMEA serving
                100+ customers globally across multiple commodity segments. For
                more information, visit https://www.ekaplus.com
              </p>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default companyslick;
