import { gql } from "@apollo/client";

export default gql`
  query sgProductByID($id: ID!) {
    sgProductByID(id: $id) {
      id
      name
      slug
      description
      is_active
      in_stock
      attachment
      type
      product_like_count
      categories {
        id
        name
      }
      product_actions {
          id
          liked
          user_id
          product_like
          book_mark
          visit
          product_id
          created_at
      }
      attachments{
        id
        imageable_type
        path
        type
        mime
        size
      }
      user{
        id
        email
      }
    }
  }
`;
