import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { RiDoubleQuotesR } from "react-icons/ri";
import Joanna from "assets/images/Joanna.webp";
import Maciej from "assets/images/subscribers/Maciej.webp";
import Kait from "assets/images/subscribers/Kait.webp";
import Nitin from "assets/images/subscribers/Nitin.webp";
import Anish from "assets/images/subscribers/Anish.webp";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer -bottom-8 slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 -bottom-8 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class Testimonialsclients extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      customPaging: function (i) {
        let index = i;
        let userImage = null;
        let username = "";
        if (index === 0) {
          userImage = Joanna;
          username = "Joanna";
        }
        if (index === 1) {
          userImage = Maciej;
          username = "Maciej";
        }
        if (index === 2) {
          userImage = Kait;
          username = "Kait";
        }
        if (index === 3) {
          userImage = Nitin;
          username = "Nitin";
        }
        if (index === 4) {
          userImage = Anish;
          username = "Anish";
        }
        return (
          <div className="flex items-center">
            <img
              alt={username}
              src={userImage}
              className="object-cover w-full h-full rounded-full"
            />
            <p className="hidden ml-2">{username}</p>
          </div>
        );
      },
      dotsClass: "slick-dots slick-thumb slick-dots-avatar ",
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            centerMode: false,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            dots: false,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            dots: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts slider-left clients-testimonials equal-height">
        <div className="mb-10">
          <h4 className="text-3xl text-center Medium">
            Testimonials from clients
          </h4>
          <p className="text-center gray">What they say about us</p>
        </div>

        <Slider {...settings} className="ml-0 important:static">
          <div className="h-full p-3">
            <div className="relative flex w-full h-full p-4 bg-gray-200 rounded-xl">
              <div className="hidden lg:block">
                <RiDoubleQuotesR size={22} />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 lg:block" />
              <p>
                On behalf of our entire organization HPMF, I thanks again for
                taking the E- Learning to a whole new level of education and
                professionalism. Fascinating and engaging learning sessions, A
                wealth of specific subject related information, and a compelling
                presenter. ProcureClass is not just the best thing to have
                happened to me but to many Procurement Professionals like me,
                who were looking for educating themselves in the comfort of
                their own homes!! This collaboration between Procurement League
                and HPMF has been the best tie up we have had in last 10 years!
              </p>
            </div>
          </div>
          <div className="h-full p-3">
            <div className="relative flex w-full h-full p-4 bg-gray-200 rounded-xl">
              <div className="hidden lg:block">
                <RiDoubleQuotesR size={22} />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 lg:block" />
              <p>
                Was great to work with Procurement League in 2021 and the wider
                procurement network. I’m grateful to have led the Development
                League with Rajiv Gupta and Hamza Kamal and for the opportunity
                to mentor Helen Melian.
              </p>
            </div>
          </div>
          <div className="h-full p-3">
            <div className="relative flex w-full h-full p-4 bg-gray-200 rounded-xl">
              <div className="hidden lg:block">
                <RiDoubleQuotesR size={22} />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 lg:block" />
              <p>
                Many. Many. So many thank you ! You did so much for the event,
                in such a transparent way and involving so much time that I
                can’t imagine a better Partner. Thank you so much! Kudos all the
                way & it was a pleasure.
              </p>
            </div>
          </div>

          <div className="h-full p-3">
            <div className="relative flex w-full h-full p-4 bg-gray-200 rounded-xl">
              <div className="hidden lg:block">
                <RiDoubleQuotesR size={22} />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 lg:block" />
              <p>
                Thank you so much for being a sponsor of Optimal. It was a huge
                success and we are thrilled with the outcome. The procurement
                community heard from Best of Breed startups, thought leaders,
                innovators, and customers using AI in procurement.
              </p>
            </div>
          </div>

          <div className="h-full p-3">
            <div className="relative flex w-full h-full p-4 bg-gray-200 rounded-xl">
              <div className="hidden lg:block">
                <RiDoubleQuotesR size={22} />
              </div>
              <div className="hidden w-1 mx-3 bg-gray-700 rounded-full h-18 lg:block" />
              <p>
                Tell me and I forget. Teach me and I remember. Involve me and I
                learn. (Benjamin Franklin). Procure Class lives unto this
                standard by teaching a subject of importance with interaction
                from subject professionals. Overall a great interactive session
                and learnt a lot.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default Testimonialsclients;
