import React from "react";
import {Route} from "react-router-dom";
import Auth from "./Auth";
import { Signinpopup } from "components";
const auth = new Auth();

const ProtectedRouteModalBased = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                auth.isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <>
                        <Component {...props}/>
                        <Signinpopup open={true} protect={true} />
                    </>
                )
            }
        />
    );
};

export default ProtectedRouteModalBased;
