import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";

function UseInput(props) {
  const [search, setSearch] = useState("");

  function CapitalizeFirstLetter(str) {
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  }

  function setValue(e) {
    setSearch(e.target.value);

    if (props.title === "Select Member") {
      props.handleSearch(search);
    } else {
      props.handleSearch(CapitalizeFirstLetter(search));
    }
    props._handleUserChange(e.target.value);
  }

  // console.log(props.title)
  return (
    <input
      placeholder={props.title}
      className="w-full h-12 px-3 mb-3 bg-gray-200 border-0 rounded-lg Regular"
      onChange={setValue}
      value={search}
    />
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSearch: (search) => dispatch({ type: "TYPE_SEARCH", payload: search }),
  };
};

export default connect(null, mapDispatchToProps)(UseInput);
