import { gql } from "@apollo/client";

export default gql`
  query getAllCaseStudies {
    getAllCaseStudies {
      id
      title
      sub_title
      conclusion
      created_at
      updated_at
      sections {
        id
        case_study_id
        section_name
        section_content
        images {
          path
          imageable_id
          imageable_type
          type
          mime
          size
        }
      }
    }
  }
`;
