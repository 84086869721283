import React, { useEffect } from "react";
import Learn from "../../assets/images/buyer/Learn.png";
import BuyersLearn from "../../assets/images/buyer/BuyersLearn.webp";
import Masterclass from "../../assets/images/buyer/Masterclass.png";
import Module from "../../assets/images/buyer/Module.png";
import training from "../../assets/images/buyer/training.png";
import { Link } from "react-router-dom";
import { Knowledgepocket } from "components";

const BuyerLearnPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="bg--lightBlue pt-10 -mt-[50px]">
            <div className="container">
                <div className="flex gap-1 items-center">
                    <img
                        src={Learn}
                        alt="Learn"
                        className="w-[45px] h-[25px]"
                    />
                    <p className="font-normal Poppins text-lg primary">
                        Buyer Learn
                    </p>
                </div>
                <div className="grid mt-3 lg:grid-cols-2 grid-cols-1">
                    <div>
                        <h1 className="lg:text-5xl text-4xl text-new-color Poppins font-light">
                            Unlock Limitless Learning: Discover Masterclasses,
                            Modules, and Certifications for Skill Enhancement
                        </h1>
                        <div className="h-px bg--gray w-full my-8" />
                        <p className="text-lg Poppins text-new-color font-normal">
                            Take the first step towards personal and
                            professional growth by joining our vibrant community
                            of learners today
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <img
                            src={BuyersLearn}
                            alt="BuyersLearn"
                            className="h-[318px]"
                        />
                    </div>
                </div>
                <div className="mt-28">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-5 gap-y-16">
                        <div className="bg-white rounded-3xl shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="absolute w-[56px] h-[56px] inset-x-0 -top-10 mx-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="56"
                                    height="48"
                                    viewBox="0 0 56 48"
                                    fill="none"
                                >
                                    <path
                                        d="M28 0L55.7128 48H0.287188L28 0Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <img src={Masterclass} alt="Masterclass" />
                            <h1 className="Poppins text-3xl mt-5 primary font-light">
                                Master Class
                            </h1>
                            <p className="Poppins text-lg text-new-color font-normal my-5">
                                Learn from industry leaders and experts. Gain invaluable insights and practical strategies through immersive sessions. Enhance your professional skills with engaging, thought-provoking content across diverse topics.
                            </p>
                            <p className="Poppins text-lg text-new-color font-normal">
                                Apply cutting-edge knowledge to real-world challenges and accelerate your career growth.
                            </p>
                            <Link
                                to={"/classes"}
                                className="Poppins text-lg primary mt-3 font-normal"
                            >
                                Explore Masterclass {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="absolute w-[56px] h-[56px] inset-x-0 -top-10 mx-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="56"
                                    height="48"
                                    viewBox="0 0 56 48"
                                    fill="none"
                                >
                                    <path
                                        d="M28 0L55.7128 48H0.287188L28 0Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <img src={Module} alt="Module" />
                            <h1 className="Poppins text-3xl mt-5 primary font-light">
                                Modules
                            </h1>
                            <p className="Poppins text-lg text-new-color font-normal my-5">
                                Expand your expertise with our comprehensive Module-Based Learning. Our curated class bundles offer in-depth, multi-disciplinary knowledge to broaden your professional horizons.
                            </p>
                            <p className="Poppins text-lg text-new-color font-normal">
                                Unlock your potential through flexible, expert-designed course sequences.
                            </p>
                            <Link
                                to={"/module"}
                                className="Poppins text-lg primary mt-3 font-normal"
                            >
                                Discover Modules {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl shadow-[0px_4px_40px_#0000001a] relative p-8">
                            <div className="absolute w-[56px] h-[56px] inset-x-0 -top-10 mx-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="56"
                                    height="48"
                                    viewBox="0 0 56 48"
                                    fill="none"
                                >
                                    <path
                                        d="M28 0L55.7128 48H0.287188L28 0Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <img src={training} alt="training" />
                            <h1 className="Poppins mt-5 text-3xl primary font-light">
                                Training & Certification
                            </h1>
                            <p className="Poppins text-lg text-new-color font-normal my-5">
                                Tailored programs for company need and industry certifications. Gain practical skills and cutting-edge knowledge to excel in your field. We offer individual and team mentorships, providing comprehensive professional development with expert guidance.
                            </p>
                            <p className="Poppins text-lg text-new-color font-normal">
                                Our one-stop solution for the holistic development of procurement professionals.
                            </p>
                            <Link
                                to={"/training-certificate"}
                                className="Poppins text-lg primary mt-3 font-normal"
                            >
                                Book Your Slot {">>"}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="mt-28 -mb-36">
                    <Knowledgepocket isImageHide={true} />
                </div>
            </div>
        </div>
    );
};

export default BuyerLearnPage;
