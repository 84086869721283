import { gql } from "@apollo/client";

export default gql`
  mutation create_campaign($name: String, $email: String!) {
    create_campaign(name: $name, email: $email) {
      id
      name
      email
    }
  }
`;
