import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { Buttonlink } from "components";
import starMan from "assets/images/starMan.png";
import { Listbox, Transition, Switch } from "@headlessui/react";
import { IoMdArrowDropdown } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const people = [
  { name: "None" },
  { name: "Technology" },
  { name: "Healthcare" },
  { name: "Finance" },
  { name: "Retail" },
  { name: "Education" },
  { name: "Manufacturing" },
  { name: "Transportation" },
  { name: "Construction" },
  { name: "Agriculture" },
  { name: "Energy" },
  { name: "Legal" },
  { name: "Consulting" },
  { name: "Others" },
];

const SubmitFormPage = () => {

  const [formData, setFormData] = useState({
    email: '',
    nameSurname: '',
    phoneNumber: '',
    expertise: '',
    prevExperience: '',
    hourlyRate: '',
    portfolioWeb: '',
    comments: '',
  });

  const hiddenFileInput = useRef(null);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [file, setFile] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [selected, setSelected] = useState(people[0]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validateNameSurname = (nameSurname) => {
    return nameSurname.length >= 3 && nameSurname.length <= 20 && /^[a-zA-Z -]+$/.test(nameSurname);
  };
  const validateExpertise = (expertise) => {
    return expertise !== 'None';
  };
  const validatePrevExperience = (experience) => {
    return experience.length > 0 && experience.length <= 600;
  };
  const validateWebsite = (website) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(website);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Restrict input for phoneNumber to allow only '+' and integers
    if (name === 'phoneNumber' && !/^[\d+]*$/.test(value)) {
      return; // Do not update state if input is invalid
    }

    // Restrict input for nameSurname to disallow integers
    if (name === 'nameSurname' && /\d/.test(value)) {
      return; // Do not update state if input is invalid
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0].name);
  };

  const handleSubmit = () => {
    const validatePhoneNumber = (phoneNumber) => {
      const phoneRegex = /^\+?\d+$/; // Allows '+' followed by digits
      return phoneRegex.test(phoneNumber);
    };

    let newErrors = {}

    if (!validateNameSurname(formData.nameSurname)) {
      newErrors.nameSurname = 'Name must be 3-20 characters, alphabets, and hyphens only';
    } else {
      newErrors.nameSurname = null;
    }
    if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number';
    } else {
      newErrors.phoneNumber = null;
    }
    if (!validateExpertise(selected.name)) {
      newErrors.expertise = 'Please select an expertise';
    } else {
      newErrors.expertise = null;
    }
    if (!validatePrevExperience(formData.prevExperience)) {
      newErrors.prevExperience = 'Prev Experience must be 600 characters long and should not be empty';
    } else {
      newErrors.prevExperience = null;
    }
    if (dateRange[0] === null && dateRange[1] === null) {
      newErrors.dateRange = 'Please Select Date Range';
    } else {
      newErrors.dateRange = null;
    }
    const numberRegex = /^\d+(\.\d+)?$/;
    if (!numberRegex.test(formData.hourlyRate)) {
      newErrors.hourlyRate = 'Enter a valid numeric rate';
    } else {
      newErrors.hourlyRate = null;
    }
    if (!validateWebsite(formData.portfolioWeb)) {
      newErrors.portfolioWeb = 'Please enter a valid website URL';
    } else {
      newErrors.portfolioWeb = null;
    }
    if (!validatePrevExperience(formData.comments)) {
      newErrors.comments = 'Comments must be 100 characters long';
    } else {
      newErrors.comments = null;
    }
    if (!file) {
      newErrors.file = 'Please upload a file in PDF format, and the file size should not exceed 5MB.';
    } else {
      newErrors.file = null;
    }

    setErrors(newErrors);

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  console.log('errors', errors)
  return (
    <div className="container mt-[50px]">
      <div className="lg:grid xl:grid-cols-8 lg:grid-cols-5 lg:gap-4 relative">
        <div className="xl:col-start-3 xl:col-span-4 lg:col-start-2 lg:col-span-3">
          <div className="relative">
            <h1 className="text-[30px] sm:text-[50px] font-light text-center lg:text-[50px] Poppins">
              Expert Hiring Form
            </h1>
            <img
              src={starMan}
              alt="logo"
              className="absolute h-32 -top-6 -right-10 sm:-right-5 lg:-right-14"
            />
          </div>
          <form
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="mt-20 space-y-5">
              <div className="space-y-5">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address *
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    autoComplete="email"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="YOUR EMAIL ADDRESS *"
                  />
                  {errors.email && <p className="text-red-500">{errors.email}</p>}
                </div>
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Name Surname *
                  </label>
                  <input
                    id="name-surname"
                    name="nameSurname"
                    value={formData.nameSurname}
                    onChange={handleChange}
                    type="text"
                    maxLength={20}
                    autoComplete="namesurname"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="YOUR NAME SURNAME *"
                  />
                  {errors.nameSurname && <p className="text-red-500">{errors.nameSurname}</p>}
                </div>
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Phone Number
                  </label>
                  <input
                    id="phone-number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    maxLength={16}
                    type="tel"
                    autoComplete="phonenumber"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="YOUR PHONE NUMBER"
                  />
                  {errors.phoneNumber && <p className="text-red-500">{errors.phoneNumber}</p>}
                </div>
                <div>
                  <Listbox value={selected} onChange={setSelected}>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative  w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm text-left flex items-center justify-start gap-2">
                        <span
                          className={
                            selected.name === "None"
                              ? "text-[#b2b2b2]"
                              : "text-gray-900"
                          }
                        >
                          {selected.name === "None"
                            ? "EXPERTISE/INDUSTRY *"
                            : selected.name}
                        </span>
                        <IoMdArrowDropdown size={18} className="primary" />
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="!list-none !pl-3 absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {people.map((person, personIdx) => (
                            <Listbox.Option
                              key={personIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pr-4 text-gray-900`
                              }
                              value={person}
                            >
                              {({ selected }) => (
                                <>
                                  <span className={``}>{person.name}</span>
                                  {/* {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null} */}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                  {errors.expertise && <p className="text-red-500">{errors.expertise}</p>}
                </div>

                <div>
                  <label htmlFor="email-address" className="sr-only">
                    previous experience
                  </label>
                  <textarea
                    id="prev-experinece"
                    name="prevExperience"
                    value={formData.prevExperience}
                    onChange={handleChange}
                    maxLength={600}
                    type="text"
                    autoComplete="prevexperience"
                    className="relative block w-full h-40 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-xl bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="YOUR PREVIOUS EXPERIENCE *"
                  ></textarea>
                  {errors.prevExperience && <p className="text-red-500">{errors.prevExperience}</p>}
                </div>
                <div>
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    value={formData.dateRange}
                    endDate={endDate}
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    placeholderText={"AVAILABILITY (Start Date - End Date)"}
                    minDate={today} // Disable dates before today
                  />
                  {errors.dateRange && <p className="text-red-500">{errors.dateRange}</p>}
                </div>

                <div>
                  <label htmlFor="hourly-rate" className="sr-only">
                    Hourly Rate/Project Rate Expectation
                  </label>
                  <input
                    id="hourly-rate"
                    name="hourlyRate"
                    type="text"
                    value={formData.hourlyRate}
                    onChange={handleChange}
                    maxLength={5}
                    max={5}
                    autoComplete="hourly-rate"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="HOURLY RATE/ PROJECT RATE EXPECTATION:"
                  />
                  {errors.hourlyRate && <p className="text-red-500">{errors.hourlyRate}</p>}
                </div>
                <div>
                  <label htmlFor="portfolio-web" className="sr-only">
                    Portfolio Website
                  </label>
                  <input
                    id="portfolio-web"
                    name="portfolioWeb"
                    value={formData.portfolioWeb}
                    onChange={handleChange}
                    type="text"
                    autoComplete="portfolio-web"
                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="PORTFOLIO WEBSITE (if available):"
                  />
                  {errors.portfolioWeb && <p className="text-red-500">{errors.portfolioWeb}</p>}
                </div>
                <div>
                  <label htmlFor="comments" className="sr-only">
                    Comments
                  </label>
                  <textarea
                    id="comments"
                    name="comments"
                    type="text"
                    autoComplete="comments"
                    value={formData.comments}
                    maxLength={100}
                    onChange={handleChange}
                    className="relative block w-full h-40 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-xl bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ADDITIONAL COMMENTS:"
                  ></textarea>
                  {errors.comments && <p className="text-red-500">{errors.comments}</p>}
                </div>
                <div>
                  <button
                    type="button"
                    className="cursor pointer relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm uppercase text-left flex items-center justify-start gap-2"
                    onClick={handleClick}
                  >
                    <span className="text-[#b2b2b2]">UPLOAD RESUME /CV:</span>
                    <IoMdArrowDropdown size={18} className="primary" />
                    {file && <span>{file}</span>}
                  </button>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                    className="hidden"
                  />
                  {errors.file && <p className="text-red-500">{errors.file}</p>}
                </div>
              </div>

              <div className="flex justify-start gap-3">
                <div className="">
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={`${enabled ? "bg--primary" : "bg-gray-500"}
                    relative inline-flex h-[28px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${enabled ? "translate-x-7" : "translate-x-0"}
                      pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                <div className="gap-2 flex items-center ">
                  <span className="text-[#b2b2b2] text-[18px]">
                    Agree to our
                  </span>
                  <Link
                    to={"/term-of-services"}
                    className="primary flex-shrink-0 font-normal text-[18px] Poppins hover:opacity-70 underline underline-offset-2"
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
              <Buttonlink
                Text="SUBMIT"
                onClick={() => handleSubmit()}
                className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-12 w-full uppercase"
              />
            </div>
          </form>
        </div>
        <div className="absolute lg:-left-20 xl:left-0 2xl:left-0 top-96 lg:block hidden">
          <svg
            width="301"
            height="301"
            viewBox="0 0 301 301"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2426_4423)">
              <path
                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                fill="#EAF2F9"
              />
              <path
                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                fill="#FF6600"
              />
              <path
                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                fill="#EAF2F9"
              />
              <path
                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                fill="#EAF2F9"
              />
              <path
                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                fill="#EAF2F9"
              />
            </g>
            <defs>
              <clipPath id="clip0_2426_4423">
                <rect
                  width="212.39"
                  height="212.39"
                  fill="white"
                  transform="translate(0 150.182) rotate(-45)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="absolute bottom-72 lg:right-2 xl:right-20 lg:block hidden">
          <svg
            width="201"
            height="201"
            viewBox="0 0 301 301"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2426_4423)">
              <path
                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                fill="#EAF2F9"
              />
              <path
                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                fill="#FF6600"
              />
              <path
                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                fill="#EAF2F9"
              />
              <path
                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                fill="#EAF2F9"
              />
              <path
                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                fill="#EAF2F9"
              />
            </g>
            <defs>
              <clipPath id="clip0_2426_4423">
                <rect
                  width="212.39"
                  height="212.39"
                  fill="white"
                  transform="translate(0 150.182) rotate(-45)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="absolute bottom-2 right-24 lg:block hidden">
          <svg
            width="101"
            height="101"
            viewBox="0 0 301 301"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2426_4423)">
              <path
                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                fill="#EAF2F9"
              />
              <path
                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                fill="#FF6600"
              />
              <path
                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                fill="#EAF2F9"
              />
              <path
                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                fill="#EAF2F9"
              />
              <path
                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                fill="#EAF2F9"
              />
            </g>
            <defs>
              <clipPath id="clip0_2426_4423">
                <rect
                  width="212.39"
                  height="212.39"
                  fill="white"
                  transform="translate(0 150.182) rotate(-45)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SubmitFormPage;