import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import encryptfunction from "helpers/Encrypt";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute right-0 top-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
            onClick={onClick}
        >
            <BsArrowRight
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute z-10 top-1/2 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
            onClick={onClick}
        >
            <BsArrowLeft
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

const ProductModulesComponent = (props) => {
    const { products, type } = props;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <h1 className="mb-20 text-new-color lg:text-5xl text-4xl font-light text-center Poppins">
                Dive Into Unique Product Modules
            </h1>
            <div className="relative blog-posts slider-left equal-height">
                <Slider {...settings} className="important:static mt-6 px-10">
                    {products.map((product, index) => (
                        <div className="h-full p-5" key={index}>
                            <Link
                                to={{
                                    pathname: `/product/${encryptfunction(
                                        product.id
                                    )}/${product.course_slug}`,
                                    state: { item: product, type: type },
                                }}
                                key={index}
                                className="block bg-white p-5 h-full transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl"
                            >
                                <div className="relative">
                                    <img
                                        alt={product.course_name}
                                        src={product.course_cover_pic}
                                        className="object-contain object-top w-full mx-auto mb-3 rounded-t-xl"
                                    />
                                </div>
                                <div className="p-4 space-y-8">
                                    <div className="text-center md:text-left">
                                        <p className="text-2xl primary Poppins font-normal">
                                            {product.users.data[0]?.firstname +
                                                " " +
                                                product.users.data[0]?.lastname}
                                        </p>
                                        {product.users.data && (
                                            <p className="my-1 Poppins text-base">
                                                {product.course_name}
                                            </p>
                                        )}
                                        <Link
                                            to={{
                                                pathname: `/product/${encryptfunction(
                                                    product?.id
                                                )}/${product?.course_slug}`,
                                                state: { item: product, type: type },
                                            }}
                                            className="flex items-center Poppins primary fs-13"
                                        >
                                            Read more {">>"}
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default ProductModulesComponent;
