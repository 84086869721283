import {
  Buttonlink,
  Fullscreenpopup,
  Post,
  Signinpopup,
  Nocontent,
  Noconnection,
  Discussionpost,
  DiscussionNavigation,
  IsLoader,
} from "components";
import "./styles.css";
import React, { Component } from "react";
import { FiSliders } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { Dropdown, Popup } from "semantic-ui-react";
import "../../assets/css/semantic.css";
import QUESTIONS from "../../graphql/queries/getQuestions";
import GET_EXPERT_OPINION from "../../graphql/SgQueries/getExpertOpinion";
import { Query } from "@apollo/client/react/components";
import {
  IoIosCloseCircle,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { connect } from "react-redux";
import Auth from "../../Auth";
import { seo } from "helpers/seo";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import { PROFILE_PHOTO_URL } from "../../config/constants";
import commentoutline from "assets/images/commentoutline.png";
import encryptfunction from "../../helpers/Encrypt";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import discussions from "assets/images/discussions.png";
import discussBg from "assets/images/discussBg.webp";
import logo1 from "assets/images/logo1.png";
import logo2 from "assets/images/logo2.png";
import logo3 from "assets/images/logo3.png";
import logo4 from "assets/images/logo4.png";
import logo5 from "assets/images/logo5.png";
import logo6 from "assets/images/logo6.png";
import circles from "assets/images/circles.png";
import { BsArrowRight } from "react-icons/bs";

const auth = new Auth();

const topics_options = [
  { key: 1, text: "All", value: 0 },
  { key: 2, text: "Strategic Sourcing", value: 1 },
  { key: 3, text: "Category Management", value: 2 },
  { key: 4, text: "Contracting", value: 3 },
  { key: 5, text: "Purchasing", value: 4 },
  { key: 6, text: "Payment & Help Desk", value: 5 },
  { key: 7, text: "Biotech", value: 6 },
  { key: 8, text: "CDMO", value: 7 },
  { key: 9, text: "CRO", value: 8 },
  { key: 10, text: "Healthcare", value: 9 },
];

const options = [
  { key: 11, text: "What's Hot?", value: "get_hotQuestions" },
  { key: 12, text: "What's New?", value: "getNewQuestions" },
  {
    key: 13,
    text: "Unanswered",
    value: "UnansweredQuestions",
  },
  {
    key: 14,
    text: "My Discussion",
    value: "get_MyDiscussions",
  },
  {
    key: 15,
    text: "Expert Opinion",
    value: "get_privateDiscussion",
  },
  {
    key: 16,
    text: "Save Discussions",
    value: "getSavedQuestions",
  },
];

function RecomendationsList({ question, selecedFilters, selecedTopics }) {
  const convertToSlug = (Text) => {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  return (
    <div key={question.id} className="mt-5">
      <div className="sm:p-4 xs:p-2 important:pb-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {question.user ? (
              <Avatar
                alt={question.user.firstname}
                src={
                  question.user.profile_photo ? (
                    process.env.REACT_APP_MRO_URL +
                    PROFILE_PHOTO_URL +
                    `/${question.user.profile_photo}`
                  ) : (
                    <Avatar
                      alt={question.user.firstname}
                      className={"h-20 w-20 object-cover rounded-full mr-3"}
                    />
                  )
                }
                sx={{ height: 48, width: 48 }}
                className={"h-20 w-20 object-cover rounded-full mr-3"}
              />
            ) : (
              ""
            )}
            {/* <div>
              <Popup
                trigger={
                  <p>
                    {question.user.firstname} {question.user.lastname}
                  </p>
                }
                content="user seeking your help in Sourcing Genie https://sourcinggenie.io/"
                inverted
              />

              <p className="Light gray fs-12">@{question.user.username}</p>
            </div> */}
            <div className='flex flex-col'>
              <div className='flex items-center gap-3'>
                <p className='text-base text-new-color'>Topic:</p>
                {
                  question?.categories?.length === 0 ? <p className='px-1 text-base primary'>General</p> :
                    <div className='divide-x divide-gray-400 flex flex-wrap'>
                      {
                        question?.categories?.map((topic, index) => {
                          return (
                            <p key={index} className='px-1 text-base flex-shrink-0 primary'>{topic?.name}</p>
                          )
                        })
                      }
                    </div>
                }
              </div>
              <p className='text-new-color text-base'>{moment(question.created_at).format("LL")}</p>
            </div>
          </div>
        </div>
        <div className='ml-16 mt-5'>
          <Link
            to={{
              pathname: `/recommendation/${encryptfunction(question.id)}/${convertToSlug(question.title)}`,
              state: {
                filters: selecedFilters,
                topics: selecedTopics,
              },
            }}
            className="text-lg text-new-color"
          >
            {question.title}
          </Link>

          <div className="py-3">
            <div className="post-footer">
              <div className="flex items-center justify-between">
                <div className="flex items-center lg:gap-x-10 gap-x-6">
                  <Link
                    to={{
                      pathname: `/recommendation/${encryptfunction(question.id)}/${convertToSlug(question.title)}`,
                      state: {
                        filters: selecedFilters,
                        topics: selecedTopics,
                      },
                    }}
                    className="flex items-center cursor-pointer gap-x-2"
                  >
                    <img
                      alt="comment"
                      src={commentoutline}
                      className="object-contain w-5 h-5 lg:h-6 lg:w-6"
                    />
                    <p className="gray fs-13">{question.total_comment}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-0 m-0 border divider" />
    </div>
  );
}

class Discussions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: this.props.history.location?.state?.topics
        ? parseInt(this.props.history.location?.state?.topics)
        : 0,
      filters: this.props.history.location?.state?.filters
        ? this.props.history.location?.state?.filters
        : "getNewQuestions",
      question: "",
      orderBy: "",
      openModal: false,
      loadMoreQuestions: false,
      currentPage: 1,
      hasMoreRecommendations: [],
      reload_required: !!this.props.history.location?.state?.refresh,
    };
    this.timeoutValue = 0;
    this.SelectFullPopup = React.createRef();
    this.openSignIn = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Discussions | Procurement League",
    });

    this.props.handleTagSearch([]);
    this.props.handleCategorySearch([]);
    this.props.handleUsersChange([]);
    this.props.handleEmailChange("");
  }

  openPopupModal = () => {
    auth.isAuthenticated()
      ? this.SelectFullPopup.current.openPopup()
      : this.openSignIn.current.openPopup();
  };

  handleInputChange = (event, filter) => {
    let inputValue = "";
    if (filter !== "Filter") {
      inputValue = parseInt(event.target.value, 10);
    } else {
      inputValue = event.target.value;
    }

    // const inputValue = parseInt(event.target.value, 10);

    if (auth.isAuthenticated()) {
      if (inputValue === "" && event.target.name === "question") {
        console.log("test")
        return this.setState({questions: "", currentPage: 1, [event.target.name]: inputValue, reload_required: true})
      }
      this.setState({
        ...this.state,
        [event.target.name]: inputValue,
        currentPage: 1,
        reload_required: true,
      })
    } else {
      this.openSignIn.current.openPopup();
    }
  };

  handleInputChangeTopics = (event, data) => {
    // console.log(event,data.value)
    auth.isAuthenticated()
      ? this.setState({
          ...this.state,
          topics: data.value,
        })
      : this.openSignIn.current.openPopup();
  };

  handleInputChangeFilters = (event, data) => {
    // console.log(event,data.value)
    auth.isAuthenticated()
      ? this.setState({
          ...this.state,
          filters: data.value,
        })
      : this.openSignIn.current.openPopup();
  };

  handleTagRemove = (tags) => {
    this.props.handleTagRemove(tags);
  };

  convertToSlug = (Text) => {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  onLoadMore = (data, fetchMore, refetch) => {
    clearTimeout(this.timeoutValue);
    this.timeoutValue = setTimeout(() => { }, 1000);
    fetchMore({
      variables: {
        cursor: data.expert_opinion.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.expert_opinion.data;
        const pageInfo = fetchMoreResult.expert_opinion.paginatorInfo;
        if (data.expert_opinion.paginatorInfo.total != pageInfo.total) {
          refetch();
        }
        return newQuestions.length
          ? {
              expert_opinion: {
                __typename: previousResult.expert_opinion.__typename,
                data: [...previousResult.expert_opinion.data, ...newQuestions],
                paginatorInfo: pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  render(state, callback) {
    const { topics, filters, questions } = this.state;
    let variables = {};

    if (questions !== "" && questions !== null) {
      variables.question = questions;
    }

    if (topics === 0 || topics === "0") {
      delete variables.category;
    } else {
      variables.category = topics;
    }

    if (this.props.selectedTags.length > 0) {
      let array = [];
      this.props.selectedTags.forEach((tags) => {
        array.push(Number(tags.id));
      });
      variables.get_tag = array;

      // Clear other variables when get_tag is set
      switch (filters) {
        case "orderby":
        case "get_hotQuestions":
        case "getNewQuestions":
        case "UnansweredQuestions":
        case "get_MyDiscussions":
        case "getSavedQuestions":
        case "get_privateDiscussion":
          delete variables.hot;
          delete variables.new;
          delete variables.unanswered;
          delete variables.my;
          delete variables.getSavedQuestions;
          delete variables.get_privateDiscussion;
          break;
        default:
          break;
      }
    } else {
      switch (filters) {
        case "orderby":
          delete variables.hot;
          delete variables.new;
          delete variables.unanswered;
          delete variables.my;
          delete variables.getSavedQuestions;
          break;
        case "get_hotQuestions":
          variables.hot = true;
          break;
        case "getNewQuestions":
          variables.new = true;
          break;
        case "UnansweredQuestions":
          variables.unanswered = true;
          break;
        case "get_MyDiscussions":
          variables.my = true;
          break;
        case "getSavedQuestions":
          variables.getSavedQuestions = true;
          break;
        case "get_privateDiscussion":
          variables.get_privateDiscussion = true;
          break;
        default:
          break;
      }
    }

    let recVariables = {};

    if (auth && auth.isAuthenticated()) {
      // let ids = [];

      /*if (this.props?.authUser?.categories?.length > 0) {
        this.props?.authUser?.categories.map((category) =>
          ids.push(category.code)
        );
      }
      if (ids.length > 0) {
        recVariables.categories = ids;
      }*/
      if (this.state.questions !== "") {
        recVariables.search = this.state.questions;
      }
    }
    return (
      <div className="h-auto">
        <div className=" bg-[#EAF2F9] -mt-14">
          <div className="h-auto py-14 container w-[100%] grid grid-cols-12 relative ">
            <div className="lg:col-span-7 col-span-12">
              <p className="text-[#FF6600] text-[18px] leading-[26px]">
                Explore. Source. Connect.
              </p>
              <h1 className="text-new-color lg:text-5xl my-2 font-light text-4xl Poppins">
                Got Something to Say?
              </h1>
              <p className="text-[18px] leading-[25px] ">
                Initiate Your Own Discussion or Engage with Our Active Community
                Topics!
              </p>
              <div className="mt-10">
                {/* Searchbar with filter Icon View */}
                {filters === "get_privateDiscussion" ? (
                  <div className="flex flex-col items-center gap-3 lg:flex-row">
                    <div className="flex items-center w-full gap-3">
                      <div className="flex items-center w-full px-2 bg-white rounded-full md:px-5">
                        <input
                          placeholder="Search discussion by titles"
                          className="flex-grow w-full pr-4 input-autofill-white bg-white border-0 h-14 Regular rounded-full"
                          name="question"
                          value={this.state.question}
                          onChange={(e) => this.handleInputChange(e, "Filter")}
                        />
                        <GoSearch size={22} className="gray" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-3 lg:flex-row">
                    <div className="flex items-center w-full gap-3">
                      <div className="flex items-center w-full px-2 bg-white rounded-full md:px-5">
                        <input
                          placeholder="Search discussion by titles"
                          className="flex-grow w-full input-autofill-white pr-4 bg-white border-0 h-14 Regular rounded-full"
                          name="question"
                          value={this.state.question}
                          onChange={(e) => this.handleInputChange(e, "Filter")}
                        />
                        <GoSearch size={22} className="gray" />
                      </div>
                      <div onClick={this.openPopupModal}>
                        <FiSliders
                          className="cursor-pointer primary"
                          size={30}
                        />
                      </div>
                    </div>

                    {true ? (
                      <div className="relative w-full group lg:w-20">
                        <Buttonlink
                          onClick={() => this.setState({questions: this.state.question})}
                          className="flex items-center justify-center w-full h-12 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 lg:h-14 rounded-full lg:w-44 gap-3 font-medium text-[18px] leading-[25px] "
                          Text="Search"
                          // Lasticon={<BsArrowRight size={24} />}
                        />
                      </div>
                    ) : (
                      <Buttonlink
                        onClick={() => this.openSignIn.current.openPopup()}
                        className="flex items-center justify-center w-full h-12 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 lg:h-14 rounded-full lg:w-44 gap-3 font-medium text-[18px] leading-[25px] "
                        Text="Search"
                        // Lasticon={<BsArrowRight size={30} />} 
                      />
                    )}
                  </div>
                )}

                {/* Selected Tags View */}
                <div className="flex flex-wrap items-center gap-3 mt-4">
                  {this.props.selectedTags.length > 0
                    ? this.props.selectedTags.map((tags) => (
                      <div
                        key={tags.id}
                        className="flex items-center px-3 border-2 rounded-full border--primary h-11"
                      >
                        <p className="Regular">{tags.title}</p>
                        <IoIosCloseCircle
                          className="ml-2 cursor-pointer primary"
                          size={22}
                          onClick={() => this.handleTagRemove(tags.id)}
                        />
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </div>
            <img
              src={discussions}
              alt="logo"
              className="absolute top-20 lg:block hidden -right-10 sm:right-10 lg:right-40 h-48"
            />
            <img
              src={circles}
              alt="circles"
              className="absolute -bottom-10 lg:block hidden -right-10 sm:right-10 lg:right-40 h-24"
            />
          </div>
        </div>
        <div className="container">
          <div className="my-10">
            <h1 className="my-12 lg:text-5xl font-light text-4xl Poppins">
              Trending Topics & Spotlight Discussions
            </h1>
            <div className="my-5 grid lg:grid-cols-6 gap-x-3 gap-y-3">
              <div className="relative flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <input
                  type="radio"
                  className="absolute left-0 z-0 invisible"
                  id="What"
                  name="filters"
                  checked={this.state.filters === "get_hotQuestions"}
                  value="get_hotQuestions"
                  onChange={(e) => this.handleInputChange(e, "Filter")}
                />
                <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                <label
                  htmlFor="What"
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                >
                  <div className={"flex gap-2 items-center"}>
                    <p className="text-[#16283A]">What's Hot </p>
                    {/* <Popup
                      trigger={
                        <AiOutlineQuestionCircle
                          className="cursor-pointer gray"
                          size={17}
                        />
                      }
                      content="Questions that have been answered and created within the last 6 months."
                      inverted
                    /> */}
                  </div>
                </label>
              </div>
              <div className="relative flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <input
                  type="radio"
                  className="absolute left-0 z-0 invisible"
                  id="New?"
                  checked={this.state.filters === "getNewQuestions"}
                  name="filters"
                  value="getNewQuestions"
                  onChange={(e) => this.handleInputChange(e, "Filter")}
                />
                <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                <label
                  htmlFor="New?"
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                >
                  <div className={"flex gap-1 items-center"}>
                    <p className="text-[#16283A]">What's New</p>
                    {/* <Popup
                          trigger={
                            <AiOutlineQuestionCircle
                              className="cursor-pointer gray"
                              size={17}
                            />
                          }
                          content="Questions were created exactly 2 months ago from the current date."
                          inverted
                        /> */}
                  </div>
                </label>
              </div>
              <div className="relative flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <input
                  type="radio"
                  className="absolute left-0 z-0 invisible"
                  id="Unanswered"
                  name="filters"
                  checked={this.state.filters === "UnansweredQuestions"}
                  value="UnansweredQuestions"
                  onChange={(e) => this.handleInputChange(e, "Filter")}
                />
                <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                <label
                  htmlFor="Unanswered"
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold "
                >
                  <div className={"flex gap-3 items-center"}>
                    <p className="text-[#16283A] ">Un-Answered</p>
                    {/* <Popup
                          trigger={
                            <AiOutlineQuestionCircle
                              className="cursor-pointer gray"
                              size={17}
                            />
                          }
                          content="Question not been answered by any User."
                          inverted
                        /> */}
                  </div>
                </label>
              </div>
              <div className="relative flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <input
                  type="radio"
                  className="absolute left-0 z-0 invisible"
                  id="MyDiscussion"
                  name="filters"
                  checked={this.state.filters === "get_MyDiscussions"}
                  value="get_MyDiscussions"
                  onChange={(e) => this.handleInputChange(e, "Filter")}
                />
                <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                <label
                  htmlFor="MyDiscussion"
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold "
                >
                  <div className={"flex gap-2 items-center"}>
                    <p className="text-[#16283A]">My Discussion</p>
                  </div>
                </label>
              </div>
              <div className="relative flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <input
                  type="radio"
                  className="absolute left-0 z-0 invisible"
                  id="PrivateDiscussion"
                  name="filters"
                  checked={this.state.filters === "get_privateDiscussion"}
                  value="get_privateDiscussion"
                  onChange={(e) => this.handleInputChange(e, "Filter")}
                />
                <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                <label
                  htmlFor="PrivateDiscussion"
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                >
                  <div className={"flex gap-1 items-center"}>
                    <p className="text-[#16283A]">Expert Opinion</p>
                  </div>
                </label>
              </div>
              <div onClick={() => this.props.history.push('/rfqs')} className="relative flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <div
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                >
                  <p className="text-[#16283A]">Rfq</p>
                </div>
              </div>
              <div className="relative lg:col-span-2 w-full lg:max-w-[220px] flex items-center whitespace-nowrap select-list flex-grow bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <input
                  type="radio"
                  className="absolute left-0 z-0 invisible"
                  id="SaveDiscussions"
                  checked={this.state.filters === "getSavedQuestions"}
                  name="filters"
                  value="getSavedQuestions"
                  onChange={(e) => this.handleInputChange(e, "Filter")}
                />
                <div className="absolute z-10 w-2 h-2 rounded-full right-5" />
                <label
                  htmlFor="SaveDiscussions"
                  className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                >
                  <div className={"flex gap-1 items-center"}>
                    <p className="text-[#16283A]">Saved Discussion</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="relative flex flex-col justify-between lg:items-center lg:justify-center lg:flex-row">
            {/* <div className="right-0 flex items-center bottom-2 lg:absolute gap-x-3">
              <Buttonlink
                Text="Checkout Leaders"
                Lasticon={<IoIosArrowForward />}
                to="/leaderboard"
                className="flex items-center justify-center border-0 shadow-none fs-15 primary hover:primary Regular rounded--xl "
              />
            </div> */}
          </div>
          {/* <div className="grid grid-cols-1 gap-4 lg:grid-cols-7">
            <div className="lg:col-span-2"></div>
            <div className="lg:col-span-5">
              <DiscussionNavigation />
            </div>
          </div> */}
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-7">
            <div className="lg:col-span-2 mt-4">
              <div className="hidden lg:block">
                {/* Topics Box */}
                <div className="p-5 mb-4 border rounded-xl">
                  <p className="mb-3 text-[18px] font-semibold">Topics</p>
                  {topics_options.map((data) => (
                    <div key={data.key}>
                      <div className="relative flex items-center -mx-3 select-list">
                        <input
                          type="radio"
                          name="topics"
                          className="absolute left-0 z-0 invisible"
                          id={data.text}
                          checked={this.state.topics == data.value}
                          value={data.value}
                          onChange={this.handleInputChange}
                        />
                        {this.state.topics == data.value && (
                          <div className="absolute bg--primary z-10 w-2 h-2 rounded-full right-5" />
                        )}
                        <label
                          htmlFor={data.text}
                          className="cursor-pointer w-full hover:bg-gray-100 rounded-md px-3 py-2.5"
                        >
                          <p className="text-new-color text-[18px] Poppins">
                            {data.text}
                          </p>
                        </label>
                      </div>
                    </div>
                  ))}
                  <Buttonlink
                    to="/post-discussion"
                    className="flex items-center justify-between px-4 w-full md:w-[90%] lg:w-[100%] xl:w-[80%] h-12 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 lg:h-14 rounded-full  gap-1 font-medium text-[18px] leading-[25px] my-5 "
                    Text="Start A Discussion"
                    Lasticon={<BsArrowRight size={24} />}
                  />
                </div>
              </div>
              <div className="grid w-full gap-4 lg:hidden">
                <div>
                  <Dropdown
                    className="border gray Regular rounded--xl"
                    placeholder="Topic"
                    fluid
                    selection
                    onChange={this.handleInputChangeTopics}
                    options={topics_options}
                  />
                </div>
                {/* <div>
                  <Dropdown
                    className="border gray Regular rounded--xl"
                    placeholder="Filter"
                    fluid
                    selection
                    onChange={this.handleInputChangeFilters}
                    options={options}
                  />
                </div> */}
              </div>
            </div>

            <div className="lg:col-span-5">
              {/* Questions Component */}
              <div className="mt-4">
                {filters === "get_privateDiscussion" ? (
                  <Query
                    key={2}
                    query={GET_EXPERT_OPINION}
                    variables={recVariables}
                  >
                    {({ loading, data, error, fetchMore, refetch }) => {
                      if (loading) {
                        return (
                          <div>
                            <Discussionpost />
                            <Discussionpost />
                            <Discussionpost />
                          </div>
                        );
                      }

                      if (error) {
                        // console.log("error",error)
                        return (
                          <div>
                            {auth.isAuthenticated() ? (
                              <div className="py-10">
                                <Noconnection />
                              </div>
                            ) : (
                              <>
                                <div style={{ fontSize: 19 }}>
                                  <Alert severity="warning">
                                    <AlertTitle>
                                      <strong>Anonymous User</strong>
                                    </AlertTitle>
                                    Please{" "}
                                    <button
                                      onClick={() =>
                                        this.openSignIn.current.openPopup()
                                      }
                                      className={"primary"}
                                    >
                                      Login
                                    </button>{" "}
                                    to connect with other Procurement
                                    practitioners and participate in discussion.
                                  </Alert>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      }

                      if (data?.expert_opinion?.data?.length <= 0) {
                        return <Nocontent />;
                      }

                      if (data) {
                        // console.log("variables", recVariables);
                        return (
                          <div>
                            {data?.expert_opinion?.data.map((question) => (
                              <RecomendationsList
                                question={question}
                                selecedFilters={filters}
                                selecedTopics={topics}
                                onLoadMore={() => {
                                  console.log("LOAD MORE MORE AND MORE");
                                }}
                              />
                            ))}
                            {data?.expert_opinion?.paginatorInfo
                              ?.hasMorePages && (
                                <Buttonlink
                                  onClick={() =>
                                    this.onLoadMore(data, fetchMore, refetch)
                                  }
                                  Text="Load More"
                                  className="flex items-center justify-center w-[150px] mx-auto mt-10 border-0 rounded-lg shadow-none bg--primary white hover:white hover:opacity-70 hover:bg--primary focus:bg--primary h-11 Regular"
                                />
                              )}
                          </div>
                        );
                      }
                    }}
                  </Query>
                ) : (
                  <Query key={1} query={QUESTIONS} variables={variables}>
                    {({ loading, data, error, fetchMore, refetch }) => {
                      if (loading) {
                        return (
                          <div className="flex flex-col gap-y-3 w-full">
                            <Discussionpost />
                            <Discussionpost />
                            <Discussionpost />
                          </div>
                        );
                      }

                      if (this.state.reload_required) {
                        this.setState({ reload_required: false }, () => {
                          refetch();
                        });
                      }

                      if (error) {
                        return (
                          <div>
                            <div className="py-10">
                              <Noconnection />
                            </div>
                          </div>
                        );
                      }

                      if (
                        !data ||
                        !data.questions ||
                        data.questions.data.length === 0
                      ) {
                        return <Nocontent />;
                      }

                      const { questions } = data;

                      return (
                        <div className="relative">
                          <Post
                            questions={questions.data}
                            selecedFilters={filters}
                            selecedTopics={topics}
                            loading={this.state.loadMoreQuestions}
                            onLoadMore={() => {
                              if (
                                questions.paginatorInfo.hasMorePages &&
                                auth.isAuthenticated()
                              ) {
                                this.setState({ loadMoreQuestions: true });
                              } else {
                                this.setState({ loadMoreQuestions: false });
                              }
                              if (auth.isAuthenticated()) {
                                fetchMore({
                                  variables: {
                                    cursor:
                                      questions.paginatorInfo.currentPage + 1,
                                  },
                                  updateQuery: (
                                    previousResult,
                                    { fetchMoreResult }
                                  ) => {
                                    if (
                                      !fetchMoreResult ||
                                      !fetchMoreResult.questions
                                    ) {
                                      return previousResult;
                                    }

                                    const {
                                      data: newQuestions,
                                      paginatorInfo,
                                    } = fetchMoreResult.questions;

                                    if (
                                      questions.paginatorInfo.total !==
                                      paginatorInfo.total
                                    ) {
                                      refetch();
                                    }

                                    const uniqueQuestions = newQuestions.filter(
                                      (newQuestion) =>
                                        !previousResult.questions.data.some(
                                          (existingQuestion) =>
                                            existingQuestion.id ===
                                            newQuestion.id
                                        )
                                    );

                                    return uniqueQuestions.length
                                      ? {
                                          questions: {
                                            ...previousResult.questions,
                                            data: [
                                              ...previousResult.questions.data,
                                              ...uniqueQuestions,
                                            ],
                                            paginatorInfo,
                                          },
                                        }
                                      : previousResult;
                                  },
                                }).finally(() => this.setState({ loadMoreQuestions: false }));
                              }
                            }}
                          />
                          {!auth.isAuthenticated() && (
                            <div className="blur-bottom-top gap-x-5 flex items-end justify-center w-full mt-4 z-50">
                              <Buttonlink
                                to="/signup"
                                Text="Sign Up"
                                className="flex items-center justify-center w-full h-12 rounded-full bg--lightGray black hover:black focus:black hover:opacity-70 hover:bg--lightGray focus:bg--lightGray Regular"
                              />
                              <Buttonlink
                                onClick={() =>
                                  this.openSignIn.current.openPopup()
                                }
                                Text="Login"
                                className="w-full h-12 text-white rounded-full bg--primary hover:opacity-70 hover:bg--primary focus:bg--primary fs-15 Regular"
                              />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  </Query>
                )}
                {this.state.loadMoreQuestions ? (
                  <div className="flex justify-center items-center">
                    <IsLoader className="w-32 h-32 mx-auto" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Fullscreenpopup ref={this.SelectFullPopup} title={"Select Tags"} />
        <Signinpopup ref={this.openSignIn} protect={true} />
        <div className="mt-28 mb-10 container hidden">
          <div
            className={`lg:h-[400px] md:h-[400px] sm:h-[550px] h-[590px]
             w-full relative`}
          >
            <img
              src={discussBg}
              alt="TopDiscussionBg"
              className="object-cover h-full"
            />
            <div className="absolute m-auto w-full h-full inset-0 flex justify-center items-center">
              <div className="space-y-5">
                <div className="flex items-center justify-center">
                  <h1 className="text-[28px] md:text-[32px] lg:text-[40px] lg:leading-[80px] text-center font-thin Poppins text-white px-2">
                    Something top discuss? Join a topic!
                  </h1>
                </div>
                <div className="bg-white h-px w-full" />
                <div className="flex gap-5 justify-around flex-wrap">
                  <div className="w-32 lg:w-40 py-2 rounded-[40px] bg--lightPrimary shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center justify-center">
                    <img
                      src={logo1}
                      alt="logo 1"
                      className="h-[30px] w-[30px]"
                    />
                    <p className="text-[14px] leading-6 font-semibold text-white text-center my-2 gap-2 px-1">
                      What's Your Procurement Challenge?
                    </p>
                  </div>
                  <div className="w-32 lg:w-40 py-2 rounded-[40px] bg--lightPrimary shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center justify-center">
                    <img
                      src={logo2}
                      alt="logo 1"
                      className="h-[30px] w-[30px]"
                    />
                    <p className="text-[14px] leading-6 font-semibold text-white text-center my-2 gap-2 px-1">
                      How Do You Manage Supply Chain Issues?
                    </p>
                  </div>
                  <div className="w-32 lg:w-40 py-2 rounded-[40px] bg--lightPrimary shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center justify-center">
                    <img
                      src={logo3}
                      alt="logo 1"
                      className="h-[30px] w-[30px]"
                    />
                    <p className="text-[14px] leading-6 font-semibold text-white text-center my-2 gap-2 px-1">
                      Latest Trends in Healthcare
                    </p>
                  </div>
                  <div className="w-32 lg:w-40 py-2 rounded-[40px] bg--lightPrimary shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center justify-center">
                    <img
                      src={logo4}
                      alt="logo 1"
                      className="h-[30px] w-[30px]"
                    />
                    <p className="text-[14px] leading-6 font-semibold text-white text-center my-2 gap-2 px-1">
                      Share your CDMO Experiences
                    </p>
                  </div>
                  <div className="w-32 lg:w-40 py-2 rounded-[40px] bg--lightPrimary shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center justify-center">
                    <img
                      src={logo5}
                      alt="logo 1"
                      className="h-[30px] w-[30px]"
                    />
                    <p className="text-[14px] leading-6 font-semibold text-white text-center my-2 gap-2 px-1">
                      What's New in Life Sciences?
                    </p>
                  </div>
                  <div className="w-32 lg:w-40 py-2 rounded-[40px] bg--lightPrimary shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center justify-center">
                    <img
                      src={logo6}
                      alt="logo 1"
                      className="h-[30px] w-[30px]"
                    />
                    <p className="text-[14px] leading-6 font-semibold text-white text-center my-2 gap-2 px-1">
                      Overcoming Logistics Obstacles?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTags: state.DiscussionSearchReducer.selectedTags,
  deletedQuestions: state.DiscussionSearchReducer.deletedQuestions,
  authUser: state.AuthUserReducer.authUserInformation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleTagRemove: (tags) => dispatch({ type: "REMOVE_TAGS", payload: tags }),
    handleDeleteQuestion: (id) =>
      dispatch({ type: "REMOVE_QUESTION", payload: id }),
    handleTagSearch: (tags) =>
      dispatch({ type: "SELECTED_TAGS", payload: tags }),
    handleCategorySearch: (data) =>
      dispatch({ type: "SELECTED_CATEGORY", payload: data }),
    handleUsersChange: (tags) =>
      dispatch({ type: "SELECTED_USER", payload: tags }),
    handleEmailChange: (email) =>
      dispatch({ type: "TYPE_EMAIL", payload: email }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Discussions);