import { Buttonlink } from "components";
import React, { Component } from "react";
import { GoSearch } from "react-icons/go";
import { IoIosArrowBack } from "react-icons/io";
import Resourcecard from "./resourcecard";

class ResourceDetail extends Component {
  render() {
    return (
      <div className="container">
        <Buttonlink
          Text="back"
          Icon={<IoIosArrowBack />}
          to="/products"
          className="flex items-center justify-center px-4 mb-3 rounded-full bg--lightGray h-9 darkGray w-28 hover:darkGray hover:opacity-80"
        />
        <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-2">
          <div className="flex items-center xl:col-span-2 xl:col-start-2">
            <div className="flex items-center w-full px-3 bg-gray-100 rounded-xl">
              <input
                placeholder="Search..."
                className="flex-grow w-full bg-gray-100 border-0 h-14 Regular rounded-xl"
                // value={this.state.searchText}
                onChange={(e) => {
                  clearTimeout(this.timeoutValue);
                  this.timeoutValue = setTimeout(() => {
                    this.setState({ searchText: e.target.value });
                  }, 2000);
                }}
              />
              <GoSearch size={22} className="gray" />
            </div>
          </div>
        </div>

        <div>
          <div className="mt-20">
            <div>
              <h4 className="mb-4 text-2xl Medium">Products</h4>
            </div>
            <Resourcecard />
          </div>
        </div>
      </div>
    );
  }
}

export default ResourceDetail;
