import React from "react";
import ReactDOM from "react-dom";
import CustomRoutes from "./Route";
import "./index.css";
import "./assets/css/style.css";
import "./assets/css/semantic.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./config/fonts/fontfamily.css";
import "./config/color/color.css";
import { ApolloProvider } from "@apollo/client";
import StripeProvider from './StripeProvider';
import client from "./graphql/client";
import { Provider } from "react-redux";
import store from "./store";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Api from "services/api";
Api.init(`${process.env.REACT_APP_MAIN_URL}/api`);
ReactDOM.render(
  <ApolloProvider client={client}>
    <StripeProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="650839431438-556g2hk3n1hctgq1ae2n8a79al7h52q0.apps.googleusercontent.com">
          <CustomRoutes />
        </GoogleOAuthProvider>,
      </Provider>
    </StripeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
