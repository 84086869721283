import React, { useState } from 'react'

const SearchJobsFilter = ({ jobCentral, handleTimingChange, handleFilterChange,activeFilter }) => {
    return (
        <>
            {jobCentral ?
                <div className="flex items-center mt-10 justify-start flex-wrap gap-x-3 gap-y-3">
                    <div onClick={() => handleTimingChange(1)} className="relative flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 1 && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    Last 7 Days
                                </p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleTimingChange(2)} className="relative flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 2 && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">This month</p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleTimingChange(3)} className="relative flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 3 && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">Last month</p>
                            </div>
                        </label>
                    </div>
                </div> :
               null}
                {/* <div className="flex items-center mt-10 justify-start flex-wrap lg:flex-nowrap gap-x-3 gap-y-3">
                    <div onClick={() => handleFilterChange('saved_jobs')} className="relative md:flex-grow-0 flex-shrink-0 flex-grow flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 'saved_jobs'  && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    Saved Jobs
                                </p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleFilterChange('all_jobs')} className="relative md:flex-grow-0 flex-shrink-0 flex-grow flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 'all_jobs' && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    All Jobs
                                </p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleFilterChange('applied_jobs')} className="relative md:flex-grow-0 flex-shrink-0 flex-grow flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 'applied_jobs' && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    Applied Jobs
                                </p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleFilterChange('my_drafted_jobs')} className="relative md:flex-grow-0 flex-shrink-0 flex-grow flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 'my_drafted_jobs' && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    Drafted Jobs
                                </p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleFilterChange('my_expired_jobs')} className="relative md:flex-grow-0 flex-shrink-0 flex-grow flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 'my_expired_jobs' && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    Expired Jobs
                                </p>
                            </div>
                        </label>
                    </div>
                    <div onClick={() => handleFilterChange('my_closed_jobs')} className="relative md:flex-grow-0 flex-shrink-0 flex-grow flex items-center select-list bg-white shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                        <input
                            type="radio"
                            className="absolute left-0 z-0 invisible"
                        />
                        <div className={`absolute z-10 w-2 h-2 rounded-full right-5 ${activeFilter === 'my_closed_jobs' && 'bg--primary'}`} />
                        <label
                            htmlFor="What"
                            className="cursor-pointer w-full hover:bg-gray-100 rounded-full px-10 py-2.5 text-[18px] leading-[25px] font-semibold"
                        >
                            <div className={"flex gap-2 items-center"}>
                                <p className="text-[#16283A]">
                                    Closed Jobs
                                </p>
                            </div>
                        </label>
                    </div>
                </div> */}
        </>
    )
}

export default SearchJobsFilter