import React, { Component } from "react";
import { FiChevronRight } from "react-icons/fi";
import Auth from "../../../Auth";
import { Buttonlink } from "components";
import client from "../../../graphql/client";
import SUBSCRIBE from "../../../graphql/mutations/subscribeMutation";
import SUBSCRIBE_TOGGLE from "../../../graphql/mutations/updateUseriSubscription";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBell, FaBellSlash } from "react-icons/fa";
import { connect } from "react-redux";
import SendMail from "assets/images/newsletter/sendMail.png";
import {
  BsArrowRight,
  BsWhatsapp,
  BsLinkedin,
  BsCheck2All,
} from "react-icons/bs";
import { BiMessageAltDetail } from "react-icons/bi";

const auth = new Auth();

class SubscribeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      loading: false,
      logged_in: false,
      disabled: true,
      addDeley: false,
      stopLoop: false,
    };
  }

  handleSubscription = (event) => {
    event.preventDefault();
    const { name, email } = this.state;

    if (email === "") {
      this.setState({
        loading: false,
      });
      this.notify("Please provide your email address.", "error");
      return;
    }

    if (name) {
      var Namepattern = new RegExp(/^[A-Za-z]+$/);
      if (!Namepattern.test(name)) {
        this.setState({
          name: "",
        });
        this.notify(
          "Please enter letters only witout spaces & special characters.",
          "error"
        );
        return;
      }
    }

    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        this.setState({
          email: "",
        });
        this.notify("Please enter valid email address.", "error");
        return;
      }
    }

    this.setState({
      loading: true,
    });
    client
      .mutate({
        mutation: SUBSCRIBE,
        variables: {
          name: name,
          email: email,
        },
      })
      .then((res) => {
        if (this.props.location === "NewLetterSubscribeDialog") {
          this.props.closeDialog(true);
        }
        this.setState({
          loading: false,
          name: "",
          email: "",
          stopLoop: true,
        });
        this.notify("Subscribed successfully!", "success");
      })
      .catch((err) => {
        this.setState({
          loading: false,
          name: "",
          email: "",
          stopLoop: true,
        });
        this.notify("Error in subscribing!", "error");
      });
  };

  handleSubscriptionMutation = (event, id) => {
    event.preventDefault();
    this.setState({
      loading: true,
      addDeley: true,
    });
    client
      .mutate({
        mutation: SUBSCRIBE_TOGGLE,
        variables: {
          id: id,
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.handleSubscriptionReducer(res.data.update_user_subscription);
        this.notify("Status Updated!", "success");
      })
      .catch((err) => {
        this.setState({
          loading: false,
          name: "",
          email: "",
        });
        this.notify("Error in subscribing!", "error");
      });
  };

  notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    if (type === "success") {
      return toast.success(message, { position: "bottom-right" });
    }

  };

  simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 10000));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.addDeley) {
      this.simulateNetworkRequest().then(() => {
        this.setState({ addDeley: false });
      });
    }
  }

  render() {
    const { name, email, loading, disabled, addDeley } = this.state;
    const { authUserInformation } = this.props;
    let show = !disabled;
    if (email.length !== 0) {
      show = true;
    } else {
      show = false;
    }

    return (
      <div>
        {this.props.location === "footer" ? (
          <>
            {auth.isAuthenticated() ? (
              <div className="flex items-center justify-center">
                {authUserInformation.is_subscribe ? (
                  <Buttonlink
                    disabled={this.state.loading || addDeley}
                    onClick={(event) =>
                      this.handleSubscriptionMutation(
                        event,
                        authUserInformation.id
                      )
                    }
                    Icon={
                      <div className="absolute flex items-center justify-center w-10 h-10 mr-2 bg-white rounded-sm shadow-lg left-1">
                        <FaBellSlash size={18} className="danger" />
                      </div>
                    }
                    className={`${addDeley
                      ? "bg--gray hover:bg--gray focus:bg--gray"
                      : "bg--danger hover:bg--danger focus:bg--danger"
                      } mt-2 relative text-white flex w-full items-center justify-center Regular hover:opacity-90 border-0 rounded-sm shadow-none h-12`}
                    Text="Unsubscribe"
                  />
                ) : (
                  <Buttonlink
                    disabled={this.state.loading || addDeley}
                    onClick={(event) =>
                      this.handleSubscriptionMutation(
                        event,
                        authUserInformation.id
                      )
                    }
                    Icon={
                      <div className="absolute flex items-center justify-center mr-3 bg-white rounded-full shadow-lg -left-4 lg:h-16 lg:w-16 h-14 w-14">
                        <FaBell size={22} className="primary" />
                      </div>
                    }
                    className={`${addDeley
                      ? "hover:bg--gray focus:bg--gray bg--gray"
                      : "hover:bg--primary focus:bg--primary bg--primary"
                      } w-full relative uppercase lg:transition lg:duration-500 lg:ease-in-out lg:transform hover:-translate-y-1 hover:scale-110 flex items-center text-[16px] shadow-lg justify-center  hover:color-white-all hover:color-white Regular white h-11 rounded--xl border-0`}
                    Text="Subscribe"
                  />
                )}
              </div>
            ) : (
              <div className="xl:pr-10">
                {/* <div className="Hoppins my-3">NAME & SURNAME *</div>
                <input
                  placeholder="John Smith"
                  className="w-full px-3 mb-3 bg-gray-200 border-0 rounded-[30px] h-12 Regular"
                  value={name}
                  maxLength={50}
                  onChange={(e) => this.setState({ name: e.target.value })}
                /> */}
                <div className="Hoppins my-3">EMAIL *</div>
                <input
                  placeholder="Enter your email"
                  className="w-full px-3 mb-3 bg-gray-200 border-0 rounded-[30px] h-12 Regular"
                  value={email}
                  maxLength={50}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                {show && (
                  <Buttonlink
                    disabled={this.state.loading}
                    Text={
                      loading ? (
                        <div className="w-5 h-5 border-b-2 rounded-full animate-spin border-white-1000" />
                      ) : (
                        "Subscribe"
                      )
                    }
                    Lasticon={
                      loading ? null : (
                        <BsArrowRight size={30} className="white" />
                      )
                    }
                    onClick={this.handleSubscription}
                    className="disabled:opacity-30 Poppins text-[18px] flex justify-center uppercase items-center border-[3px] border-[#3B455A] gap-2 text-white h-12 w-full px-5 border-0 shadow-none bg-[#1A424E] rounded-[50px] hover:opacity-60"
                  />
                )}
              </div>
            )}
          </>
        ) : null}
        {this.props.location === "newsletters" ? (
          <>
            {auth.isAuthenticated() ? (
              <>
                {authUserInformation.is_subscribe ? (
                  <>
                    <h2 className="mb-10 text-3xl text-center Medium">
                      Unsubscribe To Block Emails & Notifications
                    </h2>
                    <div className="flex items-center justify-center">
                      <Buttonlink
                        disabled={this.state.loading}
                        onClick={(event) =>
                          this.handleSubscriptionMutation(
                            event,
                            authUserInformation.id
                          )
                        }
                        Icon={
                          <div className="absolute flex items-center justify-center w-10 h-10 mr-2 bg-white rounded-sm shadow-lg left-1">
                            <FaBellSlash size={18} className="danger" />
                          </div>
                        }
                        className="relative flex items-center justify-center w-full h-12 text-white uppercase transition duration-500 ease-in-out transform border-0 rounded-sm shadow-none lg:w-96 hover:-translate-y-1 hover:scale-110 bg--danger Regular hover:bg--danger hover:opacity-90 focus:bg--danger"
                        Text="Unsubscribe"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="mb-10 text-3xl text-center Medium">
                      Subscribe To Enable Emails &#38; Notifications
                    </h2>
                    <div className="flex items-center justify-center">
                      <Buttonlink
                        disabled={this.state.loading}
                        onClick={(event) =>
                          this.handleSubscriptionMutation(
                            event,
                            authUserInformation.id
                          )
                        }
                        Icon={
                          <div className="absolute flex items-center justify-center mr-3 bg-white rounded-full shadow-lg -left-4 lg:h-16 lg:w-16 h-14 w-14">
                            <FaBell size={22} className="primary" />
                          </div>
                        }
                        className="lg:w-96 w-full relative uppercase transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 flex items-center text-[16px] shadow-lg justify-center hover:bg--primary focus:bg--primary bg--primary hover:color-white-all hover:color-white Regular white h-11 rounded--xl border-0 mt-5"
                        Text="Subscribe"
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <h2 className="mb-10 text-3xl text-center Medium">
                  Subscribe if you have not already
                </h2>
                <div className="grid gap-4 xl:grid-cols-7 md:grid-cols-3">
                  <div className="xl:col-start-2 xl:col-span-2">
                    <input
                      placeholder="Name"
                      className="w-full px-3 mb-3 bg-gray-200 border-0 rounded-full h-11 Regular"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="xl:col-span-2">
                    <input
                      placeholder="Email"
                      className="w-full px-3 mb-3 bg-gray-200 border-0 rounded-full h-11 Regular"
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div>
                    <button
                      disabled={this.state.loading}
                      type="button"
                      onClick={this.handleSubscription}
                      Icon={<BsCheck2All size={20} className="mr-2 white" />}
                      className="flex items-center h-12 px-5 border-0 shadow-none bg--primary Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                    >
                      Subscribe
                      <FiChevronRight size={20} className="white" />
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
        {this.props.location === "NewLetterSubscribeDialog" && (
          <div>
            <div className="flex justify-center items-center flex-col">
              <div className="w-52 h-28">
                <img
                  src={SendMail}
                  alt="SendMail"
                  className="w-full h-full object-cover"
                />
              </div>
              <div>
                <h1 className="text-5xl font-bold darkGray">
                  {authUserInformation.is_subscribe
                    ? "Unsubscribe"
                    : "Subscribe"}
                </h1>
              </div>
            </div>

            {!auth.isAuthenticated() ? (
              <>
                <div>
                  <input
                    type="text"
                    className="flex-grow w-full bg-gray-100 border-0 h-11 Regular rounded-2xl px-3"
                    placeholder="Name"
                    value={name}
                    maxLength={50}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <div className="mt-5">
                  <input
                    type="email"
                    className="flex-grow w-full bg-gray-100 border-0 h-11 Regular rounded-2xl px-3"
                    placeholder="email"
                    value={email}
                    maxLength={50}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
                <div className="flex justify-center items-center mb-3">
                  <button
                    disabled={
                      !this.state.name ||
                      !this.state.email ||
                      this.state.loading
                    }
                    onClick={this.handleSubscription}
                    className="disabled:opacity-30 flex items-center gap-2 text-white mt-10 h-12 px-14 border-0 shadow-none bg--primary Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                  >
                    SUBSCRIBE <BsArrowRight size={20} />
                  </button>
                </div>
              </>
            ) : authUserInformation.is_subscribe ? (
              <div className="flex items-center justify-center">
                <Buttonlink
                  onClick={(event) =>
                    this.handleSubscriptionMutation(
                      event,
                      authUserInformation.id
                    )
                  }
                  Icon={
                    <div className="absolute flex items-center justify-center w-10 h-10 mr-2 bg-white rounded-sm shadow-lg left-1">
                      <FaBellSlash size={18} className="danger" />
                    </div>
                  }
                  className="relative flex items-center justify-center w-full h-12 text-white uppercase transition duration-500 ease-in-out transform border-0 rounded-sm shadow-none lg:w-96 hover:-translate-y-1 hover:scale-110 bg--danger Regular hover:bg--danger hover:opacity-90 focus:bg--danger"
                  Text="Unsubscribe"
                  disabled={this.state.loading}
                />
              </div>
            ) : (
              <div className="flex justify-center items-center mb-3">
                <button
                  disabled={this.state.loading}
                  onClick={(event) =>
                    this.handleSubscriptionMutation(
                      event,
                      authUserInformation.id
                    )
                  }
                  className="flex items-center gap-2 text-white mt-10 h-12 px-14 border-0 shadow-none bg--primary Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                >
                  SUBSCRIBE <BsArrowRight size={20} />
                </button>
              </div>
            )}

            <div>
              <p className="text-center text-lg darkGray">
                You can also follow us on any of our social media channels
                below!
              </p>
              <div className="flex gap-3 items-center justify-center my-3">
                {/* <BiMessageAltDetail size={35} color='#f58020' onClick={() => { history.push('/contact-us') }} /> */}
                <BsWhatsapp
                  size={35}
                  color="#f58020"
                  onClick={() =>
                    window.open(
                      "https://wa.me/919901166886?text=Hi%2C%0AI%20need%20some%20information",
                      "_blank"
                    )
                  }
                />
                <BsLinkedin
                  size={35}
                  color="#f58020"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/procurement-league/",
                      "_blank"
                    )
                  }
                />
              </div>
              <p className="italic text-lg darkGray text-center">
                Your details are safe with us, and will never be sold, rented or
                given to a third party!
              </p>
            </div>
          </div>
        )}
        {this.props.location === "newsletterspage" ? (
          <>
            {auth.isAuthenticated() ? (
              <div className="flex items-center justify-center">
                {authUserInformation.is_subscribe ? (
                  <Buttonlink
                    disabled={this.state.loading || addDeley}
                    onClick={(event) =>
                      this.handleSubscriptionMutation(
                        event,
                        authUserInformation.id
                      )
                    }
                    Icon={
                      <div className="absolute flex items-center justify-center w-10 h-10 mr-2 bg-white rounded-sm shadow-lg left-1">
                        <FaBellSlash size={18} className="danger" />
                      </div>
                    }
                    className={`${addDeley
                      ? "bg--gray hover:bg--gray focus:bg--gray"
                      : "bg--danger hover:bg--danger focus:bg--danger"
                      } mt-2 relative text-white flex w-full items-center justify-center Regular hover:opacity-90 border-0 rounded-sm shadow-none h-12`}
                    Text="Unsubscribe"
                  />
                ) : (
                  <Buttonlink
                    disabled={this.state.loading || addDeley}
                    onClick={(event) =>
                      this.handleSubscriptionMutation(
                        event,
                        authUserInformation.id
                      )
                    }
                    Icon={
                      <div className="absolute flex items-center justify-center mr-3 bg-white rounded-full shadow-lg -left-4 lg:h-16 lg:w-16 h-14 w-14">
                        <FaBell size={22} className="primary" />
                      </div>
                    }
                    className={`${addDeley
                      ? "hover:bg--gray focus:bg--gray bg--gray"
                      : "hover:bg--primary focus:bg--primary bg--primary"
                      } w-full relative uppercase lg:transition lg:duration-500 lg:ease-in-out lg:transform hover:-translate-y-1 hover:scale-110 flex items-center text-[16px] shadow-lg justify-center  hover:color-white-all hover:color-white Regular white h-11 rounded--xl border-0`}
                    Text="Subscribe"
                  />
                )}
              </div>
            ) : (
              <div className="xl:pr-10">
                <div className="Hoppins my-3">NAME & SURNAME *</div>
                <input
                  placeholder="John Smith"
                  className="w-full px-3 mb-3 bg-gray-200 border-0 rounded-[30px] h-12 Regular"
                  value={name}
                  maxLength={50}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <div className="Hoppins my-3">EMAIL *</div>
                <input
                  placeholder="Enter your email"
                  className="w-full px-3 mb-3 bg-gray-200 border-0 rounded-[30px] h-12 Regular"
                  value={email}
                  maxLength={50}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                {show && (
                  <Buttonlink
                    disabled={this.state.loading}
                    Text={
                      loading ? (
                        <div className="w-5 h-5 border-b-2 rounded-full animate-spin border-white-1000" />
                      ) : (
                        "Subscribe"
                      )
                    }
                    Lasticon={
                      loading ? null : (
                        <BsArrowRight size={30} className="white" />
                      )
                    }
                    onClick={this.handleSubscription}
                    className="disabled:opacity-30 pl-10 Poppins text-[18px] flex justify-between uppercase items-center border-[3px] border-[#3B455A] gap-2 text-white mt-6 h-12 w-full px-5 border-0 shadow-none bg-[#1A424E] rounded-[50px] hover:opacity-60"
                  />
                )}
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInformation: state.AuthUserReducer.authUserInformation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubscriptionReducer: (data) =>
      dispatch({ type: "GET_AUTH_USER_INFORMATION", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeButton);
