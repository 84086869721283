import {
    Paymentplan,
    Trainers,
    Classesslider,
    Noconnection,
    IsLoader,
    BookSpot,
    SalePopup,
} from "components";

import "./styles.css";
import { Query } from "@apollo/client/react/components";
import GET_CLASSES from "../../../graphql/queries/getClasses";
import GET_MINIMAL_CLASSES from "../../../graphql/queries/getMinimalClasses";
import React, { Component } from "react";
import SubtractWhite from "../../../assets/images/Subtract-white.png";
import { BsArrowDown } from "react-icons/bs";
import Auth from "../../../Auth";
import getPaymentPlans from "graphql/queries/getPaymentPlans";
import client from "../../../graphql/client";
import { seo } from "helpers/seo";
import skill from "../../../assets/images/masterClasses/skills.png";
import store from "../../../assets/images/masterClasses/store.png";
import banner from "../../../assets/images/masterClasses/Masterclass.webp";
import book from "../../../assets/images/masterClasses/book.png";
import group from "../../../assets/images/masterClasses/Group.webp";
import { Link } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";
const auth = new Auth();

class ProcureHome extends Component {
    constructor(props) {
        super(props);
        this.openSignIn = React.createRef();
        this.state = {
            plans: [],
            logged_in: false,
            location: props.location,
        };
    }

    componentDidMount() {
        if (this.state.plans.length === 0) {
            this.fetchData();
        }
        window.scrollTo(0, 0);
        seo({
            title: "Classes | Procurement League",
            metaDescription: "With some meta description",
        });
    }

    fetchData = () => {
        client
            .query({
                query: getPaymentPlans,
            })
            .then((result) => {
                this.setState({
                    plans: result.data.plans,
                });
            })
            .catch((error) => {
                // Handle any errors
            });
    };

    openPopup = () => {
        this.openSignIn.current.openPopup();
    };

    renderClassesList = (type) => {
        let variables = {};

        let type2 = "";
        if (type === "Newest") {
            type2 = "newest";
        } else if (type === "Popular") {
            type2 = "popular";
        } else if (type === "Featured") {
            type2 = "featured";
        }

        variables.typeInfo = type2;
        return (
            <Query
                query={
                    auth.isAuthenticated() ? GET_CLASSES : GET_MINIMAL_CLASSES
                }
                variables={variables}
            >
                {({ loading, error, data, fetchMore, refetch }) => {
                    if (loading) {
                        return type2 === "newest" ? (
                            <div className="mt-5">
                                <IsLoader className="mx-auto h-28 w-28" />
                            </div>
                        ) : (
                            false
                        );
                    }
                    if (error) {
                        return type2 === "newest" ? (
                            <div className="mt-5">
                                <Noconnection />
                            </div>
                        ) : (
                            false
                        );
                    }

                    if (
                        auth.isAuthenticated()
                            ? data?.courses?.data?.length === 0
                            : data?.open_courses?.data?.length === 0
                    ) {
                        return null;
                    }

                    if (data !== undefined) {
                        return (
                            <div className="mt-28">
                                <div className="container">
                                    <h4 className="lg:text-5xl text-4xl font-light Poppins text-center">
                                        {type} Classes
                                    </h4>
                                </div>
                                <Classesslider
                                    key={type}
                                    classes={
                                        auth.isAuthenticated()
                                            ? data.courses.data
                                            : data.open_courses.data
                                    }
                                    type={type}
                                />
                            </div>
                        );
                    }
                }}
            </Query>
        );
    };

    render() {
        const { plans, location } = this.state;

        return (
            <>
                <div className="-mt-[50px]">
                    <div className="lg:bg-transparent md:bg-transparent bg-black">
                        <div className="lg:hidden md:hidden relative">
                            <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                        </div>
                        <div className="lg:h-[90vh] md:h-[100vh] ClassBanner relative">
                            <div className="flex w-full max-w-3xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Get Ready To Move Up In The Procurement
                                    &#38; Supply Chain
                                </p>
                                <p className="white Poppins font-normal">
                                    Learn from industry leaders, and practice
                                    together with practitioners from all over
                                    the world
                                </p>
                                <div className="h-px bg-white mt-3" />
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                    <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                        <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                            Discover <BsArrowDown size={23} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="bottom-0 z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                                <img
                                    src={SubtractWhite}
                                    alt="SubtractWhite"
                                    className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container relative">
                    <div className="absolute lg:block hidden left-0 top-0">
                        <svg
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            ></circle>
                        </svg>
                    </div>
                    {this.renderClassesList("Newest")}
                    {this.renderClassesList("Popular")}
                    {this.renderClassesList("Featured")}

                    <div className="container mt-28" id="contentSection">
                        <p className="text-new-color font-light Poppins text-center w-full max-w-6xl mx-auto lg:text-[50px] md:text-[40px] text-[30px]">
                            Unlock Your Potential with Masterclasses Tailored
                            for Young Professionals, Mid-Level Experts, and
                            Seasoned Professionals
                        </p>
                        <div className="grid pt-10 gap-10 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                            <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                                <img
                                    src={skill}
                                    className="w-[90px] h-[88px] mb-4"
                                    alt="Learn"
                                />
                                <h1 className="Poppins font-light text-[30px]">
                                    Ready to Take Your Skills to the Next Level?
                                </h1>
                                <p className="Poppins my-5 text-[#16283A] text-[18px] font-normal leading-6">
                                    Explore our Training & Certification Page to
                                    unlock a world of opportunities!
                                </p>
                                <Link to={'/training-certificate'} className="Poppins primary text-[18px] font-normal leading-6">
                                    Explore {">>"}
                                </Link>
                            </div>
                            <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                                <img
                                    src={store}
                                    className="w-[90px] h-[88px] mb-4"
                                    alt="Learn"
                                />
                                <h1 className="Poppins font-light text-[30px]">
                                    Gain In-Depth Expertise in Your Procurement
                                    & Supply Chain Domain!
                                </h1>
                                <p className="Poppins my-5 text-[#16283A] text-[18px] font-normal leading-6">
                                    Discover our Module Classes taught by
                                    Industry Experts and elevate your
                                    professional journey.
                                </p>
                                <Link to={'/module'} className="Poppins primary text-[18px] font-normal leading-6">
                                    Explore Modules {">>"}
                                </Link>
                            </div>
                            <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                                <img
                                    src={book}
                                    className="w-[90px] h-[88px] mb-4"
                                    alt="Learn"
                                />
                                <h1 className="Poppins font-light text-[30px]">
                                    Join the Community of Knowledge Seekers!
                                </h1>
                                <p className="Poppins my-5 text-[#16283A] text-[18px] font-normal leading-6">
                                    Engage in Meaningful Discussions, Seek
                                    Advice, and Share Your Expertise for free on
                                    our vibrant Discussion Forum.
                                </p>
                                <Link to={'/discussions'} className="Poppins primary text-[18px] font-normal leading-6">
                                    Discussion Forum {">>"}
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-28">
                            <Testimonials />
                        </div> */}
                </div>
                <div className="mt-28 flex justify-center">
                    <img src={group} alt="group" className=" mx-auto object-cover" />
                </div>
                <div className="container">
                    <div className="mt-28">
                        <Trainers />
                    </div>
                    <div className="mt-28">
                        {plans && plans.length > 0 && (
                            <Paymentplan plans={plans} location={location} />
                        )}
                    </div>
                </div>
                {/* <SalePopup from={'classes'} /> */}
                {/* <Signinpopup ref={this.openSignIn} protect={true} /> */}
                <div className="mt-28 -mb-36 container">
                    <BookSpot />
                </div>
            </>
        );
    }
}

export default ProcureHome;
