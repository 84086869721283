import { FiX } from "react-icons/fi";
import { Dialog, Transition } from "@headlessui/react";
import React from 'react'
import { BsArrowRight } from "react-icons/bs";

const GetQuotationPopup = ({ isOpen, closePopup, Buttonlink }) => {
    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={closePopup}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-7xl md:px-10 overflow-hidden p-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]">
                                <div
                                    onClick={closePopup}
                                    className="z-10 flex items-center justify-end rounded-full cursor-pointer"
                                >
                                    <FiX className="primary" size={25} />
                                </div>
                                <Dialog.Title className="text-2xl font-bold text-gray-800 my-3 Poppins">
                                    Are you searching for reliable and high-quality service providers to fulfill your business needs? <br /> Look no further than our Sourcing Genie marketplace.
                                </Dialog.Title>
                                <p className="text-lg text-new-color Poppins">
                                    Access a diverse range of vetted suppliers and service providers, all in one centralized marketplace.
                                </p>

                                <div className="mt-3">
                                    <p className="text-lg text-new-color Poppins">As a Buyer on Our Platform, You'll Enjoy These Unbeatable Advantages:</p>
                                    <ul className="!list-none">
                                        <li className="text-lg text-new-color Poppins">1. Extensive Supplier</li>
                                        <li className="text-lg text-new-color Poppins">2. Streamlined RFQ Process</li>
                                        <li className="text-lg text-new-color Poppins">3. Hassle-Free Sourcing</li>
                                        <li className="text-lg text-new-color Poppins">4. Competitive Pricing</li>
                                        <li className="text-lg text-new-color Poppins">5. Trusted Seller Profiles</li>
                                        <li className="text-lg text-new-color Poppins">6. Efficient Collaboration</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <p className="text-lg text-new-color Poppins">Effortless RFQ Creation in Just 3 Steps:</p>
                                    <ul className="!list-none">
                                        <li className="text-lg text-new-color Poppins">1. Click on the "Create RFQ" button within the RFQ section.</li>
                                        <li className="text-lg text-new-color Poppins">2. You'll be seamlessly logged in to our Sourcing Genie platform, ready to fill out the details.</li>
                                        <li className="text-lg text-new-color Poppins">3. Our AI-assisted form will guide you through the process, ensuring you provide all the necessary information to receive personalized quotes from relevant sellers.</li>
                                    </ul>
                                </div>
                                <p className="text-lg my-3 text-new-color Poppins">Unlock the power of our Sourcing Genie marketplace!</p>
                                <Buttonlink Text="Join Sourcing Genie"
                                    onClick={() => window.open(process.env.REACT_APP_SOURCING_GENIE + "/login",'_blank')}
                                    className="flex items-center w-full max-w-[280px] h-11 bg--primary text-white justify-between px-5 rounded-full Poppins text-lg"
                                    Lasticon={<BsArrowRight size={22} />}
                                />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default GetQuotationPopup