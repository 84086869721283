import footer from "assets/images/Events/footer.png";
import React from "react";
import AnimateButton from "components/allcomponents/AnimteButton";

const EventsFooter = () => {
    return (
        <div className="knowledge-pocket md:h-[400px] h-[550px] w-full relative">
            <img
                src={footer}
                alt="footer"
                className="w-full h-full object-cover"
            />
            <div className="absolute w-full h-full inset-0">
                <div className="grid h-full pr-10 md:pl-20 pl-10 grid-cols-12 gap-4">
                    <div className="md:col-span-6 col-span-12 space-y-4 h-full justify-center flex flex-col">
                        <p className="white Poppins capitalize lg:text-6xl md:text-4xl text-3xl font-semibold md:text-left text-center">
                            SUPERCHARGE
                        </p>
                        <p className="white Poppins capitalize md:text-5xl text-xl font-extralight md:text-left text-center">
                            Your Event With Us
                        </p>
                        <div className="h-px bg-white w-full" />
                        <p className="white text-xl font-light Poppins">
                            Turn your next event into an industry-wide
                            phenomenon.
                        </p>
                        <div className="flex gap-3 items-center">
                            <AnimateButton
                                to={"/contact-us"}
                                text={"Contact Us"}
                                className={
                                    "py-2.5 w-52 whitespace-nowrap text-xl font-bold self-start Lato !hover:text-white !text-white px-5 bg-orange rounded-full flex justify-between items-center"
                                }
                            />
                        </div>
                    </div>
                    <div className="md:col-span-6 col-span-12 space-y-5 h-full md:place-content-center md:pl-20">
                        {[
                            "Expanded industry reach",
                            "Higher attendee engagement",
                            "Increased lead generation",
                            "Enhanced brand visibility",
                            "Access to targeted professionals",
                        ].map((item, index) => {
                            return (
                                <div key={index} className="flex items-center gap-x-3">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_2037_3589)">
                                            <path
                                                d="M0.921615 18H9.00046C9.25177 18 9.50519 17.904 9.69726 17.7118L17.711 9.69774C17.9036 9.50567 17.9999 9.25296 17.9999 9.00113V8.99984C17.9999 8.74748 17.9036 8.49494 17.711 8.30304L9.69711 0.288993C9.50503 0.0969177 9.25144 0.000302734 9.00031 0.000302734L0.921615 0.000244141C0.412725 0.000244141 0 0.412804 0 0.921694V17.0782C0 17.5868 0.41274 17.9994 0.921615 17.9994V18Z"
                                                fill="#FF6600"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2037_3589">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="white text-lg font-light Poppins">{item}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventsFooter;
