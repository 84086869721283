import React, { Component } from "react";
import client from "../../../graphql/client";
import userLikesThisAnswer from "../../../graphql/queries/userLikeThisAnswer";
import updateAnswerQuestion from "../../../graphql/mutations/likeAnswerMutation";
import heart from "assets/images/heart.png";
import heartColor from "assets/images/heartColor.png";
import commentoutline from "assets/images/commentoutline.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class AnswerFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImage: heart,
      likes: this.props.likes.paginatorInfo.total,
    };
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      if (this.props.answer.likes.data.some(vote => vote.users.id === this.props.authUserInformation.id)) {
        this.setState({
          currentImage: heartColor,
        });
      }
      else {
        this.setState({
          currentImage: heart,
        });
      }
      // client
      //   .query({
      //     query: userLikesThisAnswer,
      //     variables: {
      //       question_id: parseInt(this.props.question.id),
      //       answer_id: parseInt(this.props.answer.id),
      //     },
      //   })
      //   .then((res) => {
      //     const { error } = res.data.adminLikesThisAnswer;
      //     if (error) {
      //      
      //     } else {
      //       this.setState({
      //         currentImage: heart,
      //       });
      //     }
      //   });
    }
  }

  handleLikeCLicked = (e, qId, aId) => {
    this.props.isAuthenticated ?
      this._answerLikeMutation(qId, aId) : this.props.unathenticated(true);
  };

  _answerLikeMutation = (qId, aId) => {
    client
      .mutate({
        mutation: updateAnswerQuestion,
        variables: {
          question_id: parseInt(qId),
          answer_id: parseInt(aId),
        },
      })
      .then((res) => {
        if (this.props.authUserSocket?.connected) {
          this.props.authUserSocket?.emit("notify", res?.data?.likeAnswer?.user_id);
        }
        if (res?.data?.likeAnswer.error === "") {
          this.setState({
            currentImage: heartColor,
            likes: res.data.likeAnswer.total_like_votes,
          });
        } else {
          this.setState({
            currentImage: heart,

            likes: this.state.likes - 1,
          });
        }
      });
  };

  render() {
    return (
      <div className="post-footer">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-10">
            <div className="flex items-center cursor-pointer gap-x-3">
              <img
                alt="likes"
                src={this.state.currentImage}
                className="object-contain w-6 h-6"
                onClick={(e) =>
                  this.handleLikeCLicked(
                    e,
                    this.props.question.id,
                    this.props.answer.id
                  )
                }
              />
              <p className="gray fs-14">{this.state.likes}</p>
            </div>
            <Link
              to={`/discussions/${this.props.question.slug}`}
              className="flex items-center cursor-pointer gap-x-3">
              <img
                alt="comments"
                src={commentoutline}
                className="object-contain w-6 h-6"
              />
              <p className="gray fs-14">
                {this.props?.comments?.paginatorInfo?.total}
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authUserInformation: state.AuthUserReducer.authUserInformation,
  authUserSocket: state.AuthUserReducer.authUserSocket,
});


export default connect(mapStateToProps, null)(AnswerFooter);
