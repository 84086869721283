import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Buttonlink } from "../index";

const ContactUsCard = ({ type }) => {
	const buttonProps = {
		to: "/contact-us",
		Text: "Contact Us",
		Lasticon: <BsArrowRight className="primary" size={22} />,
		className:
			"bg-transparent font-bold !text-white flex items-center justify-between px-4 Lato text-xl focus:bg-transparent border-2 border-white rounded--full shadow-none h-12 w-52",
	};

	return (
		<div className="lg:mt-32 md:mt-32 mt-52 -mb-60 relative">
			<div className="contact_us lg:bg-contain bg-cover">
				<div
					className={`lg:px-20 md:px-16 px-10 ${
						type === "pharma" ? "py-8" : "py-16"
					} w-full max-w-5xl`}
				>
					<p className="text-[#B5E2FF] font-light Poppins lg:text-6xl md:text-4xl text-3xl pb-3">
						Contact With Us
					</p>
					{type === "pharma" ? (
						<div className="text-white Poppins font-light capitalize text-xl flex flex-col justify-start items-start gap-y-2">
							Elevate your Biotech & Pharma presence with our expertise:
							<div className="ml-8">
								<li>Transform your marketing strategies</li>
								<li>Connect with Pharma/Biotech industry decision-makers</li>
								<li>Enhance brand visibility and community engagement</li>
							</div>
							<div>
								From innovative marketing to targeted B2B exposure, we're your
								partner in achieving unparalleled success.
							</div>
							<div>Ready to reshape your market approach?</div>
						</div>
					) : type === "b2b" ? (
						<div className="text-white Poppins font-light capitalize text-xl flex flex-col justify-start items-start gap-y-2">
							Ignite Your Brand. Amplify Your Reach. Dominate Your Market.
							<div className="text-lg">
								From expert content to cutting-edge tech, we're your launchpad
								to B2B success.
							</div>
							<div className="text-lg">
								Ready to skyrocket your growth? Let's make waves together.
							</div>
						</div>
					) : (
						<p className="text-white Poppins font-light capitalize text-xl">
							Trust us to empower your community engagement, spotlight your
							innovations, and enhance your brand presence. Embrace our
							transformative solutions and take the lead in the digital realm.
							Connect with us today to embark on a dynamic journey toward
							reshaping your Biotech & Pharma marketing strategies and achieving
							unparalleled success.
						</p>
					)}

					<div className="mt-10">
						<Buttonlink {...buttonProps} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUsCard;
