import {
    Buttonlink,
    Fullscreenpopup,
    IsLoader,
    Noconnection,
    Nocontent,
    ProfileAvatar,
    Signinpopup,
} from "components";
import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/semantic.css";
import { seo } from "helpers/seo";
import { BsCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import {
    IoIosArrowBack,
    IoIosArrowForward,
    IoIosCloseCircle,
} from "react-icons/io";
import { connect } from "react-redux";
import GET_LEADER_BOARD from "../../graphql/queries/getLeaderBoard";
import { Query } from "@apollo/client/react/components";
import Auth from "../../Auth";
import moment from "moment";
import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import { extractValidationErrors } from "helpers/extractValidationErrors";

const auth = new Auth();

const topics = [
    {
        text: "Strategic Sourcing",
        value: 1,
    },
    {
        text: "Category Management",
        value: 2,
    },
    {
        text: "Contracting",
        value: 3,
    },
    {
        text: "Purchasing",
        value: 4,
    },
    {
        text: "Payment & Help Desk",
        value: 5,
    },
];
const year = [
    { key: "2023", text: "2023", value: 2023 },
    { key: "2022", text: "2022", value: 2022 },
    { key: "2021", text: "2021", value: 2021 },
    { key: "2020", text: "2020", value: 2020 },
    { key: "2019", text: "2019", value: 2019 },
    { key: "2018", text: "2018", value: 2018 },
    { key: "2017", text: "2017", value: 2017 },
];
const month_options = [
    { key: 1, text: "January", value: "1" },
    { key: 2, text: "February", value: "2" },
    { key: 3, text: "March", value: "3" },
    { key: 4, text: "April", value: "4" },
    { key: 5, text: "May", value: "5" },
    { key: 6, text: "June", value: "6" },
    { key: 7, text: "July", value: "7" },
    { key: 8, text: "August", value: "8" },
    { key: 9, text: "September", value: "9" },
    { key: 10, text: "October", value: "10" },
    { key: 11, text: "November", value: "11" },
    { key: 12, text: "December", value: "12" },
];

class Leaderboard extends Component {
    constructor(props) {
        super(props);
        this.openSignIn = React.createRef();
        this.SelectFullPopup = React.createRef();
        const currentmonthIntiger = moment().month();
        this.state = {
            Selectedtopics: null,
            openModal: false,
            currentmonth: moment().month(currentmonthIntiger).format("M"),
            currentyear: moment().year(),
            Selectedmonth: null,
            Selectedyear: null,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        seo({
            title: "Trending | Procurement League",
        });
        this.setState({
            Selectedmonth: this.state.currentmonth,
            Selectedyear: this.state.currentyear,
        });
    }

    openPopupModal = () => {
        auth.isAuthenticated()
            ? this.SelectFullPopup.current.openPopup()
            : this.openSignIn.current.openPopup();
    };

    handleInputChange = (event, data) => {
        auth.isAuthenticated()
            ? this.setState({
                ...this.state,
                [event.target.name]: data.value,
            })
            : this.setState({ openModal: true });
    };

    handleInputTopicsChange = (event, data) => {
        auth.isAuthenticated()
            ? this.setState({
                ...this.state,
                Selectedtopics: data.value,
            })
            : this.setState({ openModal: true });
    };

    handleInputYearChange = (event, data) => {
        auth.isAuthenticated()
            ? this.setState({
                ...this.state,
                Selectedyear: data.value,
            })
            : this.setState({ openModal: true });
    };

    handleInputMonthChange = (event, data) => {
        auth.isAuthenticated()
            ? this.setState({
                ...this.state,
                Selectedmonth: data.value,
            })
            : this.setState({ openModal: true });
    };

    handleInputReset = () => {
        const { currentmonth, currentyear } = this.state;
        const { selectedTags } = this.props;
        const tagIds = selectedTags.map(tag => Number(tag.id));

        this.setState({
            Selectedmonth: currentmonth,
            Selectedtopics: null,
            Selectedyear: currentyear,
        }, () => {
            this.props.handleTagRemove(tagIds);
        });
    };


    render() {
        let variables = {};
        const { Selectedtopics, Selectedyear, Selectedmonth } = this.state;
        let { selectedTags } = this.props;
        // console.log("selectedTags",selectedTags)
        if (selectedTags.length > 0) {
            let array = [];
            selectedTags.forEach((tags) => {
                array.push(Number(tags.id));
            });
            variables.tags = array;
        }

        if (Selectedtopics !== 0 && Selectedtopics !== null) {
            variables.category_id = Selectedtopics;
        }

        if (Selectedyear !== null) {
            variables.year = Selectedyear;
        }

        if (Selectedmonth !== null) {
            variables.month = parseInt(Selectedmonth);
        }

        // console.log("variables", variables)

        return (
            <div className="container">
                <div className="relative flex flex-col justify-between mb-10 lg:items-center lg:flex-row">
                    <Buttonlink
                        Text="back"
                        Icon={<IoIosArrowBack />}
                        onClick={() => this.props.history.goBack()}
                        className="z-10 flex items-center justify-center px-4 border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray Regular h-9 rounded--full darkGray w-28 hover:darkGray hover:opacity-80"
                    />
                    <h1 className="text-4xl text-center Medium md:text-5xl">Leaders</h1>
                    <div className="w-28" />
                    {auth.isAuthenticated() ? (
                        <Buttonlink
                            Text="Participate To Become Trending Person"
                            Lasticon={<IoIosArrowForward />}
                            to="/discussions"
                            className="right-0 flex items-center justify-center border-0 shadow-none lg:absolute fs-15 primary hover:primary Regular rounded--xl "
                        />
                    ) : null}
                </div>
                <div className="grid gap-4 lg:grid-cols-6">
                    <div className="lg:col-span-2">
                        <div className="p-5 mb-4 border rounded-xl">
                            <div className="mb-4">
                                <p className="mb-1">Select Topics</p>
                                <div className="bg-gray-100 rounded--lg">
                                    <Dropdown
                                        className="bg-transparent border-0 gray Regular rounded--lg"
                                        placeholder="Select Topics"
                                        fluid
                                        selection
                                        value={Selectedtopics}
                                        onChange={
                                            auth.isAuthenticated()
                                                ? this.handleInputTopicsChange
                                                : this.openPopupModal
                                        }
                                        options={topics}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <p className="mb-1">Select Year</p>
                                <div className="bg-gray-100 rounded--lg">
                                    <Dropdown
                                        className="bg-transparent border-0 gray Regular rounded--lg"
                                        placeholder="Select Year"
                                        fluid
                                        selection
                                        value={Selectedyear}
                                        onChange={
                                            auth.isAuthenticated()
                                                ? this.handleInputYearChange
                                                : this.openPopupModal
                                        }
                                        options={year}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <p className="mb-1">Select Month</p>
                                <div className="bg-gray-100 rounded--lg">
                                    <Dropdown
                                        className="bg-transparent border-0 gray Regular rounded--lg"
                                        placeholder="Select Month"
                                        fluid
                                        selection
                                        value={Selectedmonth}
                                        onChange={
                                            auth.isAuthenticated()
                                                ? this.handleInputMonthChange
                                                : this.openPopupModal
                                        }
                                        options={month_options}
                                    />
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="mb-4">
                                    <p className="mb-1">Select Tags</p>
                                    <div
                                        className="flex items-center h-12 px-3 bg-gray-100 cursor-pointer rounded--lg"
                                        onClick={this.openPopupModal}>
                                        <p style={{ color: "#bfbfbf" }}>Select Tags</p>
                                    </div>
                                </div>
                                <div className="flex flex-wrap items-center gap-2">
                                    {this.props.selectedTags.length > 0
                                        ? this.props.selectedTags.map((tags) => (
                                            <div
                                                className="flex items-center px-4 py-2 bg-gray-100 cursor-pointer hover:bg--primary hover:color-white-all rounded-xl"
                                                key={tags.id}>
                                                <p className="Light gray">{tags.title}</p>
                                                <IoIosCloseCircle
                                                    className="ml-2 darkGray"
                                                    size={20}
                                                    onClick={() => this.props.handleTagRemove(tags.id)}
                                                />
                                            </div>
                                        ))
                                        : null}
                                </div>
                            </div>
                            <div>
                                {auth.isAuthenticated() ? (
                                    <Buttonlink
                                        onClick={this.handleInputReset}
                                        className="flex items-center justify-center w-full h-12 border-0 shadow-none white hover:color-white Regular bg--danger hover:bg--danger focus:bg--danger hover:opacity-70 rounded--xl"
                                        Text="Reset"
                                    />
                                ) : (
                                    <Buttonlink
                                        onClick={() => this.setState({ openModal: true })}
                                        className="flex items-center justify-center w-full h-12 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 rounded--xl"
                                        Text="Search"
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Right Horizontal Flex 3 */}
                    <div className="lg:col-span-4">
                        {!auth.isAuthenticated() ?
                            <div style={{ fontSize: 19 }}>
                                <Alert severity="warning">
                                    <AlertTitle>
                                        <strong>Anonymous User</strong>
                                    </AlertTitle>
                                    Please{" "}
                                    <button
                                        onClick={() => this.openPopupModal()}
                                        className={"primary"}>
                                        Login
                                    </button>
                                    {" "}
                                    To Connect With Other Procurement Practitioners And
                                    View LeaderBoard.
                                </Alert>
                            </div>
                            :
                            <Query query={GET_LEADER_BOARD} variables={variables}>
                                {({ loading, error, data, fetchMore, refetch }) => {
                                    if (loading) {
                                        return <IsLoader className="w-32 h-32 mx-auto" />;
                                    }
                                    if (error) {
                                        if (auth.isAuthenticated()) {
                                            extractValidationErrors(error);
                                        }
                                        return (
                                            // <div className="flex flex-col justify-center m-auto text-center">
                                            <div>
                                                {auth.isAuthenticated() ? (
                                                    <Noconnection />
                                                ) : (
                                                    <div style={{ fontSize: 19 }}>
                                                        <Alert severity="warning">
                                                            <AlertTitle>
                                                                <strong>Anonymous User</strong>
                                                            </AlertTitle>
                                                            Please{" "}
                                                            <button
                                                                onClick={() => this.setState({ openModal: true })}
                                                                className={"primary"}>
                                                                Login
                                                            </button>
                                                            {" "}
                                                            To Connect With Other Procurement Practitioners And
                                                            View LeaderBoard.
                                                        </Alert>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                    if (data !== undefined && data.leaderboard.length > 0) {
                                        // console.log(data.leaderboard)
                                        return (
                                            <>
                                                {/*<div className="flex items-center justify-between px-4 py-3 cursor-pointer rounded-t-md bg--primary hover:bg-gray-100 hover:black-all">*/}
                                                {/*  <div className="flex items-center flex-grow">*/}
                                                {/*    <p className="flex items-center justify-center w-8 h-8 mr-4 rounded-lg bg--white fs-12 primary">*/}
                                                {/*      1*/}
                                                {/*    </p>*/}
                                                {/*    <div className="flex items-center">*/}
                                                {/*      /!*<img src={Anders} className="w-20 h-20 rounded-full" />*!/*/}
                                                {/*      <div className="ml-3">*/}
                                                {/*        <p className="text-xl tracking-wide Medium white">*/}
                                                {/*          Muhammad Arfat*/}
                                                {/*        </p>*/}
                                                {/*        <p className="Light fs-13 white">@arfat</p>*/}
                                                {/*      </div>*/}
                                                {/*    </div>*/}
                                                {/*  </div>*/}
                                                {/*  <div className="flex flex-col items-center justify-center">*/}
                                                {/*    <p className="white">2</p>*/}
                                                {/*    <BsCaretUpFill size={22} color="#2ECC71" />*/}
                                                {/*  </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="divider" />*/}
                                                {/*<div className="flex items-center justify-between px-4 py-3 bg-gray-200 cursor-pointer hover:bg-gray-100">*/}
                                                {/*  <div className="flex items-center flex-grow">*/}
                                                {/*    <p className="flex items-center justify-center w-8 h-8 mr-4 rounded-lg bg--primary fs-12 white">*/}
                                                {/*      2*/}
                                                {/*    </p>*/}
                                                {/*    <div className="flex items-center">*/}
                                                {/*      /!*<img src={Anders} className="w-20 h-20 rounded-full" />*!/*/}
                                                {/*      <div className="ml-3">*/}
                                                {/*        <p className="text-xl tracking-wide Medium">*/}
                                                {/*          Muhammad Arfat*/}
                                                {/*        </p>*/}
                                                {/*        <p className="Light fs-13 darkGray">@arfat</p>*/}
                                                {/*      </div>*/}
                                                {/*    </div>*/}
                                                {/*  </div>*/}
                                                {/*  <div className="flex flex-col items-center justify-center">*/}
                                                {/*    <p>2</p>*/}
                                                {/*    <BsFillCaretDownFill size={22} className="danger" />*/}
                                                {/*  </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="divider" />*/}
                                                {data?.leaderboard?.map((user, index) => (
                                                    <>
                                                        <div
                                                            className="flex items-center justify-between px-4 py-3 cursor-pointer hover:bg-gray-100"
                                                            key={user.id}>
                                                            <div
                                                                className="flex items-center flex-grow"
                                                                key={user.id}>
                                                                {index <= 2 ? (
                                                                    <p className="flex items-center justify-center w-8 h-8 mr-4 rounded-lg bg--primary fs-12 white">
                                                                        {index + 1}
                                                                    </p>
                                                                ) : (
                                                                    <p className="flex items-center justify-center w-8 h-8 mr-4 rounded-lg fs-12">
                                                                        {index + 1}
                                                                    </p>
                                                                )}
                                                                <div className="flex items-center">
                                                                    <ProfileAvatar
                                                                        platform={"PL"}
                                                                        classname={"h-20 w-20 rounded-full"}
                                                                        singleUser={user}
                                                                        sizeStyle={{ height: 48, width: 48 }}
                                                                    />
                                                                    <div className="ml-3">
                                                                        <p className="text-lg tracking-wide Medium">
                                                                            {user.firstname} {user.lastname}
                                                                        </p>
                                                                        <p className="Light fs-12 darkGray">
                                                                            @{user.username}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {user.down_position !== null ? (
                                                                <div className="flex flex-col items-center justify-center">
                                                                    <p>{user.down_position} </p>
                                                                    <BsFillCaretDownFill
                                                                        size={22}
                                                                        className="danger"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="flex flex-col items-center justify-center">
                                                                    <p>{index + 4}</p>
                                                                    <BsCaretUpFill size={22} color="#2ECC71" />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="divider" />
                                                    </>
                                                ))}

                                                {/*<div className="flex items-center justify-between px-4 py-3 cursor-pointer hover:bg-gray-100">*/}
                                                {/*  <div className="flex items-center flex-grow">*/}
                                                {/*    <p className="flex items-center justify-center w-8 h-8 mr-4 rounded-lg fs-12 gray">*/}
                                                {/*      4*/}
                                                {/*    </p>*/}
                                                {/*    <div className="flex items-center">*/}
                                                {/*      <img src={Anders} className="w-20 h-20 rounded-full" />*/}
                                                {/*      <div className="ml-3">*/}
                                                {/*        <p className="text-xl tracking-wide Medium">*/}
                                                {/*          Muhammad Arfat*/}
                                                {/*        </p>*/}
                                                {/*        <p className="Light fs-13 darkGray">@arfat</p>*/}
                                                {/*      </div>*/}
                                                {/*    </div>*/}
                                                {/*  </div>*/}
                                                {/*  <div className="flex flex-col items-center justify-center">*/}
                                                {/*    <p>2</p>*/}
                                                {/*    <BsCaretUpFill size={22} color="#2ECC71" />*/}
                                                {/*  </div>*/}
                                                {/*</div>*/}
                                            </>
                                        );
                                    } else {
                                        return <Nocontent />;
                                    }
                                }}
                            </Query>}
                    </div>
                </div>
                <Fullscreenpopup ref={this.SelectFullPopup} title={"Select Tags"} />
                <Signinpopup ref={this.openSignIn} protect={true} />
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    selectedTags: state.DiscussionSearchReducer.selectedTags,
    tags_ids: state.DiscussionSearchReducer.tags_ids,
});

const mapDispatchToProps = (dispatch) => {
    return {
        handleTagRemove: (tags) => dispatch({ type: "REMOVE_TAGS", payload: tags }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
