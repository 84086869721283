import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "assets/images/register.png";
import Checkbox from "@mui/material/Checkbox";
import client from "../../../graphql/client";
import Alert from "@mui/material/Alert";
import REGISTER from "../../../graphql/mutations/registerMutation";
import { seo } from "../../../helpers/seo";
import { Buttonlink, IsLoader } from "components";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { extractValidationErrors } from "../../../helpers/extractValidationErrors";
import GoogleLoginButton from "components/allcomponents/GoogleLoginButton";
import LinkedInPage from "components/allcomponents/LinkedInPage";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            firstname: "",
            lastname: "",
            password: "",
            confirmPassword: "",
            acceptTerms: false,
            loading: false,
            errors: "",
            error: false,
            message: "",
            success: false,
            pswdType: true,
            confirmPasswordType: true,
        };
    }

    componentDidMount() {
        seo({
            title: "Signup | Procurement League",
        });
        window.scrollTo(0, 0);

        if (this.state.success) {
            return <Redirect push to={"/account-activation-email"} />;
        }
    }

    handleRegisterUser = (event) => {
        event.preventDefault();
        this.setState({
            error: false,
            errors: [],
        });
        const { email, username, firstname, lastname, password, acceptTerms, confirmPassword } =
            this.state;

        if (email) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                this.setState({
                    error: true,
                    errors: "Please enter valid email address",
                });
                return;
            }
        }

        if (email === "") {
            this.setState({
                error: true,
                errors: "Please Enter Email Address",
            });
            return;
        }

        if (username === "") {
            this.setState({
                error: true,
                errors: "Please Enter Username",
            });
            return;
        }

        if (firstname === "") {
            this.setState({
                error: true,
                errors: "Please Enter Firstname",
            });
            return;
        }

        if (lastname === "") {
            this.setState({
                error: true,
                errors: "Please Enter Lastname",
            });
            return;
        }

        if (password === "") {
            this.setState({
                error: true,
                errors: "Please Enter Password",
            });
            return;
        }

        if (password.charAt(0) === ' ') {
            this.setState({
                error: true,
                errors: "Password should not contain spaces",
            });
            return;
        }
        if (password !== confirmPassword) {
            this.setState({
                error: true,
                errors: "password and confirm password is not matching",
            });
            return;
        } if (password.length < 8) {
            this.setState({
                error: true,
                errors: "password must be atleast 8 characters long",
            });
            return;
        }
        this.setState({
            loading: true,
            error: false,
            errors: [],
        });

        client
            .mutate({
                mutation: REGISTER,
                variables: {
                    email: email,
                    username: username,
                    firstname: firstname,
                    lastname: lastname,
                    password: password,
                },
            })
            .then((res) => {
                this.setState(
                    {
                        loading: false,
                        success: true,
                    },
                    function () {
                        this.props.history?.history.push(
                            "/account-activation-email"
                        );
                    }
                );
            })
            .catch((err) => {
                console.log("err", err);
                this.setState({
                    errors: extractValidationErrors(err),
                    loading: false,
                    error: true,
                });
            });
    };

    showEyePswd = (type) => {
        if (type === "Password") {
            this.setState({ pswdType: !this.state.pswdType });
        }
        if (type === "Confirm Password") {
            this.setState({ confirmPasswordType: !this.state.confirmPasswordType });
        }

    };

    render() {
        const {
            email,
            username,
            firstname,
            lastname,
            password,
            confirmPassword,
            acceptTerms,
            error,
            loading,
            errors,
            success,
        } = this.state;
        return (
            <div className="container relative">
                <div className="grid gap-4 xl:grid-cols-7 lg:grid-cols-5">
                    <div className="xl:col-start-3 xl:col-span-3 lg:col-start-2 lg:col-span-3">
                        <div className="relative">
                            <h1 className="text-4xl font-light text-center lg:text-5xl Poppins">
                                Sign Up Today!
                            </h1>
                            <img
                                src={logo}
                                alt="logo"
                                className="absolute top-0 right-0 md:block hidden"
                            />
                        </div>
                        <div className="md:mt-36 mt-10 space-y-5">
                            <div className="flex items-center">
                                <input
                                    placeholder="Email Address"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={email}
                                    maxLength={34}
                                    type="email"
                                    onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                    }
                                    required={true}
                                />
                            </div>

                            <div className="flex items-center">
                                <input
                                    placeholder="Username"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={username}
                                    maxLength={26}
                                    type="text"
                                    onChange={(e) =>
                                        this.setState({
                                            username: e.target.value,
                                        })
                                    }
                                    required={true}
                                />
                            </div>
                            {/* <div style={{ height: 20 }}>
                {errors.find((error) => error.key === "username")}
              </div> */}

                            <div className="flex items-center">
                                <input
                                    placeholder="First Name"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={firstname}
                                    maxLength={26}
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[a-zA-Z]*$/.test(value) && value.length <= 26) {
                                            this.setState({
                                                firstname: value,
                                            });
                                        }
                                    }}
                                    required={true}
                                />
                            </div>
                            <div className="flex items-center">
                                <input
                                    placeholder="Last Name"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={lastname}
                                    maxLength={26}
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[a-zA-Z]*$/.test(value) && value.length <= 26) {
                                            this.setState({
                                                lastname: value,
                                            });
                                        }
                                    }}
                                    required={true}
                                />
                                {/* <input
                                    placeholder="Last Name"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={lastname}
                                    maxLength={26}
                                    type="text"
                                    onChange={(e) =>
                                        this.setState({
                                            lastname: e.target.value,
                                        })
                                    }
                                    required={true}
                                /> */}
                            </div>
                            {/* <div style={{ height: 20 }}>
                {errors.find((error) => error.key === "lastname")}
              </div> */}
                            <div className="flex items-center bg-[#F2F2F2] rounded-full">
                                <input
                                    placeholder="Password"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={password}
                                    minLength={8}
                                    maxLength={26}
                                    type={this.state.pswdType ? "password" : "text"}
                                    onChange={(e) =>
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }
                                    required={true}
                                />
                                <div
                                    onClick={() => this.showEyePswd("Password")}
                                    className="flex items-center justify-center w-12 h-12 cursor-pointer"
                                >
                                    {this.state.pswdType ? (
                                        <BsEye size={20} className="darkGray" />
                                    ) : (
                                        <BsEyeSlash size={20} />
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center bg-[#F2F2F2] rounded-full">
                                <input
                                    placeholder="Confirm Password"
                                    className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    value={confirmPassword}
                                    minLength={8}
                                    maxLength={26}
                                    type={this.state.confirmPasswordType ? "password" : "text"}
                                    onChange={(e) =>
                                        this.setState({
                                            confirmPassword: e.target.value,
                                        })
                                    }
                                    required={true}
                                />
                                <div
                                    onClick={() => this.showEyePswd("Confirm Password")}
                                    className="flex items-center justify-center w-12 h-12 cursor-pointer"
                                >
                                    {this.state.confirmPasswordType ? (
                                        <BsEye size={20} className="darkGray" />
                                    ) : (
                                        <BsEyeSlash size={20} />
                                    )}
                                </div>
                            </div>

                            {/* <div style={{ height: 20 }}>
                {errors.find((error) => error.key === "password")}
              </div> */}

                            <div className="flex justify-between">
                                <Checkbox
                                    className="primaryCheckboxes"
                                    value={acceptTerms}
                                    onChange={(event) => {
                                        this.setState({
                                            acceptTerms: event.target.checked,
                                        });
                                    }}
                                />
                                <p className="text-color">
                                    Confirm that you agree to{" "}
                                    <Link
                                        to="/term-of-services"
                                        className="ml-1 primary hover:opacity-70 Poppins"
                                    >
                                        Procurement League's Terms of Services{" "}
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        to="/privacy-policy"
                                        className="ml-1 primary hover:opacity-70 Poppins"
                                    >
                                        Privacy Poilicies{" "}
                                    </Link>
                                </p>
                            </div>

                            {error ? <div style={{ height: 15 }} /> : false}
                            {error ? (
                                <Alert severity="error">{errors}</Alert>
                            ) : null}
                            {success && !error ? (
                                <>
                                    <div style={{ height: 15 }} />
                                    <Alert severity="success">
                                        Congrats!! your account is created check
                                        your email to activate your account
                                    </Alert>
                                </>
                            ) : null}

                            <Buttonlink
                                Text={
                                    loading ? (
                                        <IsLoader
                                            className="h-full"
                                            white={true}
                                        />
                                    ) : (
                                        "Sign up"
                                    )
                                }
                                onClick={
                                    loading ? true : this.handleRegisterUser
                                }
                                className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-12 w-full"
                            />
                            <div>
                                <p className="mt-2 text-center text-color">
                                    Already have an account?
                                    <Link
                                        to="/login"
                                        className="ml-1 primary hover:opacity-70 Poppins underline"
                                    >
                                        Login
                                    </Link>
                                </p>
                            </div>

                            <GoogleLoginButton />
                            <LinkedInPage />
                        </div>
                    </div>{" "}
                </div>
                <div className="absolute bottom-44 lg:block hidden">
                    <svg
                        width="301"
                        height="301"
                        viewBox="0 0 301 301"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_2426_4423)">
                            <path
                                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                                fill="#FF6600"
                            />
                            <path
                                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                                fill="#EAF2F9"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2426_4423">
                                <rect
                                    width="212.39"
                                    height="212.39"
                                    fill="white"
                                    transform="translate(0 150.182) rotate(-45)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute bottom-0 right-0 lg:block hidden">
                    <svg
                        width="301"
                        height="301"
                        viewBox="0 0 301 301"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_2426_4423)">
                            <path
                                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                                fill="#FF6600"
                            />
                            <path
                                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                                fill="#EAF2F9"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2426_4423">
                                <rect
                                    width="212.39"
                                    height="212.39"
                                    fill="white"
                                    transform="translate(0 150.182) rotate(-45)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        );
    }
}

export default Register;
