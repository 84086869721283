import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function MaxWidthDialog(props) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.show}
      onClose={props.close}
    >
      <div className="flex items-center justify-between px-5 py-3 bg-gray-200 border-b rounded-t-lg border-gray-200">
        <h4 className="mr-auto text-lg Medium">Add tittle here</h4>
        <div onClick={props.close}>
          <AiOutlineCloseCircle className="h-[25px] w-[25px] cursor-pointer" />
        </div>
      </div>
      <div className={"px-8 py-5 "}></div>
    </Dialog>
  );
}
