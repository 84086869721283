import React, { Component } from "react";
import client from "../../graphql/client";
import { IsLoader } from "../../components";
import ACTIVATE_USER from "../../graphql/mutations/activateUserAccount";

class ActivateUserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let token = window.location.pathname;
    client
      .mutate({
        mutation: ACTIVATE_USER,
        variables: {
          token: token,
        },
      })
      .then((response) => {
        // console.log("response",response)
        // window.location.href = '/';
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  render() {
    return (
      <div className="Profile-page margin-top-50px">
        <div className="container">
          <IsLoader />
        </div>
      </div>
    );
  }
}

export default ActivateUserAccount;
