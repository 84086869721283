import { gql } from "@apollo/client";

export default gql`
query alltrainers  {
    alltrainers(
        orderBy: [{ column: "order_by", order: ASC }]
    ){
        id
        name
        title
        bio
        order_by
        company
        is_active
        image_path
    }
  }
`;
