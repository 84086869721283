import { Getintouch, Reasonsubscribe, NewsArticles } from "components";
import { seo } from "helpers/seo";
import React, { Component } from "react";
import NewsletterImage from "assets/images/newsletter/newsletterImage2.png";
import SubscribeButton from "../../components/allcomponents/subscribebutton";
import NewLetterSubscribeDialog from "./NewLetterSubscribeDialog";
import plus from "assets/images/newsletter/plus.png";
import circle from "assets/images/newsletter/circle.png";
import circleTick from "assets/images/newsletter/circleTick.png";
import { BsLinkedin, BsWhatsapp } from "react-icons/bs";
import { ToastContainer } from "react-toastify";

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Newsletter | Procurement League",
    });
  }

  closeModal = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <div className="relative bg--lightBlue -mt-[50px] pt-[100px] pb-[50px] lg:-mb-[180px] -mb-[80px]">
        <ToastContainer />

        <img
          src={plus}
          alt=" "
          className="absolute right-12 top-5 w-[98px] h-[98px] lg:block hidden"
        /> 
        <img
          src={circleTick}
          alt=" "
          className="absolute right-16 top-[930px] w-[327px] h-[258px] lg:block hidden"
        /> 
        <img
          src={circle}
          alt=" "
          className="absolute left-36 top-[1120px] w-[81px] h-[81px] lg:block hidden"
        /> 
        <img
          src={circle}
          alt=" "
          className="absolute left-[590px] bottom-[105px] w-[126px] h-[126px] lg:block hidden"
        /> 
        <div className="container flex-grow">
          <div className="shadow--sm custom-shadow rounded-3xl sm:p-10 xs:p-4 bg-white">
            <h4 className="mt-4 mb-3 text-center Poppins lg:text-[50px] text-[30px] font-[300]">
              Subscribe To Newsletter
            </h4>
            <div className="grid gap-4 lg:grid-cols-2">
              <div className="mr-auto xs:px-4 flex flex-col justify-center w-full">
                <SubscribeButton location="newsletterspage" />
                <div className="mt-5 space-y-5">
                  <p className="text-lg text-[#B2B2B2] font-normal">You can also follow us on any of our social media channels below! <br /> Your details are safe with us, and will never be sold, rented or given to a third party!</p>
                  <div className="flex gap-3 items-center">
                    <BsLinkedin
                      size={35}
                      color="#0a66c2"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/procurement-league/",
                          "_blank"
                        )
                      }
                    />
                    <BsWhatsapp
                      size={35}
                      color="#2ec452"
                      onClick={() =>
                        window.open(
                          "https://wa.me/919901166886?text=Hi%2C%0AI%20need%20some%20information",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="-mr-32">
                <img
                  alt="Newsletter"
                  src={NewsletterImage}
                  className="object-cover m-auto lg:block hidden"
                />
              </div>
            </div>
          </div>

          {/* <div className="mt-28">
            <NewsArticles />
          </div> */}
          <div className="mt-24 mx-10">
            <Reasonsubscribe />
          </div>
          {/* <div className="flex items-center justify-center mt-5 xs:px-2">
            <Getintouch header={"want to subscribe for newsletter"} />
          </div> */}

          {/* <NewLetterSubscribeDialog
            open={this.state.open}
            closeModal={this.closeModal}
          /> */}
        </div>
      </div>
    );
  }
}

export default Newsletter;
