import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Nocontent } from "components";

export default function MaxWidthDialog(props) {
  let user = props.user;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.show}
      onClose={props.close}>
      <div className="flex items-center px-5 py-3 bg-gray-200 border-b border-gray-200">
        <h4 className="mr-auto text-lg Medium">Reviews</h4>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.close}
          aria-label="close"
          className="hover:text-[#f58020]">
          <IoCloseCircleOutline size={32} />
        </IconButton>
      </div>
      <div className={"px-8 py-5 "}>
        {user.sellerReviews.length === 0 && (
          <div className={"pt-4"}>
            <Nocontent />
          </div>
        )}
        {user.sellerReviews.map((review) => {
          return (
            <div className="py-3 border-b">
              <div className="flex items-center gap-x-3">
                {review?.buyer.profile_photo ? (
                  <img
                    className="object-cover rounded-full h-14 w-14"
                    onError={(e) => {
                      e.target.src = `${process.env.REACT_APP_MAIN_URL}/images/user_images/nobody_m.original.jpg`;
                    }}
                    src={
                      process.env.REACT_APP_MRO_URL +
                      `/uploads/profile_images/` +
                      review.buyer.profile_photo
                    }
                    alt={user.username}
                  />
                ) : (
                  <Avatar
                    id={review.buyer.id}
                    alt={review.buyer.firstname}
                    className={"object-cover rounded-full mr-3"}
                    sx={{ height: 28, width: 28 }}
                  />
                )}
                <div>
                  <div className="Medium text-[15px] text-black ">
                    {`${review.buyer.firstname} ${review.buyer.lastname}`}
                  </div>
                  <p className=" gray text-[12px] Light">
                    @{review.buyer.username}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-x-3">
                {/* <div className="object-cover rounded-full w-14" /> */}
                <p className="text-gray-700 ml-[65px]">{review.comment}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
}
