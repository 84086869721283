import {gql} from "@apollo/client";

export default gql`
    mutation UpdateAnswerOrComment($dataType: String!, $id: Int!, $text: String!) {
        updateAnswerOrComment(dataType: $dataType, id: $id, text: $text) {
            error
            message
        }
    }
`;
