import { GET_OPEN_COURSE_REVIEWS } from "../actions/types";
import { produce } from "immer";
const state = {
  allCoursesReviews: [],
};

function CourseReviewsReducer(mState = { ...state }, action) {
  switch (action.type) {
    case GET_OPEN_COURSE_REVIEWS:
      return produce(mState, (draftState) => {
        draftState.allCoursesReviews = [];
        action.payload.forEach((review) => {
          draftState.allCoursesReviews.push(review);
        });
      });

    default:
      return { ...mState };
  }
}

export default CourseReviewsReducer;
