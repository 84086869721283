import Avatar from "@mui/material/Avatar";
import { PROFILE_PHOTO_URL } from "../../../config/constants";
import React, { useMemo } from "react";

export default function ProfileAvatar({ singleUser, sizeStyle, className }) {
    // Use useMemo to create a memoized photoUrl
    const photoUrl = useMemo(() => {
        if (singleUser?.profile_photo) {
            // console.log('first', PROFILE_PHOTO_URL, singleUser.profile_photo)
            return `${singleUser.profile_photo}`;
        }
        return null; // Return null if profile_photo is not available
    }, [singleUser?.profile_photo]);

    return (
        <Avatar
            id={singleUser?.id}
            alt={singleUser?.firstname}
            src={photoUrl}
            sx={sizeStyle}
            className={className}
            key={singleUser?.id}
        />
    );
}
