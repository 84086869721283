import React from "react";
import { BsPlusLg } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import { Query } from "@apollo/client/react/components";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import GET_ALL_CASE_STUDIES from "../../graphql/queries/caseStudies";
import {
    Noconnection,
    IsLoader
} from "components";

const CaseStudies = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let variables = {};

    return (
        <div className="container">
            <h1 className="text-center text-white Poppins my-4 lg:text-5xl text-4xl font-light pb-3">
                Case Studies
            </h1>
            <Query query={GET_ALL_CASE_STUDIES} variables={variables}>
                {/* Case Studies */}
                {({ loading, error, data }) => {
                    if (loading) {
                        return (
                            <div className="my-10">
                                <IsLoader className="w-32 h-32 mx-auto" />
                            </div>
                        );
                    }
                    if (error) return <Noconnection />;

                    if (data !== undefined) {
                        return (
                            <>
                                {data.getAllCaseStudies.map((item) => {
                                    return (
                                        <div key={item.id}>
                                            <h1 className="Poppins success-new font-semibold text-2xl text-center mt-4 pb-2">
                                                {item.title}: {item.sub_title}
                                            </h1>
                                            <h1
                                                dangerouslySetInnerHTML={{ __html: item.conclusion }}
                                                className="Poppins important:white-all font-normal text-1xl text-center pb-5"
                                            ></h1>
                                            <div className="flex flex-col gap-4 bg-white custom-shadow p-5 rounded-3xl divide-y">
                                                {item.sections.map((section) => {
                                                    const panelId = `panel${section.id}`;
                                                    return (
                                                        <div key={item.id}>
                                                            <Accordion
                                                                expanded={expanded[panelId]}
                                                                onChange={handleChange(panelId)}
                                                                className="p-2 overflow-hidden !shadow-none"
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        <BsPlusLg
                                                                            className="ml-5 success-new"
                                                                            size={28}
                                                                        />
                                                                    }
                                                                >
                                                                    <p className="text-[#3B455A] text-lg Poppins font-semibold">
                                                                        {section.section_name}
                                                                    </p>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <p className="text-[#3B455A] text-3xl Poppins font-semibold">
                                                                        {section.section_name}
                                                                    </p>
                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: section.section_content,
                                                                        }}
                                                                        className="text-[#3B455A] py-5 font-normal text-lg Poppins"
                                                                    ></p>
                                                                    <div className="mt-2">
                                                                        {section.images.map((img, index) => {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <img
                                                                                        src={`${img.path}`}
                                                                                        alt="casestudy"
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        );
                    }
                }}
            </Query>
        </div>
    );
};

export default CaseStudies;
