import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Loader } from "semantic-ui-react";
import "../../../assets/css/semantic.css";

function Backdroploader(props) {
  return (
    <Backdrop className="z-10 primary--loader backdroploader" open="True">
      <div className="primary--loader">
        {props.hideSpinner ? (
          false
        ) : (
          <Loader active inline="centered" size="large" className="white ">
            Loading...
          </Loader>
        )}
        {props.message ? (
          <h1 className="mt-4 text-white">{props.message}</h1>
        ) : (
          ""
        )}
      </div>
    </Backdrop>
  );
}

export default Backdroploader;
