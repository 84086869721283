import React from "react";
import Cross from "../../../../assets/images/CrossImg.png";

export default function mainpageskeleton() {
  return (
    <div className="container overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center h-screen mb-48 animate-pulse">
        <img src={Cross} className="h-[20rem]" />
        <img src={Cross} className="h-[20rem]" />
        <img src={Cross} className="h-[20rem]" />
        <img src={Cross} className="h-[20rem] lg:block hidden" />
        <img src={Cross} className="h-[20rem] lg:block hidden" />
        <img src={Cross} className="h-[20rem] lg:block hidden" />
        <img src={Cross} className="h-[20rem] lg:block hidden" />
        <img src={Cross} className="h-[20rem] lg:block hidden" />
        <img src={Cross} className="h-[20rem] lg:block hidden" />
      </div>
    </div>
  );
}