import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "Group A", value: 100 },
  { name: "Group B", value: 0 },
];
const COLORS = ["#f58020", "#ff770033"];

export default class ChartsData extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let total = this.props.sgFeaturesTotal ?? 0;
    let used = (this.props.sgFeaturesTotal - this.props.sgFeaturesRemaining) ?? 0;
    let remaining = this.props.sgFeaturesRemaining ?? 0;
    let endAngle = 270 - ((360 / total) * (used));
    return (
      <div className="max-w-[170px]">
        <div className="w-[100px] h-[100px] relative flex items-center justify-center">
          <ResponsiveContainer
            width="100%"
            height="100%"
          // className={"rotate-90 "}
          >
            <PieChart width={60} height={60} onMouseEnter={this.onPieEnter}>
              <Pie
                data={data}
                cx={45}
                cy={45}
                innerRadius={43}
                outerRadius={50}
                fill="#f58020"
                startAngle={endAngle}
                endAngle={-90}
                paddingAngle={null}
                dataKey="value">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <h4 className="absolute Poppins primary text-3xl">{remaining}</h4>
        </div>
        <p className="text-new-color text-lg text-center mt-2">
          {used !== undefined
            ? `${used} out of ${total}`
            : ''}
        </p>
      </div>
    );
  }
}
