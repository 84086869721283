import { gql } from "@apollo/client";

export default gql`
  query UserSearch($cursor: Int, $groupSearch: Boolean, $q: String) {
    UserSearch(
      first: 15
      page: $cursor
      email: $q
      groupSearch: $groupSearch
      orderBy: [{ column: "created_at", order: DESC }]
    ) {
      data {
        id
        username
        firstname
        lastname
        email
        tagline
        profile_photo
        color
      }
      paginatorInfo {
        total
        count
        currentPage
        lastPage
      }
    }
  }
`;
