import React, { useEffect } from "react";
import { BsArrowDown, BsArrowRight, BsPlusLg } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Subtract from "assets/images/Subtract.png";
import Learn from "assets/images/buyer/Learn.png";
import Source from "assets/images/buyer/Source.png";
import Community from "assets/images/buyer/Community.png";
import banner from "assets/images/buyer/banner.webp";
import Certificate from "assets/images/buyer/Certificate.png";
import quotes from "assets/images/our-team/quotes.png";
import { Knowledgepocket } from "components";
import { Link } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";
import Slider from "react-slick";
import JamesMoran from "assets/images/SuccessStories/JamesBW.png"
import Getquoatation from "assets/images/buyer/getquoatation.png"
import Research from "assets/images/buyer/Research.png"
import events from "assets/images/Sellers/noun-events.png";
import forum from "assets/images/Sellers/noun-forum.png";
import AnnabelKaur from "assets/images/HireExpert/Mask_two.png";
import Chris from "assets/images/buyer/Chris.jpg";
import knowledge from "assets/images/Sellers/noun-knowledge.png";
import { useHistory } from "react-router-dom";
import Erik from "assets/images/Modules/Erik.png";

const BuyerPage = () => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const history = useHistory();

    const accordionData = [
        {
            id: "panel1",
            title: "Masterclass",
            content: [
                "Direct insights from industry leaders.",
                "Practical strategies for success.",
                "Insider tips and knowledge.",
                "Thought-provoking discussions",
                "Enhanced professional skills.",
            ],
            testimonials: [
                { description: 'This sprint aims to guide participants through decision-making processes and streamline workflows to boost the success of your S2P project.', author: 'Anders Lillevik' },
                { description: 'Take worked examples from the prepared live project lists in my masterclass and develop the plan for engaging with finance.', author: 'Pauline King' },
            ]
        },
        {
            id: "panel2",
            title: "Modules",
            content: [
                "Comprehensive learning bundles.",
                "Deep dive into multiple subjects.",
                "Connect dots between disciplines.",
                "Stimulate critical thinking.",
                "Bridge knowledge gaps.",
            ],
            testimonialsSlider: [
                { author: 'Erik Stavrand', description: "Redefine procurement excellence: prioritize long-term value and innovation over mere cost-cutting.", img: Erik },
                { author: 'Chris Shanahan', description: "Transform your procurement strategy with guidance towards operational excellence.", img: Chris },
            ]
        },
        {
            id: "panel3",
            title: "Trainings & Certification",
            content: [
                "Customized programs for companies.",
                "Industry-recognized certifications.",
                "Practical skills and knowledge.",
                "Mentee-Mentor support.",
                "Holistic leadership development.",
            ],
            image: Certificate,
        },
    ];
    const OfferData = [
        {
            id: "panel4",
            title: "Get Quotation",
            content: [
                "Access verified sellers for complex services.",
                "Exclusive invitations to RFQ.",
                "Streamlined vendor selection.",
                "Ensure transparency in procurement.",
                "Simplify complex service purchasing.",
            ],
            image: Getquoatation,
        },
        {
            id: "panel5",
            title: "Expert Recommendation",
            content: [
                "Tap into specialized knowledge.",
                "Make informed decisions.",
                "Gain tailored insights.",
                "Enhance competitive advantage.",
                "Expert guidance.",
            ],
            testimonials: [
                { author: 'James Moran, USA', description: "Your supply management coach, helping you reach job-ready skill levels.", img: JamesMoran },
                { author: 'Annabel Kaur Barry, UK', description: "We need to get more CREATIVE with Procurement to leverage all of our skills, knowledge and connections!", img: AnnabelKaur },
                { author: 'Chris Shanahan, USA', description: "Empower, evolve, excel: Harnessing global talent for transformative results.", img: Chris }
            ]
        },
        {
            id: "panel6",
            title: "Get Custom Research",
            content: [
                "Tailored industry secrets just for you.",
                "Data-driven analysis.",
                "Actionable recommendations.",
                "Rock your goals with our research.",
                "Stay ahead of trends with our trend-spotting skills.",
            ],
            image: Research,
        },
    ];
    const communityData = [
        {
            id: "panel7",
            title: "Discussion Forum",
            heading: 'Perks for Connection Forum',
            image: forum,
            btn: { text: 'Join The Conversation Now', link: '/discussions' },
            content: [
                "Networking Hub",
                "Knowledge Sharing",
                "Interactive Learning",
                "Community Support",
                "Professional Growth",
            ],
        },
        {
            id: "panel8",
            title: "Events",
            heading: 'Perks for attending Events',
            image: events,
            btn: { text: 'Explore', link: '/events' },
            content: [
                "Gain Valuable Insights",
                "Networking Opportunities",
                "Free Access",
                "Stay Informed",
                "Inspiration",
            ],
        },
        {
            id: "panel9",
            title: "Resources",
            heading: 'Perks of our Knowledge Bank',
            image: knowledge,
            btn: { text: 'Get Inspired Today', link: '/knowledge-bank' },
            content: [
                "Rich Information Repository",
                "Practical Tips",
                "Exclusive Content",
                "Professional Development",
                "Free Access",
            ],
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="bg--lightBlue pb-28 -mt-[50px]">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden relative">
                        <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] BuyerBanner relative">
                        <div className="flex w-full max-w-3xl relative z-10 flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Learn. Source. Connect
                            </p>
                            <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Unlock Your Procurement Potential: The Smart Choice For Buyers
                            </p>
                            <p className="white Poppins font-normal">
                                Platform where buyers meet sellers
                            </p>
                            <p className="white Poppins font-normal">
                                Our platform offers extensive learning, streamlined sourcing, and powerful networking - all designed to elevate your procurement career. Experience the flexibility, support, and proven results that set us apart.
                            </p>
                            <div className="h-px bg-white mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={Subtract}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>
                <div className="pt-28 container mx-auto lg:relative">
                    <div className="absolute lg:block hidden left-0 top-0">
                        <svg
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            />
                        </svg>
                    </div>
                    <div className="absolute lg:block hidden right-0 -top-10">
                        <svg
                            width="108"
                            height="108"
                            viewBox="0 0 108 108"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_144_559)">
                                <path
                                    d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_144_559">
                                    <rect
                                        width="81"
                                        height="81"
                                        fill="white"
                                        transform="translate(0 33.8882) rotate(-24.7319)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="grid pt-10 gap-10 lg:grid-cols-3 md:grid-cols-2 grid-cols-1" id="contentSection">
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Learn}
                                className="w-[91px] h-[50px] mb-4"
                                alt="Learn"
                            />
                            <h1 className="Poppins font-light text-[30px] leading-6">
                                Learn
                            </h1>
                            <p className="Poppins my-5 text-[#16283A] text-[18px] font-normal leading-6">
                                Gain invaluable knowledge and skills through our
                                masterclasses, comprehensive modules, and
                                specialized training.
                            </p>
                            <Link to={"/buyer-learn"} className="Poppins primary text-[18px] font-normal leading-6">
                                Unlock Potential {">>"}
                            </Link>
                        </div>
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Source}
                                className="w-[76px] h-[47px] mb-4"
                                alt="Source"
                            />
                            <h1 className="Poppins font-light text-[30px] leading-6">
                                Source
                            </h1>
                            <p className="Poppins my-4 text-[#16283A] text-[18px] font-normal leading-6">
                                Streamline your sourcing process with ease.
                                Request quotations, receive expert
                                recommendations, and explore collaborative
                                research opportunities.
                            </p>
                            <div onClick={() => history.push('/source')} className="Poppins cursor-pointer primary text-[18px] font-normal leading-6">
                                Discover the Best Options {">>"}
                            </div>
                        </div>
                        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                            <img
                                src={Community}
                                className="w-[68px] h-[52px] mb-4"
                                alt="Community"
                            />
                            <h1 className="Poppins font-light text-[30px] leading-6">
                                Community
                            </h1>
                            <p className="Poppins my-4 text-[#16283A] text-[18px] font-normal leading-6">
                                Connect, learn, and collaborate in our thriving
                                community. Engage in insightful Q&A discussions,
                                gain valuable knowledge from expert.
                            </p>
                            <Link to={"/community"} className="Poppins primary text-[18px] font-normal leading-6">
                                Join & Succeed {">>"}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="mt-28 container space-y-20">
                    <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                        <div className="flex gap-2 items-center">
                            <p className="Poppins text-[15px] text-[#16283A]">
                                Learn
                            </p>
                            <img src={Learn} alt="Learn" className="w-[44px] h-[24px]" />
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                            <div className="divide-y">
                                {accordionData.map((item) => (
                                    <div key={item.id}>
                                        <Accordion
                                            expanded={expanded === item.id}
                                            onChange={handleChange(item.id)}
                                            className="p-2 m-0 overflow-hidden !shadow-none"
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <BsPlusLg
                                                        className="primary"
                                                        size={24}
                                                    />
                                                }
                                            >
                                                <p className="text-lg Poppins font-semibold text-new-color">
                                                    {item.title}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="!pt-0 grid lg:grid-cols-2 gap-3 grid-cols-1">
                                                <div>
                                                    <ul className="list-disc space-y-2">
                                                        {item.content.map(
                                                            (content, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="Poppins text-lg font-normal text-new-color"
                                                                >
                                                                    {content}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="w-full max-w-lg mx-auto">
                                                    {
                                                        item?.testimonials &&
                                                        <>
                                                            <img src={quotes} alt="quotes" className="w-[72px] pb-5 mx-auto" />
                                                            <p className="text-center text-3xl text-new-color font-light">Testimonials</p>
                                                            <Slider {...settings} className="slider_dots">
                                                                {
                                                                    item.testimonials.map((testimonial, index) => {
                                                                        return (
                                                                            <div key={index} className="text-center space-y-3">
                                                                                <p className="text-lg text-new-color">
                                                                                    {testimonial.description}
                                                                                </p>
                                                                                <p className="italic text-lg text-new-color font-bold">
                                                                                    {testimonial.author}
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </>
                                                    }
                                                    {
                                                        item?.testimonialsSlider &&
                                                        <>
                                                            <Slider {...settings} className="slider_dots">
                                                                {
                                                                    item.testimonialsSlider.map((testimonial, index) => {
                                                                        return (
                                                                            <div key={index} className="!flex items-center lg:flex-nowrap flex-wrap justify-center gap-y-5 gap-x-12">
                                                                                <div className="relative flex-shrink-0">
                                                                                    <img className="w-40 h-40 rounded-full" src={testimonial.img} alt={testimonial.author} />
                                                                                    <img src={quotes} alt="quotes" className="absolute md:!block !hidden left-28 w-[72px] h-[54px] inset-y-0 my-auto mix-blend-multiply" />
                                                                                </div>
                                                                                <div className="flex flex-col gap-3">
                                                                                    <p className="text-3xl font-light text-new-color">{testimonial.author}</p>
                                                                                    <p className="text-lg font-normal text-new-color">{testimonial.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </>
                                                    }
                                                    {
                                                        item.quote &&
                                                        <div className="flex items-center justify-center gap-x-12 gap-y-5">
                                                            <div className="relative flex-shrink-0">
                                                                <img className="w-40 h-40" src={item.quote.img} alt={item.quote.author} />
                                                                <img src={quotes} alt="quotes" className="absolute -right-8 w-[72px] h-[54px] inset-y-0 my-auto mix-blend-multiply" />
                                                            </div>
                                                            <div className="flex flex-col gap-3">
                                                                <p className="text-3xl font-light text-new-color">{item.quote.author}</p>
                                                                <p className="text-lg font-normal text-new-color">{item.quote.description}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item?.image &&
                                                        <div className="flex justify-center items-center">
                                                            <img src={item?.image} alt={item?.title} />
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                        <div className="flex gap-2 items-center">
                            <p className="Poppins text-[15px] text-[#16283A]">
                                Source
                            </p>
                            <img src={Source} alt="Source" className="w-[31px] h-[19px]" />
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                            <div className="divide-y">
                                {OfferData.map((item) => (
                                    <div key={item.id}>
                                        <Accordion
                                            expanded={expanded === item.id}
                                            onChange={handleChange(item.id)}
                                            className="p-2 m-0 overflow-hidden !shadow-none"
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <BsPlusLg
                                                        className="primary"
                                                        size={24}
                                                    />
                                                }
                                            >
                                                <p className="text-lg Poppins font-semibold text-new-color">
                                                    {item.title}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="!pt-0 grid lg:grid-cols-2 gap-3 grid-cols-1">
                                                <div>
                                                    <ul className="list-disc space-y-2">
                                                        {item.content.map(
                                                            (content, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="Poppins text-lg font-normal text-new-color"
                                                                >
                                                                    {content}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div>
                                                    {
                                                        item?.testimonials &&
                                                        <>
                                                            <Slider {...settings} className="slider_dots">
                                                                {
                                                                    item.testimonials.map((testimonial, index) => {
                                                                        return (
                                                                            <div key={index} className="!flex items-center lg:flex-nowrap flex-wrap justify-center gap-y-5 gap-x-12">
                                                                                <div className="relative flex-shrink-0">
                                                                                    <img className="w-40 h-40 rounded-full" src={testimonial.img} alt={testimonial.author} />
                                                                                    <img src={quotes} alt="quotes" className="absolute md:!block !hidden left-28 w-[72px] h-[54px] inset-y-0 my-auto mix-blend-multiply" />
                                                                                </div>
                                                                                <div className="flex flex-col gap-3">
                                                                                    <p className="text-3xl font-light text-new-color">{testimonial.author}</p>
                                                                                    <p className="text-lg font-normal text-new-color">{testimonial.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </>
                                                    }
                                                    {
                                                        item?.image &&
                                                        <div className="flex justify-center items-center">
                                                            <img src={item?.image} alt={item?.title} />
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                        <div className="flex gap-2 items-center">
                            <p className="Poppins text-[15px] text-[#16283A]">
                                Community
                            </p>
                            <img src={Community} alt="Community" className="w-[38px] h-[29px]" />
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                            <div className="divide-y">
                                {communityData.map((item) => (
                                    <div key={item.id}>
                                        <Accordion
                                            expanded={expanded === item.id}
                                            onChange={handleChange(item.id)}
                                            className="p-2 m-0 overflow-hidden !shadow-none"
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <BsPlusLg
                                                        className="primary"
                                                        size={24}
                                                    />
                                                }
                                            >
                                                <p className="text-lg Poppins font-semibold text-new-color">
                                                    {item.title}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="!pt-0 grid lg:grid-cols-2 gap-3 grid-cols-1">
                                                <div>
                                                    <p className="text-xl pb-3 primary font-normal">{item.heading}</p>
                                                    <ul className="list-disc space-y-2">
                                                        {item.content.map(
                                                            (content, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="Poppins text-lg font-normal text-new-color"
                                                                >
                                                                    {content}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div>
                                                    {
                                                        item?.image && item.btn &&
                                                        <div className="flex items-center gap-5 flex-col">
                                                            <img src={item?.image} alt={item?.title} />
                                                            <button onClick={() => history.push(item.btn.link)} className="bg--primary hover:opacity-80 text-white px-5 rounded-full w-full max-w-xs md:h-12 md:py-0 py-2 flex justify-between items-center">{item.btn.text} <BsArrowRight color="white" size={24} /></button>
                                                        </div >
                                                    }
                                                </div >
                                            </AccordionDetails >
                                        </Accordion >
                                    </div >
                                ))}
                            </div >
                        </div >
                    </div >
                </div >
            </div >

            <div className="bg-white relative container pt-28">
                <div className="absolute lg:block hidden -left-20 bottom-36">
                    <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF" />
                    </svg>
                </div>
                <div className="absolute lg:block hidden right-0 bottom-0">
                    <svg
                        width="112"
                        height="112"
                        viewBox="0 0 112 112"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_30_843)">
                            <path
                                d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
                                fill="#B5E2FF"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_30_843">
                                <rect
                                    width="102.698"
                                    height="102.698"
                                    fill="white"
                                    transform="translate(0 102.315) rotate(-85.0461)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -left-20 top-48">
                    <svg
                        width="200"
                        height="200"
                        viewBox="0 0 258 258"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M258 129C258 200.245 200.245 258 129 258C57.7553 258 0 200.245 0 129C0 57.7553 57.7553 0 129 0C200.245 0 258 57.7553 258 129Z"
                            fill="#FFFAEF"
                        />
                    </svg>
                </div>
                <div className="text-center space-y-10">
                    <h1 className="font-light lg:text-[50px] text-[36px] lg:leading-[60px] Poppins">
                        Why Choose Our Buyer Opportunity:
                    </h1>
                    <p className="font-normal text-[18px] leading-6 text-[#16283A] Poppins w-full max-w-5xl mx-auto">
                        Make a smart investment in your future by choosing our
                        buyer opportunity. Experience high-quality services,
                        practical application, flexibility, ongoing support,
                        networking opportunities, and a proven track record.
                        Start your journey towards success today.
                    </p>
                </div>
                <div className="grid mt-24 place-content-center place-items-center grid-cols-12 gap-5">
                    <div className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Learn:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Expand skills through diverse training options.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Source:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Access expert guidance and collaborative tools.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Community:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Engage with peers in a supportive environment.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Resources:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Gain insights from curated industry content.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Flexibility:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Study at your own pace, on your schedule.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                    <div className="bg-white shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 p-4 rounded-3xl text-center">
                        <p className="font-bold Poppins text-[18px] left-6">
                            Connections:
                        </p>
                        <p className="font-normal text-[18px] leading-6 Poppins">
                            Build strategic industry partnerships.
                        </p>
                    </div>
                    <div className="col-span-4 lg:block hidden" />
                </div>
            </div>

            <div className=" plSaysBanner mt-28">
                <div className="flex justify-end px-5 items-center h-[calc(100%-150px)] flex-col">
                    <div className="mb-4">
                        <svg
                            width="110"
                            height="83"
                            viewBox="0 0 110 83"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.5966 42.3267C18.3041 37.4056 24.172 35.2118 30.8864 35.2118C43.2935 35.2118 52.2558 43.72 52.2558 58.0683C52.2558 71.5569 43.8774 83 26.2739 83C10.1008 83 0 71.2605 0 52.1986C0 26.7036 15.0929 6.6931 42.3885 0.082202C43.9942 -0.303186 45.7749 0.704753 46.3296 2.3056L46.8843 3.90644C47.439 5.50728 46.5924 7.07848 44.9867 7.55281C26.9745 12.6814 16.0563 23.8281 13.3413 41.882C13.1077 43.5422 13.5748 43.6904 14.5966 42.3267ZM72.3408 42.3267C76.0483 37.4056 81.9161 35.2118 88.6306 35.2118C101.038 35.2118 110 43.72 110 58.0683C110 71.5569 101.622 83 84.018 83C67.845 83 57.7442 71.2605 57.7442 52.1986C57.7442 26.7036 72.8371 6.6931 100.162 0.082202C101.768 -0.303186 103.548 0.704753 104.103 2.3056L104.658 3.90644C105.212 5.50728 104.366 7.07848 102.76 7.55281C84.7479 12.6814 73.8296 23.8281 71.1146 41.882C70.8519 43.5422 71.3482 43.6904 72.3408 42.3267Z"
                                fill="#F5BA45"
                            />
                        </svg>
                    </div>
                    <h1 className="lg:text-[48px] text-[36px] mb-7 lg:leading-[60px] font-light Poppins text-black">
                        What Procurement Leaguers Have To Say
                    </h1>
                    <p className="text-[#16283A] w-full max-w-4xl text-center mx-auto mb-5 Poppins font-normal text-[18px] leading-6">
                        “Working with Rajiv and the Procurement League team on research projects always brings great results. They're always quick to respond, hardworking, and dedicated to delivering excellent work, and have proved to be a wonderful partner.”
                    </p>
                    <p className="font-bold text-[18px] leading-6 Poppins text-black">
                        Global Sourcing Association, USA
                    </p>
                </div>
            </div>

            <div className="mt-28 -mb-36 container">
                <Knowledgepocket BsArrowRight={BsArrowRight} />
            </div>
        </>
    );
};

export default BuyerPage;
