import React from "react";
function Nocontent() {
  return (
    <div className="flex items-center justify-center h-25">
      <p className="items-center text-2xl lg:text-4xl gray">No result found.</p>
    </div>
  );
}

export default Nocontent;
