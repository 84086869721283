import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Footer, Header, Pagenotfound } from "./components";
import {
    LoginScreen,
    RegisterScreen,
    ForgotpswdScreen,
    HomeScreen,
    ProcureHomeScreen,
    EventsScreen,
    EventsDetailScreen,
    AdditionalResourceScreen,
    ResourceDetailScreen,
    ResourceAllScreen,
    NewsletterScreen,
    DiscussionsScreen,
    DiscussionDetailScreen,
    SolutionScreen,
    ExpertScreen,
    JobslistingScreen,
    ClassDetailScreen,
    ClassesAllScreen,
    ClassesMyScreen,
    ChapterDetailScreen,
    PostDiscussionScreen,
    Profile,
    ProfileCompanyScreen,
    CategoryExpertiseScreen,
    ChangePswdScreen,
    UserProfileScreen,
    UserPaymentHistoryScreen,
    PaymentPageScreen,
    TeamDetailScreen,
    NewsArticleDetailScreen,
    ConsultingScreen,
    DevelopmentLeagueScreen,
    MediaSolutionsScreen,
    OutsourcingScreen,
    ResearchScreen,
    TrainingScreen,
    BlogDetailScreen,
    FaqsScreen,
    EventsUpdateScreen,
    SubscribersTrainingScreen,
    DigitalProcurementScreen,
    HappySubscribersScreen,
    ProcurementContentScreen,
    ConferencePassesScreen,
    PricingScreen,
    PrivacyPolicyScreen,
    DisclaimerScreen,
    TermsServiceScreen,
    ContactUsScreen,
    LeaderboardScreen,
    ActivateUserAccount,
    RecommendationDetailScreen,
    NotificationScreen,
    DeactivateScreen,
    SellerProfileScreen,
    SellersScreen,
    SellerProductDetail,
    RFQsDiscussionScreen,
    IndustriesScreen,
    UserLoginHistoryScreen,
    BuyerScreen,
    ModuleScreen,
    CommunityScreen,
    GrowthSolutionScreen,
    BlogScreen,
    BuyerLearnScreen,
    SuccessStoryScreen,
    HireExpertScreen,
    TrainingCertificateScreen,
    SubmitOfferScreen,
    IndustriesSolutionsScreen,
    IndustrySolutionsB2BScreen,
    TrainnersScreen,
    AboutUsScreen,
    KnowLedgeBankScreen,
    SuccessStorySellSideScreen,
    SubmitForm,
    JobCentralScreenScreen,
    EmailComposerScreen,
    BuyerSourceScreen,
    JoinResearchScreen,
    AppliedJobScreen,
    KnowLedgeBankDetailScreen,
    OfferingsScreen,
    CompanyScreen,
    // JobFormScreen
} from "navigation";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRouteModalBased from "./ProtectedRouteModalBased";
import ScrollToTop from "react-scroll-to-top";
import ActivationEmail from "./components/allcomponents/ActivationEmail";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LoggedInUser from "./LoggedInUser";
import { SellerNewDesign } from "container";
const CustomRoutes = () => {
    return (
        <BrowserRouter>
            <Header />
            <div className="flex-grow pt-[50px]">
                <Switch>
                    <LoggedInUser exact path="/login" component={LoginScreen} />
                    <Route exact path="/signup" component={RegisterScreen} />
                    <Route
                        exact
                        path="/forgot-password"
                        component={ForgotpswdScreen}
                    />
                    <Route
                        path="/activate/:token"
                        component={ActivateUserAccount}
                    />
                    <Route
                        path="/account-activation-email"
                        component={ActivationEmail}
                    />
                    <Route
                        exact
                        path="/linkedin/callback/"
                        component={LinkedInCallback}
                    />

                    <Route exact path="/" component={HomeScreen} />
                    <Route exact path="/offerings" component={OfferingsScreen} />
                    <Route
                        exact
                        path="/auto-login/:token"
                        component={HomeScreen}
                    />
                    <Route
                        exact
                        path="/:id/:price/:name/:is_shippable/:type/:token/Google-Pay"
                        component={HomeScreen}
                    />
                    <ProtectedRouteModalBased
                        exact
                        path="/classes"
                        component={ProcureHomeScreen}
                    />
                    <Route
                        exact
                        path="/classes/:id/:course_slug"
                        component={ClassDetailScreen}
                    />
                    <Route
                        exact
                        path="/search/:type/all"
                        component={ClassesAllScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/my-classes"
                        component={ClassesMyScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/chapter/:course_id/:course_slug/:id"
                        component={ChapterDetailScreen}
                    />
                    <Route exact path="/pricing" component={PricingScreen} />
                    <Route exact path="/events" component={EventsScreen} />
                    <Route
                        exact
                        path="/event/:id/:course_slug"
                        component={EventsDetailScreen}
                    />
                    <ProtectedRouteModalBased
                        exact
                        path="/products"
                        component={AdditionalResourceScreen}
                    />
                    <Route
                        exact
                        path="/product/:id/:course_slug"
                        component={ResourceDetailScreen}
                    />
                    <Route
                        exact
                        path="/products/all"
                        component={ResourceAllScreen}
                    />
                    <Route
                        exact
                        path="/events/all"
                        component={ResourceAllScreen}
                    />
                    <Route
                        exact
                        path="/newsletters"
                        component={NewsletterScreen}
                    />
                    <Route
                        exact
                        path="/newsletter/:id/:slug"
                        component={NewsArticleDetailScreen}
                    />
                    <ProtectedRouteModalBased
                        exact
                        path="/discussions"
                        component={DiscussionsScreen}
                    />
                    <ProtectedRouteModalBased
                        exact
                        path="/rfqs"
                        component={RFQsDiscussionScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/post-discussion"
                        component={PostDiscussionScreen}
                    />
                    <ProtectedRouteModalBased
                        exact
                        path="/discussions/:slug"
                        component={DiscussionDetailScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/recommendation/:id/:slug"
                        component={RecommendationDetailScreen}
                    />
                    <Route exact path="/solution" component={SolutionScreen} />
                    <Route exact path="/expert" component={ExpertScreen} />
                    <Route exact path="/job/:id/:title" component={JobslistingScreen} />
                    <ProtectedRouteModalBased
                        exact
                        path="/seller/:id/:username"
                        component={SellerProfileScreen}
                    />
                    <Route exact path="/sellers" component={SellersScreen} />
                    <Route exact path="/seller" component={SellerNewDesign} />
                    <ProtectedRouteModalBased
                        exact
                        path="/seller/:id/product/:slug"
                        component={SellerProductDetail}
                    />
                    <ProtectedRoute exact path="/profile" component={Profile} />
                    <ProtectedRouteModalBased
                        exact
                        path="/profile/:username"
                        component={UserProfileScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/profile-company"
                        component={ProfileCompanyScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/category-expertise"
                        component={CategoryExpertiseScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/change-password"
                        component={ChangePswdScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/payment-history"
                        component={UserPaymentHistoryScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/login-history"
                        component={UserLoginHistoryScreen}
                    />
                    <Route
                        exact
                        path="/payment"
                        component={PaymentPageScreen}
                    />
                    <Route
                        exact
                        path="/get-discount/:token"
                        component={PaymentPageScreen}
                    />
                    <Route
                        exact
                        path="/our-team"
                        component={TeamDetailScreen}
                    />
                    <Route
                        exact
                        path="/consulting"
                        component={ConsultingScreen}
                    />
                    <Route
                        exact
                        path="/development-league"
                        component={DevelopmentLeagueScreen}
                    />
                    <Route
                        exact
                        path="/media-solutions"
                        component={MediaSolutionsScreen}
                    />
                    <Route
                        exact
                        path="/outsourcing"
                        component={OutsourcingScreen}
                    />
                    <Route exact path="/research" component={ResearchScreen} />
                    <Route exact path="/training" component={TrainingScreen} />
                    <Route
                        exact
                        path="/events-update"
                        component={EventsUpdateScreen}
                    />
                    <Route
                        exact
                        path="/subscribers-training"
                        component={SubscribersTrainingScreen}
                    />
                    <Route
                        exact
                        path="/digital-procurement"
                        component={DigitalProcurementScreen}
                    />
                    <Route
                        exact
                        path="/happy-subscribers"
                        component={HappySubscribersScreen}
                    />
                    <Route
                        exact
                        path="/procurement-content"
                        component={ProcurementContentScreen}
                    />
                    <Route
                        exact
                        path="/conference-passes"
                        component={ConferencePassesScreen}
                    />
                    <Route
                        exact
                        path="/blog-detail"
                        component={BlogDetailScreen}
                    />
                    <Route exact path="/faqs" component={FaqsScreen} />
                    <Route
                        exact
                        path="/privacy-policy"
                        component={PrivacyPolicyScreen}
                    />
                    <Route
                        exact
                        path="/disclaimer"
                        component={DisclaimerScreen}
                    />
                    <Route
                        exact
                        path="/term-of-services"
                        component={TermsServiceScreen}
                    />
                    <Route
                        exact
                        path="/contact-us"
                        component={ContactUsScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/notifications"
                        component={NotificationScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/deactivate-account"
                        component={DeactivateScreen}
                    />
                    <ProtectedRouteModalBased
                        exact
                        path="/leaderboard"
                        component={LeaderboardScreen}
                    />
                    <Route
                        exact
                        path="/pharma-league"
                        component={IndustriesScreen}
                    />
                    <Route
                        exact
                        path="/industry-solution"
                        component={IndustriesSolutionsScreen}
                    />
                    <Route
                        exact
                        path="/B2B"
                        component={IndustrySolutionsB2BScreen}
                    />
                    <Route
                        exact
                        path="/buyers"
                        component={BuyerScreen}
                    />
                    <Route
                        exact
                        path="/buyer-learn"
                        component={BuyerLearnScreen}
                    />
                    <Route
                        exact
                        path="/module"
                        component={ModuleScreen}
                    />
                    <Route
                        exact
                        path="/community"
                        component={CommunityScreen}
                    />
                    <Route
                        exact
                        path="/growth-solutions"
                        component={GrowthSolutionScreen}
                    />
                    <Route
                        exact
                        path="/blog/:slug"
                        component={BlogScreen}
                    />
                    <Route
                        exact
                        path="/success-stories-buyside"
                        component={SuccessStoryScreen}
                    />
                    <Route
                        exact
                        path="/success-stories-sellside"
                        component={SuccessStorySellSideScreen}
                    />
                    <Route
                        exact
                        path="/hire-expert"
                        component={HireExpertScreen}
                    />
                    <Route
                        exact
                        path="/training-certificate"
                        component={TrainingCertificateScreen}
                    />
                    <Route
                        exact
                        path="/submit-offer"
                        component={SubmitOfferScreen}
                    />
                    <Route
                        exact
                        path="/trainners"
                        component={TrainnersScreen}
                    />
                    <Route
                        exact
                        path="/about-us"
                        component={AboutUsScreen}
                    />
                    <Route
                        exact
                        path="/knowledge-bank"
                        component={KnowLedgeBankScreen}
                    />
                    <Route
                        exact
                        path="/knowledge-bank/:slug"
                        component={KnowLedgeBankDetailScreen}
                    />
                    <Route
                        exact
                        path="/submit-form"
                        component={SubmitForm}
                    />
                    <Route
                        exact
                        path="/job-central"
                        component={JobCentralScreenScreen}
                    />
                    <ProtectedRoute
                        exact
                        path="/email-composer"
                        component={EmailComposerScreen}
                    ></ProtectedRoute>
                    <Route
                        exact
                        path="/source"
                        component={BuyerSourceScreen}
                    />
                    <Route
                        exact
                        path="/join-research"
                        component={JoinResearchScreen}
                    />
                    <Route
                        exact
                        path="/job-central/jobs"
                        component={AppliedJobScreen}
                    />
                    <Route
                        exact
                        path="/company-detail/:encryptId"
                        component={CompanyScreen}
                    />
                    <Route path="*" component={Pagenotfound} />
                </Switch>
            </div>
            <Footer />
            <ScrollToTop
                smooth
                top={300}
                style={{ padding: 6, backgroundColor: "#FF6600" }}
                color="white"
            />
        </BrowserRouter>
    );
};

export default CustomRoutes;
