import {gql} from "@apollo/client";

export default gql`
    
    mutation createProduct(
        $id:ID!
        $name:String!
        $slug:String!
        $description:String!
        $in_stock:Int!
        $categories_ids:[Int]!
    ){
        createProduct(
            id:$id,
            name:$name,
            slug:$slug,
            description:$description
            categories_ids:$categories_ids
            in_stock:$in_stock
        ){
            id
            name
            slug
            description
            is_active
            in_stock
            attachment
            type
            product_like_count
            categories{
                id
                name
            }
            product_actions{
                id
                liked
                user_id
                product_like
                book_mark
                visit
                product_id
                created_at
            }
        }
    }
`;