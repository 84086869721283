import React from "react";
import bookSpot from "../../assets/images/bookSpot.png";
import Buttonlink from "components/allcomponents/buttonlink";
import { BsArrowRight } from "react-icons/bs";

const BookSpot = () => {
    return (
        <div className="knowledge-pocket lg:h-[400px] md:h-[400px] h-[350px] w-full relative">
            <img
                src={bookSpot}
                alt="bookSpot"
                className="w-full h-full object-cover"
            />
            <div className="absolute w-full h-full inset-0">
                <div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
                    <div className="lg:col-span-10 md:col-span-10 col-span-12 space-y-4 h-full justify-center flex flex-col">
                        <p className="white Poppins capitalize lg:text-6xl md:text-4xl text-2xl font-extralight lg:text-left md:text-left text-center">
                            So, What Are You Waiting For?
                        </p>
                        <div className="lg:block md:block hidden space-y-4">
                            <div className="pt-5">
                                <p className="white text-xl font-light Poppins">
                                    Don't let your potential go unnoticed!
                                </p>
                                <p className="white text-xl font-light Poppins">
                                    The stage is set, the spotlight awaits.
                                </p>
                                <p className="white text-xl font-light Poppins">
                                    React out to us today and take center stage
                                    like never before!
                                </p>
                            </div>
                            <div className="h-px bg-white w-full" />
                            <div className="flex gap-3 pt-4 items-center">
                                <Buttonlink
                                    to={'/contact-us'}
                                    Text={"Contact Us"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 w-52 whitespace-nowrap text-xl border-2 font-bold self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 md:col-span-6 col-span-12 lg:hidden md:hidden block">
                        <div className="space-y-4">
                            <div>
                                <p className="white text-xl font-light Poppins">
                                    Don't let your potential go unnoticed!
                                </p>
                                <p className="white text-xl font-light Poppins">
                                    The stage is set, the spotlight awaits.
                                </p>
                                <p className="white text-xl font-light Poppins">
                                    React out to us today and take center stage
                                    like never before!
                                </p>
                            </div>
                            <Buttonlink
                                to={'/contact-us'}
                                Text={"Contact Us"}
                                Lasticon={<BsArrowRight size={23} />}
                                className="py-2 w-52 whitespace-nowrap text-xl border-2 font-bold self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookSpot;
