import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FiChevronDown, FiX } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

export default function Chapters(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [becomePro, setBecomePro] = React.useState(false);
  const [is_enroll, set_is_enroll] = React.useState(false);
  const [state] = React.useState({ contents: props.contents.data });
  const { id, course_slug } = useParams();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };

  const { type, item, authUser } = props;

  return (
    <div>
      <div className="flex items-center mb-2">
        <h4 className="Poppins text-lg font-bold">Resources</h4>
        <div className="flex items-center ml-3 cursor-pointer">
          <p className="gray Poppins text-base">{props.chapter_count} {props.chapter_count > 1 ? "Chapters": "Chapter"}</p>
          <BsDot className="mx-1 gray" />
          <p className="gray Poppins text-base">{props.lecture_count} {props.lecture_count > 1 ? "Lectures": "Lecture"}</p>
        </div>
      </div>
      {state?.contents?.filter?.((cont) => cont?.content_type === 'section')?.map(
        (content, index) => (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              className="p-0 mb-3 overflow-hidden border important:shadow--none rounded--xl">
              <AccordionSummary
                expandIcon={<FiChevronDown className="ml-5 black" size={20} />}
                aria-controls={`panelbh-content-${index}`}
                id={`panelbh-content-${index}`}>
                <p className="text-lg text-new-color">{content?.content_name}</p>
              </AccordionSummary>
              <AccordionDetails className="flex flex-col gap-y-3">
                {content.content_modules.map((data, dataIndex) => (
                  type === "item_based" ? (
                  item.is_enroll ? (
                    <Link
                      to={{
                        pathname: `/chapter/${id}/${course_slug}/${item.id}`,
                        state: {
                          item: item,
                          video_path: data && data.video_path,
                          audio_path: data && data?.audio_path,
                          title: data && data.slug,
                          course_id: content?.course_id,
                          content_id: data?.content_id,
                          module_id: data?.id
                        },
                      }}
                      className="flex items-center">
                      <img
                        alt="Cover"
                        src={props.item.course_cover_pic}
                        className="object-cover rounded-full w-9 h-9"
                      />
                      <p className="ml-3 cursor-pointer flex-grow-1">
                        {data && data.slug}
                      </p>
                    </Link>
                  ) : (
                    <div className="flex items-center">
                      <img
                        alt="Cover"
                        src={props.item.course_cover_pic}
                        className="object-cover rounded-full w-9 h-9"
                      />
                      <p
                        className="ml-3 cursor-pointer flex-grow-1"
                        onClick={() => {
                          type === "item_based"
                            ? item.is_enroll
                              ? console.log("Product lecture available")
                              : setBecomePro(true)
                            : authUser.is_pro
                            ? item.is_enroll
                              ? console.log("Class lecture available")
                              : set_is_enroll(true)
                            : setBecomePro(true);
                        }}>
                        {data && data.slug}
                      </p>
                    </div>
                  )
                ) : authUser.is_pro ? (
                  item.is_enroll ? (
                    <Link
                      to={{
                        pathname: `/chapter/${id}/${course_slug}/${item.id}`,
                        state: {
                          item: item,
                          video_path: data && data.video_path,
                          audio_path: data && data?.audio_path,
                          title: data && data.slug,
                          is_scorm: data && data.is_scorm,
                          course_id: content?.course_id,
                          content_id: data?.content_id,
                          module_id: data?.id
                        },
                      }}
                      className="flex items-center">
                      <img
                        alt="Cover"
                        src={props.item.course_cover_pic}
                        className="object-cover rounded-full w-9 h-9"
                      />
                      <p className="ml-3 cursor-pointer flex-grow-1">
                        {data && data.slug}
                      </p>
                    </Link>
                  ) : (
                    <div className="flex items-center">
                      <img
                        alt="Cover"
                        src={props.item.course_cover_pic}
                        className="object-cover rounded-full w-9 h-9"
                      />
                      <p
                        className="ml-3 cursor-pointer flex-grow-1"
                        onClick={() => {
                          type === "item_based"
                            ? item.is_enroll
                              ? console.log("Product lecture available")
                              : setBecomePro(true)
                            : authUser.is_pro
                            ? item.is_enroll
                              ? console.log("Class lecture available")
                              : set_is_enroll(true)
                            : setBecomePro(true);
                        }}>
                        {data && data.slug}
                      </p>
                    </div>
                  )
                ) : (
                  <div className="flex items-center">
                    <img
                      alt="Cover"
                      src={props.item.course_cover_pic}
                      className="object-cover rounded-full w-9 h-9"
                    />
                    <p
                      className="ml-3 cursor-pointer flex-grow-1"
                      onClick={() => {
                        type === "item_based"
                          ? item.is_enroll
                            ? console.log("Product lecture available")
                            : setBecomePro(true)
                          : authUser.is_pro
                          ? item.is_enroll
                            ? console.log("Class lecture available")
                            : set_is_enroll(true)
                          : setBecomePro(true);
                      }}>
                      {data && data.slug}
                    </p>
                  </div>
                )))}
              </AccordionDetails>
            </Accordion>
          )
        // </div>
      )}
      {/* <Becomeapropopup
        item={item}
        type={type}
        show={becomePro}
        onHide={() => setBecomePro(false)}
      /> */}

      <Modal
        open={becomePro}
        onClose={() => setBecomePro(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex items-center justify-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Note!!
            </Typography>
            <div
              onClick={() => setBecomePro(false)}
              className="p-2 -mr-4 rounded-full cursor-pointer hover:bg-gray-200 bg-gray-50">
              <FiX size={22} />
            </div>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please sign-up for Pro level.
          </Typography>
          <a
            href={`${process.env.REACT_APP_WEB_URL}/pricing`}
            className="flex items-center justify-center h-10 px-3 mt-4 text-white border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary Regular hover:opacity-90 hover:text-white rounded--xl">
            Become a pro
          </a>
        </Box>
      </Modal>

      {/*<EnrollPopup show={is_enroll} onHide={() => set_is_enroll(false)} />*/}
      <Modal
        open={is_enroll}
        onClose={() => set_is_enroll(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex items-center justify-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Note!!
            </Typography>
            <div
              onClick={() => set_is_enroll(false)}
              className="p-2 -mr-4 rounded-full cursor-pointer hover:bg-gray-200 bg-gray-50">
              <FiX size={22} />
            </div>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please enroll in this class first to attend this lecture.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
