import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class services extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts">
        <div className="container">
          <h4 className="mb-4 text-2xl lg:text-3xl Medium">Services</h4>
        </div>
        <Slider {...settings} className="important:static">
          <div className="p-2">
            <div className="justify-start overflow-hidden transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--primary shadow--sm rounded-xl">
              <div className="p-4">
                <h4 className="Medium text-[17px] leading-normal	">
                  Renewable Energy Group (REG) Gains Real-time Consolidated View
                  of Position
                </h4>
                <div className="my-3 divider" />
                <p className="Regular text-color">
                  REG implemented Eka’s Position app to empower it to identify
                  market risk in near real-time and also provided heating
                  spreads, positions, compliance reports, and executive
                  dashboards
                </p>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="justify-start overflow-hidden transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--primary shadow--sm rounded-xl">
              <div className="p-4">
                <h4 className="Medium text-[17px] leading-normal	">
                  Renewable Energy Group (REG) Gains Real-time Consolidated View
                  of Position
                </h4>
                <div className="my-3 divider" />
                <p className="Regular text-color">
                  REG implemented Eka’s Position app to empower it to identify
                  market risk in near real-time and also provided heating
                  spreads, positions, compliance reports, and executive
                  dashboards
                </p>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="justify-start overflow-hidden transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--primary shadow--sm rounded-xl">
              <div className="p-4">
                <h4 className="Medium text-[17px] leading-normal	">
                  Renewable Energy Group (REG) Gains Real-time Consolidated View
                  of Position
                </h4>
                <div className="my-3 divider" />
                <p className="Regular text-color">
                  REG implemented Eka’s Position app to empower it to identify
                  market risk in near real-time and also provided heating
                  spreads, positions, compliance reports, and executive
                  dashboards
                </p>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="justify-start overflow-hidden transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--primary shadow--sm rounded-xl">
              <div className="p-4">
                <h4 className="Medium text-[17px] leading-normal	">
                  Renewable Energy Group (REG) Gains Real-time Consolidated View
                  of Position
                </h4>
                <div className="my-3 divider" />
                <p className="Regular text-color">
                  REG implemented Eka’s Position app to empower it to identify
                  market risk in near real-time and also provided heating
                  spreads, positions, compliance reports, and executive
                  dashboards
                </p>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="justify-start overflow-hidden transition duration-500 ease-in-out cursor-pointer hover:color-white-all hover:bg--primary shadow--sm rounded-xl">
              <div className="p-4">
                <h4 className="Medium text-[17px] leading-normal	">
                  Renewable Energy Group (REG) Gains Real-time Consolidated View
                  of Position
                </h4>
                <div className="my-3 divider" />
                <p className="Regular text-color">
                  REG implemented Eka’s Position app to empower it to identify
                  market risk in near real-time and also provided heating
                  spreads, positions, compliance reports, and executive
                  dashboards
                </p>
              </div>
            </div>
          </div>
        </Slider>
        <div className="flex justify-end mt-4">
          <Link to={"#"} className="flex items-center black Regular">
            See all services <IoIosArrowForward className="ml-2" />
          </Link>
        </div>
      </div>
    );
  }
}

export default services;
