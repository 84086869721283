import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import client from "../../../graphql/client";
import LOGIN from "../../../graphql/mutations/loginMutation";
import { GRAPH_USER_AUTH_TOKEN } from "../../../config/constants";
import cookie from "js-cookie";
import Alert from "@mui/material/Alert";
import { BsEyeSlash, BsEye, BsArrowRight } from "react-icons/bs";
import { seo } from "helpers/seo";
import { Buttonlink, IsLoader } from "components";
import { connect } from "react-redux";
import GoogleLoginButton from "../../../components/allcomponents/GoogleLoginButton";
import LinkedInPage from "../../../components/allcomponents/LinkedInPage";
import MyAuthUserAction from "store/actions/authUserAction";
import axios from "axios";
import logo from "assets/images/register.png";
import { setAuthExpiry } from "helpers";
// import AuthUserAction from "../../store/actions/authUserAction";

const Login = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [pswdType, setPswdType] = useState(true);
    const [userInformation, setUserInformation] = useState([]);

    useEffect(() => {
        seo({
            title: "Login | Procurement League",
        });
        window.scrollTo(0, 0);

        fetchData();
    }, []);

    const fetchData = () => {
        let user_brower = "";
        let operating_system = "";
        let longitude = "";
        let latitude = "";

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                longitude = position.coords.longitude;
                latitude = position.coords.latitude;

                const userAgent = navigator.userAgent;

                if (userAgent.indexOf("Chrome") !== -1) {
                    user_brower = "Chrome";
                } else if (userAgent.indexOf("Firefox") !== -1) {
                    user_brower = "Firefox";
                } else if (userAgent.indexOf("Safari") !== -1) {
                    user_brower = "Safari";
                } else if (userAgent.indexOf("Edge") !== -1) {
                    user_brower = "Edge";
                } else if (
                    userAgent.indexOf("MSIE") !== -1 ||
                    userAgent.indexOf("Trident/") !== -1
                ) {
                    user_brower = "Internet Explorer";
                } else {
                    user_brower = "unknown browser";
                }

                if (userAgent.indexOf("Windows") !== -1) {
                    operating_system = "Windows";
                } else if (userAgent.indexOf("Mac OS") !== -1) {
                    operating_system = "Mac OS";
                } else if (userAgent.indexOf("Linux") !== -1) {
                    operating_system = "Linux";
                }

                const userInformationArray = [
                    {
                        user_browser: user_brower,
                        operating_system: operating_system,
                        latitude: latitude,
                        longitude: longitude,
                    },
                ];

                // Set the array in state
                setUserInformation(userInformationArray);
            });
        }
    };

    const handleLoginSubmit = (event) => {
        event.preventDefault();

        if (email === "" || password === "") {
            setLoading(false);
            setError(true);
            setMessage("Email or password cannot be empty.");
            return;
        }

        /*   if (userInformation.length === 0) {
      // The user didn't grant location permission, you can handle this case here.
      fetchData();
      setLoading(false);
      setError(true);
      setMessage('Location permission is required for a better experience.');
      return;
    }*/

        // if(userInformation)

        setLoading(true);
        setError(false);
        setMessage("");

        let variables = {
            email: email,
            password: password,
            userInformation: userInformation,
        };

        client
            .mutate({
                mutation: LOGIN,
                variables,
            })
            .then((response) => {
                let { error, message, token } = response.data.login;
                if (error === true) {
                    setLoading(false);
                    setError(error);
                    setMessage(message);
                } else {
                    setLoading(false);
                    setError(false);
                    setMessage("");
                    // cookie.set(GRAPH_USER_AUTH_TOKEN, token)
                    // props.handleAuthUser(token);
                    cookie.set(GRAPH_USER_AUTH_TOKEN, token);
                    props.handleAuthUser(token);
                    setAuthExpiry();
                    props?.RedirectTo?.state
                        ? (window.location.href =
                            props.RedirectTo.state.from.pathname)
                        : (window.location.href = "/");
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                setMessage("Oops something went wrong please try again later");
            });
    };

    const showEyePswd = () => {
        setPswdType(!pswdType);
    };
    return (
        <div className="container">
            <div className="lg:grid xl:grid-cols-7 lg:grid-cols-5 lg:gap-4 relative">
                <div className="xl:col-start-3 xl:col-span-3 lg:col-start-2 lg:col-span-3 relative z-20">
                    <div className="relative">
                        <h1 className="text-4xl font-light text-center lg:text-5xl Poppins">
                            Sign In
                        </h1>
                        <img
                            src={logo}
                            alt="logo"
                            className="absolute top-0 md:right-20 right-0"
                        />
                    </div>
                    <form onSubmit={handleLoginSubmit}>
                        <div className="mt-36 space-y-5">
                            <div className="space-y-5">
                                <div>
                                    <label
                                        htmlFor="email-address"
                                        className="sr-only"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        placeholder="Email address"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="relative">
                                    <input
                                        id="password"
                                        name="password"
                                        autoComplete="current-password"
                                        required
                                        className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-gray-500 appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        placeholder="Password"
                                        type={pswdType ? "password" : "text"}
                                        variant="standard"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <div
                                        onClick={showEyePswd}
                                        className="absolute top-0 right-0 z-10 flex items-center justify-center w-12 h-full cursor-pointer"
                                    >
                                        {pswdType ? (
                                            <BsEye size={20} />
                                        ) : (
                                            <BsEyeSlash size={20} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <Link
                                    to={"/forgot-password"}
                                    className="primary text-lg flex-shrink-0 font-normal Poppins hover:opacity-70"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                            <div className="flex items-center justify-between">
                                {loading ? (
                                    <div className="bg--primary text-white flex items-center justify-center Regular text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-11 w-full">
                                        <IsLoader
                                            white={true}
                                            className="h-full"
                                        />
                                    </div>
                                ) : (
                                    <Buttonlink
                                        onClick={handleLoginSubmit}
                                        Text={"Login"}
                                        Lasticon={<BsArrowRight size={22} />}
                                        className="w-full text-white border-0 flex justify-center gap-2 items-center px-5 Poppins text-[17px] rounded-full shadow-none bg--primary hover:opacity-70 hover:bg--primary focus:bg--primary  h-12 fs-14 Poppins"
                                    />
                                )}
                            </div>

                            <div className="space-y-5">
                                <div>
                                    <p className="mt-2 text-center text-color">
                                        Don't have an account?
                                        <Link
                                            to="/signup"
                                            className="ml-1 primary hover:opacity-70 Poppins underline"
                                        >
                                            SignUp
                                        </Link>
                                    </p>
                                </div>
                                <div className="flex flex-col items-center gap-3 mt-5 ">
                                    <GoogleLoginButton />
                                    <LinkedInPage />
                                </div>
                            </div>

                            {error ? (
                                <Alert severity="error">{message}</Alert>
                            ) : (
                                ""
                            )}
                            {error ? <div style={{ height: 15 }} /> : false}
                        </div>
                    </form>
                </div>
                <div className="absolute bottom-44 lg:block hidden">
                    <svg
                        width="301"
                        height="301"
                        viewBox="0 0 301 301"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_2426_4423)">
                            <path
                                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                                fill="#FF6600"
                            />
                            <path
                                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                                fill="#EAF2F9"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2426_4423">
                                <rect
                                    width="212.39"
                                    height="212.39"
                                    fill="white"
                                    transform="translate(0 150.182) rotate(-45)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute bottom-0 right-0 lg:block hidden">
                    <svg
                        width="301"
                        height="301"
                        viewBox="0 0 301 301"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_2426_4423)">
                            <path
                                d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
                                fill="#FF6600"
                            />
                            <path
                                d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
                                fill="#EAF2F9"
                            />
                            <path
                                d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
                                fill="#EAF2F9"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2426_4423">
                                <rect
                                    width="212.39"
                                    height="212.39"
                                    fill="white"
                                    transform="translate(0 150.182) rotate(-45)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authUserToken: state.AuthUserReducer.authUserToken,
});

const mapDispatchToProps = (dispatch) => {
    return {
        handleAuthUser: (data) =>
            dispatch({ type: "AUTH_USER_TOKEN", payload: data }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
