import {
    Paymentplan,
    IsLoader,
    EventSubscribers,
    PricingFaq,
    Knowledgepocket,
    ContactUsCardPricing,
} from "components";
import "../ProcureHome/styles.css";
import React, { Component } from "react";
import getPaymentPlans from "graphql/queries/getPaymentPlans";
import client from "graphql/client";
import { seo } from "helpers/seo";
import SubtractWhite from "../../../assets/images/Subtract-white.png";
import { BsArrowDown } from "react-icons/bs";
import certificate from "../../../assets/images/pricing/Certificate.png";
import email from "../../../assets/images/pricing/Email.png";
import banner from "../../../assets/images/pricing/Pricing.webp";
import learning from "../../../assets/images/pricing/Learning.png";
import { scrollToContent } from "helpers/ScrollToContent";
class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        client
            .query({
                query: getPaymentPlans,
            })
            .then((result) => {
                this.setState({
                    plans: result.data.plans,
                });
            });
        seo({
            title: "Pricing | Procurement League  ",
        });
    }

    render() {
        return (
            <>
                <div className="-mt-[50px]">
                    <div className="lg:bg-transparent md:bg-transparent bg-black">
                        <div className="lg:hidden md:hidden relative">
                            <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                        </div>
                        <div className="lg:h-[90vh] md:h-[100vh] PricingBanner relative">
                            <div className="flex w-full max-w-3xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                                <p className="text-[#FBE4D3] uppercase Poppins font-normal">
                                    Pricing
                                </p>
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Helping Procurement Professionals, And
                                    Enterprises To Drive Success Growth
                                </p>
                                <p className="white Poppins capitalize font-normal">
                                    Our Passion is your success
                                </p>
                                <div className="h-px bg-white mt-3" />
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                    <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                        <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                            Contact us <BsArrowDown size={23} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                                <img
                                    src={SubtractWhite}
                                    alt="SubtractWhite"
                                    className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" id="contentSection">
                    <div className="mt-16">
                        {this.state.plans.length === 0 ? (
                            <IsLoader className="w-32 h-32 mx-auto" />
                        ) : (
                            <Paymentplan
                                plans={this.state.plans}
                                location={this.props.location}
                            />
                        )}
                    </div>
                    <div className="mt-28">
                        <div className="flex lg:justify-between justify-center lg:items-baseline items-center lg:flex-nowrap flex-wrap gap-6">
                            <div className="flex justify-center items-center flex-col">
                                <img src={certificate} alt="certificate" />
                                <div className="flex-grow flex-col justify-start items-center mt-3">
                                    <p className="text-3xl font-light Poppins pt-1 text-new-color">
                                        Offical Certificate
                                    </p>
                                    <p className="text-lg font-normal text-new-color pt-2 Poppins">
                                        After Completing this course
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center flex-col">
                                <img src={email} alt="email" />
                                <div className="flex-grow flex-col justify-start items-center mt-3">
                                    <p className="text-3xl font-light Poppins pt-1 text-new-color">
                                        Fast Email Assistance
                                    </p>
                                    <p className="text-lg font-normal text-new-color pt-2 Poppins w-full max-w-xs text-center">
                                        Providing personalized 1:1 support to
                                        elevate your experience
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center items-center flex-col">
                                <img src={learning} alt="learning" />
                                <div className="flex-grow flex-col justify-center items-center mt-3">
                                    <p className="text-3xl font-light Poppins pt-1 text-new-color">
                                        Self-Paced Learning
                                    </p>
                                    <p className="text-lg font-normal text-new-color pt-2 text-center Poppins">
                                        Self-Paced Learning
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-screen mt-28 event_testinomails z-50 relative">
                    <EventSubscribers />
                </div>
                <div className="bg--lightBlue -mt-44">
                    <div className="container pt-48 relative">
                        <div className="lg:block hidden absolute right-28 top-10">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="214"
                                height="214"
                                viewBox="0 0 214 214"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_769_2629)">
                                    <path
                                        d="M90.1865 29.3941L85.6539 81.6878L33.3602 77.1551C20.2868 76.0219 9.63191 84.9771 8.49874 98.0505C7.36558 111.124 16.3208 121.779 29.3942 122.912L81.6878 127.445L77.1551 179.738C76.022 192.812 84.9771 203.467 98.0506 204.6C111.124 205.733 121.779 196.778 122.912 183.704L127.445 131.411L179.738 135.943C192.812 137.077 203.467 128.121 204.6 115.048C205.733 101.975 196.778 91.3197 183.704 90.1865L131.411 85.6538L135.943 33.3602C137.077 20.2868 128.121 9.63187 115.048 8.49871C101.975 7.36554 91.3197 16.3207 90.1865 29.3941Z"
                                        fill="#B5E2FF"
                                        fillOpacity="0.4"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_769_2629">
                                        <rect
                                            width="196.836"
                                            height="196.836"
                                            fill="white"
                                            transform="translate(0 196.101) rotate(-85.0461)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="lg:block hidden absolute -right-28 -top-32">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="258"
                                height="258"
                                viewBox="0 0 258 258"
                                fill="none"
                            >
                                <path
                                    d="M258 129C258 200.245 200.245 258 129 258C57.7553 258 0 200.245 0 129C0 57.7553 57.7553 0 129 0C200.245 0 258 57.7553 258 129Z"
                                    fill="#FFFAEF"
                                />
                            </svg>
                        </div>
                        <div className="space-y-10">
                            <p className="Poppins font-light text-[50px] text-[#16283A]">
                                FAQ
                            </p>
                            <PricingFaq />
                        </div>

                        <div className="mt-28 -mb-36 container">
                            <ContactUsCardPricing />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Pricing;
