import React from "react";
import { Blogskeleton, Buttonlink, KeepChecking, NeedHelp, Noconnection } from "components";
import { Link } from "react-router-dom";
import GET_SG_USERS from "../../graphql/SgQueries/SgUsers";
import ADD_PRODUCT_ACTIONS from "../../graphql/SgMutations/AddSgProductAction";
import { Query } from "@apollo/client/react/components";
import Avatar from "@mui/material/Avatar";
import encryptfunction from "../../helpers/Encrypt";
import { useMutation } from "@apollo/client";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import Auth from "../../Auth"
import { useEffect } from "react";

export default function Index(props) {
    const auth = new Auth();
    const [open, setOpen] = React.useState(false);
    // if (props.history.location?.pathname === '/sellers' && !open){
    //     setOpen(true)
    // }
    const onLoadMore = (data, fetchMore, refetch) => {
        // if (data?.sgUsers?.paginatorInfo?.hasMorePages) {
        //   SetLoadMoreQuestions(true);
        // } else {
        //   SetLoadMoreQuestions(false);
        // }
        fetchMore({
            variables: {
                cursor: data.sgUsers.paginatorInfo.currentPage + 1,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newQuestions = fetchMoreResult.sgUsers.data;
                const pageInfo = fetchMoreResult.sgUsers.paginatorInfo;
                return newQuestions.length
                    ? {
                        sgUsers: {
                            __typename: previousResult.sgUsers.__typename,
                            data: [...previousResult.sgUsers.data, ...newQuestions],
                            paginatorInfo: pageInfo,
                        },
                    }
                    : previousResult;
            },
        });
    };

    const [add_products_actions] = useMutation(ADD_PRODUCT_ACTIONS)
    const addProductAction = () => {
        // if (auth.isAuthenticated()) {
        // Only perform the action if the user is logged in
        add_products_actions({
            variables: {
                user_id: user.id
            }
        });
        // } else {
        //     // Redirect to the login page or show a login modal, etc.
        //     // Example using react-router-dom's useHistory hook:
        //     // history.push('/login');
        // }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    return (
        <div className="container pt-10 -mt-[50px]">
            <Buttonlink
                Text="<< Back"
                onClick={() =>
                    props?.history ? props.history.goBack() : props.history.go("/")
                }
                className="flex items-center justify-center px-4 bg-white rounded-full Poppins primary !text-lg"
            />
            <div className="mt-5">
                <div className="flex justify-between items-center">
                <h1 className="text-new-color font-light Poppins md:text-[50px] text-[30px]">Discover Unique Sellers and <br /> Their Extraordinary Services</h1>
                {/* <button
                className="disabled:opacity-30 flex items-center gap-2 text-white mt-10 h-12 px-6 border-0 shadow-none bg--primary Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                >+ <span className="ml-2">Create Seller Profile</span></button> */}
                </div>
                <p className="text-lg text-new-color pt-5 Poppins">Experience personalized services from sellers who take the time to understand your needs and preferences. <br /> Maximize Efficiency, Minimize Cost: Unlock the secrets of Procurement Outsourcing!</p>
            </div>

            <Query query={GET_SG_USERS} fetchPolicy={"cache-and-network"} variables={{isSellerProfileCompleted:true}}>
                {({ loading, error, data, fetchMore, refetch }) => {
                    if (loading)
                        return (
                            <div className="grid grid-cols-1 my-5 gap-4  md:grid-cols-3 sm:grid-cols-2">
                                <Blogskeleton />
                                <Blogskeleton />
                                <Blogskeleton />
                            </div>
                        );

                    if (error) {
                        extractValidationErrors(error);
                        return (
                            <div>
                                <Noconnection />
                            </div>
                        );
                    }
                    if (data) {
                        let users = data.sgUsers.data;
                        return (
                            <>
                                <div
                                    className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 gap-y-8 mt-20">
                                    {users?.map((user) => (
                                            <div className="block bg-white p-5 transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl" key={user.username}>
                                                <Link
                                                    to={`/seller/${encryptfunction(user.id)}/${user.username
                                                        }`}
                                                    onClick={() => add_products_actions({
                                                        variables: {
                                                            user_id: user.id
                                                        }
                                                    })}
                                                >
                                                    <div className="px-5 py-5 space-y-4">
                                                        {user?.profile_photo ? (
                                                            <img
                                                                className="mx-auto border rounded-4 rounded-full h-[121px] w-[121px]"
                                                                // onError={(e) => {
                                                                //     e.target.src = `${process.env.REACT_APP_MAIN_URL}/images/user_images/nobody_m.original.jpg`;
                                                                // }}
                                                                src={
                                                                    process.env.REACT_APP_MRO_URL +
                                                                    `/uploads/profile_images/` +
                                                                    user.profile_photo
                                                                }
                                                                alt={user.username}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                id={user.id}
                                                                alt={user.firstname}
                                                                className={"h-28 w-28 rounded-full mx-auto"}
                                                                sx={{ height: 104, width: 104 }}
                                                            />
                                                        )}
                                                        <div className="space-y-2">
                                                            <h4 className="text-center break-all Poppins text-new-color text-xl font-semibold">
                                                                {user.firstname} {user.lastname}
                                                            </h4>
                                                            <p className="text-base text-center darkGray">
                                                                @{user.username}
                                                            </p>
                                                        </div>
                                                        <p className="text-lg line-clamp-3 text-new-color text-center w-full">
                                                            {user.tagline}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="flex items-center shadow-[0px_4px_20px_0px_#0000001A] w-max mx-auto rounded-full justify-center px-10 py-2 gap-x-2">
                                                        <p className="text-center primary text-lg font-semibold">
                                                            Products/Services
                                                        </p>
                                                        <p className="text-center primary text-lg font-semibold">
                                                            {user?.product_count}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                                <div className="lg:mt-44 mt-10 relative">
                                    {data?.sgUsers?.paginatorInfo?.hasMorePages && (
                                        <Buttonlink
                                            onClick={() => onLoadMore(data, fetchMore, refetch)}
                                            Text="Load More >>"
                                            className="border-0 font-semibold primary Poppins"
                                        />
                                    )}

                                    <div className="absolute left-44 -top-44 lg:block hidden">
                                        <svg width="265" height="265" viewBox="0 0 265 265" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_3665_5750)">
                                                <path d="M192.254 79.2798L143.884 101.56L121.604 53.19C116.034 41.0975 102.787 36.2056 90.6941 41.7757C78.6015 47.3458 73.7097 60.5928 79.2798 72.6854L101.56 121.056L53.19 143.336C41.0975 148.906 36.2057 162.153 41.7758 174.246C47.3459 186.338 60.5929 191.23 72.6854 185.66L121.056 163.379L143.336 211.75C148.906 223.842 162.153 228.734 174.246 223.164C186.338 217.594 191.23 204.347 185.66 192.254L163.379 143.884L211.75 121.604C223.842 116.034 228.734 102.787 223.164 90.694C217.594 78.6015 204.347 73.7097 192.254 79.2798Z" fill="#FBE4D3" fill-opacity="0.7" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3665_5750">
                                                    <rect width="199.706" height="199.706" fill="white" transform="translate(0 83.5515) rotate(-24.7319)" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </div>
                                </div>
                            </>
                        );
                    }
                }}
            </Query>

            <KeepChecking open={open} history={props.history} path={`/discussions`} />
            <div className="mt-28 -mb-36">
                <NeedHelp />
            </div>
        </div>
    );
}
