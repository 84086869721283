import {COURSE_RECALL, GET_SINGLE_CLASS_INFORMATION} from "../actions/types";
import {produce} from "immer";
const state = {
    course_recall:false,
    currentClass:{}
};

function CourseReducer(mState = { ...state }, action) {
    // console.log(action.type)
    switch (action.type) {
        case COURSE_RECALL:
            return {
                ...state,
                course_recall: action.payload,
            };
        case GET_SINGLE_CLASS_INFORMATION:
            return produce(mState, draftState => {
                draftState.currentClass = action.payload;
            });

        default:
            return { ...mState };
    }
}

export default CourseReducer;
