import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import Subtract from "assets/images/Subtract.png";
import Vector from "assets/images/TrainingsCertifications/Vector.png";
import certifications from "assets/images/TrainingsCertifications/certifications.png";
import business from "assets/images/TrainingsCertifications/business-team.webp";
import banner from "assets/images/TrainingsCertifications/training.webp";
import { IoMdCheckmark } from "react-icons/io";
import { BookSpot } from "components";
import { scrollToContent } from "helpers/ScrollToContent";
import { Link } from "react-router-dom";

const TrainingCertificatePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="-mt-[50px] bg--lightBlue">
            <div className="lg:bg-transparent md:bg-transparent bg-black">
                <div className="lg:hidden md:hidden relative">
                    <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                </div>
                <div className="lg:h-[90vh] md:h-[100vh] TrainingCertificateBanner relative">
                    <div className="flex w-full max-w-3xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                        <p className="text-[#FBE4D3] Poppins font-normal">
                            Trainings & Certifications
                        </p>
                        <p className="white font-light Poppins md:leading-[60px] leading-[35px] lg:text-[48px] md:text-[48px] text-[30px]">
                            Custom Programs: Company Needs & Industry
                            Certifications
                        </p>
                        <p className="white Poppins font-normal">
                            Empower your workforce with our tailored training
                            sessions and industry-recognized certification
                            programs designed to meet the specific needs of your
                            organization.
                        </p>
                        <div className="h-px bg-white mt-4" />
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                            <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                    Discover <BsArrowDown size={23} />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                        <img
                            src={Subtract}
                            alt="Subtract"
                            className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                        />
                    </div>
                </div>
            </div>
            <div className="container mt-28 relative">
                <div className="absolute left-0 -top-20 lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                    >
                        <circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF" />
                    </svg>
                </div>
                <div className="absolute right-0 -top-20 lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_820_3502)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_820_3502">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="flex lg:flex-row flex-col justify-center gap-10 items-center" id="contentSection">
                    <div className="w-full lg:h-[370px] custom-shadow max-w-md rounded-3xl space-y-5 bg-white p-8">
                        <img
                            src={Vector}
                            alt="Vector"
                            className="w-[66px] h-[70px]"
                        />
                        <p className="text-3xl Poppins font-light text-new-color">
                            Custom Training:
                        </p>
                        <p className="text-lg Poppins font-normal text-new-color">
                            We customize training for your team's needs,
                            empowering them with actionable insights for
                            measurable results.
                        </p>
                        <Link to={'/contact-us'} className="primary font-normal text-lg Poppins">
                            Get in touch for requirements {">>"}
                        </Link>
                    </div>
                    <div className="w-full lg:h-[370px] custom-shadow max-w-md rounded-3xl space-y-5 bg-white p-8">
                        <img
                            src={certifications}
                            alt="certifications"
                            className="w-[66px] h-[70px]"
                        />
                        <p className="text-3xl Poppins font-light text-new-color">
                            Industry-Recognized Certifications:
                        </p>
                        <p className="text-lg Poppins font-normal text-new-color">
                            Our certifications prove expertise in procurement,
                            supply chain, and negotiation, boosting your
                            credibility.
                        </p>
                        <Link to={'/contact-us'} className="primary font-normal text-lg Poppins">
                            Get in touch for requirements {">>"}
                        </Link>
                    </div>
                </div>

                <div className="mt-28">
                    <img
                        src={business}
                        alt="business"
                        className="lg:h-[750px] w-full"
                    />
                </div>

                <div className="mt-28 lg:pb-44">
                    <p className="Poppins lg:text-5xl text-4xl text-new-color font-light text-center">
                        Benefits of Choosing Our Trainings & Certifications:
                    </p>
                    <div className="bg-white relative rounded-2xl p-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
                        <div className="divide-y">
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Tailored Solutions:
                                    </span>{" "}
                                    Customized training programs aligned with
                                    your organization's unique needs and
                                    challenges.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Expert Trainers:
                                    </span>{" "}
                                    Learn from seasoned industry professionals
                                    with extensive real-world experience.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Practical Approach:
                                    </span>{" "}
                                    Gain actionable insights and best practices
                                    that can be implemented immediately.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Industry Recognition:
                                    </span>{" "}
                                    Enhance your professional profile with
                                    industry-recognized certifications.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Flexible Delivery Options:
                                    </span>{" "}
                                    Choose from virtual team training sessions,
                                    blended learning approach etc.
                                </p>
                            </div>
                        </div>
                        <div className="absolute left-0 lg:block hidden -bottom-56">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="214"
                                height="214"
                                viewBox="0 0 214 214"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_820_3467)">
                                    <path
                                        d="M90.3545 29.8741L85.8218 82.1677L33.5282 77.6351C20.4548 76.5019 9.79988 85.4571 8.66671 98.5305C7.53355 111.604 16.4887 122.259 29.5621 123.392L81.8558 127.925L77.3231 180.218C76.1899 193.292 85.1451 203.947 98.2185 205.08C111.292 206.213 121.947 197.258 123.08 184.184L127.613 131.891L179.906 136.423C192.98 137.557 203.635 128.601 204.768 115.528C205.901 102.455 196.946 91.7997 183.872 90.6665L131.579 86.1338L136.111 33.8402C137.245 20.7668 128.289 10.1119 115.216 8.97869C102.143 7.84552 91.4877 16.8007 90.3545 29.8741Z"
                                        fill="#B5E2FF"
                                        fillOpacity="0.4"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_820_3467">
                                        <rect
                                            width="196.836"
                                            height="196.836"
                                            fill="white"
                                            transform="translate(0.167969 196.581) rotate(-85.0461)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="mt-28 -mb-36">
                    <BookSpot />
                </div>
            </div>
        </div>
    );
};

export default TrainingCertificatePage;
