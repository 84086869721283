import React, { Component } from "react";
import { connect } from "react-redux";

class posttags extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _handleTagChange = (tag_id, tag_title) => {
    const tags = [{ id: tag_id, title: tag_title }];
    this.props.handleTagSearch(tags);
  };

  render() {
    const { tags } = this.props;
    return (
      <div className="flex flex-wrap items-center gap-2">
        {tags.map((tag) => (
          <div
            className="flex items-center h-8 px-4 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300 lg:rounded-xl lg:h-9"
            key={tag.id}>
            <p
              className="darkGray fs-12"
              onClick={() => this._handleTagChange(tag.id, tag.tag_title)}>
              {tag.tag_title}
            </p>
          </div>
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleTagSearch: (tags) =>
      dispatch({ type: "SELECTED_TAGS", payload: tags }),
  };
};

export default connect(null, mapDispatchToProps)(posttags);
