import {ProfileAvatar, Buttonlink, IsLoader, Noconnection, Nocontent} from "components";
import React, {useState} from "react";
import Auth from "../../../Auth";
import GET_SG_OFFFERS from "../../../graphql/SgQueries/getSgOffers";
import Pagenotfound from "../../allcomponents/pagenotfound";
import {Query} from "@apollo/client/react/components";
import encryptfunction from "../../../helpers/Encrypt";
import convertToSlug from "../../../helpers/convertToSlug";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";
const auth = new Auth();
function RfqPost(props) {
  const [loadMore, setLoadMore] = useState(true);
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState(null);
  let timeoutValue = null;
  const onLoadMore = (data, fetchMore, refetch) => {
    if (data.SgOffers.paginatorInfo.hasMorePages && auth.isAuthenticated()) {
      setLoadMore(true);
    } else {
      setLoadMore(false);
    }
    clearTimeout(timeoutValue);
    timeoutValue = setTimeout(() => {
      setLoadMore(false);
    }, 1000);
    if (auth.isAuthenticated()) {
      fetchMore({
        variables: {
          cursor: data.SgOffers.paginatorInfo.currentPage + 1,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newQuestions = fetchMoreResult.SgOffers.data;
          const pageInfo = fetchMoreResult.SgOffers.paginatorInfo;
          if (data.SgOffers.paginatorInfo.total != pageInfo.total) {
            refetch();
          }
          return newQuestions.length
            ? {
                SgOffers: {
                  __typename: previousResult.SgOffers.__typename,
                  data: [...previousResult.SgOffers.data, ...newQuestions],
                  paginatorInfo: pageInfo,
                },
              }
            : previousResult;
        },
      });
    }
  };
  
  let variables = {};
  if (props?.filterData?.search !== "") {
    variables.search = props?.filterData?.search;
  } 
  // else {
  //   delete variables.search;
  // }
  if (props?.filterData?.category?.id) {
    variables.categories = [Number(props?.filterData?.category?.id)];
  }
  if (props?.filterData?.tag?.id) {
    variables.tags = [Number(props?.filterData?.tag?.id)];
  }
  if (props?.filterData?.dateRange) {
    variables.dateRange = props?.filterData?.dateRange
  }

  const openInNewTap = (value) => {
    setOpen(!open)
    if (value){
      window.open( `${process.env.REACT_APP_SOURCING_GENIE}/auto-login/${props.authUser?.cross_token}/seller/${select?.id}`,'_blank')
    }else {
      window.open(process.env.REACT_APP_SOURCING_GENIE + "/login",'_blank')
    }
  }

  return (
      !auth.isAuthenticated() ? null :
      <Query query={GET_SG_OFFFERS} variables={variables}>
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading) {
            return (
              <div className="my-10">
                <IsLoader className="w-32 h-32 mx-auto" />
              </div>
            );
          }
          if (error) return <Noconnection />;

          if (!data) {
            return Pagenotfound;
          }

          if (data.SgOffers.data.length === 0) return <Nocontent />;

          if (data !== undefined) {
            let offers = data.SgOffers.data;
            return (
              <>
                {offers.map((offer) => {
                  return (
                    <div className="md:p-10 p-5 mb-5 shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl">
                      <div className="sm:p-4 xs:p-2 important:pb-0 ">
                        <div className="flex items-center">
                          <ProfileAvatar
                            singleUser={offer.user}
                            className="object-cover w-[60px] h-[60px] mr-3 rounded-full"
                            sizeStyle={{ height: 80, width: 80 }}
                            platform={"SG"}
                          />
                          <div>
                            <p className="Poppins md:text-3xl text-xl font-bold text-new-color">
                              {offer.user.firstname} {offer.user.lastname}
                            </p>
                            <p className="text-new-color Poppins md:text-lg text-base font-normal">
                              @{offer.user.username}
                            </p>
                          </div>
                        </div>
                        <div className="bg-white w-max mt-5 py-2 px-5 shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                          <span class="primary Poppins text-sm font-bold">
                            Sourcing Genie
                          </span>
                        </div>
                        <div className="mt-10 space-y-5">
                          <Buttonlink
                            href={
                              process.env.REACT_APP_SOURCING_GENIE +
                              "/rfq/" +
                              encryptfunction(offer?.id) +
                              "/" +
                              convertToSlug(offer?.title)
                            }
                            className="Poppins font-bold text-lg"
                            Text={offer?.title}
                          />
                          <div className="flex items-center gap-x-2">
                            <p className="text-new-color Poppins text-lg font-normal">Status:</p>
                            <p className="primary Poppins text-lg font-normal">{offer?.readable_status}</p>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <p className="text-new-color flex-shrink-0 Poppins text-lg font-normal">
                              Required {offer?.uom}:
                            </p>
                            <p className="primary Poppins text-lg font-normal">{offer?.quantity}</p>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <p className="text-new-color flex-shrink-0 Poppins text-lg font-normal">
                              Reference number:
                            </p>
                            <p className="primary Poppins text-lg font-normal">{offer?.reference_number}</p>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <p className="text-new-color Poppins text-lg font-normal">Expiry date:</p>
                            <p className="primary Poppins text-lg font-normal">{offer?.last_date}</p>
                          </div>
                          {!auth.isAuthenticated() && (
                            <Buttonlink
                              onClick={() => props.callback()}
                              className="flex items-center justify-between px-4 w-full h-11 max-w-[200px] border-0 shadow-none white hover:color-white Poppins bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 rounded-full"
                              Text="Submit Quotation"
                              Lasticon = {<BsArrowRight size={22} />}
                            />
                          )}
                          {auth.isAuthenticated() &&
                            props?.authUser?.email &&
                            offer?.user?.email !== props?.authUser?.email && (
                              <Buttonlink
                                onClick={() => {
                                  setOpen(!open);
                                  setSelect(offer);
                                }}
                                className="flex items-center justify-between px-4 w-full h-11 max-w-[200px] border-0 shadow-none white hover:color-white Poppins bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 rounded-full"
                                Text="Submit Quotation"
                                Lasticon = {<BsArrowRight size={22} />}
                              />
                          )}
                        </div>
                        <div className="Poppins-all mt-10 text-lg-all" dangerouslySetInnerHTML={{__html: offer?.description}}></div>
                        <div className="mt-10 space-y-5">
                          <div>
                            <h4 className="mb-1 Poppins primary text-lg px-1 font-normal">Categories:</h4>
                            <div className="flex overflow-x-auto overflow-y-hidden px-1 py-3 items-center gap-y-2 gap-x-5">
                              {offer?.categories.map((category) => {
                                return (
                                  <div className="flex items-center whitespace-nowrap py-2 px-4 shadow-[0px_4px_10px_0px_#0000001A] rounded-full cursor-pointer">
                                    <p className="Poppins text-new-color text-sm font-bold">
                                      {category.name}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div>
                            <h4 className="mb-1 Poppins primary text-lg px-1 font-normal">Industry Tags:</h4>
                            <div className="flex overflow-x-auto overflow-y-hidden px-1 py-3 items-center gap-y-2 gap-x-5">
                              {offer.tags.map((tag) => (
                                <div className="flex items-center whitespace-nowrap py-2 px-4 shadow-[0px_4px_10px_0px_#0000001A] rounded-full cursor-pointer">
                                  <p className="Poppins text-new-color text-sm font-bold">{tag.name}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  {data?.SgOffers?.paginatorInfo?.hasMorePages && (
                    <Buttonlink
                      onClick={() => onLoadMore(data, fetchMore, refetch)}
                      Text="Load More"
                      className="flex items-center justify-center w-[150px] mx-auto mt-10 border-0 rounded-lg shadow-none bg--primary white hover:white hover:opacity-70 hover:bg--primary focus:bg--primary h-11 Poppins"
                    />
                  )}
                </div>
                <Dialog
                  fullWidth={"sm"}
                  maxWidth={"sm"}
                  scroll="body"
                  open={open}
                  onClose={()=>setOpen(!open)}
                  className="rounded--xl"
                >
                  <div className="p-10 mt-5 relative">
                    <div className="absolute right-3 -top-2 bg-white">
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={()=>setOpen(!open)}
                        aria-label="close"
                      >
                        <IoCloseCircleOutline size={32} className="primary" />
                      </IconButton>
                    </div>
                    <h2 className="text-3xl font-bold text-center Poppins">Choose an Option</h2>

                    <div className="flex items-center justify-center gap-x-3 mt-6">
                      <Buttonlink
                          onClick={()=>openInNewTap(false)}
                        Text="Existing User"
                        className="w-32 py-2.5 fs-15 leading-5 Poppins rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:color-white gap-x-3 flex items-center justify-center"
                      />
                      {select?.id &&
                        <Buttonlink
                          onClick={()=>openInNewTap(true)}
                          Text="New User"
                          className="w-32 py-2.5 fs-15 leading-5 Poppins rounded-lg shadow-md hover: bg--lightGray hover:opacity-70 hover:black gap-x-3 flex items-center justify-center bg--lightGray"
                      />}
                    </div>
                  </div>
                </Dialog>
              </>
            );
          }
        }}
      </Query>
  );
}

export default RfqPost;
