import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function Blogskeleton() {
  return (
    <div className="px-3 pb-0 overflow-hidden bg-white rounded-lg shadow-sm">
      <div className="-mx-4">
        <Skeleton variant="rect" height={270} />
      </div>
      <div className="items-center my-3">
        <div className="flex items-center justify-between flex-grow">
          <div>
            <Skeleton
              animation="pulse"
              variant="rect"
              height={8}
              className="rounded-full"
              width={200}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-grow mt-2">
          <div>
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={150}
            />
            <Skeleton
              variant="rect"
              height={8}
              className="mt-2 rounded-full"
              width={100}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
