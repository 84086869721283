import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsFillCameraFill } from "react-icons/bs";
import client from "../../../graphql/client";
import UPLOAD_PROFILE_PICTURE from "../../../graphql/mutations/updateProfileImage";
import MyAuthUserAction from "../../../store/actions/authUserAction";
import ProfileAvatar from "../../allcomponents/ProfileAvatar";
import ME from "../../../graphql/queries/me"
import { Profilenavigation } from "../../index";
import store from "../../../store";
import { GET_AUTH_USER_INFORMATION, UPDATE_AUTH_USER_PROPERTY } from "../../../store/actions/types";
import { extractValidationErrors } from "helpers/extractValidationErrors";

function Profilesidebar(props) {

  const photoRef = useRef(null);
  const [uploadTempImage, setuploadTempImage] = useState("");
  const [errorImage, setErrorImage] = useState(false);

  const onPhotoClick = () => {
    photoRef.current.click();
  };

  const onPhotoChange = (e) => {
    let data = e.target.files[0];
    setuploadTempImage(URL.createObjectURL(data))
    if (data) {
      function getBase64(data) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
      getBase64(data)
        .then((data) => uploadImage(data))
        .catch((error) => {
          extractValidationErrors(error);

          console.log(error);
        });
    }
  };

  const uploadImage = useCallback((file) => {
    client
      .mutate({
        mutation: UPLOAD_PROFILE_PICTURE,
        variables: {
          file: file,
        }
      })
      .then((res) => {
        const picName = res?.data?.uploadProfilePic?.path;
        store.dispatch({
          type: UPDATE_AUTH_USER_PROPERTY,
          payload: { key: "profile_photo", value: picName },
        });
        // props.reloadData(picName);
      })
      .catch((error) => {
        extractValidationErrors(error);
        console.log(error);
      });
  }, [props.reloadData]);

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://images.unsplash.com/photo-1635701886662-53dc6aae71c7?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDJ8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60";
  };

  const onBeforeFileLoad = (elem) => {
    let size = elem.target.files[0].size / 1024 / 1024;
    if (size > 2) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const { authUser } = props;
  return (
    <div className="lg:col-span-2">
      <div className="p-5 mb-3 border rounded-xl">
        <div
          className="relative w-40 h-40 mx-auto rounded-full cursor-pointer "
          onClick={onPhotoClick}
        >
          {
            (uploadTempImage !== "" || authUser?.profile_photo) && !errorImage ?
              <img
                src={uploadTempImage !== '' ? uploadTempImage : authUser?.profile_photo}
                alt="Temporary Preview"
                onError={()=>setErrorImage(true)}
                className={"object-cover w-40 h-40 mx-auto rounded-full"}
              /> :
              <ProfileAvatar
                platform={"PL"}
                sizeStyle={{ height: 160, width: 160 }}
                singleUser={authUser}
                className={"object-cover mx-auto rounded-full"} />
          }

          <div className="absolute bottom-0 right-0 z-10 flex items-center justify-center w-10 h-10 rounded-full bg--lightGray">
            <BsFillCameraFill size={20} />
          </div>
        </div>
        <h2 className="my-3 text-xl text-center Medium lg:text-2xl">
          {authUser.firstname} {authUser.lastname}
        </h2>
        <p className="text-center text-color">{authUser.tagline}</p>
        <Link
          to={`/profile/${authUser.username}`}
          className="flex items-center justify-center h-10 mt-4 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 rounded--xl"
        >
          View Profile
        </Link>
      </div>
      <input
        className="hidden"
        accept="image/*"
        type="file"
        name={"file"}
        onChange={onPhotoChange}
        ref={photoRef}
      />
      <Profilenavigation />
    </div>
  );
}

export default Profilesidebar;
