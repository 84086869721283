import { gql } from "@apollo/client";

export default gql`
  query Questions(
    $cursor: Int
    $course_id: Int
    $category: Int
    $getTagQuestion: Int
    $get_tag: [Int]
    $getSavedQuestions: Boolean
    $question: String
    $unanswered: Boolean
    $new: Boolean
    $hot: Boolean
    $my: Boolean
    $my_relevant: Boolean
  ) {
    questions(
      first: 10
      category: $category
      get_tag_question: $getTagQuestion
      page: $cursor
      getSavedQuestions: $getSavedQuestions
      UnansweredQuestions: $unanswered
      getNewQuestions: $new
      get_hotQuestions: $hot
      get_MyDiscussions: $my
      question: $question
      orderBy: [{ column: "updated_at", order: DESC }]
      course_id: $course_id
      my_relevant: $my_relevant
      get_tag: $get_tag
    ) {
      data {
        id
        question
        description
        created_at
        voteStatus
        saveForCurrentUser
        status
        slug
        meta_text
        tags {
          id
          tag_title
        }
        categories {
          id
          name
        }
        invites {
          id
          email
        }
        attachments {
          id
          url
          type
          width
          height
          public_image
        }
        users {
          id
          firstname
          lastname
          profile_photo
          color
          sg_badge
          sg_paid_user
          is_follower
          username
        }
        likes(first: 1) {
          data {
            users {
              id
            }
          }
          paginatorInfo {
            total
          }
        }
        dislikes(first: 1) {
          data {
            users {
              id
            }
          }
          paginatorInfo {
            total
          }
        }
        answers(first: 1) {
          data {
            id
            answer
            voteStatus
            created_at
            likes(first: 1) {
              paginatorInfo {
                total
              }
            }
            users {
              id
              username
              firstname
              lastname
              profile_photo
              is_pro
            }
            comments(first: 1) {
              paginatorInfo {
                total
              }
            }
          }
          paginatorInfo {
            total
          }
        }
      }
      paginatorInfo {
        total
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
`;
