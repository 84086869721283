import { gql } from "@apollo/client";

export default gql`
  mutation AddClientCard(
    $card_holder_name: String!
    $email:String
    $package_id: Int
    $course_id: Int
    $coupon_code: String
    $payment_method_id: String
  ) {
    add_client_card(
      card_holder_name: $card_holder_name
      email:$email
      package_id: $package_id
      coupon_code: $coupon_code
      course_id: $course_id
      payment_method_id: $payment_method_id
    ) {
      error
      message
      redirect
      method
      amount
    }
  }
`;
