import pMinDelay from "p-min-delay";
import cookie from "js-cookie";

const componentDelay = compoenet => {
  return pMinDelay(compoenet, 1000);
};

export { componentDelay };

export const getMimeTypeFromUrl = (url) => {

  const extension = url.split('.').pop().toLowerCase();

  return extension;
}

export const getTextLength = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const text = doc.body.textContent || "";
  return text.length;
}

export const getText = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const text = doc.body.textContent || "";
  return text;
}

export const setAuthExpiry = () => {
  const date = new Date();
  const seconds = date.getTime() + (1 * 24 * 60 * 60 * 1000);
  cookie.set("AUTH_TOKEN_EXPIRY", seconds?.toString());
}


