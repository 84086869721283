import React from "react";
import Slider from "react-slick";
import sliderPhoto from "../../assets/images/industries/Slider2.webp";

const testimonials = [
    {
        text: "Procurement League helped us with market insights and competitive intelligence to better position our services in the R&D, CDMO, and Research outsourcing of Pharma trials and services. They provided deep intelligence to help our field sales teams.",
        author: "Chief Commercial Officer, CRDMO Company",
    },
    {
        text: "The Procurement League team performed targeted market research based on our needs and shared a list of suppliers who could meet our rapid and high-volume scale-up requirements.",
        author: "Chief Supply Chain Officer, Fast-growing 1bn Biotech Startup",
    },
    {
        text: "As we prepared to launch a new business segment of CDMO, we looked to the experts in Pharma Procurement, the Procurement League team, to refine our marketing content, highlighting our CDMO services and uniqueness compared to the competition.",
        author: "CEO, India-based API Company",
    },
];

const IndustrySliderB2B = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };

    return (
        <div className="mt-10">
            <h1 className="text-center Poppins lg:text-[50px] font-light lg:leading-[60px] text-[36px] text-new-color pb-3">
                What Procurement Leaguers Have To Say
            </h1>

            <div className="rounded-3xl bg-white w-full lg:px-20 md:px-10 px-5 relative max-w-4xl mt-20 mx-auto home_slider">
                <div className="absolute -top-20 -left-24 w-40 h-[283px] lg:block md:block sm:block hidden">
                    <img
                        src={sliderPhoto}
                        alt="sliderPhoto"
                        className="w-full h-full"
                    />
                </div>
                <div className="absolute -top-24 w-16 h-28 lg:hidden md:hidden sm:hidden block">
                    <img
                        src={sliderPhoto}
                        alt="sliderPhoto"
                        className="w-full h-full"
                    />
                </div>
                <div className="absolute right-[44%] -top-10">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="72"
                        height="54"
                        viewBox="0 0 72 54"
                        fill="none"
                    >
                        <path
                            d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                            fill="#FF6600"
                        />
                    </svg>
                </div>
                <div></div>
                <Slider {...settings} className="">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="p-2 md:flex !block">
                            <div className="p-4 md:p-10 md:flex-1 md:mx-2">
                                <div className="text-center md:text-left">
                                    <p>{testimonial.text}</p>
                                    <b className="block font-bold mt-2 md:mt-4">
                                        {testimonial.author}
                                    </b>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default IndustrySliderB2B;
