import { FiX } from "react-icons/fi";
import { Dialog, Transition } from "@headlessui/react";
import React from 'react'
import Buttonlink from "../buttonlink";
import { IoMdCheckmark } from "react-icons/io";
import { useHistory } from "react-router-dom";

const SupplierSubmitPopup = ({ isOpen, closePopup }) => {
    const history = useHistory();

    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={closePopup}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full relative max-w-7xl md:px-10 overflow-hidden px-5 lg:pb-20 py-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]">
                                <div
                                    onClick={closePopup}
                                    className="z-10 flex items-center justify-end rounded-full cursor-pointer"
                                >
                                    <FiX className="primary" size={25} />
                                </div>
                                <Dialog.Title className="text-5xl capitalize text-center font-light my-3 Poppins">
                                    Become a Seller on Our Marketplace and Showcase Your Services to Thousands!
                                </Dialog.Title>

                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-10 gap-y-5 mt-10">
                                    <div>
                                        <p className="text-lg Poppins text-new-color font-normal">Are you a service provider or a small business looking to expand your reach and get more customers?</p>
                                        <p className="text-lg Poppins text-new-color font-normal">Join our thriving marketplace and take advantage of our powerful platform to showcase your offerings and generate new business opportunities.</p>
                                    </div>
                                    <div>
                                        <p className="text-lg Poppins mb-5 w-full max-w-sm text-new-color font-bold">As a Seller on Our Platform, You'll Enjoy These Incredible Benefits:</p>
                                        <ul className="!list-none !p-0">
                                            <li className="text-lg text-new-color Poppins">1. Massive Exposure </li>
                                            <li className="text-lg text-new-color Poppins">2. Easy Publishing and Showcasing </li>
                                            <li className="text-lg text-new-color Poppins">3. Direct Customer Inquiries (RFQ)</li>
                                            <li className="text-lg text-new-color Poppins">4. Affordable Subscription Plans </li>
                                            <li className="text-lg text-new-color Poppins">5. Simplified Quoting Process</li>
                                            <li className="text-lg text-new-color Poppins">6. Trusted Platform</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="mt-10">
                                    <p className="primary text-3xl font-light text-center Poppins">Getting Started is Easy!</p>
                                    <div className="flex justify-between lg:flex-nowrap flex-wrap gap-5 items-center mt-10">
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                                <IoMdCheckmark color="#B5E2FF" size={24} />
                                            </div>
                                            <p className="text-lg Poppins text-new-color font-normal text-center">Click the "Become a Seller" button to access our Sourcing Genie app.</p>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                                <IoMdCheckmark color="#B5E2FF" size={24} />
                                            </div>
                                            <p className="text-lg Poppins text-new-color font-normal text-center">Fill out the simple registration form to verify your legitimacy as a seller.</p>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                                <IoMdCheckmark color="#B5E2FF" size={24} />
                                            </div>
                                            <p className="text-lg Poppins text-new-color font-normal text-center">Create your personalized seller profile and start showcasing your services.</p>
                                        </div>
                                    </div>
                                </div>
                                <Buttonlink Text="Become a seller"
                                    onClick={() => history.push('/sellers')}
                                    className="flex items-center border-0 mt-10 mx-auto uppercase font-semibold w-3/5 h-11 bg--primary text-white justify-center px-5 rounded-full Poppins text-lg"
                                />

                                <div className="absolute lg:block hidden -bottom-5 left-0">
                                    <svg width="200" height="200" viewBox="0 0 301 301" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_2426_4423)"><path d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z" fill="#EAF2F9"></path><path d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z" fill="#FF6600"></path><path d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z" fill="#EAF2F9"></path><path d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z" fill="#EAF2F9"></path><path d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z" fill="#EAF2F9"></path></g><defs><clipPath id="clip0_2426_4423"><rect width="212.39" height="212.39" fill="white" transform="translate(0 150.182) rotate(-45)"></rect></clipPath></defs></svg>
                                </div>
                                <div className="absolute lg:block hidden -bottom-5 right-0">
                                    <svg width="200" height="200" viewBox="0 0 301 301" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_2426_4423)"><path d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z" fill="#EAF2F9"></path><path d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z" fill="#FF6600"></path><path d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z" fill="#EAF2F9"></path><path d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z" fill="#EAF2F9"></path><path d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z" fill="#EAF2F9"></path></g><defs><clipPath id="clip0_2426_4423"><rect width="212.39" height="212.39" fill="white" transform="translate(0 150.182) rotate(-45)"></rect></clipPath></defs></svg>
                                </div>
                                <div className="absolute lg:block hidden bottom-1/2 right-0">
                                    <svg width="200" height="200" viewBox="0 0 301 301" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_2426_4423)"><path d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z" fill="#EAF2F9"></path><path d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z" fill="#FF6600"></path><path d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z" fill="#EAF2F9"></path><path d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z" fill="#EAF2F9"></path><path d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z" fill="#EAF2F9"></path></g><defs><clipPath id="clip0_2426_4423"><rect width="212.39" height="212.39" fill="white" transform="translate(0 150.182) rotate(-45)"></rect></clipPath></defs></svg>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default SupplierSubmitPopup