import React, { Component } from "react";
import Slider from "react-slick";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Signinpopup } from "components";
import encryptfunction from "../../../helpers/Encrypt";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 top-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
      onClick={onClick}>
      <BsArrowRight size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 top-1/2 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
      onClick={onClick}>
      <BsArrowLeft size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

class eventscard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
        };
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1224,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        const { latestEvent,hideTitle } = this.props;
        return (
            <div className="relative blog-posts slider-left equal-height">
                {
                    !hideTitle && 
                    <div className="container">
                        {latestEvent.length > 0 ? (
                            <>
                                <h4 className="lg:text-5xl text-4xl font-light Poppins text-center">
                                    {this.props.eventType}
                                </h4>
                                {this.props.eventType === "Upcoming Events" ? (
                                    <p className="text-center mt-3 text-base Poppins">
                                        Curated by Us and Our Partners
                                    </p>
                                ) : (
                                    <p className="text-center mt-3 text-base Poppins">
                                        Curated by Procurement League
                                    </p>
                                )}
                            </>
                        ) : null}
                    </div>
                }
                {latestEvent.length < 1 && null}
                <Slider {...settings} className="important:static mt-6 lg:px-10 px-[14px]">
                    {latestEvent.map((event) => (
                        <div className="h-full p-5" key={event.id}>
                            <Link
                                to={{
                                    pathname: `/event/${encryptfunction(
                                        event.id
                                    )}/${event.course_slug}`,
                                    state: { item: event },
                                }}
                                className="block bg-white p-5 h-full transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl"
                            >
                                <div className="relative">
                                    <img
                                        alt={event.course_name}
                                        src={event.course_cover_pic}
                                        className="object-fill object-top h-44 w-full mx-auto mb-3 rounded-t-xl"
                                    />
                                </div>
                                <div>
                                    <h4 className="mb-1 Poppins primary text-xl">
                                        {event.course_name}
                                    </h4>
                                    {event.users?.data && (
                                        <p className="my-3 Poppins fs-13">
                                            {event?.is_external_event ? event?.company_name: event?.users?.data[0]?.firstname +
                                                " " +
                                                event?.users?.data[0]?.lastname}
                                        </p>
                                    )}
                                    <Link
                                        to={{
                                            pathname: `/event/${encryptfunction(
                                                event.id
                                            )}/${event.course_slug}`,
                                            state: { item: event },
                                        }}
                                        className="flex items-center primary Poppins fs-14"
                                    >
                                        Read more {'>>'}
                                    </Link>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Slider>

                {this.state.openModal ? (
                    <Signinpopup
                        open={this.state.openModal}
                        closePopup={() => this.setState({ openModal: false })}
                    />
                ) : (
                    false
                )}
            </div>
        );
    }
}

export default eventscard;
