import React from "react";
import { BsArrowRight } from "react-icons/bs";
import Buttonlink from "../buttonlink";
import knowledgeImage from "../../../assets/images/Home/knowledge.webp";

const NeedHelp = () => {
    return (
        <div className={`lg:h-[400px] md:h-[400px] h-[350px] w-full relative`}>
            <img
                src={knowledgeImage}
                alt="Knowledge"
                className="w-full h-full object-cover"
            />
            <div className="absolute w-full h-full inset-0">
                <div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
                    <div className="lg:col-span-7 md:col-span-6 col-span-12 space-y-4 h-full flex justify-center items-start flex-col">
                        <p className="white Poppins lg:text-6xl md:text-4xl text-2xl font-extralight text-left">
                            Need Help?
                        </p>
                        <div className="h-px bg-white w-full" />
                        <div className="lg:block md:block hidden space-y-4">
                            <p className="white text-xl font-light Poppins">
                                Visit our FAQ page or contact our support team for assistance.
                            </p>
                            <Buttonlink
                                to={'/contact-us'}
                                Text={"Contact us"}
                                Lasticon={<BsArrowRight size={23} />}
                                className="py-2 text-xl border-2 self-start font-bold Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-44"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NeedHelp;
