import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RiMapPinLine } from "react-icons/ri";
import Auth from "../../Auth";

export default function CompanyDetailPopup(props) {
  const auth = new Auth();
  const user = props.user;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.show}
      onClose={props.close}>
      <div className="flex items-center px-5 py-3 bg-gray-200 border-b border-gray-200">
        <h4 className="mr-auto text-lg Medium">
          {auth.isAuthenticated() && props?.authUser?.email === user?.email
            ? "Add Company Detail"
            : "Company Detail"}
        </h4>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.close}
          aria-label="close"
          className="hover:text-[#f58020]">
          <IoCloseCircleOutline size={32} />
        </IconButton>
      </div>
      <div className="p-10 ">
        {user?.addresses
          ?.filter((o) => o.is_primary === true)
          .map((address) => {
            return (
              <div key={address?.id} className="space-y-5">
                <div className="flex flex-col lg:items-center lg:flex-row gap-x-4">
                  <p className="mb-1 text-black capitalize Medium lg:mb-0">
                    Corporate Office:
                  </p>
                  <div className="flex items-center gap-x-2">
                    <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
                      <RiMapPinLine size={18} className="darkGray" />
                    </div>
                    <p className="darkGray Light text-[15px]">
                      {address.address_1}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:items-center lg:flex-row gap-x-4">
                  <p className="mb-1 text-black capitalize Medium lg:mb-0">
                    Registered Office:
                  </p>
                  <div className="flex items-center gap-x-2">
                    <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
                      <RiMapPinLine size={18} className="darkGray" />
                    </div>
                    <p className="darkGray Light text-[15px]">
                      {address.address_2}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Dialog>
  );
}
