import React, {
	useState,
	useEffect,
	useRef,
	useImperativeHandle,
	forwardRef,
} from "react";
import { Buttonlink, IsLoader } from "components";
import { Transition, Dialog } from "@headlessui/react";
import { FiX } from "react-icons/fi";
import Api from "services/api";
import { toast } from "react-toastify";
import Auth from "Auth";
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import MyAuthUserAction from "store/actions/authUserAction";
import { useLocation } from "react-router-dom";
import { UploadFile } from "components/UploadFile";

const JobFormScreen = forwardRef((props, ref) => {
	const userInfo = useSelector(
		(state) => state.AuthUserReducer?.authUserInformation
	);
	const auth = new Auth();
	const isLogin = auth?.isAuthenticated();
	const [data, setData] = useState({});
	const [userData, setUserData] = useState({
		name: "",
		email: "",
		phone: "",
		linkedin: "",
		shortPitch: "",
		address: "",
		notice_period: null,
		questions: [],
	});
	const [isOpen, setIsOpen] = useState(false);
	const [errors, setErrors] = useState({});
	const [isFileLoading, setIsFileLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [applied, setApplied] = useState(false);
	const [newuser, setNewuser] = useState(false);
	const [agree, setAgree] = useState(false);
	const hiddenFileInput = useRef(null);
	const location = useLocation();

	const notify = (message = "", type = "error") => {
		if (type === "error") {
			return toast.error(message, { position: "bottom-right" });
		}
		toast.success(message, { position: "bottom-right" });
	};
	useImperativeHandle(ref, () => ({
		open(data, setApply) {
			setData(data);
			setApplied(data?.applied);
			setIsOpen(true);
		},
		close() {
			closePopup();
		},
	}));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (isLogin) {
			setUserData({
				...userData,
				name: `${userInfo?.firstname || ""} ${userInfo?.lastname || ""}`,
				email: userInfo?.email,
				phone: userInfo?.phone,
				linkedin: userInfo?.linkedin_profile,
				shortPitch: userInfo?.tagline,
				address: userInfo?.address,
				notice_period: Number(userInfo?.notice_period),
			});
		}
	}, [userInfo]);

	const [resumes, setResumes] = useState([]);

	useEffect(() => {
		getResume();
	}, []);
	//get login users resume
	const getResume = async (params) => {
		try {
			const response = await Api.fetchResume(params);
			setResumes(response?.data?.resumes);
		} catch (error) {
			console.error(error);
		}
	};

	const addResume = async (params) => {
		try {
			setIsFileLoading(true);
			const response = await Api.addResume(params);
			if (response?.status === 200) {
				const newResumes = resumes ? [...resumes] : [];
				newResumes.push({
					id: newResumes.length + 1,
					user_id: 1,
					resume_path: response.data.resume_path,
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
					active: 0,
					resume_paths: response.data.resume_paths,
				});
				setIsFileLoading(false);
				setUserData((prevUserData) => ({
					...prevUserData,
					resume_path: response.data.resume_path,
				}));
				setResumes(newResumes);
			} else {
				setIsFileLoading(false);
				notify(response?.data?.message, "error");
			}
		} catch (error) {
			setIsFileLoading(false);
			console.error(error);
		}
	};
	const submitJobRequest = async (params) => {
		try {
			setIsLoading(true);
			const response = await Api.submitJobCandidate(params);
			setIsLoading(false);
			if (response?.status == 200 || response?.status == 201) {
				setApplied(true);
				props.setApply();
				notify("Job Applied Successfully", "message");
				setData({});
				if (response?.status == 201) {
					setNewuser(true);
				}
				if (isLogin) {
					await MyAuthUserAction.getAuthUserInformation();
				}
			} else if (response.status === 422) {
				setErrors({ resume_path: response?.data?.errors?.resume_file?.[0] });
				notify(response?.data?.message, "error");
			} else {
				notify(response?.data?.message, "error");
			}
		} catch (error) {
			console.error(error);
		}
	};
	const validateUserData = () => {
		const errors = {};

		// Validate name
		if (typeof userData.name !== "string" || userData.name.length < 3) {
			errors.name = "Full Name must be a string with at least 3 characters";
		}

		// Validate email
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(userData.email) || userData.email.length > 30) {
			errors.email =
				"Email must be a valid email address with maximum length of 30 characters";
		}

		// Validate phone
		const validatePhoneNumber = (phoneNumber) => {
			return /^\+?\d{6,16}$/.test(phoneNumber);
		};
		if (!validatePhoneNumber(userData.phone)) {
			errors.phone = "Invalid phone number";
		}

		// Validate shortPitch
		if (!userData.shortPitch) {
			errors.shortPitch = "Short Pitch about yourself is required";
		}

		// Validate Address
		if (!userData.address) {
			errors.address = "Address is required";
		}

		if (!/^\d*\.?\d+$/.test(userData.notice_period) || parseFloat(userData.notice_period) < 0) {
			errors.notice_period = "Notice period must be a non-negative number";
		}

		// Validate Notice Period
		if (!userData.notice_period) {
			errors.notice_period = "Notice Period is required";
		}

		// Validate resume_path
		if (isLogin) {
			if (!userData.resume_path) {
				errors.resume_path = "Resume is required";
			}
		} else {
			if (!file) {
				errors.resume_path = "Resume is required";
			}
		}

		// Validate questions
		Object.keys(userData.questions).forEach((key) => {
			const question = userData.questions[key];
			if (!Number.isInteger(question.questionId)) {
				errors.questions = errors.questions || {};
				errors.questions[key] = "Question ID must be an integer";
			}
			if (typeof question.answer !== "string") {
				errors.questions = errors.questions || {};
				errors.questions[key] = "Answer must be a string";
			}
		});

		setErrors(errors);
		return errors;
	};

	const handleChangeData = (e) => {
		const { name, value } = e.target;

		if (name === "name") {
			if (value.length > 30 || /\d/.test(value)) {
				return; // Do not update state if name exceeds 30 characters or contains digits
			}
		}

		if (name === "phone" && !/^[\d+]*$/.test(value)) {
			return; // Do not update state if phone contains non-digit characters
		}

		if (name.startsWith("question-")) {
			const questionId = parseInt(name.split("-")[1]);
			setUserData((prevUserData) => ({
				...prevUserData,
				questions: {
					...prevUserData.questions,
					[questionId]: {
						questionId,
						answer: value,
						answer_type: e.target.id,
					},
				},
			}));
		} else if (name.startsWith("resume-")) {
			const resumeIndex = parseInt(name.split("-")[1]);
			const selectedResume = resumes[resumeIndex];
			setUserData((prevUserData) => ({
				...prevUserData,
				resume_path: selectedResume.resume_path,
			}));
		} else {
			setUserData((prevUserData) => ({
				...prevUserData,
				[name]: value,
			}));
		}
	};

	const handleChange = (event) => {
		const file = event.target.files[0];
		if (file?.size > 1024 * 1024) {
			const inputThumb = document.getElementById(event.target.id);
			if (inputThumb) {
				inputThumb.value = "";
			}
			setErrors({ ...errors, resume_path: "File must be smaller than 1MB" });
			return;
		} else {
			setErrors({ ...errors, resume_path: "" });
		}
		if (!isLogin) {
			setFile(file);
			return;
		}
		const formData = new FormData();
		formData.append("file", file);
		addResume(formData); // Pass the file object from FormData
	};

	const closePopup = () => {
		setIsOpen(false);
		setNewuser(false);
		setAgree(false);
	};
	const handleSubmit = (e) => {
		const errors = validateUserData();
		if (Object.keys(errors).length === 0) {
			// Submit logic here
			const formData = new FormData();
			formData.append("job_id", data.id);
			formData.append("name", userData.name);
			formData.append("email", userData.email);
			formData.append("phone", userData.phone);
			formData.append("address", userData.address);
			formData.append("notice_period", userData.notice_period);
			formData.append("linkedin", userData.linkedin);
			formData.append("short_pitch", userData.shortPitch);
			if (isLogin) {
				formData.append("resume_path", userData?.resume_path);
			} else {
				formData.append("resume_file", file);
			}
			formData.append("questions", JSON.stringify(userData?.questions));
			submitJobRequest(formData);
		} else {
			console.log("Form has errors", errors);
		}
		e.preventDefault(); // Prevents the default form submission behavior

		// Your submission logic here
	};

	const handleRegisterEmail = async () => {
		setIsLoading(true);
		const json = await Api.welcomeEmail({ email: userData?.email });
		setIsLoading(false);
		if (json?.status === 201) {
			setAgree(true);
		}
	};

	return (
		<Transition appear show={isOpen} as={React.Fragment}>
			<Dialog as="div" className="relative z-[999]" onClose={closePopup}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className={`w-full p-5 relative ${
									applied ? "max-w-2xl" : "max-w-7xl"
								} overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]`}
							>
								<div
									onClick={closePopup}
									className="flex cursor-pointer justify-end items-end"
								>
									<FiX size={22} />
								</div>
								{applied ? (
									<div className="flex flex-col justify-center items-center gap-y-4">
										<FaCheck color="#FF6600" size={60} />
										<div className="text-2xl font-bold">
											Application{" "}
											{data?.applied_candidates?.[0]?.status === 1 ? "Accepted" :
											data?.applied_candidates?.[0]?.status === 2 ? "Rejected"
												: "Submitted"}
										</div>
										<span className="text-lg text-center">
											{data?.applied_candidates?.[0]?.status === 1
												? "Congratulations! Your application has been shortlisted. Relevant person will contact you shortly for further process"
												: data?.applied_candidates?.[0]?.status === 2 ? 
												"Unfortunately! We are unable to proceed with your application at the moment. You can still apply to relevant jobs. Thank you for your interest and time."
												: "Congratulations! Your Application has been submitted, and it is under review."}
											<br />
											<br />
											{!(
												data?.applied_candidates?.[0]?.status === 1 ||
												data?.applied_candidates?.[0]?.status === 2
											) &&
												"We Will Get back to you soon for further process if your resume gets shortlisted!"}
										</span>
										{data?.applied_candidates?.[0]?.admin_message && 
										 	<span className="text-lg text-center">({data?.applied_candidates?.[0]?.admin_message})</span>
										}
										{newuser && (
											<div className="mt-3 text-white text-left mx-10 p-2 bg--primary opacity-80 flex justify-start gap-x-3 rounded-md items-start">
												<span>Note:</span>
												{!agree
													? "Create your PL account by clicking on the button below to track your all applications"
													: "Congratulations! Your Profile has been created. Please check your email, and login to track your application!"}
											</div>
										)}
										<div className="flex mt-5 justify-center items-center gap-x-3">
											{!(location.pathname.includes("job-central")) &&
											<Buttonlink
												Lasticon={<BsArrowLeft size={22} />}
												to={isLogin ? "/job-central/jobs" : "job-central"}
												Text={
													!isLogin
														? "Back to Job Posting"
														: "Back to Applied Jobs"
												}
												className="bg--primary hover:text-white items-center px-5 justify-between w-full min-w-[240px] flex flex-row-reverse rounded-full text-lg py-2 text-white"
											/>}
											{newuser && !agree && (
												<Buttonlink
													onClick={() => handleRegisterEmail()}
													Text={
														isLoading ? (
															<IsLoader className="w-10" white={true} />
														) : (
															"Register with PL"
														)
													}
													className="bg--primary hover:text-white items-center px-5 justify-between w-full flex flex-row-reverse rounded-full text-lg py-2 text-white"
												/>
											)}
										</div>
									</div>
								) : (
									<div className="lg:grid xl:grid-cols-8 lg:grid-cols-5 lg:gap-4 relative">
										<div className="xl:col-start-3 xl:col-span-4 lg:col-start-2 lg:col-span-3">
											<div className="relative">
												<h1 className="text-4xl font-light text-center Poppins">
													Job Application Form
												</h1>
											</div>
											<form>
												<div className="mt-10">
													<div className="h-[calc(100vh-300px)] no-scrollbar overflow-y-auto space-y-5">
													<div className="space-y-5">
														<div>
															<label htmlFor="full-name" className="sr-only">
																Full Name *
															</label>
															<input
																id="full-name"
																name="name"
																type="text"
																value={userData.name}
																className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
																onChange={handleChangeData}
																placeholder="Full name *"
															/>
															{errors?.name && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.name}
																</p>
															)}
														</div>
														<div>
															<label
																htmlFor="email-address"
																className="sr-only"
															>
																Email address *
															</label>
															<input
																id="email-address"
																name="email"
																type="email"
																value={userData.email}
																className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
																onChange={handleChangeData}
																placeholder="Email *"
																readOnly={isLogin}
															/>
															{errors?.email && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.email}
																</p>
															)}
														</div>
														<div>
															<label htmlFor="phone" className="sr-only">
																Phone *
															</label>
															<input
																id="phone"
																name="phone"
																type="text"
																placeholder="Phone number *"
																value={userData.phone}
																className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
																onChange={handleChangeData}
															/>
															{errors?.phone && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.phone}
																</p>
															)}
														</div>
														<div>
															<label htmlFor="address" className="sr-only">
																Address *
															</label>
															<input
																id="address"
																name="address"
																type="text"
																placeholder="Address *"
																value={userData?.address}
																className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
																onChange={handleChangeData}
															/>
															{errors?.address && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.address}
																</p>
															)}
														</div>
														<div>
															<label htmlFor="linkedin" className="sr-only">
																Linkedin Url
															</label>
															<input
																id="linkedin"
																name="linkedin"
																type="url"
																value={userData?.linkedin}
																onChange={handleChangeData}
																placeholder="Linkedin Url"
																className="relative block w-full h-12  px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
															/>
															{errors?.linkedin && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.linkedin}
																</p>
															)}
														</div>
														<div>
															<label htmlFor="linkedin" className="sr-only">
																Notice Period *
															</label>
															<input
																id="notice_period"
																name="notice_period"
																type="number"
																value={userData?.notice_period}
																onChange={handleChangeData}
																placeholder="Notice Period in days *"
																className="relative block w-full h-12  px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
															/>
															{errors?.notice_period && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.notice_period}
																</p>
															)}
														</div>
														<div>
															<label htmlFor="shortPitch" className="sr-only">
																Short Pitch About Yourself *
															</label>
															<textarea
																id="shortPitch"
																name="shortPitch"
																value={userData?.shortPitch}
																onChange={handleChangeData}
																placeholder="Short Pitch About Yourself *"
																className="relative block w-full resize-none h-32 p-4 Poppins text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-lg bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
															/>
															{errors?.shortPitch && (
																<p className="text-red-500 text-sm pt-2 pl-2">
																	{errors.shortPitch}
																</p>
															)}
														</div>
														<div className="h-px bg-black/50 w-full" />
														{data?.questions &&
															data?.questions.length !== 0 && (
																<h3 className="text-3xl Poppins font-normal">
																	Additional Questions
																</h3>
															)}
														{(data?.questions || [])?.map((question, index) =>
															question?.answer_type === "varchar" ? (
																<div key={index} className="space-y-2 pb-3">
																	<label htmlFor={`question-${question?.id}`}>
																		{question.question}
																	</label>
																	<textarea
																		id={question.answer_type}
																		name={`question-${question?.id}`}
																		value={userData[`question-${question?.id}`]}
																		rows={5}
																		className="relative block w-full pt-2 px-5 Poppins text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-lg bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
																		onChange={handleChangeData}
																		placeholder="Enter your answer"
																	></textarea>
																</div>
															) : (
																<div key={index}>
																	<p>{question.question}</p>
																	<div className="flex gap-5 pt-3">
																		<div className="flex gap-2 items-center">
																			<input
																				type="radio"
																				value="1"
																				id={question.answer_type}
																				name={`question-${question.id}`}
																				className="w-4 h-4"
																				onChange={handleChangeData}
																			/>
																			<label>Yes</label>
																		</div>
																		<div className="flex gap-2 items-center">
																			<input
																				type="radio"
																				value="0"
																				id={question.answer_type}
																				name={`question-${question.id}`}
																				className="w-4 h-4"
																				onChange={handleChangeData}
																			/>
																			<label>No</label>
																		</div>
																	</div>
																</div>
															)
														)}

														<div className="flex">
															<UploadFile id={"resume"}>
																<input
																	id="resume"
																	type="file"
																	onChange={(e) => handleChange(e)}
																	ref={hiddenFileInput}
																	hidden="true"
																	//className="cursor pointer relative w-full px-5 py-2 Poppins text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm uppercase text-left flex items-center justify-start gap-2"
																	accept=".pdf,.doc,.docx,.rtf,.txt"
																/>
															</UploadFile>
															{isFileLoading && <IsLoader className="w-20" />}
														</div>
														{errors?.resume_path && (
															<p className="text-red-500 text-sm pl-2">
																{errors.resume_path}
															</p>
														)}
													</div>
													<div className="grid grid-cols-1 gap-5">
														{resumes?.length !== 0 &&
															resumes?.map((resume, index) => {
																const resumeUrl = resume.resume_path;
																const parts = resumeUrl.split("/"); // Split the URL by '/'
																const resumeName = parts[parts.length - 1];
																return (
																	<label
																		htmlFor={`resume-${index}`}
																		key={index}
																		className="flex border"
																	>
																		<input
																			type="checkbox"
																			id={`resume-${index}`}
																			name={`resume-${index}`}
																			value={resume.resume_paths}
																			checked={
																				userData.resume_path ===
																				resume.resume_path
																			}
																			onChange={handleChangeData}
																			className="group hidden"
																		/>
																		<div className="bg-red-500 pointer-events-none min-w-12 w-12 max-w-[48px] flex justify-center items-center white font-semibold Poppins text-sm h-16">
																			PDF
																		</div>
																		<a
																			href={resume.resume_paths}
																			target="_blank"
																			className="px-3 flex-grow border-r flex items-center text-base Poppins text-new-color font-normal"
																		>
																			{resumeName}
																		</a>
																		<p
																			className={`flex justify-center cursor-pointer min-w-20 w-20 max-w-[80px] items-center text-base Poppins ${
																				userData.resume_path ===
																				resume.resume_path
																					? "primary"
																					: "text-new-color"
																			} font-normal`}
																		>
																			{userData.resume_path ===
																			resume.resume_path
																				? "selected"
																				: "select"}
																		</p>
																	</label>
																);
															})}
														{file && (
															<label className="flex border">
																<div className="bg-red-500 pointer-events-none min-w-12 w-12 max-w-[48px] flex justify-center items-center white font-semibold Poppins text-sm h-16">
																	PDF
																</div>
																<div
																	target="_blank"
																	className="px-3 flex-grow border-r flex items-center text-base Poppins text-new-color font-normal"
																>
																	{file?.name}
																</div>
															</label>
														)}
													</div>
													</div>
													<Buttonlink
														Text={
															isLoading ? (
																<IsLoader className="w-20" white={true} />
															) : (
																"SUBMIT"
															)
														}
														onClick={handleSubmit}
														className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded-full shadow-none h-12 w-full uppercase"
													/>
												</div>
											</form>
										</div>
										<div className="absolute lg:-left-20 xl:left-0 2xl:left-0 top-96 lg:block hidden">
											<svg
												width="301"
												height="301"
												viewBox="0 0 301 301"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clipPath="url(#clip0_2426_4423)">
													<path
														d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
														fill="#EAF2F9"
													/>
													<path
														d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
														fill="#FF6600"
													/>
													<path
														d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
														fill="#EAF2F9"
													/>
													<path
														d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
														fill="#EAF2F9"
													/>
													<path
														d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
														fill="#EAF2F9"
													/>
												</g>
												<defs>
													<clipPath id="clip0_2426_4423">
														<rect
															width="212.39"
															height="212.39"
															fill="white"
															transform="translate(0 150.182) rotate(-45)"
														/>
													</clipPath>
												</defs>
											</svg>
										</div>
										<div className="absolute bottom-72 lg:right-2 xl:right-20 lg:block hidden">
											<svg
												width="201"
												height="201"
												viewBox="0 0 301 301"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clipPath="url(#clip0_2426_4423)">
													<path
														d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
														fill="#EAF2F9"
													/>
													<path
														d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
														fill="#FF6600"
													/>
													<path
														d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
														fill="#EAF2F9"
													/>
													<path
														d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
														fill="#EAF2F9"
													/>
													<path
														d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
														fill="#EAF2F9"
													/>
												</g>
												<defs>
													<clipPath id="clip0_2426_4423">
														<rect
															width="212.39"
															height="212.39"
															fill="white"
															transform="translate(0 150.182) rotate(-45)"
														/>
													</clipPath>
												</defs>
											</svg>
										</div>
										<div className="absolute bottom-2 right-24 lg:block hidden">
											<svg
												width="101"
												height="101"
												viewBox="0 0 301 301"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clipPath="url(#clip0_2426_4423)">
													<path
														d="M100.126 50.0561L50.0625 100.119L100.126 150.182L150.189 100.119L100.126 50.0561Z"
														fill="#EAF2F9"
													/>
													<path
														d="M149.938 99.8718L99.875 149.935L149.938 199.998L200.001 149.935L149.938 99.8718Z"
														fill="#FF6600"
													/>
													<path
														d="M199.54 149.468L149.477 199.531L199.54 249.595L249.603 199.531L199.54 149.468Z"
														fill="#EAF2F9"
													/>
													<path
														d="M250.305 100.119L200.242 50.056L150.179 100.119L200.242 150.182L250.305 100.119Z"
														fill="#EAF2F9"
													/>
													<path
														d="M150.18 200.253L100.117 150.189L50.054 200.253L100.117 250.316L150.18 200.253Z"
														fill="#EAF2F9"
													/>
												</g>
												<defs>
													<clipPath id="clip0_2426_4423">
														<rect
															width="212.39"
															height="212.39"
															fill="white"
															transform="translate(0 150.182) rotate(-45)"
														/>
													</clipPath>
												</defs>
											</svg>
										</div>
									</div>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
});
export default JobFormScreen;
