import { Buttonlink } from "components";
import * as React from "react";
import { BsNodePlusFill } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";

export default function Profilenavigation() {
  return (
    <div className="p-5 border rounded-xl">
      <NavLink
        to="/profile"
        activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
        className="flex items-center justify-between px-3 py-2 mb-2 rounded-lg cursor-pointer black hover:bg-gray-200 list-items">
        Profile
        <FiChevronRight className="gray" />
      </NavLink>
      <div className="my-1 divider" />
      <div className="relative hover_items">
        <NavLink
          to="/profile-company"
          activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
          className="flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer  black hover:bg-gray-200 list-items">
          Company
          <FiChevronRight className="gray" />
        </NavLink>
        {/* <Buttonlink
          Icon={<BsNodePlusFill className="mr-1" />}
          className="opacity_items absolute right-8 top-1.5 fs-12 px-4 items-center justify-center important:white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-7 rounded-md border-0 shadow-none"
          onClick={() => console.log()}
          Text={"Add"}
        /> */}
      </div>
      <div className="my-1 divider" />
      <NavLink
        to="/category-expertise"
        activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
        className="flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer black hover:bg-gray-200 list-items">
        Category Expertise
        <FiChevronRight className="gray" />
      </NavLink>
      <div className="my-1 divider" />
      <NavLink
        to="/change-password"
        activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
        className="flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer black hover:bg-gray-200 list-items">
        Change Password
        <FiChevronRight className="gray" />
      </NavLink>
      <div className="my-1 divider" />
      <NavLink
        to="/payment-history"
        activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
        className="flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer black hover:bg-gray-200 list-items">
        Payment history
        <FiChevronRight className="gray" />
      </NavLink>
      <div className="my-1 divider" />
      <NavLink
        to="/deactivate-account"
        activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
        className="flex items-center justify-between px-3 py-2 text-red-500 rounded-lg cursor-pointer hover:text-red-500 hover:bg-gray-200 list-items">
        Deactivate Your Account
        <FiChevronRight className="gray" />
      </NavLink>
      <div className="my-1 divider" />
      <NavLink
        to="/login-history"
        activeClassName="bg--lightPrimary important:primary fill:primary-all-svg"
        className="flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer black hover:bg-gray-200 list-items">
        Login History
        <FiChevronRight className="gray" />
      </NavLink>
    </div>
  );
}
