import {
    AnimateButton,
    Buttonlink,
    EventHighLight,
    Knowledgepocket,
    Ourpartners,
    Subscribers,
    Team,
} from "components";
import React, { Component } from "react";
import { BsArrowRight } from "react-icons/bs";
import { AUTH_USER } from "../../config/constants";
import Auth from "../../Auth";
import cookie from "js-cookie";
import { seo } from "helpers/seo";
import { connect } from "react-redux";
import MobileBanner from "assets/images/Home/MobileBanner.png";
import Layer_one from "assets/images/Home/Layer_1.png";
import Layer_two from "assets/images/Home/Layer_2.png";
import Layer_three from "assets/images/Home/Layer_3.png";
import buyer from "assets/images/Home/buyer.jpg";
import seller from "assets/images/Home/Sellers.jpg";
import industry_solution from "assets/images/Home/industry_solution.jpg";
import Community from "assets/images/Home/Community.jpg";
import arrow from "assets/images/Home/arrow.png";
import Job from "assets/images/Home/Job.png";
import Group from "assets/images/Home/Group.png";
import Bubbles from "assets/images/Home/bubbles.png";
import Subtract from "assets/images/Subtract.png";
import { Link } from "react-router-dom";
const auth = new Auth();

class Home extends Component {
    constructor(props) {
        super(props);
        this.industryRef = React.createRef();
        this.buyerRef = React.createRef();
        this.sellerRef = React.createRef();
    }

    componentDidMount = () => {
        if (auth.isAuthenticated()) {
            cookie.set(AUTH_USER, JSON.stringify(this.props.authUser));
        }
        seo({
            title: "Procurement League | Accelerating Innovation in Procurement Practice",
        });
        window.scrollTo(0, 0);
    };
    executeScroll = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    render() {
        return (
            <>
                <div className="bg--lightBlue pb-28 -mt-[50px]">
                    <div className="lg:bg-transparent md:bg-transparent bg-black">
                        <div className="lg:hidden md:hidden relative">
                            <img src={MobileBanner} alt="banner" className="object-cover w-full h-full object-center" />
                        </div>
                        <div className="lg:h-[90vh] md:h-[100vh] HomeBanner relative">
                            <div className="flex w-full max-w-2xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                                <p className="text-[#FBE4D3] Poppins font-normal">
                                    Procurement Connections, Commerce, and Career
                                </p>
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Virtual Procurement <br className="lg:block md:block sm:block hidden" /> Townsquare For Ideas, <br className="lg:block md:block sm:block hidden" /> Advice & Success
                                </p>
                                <p className="white Poppins font-normal">
                                    We have built Procurement League for buyers and
                                    sellers to grow in their business and
                                    profession.
                                </p>
                                <div className="flex items-center gap-5">
                                    {
                                        !auth.isAuthenticated() &&
                                        <Buttonlink
                                            to={"/signup"}
                                            Text={"Join Us >>"}
                                            className="primary Poppins underline"
                                        />
                                    }

                                    <Buttonlink
                                        href="https://play.google.com/store/apps/details?id=com.pro.procurementleague&hl=en"
                                        Text={"Download App >>"}
                                        className="primary Poppins underline"
                                    />
                                </div>
                                <div className="h-px bg-white" />
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                    <button
                                        onClick={() =>
                                            this.props.history.push('/buyers')
                                        }
                                        className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                                    >
                                        <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">Buyers <BsArrowRight size={23} /></span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            this.props.history.push('/seller')
                                        }
                                        className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                                    >
                                        <div className="absolute h-full rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">Sellers <BsArrowRight size={23} /></span>
                                    </button>
                                    <button
                                        onClick={() =>
                                            this.props.history.push('/industry-solution')
                                        }
                                        className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                                    >
                                        <div className="absolute h-full rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">Industry <BsArrowRight size={23} /></span>
                                    </button>
                                </div>
                            </div>
                            <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                                <img src={Subtract} alt="Subtract" className="object-cover w-full left-1/2 -translate-x-1/2 relative" />
                            </div>
                        </div>
                    </div>
                    <div className="pt-10 container mx-auto lg:relative">
                        <div className="absolute lg:block hidden right-0 top-0">
                            <svg
                                width="112"
                                height="112"
                                viewBox="0 0 112 112"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_30_843)">
                                    <path
                                        d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
                                        fill="#B5E2FF"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_30_843">
                                        <rect
                                            width="102.698"
                                            height="102.698"
                                            fill="white"
                                            transform="translate(0 102.315) rotate(-85.0461)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="absolute lg:block hidden left-1/4 top-0">
                            <svg
                                width="108"
                                height="108"
                                viewBox="0 0 108 108"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_2_811)">
                                    <path
                                        d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                        fill="#FFEFE5"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2_811">
                                        <rect
                                            width="81"
                                            height="81"
                                            fill="white"
                                            transform="translate(0 33.8882) rotate(-24.7319)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <h1 className="text-center Poppins text-new-color mt-20 capitalize lg:text-5xl font-light text-4xl">
                            We are the future of digital b2B commerce
                        </h1>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-32 lg:gap-7 md:gap-10 gap-y-14">
                            <div className="relative mx-auto blobBg w-[340px] h-72">
                                <div className="absolute -top-16 left-24">
                                    <img
                                        src={Layer_one}
                                        alt="Layer_one"
                                        className="h-36 w-36 object-contain"
                                    />
                                </div>
                                <div className="flex justify-center gap-3 items-center flex-col h-full">
                                    <h1 className="primary Poppins font-semibold text-6xl text-center">
                                        44,580
                                    </h1>
                                    <p className="text-center Poppins">
                                        Minutes buyers and <br /> sellers spent
                                        on Q&A
                                    </p>
                                </div>
                            </div>
                            <div className="relative mx-auto blobBg w-[340px] h-72">
                                <div className="absolute -top-16 left-24">
                                    <img
                                        src={Layer_two}
                                        alt="Layer_two"
                                        className="h-36 w-36 object-contain"
                                    />
                                </div>
                                <div className="flex justify-center gap-3 items-center flex-col h-full">
                                    <h1 className="primary Poppins font-semibold text-6xl text-center">
                                        1.4M
                                    </h1>
                                    <p className="text-center Poppins">
                                        Online impressions <br /> by buyers and
                                        sellers
                                    </p>
                                </div>
                            </div>
                            <div className="relative mx-auto blobBg w-[340px] h-72">
                                <div className="absolute -top-16 left-24">
                                    <img
                                        src={Layer_three}
                                        alt="Layer_one"
                                        className="h-36 w-36 object-contain"
                                    />
                                </div>
                                <div className="flex justify-center gap-3 items-center flex-col h-full">
                                    <h1 className="primary Poppins font-semibold text-6xl text-center">
                                        90%
                                    </h1>
                                    <p className="text-center Poppins">
                                        9/10 Times buyers and <br /> sellers had
                                        a match of <br /> their query
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-28 container">
                    <Ourpartners />
                </div>

                <div className="mt-28 lg:space-y-0 md:space-y-0 space-y-5">
                    <div ref={this.buyerRef} className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:h-screen md:h-screen gap-0 relative">
                        <div className="lg:bg-[#EAF2F9] md:bg-[#EAF2F9] container h-full w-full">
                            <div className="flex-col flex justify-start items-start w-full max-w-lg mt-10 gap-5 mx-auto">
                                <h1 className="Poppins text-new-color text-4xl font-light">Buyers</h1>
                                <p className="Poppins">
                                    We educate and connect 26290+ Procurement
                                    Professionals across the world to improve
                                    performance and seek better opportunities
                                </p>
                                <div className="flex lg:hidden md:hidden items-center gap-5">
                                    <Buttonlink
                                        to={"/buyer-learn"}
                                        Text={"Learn >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/source"}
                                        Text={"Source >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/community"}
                                        Text={"Community >>"}
                                        className="primary Poppins"
                                    />
                                </div>
                                <AnimateButton to={'/buyers'} text={'Read More'} className={'flex h-12 Poppins justify-between items-center hover:text-white bg--primary white rounded-full w-48 px-4'} />
                            </div>
                        </div>
                        <div className="h-full lg:order-last md:order-last order-first w-full">
                            <img
                                src={buyer}
                                alt="buyer"
                                className="w-full h-full object-cover object-center"
                            />
                        </div>
                        <div className="absolute lg:flex h-[calc(100%-290px)] overflow-y-auto justify-evenly md:flex items-center flex-col hidden bg-white rounded-3xl p-6 inset-0 mt-auto scrollBar mb-10 mx-auto z-10 w-[500px] shadow-[0px_4px_20px_0px_#0000001A]">
                            <div className="space-y-2">
                                <div className="space-y-2 flex justify-between items-center">
                                    <h1 className="Poppins text-[#16283A] text-2xl">
                                        Learn
                                    </h1>
                                    <Link to="/buyer-learn">
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className="w-10 object-contain"
                                        />
                                    </Link>
                                </div>
                                <p className="text-[#16283A] Poppins">
                                    Acquire valuable knowledge and skills
                                    through our masterclasses, modules, and
                                    training. Embrace growth and develop
                                    personally and professionally.
                                </p>
                            </div>
                            <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                            <div className="space-y-2">
                                <div className="space-y-2 flex justify-between items-center">
                                    <h1 className="Poppins text-[#16283A] text-2xl">
                                        Source
                                    </h1>
                                    <Link to="/source">
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className="w-10 object-contain"
                                        />
                                    </Link>
                                </div>
                                <p className="text-[#16283A] Poppins">
                                    Streamline sourcing with ease. Request
                                    quotations, receive recommendations, and
                                    explore research opportunities for
                                    perfect solutions.
                                </p>
                            </div>
                            <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                            <div className="space-y-2">
                                <div className="space-y-2 flex justify-between items-center">
                                    <h1 className="Poppins text-[#16283A] text-2xl">
                                        Community
                                    </h1>
                                    <Link to="/community">
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className="w-10 object-contain"
                                        />
                                    </Link>
                                </div>
                                <p className="text-[#16283A] Poppins">
                                    Connect, learn, and collaborate in our
                                    thriving Community. Engage in Q&A
                                    discussions, gain knowledge from expert
                                    blogs, and stay updated with
                                    newsletters.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={this.sellerRef} className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:h-screen md:h-screen gap-0 relative">
                        <div className="h-full w-full">
                            <img
                                src={seller}
                                alt="seller"
                                className="w-full h-full object-cover object-center"
                            />
                        </div>
                        <div className="relative container h-full w-full">
                            <div className="flex-col flex justify-start items-start w-full max-w-lg mt-10 gap-5 mx-auto">
                                <h1 className="Poppins text-new-color font-light text-4xl">Sellers</h1>
                                <p className="Poppins">
                                    Unlock your potential with our comprehensive
                                    Growth Solutions, easily submit your offers
                                    to connect with potential clients and
                                    partners and engage in a vibrant community
                                    of learning and collaboration.
                                </p>
                                <div className="flex lg:hidden md:hidden items-center gap-5">
                                    <Buttonlink
                                        to={"/growth-solutions"}
                                        Text={"Growth >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/submit-offer"}
                                        Text={"Offer >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/community"}
                                        Text={"Community >>"}
                                        className="primary Poppins"
                                    />
                                </div>
                                <AnimateButton to={'/seller'} text={'Read More'} className={'flex h-12 Poppins justify-between items-center hover:text-white bg--primary white rounded-full w-48 px-4'} />
                            </div>
                            <div className="absolute lg:flex md:flex flex-col justify-evenly items-center hidden h-[calc(100%-290px)] overflow-y-auto scrollBar bg-white rounded-3xl p-6 bottom-10 lg:-left-16 md:-left-1/3 z-10 w-[500px] shadow-[0px_4px_20px_0px_#0000001A]">
                                <div className="space-y-2">
                                    <div className="space-y-2 flex justify-between items-center">
                                        <h1 className="Poppins text-[#16283A] text-2xl">
                                            Growth Solutions
                                        </h1>
                                        <Link to="/growth-solutions">
                                            <img
                                                src={arrow}
                                                alt="arrow"
                                                className="w-10 object-contain"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-[#16283A] Poppins">
                                        Elevate your business with our
                                        Growth Solutions: a powerhouse
                                        combination of content, expert
                                        advice, and cutting-edge community
                                        technology services.
                                    </p>
                                </div>
                                <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                                <div className="space-y-2">
                                    <div className="space-y-2 flex justify-between items-center">
                                        <h1 className="Poppins text-[#16283A] text-2xl">
                                            Submit Offer
                                        </h1>
                                        <Link to="/submit-offer">
                                            <img
                                                src={arrow}
                                                alt="arrow"
                                                className="w-10 object-contain"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-[#16283A] Poppins">
                                        Expand your reach and showcase
                                        expertise by submitting offers.
                                        Connect with potential clients and
                                        partners as a skilled practitioner,
                                        supplier, or research collaborator.
                                    </p>
                                </div>
                                <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                                <div className="space-y-2">
                                    <div className="space-y-2 flex justify-between items-center">
                                        <h1 className="Poppins text-[#16283A] text-2xl">
                                            Community
                                        </h1>
                                        <Link to="/community">
                                            <img
                                                src={arrow}
                                                alt="arrow"
                                                className="w-10 object-contain"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-[#16283A] Poppins">
                                        Connect, learn, and collaborate in
                                        our thriving Community. Engage in
                                        Q&A, gain knowledge from expert
                                        blogs, and stay updated with
                                        newsletters.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={this.industryRef} className="grid Poppins lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:h-screen md:h-screen gap-0 relative">
                        <div className="lg:bg-[#EAF2F9] md:bg-[#EAF2F9] container h-full w-full">
                            <div className="flex-col flex justify-center items-start w-full max-w-lg lg:mt-48 mt-10 gap-5 mx-auto">
                                <h1 className="Poppins text-new-color font-light text-4xl">
                                    Industry Solutions
                                </h1>
                                <p className="Poppins">
                                    Industry-specific solutions, connecting you with the right people and solutions for your field
                                </p>
                                <div className="flex lg:hidden md:hidden items-center gap-5">
                                    <Buttonlink
                                        to={"/B2B"}
                                        Text={"B2B Solution >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/pharma-league"}
                                        Text={"Pharma/Biotech >>"}
                                        className="primary Poppins"
                                    />
                                </div>
                                <AnimateButton to={'/pharma-league'} text={'Read More'} className={'flex h-12 Poppins justify-between items-center hover:text-white bg--primary white rounded-full w-48 px-4'} />
                            </div>
                        </div>
                        <div className="h-full w-full lg:order-last md:order-last order-first">
                            <img
                                src={industry_solution}
                                alt="industry_solution"
                                className="w-full h-full object-cover object-center"
                            />
                        </div>
                        <div className="absolute lg:flex h-[calc(100%-430px)] overflow-y-auto md:flex justify-evenly items-center flex-col hidden h-max bg-white scrollBar rounded-3xl p-6 inset-0 mt-auto mb-10 mx-auto z-10 w-[500px] shadow-[0px_4px_20px_0px_#0000001A]">
                            <div className="space-y-2">
                                <div className="space-y-2 flex justify-between items-center">
                                    <h1 className="Poppins text-[#16283A] text-2xl">
                                        B2B Solutions
                                    </h1>
                                    <Link to={'/B2B'}>
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className="w-10 object-contain"
                                        />
                                    </Link>
                                </div>
                                <p className="text-[#16283A] Poppins">
                                    We connect decision-makers, facilitate
                                    B2B connections, and enable businesses
                                    to thrive in a dynamic marketplace with
                                    exciting gig projects.
                                </p>
                            </div>
                            <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                            <div className="space-y-2">
                                <div className="space-y-2 flex justify-between items-center">
                                    <h1 className="Poppins text-[#16283A] text-2xl">
                                        Pharma/Biotech Solutions
                                    </h1>
                                    <Link to={'/pharma-league'}>
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className="w-10 object-contain"
                                        />
                                    </Link>
                                </div>
                                <p className="text-[#16283A] Poppins">
                                    Unlock growth and efficiency for
                                    pharmaceutical and biotech brands with
                                    our tailored solutions and network of
                                    decision-makers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid Poppins lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:h-screen md:h-screen gap-0 relative">
                        <div className="h-full w-full">
                            <img
                                src={Community}
                                alt="Community"
                                className="w-full h-full object-cover object-center"
                            />
                        </div>
                        <div className="relative lg:bg-[#EAF2F9] md:bg-[#EAF2F9] container h-full w-full">
                            <div className="absolute -top-1 -left-40 lg:hidden md:hidden block">
                                <svg width="177" height="177" viewBox="0 0 177 177" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="88.5" cy="88.5" r="88.5" fill="#B5E2FF" fillOpacity="0.2" />
                                </svg>
                            </div>
                            <div className="absolute w-48 h-48 right-10 -bottom-20 lg:block hidden">
                                <img src={Bubbles} alt="Bubbles" className="w-full h-full object-contain" />
                            </div>
                            <div className="flex-col flex justify-start items-start w-full max-w-lg mt-10 gap-5 mx-auto">
                                <h1 className="Poppins text-new-color font-light text-4xl">
                                    Community (Free)
                                </h1>
                                <p className="Poppins">
                                    Unlock the power of knowledge exchange in
                                    our vibrant community hub, where industry
                                    leaders connect, inspire, and collaborate to
                                    fuel innovation and elevate success.
                                </p>
                                <div className="flex lg:hidden md:hidden items-center gap-5">
                                    <Buttonlink
                                        to={"/discussions"}
                                        Text={"Forum >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/events"}
                                        Text={"Events >>"}
                                        className="primary Poppins"
                                    />
                                    <Buttonlink
                                        to={"/knowledge-bank"}
                                        Text={"Resources >>"}
                                        className="primary Poppins"
                                    />
                                </div>
                                <AnimateButton to={'/community'} text={'Read More'} className={'flex h-12 Poppins justify-between items-center hover:text-white bg--primary white rounded-full w-48 px-4'} />
                            </div>
                            <div className="absolute lg:flex flex-col md:flex justify-evenly items-center hidden bg-white rounded-3xl p-6 bottom-10 lg:-left-16 md:-left-1/3 z-10 h-[calc(100%-290px)] overflow-y-auto scrollBar w-[500px] shadow-[0px_4px_20px_0px_#0000001A]">
                                <div className="space-y-2">
                                    <div className="space-y-2 flex justify-between items-center">
                                        <h1 className="Poppins text-[#16283A] text-2xl">
                                            Connection Forum
                                        </h1>
                                        <Link to="/discussions">
                                            <img
                                                src={arrow}
                                                alt="arrow"
                                                className="w-10 object-contain"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-[#16283A] Poppins">
                                        Control your participation. Search
                                        topics, text, usernames, and
                                        interests. Choose discussions.
                                        Manage questions and answers in your
                                        dashboard.
                                    </p>
                                </div>
                                <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                                <div className="space-y-2">
                                    <div className="space-y-2 flex justify-between items-center">
                                        <h1 className="Poppins text-[#16283A] text-2xl">
                                            Events
                                        </h1>
                                        <Link to="/events">
                                            <img
                                                src={arrow}
                                                alt="arrow"
                                                className="w-10 object-contain"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-[#16283A] Poppins">
                                        Discover thought-provoking events
                                        where professionals collaborate and
                                        shape expertise. Join us and
                                        esteemed partners on a journey of
                                        innovation and success.
                                    </p>
                                </div>
                                <div className="bg-[#A2A2B6] w-full h-px my-5 flex-shrink-0" />
                                <div className="space-y-2">
                                    <div className="space-y-2 flex justify-between items-center">
                                        <h1 className="Poppins text-[#16283A] text-2xl">
                                            Resources
                                        </h1>
                                        <Link to="/knowledge-bank">
                                            <img
                                                src={arrow}
                                                alt="arrow"
                                                className="w-10 object-contain"
                                            />
                                        </Link>
                                    </div>
                                    <p className="text-[#16283A] Poppins">
                                        Discover knowledge and inspiration.
                                        Explore curated newsletters, blogs,
                                        and success stories from us and
                                        partners, fueling your path to
                                        success.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-28 container">
                    <Team />
                </div>

                <div className="mt-28 h-screen testinomails">
                    <Subscribers />
                </div>

                <div className="mt-28 container">
                    <EventHighLight Buttonlink={Buttonlink} />
                </div>

                <div className="mt-28 container">
                    <h1 className="lg:mb-20 text-new-color md:mb-20 mb-10 lg:text-5xl font-light text-4xl lg:w-full md:w-full w-60 mx-auto text-center Poppins">
                        Find your Dream Job
                    </h1>
                    <div className="bg-white rounded-2xl w-full max-w-4xl mx-auto lg:shadow-[0px_4px_40px_#0000001a] md:shadow-[0px_4px_40px_#0000001a] p-10">
                        <div className="grid grid-cols-12 gap-5">
                            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                                <img
                                    src={Job}
                                    alt="Job"
                                    className="w-full object-contain"
                                />
                            </div>
                            <div className="flex lg:items-start md:items-start items-center flex-col lg:col-span-6 md:col-span-6 col-span-12 gap-5">
                                <p className="lg:text-left text-lg font-normal Poppins md:text-left text-center">
                                    Let your knowledge not your CV, tell your
                                    story. Browse the largest single stop
                                    collection of procurement and supply chain
                                    jobs.
                                </p>
                                <p className="primary Poppins text-lg font-normal">
                                    No membership required
                                </p>
                                <AnimateButton to={'/job-central'} text={'Search Job'} className={'flex bg--primary rounded-full white hover:text-white Poppins justify-between items-center h-12 px-5 w-48'} />
                            </div>
                        </div>
                    </div>
                    <div className="hidden grid-cols-12 lg:grid md:grid gap-5 w-full max-w-4xl mx-auto mt-10">
                        <div className="col-span-4">
                            <img src={Group} alt="Group" />
                        </div>
                        <div className="col-span-4">
                            <img src={Group} alt="Group" />
                        </div>
                        <div className="col-span-4">
                            <img src={Group} alt="Group" />
                        </div>
                    </div>
                </div>

                <div className="mt-28 -mb-36 container">
                    <Knowledgepocket BsArrowRight={BsArrowRight} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(Home);
