import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BsPlusLg } from "react-icons/bs";

export default function TechnicalFaq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                <p className="Poppins text-lg font-normal text-[#16283A]">Techinal issue</p>
                <div className="grid grid-cols-12 place-items-center gap-5">
                    <div className="divide-y col-span-12">
                        <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    How do I report a technical issue related to
                                    procurementleague.com?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg text-new-color font-normal">
                                    To report a technical issue with
                                    procurementleague.com, please email
                                    contactus@procurementleague.com. We
                                    appreciate your feedback.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Why haven't I received any emails regarding
                                    Procurement League Events/Newsletters for a
                                    while?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg text-new-color font-normal">
                                    We are sorry you have not been receiving our
                                    emails. Spam filter settings on your
                                    computer or company network could be one of
                                    the reasons. Please email us at
                                    contactus@procurementleague.com, and we will
                                    attempt to identify the cause. If your
                                    Internet provider or email application
                                    filters incoming email, please add
                                    procurementleague.com to your list of
                                    approved senders to ensure that you receive
                                    our email newsletters and alerts.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Why can't I download a Whitepaper?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg text-new-color font-normal">
                                    Please note that only registered members can
                                    download Whitepapers. To view Whitepaper PDF
                                    files, you must have Adobe Reader installed
                                    on your computer. To obtain a copy of this
                                    software, please visit the Adobe website.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    );
}
