import React, { Component } from "react";
import Slider from "react-slick";
import partners from "assets/images/SuccessStories/partners.png";

class PartnersTestimonial extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
        };
        const testimonials = [
            {
                text: "Many. Many. So many thank you ! You did so much for the event, in such a transparent way and involving so much time that I can’t imagine a better Partner. Thank you so much! Kudos all the way & it was a pleasure.",
                author: "PR & Marketing Specialist, Procurement Solution Company",
            },
            {
                text: "Thank you so much for being a sponsor of Optimal. It was a huge success and we are thrilled with the outcome. The procurement community heard from Best of Breed startups, thought leaders, innovators, and customers using AI in procurement.",
                author: "Digital Marketing Manager, E-Sourcing Company",
            },
            {
                text: "Was great to work with Procurement League in 2021 and the wider procurement network. I’m grateful to have led the Development League with Rajiv Gupta and Hamza Kamal and for the opportunity to mentor Helen Melian.",
                author: "Leading Procurement Practitioner",
            },
        ];
        return (
            <div className="flex justify-start pt-20 container items-center flex-col h-full">
                <img src={partners} alt="partners" className="w-[274px] h-[178px] lg:block hidden" />
                <h1 className="lg:text-5xl text-4xl lg:font-light md:font-light font-bold mt-10 lg:mb-20 mb-10 text-new-color text-center Poppins">
                    What Our Partners Have To Say
                </h1>

                <div className="rounded-2xl bg-white lg:h-64 md:h-64 sm:h-64 h-[310px] w-full lg:px-20 md:px-10 shadow-lg px-5 relative max-w-4xl mx-auto home_slider">
                    <div className="absolute right-[44%] -top-10">
                        <svg
                            width="72"
                            height="54"
                            viewBox="0 0 72 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                fill="#FF6600"
                            />
                        </svg>
                    </div>
                    <Slider {...settings} className="h-full">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="p-2 md:flex !block">
                                <div className="p-4 md:p-10 md:flex-1 md:mx-2">
                                    <div className="text-center Poppins md:text-left">
                                        <p className="lg:text-lg md:text-lg sm:text-lg text-base Poppins font-normal text-new-color">{testimonial.text}</p>
                                        <strong className="block mt-2 md:mt-4 lg:text-lg md:text-lg sm:text-lg text-base Poppins font-bold text-new-color">
                                            {testimonial.author}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default PartnersTestimonial;
