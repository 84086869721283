import { ProfileAvatar } from "components";
import * as React from "react";
import { FiChevronDown } from "react-icons/fi";

export default function expertquestions() {
  return (
    <div className="rounded-xl shadow--sm">
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <ProfileAvatar className="object-cover w-20 h-20 mr-3 rounded-full" />
            <div>
              <p className="text-2xl Medium">Procurement League </p>
            </div>
          </div>
        </div>
        <div className="my-4 divider" />
        <h4 className="mb-4 Medium">
          Eka is a global leader in providing digital commodity management
        </h4>
        <p className="mb-2 primary">Answer</p>
        <p className="leading-normal text-color">
          Eka is a global leader in providing digital commodity management
          solutions driven by Cloud, Blockchain, Machine Learning and Analytics.
          The company’s best-of-breed solutions serve the entire trading value
          chain across agriculture, energy, metals and mining and manufacturing
          markets. Eka is reimagining commodity management with a new age
          architecture that breaks monolithic ETRM and CTRM systems into
          bite-sized chunks of powerful, enterprise-grade apps.
        </p>
      </div>
      <div className="flex items-center justify-center cursor-pointer h-14">
        <p className="primary">Read more</p>
        <FiChevronDown className="primary" />
      </div>
    </div>
  );
}
