import React, { useEffect } from "react";
import Profilesidebar from "../../../components/profile/profilesidebar";
import { seo } from "helpers/seo";
import { connect } from "react-redux";
import { Nocontent } from "../../../components";
import moment from "moment";

function UserPaymentHistory({ authUser }) {
  useEffect(() => {
    seo({
      title: "Payment History | Procurement League ",
    });
  }, []);

  let subscriptionsPurchased = authUser.subscriptionsPurchased;
  let productPurchased = authUser.productPurchased;

  return (
      <div className="container">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-7">
          <Profilesidebar authUser={authUser} />
          <div className="lg:col-span-5">
            <div className="p-3 mb-3 border rounded-xl lg:p-8">
              {productPurchased || subscriptionsPurchased ? null : (
                  <Nocontent />
              )}
              <div className="flex flex-col">
                <div
                    className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                    id="scrollbar">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                          <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
                            Receipt ID
                          </th>
                          <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
                            Name
                          </th>
                          <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
                            Created at
                          </th>
                          <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
                            Validity
                          </th>
                          <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
                            Total price
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {subscriptionsPurchased?.data.map((person) => (
                            <tr key={person.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {person.invoice_num}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {person.plan.name}
                                </div>
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                {moment(person.created_at).format(
                                    "DD-MM-YYYY"
                                )}
                              </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
                                {person.plan && person.plan.validity === "Y"
                                    ? "Yearly"
                                    : "Monthly"}
                              </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  $ {person.total_payment}
                                </div>
                              </td>
                            </tr>
                        ))}
                        {productPurchased?.data.map((person) => (
                            <tr key={person.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {person.invoice_num}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {person.course.course_name}
                                </div>
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                {moment(person.created_at).format(
                                    "DD-MM-YYYY"
                                )}
                              </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
                                one-time purchase
                              </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  $ {person.total_payment}
                                </div>
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(UserPaymentHistory);
