import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
export default function CategoriesPopup(props) {
  let user = props.user;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.show}
      onClose={props.close}>
      <div className="flex items-center px-5 py-3 bg-gray-200 border-b border-gray-200">
        <h4 className="mr-auto text-lg Medium">Categories</h4>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.close}
          aria-label="close"
          className="hover:text-[#f58020]">
          <IoCloseCircleOutline size={32} />
        </IconButton>
      </div>
      <div className="flex flex-wrap items-center gap-2 p-5">
        {user.categories.map((category) => {
          return (
            <div key={category?.id}>
              <div className="inline-block px-5 py-2 bg-gray-200 rounded-lg hover:opacity-90 intro-x">
                <p className="fs-13">{category.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
}
