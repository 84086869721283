import { gql } from "@apollo/client";

export default gql`
  mutation createUser(
    $email: String!
    $username: String!
    $firstname: String!
    $lastname: String!
    $password: String!
  ) {
    createUser(
      email: $email
      username: $username
      firstname: $firstname
      lastname: $lastname
      password: $password
    ) {
      id
      email
      username
    }
  }
`;
