import { useRef, useEffect } from "react";
import lottie from "lottie-web";
import loaderGold from "assets/lotties/loader_gold.json";
import loaderWhite from "assets/lotties/loader_white.json";

export default function IsLoader(props) {
  const { className, white } = props;
  const black = useRef(null);
  const _white = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: black.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loaderGold,
      rendererSettings: {},
    });

    lottie.loadAnimation({
      container: _white.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loaderWhite,
      rendererSettings: {},
    });

    return () => {
      lottie.destroy();
    };
  }, []);
  return <div ref={white ? _white : black} className={className} />;
}
