import { EducationalMedia, IsLoader, NewsArticles, Noconnection, Whitepapers } from "components";
import React from "react";
import TrainingModuleFooter from "components/allcomponents/trainingModule";
import { Query } from "@apollo/client/react/components";
import KnowLedgeBankValues from "../../graphql/queries/knowledgeBank";
import { extractValidationErrors } from "helpers/extractValidationErrors";

const KnowLedgeBank = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="bg--lightBlue pt-10 -mt-[50px]">
            <div className="container relative">
                <div className="absolute top-[10%] right-[6%] lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="81"
                        viewBox="0 0 258 258"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_742_2919)">
                            <path
                                d="M64.3955 94.4469L98.7398 128.791L64.3955 163.135C55.8094 171.722 55.8094 184.601 64.3955 193.187C72.9815 201.773 85.8607 201.773 94.4467 193.187L128.791 158.842L163.135 193.187C171.721 201.773 184.6 201.773 193.187 193.187C201.773 184.601 201.773 171.722 193.187 163.135L158.842 128.791L193.187 94.4469C201.773 85.8608 201.773 72.9817 193.187 64.3957C184.6 55.8096 171.721 55.8096 163.135 64.3957L128.791 98.7399L94.4467 64.3957C85.8606 55.8096 72.9815 55.8096 64.3955 64.3957C55.8094 72.9817 55.8094 85.8609 64.3955 94.4469Z"
                                fill="white"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_742_2919">
                                <rect
                                    width="182.138"
                                    height="182.138"
                                    fill="white"
                                    transform="translate(128.791 257.582) rotate(-135)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute top-[37%] right-[10%] lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="81"
                        viewBox="0 0 258 258"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_742_2919)">
                            <path
                                d="M64.3955 94.4469L98.7398 128.791L64.3955 163.135C55.8094 171.722 55.8094 184.601 64.3955 193.187C72.9815 201.773 85.8607 201.773 94.4467 193.187L128.791 158.842L163.135 193.187C171.721 201.773 184.6 201.773 193.187 193.187C201.773 184.601 201.773 171.722 193.187 163.135L158.842 128.791L193.187 94.4469C201.773 85.8608 201.773 72.9817 193.187 64.3957C184.6 55.8096 171.721 55.8096 163.135 64.3957L128.791 98.7399L94.4467 64.3957C85.8606 55.8096 72.9815 55.8096 64.3955 64.3957C55.8094 72.9817 55.8094 85.8609 64.3955 94.4469Z"
                                fill="white"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_742_2919">
                                <rect
                                    width="182.138"
                                    height="182.138"
                                    fill="white"
                                    transform="translate(128.791 257.582) rotate(-135)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute bottom-[37%] right-[10%] lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="81"
                        viewBox="0 0 258 258"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_742_2919)">
                            <path
                                d="M64.3955 94.4469L98.7398 128.791L64.3955 163.135C55.8094 171.722 55.8094 184.601 64.3955 193.187C72.9815 201.773 85.8607 201.773 94.4467 193.187L128.791 158.842L163.135 193.187C171.721 201.773 184.6 201.773 193.187 193.187C201.773 184.601 201.773 171.722 193.187 163.135L158.842 128.791L193.187 94.4469C201.773 85.8608 201.773 72.9817 193.187 64.3957C184.6 55.8096 171.721 55.8096 163.135 64.3957L128.791 98.7399L94.4467 64.3957C85.8606 55.8096 72.9815 55.8096 64.3955 64.3957C55.8094 72.9817 55.8094 85.8609 64.3955 94.4469Z"
                                fill="white"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_742_2919">
                                <rect
                                    width="182.138"
                                    height="182.138"
                                    fill="white"
                                    transform="translate(128.791 257.582) rotate(-135)"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden left-20 top-[14%]">
                    <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="19.5"
                            cy="19.5"
                            r="19.5"
                            fill="#B5E2FF"
                        ></circle>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -left-44 top-1/3">
                    <svg
                        width="144"
                        height="144"
                        viewBox="0 0 144 144"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="72"
                            cy="72"
                            r="57"
                            fill="white"
                            stroke="#FFEFE5"
                            strokeWidth="30"
                        ></circle>
                    </svg>
                </div>
                <div className="flex gap-1 items-center">
                    <p className="font-normal Poppins primary text-lg text-new-color">
                        Explore Source Connect
                    </p>
                </div>
                <div className="grid mt-3 lg:grid-cols-2 grid-cols-1 relative">
                    <div>
                        <h1 className="lg:text-5xl text-4xl text-new-color Poppins font-light">
                            Knowledge Bank
                        </h1>
                        <p className="text-lg Poppins text-new-color pt-5 font-normal">
                            Your go-to treasure trove of educational resources,
                            newsletters, and whitepapers, meticulously curated
                            to enhance your knowledge and understanding in a
                            professional context.
                        </p>
                        <div className="h-px bg--gray w-full my-8" />
                    </div>
                </div>
                <div className="mt-28 space-y-28">
                    <Query query={KnowLedgeBankValues}>
                        {({ loading, error, data, fetchMore, refetch }) => {
                            if (loading) {
                                return (
                                    <div className="my-10">
                                        <IsLoader className="w-32 h-32 mx-auto" />
                                    </div>
                                );
                            }
                            if (error) {
                                extractValidationErrors(error);
                                return <Noconnection />
                            };

                            if (!data) {
                                return Pagenotfound;
                            }

                            // if (data.UserSearch.data.length === 0) return <Nocontent />;

                            if (data !== undefined) {
                                return (
                                    <>
                                        {data?.KnowledgeBank.map((KnowledgeBank, index) => (
                                            <EducationalMedia key={index} data={KnowledgeBank} />
                                        ))}
                                    </>
                                );
                            }
                        }}
                    </Query>

                </div>
                <TrainingModuleFooter />
            </div>
        </div>
    );
};

export default KnowLedgeBank;
