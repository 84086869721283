import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import Subtract from "assets/images/Subtract-white.png";
import Ami from "assets/images/SuccessStories/Ami.png";
import Krinati from "assets/images/SuccessStories/krinati_logo.png";
import SIG from "assets/images/SuccessStories/SIG-ORG_Full.png"; 
import MobileBanner from "assets/images/SuccessStories/successstories.png";
import { useHistory } from "react-router-dom";
import { Knowledgepocket, PartnersTestimonial } from "components";

const SuccessSellSidePage = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="-mt-[50px]">
            <div className="lg:bg-transparent md:bg-transparent bg-black">
                <div className="lg:hidden md:hidden relative">
                    <img src={MobileBanner} alt="banner" className="object-cover w-full h-full object-center" />
                </div>
                <div className="lg:h-[90vh] md:h-[100vh] SuccessStoriesBuySideBanner relative">
                    <div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                        <p className="text-[#FBE4D3] Poppins font-normal">
                            Success Stories
                        </p>
                        <p className="primary font-light Poppins md:leading-[60px] leading-[35px] lg:text-[48px] md:text-[48px] text-[30px]">
                            Sellside Success Stories: Partner's Spotlight
                        </p>
                        <div className="h-px bg-white" />
                        <p className="white Poppins font-normal">
                            Discover Inspiring Narratives of Achievement and Growth
                        </p>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                            <button
                                onClick={() => history.push("/success-stories-buyside")}
                                className="group px-4 hover:bg--black border-2 border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                            >
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                    Buyside <BsArrowRight size={23} />
                                </span>
                            </button>
                            <button onClick={() => history.push("/success-stories-sellside")} className="group px-4 hover:bg--black border-2 border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg-transparent">
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative primary flex Poppins items-center justify-between group-hover:text-white">
                                    Sellside <BsArrowRight size={23} />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                        <img
                            src={Subtract}
                            alt="Subtract"
                            className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                        />
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-10 mt-44 relative">
                <div className="absolute lg:block hidden -top-60 left-28">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="144"
                        height="144"
                        viewBox="0 0 144 144"
                        fill="none"
                    >
                        <circle
                            cx="72"
                            cy="72"
                            r="57"
                            fill="white"
                            stroke="#B5E2FF"
                            strokeWidth="30"
                        />
                    </svg>
                </div>
                <div className="absolute lg:block hidden -top-52 left-96">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -top-52 right-96">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -top-44 right-44">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="46"
                        viewBox="0 0 46 46"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1849)">
                            <path
                                d="M19.3502 6.30676L18.3777 17.5267L7.15768 16.5542C4.35269 16.3111 2.0666 18.2325 1.82347 21.0375C1.58034 23.8425 3.50174 26.1286 6.30673 26.3717L17.5267 27.3442L16.5542 38.5642C16.3111 41.3692 18.2325 43.6553 21.0375 43.8984C23.8425 44.1415 26.1285 42.2201 26.3717 39.4152L27.3442 28.1952L38.5642 29.1677C41.3692 29.4108 43.6553 27.4894 43.8984 24.6844C44.1415 21.8794 42.2201 19.5933 39.4151 19.3502L28.1951 18.3777L29.1677 7.15771C29.4108 4.35272 27.4894 2.06663 24.6844 1.8235C21.8794 1.58037 19.5933 3.50177 19.3502 6.30676Z"
                                fill="#B5E2FF"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1849">
                                <rect
                                    width="42.2327"
                                    height="42.2327"
                                    fill="white"
                                    transform="translate(0 42.075) rotate(-85.0461)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden bottom-[15%] right-1/2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -bottom-28 left-28">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="grid grid-cols-1 container gap-16">
                    <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] left-1/4 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="grid grid-cols-12 gap-5 place-items-center">
                            <div className="lg:col-span-2 col-span-12">
                                <img src={Ami} alt="Ami" className="object-contain" />
                            </div>
                            <div className="mt-8 space-y-5 lg:col-span-10 col-span-12">
                                <h1 className="text-black font-light Poppins lg:leading-[60px] leading-[30px] lg:text-[48px] md:text-[48px] text-[30px]">Ami Lifescience</h1>
                                <div className="flex gap-5 lg:flex-row flex-col pr-8">
                                    <div className="space-y-3">
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            We wanted to share the incredible success we've experienced since teaming up with Procurement League to revamp our sales deck. Their unique approach and attention to detail transformed our presentation, making our services more accessible and compelling for clients.
                                        </p>
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            The visually stunning and strategically crafted sales deck not only captured attention but also clarified our value proposition.
                                        </p>
                                    </div>
                                    <div className="space-y-3">
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            Since its implementation, we've seen a significant increase in client engagement, leading to successful conversions and improved bottom-line results.
                                        </p>
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            Our sincere thanks to the Procurement League team for their creativity and dedication. You've played a pivotal role in our success story, and we look forward to continuing this fruitful collaboration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] left-1/4 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="grid grid-cols-12 gap-5 place-items-center">
                            <div className="lg:col-span-2 col-span-12">
                                <img src={Krinati} alt="Krinati" className="object-contain" />
                            </div>
                            <div className="mt-8 space-y-5 lg:col-span-10 col-span-12">
                                <h1 className="text-black font-light Poppins lg:leading-[60px] leading-[30px] lg:text-[48px] md:text-[48px] text-[30px]">Krinati</h1>
                                <div className="flex gap-5 lg:flex-row flex-col pr-8">
                                    <div className="w-full">
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            Partnering with Procurement League, Krinati, an advanced eSourcing platform developed by experienced procurement practitioners, achieved remarkable success in revolutionizing the procurement landscape.
                                        </p>
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            By leveraging Procurement League's extensive network, Krinati conducted high-level demos and feature descriptions, captivating senior procurement executives. These interactions resulted in active interest, with potential buyers recognizing Krinati as an innovative solution for their sourcing needs.
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            The collaboration generated increased engagement, favorable impressions, advanced conversations, and a surge in sales opportunities. Krinati's partnership with Procurement League solidified its position as a game-changer in eSourcing solutions. This success story exemplifies the power of collaboration, expertise, and innovation, paving the way for a brighter future in procurement practices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] left-1/4 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="grid grid-cols-12 gap-5 place-items-center">
                            <div className="lg:col-span-2 col-span-12">
                                <img src={SIG} alt="SIG" className="object-contain" />
                            </div>
                            <div className="mt-8 space-y-5 lg:col-span-10 col-span-12">
                                <h1 className="text-black font-light Poppins lg:leading-[60px] leading-[30px] lg:text-[48px] md:text-[48px] text-[30px]">SIG</h1>
                                <div className="flex gap-5 lg:flex-row flex-col pr-8">
                                    <div className="space-y-3">
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            We are excited to share our ongoing success story with Procurement League, an invaluable partner that has consistently served as our backbone in the realm of research and data analysis.
                                        </p>
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            Procurement League has been instrumental in crafting comprehensive and insightful research reports for our organization. Their commitment to excellence and attention to detail shine through in every project, enabling us to present data-driven insights that have proven indispensable for our decision-making processes.
                                        </p>
                                    </div>
                                    <div className="space-y-3">
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            What sets Procurement League apart is not just their proficiency in data analysis, but their collaborative spirit. The synergy between our teams has fostered a seamless exchange of ideas, resulting in research reports that not only meet but exceed our expectations.
                                        </p>
                                        <p className="text-lg Poppins text-new-color font-normal">
                                            As we continue to evolve and grow, we look forward to further leveraging the expertise of Procurement League in our research endeavors. Heartfelt thanks to the entire Procurement League team for being a reliable and innovative force in our success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-28 h-screen testinomails">
                <PartnersTestimonial />
            </div>
            <div className="mt-28 container -mb-36">
                <Knowledgepocket isImageHide={false} />
            </div>
        </div>
    );
};

export default SuccessSellSidePage;
