import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import business from "assets/images/aboutus/business.png";
import slider2 from "assets/images/aboutus/slider2.webp";
import slider3 from "assets/images/aboutus/slider3.webp";
import slider4 from "assets/images/aboutus/slider4.webp";
import slider5 from "assets/images/aboutus/slider5.webp";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute Poppins right-0 top-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
            onClick={onClick}
        >
            <BsArrowRight
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute Poppins z-10 top-1/2 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
            onClick={onClick}
        >
            <BsArrowLeft
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

const AboutUsSlider = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1224,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };
    return (  
        <div className="relative aboutus_slider">
            <Slider {...settings} className="px-10 important:static">
                <div className="mt-28 lg:h-[600px] h-[300px] relative">
                    <div className="h-full absolute inset-0 bg-gradient-to-t from-transparent to-black">
                        <p className="text-white text-center lg:text-3xl text-lg pt-10 font-light">
                            Here's the dream team in action, turning ideas into
                            reality.
                        </p>
                    </div>
                    <img
                        src={business}
                        alt="business"
                        className="h-full w-full object-cover"
                    />
                </div>
                <div className="mt-28 lg:h-[600px] h-[300px] relative">
                    <div className="h-full absolute inset-0 bg-gradient-to-t from-transparent to-black">
                        <p className="text-white text-center lg:text-3xl text-lg pt-10 font-light">
                            Our team lunches are where great ideas and great friendships blossom. 
                        </p>
                    </div>
                    <img
                        src={slider2}
                        alt="slider2"
                        className="h-full w-full object-cover"
                    />
                </div>
                <div className="mt-28 lg:h-[600px] h-[300px] relative">
                    <div className="h-full absolute inset-0 bg-gradient-to-t from-transparent to-black">
                        <p className="text-white text-center lg:text-3xl text-lg pt-10 font-light">
                            Here's to celebrating milestones, big and small, with the incredible individuals who make up the heart of Procurement League. 
                        </p>
                    </div>
                    <img
                        src={slider3}
                        alt="slider3"
                        className="h-full w-full object-cover"
                    />
                </div>
                <div className="mt-28 lg:h-[600px] h-[300px] relative">
                    <div className="h-full absolute inset-0 bg-gradient-to-t from-transparent to-black">
                        <p className="text-white text-center lg:text-3xl text-lg pt-10 font-light">
                            Employee Spotlights - Meet the faces behind the <br /> success of Procurement League! .
                        </p>
                    </div>
                    <img
                        src={slider4}
                        alt="slider4"
                        className="h-full w-full object-cover"
                    />
                </div>
                <div className="mt-28 lg:h-[600px] h-[300px] relative">
                    <div className="h-full absolute inset-0 bg-gradient-to-t from-transparent to-black">
                        <p className="text-white text-center lg:text-3xl text-lg pt-10 font-light">
                            Work Hard, Play Harder - Balancing work and play because <br /> a happy team is a productive team! 
                        </p>
                    </div>
                    <img
                        src={slider5}
                        alt="slider5"
                        className="h-full w-full object-cover"
                    />
                </div>
            </Slider>
        </div>
    );
};

export default AboutUsSlider;
