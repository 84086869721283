import { Buttonlink } from "components";
import React, { Component } from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import blog from "assets/images/blog.webp";
import { FACEBOOK_URL, LINKEDIN_URL } from "../../config/constants";

class BlogDetail extends Component {
  render() {
    return (
      <div className="container">
        <div className="grid lg:grid-cols-7">
          <div className="">
            <Buttonlink
              Text="back"
              Icon={<IoIosArrowBack />}
              to="/"
              className="flex items-center justify-center px-4 mb-3 rounded-full bg--lightGray h-9 darkGray w-28 hover:darkGray hover:opacity-80"
            />
            <div className="flex flex-col items-center justify-center h-full gap-4">
              <a
                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform bg-gray-800 rounded-full hover:-translate-y-1 hover:scale-110 black hover:bg-gray-200 hover:primary-all-text"
                href={LINKEDIN_URL}
                target={"_blank"}
                rel="noreferrer">
                <FaLinkedinIn
                  size={18}
                  className="cursor-pointer white hover"
                />
              </a>
              <a
                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform bg-gray-800 rounded-full hover:-translate-y-1 hover:scale-110 black hover:bg-gray-200 hover:primary-all-text"
                href={FACEBOOK_URL}
                target={"_blank"}
                rel="noreferrer">
                <FaFacebookF size={18} className="cursor-pointer white hover" />
              </a>
              <a
                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform bg-gray-800 rounded-full hover:-translate-y-1 hover:scale-110 black hover:bg-gray-200 hover:primary-all-text"
                href="https://twitter.com/procurementlea"
                target={"_blank"}
                rel="noreferrer">
                <FaTwitter size={18} className="cursor-pointer white hover" />
              </a>
            </div>
          </div>
          <div className="col-span-6 mt-5">
            <div className="flex ">
              <img
                src={blog}
                alt=" Wake-up calls for midmarket organizations!"
                className="object-cover m-auto h-60"
              />
              <div className="ml-4">
                <p className="gray fs-13 Light">Aug, 2, 2019</p>
                <h2 className="text-2xl Medium lg:text-4xl">
                  Wake-up calls for midmarket organizations!
                </h2>
              </div>
            </div>
            <div className="grid gap-4 mt-5 lg:grid-cols-3">
              <div className="col-span-2 ">
                <p className="mb-4">
                  Procure-to-Pay (P2P) solutions have been around for a long
                  time now. There has been a paradigm shift in how P2P solutions
                  have evolved over the years. You (the mid-market segment)
                  contribute a major chunk to the overall structure of the
                  economy. It is in the best interest of the mid-market
                  organizations and P2P solution providers to utilize these P2P
                  solutions to better govern and manage their spend; especially
                  getting a grip on tail spend.
                </p>
                <p className="mb-4">
                  Procure-to-Pay (P2P) solutions have been around for a long
                  time now. There has been a paradigm shift in how P2P solutions
                  have evolved over the years. Economy. It is in the best
                  interest of the mid-market organizations and P2P solution
                  providers to utilize these P2P solutions to better govern and
                  manage their spend; especially getting a grip on tail spend.
                </p>
                <p className="mb-4">
                  Solutions have been around for a long time now. There has been
                  a paradigm shift in how P2P solutions. Procure-to-Pay (P2P)
                  solutions have been around for a long time now. There has been
                  a paradigm shift in how P2P solutions have evolved over the
                  years. You (the mid-market segment) contribute a major chunk
                  to the overall structure of the economy. It is in the best
                  interest of the mid-market organizations and P2P solution
                  providers to utilize these P2P solutions to better govern and
                  manage their spend; especially getting a grip on tail spend.
                </p>
                <div className="p-4 bg-gray-100 border-l-2 rounded-xl border-darkGray">
                  <p className="Medium">Important!</p>
                  <p>
                    Even though garcinia cambogia may cause modest weight loss,
                    the effects are so small that they probably won’t even be
                    noticeable.
                  </p>
                </div>
                <h2 className="mt-5 mb-4 Medium">
                  Calls for midmarket organizations!
                </h2>
                <p className="mb-4">
                  Procure-to-Pay (P2P) solutions have been around for a long
                  time now. There has been a paradigm shift in how P2P solutions
                  have evolved over the years. You (the mid-market segment)
                  contribute a major chunk to the overall structure of the
                  economy. It is in the best interest of the mid-market
                  organizations and P2P solution providers to utilize these P2P
                  solutions to better govern and manage their spend; especially
                  getting a grip on tail spend.
                </p>
                <p className="mb-4">
                  Procure-to-Pay (P2P) solutions have been around for a long
                  time now. There has been a paradigm shift in how P2P solutions
                  have evolved over the years. Economy. It is in the best
                  interest of the mid-market organizations and P2P solution
                  providers to utilize these P2P solutions to better govern and
                  manage their spend; especially getting a grip on tail spend.
                </p>
                <h2 className="mt-5 mb-4 Medium">
                  Headline for midmarket organizations!
                </h2>
                <p className="mb-4">
                  Procure-to-Pay (P2P) solutions have been around for a long
                  time now. There has been a paradigm shift in how P2P solutions
                  have evolved over the years. You (the mid-market segment)
                  contribute a major chunk to the overall structure of the
                  economy. It is in the best interest of the mid-market
                  organizations and P2P solution providers to utilize these P2P
                  solutions to better govern and manage their spend; especially
                  getting a grip on tail spend.
                </p>
                <p className="mb-4">
                  Procure-to-Pay (P2P) solutions have been around for a long
                  time now. There has been a paradigm shift in how P2P solutions
                  have evolved over the years. Economy. It is in the best
                  interest of the mid-market organizations and P2P solution
                  providers to utilize these P2P solutions to better govern and
                  manage their spend; especially getting a grip on tail spend.
                </p>
                <div className="p-4 bg-gray-100 border-l-2 rounded-xl border-darkGray">
                  <p className="gray Light">Procurement recurces</p>
                  <h4 className="my-2 text-2xl Medium">
                    Take our free 3-question diet quiz
                  </h4>
                  <p>
                    Procure-to-Pay (P2P) solutions have been around for a long
                    time now. There has been a paradigm shift in how P2P
                    solutions have evolved over the years.
                  </p>
                  <Buttonlink
                    to="/blog-detail"
                    Text="Read more"
                    className="flex items-center justify-center flex-grow h-10 mt-4 bg-gray-300 border-0 shadow-none darkGray hover:darkGray Regular hover:opacity-80 focus:bg-gray-300 rounded--full w-36"
                  />
                </div>
              </div>
              <div className="p-2">
                <div className="mb-10">
                  <div>
                    <img
                      alt="First headline calls for midmarket organizations!"
                      src={blog}
                      className="z-0 object-cover mx-auto h-52 rounded-xl"
                    />
                  </div>
                  <div className="relative z-10 w-full p-3 -mt-10 bg-white border shadow-sm rounded-xl left-2">
                    <h6 className="mb-1 Medium fs-14 ">
                      First headline calls for midmarket organizations!
                    </h6>
                    <Link
                      to={"#"}
                      className="flex items-center mt-2 black Regular fs-12">
                      Read more
                      <FiChevronRight />
                    </Link>
                  </div>
                </div>
                <div className="">
                  <img
                    alt="First headline calls for midmarket organizations!"
                    src={blog}
                    className="object-cover mx-auto h-52 rounded-xl"
                  />
                  <div className="relative z-10 w-full p-3 -mt-10 bg-white border shadow-sm rounded-xl left-2">
                    <h6 className="mb-1 Medium fs-14 ">
                      First headline calls for midmarket organizations!
                    </h6>
                    <Link
                      to={"#"}
                      className="flex items-center mt-2 black Regular fs-12">
                      Read more
                      <FiChevronRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogDetail;
