import React from "react";
import Rajiv from "assets/images/team/Rajiv.webp";
import Saheena from "assets/images/team/Saheena.webp";
import Erik from "assets/images/team/Erik.png";
import Zain from "assets/images/team/zain.webp";
import Hardik from "assets/images/team/Hardik.webp";
import Hamza from "assets/images/team/Hamza.webp";
import Joanna from "assets/images/Joanna.webp";
import Chris from "assets/images/team/Chris.png";
import Slider from "react-slick";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import TeamsPopup from "components/allcomponents/teamspopup/teamsPopup";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute Poppins md:right-32 right-0 top-[35%] z-10 flex items-center justify-center cursor-pointer slick-arrows next"
            onClick={onClick}
        >
            <BsArrowRight
                strokeWidth={1.2}
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute Poppins z-10 top-[35%] left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
            onClick={onClick}
        >
            <BsArrowLeft
                size={30}
                strokeWidth={1.2}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

class team extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef(null);
    }

    openPopup = (team) => {
        this.modalRef.current?.open(team);
    };


    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                    },
                },
            ],
        };

        const teamSlider = [
            {
                link: "https://www.linkedin.com/in/joanna-gosling-mcips-she-her-071ab516",
                image: Joanna,
                name: "Joanna Gosling, UK",
                designation: "Global Sourcing Professional",
                description:
                    "Joanna is an experienced Global Sourcing Manager. Her skill stack includes Sourcing and Business Strategy, Negotiation, Contract Law, Supply Chain Assessment, Mobilization and Transitions, Contract Management. Supplier Performance Management, and Strategic Supplier Relationship Development. Apart from her professional skill stack, she is a highly skilled nutritionist.",
                quote: "In sourcing, every deal is a success recipe. As a global sourcer and nutritionist, I mix business strategy with the right ingredients for procurement excellence.",
            },
            {
                link: "https://www.linkedin.com/in/erik-stavrand-4927271/",
                image: Erik,
                name: "Erik Stavrand, USA",
                designation: "Procurement Leader",
                description: `Erik is a globally recognized expert in procurement strategy and negotiation techniques. He has over 25 years of procurement education and transformation experience across works with numerous, global Fortune 100 and FTSE 50 companies in the pharmaceutical, manufacturing, medical products, technology, oil & gas and sectors.`,
                quote: `In every negotiation and procurement decision, we have the opportunity to set new standards, inspire innovation, and lead industries toward a brighter future.`,
            },
            {
                link: "https://www.linkedin.com/in/saheenaansari",
                image: Saheena,
                name: "Saheena Parween Ansari, India",
                designation: "Assistant Marketing",
                description: `Saheena thrives on building solid relationships, and nothing gives her greater satisfaction. She is an expert in content writing, social media marketing, and research. Whether chatting with a new client, brainstorming new ideas with her team, or simply sharing a cup of coffee with a colleague, she is always excited to see their next adventure.`,
                quote: `In marketing, every word is a stroke, every post a canvas. I paint brands with the colors of connection and creativity.`,
            },
            {
                link: "https://www.linkedin.com/in/chris-s-54a62aa/",
                image: Chris,
                name: "Chris Shanahan, USA",
                designation: "VP Global Sustainability Supply Chain",
                description: `Chris has over 17 years of experience in procurement roles at leading life sciences companies, Chris now spearheads Thermo Fisher's sustainability efforts across its supply chain. He focuses on areas like climate change, water conservation, human rights, and workplace fairness. Chris champions the company's ambitious net-zero emissions target for 2050, emphasizing a balanced approach that considers infrastructure readiness and investment cycles. He underscores the importance of education and collaboration in driving sustainable practices throughout the supply chain.`,
                quote: `Sustainability is the core of our procurement approach. By focusing on climate action, water conservation, and human rights, we reach net-zero goals and set new benchmarks for corporate responsibility.`,
            },
            {
                link: "https://www.linkedin.com/in/hamzakamal",
                image: Hamza,
                name: "Hamza, Australia",
                designation: "Procurement Category Manager",
                description: `Hamza is an experienced Procurement Category Manager with a demonstrated history of working in the hospital & health care industry. His academic education in accounting and previous experience in Finance and Sales allows him to think outside the box and run procurement projects incorporating the whole mix. He is passionate about motivational coaching.`,
                quote: ` Procurement is more than transactions; it's about healing. Each category managed is a step toward a healthier, more efficient future.`,
            },
            {
                image: Zain,
                name: "Zain, Pakistan",
                designation: "Chief Technology Officer",
                description: `Zain has achieved tremendous success in spearheading the digital procurement transformation of clients. He excels in developing and implementing micro-automation solutions for clients' existing source-to-pay and procure-to-pay systems. Zain's innovative approach has not only streamlined and optimized procurement operations but has also significantly improved efficiency, reduced costs, and delivered overall process enhancements for the clients he serves.`,
                quote: `In tech, every line of code is a bridge to efficiency. I build paths to a future of seamless procurement.`,
            },
            {
                link: "https://www.linkedin.com/in/hardikpandey",
                image: Hardik,
                name: "Hardik Pandey, India",
                designation: "Senior Research Analyst",
                description: `Hardik is a native of India who earned two degrees in mechanical engineering and an MBA before embarking on his career with Procurement League's India Office. He has become a prominent leader in Procurement Outsourcing and is equally passionate about Martial Arts.`,
                quote: `In procurement, like martial arts, mastery comes from knowledge, discipline, and a relentless pursuit of excellence.`,
            },
            {
                link: "https://www.linkedin.com/in/procurementtransformation",
                image: Rajiv,
                name: "Rajiv Gupta, USA",
                designation: "Founder",
                description: `Rajiv is a Procurement Nerd who started the Procurement League with a vision to bring a revolution in the procurement world, all with a community of procurement professionals, suppliers, vendors, and techies. His outlook is to offer holistic procurement growth from technological enablement of procurement departments to training the procurement teams.`,
                quote: `In the world of procurement, we're not just making deals; we're creating a community. The three Cs—Collaboration, Commitment, and Continuous Growth—fuel our vision for a revolutionary procurement future at Procurement League.`,
            },
        ];
        return (
            <>
                <div className="relative equal-height">
                    <h2 className="mb-20 text-new-color lg:text-5xl font-light text-4xl text-center Poppins">
                        The People Behind Procurement League
                    </h2>
                    <div className="absolute -top-16 right-0 lg:hidden md:hidden block">
                        <svg
                            width="46"
                            height="46"
                            viewBox="0 0 46 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1472_3592)">
                                <path
                                    d="M19.3502 6.307L18.3777 17.527L7.15768 16.5545C4.35269 16.3113 2.0666 18.2327 1.82347 21.0377C1.58034 23.8427 3.50174 26.1288 6.30673 26.372L17.5267 27.3445L16.5542 38.5644C16.3111 41.3694 18.2325 43.6555 21.0375 43.8987C23.8425 44.1418 26.1285 42.2204 26.3717 39.4154L27.3442 28.1954L38.5642 29.1679C41.3692 29.4111 43.6553 27.4897 43.8984 24.6847C44.1415 21.8797 42.2201 19.5936 39.4151 19.3505L28.1951 18.3779L29.1677 7.15796C29.4108 4.35296 27.4894 2.06687 24.6844 1.82374C21.8794 1.58061 19.5933 3.50201 19.3502 6.307Z"
                                    fill="#B5E2FF"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1472_3592">
                                    <rect
                                        width="42.2327"
                                        height="42.2327"
                                        fill="white"
                                        transform="translate(0 42.0752) rotate(-85.0461)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <Slider {...settings} className="important:static px-5">
                        {teamSlider.map((team, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => this.openPopup(team)}
                                    className="cursor-pointer flex flex-col"
                                >
                                    <div className="block pt-4 xs:px-4 sm:px-10">
                                        <img
                                            alt={team.name}
                                            src={team.image}
                                            className={`object-contain w-52 grayscale`}
                                        />
                                        <h4 className="mt-3 text-3xl text-new-color font-light Poppins primary">
                                            {team.name}
                                        </h4>
                                        <p className="text-3xl text-new-color font-light Poppins black">
                                            {team.designation}
                                        </p>
                                    </div>
                                    <div className="pb-4 xs:px-4 sm:px-10">
                                        <p className="mt-3 text-lg line-clamp-3 text-new-color text-left Poppins">
                                            {team.description}
                                        </p>
                                        <p>
                                            <BsArrowRight
                                                size={30}
                                                className="primary"
                                            />
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <TeamsPopup
                    ref={this.modalRef}
                />
            </>
        );
    }
}

export default team;
