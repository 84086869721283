import React from "react";
import { Query } from "@apollo/client/react/components";
import UPCOMING_EVENTS from "graphql/queries/getClasses";
import PROTECTED_EVENTS from "graphql/queries/getMinimalClasses";
import { Eventscard, Loader, Nocontent } from "components";
import Auth from "Auth";

const auth = new Auth();

const EventHighLight = ({ Buttonlink }) => {
    let variables2 = {};
    variables2.typeInfo = "expired";

    return (
        <div>
            <h1 className="mb-20 text-new-color lg:text-5xl font-light text-4xl text-center Poppins">
                Discover Event Highlights Now!
            </h1>

            <Query
                query={
                    auth.isAuthenticated() ? UPCOMING_EVENTS : PROTECTED_EVENTS
                }
                variables={variables2}
            >
                {({ loading, error, data: pastEvent }) => {
                    // this.refetch.push(refetch);
                    if (loading) {
                        return (
                            <div>
                                <Loader />
                            </div>
                        );
                    }
                    if (error) return <div>{null}</div>;

                    if (
                        auth.isAuthenticated()
                            ? pastEvent?.courses?.data?.length === 0
                            : pastEvent?.open_courses?.data?.length === 0
                    ) {
                        return <Nocontent />;
                    }

                    if (pastEvent !== undefined) {
                        return (
                            <>
                                <Eventscard
                                    hideTitle={true}
                                    eventType={"Past Events"}
                                    latestEvent={
                                        auth.isAuthenticated()
                                            ? pastEvent.courses.data
                                            : pastEvent.open_courses.data
                                    }
                                />
                            </>
                        );
                    }
                }}
            </Query>
        </div>
    );
};

export default EventHighLight;
