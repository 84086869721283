import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { connect } from 'react-redux';
import QuickActions from '../../allcomponents/quickActions';
import AnswerFooter from '../answerfooter';
import Auth from '../../../Auth';
import { Postfooter, Posttags, ProfileAvatar } from 'components';
import { Description } from './description';
import { IoIosArrowForward } from 'react-icons/io';

const auth = new Auth();

const Post = (props) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => {
    const handleOnScroll = () => {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const isScrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      setScrolledToBottom(isScrolledToBottom);
    };

    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, []);

  useEffect(() => {
    if (scrolledToBottom && !props.loading) {
      props.onLoadMore();
    }
  }, [scrolledToBottom, props]);
  return (
    <div className="rounded-xl space-y-5">
      {props.questions.length > 0 &&
        props.questions
          .filter((question) => question.users !== null)
          .filter((question) => {
            if (props.selecedFilters === 'getSavedQuestions') {
              return question.saveForCurrentUser !== false;
            }
            return true;
          })
          .map((question) => (
            <div key={question.id}>
              <div className="sm:p-4 xs:p-2 important:pb-0 ">
                <div className="flex lg:items-center items-start justify-between">
                  <div className="flex lg:items-center items-start">
                    <ProfileAvatar
                      singleUser={question.users}
                      className="object-cover border shadow-lg w-20 h-20 mr-3 rounded-full"
                      sizeStyle={{ height: 48, width: 48 }}
                      platform={"PL"}
                    />
                    <div className='flex flex-col'>
                      <div className='flex items-center flex-wrap'>
                        <p className='text-base text-new-color'>Topic:</p>
                        {
                          question?.categories?.length === 0 ? <p className='px-1 text-base primary'>General</p> :
                            <div className='lg:divide-x divide-gray-400 gap-1 flex-wrap flex'>
                              {
                                question?.categories?.map((topic) => {
                                  return (
                                    <p className='lg:px-1 text-base flex-shrink-0 primary'>{topic?.name}</p>
                                  )
                                })
                              }
                            </div>
                        }
                      </div>
                      <p className='text-new-color text-base'>{moment(question.created_at).format("LL")}</p>
                    </div>
                  </div>
                  <div className="items-center">
                    <div className="text-right">
                      {auth.isAuthenticated() && (
                        <QuickActions data={question} type="question" />
                      )}
                    </div>
                  </div>
                </div>
                <div className='ml-16 mt-5'>
                <Link to={{
                    pathname: `/discussions/${question.slug}`,
                    state: {
                      filters: props.selecedFilters,
                      topics: props.selecedTopics,
                    },
                  }}>
                    <p className="text-lg pt-2 font-bold text-new-color pb-2">{question?.description}</p>
                  </Link>
                  <Description question={question} props={props} />
                  <div className={'flex justify-start items-center gap-2'}>
                    {question.attachments.length > 0
                      ? question.attachments.map((attachment) => (
                        <img
                          alt="Description"
                          src={attachment.public_image}
                          key={attachment.id}
                          className={'rounded-md object-cover w-20 h-20'}
                        />
                      ))
                      : null}
                  </div>
                  <div className="my-3">
                    {question.tags.length > 0 && <Posttags tags={question.tags} />}
                  </div>
                  <div className='flex pt-3 gap-x-3'>
                    <Link
                      to={`/profile/${question.users.username}`}
                      className="text-lg text-new-color font-bold Poppins"
                    >
                      {`${question.users.firstname} ${question.users.lastname}`}
                    </Link>
                    {question.users.sg_paid_user && (
                      <span className="bg-green-100 text-new-color h-7 flex justify-center items-center text-xs font-semibold mr-2 px-2 rounded">
                        Sponsored
                      </span>
                    )}
                  </div>
                  <div className="my-5">
                    <Postfooter
                      question={question}
                      saveForCurrentUser={question.saveForCurrentUser}
                      type="question"
                      answers={question.answers}
                      likes={question.likes}
                    />
                  </div>
                </div>
                <div className="divider divide-gray-900" />
              </div>
              {/* <Link
                to={{
                  pathname: `/discussions/${question.slug}`,
                  state: {
                    filters: props.selecedFilters,
                    topics: props.selecedTopics,
                  },
                }}
                className="flex items-center justify-center bg-gray-100 sm:py-3 xs:py-2 primary"
              >
                Show all comments
              </Link> */}
              {question.answers.data &&
                question.answers.data.users &&
                question.data.users.firstname &&
                question.data.users.lastname > 0
                ? question.answers.data.map((data) => (
                  <div key={data.id}>
                    <div className="divider " />
                    <div className="p-4 pb-0">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <ProfileAvatar
                            singleUser={data.users}
                            className="object-cover w-16 h-16 mr-3 rounded-full"
                            platform={"PL"}
                          />
                          <div>
                            <p className="text-xl Medium">
                              {data.users.firstname} {data.users.lastname}
                            </p>
                            <p className="Light gray">
                              @{data.users.username}
                            </p>
                          </div>
                        </div>
                        <p className="Light gray fs-13">
                          {moment(data.created_at).format("LL")}{" "}
                        </p>
                      </div>
                      <div className="my-4" />
                      <ShowMoreText
                        lines={2}
                        anchorclassName="primary"
                        more="Show more"
                        less="Show less"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        {data.answer}
                      </ShowMoreText>
                      <div className="my-3 post-footer">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-x-10">
                            <div className="flex items-center gap-x-3">
                              <AnswerFooter
                                question={question}
                                answer={data}
                                comments={data.comments}
                                likes={data.likes}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="divider " />
                  </div>
                ))
                : null}
            </div>
          ))}
          {/* {!props?.loading &&
          <button
            onClick={() => props.onLoadMore()}
                className="flex items-center Poppins hover-text-primary">
                Load more <IoIosArrowForward className="ml-2" />
          </button>} */}

    </div>
  );
};

const mapStateToProps = (state) => ({
  deletedQuestions: state.DiscussionSearchReducer.deletedQuestions,
});

export default connect(mapStateToProps, null)(Post);
