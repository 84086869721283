import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import company from "assets/images/company.png";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class featured extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative blog-posts">
        <div className="container">
          <h4 className="mb-4 text-2xl lg:text-3xl Medium">Featured</h4>
        </div>
        <Slider {...settings} className="important:static">
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
          <div className="p-2 pt-3">
            <div className="flex items-center justify-center w-full mr-10 shadow--sm rounded-3xl h-44">
              <img
                alt="company name"
                src={company}
                className="object-contain w-40 "
              />
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default featured;
