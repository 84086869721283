import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft, BsPlayFill } from "react-icons/bs";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center -mt-6 rounded-full cursor-pointer right-2 top-1/2 slick-arrows next w-9 h-9 lg:bg-gray-200 bg-gray-50"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 flex items-center justify-center -mt-6 rounded-full cursor-pointer left-2 top-1/2 slick-arrows prev w-9 h-9 lg:bg-gray-200 bg-gray-50"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class classpreviewslider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      activeSlide2: 0,
    };
    this.timeout = 0;
  }
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      adaptiveHeight : true,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 624,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };

    const { event } = this.props;
    return (
      <div className="relative blog-posts">
        <Slider {...settings} className="important:static">
          {event.trailers.map((media) =>
            media.extension === "mp4" ||
            media.extension === "mov" ||
            media.extension === "avi" ||
            media.extension === "webm" ? (
              <div
                className="flex flex-col items-center justify-center overflow-hidden lg:mt-0 rounded-xl"
                key={media.id + 1}>
                <video
                  controls
                  loop
                  className="w-full h-full"
                  muted
                  src={media.file_path}
                  />
                  <p className="primary text-lg pt-3 m-0 font-normal Poppins hidden">{media.name}</p>
              </div>
            ) : (
              <div
                className="flex flex-col p-0.5 items-center justify-center relative lg:mt-0 rounded-xl overflow-hidden"
                key={media.id + 2}>
                {media.youtube_link ? (
                  <div className="relative h-full">
                    <a
                      target="_blank"
                      alt="link"
                      rel="noopener noreferrer"
                      href={media.youtube_link}
                      className="flex items-center justify-center h-full">
                      <div className="relative h-full">
                        <img
                          alt="link"
                          src={media.file_path}
                          className="object-contain w-full h-full rounded-xl"
                        />
                        <div className="absolute bottom-0 flex items-center justify-center w-full h-full duration-500 ease-in-out cursor-pointer overlay:bg--gray-200 rounded-xl">
                          <div className="flex items-center justify-center m-2 bg-white rounded-full cursor-pointer h-11 w-11">
                            <BsPlayFill className="-mr-1 black" size={22} />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : (
                  // <a href={media.youtube_link} target="_blank" rel="noopener noreferrer">
                  //   <img alt="Media" src={media.file_path} className="object-contain w-full h-full rounded-xl" />
                  // </a>
                  <img
                    alt="Media"
                    src={media.file_path}
                    className="object-contain w-full h-full rounded-xl"
                  />
                )}
              </div>
            )
          )}
          {(event.trailers || [])?.length === 0 &&               
            <div
              className="flex flex-col items-center justify-center overflow-hidden lg:mt-0 rounded-xl">
              <img
                className="w-full h-full"
                src={event.course_cover_pic}
                />
                <p className="primary text-lg pt-3 m-0 font-normal Poppins hidden">{event.course_name}</p>
            </div>
          }
        </Slider>
        {/* <div className="absolute flex items-center justify-center w-10 h-10 rounded-full top-3 left-4 bg--primary">
          <p className="white">
            {this.state.activeSlide + 1}/{event.trailers.length}
          </p>
        </div> */}
      </div>
    );
  }
}

export default classpreviewslider;
