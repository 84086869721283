import { gql } from "@apollo/client";

export default gql`
  mutation readNotification($id: String!) {
    readNotification(id: $id) {
      id
      read_at
    }
  }
`;
