import {gql} from "@apollo/client";

export default gql`
    mutation updateCategories(
        $action:String
        $categories:[Int!]!
    ) {
        updateCategories(
            action:$action
            categories:$categories
        ) {
            id
            categories{
                id
                name
            }
        }
    }
`;