import IsLoader from "components/allcomponents/IsLoader";
import encryptfunction from "helpers/Encrypt";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { MdOutlineTimer } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";
import JobFormScreen from "navigation/JobFormScreen";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function jobcard({ jobs:allJobs, loader }) {
  const history = useHistory();
  const modalRef = React.useRef();
  const [jobs, setJobs] = React.useState([]);
  const [id, setId] = React.useState(null);

  React.useEffect(() => {
    setJobs(allJobs);
  }, [allJobs])

  const openModal = (data) => {
    setId(data?.id)
    modalRef.current.open(data, setApply);
  }

  const setApply = () => {
    setJobs(jobs?.map((job) => {
      return job?.id !== id ? job: {...job, applied_candidates: [{status: 0}], applied: true}
    }))
  }

  return (
    <>
      <JobFormScreen ref={modalRef} setApply={setApply} />
      <div className="lg:col-span-5 mt-4">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          {(jobs || []).map((item, index) => (
            <Link to={`/job/${encryptfunction(item?.id)}/${item?.slug}`} key={index} className={`rounded-3xl flex flex-col bg-white shadow-[0px_4px_20px_0px_#0000001A] p-4`}>
              <div className="relative w-full max-w-[200px] mx-auto  bg-white py-2.5 shadow-[0px_4px_20px_0px_#0000001A] rounded-full">
                <div className={"flex gap-2 justify-center items-center"}>
                  <p className="primary font-semibold">{item?.employment?.name}</p>
                </div>
              </div>
              <p className="text-center text-base pt-4 text-new-color">{item?.created_at?.slice(0, 10)}</p>
              <p className="text-2xl md:break-normal font-bold text-new-color text-center pt-5">{item?.title}</p>
              <p className="text-center text-base pt-2 text-new-color">{item?.companies?.title || item?.external_companies?.title}</p>
              <p className="text-center text-lg font-bold pt-2 text-[#F5BA45]">{item?.education?.education_level}</p>
              <p className="text-center text-lg font-normal pt-2 text-new-color line-clamp-3" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
              <div className="flex-grow md:flex-row mt-10 flex-col flex justify-center items-center gap-4 px-2">
                {item?.applied_candidates?.[0]?.status === 0 ? 
                  <div 
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(item);
                  }}
                  className="bg--primary h-12 rounded-full mx-auto w-full flex justify-between items-center px-6 text-white hover:text-white text-lg">Pending <MdOutlineTimer size={22} color="white" /></div>
                  :item?.applied_candidates?.[0]?.status === 1 ? 
                  <div 
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(item);
                  }}
                  className="bg--primary h-12 rounded-full mx-auto w-full flex justify-between items-center px-6 text-white hover:text-white text-lg">Accepted <FaEye size={22} color="white" /></div>
                  :item?.applied_candidates?.[0]?.status === 2 ? 
                  <div 
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(item);
                  }}
                  className="bg--primary h-12 rounded-full mx-auto w-full flex justify-between items-center px-6 text-white hover:text-white text-lg">Rejected <FaEye size={22} color="white" /></div>
                  :<div 
                  onClick={(e) => {
                  e.preventDefault();
                  openModal(item);
                  }} 
                  className={`bg--primary h-12 rounded-full mx-auto w-full flex justify-between items-center ${item?.external_link ? "px-5":"p-6"} text-white hover:text-white text-lg`}>Apply Now <BsArrowRight size={22} color="white" /></div>
                }
                <div
                className="bg--primary h-12 rounded-full mx-auto w-full flex justify-between items-center px-6 text-white hover:text-white text-lg">Details <FiExternalLink size={22} color="white" /></div>
              </div>
            </Link>
          ))}
        </div>
        {loader &&
          <div className="flex justify-center items-center">
            <IsLoader className="w-32 h-32 mx-auto" />
          </div>
        }
        {!loader && jobs?.length === 0 &&
          <div className="flex justify-center items-center">
            <h1 className="text-3xl Poppins font-normal text-new-color">No jobs found</h1>
          </div>
        }
      </div>
    </>
  );
}
