import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import DetailBanner from 'assets/images/newsletter/detailbanner.png';
import First from 'assets/images/newsletter/first.jpg';
import Detail from 'assets/images/newsletter/detailimage.png';
import { gql } from '@apollo/client';
import share from "../../assets/images/share.png";
import { Buttonlink, IsLoader, Noconnection, Socialshare } from "components";
import encryptfunction from "../../helpers/Encrypt";
import { IoMdShareAlt } from "react-icons/io";
import convertToSlug from "../../helpers/convertToSlug";

const GET_NEWSLETTER_BY_ID = gql`
    query newsletter_by_id($id: ID!) {
        newsletter_by_id(id: $id) {
            id
            category_id
            title
            slug
            description
            thumbnail
            created_at
            category {
                id
                name
            }
        }
    }
`;

const NewsArticleDetail = (props) => {
    const [shareBtn, setShareBtn] = useState(false);
    const { loading, error, data } = useQuery(GET_NEWSLETTER_BY_ID, {
        variables: {
            id: props.id,
        },
    });

    if (loading) {
        // Handle loading state
        return <IsLoader className="h-48" white={false} />;
    }

    if (error) {
        // Handle error state
        return <Noconnection />;
    }

    const newsletter = data.newsletter_by_id;

    return (
        <div className="container">
            <div className="h-96 w-full">
                <img src={DetailBanner} alt="DetailBanner" className="w-full h-full object-cover" />
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                <div>
                    <h1 className="text-5xl font-bold text-[#A0A3BD]">{newsletter.title}</h1>
                    <p className="font-normal text-sm text-[#A0A3BD]">{newsletter.category.name}</p>
                    <div className="flex items-center cursor-pointer gap-x-2 mt-2">
                        <Buttonlink
                            onClick={() => setShareBtn(!shareBtn)}
                            className={`bg--lightGray hover:bg--lightGray focus:bg--lightGray fs-24 Regular flex items-center justify-center h-9 rounded--full darkGray hover:darkGray hover:opacity-80 border-0 shadow-none
                        ${shareBtn ? "w-28" : "w-28"} `}
                            Text="Share"
                            Lasticon={
                                <IoMdShareAlt className="ml-2 darkGray" size={15} />
                            }
                        />
                        {shareBtn ? (
                            <div>
                                <Socialshare
                                    size={22}
                                    className="w-6 h-6"
                                    subject={newsletter.title}
                                    url={new URL(process.env.REACT_APP_WEB_URL + '/newsletter/' + encryptfunction(newsletter.id) + "/" + newsletter.slug)}
                                    title={newsletter.title}
                                    quote={newsletter.title}
                                    summary={{ __html: newsletter.description }}
                                    source={process.env.REACT_APP_WEB_URL}
                                    via={process.env.REACT_APP_WEB_URL}
                                    hashtags={[convertToSlug(newsletter.category.name)]}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="h-[366px] lg:w-[520px] lg:-mt-32 mt-0">
                    <img src={process.env.REACT_APP_MAIN_URL + "/" + newsletter.thumbnail} alt={newsletter.slug} className="w-full h-full rounded-3xl object-cover" />
                </div>
            </div>
            <div className="mt-6">
                <span dangerouslySetInnerHTML={{ __html: newsletter.description }} />
            </div>
        </div>
    );
};

export default NewsArticleDetail;
