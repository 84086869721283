import { Buttonlink, Postdetail } from "components";
import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { seo } from "../../../helpers/seo";
import { useHistory } from "react-router-dom";

function DiscussionDetail(props) {
  let history = useHistory();
  useEffect(() => {
    seo({
      title: `${props.slug} | Procurement League`,
    });
  }, []);
  const handleClick = () => {
    if (props.location.state?.filters !== undefined || props.location.state?.topics !== undefined) {
      props.history.push({
        pathname: "/discussions",
        state: {
          filters: props.location.state?.filters,
          topics: props.location.state?.topics,
        },
      });
    } else {
      props.history.goBack();
    }
  };

  return (
    <div className="container">
      <div className="xs:px-0 sm:px-4 xl:px-28 lg:px-5">
        <div className="flex items-center justify-between mb-10">
          <Buttonlink
            Text="back"
            Icon={<IoIosArrowBack />}
            onClick={handleClick}
            className="flex items-center justify-center px-4 rounded-full  bg--lightGray Regular h-9 darkGray w-28 hover:darkGray hover:opacity-80"
          />

          <h1 className="text-3xl text-center Medium lg:text-5xl">Detail</h1>
          <div className="w-28" />
        </div>
        <div>
          <Postdetail slug={props.slug} key={props.slug} history={history} />
        </div>
      </div>
    </div>
  );
}

export default DiscussionDetail;
