import { Buttonlink, Videotips, Faqscategory } from "components";
import React, { Component } from "react";
import Newsletter from "assets/images/Newsletter.png";
import { SiWhatsapp } from "react-icons/si";
import { MdOutlineMail } from "react-icons/md";
import { BsArrowDown, BsArrowRight } from "react-icons/bs";
import { ANDROID_APP_URL } from "config/constants";
import questionmark from "assets/images/questionmark.webp";
import { easeInOut } from "helpers/EaseInOut";
import { scrollToContent } from "helpers/ScrollToContent";

class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <div className="bg--lightBlue pb-28 -mt-[50px]">
                    <div className="relative">
                        <div className="flex w-full max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="primary Poppins font-normal">
                                Explore. Source. Connect
                            </p>
                            <p className="black font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Unleash Your Potential With Our Dynamic Buyer
                                Services
                            </p>
                            <div className="flex flex-col">
                                <p className="black Poppins font-normal">
                                    Platform where buyers meet sellers
                                </p>
                                <p className="black Poppins font-normal">
                                    Expand your knowledge, source quality
                                    products and services, and connect with a
                                    supportive community of like-minded
                                    individuals
                                </p>
                            </div>
                            <div className="h-px bg-[#A2A2B6] mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button onClick={()=>scrollToContent('contentSection',1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="justify-center items-center xl:-mt-96 -mt-72 ml-48 lg:flex hidden">
                            <svg
                                width="600"
                                height="600"
                                viewBox="0 0 902 902"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_460_1179)">
                                    <path
                                        d="M654.401 269.855L489.757 345.694L413.918 181.05C394.959 139.889 349.868 123.238 308.707 142.198C267.546 161.157 250.895 206.248 269.855 247.409L345.694 412.053L181.05 487.891C139.889 506.851 123.238 551.942 142.198 593.103C161.157 634.264 206.248 650.914 247.409 631.955L412.053 556.116L487.891 720.76C506.851 761.921 551.942 778.572 593.103 759.612C634.264 740.652 650.915 695.562 631.955 654.401L556.116 489.757L720.76 413.918C761.921 394.959 778.572 349.868 759.612 308.707C740.653 267.546 695.562 250.895 654.401 269.855Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_460_1179">
                                        <rect
                                            width="679.766"
                                            height="679.766"
                                            fill="white"
                                            transform="translate(0 284.395) rotate(-24.7319)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="container">
                        <div>
                            <h1 className="text-center Poppins lg:text-5xl text-4xl font-light text-new-color mb-5">Video Tutorials</h1>
                            <Videotips />
                        </div>
                        <div id="contentSection" className="mt-28">
                            <h1 className="text-center Poppins lg:text-5xl text-4xl font-light text-new-color mb-10 capitalize">FAQs and tips to get started</h1>
                            <Faqscategory />
                        </div>
                    </div>
                </div>
                <div className="mt-28 container -mb-36 ">
                    <div className="knowledge-pocket lg:h-[400px] md:h-[400px] h-[580px] w-full relative">
                        <img
                            src={questionmark}
                            alt="questionmark"
                            className="w-full h-full object-cover"
                        />
                        <div className="absolute w-full h-full inset-0">
                            <div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
                                <div className="lg:col-span-6 md:col-span-6 col-span-12 space-y-4 h-full flex justify-center items-center flex-col">
                                    <p className="white Poppins capitalize lg:text-6xl md:text-4xl text-2xl font-extralight lg:text-left md:text-left text-center">
                                        How can we help?
                                    </p>
                                    <div className="h-px bg-white w-full" />
                                    <div className="lg:block md:block hidden space-y-4">
                                        <p className="white Poppins">
                                            Let's get this conversation started.
                                            We are looking for your queries,
                                            feedback, and everything else you
                                            want to discuss.
                                        </p>
                                        <div className="flex gap-3 items-center">
                                            <Buttonlink
                                                href={
                                                    "https://wa.me/919901166886?text=Hi%2C%0AI%20need%20some%20information"
                                                }
                                                Text={"Whats App"}
                                                Lasticon={
                                                    <BsArrowRight size={23} />
                                                }
                                                className="py-1.5 w-52 whitespace-nowrap text-xl font-bold border-2 self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center"
                                            />
                                            <Buttonlink
                                                href={
                                                    "mailto:contactus@procurementleague.com"
                                                }
                                                Text={"Email"}
                                                Lasticon={
                                                    <BsArrowRight size={23} />
                                                }
                                                className="py-1.5 text-xl border-2 font-bold self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-44"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-span-6 md:col-span-6 col-span-12">
                                    <div className="space-y-4 lg:hidden md:hidden block">
                                        <p className="white Poppins">
                                            Let's get this conversation started.
                                            We are looking for your queries,
                                            feedback, and everything else you
                                            want to discuss.
                                        </p>
                                        <Buttonlink
                                            href={
                                                "https://wa.me/919901166886?text=Hi%2C%0AI%20need%20some%20information"
                                            }
                                            Text={"Whats App"}
                                            Lasticon={
                                                <BsArrowRight size={23} />
                                            }
                                            className="py-1.5 w-52 whitespace-nowrap text-xl border-2 self-start Lato font-bold !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center"
                                        />
                                        <Buttonlink
                                            href={
                                                "mailto:contactus@procurementleague.com"
                                            }
                                            Text={"Email"}
                                            Lasticon={
                                                <BsArrowRight size={23} />
                                            }
                                            className="py-1.5 text-xl border-2 self-start Lato font-bold !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-44"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Faqs;
