import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from "react";
import { FiX } from "react-icons/fi";
import { Dialog, Transition } from "@headlessui/react";

const TeamsPopup = forwardRef((props, ref) => {
    const [data, setData] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open(data) {
            setData(data);
            setIsOpen(true);
        },
        close() {
            closePopup();
        },
    }));

    function closePopup() {
        setIsOpen(false);
        setTimeout(() => {
            setData({});
        }, 200);
    }

    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={closePopup}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-7xl md:px-10 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]">
                                <div
                                    onClick={closePopup}
                                    className="absolute z-10 flex items-center justify-center rounded-full cursor-pointer right-4 top-4"
                                >
                                    <FiX className="primary" size={25} />
                                </div>
                                <div className="absolute left-8 top-14 hidden lg:block">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="200"
                                        height="200"
                                        viewBox="0 0 312 312"
                                        fill="none"
                                    >
                                        <path
                                            d="M130.001 33.6078L122.814 116.526L39.8966 109.338C19.1672 107.542 2.27253 121.741 0.475766 142.471C-1.321 163.2 12.8785 180.095 33.6079 181.891L116.526 189.079L109.339 271.996C107.542 292.726 121.741 309.62 142.471 311.417C163.2 313.214 180.095 299.014 181.892 278.285L189.079 195.367L271.996 202.554C292.726 204.351 309.621 190.152 311.417 169.422C313.214 148.693 299.015 131.798 278.285 130.001L195.367 122.814L202.554 39.8965C204.351 19.167 190.152 2.27237 169.422 0.475604C148.693 -1.32116 131.798 12.8783 130.001 33.6078Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                </div>
                                <div className="space-y-2 md:space-y-4 lg:space-y-8 px-12 sm:px-16 md:px-20 lg:px-28 py-7 sm:py-8 md:py-10 lg:py-16">
                                    <div className="flex lg:flex-row flex-col gap-x-10 gap-y-5 justify-center items-start lg:items-center">
                                        <img
                                            src={data?.image}
                                            alt={data?.name}
                                            className="w-[150px] sm:w-[200px] md:w-[250px] lg:w-[300px]"
                                        />
                                        <div className="flex flex-col gap-5">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="173"
                                                height="129"
                                                viewBox="0 0 173 129"
                                                className="hidden lg:block"
                                                fill="none"
                                            >
                                                <path
                                                    d="M22.9565 65.7849C28.7874 58.1364 38.0159 54.7268 48.5759 54.7268C68.0889 54.7268 82.1842 67.9504 82.1842 90.2508C82.1842 111.215 69.0072 129 41.3217 129C15.8859 129 0 110.754 0 81.1279C0 41.5033 23.737 10.4025 66.6656 0.12776C69.1908 -0.471217 71.9915 1.09534 72.8639 3.5834L73.7362 6.07146C74.6085 8.55951 73.2771 11.0015 70.7519 11.7387C42.4236 19.7097 25.2521 37.034 20.9822 65.0937C20.6149 67.674 21.3495 67.9043 22.9565 65.7849ZM113.772 65.7849C119.603 58.1364 128.832 54.7268 139.392 54.7268C158.905 54.7268 173 67.9504 173 90.2508C173 111.215 159.823 129 132.137 129C106.702 129 90.8158 110.754 90.8158 81.1279C90.8158 41.5033 114.553 10.4025 157.527 0.12776C160.053 -0.471217 162.853 1.09534 163.726 3.5834L164.598 6.07146C165.47 8.55951 164.139 11.0015 161.614 11.7387C133.285 19.7097 116.114 37.034 111.844 65.0937C111.431 67.674 112.211 67.9043 113.772 65.7849Z"
                                                    fill="#EAF2F9"
                                                />
                                            </svg>
                                            <h1 className="lg:text-3xl md:text-2xl text-xl Poppins font-light text-new-color">
                                                {data?.quote}
                                            </h1>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="primary text-3xl font-light Poppins">
                                            {data?.name}
                                        </h4>
                                        <h4 className="Poppins text-3xl font-light text-new-color">
                                            {data?.designation}
                                        </h4>
                                        <p className="py-5 Poppins font-normal text-lg text-new-color">
                                            {data?.description}
                                        </p>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
});

export default TeamsPopup;
