import {
  Publicdiscussion,
  Detailhead,
  Detailabout,
  Buttonlink,
  Noconnection,
  Productdetail,
} from "components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { seo } from "../../../helpers/seo";
import GET_CLASS_BY_ID from "../../../graphql/queries/getCourse";
import { Query } from "@apollo/client/react/components";
import TrainingModuleFooter from "components/allcomponents/trainingModule";

class ClassDetail extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: `${
        this.props._class
          ? this.props._class.course_slug
          : this.props.course_slug
      } | Procurement League`,
    });
  }

  render() {
    let { _class, type, authUser, class_id } = this.props;
    let variables = {};
    if (_class && _class.id) {
      variables.id = _class.id;
    } else {
      variables.id = class_id;
    }
    variables.cursor = 1;
    // console.log(_class.id,class_id,type)
    return (
      <div className="container">
        <Buttonlink
          Text="<< Back"
          to="/classes"
          className="flex primary items-center uppercase justify-start px-4 mb-3 rounded-full text-lg h-9"
        />
        <Query query={GET_CLASS_BY_ID} variables={variables}>
          {({ loading, error, data, fetchMore, refetch }) => {
            if (loading) return <Productdetail />;

            if (error) {
              return (
                <div>
                  <Noconnection />
                </div>
              );
            }
            if (this.props.course_recall) {
              refetch();
              setTimeout(() => {
                this.props.handleClassReset(false);
              }, 10);
            }
            if (data) {
              this.props.handleClassDetails(data);
            }
            return (
              <div className="grid lg:gap-20 gap-5 mt-5 lg:grid-cols-2 relative">
                <div className="absolute right-1/2 top-0 lg:block hidden z-[-1]">
                  <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="62" cy="62" r="62" fill="#B5E2FF"/>
                  </svg>
                </div>
                <div className="absolute right-20 bottom-0 lg:block hidden z-[-1]">
                  <svg width="266" height="265" viewBox="0 0 266 265" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2758_5298)">
                    <path d="M192.887 79.2798L144.517 101.56L122.237 53.19C116.666 41.0975 103.419 36.2056 91.3269 41.7757C79.2343 47.3458 74.3425 60.5928 79.9126 72.6854L102.193 121.056L53.8229 143.336C41.7303 148.906 36.8385 162.153 42.4086 174.246C47.9787 186.338 61.2257 191.23 73.3182 185.66L121.688 163.379L143.969 211.75C149.539 223.842 162.786 228.734 174.878 223.164C186.971 217.594 191.863 204.347 186.293 192.254L164.012 143.884L212.382 121.604C224.475 116.034 229.367 102.787 223.797 90.694C218.227 78.6015 204.98 73.7097 192.887 79.2798Z" fill="#FBE4D3" fill-opacity="0.7"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2758_5298">
                    <rect width="199.706" height="199.706" fill="white" transform="translate(0.632812 83.5515) rotate(-24.7319)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <Detailabout
                    event={data.course}
                    authUser={authUser}
                    type={type}
                  />
                </div>
                {/* <div>
                  {authUser && data.course && type ? (
                    <Publicdiscussion
                        course={data.course}
                      type={type}
                      authUser={authUser}
                    />
                  ) : null}
                </div> */}
                {authUser && data.course && type ? (
                  <Detailhead type={type} />
                ) : null}
              </div>
            );
          }}
        </Query>
        <TrainingModuleFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
  course_recall: state.CourseReducer.course_recall,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleClassDetails: (data) =>
      dispatch({ type: "GET_SINGLE_CLASS_INFORMATION", payload: data }),
    handleClassReset: (data) =>
      dispatch({ type: "COURSE_RECALL", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetail);
