import * as React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import Auth from "../../../Auth";
import Signinpopup from "components/allcomponents/signinpopup";
import freeBadge from '../../../assets/images/freeBadge.png';
import updateLike from "../../../graphql/mutations/updateKnowledgeBankLike";
import heart from "assets/images/grayHeart.png";
import signUpBadge from 'assets/images/signUpBadge.png';
import colorheart from "assets/images/heartColor.png";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import convertToSlug from "helpers/convertToSlug";

function SampleNextArrow(props) {
    const { onClick } = props;

    return (
        <div
            className="absolute Poppins right-0 top-[45%] z-10 flex items-center justify-center cursor-pointer slick-arrows next"
            onClick={onClick}
        >
            <BsArrowRight
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute Poppins z-10 top-[45%] left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
            onClick={onClick}
        >
            <BsArrowLeft
                size={30}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

export default function EducationalMedia({ data }) {
    const auth = new Auth();
    const isLogin = auth?.isAuthenticated();
    const [openModal, setOpenModal] = React.useState(false);
    const [likeKB] = useMutation(updateLike);
    const history = useHistory();

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleLike = (id, educational) => {
        if (!isLogin) {
            setOpenModal(true);
        } else {
            likeKB({
                variables: { id: Number(id) },
                optimisticResponse: {
                    updateKnowledgeBankVote:{
                        id: educational?.id,
                        is_paid: educational?.is_paid,
                        additional_votes: educational?.check_for_vote ? educational?.additional_votes - 1:educational?.additional_votes + 1,
                        check_for_vote: !educational?.check_for_vote,
                        __typename: "KnowledgeBank"
                    }
                }
            })
        }
    }

    const handleImageClick = (url, is_paid) => {
        if (is_paid && !isLogin) {
            setOpenModal(true);
        } else {
            window.open(url, '_blank');
        }
    }

    return (
        <div className="relative">
            <h2 className="mb-10 text-new-color lg:text-5xl font-light text-4xl text-center Poppins">
                {data?.category_name}
            </h2>
            <Slider {...settings} className="px-10">
                {data?.knowledge_banks?.map((educational, i) => {
                    const t = educational?.tags === '' || !educational?.tags ? [] : JSON.parse(educational.tags);
                return(
                    <div className="h-full p-5" key={educational.id}>
                        <div className="block bg-white p-5 h-full transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl">
                            <div className="relative">
                                <img
                                    onClick={() => handleImageClick(educational?.file_path?.path, educational?.is_paid)}
                                    alt={data?.category_name}
                                    src={educational.image?.path}
                                    className="object-fill cursor-pointer max-h-[170px] w-full mx-auto mb-3 rounded-t-xl"
                                />
                                {!educational?.is_paid || isLogin ? 
                                <img
                                    alt={'free'}
                                    src={freeBadge}
                                    className="absolute top-2 right-2 max-w-[68px]"
                                />: 
                                <img
                                    alt={'paid'}
                                    src={signUpBadge}
                                    className="absolute top-2 right-2 max-w-[68px]"
                                />}
                            </div>
                            <div className="flex flex-wrap gap-2 mb-3">
                            {t?.map((tag, index) => (
                                <div
                                    key={index}
                                    className="px-3 py-1 bg-gray-200 rounded-full"
                                >
                                    <p className="fs-11 Poppins">{tag}</p>
                                </div>
                            ))}
                        </div>
                            {educational?.is_paid && !isLogin ?
                                <button onClick={() => setOpenModal(true)}><p className="text-lg text-left primary Poppins">{educational.name}</p></button>:
                                <a href={educational.file_path?.path} target={educational.file_path?.path && "_blank"}>
                                {
                                    <p className="text-lg primary text-left Poppins">{educational.name}</p>
                                }
                            </a>}
                            <button 
                            onClick={() => handleLike(educational?.id, educational)}
                            disabled={false}
                            className="flex justify-start items-center gap-x-2 mt-3">
                                {!educational?.check_for_vote ? 
                                <img
                                    alt={'likes'}
                                    src={heart}
                                    className="w-[20px]"
                                />:<img
                                    alt={'likes'}
                                    src={colorheart}
                                    className="w-[20px]"
                                />}
                                <span className={`text-[18px] leading-6 font-normal ${educational?.check_for_vote ? 'primary': 'text-[#3B455A]'}`}>{Number(educational?.additional_votes || 0)}</span>
                            </button>
                        </div>
                    </div>
                )})}
            </Slider>
            {(data?.knowledge_banks || [])?.length > 3 && 
            <div className="flex justify-end w-full primary">
                <button onClick={() => {isLogin ? history.push(`/knowledge-bank/${convertToSlug(data?.category_name)}`):setOpenModal(true)}} className="mr-16">See All {">>"}</button>
            </div>}
            {openModal && (
                <Signinpopup
                    open={openModal}
                    closePopup={() => setOpenModal(false)}
                    protected={false}
                />
            )}
        </div>
    );
}
