import React from "react";
import {Redirect, Route} from "react-router-dom";
import Auth from "./Auth";
const auth = new Auth();

const LoggedInUser = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                auth.isAuthenticated() ?
                <>
                    <Component {...props} />
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                </>:<Component {...props} />

            }
        />
    );
};

export default LoggedInUser;
