import * as React from "react";
import {Link} from "react-router-dom";
import best from "assets/images/newsletter/best.png";
import digital from "assets/images/newsletter/digital.png";
import event from "assets/images/newsletter/event.png";
import happy from "assets/images/newsletter/happy.png";
import conference from "assets/images/newsletter/conference.png";
import training from "assets/images/newsletter/training.png";

export default function reasonsubscribe() {
  return (
    <div className="relative">
      <h2 className="lg:text-[50px] text-[30px] font-[300] text-center Poppins">
        6 Reasons You Should Subscribe
      </h2>
      <div className="grid mt-24 place-content-center place-items-center grid-cols-12 gap-7">
        <div className="col-span-4 lg:block hidden" />
        <Link
          to="/events-update"
          className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 col-span-12 rounded-3xl text-center flex flex-col items-center w-[367px] h-[162px]"
        >
          <img className="w-[64px] h-[64px] mt-7" src={event} alt="event" />
          <div className="font-bold Poppins text-[18px] mt-4">
            Event Updates
          </div>
        </Link>
        <div className="col-span-4 lg:block hidden" />
        <Link
          to="/happy-subscribers"
          className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 col-span-12 rounded-3xl text-center flex flex-col items-center w-[367px] h-[162px]">
          <img className="w-[64px] h-[64px] mt-7" src={happy} alt="event" />
          <div className="font-bold Poppins text-[18px] mt-4">
            Happy Subscribers
          </div>
        </Link>
        <div className="col-span-4 lg:block hidden" />
        <Link
          to="/subscribers-training"
          className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 col-span-12 rounded-3xl text-center flex flex-col items-center w-[367px] h-[162px]">
          <img className="w-[64px] h-[64px] mt-7" src={training} alt="event" />
          <div className="font-bold Poppins text-[18px] mt-4">Training</div>
        </Link>
        <div className="col-span-4 lg:block hidden" />
        <Link
          to="/digital-procurement"
          className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 col-span-12 rounded-3xl text-center flex flex-col items-center w-[367px] h-[162px]">
          <img className="w-[64px] h-[64px] mt-7" src={digital} alt="event" />
          <div className="font-bold Poppins text-[18px] mt-4">
            Digital Procurement
          </div>
        </Link>
        <div className="col-span-4 lg:block hidden" />
        <Link
          to="/conference-passes"
          className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 col-span-12 rounded-3xl text-center flex flex-col items-center w-[367px] h-[162px]">
          <img
            className="w-[64px] h-[64px] mt-7"
            src={conference}
            alt="event"
          />
          <div className="font-bold Poppins text-[18px] mt-4 w-[200px]">
            Conference Passes At Our Special Price
          </div>
        </Link>
        <div className="col-span-4 lg:block hidden" />
        <Link
          to="/procurement-content"
          className="bg-white relative shadow-[0px_4px_40px_0px_#0000001A] lg:col-span-4 col-span-12 rounded-3xl text-center flex flex-col items-center w-[367px] h-[162px]">
          <img className="w-[64px] h-[64px] mt-7" src={best} alt="event" />
          <div className="font-bold Poppins text-[18px] mt-4 w-[200px]">
            Best Procurement Content
          </div>
        </Link>
        <div className="col-span-4 lg:block hidden" />
      </div>
      {/* <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2">
        <Link
          to="/events-update"
          className="shadow--sm rounded-xl sm:p-10 xs:p-4">
          <div className="h-44">
            <img
              alt="Events Update"
              src={Consulting}
              className="object-cover h-full m-auto"
            />
          </div>
          <h4 className="mt-4 mb-3 text-2xl text-center Medium">
            Events Update
          </h4>
          <p className="text-justify Regular text-last-center">
            You’ll find out about our regular events. But they’re not events
            you’ll be sold at. You will learn and feel like you’re part of
            something.
          </p>
        </Link>
        <Link
          to="/subscribers-training"
          className="shadow--sm rounded-xl sm:p-10 xs:p-4">
          <div className="h-44">
            <img
              alt="Training"
              src={Training}
              className="object-cover h-full m-auto"
            />
          </div>
          <h4 className="mt-4 mb-3 text-2xl text-center Medium">Training</h4>
          <p className="text-justify Regular text-last-center">
            We do our best to entertain, as much as inform. You’ll hopefully
            look forward to opening our emails.
          </p>
        </Link>
        <Link
          to="/digital-procurement"
          className="block shadow--sm rounded-xl sm:p-10 xs:p-4">
          <div className="h-44">
            <img
              alt="Digital Procurement"
              src={Outsourcing}
              className="object-cover h-full m-auto"
            />
          </div>
          <h4 className="mt-4 mb-3 text-2xl text-center Medium">
            Digital Procurement
          </h4>
          <p className="text-justify Regular text-last-center">
            We are promising you the World of Technology, Automation and AI will
            make it to your inboxes.
          </p>
        </Link>
        <Link
          to="/happy-subscribers"
          className="block shadow--sm rounded-xl sm:p-10 xs:p-4">
          <div className="h-44">
            <img
              alt="Happy Subscribers"
              src={Research}
              className="object-cover h-full m-auto"
            />
          </div>
          <h4 className="mt-4 mb-3 text-2xl text-center Medium">
            Happy Subscribers
          </h4>
          <p className="text-justify Regular text-last-center">
            10,000+ professionals can’t be wrong… can they?
          </p>
        </Link>
        <Link
          to="/procurement-content"
          className="block shadow--sm rounded-xl sm:p-10 xs:p-4">
          <div className="h-44">
            <img
              alt="Best Procurement Content"
              src={League}
              className="object-cover h-full m-auto"
            />
          </div>
          <h4 className="mt-4 mb-3 text-2xl text-center Medium">
            Best Procurement Content
          </h4>
          <p className="text-justify Regular text-last-center">
            You will be pointed to the best procurement content we can find from
            across the web which means you don’t have to search for it.
          </p>
        </Link>{" "}
        <Link
          to="/conference-passes"
          className="block shadow--sm rounded-xl sm:p-10 xs:p-4">
          <div className="h-44">
            <img
              alt="Conference Passes"
              src={Media}
              className="object-cover h-full m-auto"
            />
          </div>
          <h4 className="mt-4 mb-3 text-2xl text-center Medium">
            Conference Passes At Our Special Price
          </h4>
          <p className="text-justify Regular text-last-center">
            We will send you the special discount codes for the conferences
            happening in Procurement World.
          </p>
        </Link>
      </div> */}
    </div>
  );
}
