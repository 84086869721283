import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import Subtract from "assets/images/Subtract-white.png";
import { EventSubscribers } from "components";
import { IoMdCheckmark } from "react-icons/io";
import vector from "assets/images/GrowthSolutions/vector.png";
import banner from "assets/images/GrowthSolutions/Growth-Solutions.webp";
import { useHistory } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";

const GrowthSolutionsPage = () => {

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const Navigation = (advisory, community) => {
        history.push({
            pathname: "/B2B",
            state: { advisory: advisory, community: community }
        });
    }

    return (
        <>
            <div className="-mt-[50px]">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden relative">
                        <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] GrowthSolutionsBanner relative">
                        <div className="flex w-full max-w-3xl z-10 relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Growth Solutions
                            </p>
                            <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Unlock Your Growth Potential With Powerful
                                Solutions
                            </p>
                            <p className="white Poppins font-normal">
                                Don't just connect with buyers; partner with a team that understands your industry intricately and is dedicated to being your trusted partner in strategy.
                            </p>
                            <div className="h-px bg-white mt-3" />
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button
                                    onClick={() => scrollToContent('contentSection', 1000)}
                                    className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                                >
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={Subtract}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>

                <div
                    className="container space-y-28 mt-28 relative"
                    id="contentSection"
                >
                    <div className="space-y-10 relative">
                        <div class="absolute lg:block hidden right-0 top-0">
                            <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF"></circle></svg>
                        </div>
                        <div className="flex flex-col w-full max-w-7xl mx-auto justify-center items-center relative">
                            <div class="absolute -left-48 -bottom-48 lg:block hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" width="214" height="214" viewBox="0 0 214 214" fill="none"><g clipPath="url(#clip0_553_2505)"><path d="M90.1865 29.3941L85.6539 81.6878L33.3602 77.1551C20.2868 76.0219 9.63191 84.9771 8.49874 98.0505C7.36558 111.124 16.3208 121.779 29.3942 122.912L81.6878 127.445L77.1551 179.738C76.022 192.812 84.9771 203.467 98.0506 204.6C111.124 205.733 121.779 196.778 122.912 183.704L127.445 131.411L179.738 135.943C192.812 137.077 203.467 128.121 204.6 115.048C205.733 101.975 196.778 91.3197 183.704 90.1865L131.411 85.6538L135.943 33.3602C137.077 20.2868 128.121 9.63187 115.048 8.49871C101.975 7.36554 91.3197 16.3207 90.1865 29.3941Z" fill="#B5E2FF" fillOpacity="0.4"></path></g><defs><clipPath id="clip0_553_2505"><rect width="196.836" height="196.836" fill="white" transform="translate(0 196.101) rotate(-85.0461)"></rect></clipPath></defs></svg>
                            </div>
                            <h1 className="Poppins text-center capitalize font-semibold text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                                Are you a Service Provider seeking a partner who truly understands your industry and can help you connect with the right buyers?
                            </h1>
                        </div>
                        <div className="space-y-10 w-full max-w-5xl mx-auto">
                            <p className="text-3xl Poppins text-center font-light primary">
                                What are your core challenges?
                            </p>
                            <div className="grid grid-cols-12 gap-5">
                                <div className="bg-white custom-shadow lg:col-span-4 md:col-span-6 col-span-12 w-full flex flex-col justify-center items-center gap-3 p-8 rounded-3xl text-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="29"
                                        viewBox="0 0 38 29"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                    <p className="font-bold Poppins text-lg text-new-color">
                                        Managing Long Sales Cycles
                                    </p>
                                </div>
                                <div className="bg-white custom-shadow lg:col-span-4 md:col-span-6 col-span-12 w-full flex flex-col justify-center items-center gap-3 p-8 rounded-3xl text-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="29"
                                        viewBox="0 0 38 29"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                    <p className="font-bold Poppins text-lg text-new-color">
                                        Building Trust and Credibility
                                    </p>
                                </div>
                                <div className="bg-white custom-shadow lg:col-span-4 md:col-span-6 col-span-12 w-full flex flex-col justify-center items-center gap-3 p-8 rounded-3xl text-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="29"
                                        viewBox="0 0 38 29"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                    <p className="font-bold Poppins text-lg text-new-color">
                                        Differentiating from Competitors
                                    </p>
                                </div>
                                <div className="bg-white custom-shadow lg:col-span-4 md:col-span-6 col-span-12 w-full flex flex-col justify-center items-center gap-3 p-8 rounded-3xl text-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="29"
                                        viewBox="0 0 38 29"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                    <p className="font-bold Poppins text-lg text-new-color">
                                        Overcoming Budget Constraint
                                    </p>
                                </div>
                                <div className="bg-white custom-shadow lg:col-span-4 md:col-span-6 col-span-12 w-full flex flex-col justify-center items-center gap-3 p-8 rounded-3xl text-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="29"
                                        viewBox="0 0 38 29"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                    <p className="font-bold Poppins text-lg text-new-color">
                                        Managing Client Expectations
                                    </p>
                                </div>
                                <div className="bg-white custom-shadow lg:col-span-4 md:col-span-6 col-span-12 w-full flex flex-col justify-center items-center gap-3 p-8 rounded-3xl text-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="38"
                                        height="29"
                                        viewBox="0 0 38 29"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                            fill="#B5E2FF"
                                        />
                                    </svg>
                                    <p className="font-bold Poppins text-lg text-new-color">
                                        Expanding into New Markets
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className="Poppins text-center capitalize font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                            Unlock your potential with us:
                        </h1>
                        <h1 className="Poppins text-center capitalize font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                            Real Solutions, Real Results
                        </h1>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-5 mt-28">
                            <div className="flex justify-between h-full flex-col">
                                <div>
                                    <p className="font-light text-3xl Poppins primary">
                                        Empower Your Brand with Captivating
                                        Content
                                    </p>
                                    <p className="font-normal text-lg Poppins text-new-color pt-5">
                                        We dive deep into what makes you unique. Your brand becomes a guide that educates and magnetizes your audience.
                                    </p>
                                </div>
                                <button
                                    onClick={() => Navigation(false, false)}
                                    className={
                                        "mt-5 bg--primary font-normal Poppins hover:text-white text-white flex items-center justify-between px-4 !text-lg hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 lg:w-48 md:w-80 w-full"
                                    }
                                >Discover <BsArrowDown color="white" size={22} /></button>
                            </div>
                            <div className="flex justify-between h-full flex-col">
                                <div>
                                    <p className="font-light text-3xl Poppins primary">
                                        Guidance Beyond Ordinary: Smart Advice
                                    </p>
                                    <p className="font-normal text-lg Poppins text-new-color pt-5">
                                        We're here to bridge gaps, solve problems, and drive growth. Unleash your business potential with our hands-on advice. Say goodbye to the 'marketing gap' with us.
                                    </p>
                                </div>
                                <button
                                    onClick={() => Navigation(true, false)}
                                    className={
                                        "mt-5 bg--primary font-normal Poppins hover:text-white text-white flex items-center justify-between px-4 !text-lg hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 lg:w-48 md:w-80 w-full"
                                    }
                                >Discover <BsArrowDown color="white" size={22} /></button>
                            </div>
                            <div className="flex justify-between h-full flex-col">
                                <div>
                                    <p className="font-light text-3xl Poppins primary">
                                        Building Thriving Communities
                                    </p>
                                    <p className="font-normal text-lg Poppins text-new-color pt-5">
                                        Step into the future of marketing – where community rules. Our proprietary technology, primed for white labeling, empowers you to forge robust client and prospective communities.
                                    </p>
                                </div>
                                <button
                                    onClick={() => Navigation(false, true)}
                                    className={
                                        "mt-5 bg--primary font-normal Poppins hover:text-white text-white flex items-center justify-between px-4 !text-lg hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 lg:w-48 md:w-80 w-full"
                                    }
                                >Discover <BsArrowDown color="white" size={22} /></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className="Poppins text-center capitalize font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                            How We Help You Sell Better
                        </h1>
                        <div className="bg-white relative rounded-2xl p-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
                            <div className="divide-y">
                                <div className="flex p-3 items-center gap-4 w-full">
                                    <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                        <IoMdCheckmark
                                            color="#B5E2FF"
                                            size={24}
                                        />
                                    </div>
                                    <p className="text-lg Poppins font-normal text-new-color">
                                        <span className="text-lg font-bold">Connect with the Right Buyers:</span> We'll
                                        help introduce you to Pharma and biotech
                                        buyers interested in your offers.
                                    </p>
                                </div>
                                <div className="flex p-3 items-center gap-4 w-full">
                                    <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                        <IoMdCheckmark
                                            color="#B5E2FF"
                                            size={24}
                                        />
                                    </div>
                                    <p className="text-lg Poppins font-normal text-new-color">
                                        <span className="text-lg font-bold">Tailored Guidance:</span> Our team will provide personalized guidance on approaching each buyer effectively, increasing your chances of success.
                                    </p>
                                </div>
                                <div className="flex p-3 items-center gap-4 w-full">
                                    <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                        <IoMdCheckmark
                                            color="#B5E2FF"
                                            size={24}
                                        />
                                    </div>
                                    <p className="text-lg Poppins font-normal text-new-color">
                                        <span className="text-lg font-bold">Data-Driven Insights:</span> Leverage our wealth of data to make informed decisions and optimize your sales strategies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="absolute top-20 right-0 z-[-1] lg:block hidden">
                            <img
                                src={vector}
                                alt="vector"
                                className="w-[400px] h-[250px]"
                            />
                        </div>
                        <div className="absolute bottom-0 left-0 lg:block hidden">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                                fill="none"
                            >
                                <circle cx="40" cy="40" r="40" fill="#B5E2FF" />
                            </svg>
                        </div>
                        <h1 className="Poppins text-center capitalize font-light text-new-color lg:text-[48px] lg:leading-[60px] text-[36px]">
                            Why Choose Procurement League?
                        </h1>
                        <div className="grid lg:mt-44 mt-28 place-content-center place-items-center grid-cols-12 gap-5">
                            <div className="col-span-4 lg:block hidden" />
                            <div className="bg-white custom-shadow w-full max-w-sm lg:col-span-4 flex flex-col justify-center items-center gap-3 md:col-span-6 sm:col-span-6 col-span-12 p-8 rounded-3xl text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="38"
                                    height="29"
                                    viewBox="0 0 38 29"
                                    fill="none"
                                >
                                    <path
                                        d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                        fill="#B5E2FF"
                                    />
                                </svg>
                                <p className="font-bold Poppins text-lg text-new-color">
                                    Curated Lists of Biotech Innovators
                                </p>
                            </div>
                            <div className="col-span-4 lg:block hidden" />
                            <div className="bg-white custom-shadow w-full max-w-sm lg:col-span-4 flex flex-col justify-center items-center gap-3 md:col-span-6 sm:col-span-6 col-span-12 p-8 rounded-3xl text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="38"
                                    height="29"
                                    viewBox="0 0 38 29"
                                    fill="none"
                                >
                                    <path
                                        d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                        fill="#B5E2FF"
                                    />
                                </svg>
                                <p className="font-bold Poppins text-lg text-new-color">
                                    Vast Network of Pharma Buyers and Senior
                                    Executives
                                </p>
                            </div>
                            <div className="col-span-4 lg:block hidden" />
                            <div className="bg-white custom-shadow w-full max-w-sm lg:col-span-4 flex flex-col justify-center items-center gap-3 md:col-span-6 sm:col-span-6 col-span-12 p-8 rounded-3xl text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="38"
                                    height="29"
                                    viewBox="0 0 38 29"
                                    fill="none"
                                >
                                    <path
                                        d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                        fill="#B5E2FF"
                                    />
                                </svg>
                                <p className="font-bold Poppins text-lg text-new-color">
                                    Extensive Spending Power Management
                                </p>
                            </div>
                            <div className="col-span-4 lg:block hidden" />
                            <div className="bg-white custom-shadow w-full max-w-sm lg:col-span-4 flex flex-col justify-center items-center gap-3 md:col-span-6 sm:col-span-6 col-span-12 p-8 rounded-3xl text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="38"
                                    height="29"
                                    viewBox="0 0 38 29"
                                    fill="none"
                                >
                                    <path
                                        d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
                                        fill="#B5E2FF"
                                    />
                                </svg>
                                <p className="font-bold Poppins text-lg text-new-color">
                                    Competitive Intelligence
                                </p>
                            </div>
                            <div className="col-span-4 lg:block hidden" />
                        </div>
                    </div>
                </div>
                <div className="h-screen mt-28 event_testinomails z-50 relative lg:-mb-[240px]">
                    <EventSubscribers isImageHide={true} />
                </div>
            </div>
        </>
    );
};

export default GrowthSolutionsPage;
