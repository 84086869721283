import { gql } from "@apollo/client";

export default gql`
    mutation DeleteNotification(
        $id:String
    ) {
        deleteNotification(
            id:$id
        ) {
            id
        }
    }
`;

