import React from "react";
import { Loader } from "semantic-ui-react";
import "../../../assets/css/semantic.css";

export default function index() {
  return (
    <Loader active inline="centered" className="!z-50" size="large">
      Loading...
    </Loader>
  );
}
