import { gql } from "@apollo/client";

export default gql`
  mutation module_watched($id: Int, $content_id: Int, $course_id: Int) {
    module_watched(module_id:$id, chapter_id:$content_id, course_id:$course_id, ){
        message,
        error
    }
  }
`;
