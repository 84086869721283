import { gql } from "@apollo/client";

export default gql`
  query QuestionWithSlug($slug: String!) {
    questionWithSlug(slug: $slug) {
      id
      question
      description
      created_at
      voteStatus
      saveForCurrentUser
      status
      slug
      meta_text
      tags {
        id
        tag_title
      }
      categories {
        id
        name
      }
      invites {
        id
        email
      }
      attachments {
        id
        url
        type
        width
        height
        public_image
      }
      users {
        id
        firstname
        lastname
        profile_photo
        color
        is_follower
        username
      }
      likes(first: 1) {
        data {
          users {
            id
          }
        }
        paginatorInfo {
          total
        }
      }
      dislikes(first: 1) {
        data {
          users {
            id
          }
        }
        paginatorInfo {
          total
        }
      }
      answers(first: 20, orderBy: [{ column: "created_at", order: DESC }]) {
        data {
          id
          answer
          voteStatus
          created_at
          users {
            id
            username
            firstname
            lastname
            profile_photo
            is_pro
          }
          likes(first: 1) {
            data {
              users {
                id
              }
            }
            paginatorInfo {
              total
            }
          }
          comments(first: 10) {
            data {
              id
              comment
              users {
                id
                firstname
                lastname
                username
                profile_photo
              }
            }
            paginatorInfo {
              total
            }
          }
        }
        paginatorInfo {
          total
          currentPage
        }
      }
    }
  }
`;
