import {gql} from "@apollo/client";

export default gql`
    query sgUserProducts(
        $user_id: ID!
        $name:String
        $stock:Boolean
        $categories:[Int]
        $cursor:Int
    ) {
        sgUserProducts(
            user_id: $user_id
            name:$name
            stock:$stock
            categories:$categories
            page: $cursor,
            first:8
            orderBy: [{ column: "updated_at", order: DESC }]
        ) {
            data{
                id
                name
                slug
                description
                is_active
                in_stock
                attachment
                type
                product_like_count
                categories{
                    id
                    name
                }
                product_actions{
                    id
                    liked
                    user_id
                    book_mark
                    product_like
                    visit
                }
            }
            paginatorInfo {
                total
                lastItem
                currentPage
                hasMorePages
            }
        }
    }
`;
