import React, { useEffect } from "react";
import Subtract from "assets/images/Subtract-white.png";
import { BsArrowDown } from "react-icons/bs";
import { BookSpot, Buttonlink } from "components";
import business from "assets/images/Modules/business-team.webp";
import Erik from "assets/images/Modules/Erik.png";
import banner from "assets/images/Modules/Modules.webp";
import Chris from "assets/images/buyer/Chris.jpg";
import { IoMdCheckmark } from "react-icons/io";
import { scrollToContent } from "helpers/ScrollToContent";

const ModulePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const keyFeatures = [
		"In-depth masterclasses led by industry experts.",
		"Practical insights and strategies for efficient and sustainable contracting.",
		"Interactive sessions and case studies to reinforce learning.",
	];

	return (
		<>
			<div className="pb-28 -mt-[50px]">
				<div className="lg:bg-transparent md:bg-transparent bg-black">
					<div className="lg:hidden md:hidden relative">
						<img
							src={banner}
							alt="banner"
							className="object-cover w-full h-full object-center"
						/>
					</div>
					<div className="lg:h-[90vh] md:h-[100vh] ModuleBanner relative">
						<div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
							<p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
								Dive into Our Extensive Product Modules for Cutting-Edge
								Insights and Strategies
							</p>
							<p className="white Poppins font-normal">
								Transform your procurement game with us!
							</p>
							<div className="h-px bg-white mt-3" />
							<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
								<button
									onClick={() => scrollToContent("contentSection", 1000)}
									className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
								>
									<div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
									<span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
										Discover <BsArrowDown size={23} />
									</span>
								</button>
							</div>
						</div>
						<div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
							<img
								src={Subtract}
								alt="Subtract"
								className="object-cover w-full left-1/2 -translate-x-1/2 relative"
							/>
						</div>
					</div>
				</div>
			</div>
			<div id="contentSection" className="container relative">
				<div className="absolute lg:block hidden left-0 top-0">
					<svg
						width="39"
						height="39"
						viewBox="0 0 39 39"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF"></circle>
					</svg>
				</div>
				<div className="grid grid-cols-12 gap-4 relative">
					<div className="lg:col-span-4 md:col-span-6 col-span-12">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="332"
							height="332"
							viewBox="0 0 332 332"
							fill="none"
						>
							<path
								d="M138.077 35.566L130.434 123.746L42.2537 116.103C20.2087 114.192 2.24179 129.293 0.330993 151.338C-1.57981 173.383 13.5209 191.35 35.5659 193.26L123.746 200.904L116.103 289.084C114.192 311.129 129.293 329.095 151.338 331.006C173.383 332.917 191.349 317.816 193.26 295.771L200.903 207.591L289.084 215.235C311.129 217.145 329.095 202.045 331.006 180C332.917 157.955 317.816 139.988 295.771 138.077L207.591 130.434L215.234 42.2538C217.145 20.2087 202.045 2.24189 180 0.33109C157.955 -1.57971 139.988 13.5209 138.077 35.566Z"
								fill="#B5E2FF"
								fillOpacity="0.7"
							/>
						</svg>
					</div>
					<div className="lg:col-span-8 space-y-5 md:col-span-8 col-span-12">
						<h1 className="lg:text-5xl text-4xl font-light text-text-new-color Poppins">
							What's in it for you?
						</h1>
						<p className="text-3xl font-light Poppins primary">
							Discover the key topics and learning objectives covered in our
							product modules through our concise Module Overview.
						</p>
						<p className="font-normal text-text-new-color text-lg Poppins">
							Gain valuable insights into procurement strategies, supply chain
							optimization, risk management, sustainability practices, and more.
						</p>
						<Buttonlink
							onClick={() => scrollToContent("modules", 1000)}
							Lasticon={<BsArrowDown size={23} />}
							Text="Explore Topics"
							className="flex items-center px-4 justify-between mt-3 rounded-full lg:w-60 md:w-60 w-full Poppins white bg--primary hover:color-white hover:bg--primary focus:bg--primary hover:opacity-70 h-12"
						/>
					</div>
					<div className="absolute right-0 -bottom-20 lg:block hidden">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="214"
							height="214"
							viewBox="0 0 214 214"
							fill="none"
						>
							<g clipPath="url(#clip0_553_2505)">
								<path
									d="M90.1865 29.3941L85.6539 81.6878L33.3602 77.1551C20.2868 76.0219 9.63191 84.9771 8.49874 98.0505C7.36558 111.124 16.3208 121.779 29.3942 122.912L81.6878 127.445L77.1551 179.738C76.022 192.812 84.9771 203.467 98.0506 204.6C111.124 205.733 121.779 196.778 122.912 183.704L127.445 131.411L179.738 135.943C192.812 137.077 203.467 128.121 204.6 115.048C205.733 101.975 196.778 91.3197 183.704 90.1865L131.411 85.6538L135.943 33.3602C137.077 20.2868 128.121 9.63187 115.048 8.49871C101.975 7.36554 91.3197 16.3207 90.1865 29.3941Z"
									fill="#B5E2FF"
									fillOpacity="0.4"
								/>
							</g>
							<defs>
								<clipPath id="clip0_553_2505">
									<rect
										width="196.836"
										height="196.836"
										fill="white"
										transform="translate(0 196.101) rotate(-85.0461)"
									/>
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
				<div className="shadow-[0px_4px_20px_0px_#0000001A] mt-28 rounded-3xl p-10">
					<div className="flex lg:items-start items-center lg:flex-row flex-col gap-5">
						<img src={Erik} alt="Erik" className="rounded-full w-60 h-60" />
						<div className="space-y-3">
							<h6 className="primary text-3xl font-light Poppins">
								Erik Stavrand
							</h6>
							<p className="text-new-color text-lg Poppins font-normal">
								Introducing Erik, a globally recognized expert in procurement
								strategy and negotiation techniques. With a proven track record
								across diverse industries including diesel engines, oil and gas
								exploration, pharmaceuticals, and medical devices, Erik's
								approach is widely acclaimed for delivering optimal value and
								fostering strong supplier relationships.
							</p>
							<p className="text-new-color text-lg Poppins font-normal">
								Erik's methodology goes beyond traditional price-focused
								approaches. Instead, he emphasizes understanding business
								requirements, stakeholder analysis, supplier evaluation, and
								negotiation planning to achieve the best total value. His
								framework, rooted in years of experience and success, ensures
								that organizations not only secure the right products but also
								maximize long-term benefits and innovation potential.
							</p>
							<p className="text-new-color text-lg Poppins font-normal">
								Now, Erik brings his expertise to the firefighting community,
								offering tailored training in apparatus procurement. Fire
								department leaders and apparatus committees will learn how to
								apply Erik's tools and strategies to plan purchases effectively,
								ensuring maximum value for their departments. By adopting this
								approach, departments can enhance their procurement processes,
								identify the best suppliers, and create a predictable order
								queue for future needs. <br />
								For those interested in delving deeper into Erik's methodology,
								he shares insights on LinkedIn and has authored two
								comprehensive books: "The Global Category Manager's Handbook"
								and "The Global Procurement Leader's Handbook." Join us as we
								revolutionize the approach to apparatus procurement with Erik's
								proven techniques.
							</p>
						</div>
					</div>
				</div>
				<div
					className="shadow-[0px_4px_20px_0px_#0000001A] mt-28 rounded-3xl p-10"
					id="modules"
				>
					<div className="flex lg:items-start items-center lg:flex-row flex-col gap-5">
						<img
							src={Chris}
							alt="Chris"
							className="rounded-full w-60 h-60 grayscale"
						/>
						<div className="space-y-3">
							<h6 className="primary text-3xl font-light Poppins">
								Chris Shanahan
							</h6>
							<p className="text-new-color text-lg Poppins font-normal">
								Chris Shanahan is a seasoned executive with over two decades of
								experience in procurement, supply chain management, and
								sustainability. He brings a wealth of knowledge and a passion
								for sustainable practices to his role.
							</p>
							<p className="text-new-color text-xl Poppins font-bold">
								Career Highlights
							</p>
							<ul className="!pt-3">
								{[
									{
										bold: "Thermo Fisher Scientific",
										text: "Joined in 2018 as Vice President of Global Procurement, where he has been instrumental in leveraging the company's scale, building capabilities, and streamlining processes across the organization.",
									},
									{
										bold: "Becton Dickinson (BD)",
										text: "Spent 18 years as Senior Vice President of Global Procurement, Facilities, and Real Estate, developing extensive expertise in global supply networks.",
									},
									{
										bold: "Previous Roles",
										text: "Held significant leadership positions at SmithKline Beecham and Essilor, gaining valuable experience in materials management and supply chain operations across Europe.",
									},
								]?.map((highlight) => {
									return (
										<li className="text-new-color text-lg Poppins font-normal">
											<span className="text-new-color text-lg Poppins font-bold">
												{highlight?.bold}:
											</span>{" "}
											{highlight?.text}
										</li>
									);
								})}
							</ul>
							<p className="text-new-color text-xl Poppins font-bold">
								Expertise and Approach
							</p>
							<p className="text-new-color text-lg Poppins font-normal">
								Chris views every supply chain challenge as an opportunity for
								improvement. His approach to reducing emissions and driving
								sustainability is informed by personal experiences, including
								his own asthma, which has heightened his awareness of the
								critical intersection between environmental sustainability and
								personal health.
							</p>
							<p className="text-new-color text-xl Poppins font-bold">
								Additional Roles
							</p>
							<ul className="!pt-3">
								{[
									"Adjunct Professor in the Supply Chain Master's programme at Fairleigh Dickinson University",
									`Co-author of "The Procurement Leader's Handbook"`,
									"Board member of EPICA International",
									"Active participant in CPO Rising conferences",
								]?.map((highlight) => {
									return (
										<li className="text-new-color text-lg Poppins font-normal">
											{highlight}
										</li>
									);
								})}
							</ul>

							<p className="text-new-color text-lg Poppins font-normal">
								Chris Shanahan's unique blend of corporate leadership, academic
								involvement, and personal commitment to sustainability makes him
								a driving force in advancing sustainable procurement practices
								within the pharmaceutical and biotechnology industries.{" "}
							</p>
							<div>
								<p className="text-new-color text-lg Poppins font-bold">
									Series Title: Mastering Procurement: Streamlining Contracting
									with Third-Party Vendors
								</p>
								<p className="text-new-color text-lg Poppins font-normal">
									<span className="text-new-color text-lg Poppins font-bold">
										Cohort 1:
									</span>{" "}
									Contracting for Non-Contracting Professionals
								</p>
								<p className="text-new-color text-lg Poppins font-normal">
									<span className="text-new-color text-lg Poppins font-bold">
										Cohort 2:
									</span>{" "}
									Masterclass for Navigating Contracting Basics in an
									Inflationary Market
								</p>
								<p className="text-new-color text-lg Poppins font-normal">
									<span className="text-new-color text-lg Poppins font-bold">
										Cohort 3:
									</span>{" "}
									Masterclass for Contracting Basics for Procurement Category
									Management
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-center items-center flex-col mt-10 gap-y-6">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width={72}
						height={54}
						viewBox="0 0 146 108"
						fill="none"
					>
						<path
							d="M19.2708 55.0287C24.1656 48.6308 31.9125 45.7787 40.7771 45.7787C57.1573 45.7787 68.9896 56.8402 68.9896 75.4944C68.9896 93.0308 57.9281 107.908 34.6875 107.908C13.3354 107.908 0 92.6454 0 67.8631C0 34.7173 19.926 8.70166 55.9625 0.10687C58.0823 -0.394171 60.4333 0.916245 61.1656 2.9975L61.8979 5.07875C62.6302 7.16 61.5125 9.2027 59.3927 9.81937C35.6125 16.4871 21.1979 30.9787 17.6135 54.4506C17.3052 56.609 17.9219 56.8017 19.2708 55.0287ZM95.5063 55.0287C100.401 48.6308 108.148 45.7787 117.013 45.7787C133.393 45.7787 145.225 56.8402 145.225 75.4944C145.225 93.0308 134.164 107.908 110.923 107.908C89.5708 107.908 76.2354 92.6454 76.2354 67.8631C76.2354 34.7173 96.1615 8.70166 132.236 0.10687C134.356 -0.394171 136.707 0.916245 137.44 2.9975L138.172 5.07875C138.904 7.16 137.786 9.2027 135.667 9.81937C111.886 16.4871 97.4719 30.9787 93.8875 54.4506C93.5406 56.609 94.1958 56.8017 95.5063 55.0287Z"
							fill="#FF6600"
						></path>
					</svg>
					<p className="text-new-color text-center mx-10 text-3xl font-light Poppins">
						Each cohort offers specialized training tailored to different
						aspects of contracting, equipping you with the knowledge and tools
						needed to excel in procurement category management and navigate
						challenges in an inflationary market.”
					</p>
				</div>
				<div className="space-y-8 flex justify-center items-center flex-col px-12 mt-12">
					<p className="text-[18px] leading-6 Poppins text-center font-normal">
						Key Features:
					</p>
					<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12">
						{keyFeatures.map((feature) => {
							return (
								<div className="bg-white custom-shadow w-full flex flex-col justify-start items-center gap-3 py-5 px-8 rounded-[50px] text-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="38"
										height="29"
										viewBox="0 0 38 29"
										fill="none"
									>
										<path
											d="M37.2947 4.2051L13.2872 28.275C12.7811 28.7824 12.1303 29 11.4072 29C10.7563 29 10.0332 28.7824 9.52715 28.275L0.705129 19.43C-0.235043 18.4874 -0.235043 16.8925 0.705129 15.9499C1.21121 15.4425 1.7898 15.2249 2.44067 15.2249C3.09153 15.2249 3.7424 15.4425 4.17621 15.9499L11.4074 23.1999L33.824 0.724997C34.3301 0.217596 34.9086 0 35.5595 0C36.2104 0 36.8612 0.217596 37.2951 0.724997C38.235 1.66761 38.235 3.26249 37.2951 4.2051H37.2947Z"
											fill="#B5E2FF"
										/>
									</svg>
									<p className="font-bold Poppins text-lg text-new-color">
										{feature}
									</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="bg--lightBlue lg:mt-56 mt-28">
				<div className="container relative">
					<img
						src={business}
						alt="business"
						className="lg:absolute lg:h-[750px] lg:pt-0 py-16 w-full lg:-top-24"
					/>

					<div className="lg:pt-[650px] lg:pb-44">
						<div className="relative">
							<p className="text-3xl text-center font-light Poppins">
								Why Choose our Modules?
							</p>
							<div className="bg-white z-50 relative rounded-2xl p-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
								<div className="divide-y">
									<div className="flex p-3 items-start gap-4 w-full">
										<div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
											<IoMdCheckmark color="#B5E2FF" size={24} />
										</div>
										<p className="text-lg Poppins">
											<span className="Poppins font-bold text-lg">
												Expert-Level Skills:
											</span>{" "}
											Master advanced procurement techniques to become an
											industry leader.
										</p>
									</div>
									<div className="flex p-3 items-start gap-4 w-full">
										<div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
											<IoMdCheckmark color="#B5E2FF" size={24} />
										</div>
										<p className="text-lg Poppins">
											<span className="Poppins font-bold text-lg">
												Drive Business Success:
											</span>{" "}
											Implement strategies that optimize operations and boost
											bottom-line results.
										</p>
									</div>
									<div className="flex p-3 items-start gap-4 w-full">
										<div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
											<IoMdCheckmark color="#B5E2FF" size={24} />
										</div>
										<p className="text-lg Poppins">
											<span className="Poppins font-bold text-lg">
												Future-Proof Your Skills:
											</span>{" "}
											Stay ahead with cutting-edge knowledge in trends and
											emerging technologies.
										</p>
									</div>
									<div className="flex p-3 items-start gap-4 w-full">
										<div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
											<IoMdCheckmark color="#B5E2FF" size={24} />
										</div>
										<p className="text-lg Poppins">
											<span className="Poppins font-bold text-lg">
												Network with Industry Leaders:
											</span>{" "}
											Build valuable connections for collaborative growth and
											new opportunities.
										</p>
									</div>
								</div>
								<div className="absolute left-0 lg:block hidden -bottom-56">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="214"
										height="214"
										viewBox="0 0 214 214"
										fill="none"
									>
										<g clipPath="url(#clip0_820_3467)">
											<path
												d="M90.3545 29.8741L85.8218 82.1677L33.5282 77.6351C20.4548 76.5019 9.79988 85.4571 8.66671 98.5305C7.53355 111.604 16.4887 122.259 29.5621 123.392L81.8558 127.925L77.3231 180.218C76.1899 193.292 85.1451 203.947 98.2185 205.08C111.292 206.213 121.947 197.258 123.08 184.184L127.613 131.891L179.906 136.423C192.98 137.557 203.635 128.601 204.768 115.528C205.901 102.455 196.946 91.7997 183.872 90.6665L131.579 86.1338L136.111 33.8402C137.245 20.7668 128.289 10.1119 115.216 8.97869C102.143 7.84552 91.4877 16.8007 90.3545 29.8741Z"
												fill="#B5E2FF"
												fillOpacity="0.4"
											/>
										</g>
										<defs>
											<clipPath id="clip0_820_3467">
												<rect
													width="196.836"
													height="196.836"
													fill="white"
													transform="translate(0.167969 196.581) rotate(-85.0461)"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-28 -mb-36">
						<BookSpot />
					</div>
				</div>
			</div>
		</>
	);
};

export default ModulePage;
