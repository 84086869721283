import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Buttonlink(props) {
  const { className, Icon, Text, to, onClick, Lasticon, href, disabled } =
    props;

  useEffect(() => {
    //
  }, [disabled]);

  return (
    <>
      {to && (
        <Link to={to} className={className}>
          {Icon}
          {Text}
          {Lasticon}
        </Link>
      )}
      {onClick && (
        <button
          // type="button"
          onClick={onClick}
          disabled={disabled}
          className={className}>
          {Icon}
          {Text}
          {Lasticon}
        </button>
      )}
      {href && (
        <a href={href} target="_blank" rel="noreferrer" className={className}>
          {Icon}
          {Text}
          {Lasticon}
        </a>
      )}
    </>
  );
}
