import React, { useEffect, useState } from "react";
import { Buttonlink, Knowledgepocket } from "components";
import { BsArrowDown } from "react-icons/bs";
import GET_BLOG from "../../graphql/queries/getBlog";
import GET_ALL_BLOGS from "../../graphql/queries/getAllBlogs";
import { Query } from "@apollo/client/react/components";
import { useParams, Link } from "react-router-dom";
import { Noconnection, IsLoader } from "components";

const BlogPage = () => {
  const { slug } = useParams();
  const [variables, setVariables] = useState({ slug: slug });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [variables]);

  const handleVariables = (newSlug) => {
    setVariables({ slug: newSlug });
  }

  const variablesForAll = {};

  return (
    <div className="container">
      <div>
        <p className="font-normal text-lg primary Poppins">
          Unleash the Power of Procurement with us!
        </p>
        <h1 className="lg:text-5xl text-4xl font-light Poppins text-new-color">
          Explore our Exquisite Collection of Blogs that Illuminate, Inspire,
          And Empower Your Procurement Journey!
        </h1>
        <p className="font-normal text-lg pt-10 text-new-color Poppins">
          Maximize Efficiency, Minimize Costs: Unlock the Secrets of Procurement
          Outsourcing
        </p>
      </div>
      <Query query={GET_BLOG} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div className="my-10">
                <IsLoader className="w-32 h-32 mx-auto" />
              </div>
            );
          }
          if (error) return <Noconnection />;

          if (data !== undefined) {
            return (
              <>
                {data?.blog ?
                <div className="mt-28">
                  <img src={`${data?.blog?.images?.[0]?.path}`} alt="blog" />
                </div>: 
                <div className="mt-20 flex justify-center items-center flex-col primary font-2xl font-light">Blog doesn't exist! 
                  <div>Please explore Other Blog Articles!</div>
                </div>
                }
                <div className="grid grid-cols-12 mt-28 gap-5">
                  <div className="lg:col-span-8 col-span-12">
                    <div className="space-y-5">
                      <h1 className="Poppins text-[45px] font-normal text-new-color">
                        {data?.blog?.title}
                      </h1>
                      <div dangerouslySetInnerHTML={{ __html: data?.blog?.content }} className="font-normal Poppins-all text-lg text-new-color">
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-4 lg:order-last order-first col-span-12">
                    <div className="p-8 border border-gray space-y-5">
                      <p className="Poppins font-bold text-new-color text-lg">
                        Other Blog Articles
                      </p>
                      <Query query={GET_ALL_BLOGS} variables={variablesForAll}>
                        {({ loading, error, data }) => {
                          if (loading) {
                            return (
                              <div className="my-10">
                                <IsLoader className="w-32 h-32 mx-auto" />
                              </div>
                            );
                          }
                          if (error) return <Noconnection />;

                          if (data !== undefined) {
                            return (
                              <>
                                {data?.blogs?.data.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <Link
                                        to={`/blog/${item.slug}`}
                                        onClick={() => handleVariables(item.slug)}
                                        className="Poppins font-[600] primary underline text-lg block"
                                      >
                                        {item.title}
                                      </Link>
                                    </div>
                                  );
                                })}
                                <Buttonlink
                                  to={"/contact-us"}
                                  Lasticon={<BsArrowDown size={23} />}
                                  Text="Contact us"
                                  className="flex items-center px-3 justify-between mt-3 rounded-full lg:w-48 md:w-60 w-full Poppins white bg--primary hover:color-white hover:bg--primary focus:bg--primary hover:opacity-70 h-11"
                                />
                              </>
                            );
                          }
                        }}
                      </Query>
                    </div>
                  </div>
                </div>
                <div className="-mb-36 mt-28">
                  <Knowledgepocket isImageHide={true} />
                </div>
              </>
            );
          }
        }}
      </Query>
    </div>
  );
};

export default BlogPage;
