import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsArrowDown } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import apple_icon from "assets/images/apple_icon.png";
import black_logo from "assets/images/new_logo_latest.png_black.png";
import OffModal from "assets/images/OffModal.png";
import playstore from "assets/images/playstore.png";
import {
    AiFillInstagram,
    AiFillYoutube,
} from "react-icons/ai";
import {
    ANDROID_APP_URL,
    IOS_APP_URL,
    LINKEDIN_URL,
    FACEBOOK_URL,
} from "../../config/constants";
import GET_BANNER_VOUCHER from "../../graphql/queries/getBannerVoucher";
import client from "../../graphql/client";
import { LinearProgress } from "@mui/material";
import Buttonlink from "components/allcomponents/buttonlink";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

function SalePopup(props) {
    const [isOpen, SetIsOpen] = useState(false);
    const [product, SetProduct] = useState([]);
    const [course, SetCourse] = useState([]);
    const [progressValue, setProgressValue] = useState(-20);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progressValue < 100) {
                setProgressValue((prevValue) => prevValue + 1);
            } else {
                clearInterval(interval); // Stop the interval when progress reaches 100
                setTimeout(() => {
                    SetIsOpen(false); // Close the popup after a delay
                }, 200); // Adjust the delay time as needed
            }
        }, 200); // Adjust the interval duration as needed

        return () => {
            clearInterval(interval); // Cleanup the interval when the component unmounts
        };
    }, [progressValue]);

    useEffect(() => {
        client
            .query({
                query: GET_BANNER_VOUCHER,
                fetchPolicy: "network-only",
            })
            .then((res) => {
                // console.log("res",res)
                let array = [];
                let banner = res?.data?.get_banner_voucher;
                if (banner?.length > 0) {
                    banner.forEach((voucher) => {
                        if (voucher?.plan_id === 7) {
                            SetProduct(voucher);
                        } else {
                            array.push(voucher);
                        }
                        setTimeout(() => {
                            SetCourse(array);
                            if (
                                banner.some((o) => o.plan_id === 7) &&
                                props.from === "product"
                            ) {
                                SetIsOpen(true);
                            } else if (
                                array.length > 0 &&
                                props.from === "classes"
                            ) {
                                SetIsOpen(true);
                            } else {
                                SetIsOpen(false);
                            }
                        });
                    });
                } else {
                    console.log("No Discount");
                }
            })
            .catch((error) => {
                console.log("error", error);
                SetIsOpen(false);
            });

        /*   setTimeout(()=>{
               switch (props.from) {
                   case course?.length !== 0 : SetIsOpen(true)
                       break;
                   case product?.length !== 0 : SetIsOpen(true)
                       break
                   default:SetIsOpen(false)
               }
           },2000)*/
    }, [props.from]);
    const offPercentage = Math.max(course?.map(c => c.discount || 0))
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[999]"
                onClose={() => SetIsOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-[1240px] h-[calc(100vh-10%)] overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]">
                                <LinearProgress
                                    variant="determinate"
                                    value={progressValue}
                                />
                                <div className="px-5 py-5">
                                    <div className="flex justify-between items-start">
                                        <img
                                            src={black_logo}
                                            alt="black_logo"
                                            className="md:w-[280px] w-[200px]"
                                        />
                                        <div>
                                            <FiX
                                                onClick={() => SetIsOpen(false)}
                                                size={24}
                                                className="primary cursor-pointer"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:mt-0 mt-5 gap-5 justify-center items-center">
                                        <div className="text-center">
                                            <h1 className="lg:text-5xl text-3xl primary font-bold Poppins">
                                               Upto {offPercentage} %OFF
                                            </h1>
                                            <p className="text-3xl capitalize font-light Poppins text-new-color">
                                                On all our specially designed
                                                masterclasses
                                            </p>
                                        </div>
                                        <div>
                                            <img
                                                src={OffModal}
                                                alt="OffModal"
                                                className="w-[400px] md:h-[170px] h-[120px]"
                                            />
                                        </div>
                                        <div className="h-1 rounded  max-w-md w-full bg-[#392054]" />
                                        <div className=" w-full md:flex-nowrap flex-wrap max-w-lg mx-auto flex justify-center gap-y-5 md:justify-between items-center">
                                            {
                                                course.length !== 0 &&
                                                course.map((plan, index) => (
                                                    <div className="text-center w-full">
                                                        <p className="text-3xl font-semibold Poppins text-new-color">
                                                            Pro ({plan.plan?.validity === "M" ? "Monthly" : "Yearly"})
                                                        </p>
                                                        <p className="text-lg Poppins font-normal text-new-color">
                                                            ${plan.plan?.price}/{plan.plan?.validity === "M" ? "Month" : "Year"}
                                                        </p>
                                                        <p className="text-3xl pt-3 font-light Poppins text-new-color">
                                                            {plan.plan?.validity === "M" ? "1 Month" : "1 Year"}
                                                        </p>
                                                        <Buttonlink
                                                            Text={"Select"}
                                                            to={"/pricing"}
                                                            Lasticon={
                                                                <BsArrowDown
                                                                    size={24}
                                                                    color="white"
                                                                />
                                                            }
                                                            className="bg--primary border-none outline-none md:w-44 w-full mx-auto hover:text-white mt-3 h-12 px-5 hover:opacity-80 text-white Poppins text-lg font-normal flex justify-between items-center rounded-full"
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <p className="text-center Poppins text-lg font-normal text-new-color ">
                                            Copyright © 2024 Procurement League
                                            Inc. All Rights Reserved
                                        </p>
                                        <div className="flex justify-center items-center gap-5">
                                            <a href={ANDROID_APP_URL}>
                                                <img
                                                    src={playstore}
                                                    className="w-[138px] h-[40px]"
                                                    alt="playstore"
                                                />
                                            </a>
                                            <a href={IOS_APP_URL}>
                                                <img
                                                    src={apple_icon}
                                                    className="w-[138px] h-[40px]"
                                                    alt="apple_icon"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex  flex-row items-center justify-start gap-4">
                                            <a
                                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                                href={LINKEDIN_URL}
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                <FaLinkedinIn
                                                    size={18}
                                                    className="cursor-pointer white hover"
                                                />
                                            </a>
                                            <a
                                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                                href={FACEBOOK_URL}
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                <FaFacebookF
                                                    size={18}
                                                    className="cursor-pointer white hover"
                                                />
                                            </a>
                                            <a
                                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                                href="https://twitter.com/procurementlea"
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                <FaTwitter
                                                    size={18}
                                                    className="cursor-pointer white hover"
                                                />
                                            </a>
                                            <a
                                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                                href="https://www.instagram.com/procurementleague/"
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                <AiFillInstagram
                                                    size={18}
                                                    className="cursor-pointer white hover"
                                                />
                                            </a>
                                            <a
                                                className="flex items-center justify-center w-10 h-10 transition duration-500 ease-in-out transform rounded-full hover:-translate-y-1 hover:scale-110 black bg--primary"
                                                href="https://www.youtube.com/@procurementleague"
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                <AiFillYoutube
                                                    size={18}
                                                    className="cursor-pointer white hover"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default SalePopup;
