import { gql } from "@apollo/client";
export default gql`
    query{
    KnowledgeBank{
        category_name
        knowledge_banks{
            id
            name
            image{
                id
                path
                type
                mime
                size
            }
            file_path{
                id
                path
                type
                mime
                size
            }
            tags
            is_paid
            additional_votes
            check_for_vote
        }
    }
}
`;
