import { GET_AUTH_USER_INFORMATION } from "./types";
import store from "../index";
import client from "../../graphql/client";
import ME from "../../graphql/queries/me";
import cookie from "js-cookie";
import { AUTH_USER, GRAPH_USER_AUTH_TOKEN, GET_AUTH_USER_SOCKET } from "../../config/constants";
import io from "socket.io-client";

class AuthUserAction {

  async getAuthUserInformation() {
    let socket = io.connect(process.env.REACT_APP_SOCKET_WEB_URL);
    let authExpiry = cookie.get("AUTH_TOKEN_EXPIRY");
    const date = new Date();
    if (!authExpiry || (Number(authExpiry) < Number(date.getTime()))) {
      this.logoutUser();
    }
    try {
      const res = await client.query({ query: ME, fetchPolicy: "network-only" });
      if (!res.data?.me?.is_active) {
        this.logoutUser();
      } else {
        this.updateAuthUser(res.data.me, socket);
      }

      return true;
    } catch (err) {
      //this.logoutUser();
      return false;
    }
  }

  logoutUser() {
    cookie.remove("AUTH_TOKEN_EXPIRY");
    cookie.remove(GRAPH_USER_AUTH_TOKEN);
    cookie.remove(AUTH_USER);
    localStorage.removeItem(AUTH_USER);
    client.clearStore();
    window.location.href = "/";
  }

  updateAuthUser(userData, socket) {
    store.dispatch({
      type: GET_AUTH_USER_INFORMATION,
      payload: userData,
    });
    store.dispatch({
      type: GET_AUTH_USER_SOCKET,
      payload: socket,
    });

    cookie.set(AUTH_USER, JSON.stringify(userData));
    localStorage.setItem(AUTH_USER, JSON.stringify(userData));
  }
}

const MyAuthUserAction = new AuthUserAction();
export default MyAuthUserAction;
