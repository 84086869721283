import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function Postskeleton() {
  return (
    <div className="">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-5">
        <div className="my-auto lg:col-span-3 md:col-span-2">
          <Skeleton
            animation="pulse"
            variant="circle"
            height={300}
            className="rounded-lg"
          />
        </div>
        <div className="space-y-8 lg:col-span-2 md:col-span-3">
          <Skeleton
            animation="pulse"
            variant="rect"
            height={6}
            className="rounded-full"
            width={100}
          />
          <div className="space-y-2">
            <Skeleton variant="rect" height={12} className="rounded-full" />
            <Skeleton
              variant="rect"
              height={12}
              className="w-2/3 rounded-full"
            />
            <Skeleton
              variant="rect"
              height={12}
              className="w-1/2 rounded-full"
            />
          </div>
          <Skeleton
            animation="pulse"
            variant="rect"
            height={6}
            className="mt-5 rounded-full"
            width={50}
          />
          <Skeleton
            animation="pulse"
            variant="rect"
            height={30}
            className="rounded-full"
            width={100}
          />
          <Skeleton
            animation="pulse"
            variant="rect"
            height={30}
            className="rounded-full"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-6 lg:grid-cols-5">
        <div className="my-auto lg:col-span-3 md:col-span-2">
          <div className="space-y-2">
            <Skeleton variant="rect" height={12} className="rounded-full" />
            <Skeleton
              variant="rect"
              height={12}
              className="w-2/3 rounded-full"
            />
            <Skeleton
              variant="rect"
              height={6}
              width={120}
              className="mt-10 rounded-full"
            />
            <Skeleton
              variant="rect"
              height={6}
              width={150}
              className="rounded-full "
            />
            <Skeleton
              variant="rect"
              height={6}
              width={80}
              className="rounded-full "
            />
          </div>
        </div>
        <div className="space-y-8 lg:col-span-2 md:col-span-3"></div>
      </div>
    </div>
  );
}
