import React from "react";
import { Modal } from "react-bootstrap";
import { Buttonlink } from "components";

export default function Becomeapropopup(props) {
  const { type, item } = props;
  return (
    <div>
      <Modal {...props} centered>
        <Modal.Body>
          <div className="mb-4 text-center">Please sign-up for Pro level.</div>
          <div className="flex justify-center">
            <Buttonlink
              onClick={props.onHide}
              className="h-10 mr-3 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary Regular hover:opacity-90 rounded--xl "
              Text={"Cancel"}
            />
            <Buttonlink
              to={{
                pathname: type === "item_based" ? `/payment` : `/pricing`,
                state:
                  type === "item_based"
                    ? {
                        item: {
                          id: item.id,
                          price: item.course_objective,
                          name: item.course_name,
                          data_type: item.data_type,
                          is_shippable: item.is_shippable,
                        },
                        type: type,
                      }
                    : false,
              }}
              className="flex items-center justify-center h-10 px-3 text-white border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary Regular hover:opacity-90 rounded--xl"
              Text={"Become a pro"}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
