import { gql } from "@apollo/client";

export default gql`
 query blog($id: ID, $slug: String) {
    blog(id: $id, slug: $slug){
        id
        title
        slug
        content
        user_id
        is_active
        created_at
        updated_at
        categories{
            name
            slug
        }
        images{
            id
            path
            imageable_id
            imageable_type
            type
            mime
            size
            created_at
            updated_at
            }
    }
  }
`;
