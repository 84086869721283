import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import Subtract from "assets/images/Subtract-white.png";
import Mask_four from "assets/images/SuccessStories/Mask_four.png";
import pattern_one from "assets/images/SuccessStories/pattern_one.png";
import pattern_two from "assets/images/SuccessStories/pattern_two.png";
import Mask_five from "assets/images/SuccessStories/Mask_five.png";
import Saheena from "assets/images/SuccessStories/Saheena.png";
import AvnipBW from "assets/images/SuccessStories/AvnipBW.png";
import JamesBW from "assets/images/SuccessStories/JamesBW.png";
import KaurBW from "assets/images/SuccessStories/KaurBW.png";
import Gerald from "assets/images/SuccessStories/Gerald.jpg";
import Joanna from "assets/images/SuccessStories/Joanna.png";
import HamzaBW from "assets/images/SuccessStories/HamzaBW.png";
import Hamza from "assets/images/team/Hamza.webp";
import MobileBanner from "assets/images/SuccessStories/SuccessStoriesbuyside.png";
import Zoran from "assets/images/SuccessStories/Zoran.jpg";
import Group from "assets/images/SuccessStories/group.png";
import { Knowledgepocket } from "components";
import { useHistory } from "react-router-dom";
import { scrollToContent } from "helpers/ScrollToContent";

const SuccessStoryPage = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="-mt-[50px]">
            <div className="lg:bg-transparent md:bg-transparent bg-black">
                <div className="lg:hidden md:hidden relative">
                    <img src={MobileBanner} alt="banner" className="object-cover w-full h-full object-center" />
                </div>
                <div className="md:h-[90vh] SuccessStoriesBanner relative">
                    <div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                        <p className="text-[#FBE4D3] Poppins font-normal">
                            Success Stories
                        </p>
                        <p className="primary font-light Poppins md:leading-[60px] leading-[35px] lg:text-[48px] md:text-[48px] text-[30px]">
                            Buyside Success Stories: Igniting Careers,
                            Empowering Futures
                        </p>
                        <div className="h-px bg-white mt-8" />
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                            <button onClick={() => history.push(() => scrollToContent('success_stories', 2000))} className="group border-white px-4 hover:bg--black border-2 hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg-transparent">
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative primary flex Poppins items-center justify-between group-hover:text-white">
                                    BuySide <BsArrowRight size={23} />
                                </span>
                            </button>
                            <button onClick={() => history.push('/success-stories-sellside')} className="group px-4 hover:bg--black border-2 border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                    SellSide <BsArrowRight size={23} />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                        <img
                            src={Subtract}
                            alt="Subtract"
                            className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                        />
                    </div>
                </div>
            </div>
            <div className="relative mt-28">
                <div className="absolute left-20 top-0 lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="144"
                        height="144"
                        viewBox="0 0 144 144"
                        fill="none"
                    >
                        <circle
                            cx="72"
                            cy="72"
                            r="57"
                            fill="white"
                            stroke="#FFEFE5"
                            strokeWidth="30"
                        />
                    </svg>
                </div>
                <div className="absolute left-1/4 -top-32 lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_716_2804)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_716_2804">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="container relative flex flex-col justify-center items-center gap-10">
                    <h1 className="text-black text-center font-light Poppins lg:leading-[60px] leading-[40px] lg:text-[48px] md:text-[48px] text-[30px]">We Procurement League, support people from all the hierarchy on our platform.</h1>
                    <img src={Group} alt="Group" className="w-full max-w-lg h-full max-h-80 object-contain" />
                </div>
            </div>
            <div className="relative mt-28 bg--lightBlue py-10">
                <div className="absolute right-0 lg:block hidden">
                    <img
                        src={pattern_one}
                        alt="pattern_one"
                        className="w-[500px] h-[500px]"
                    />
                </div>
                <div className="absolute left-0 bottom-[15%] lg:block hidden">
                    <img
                        src={pattern_two}
                        alt="pattern_two"
                        className="w-[500px] h-[500px]"
                    />
                </div>
                <div className="container mt-28 relative">
                    <div class="absolute left-0 -top-20 lg:block hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none"><circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF"></circle></svg>
                    </div>
                    <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-12 gap-16 place-items-center">
                        <div className="lg:col-span-7 flex flex-col lg:gap-5 gap-10">
                            <div className="lg:self-end self-center">
                                <img
                                    src={Mask_five}
                                    alt="Mask_five"
                                    className="w-[236px] h-[236px]"
                                />
                            </div>
                            <div className="space-y-5 w-full max-w-2xl">
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Career Growth Persona
                                </h1>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    People who just started fresh: from a
                                    non-Procurement background & becoming
                                    procurement professional.
                                </p>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    Are you someone who has recently embarked on
                                    a new career path in procurement, coming
                                    from a non-procurement background? We are
                                    excited to share some incredible success
                                    stories of individuals just like you who
                                    have transitioned into procurement
                                    professionals and achieved remarkable growth
                                    and fulfillment in their careers.
                                </p>
                                {/* <Buttonlink
                                    onClick={()=>scrollToContent('success_stories',2000)}
                                    Text={"Get To Know"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                /> */}
                            </div>
                        </div>
                        <div className="lg:col-span-5">
                            <div className="bg-white space-y-5 rounded-3xl custom-shadow lg:p-16 p-8 relative">
                                <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="54"
                                        viewBox="0 0 72 54"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                            fill="#FF6600"
                                        />
                                    </svg>
                                </div>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    If you are considering a career in
                                    Procurement, I encourage you to give it a
                                    chance. It may not be something you have
                                    considered before, but it could lead you to
                                    great success and fulfillment, just like it
                                    has for me.”
                                </p>
                                <p className="text-new-color text-lg font-bold Poppins">
                                    Saheena Parween Ansari, Assistant Marketing
                                    Manager, IND
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-12 grid-cols-1 lg:mt-0 mt-28 lg:gap-12 gap-16">
                        <div className="lg:col-span-7 flex lg:flex-row flex-col justify-center items-center lg:items-end lg:justify-end lg:gap-5 gap-16">
                            <div className="flex-shrink-0">
                                <img
                                    src={AvnipBW}
                                    alt="AvnipBW"
                                    className="w-[236px] h-[236px]"
                                />
                            </div>
                            <div className="bg-white space-y-5 rounded-3xl custom-shadow p-8 relative">
                                <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="54"
                                        viewBox="0 0 72 54"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                            fill="#FF6600"
                                        />
                                    </svg>
                                </div>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    I owe much to Procurement League and its incredible team, who have provided me with a platform to showcase my abilities and achieve my career goals.”
                                </p>
                                <p className="text-new-color text-lg font-bold Poppins">
                                    Sourcing Analyst, IND
                                </p>
                            </div>
                        </div>
                        <div className="lg:col-span-5 lg:mb-20">
                            <div className="bg-white space-y-5 rounded-3xl custom-shadow lg:p-16 p-8 relative">
                                <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="54"
                                        viewBox="0 0 72 54"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                            fill="#FF6600"
                                        />
                                    </svg>
                                </div>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    Being part of the Procurement League has been an incredible journey. Here I've leveraged my diverse background in Accounting, Finance, and Sales to drive innovative procurement projects.”
                                </p>
                                <p className="text-new-color text-lg font-bold Poppins">
                                    Strategic Advisor, AU
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-12 grid-cols-1 lg:mt-0 mt-28 lg:gap-12 gap-16">
                        <div className="lg:col-span-7 lg:mt-44">
                            <div className="space-y-5">
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Mid-level/senior
                                </h1>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    Learn about the inspiring journeys of
                                    mid-level procurement professionals who have
                                    embraced gig projects and part-time jobs
                                    through our platform. <br />
                                    Are you a mid-level procurement professional
                                    looking for gig projects or part-time
                                    opportunities? We are thrilled to share
                                    inspiring stories of individuals who have
                                    successfully transitioned into flexible
                                    career paths while leveraging their
                                    procurement expertise through our platform.
                                </p>
                                {/* <Buttonlink
                                    onClick={()=>scrollToContent('success_stories',2000)}
                                    Text={"Get To Know"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                /> */}
                            </div>
                        </div>
                        <div className="lg:col-span-5 flex lg:justify-start lg:items-start justify-center items-center flex-col gap-10">
                            <div>
                                <img
                                    src={Hamza}
                                    alt="Hamza"
                                    className="w-[236px] h-[236px] rounded-full"
                                />
                            </div>
                            <div className="bg-white space-y-5 rounded-3xl flex flex-col lg:gap-5 gap-10 custom-shadow lg:p-16 p-8 relative">
                                <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="54"
                                        viewBox="0 0 72 54"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                            fill="#FF6600"
                                        />
                                    </svg>
                                </div>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    Procurement League has significantly boosted
                                    my career since I launched my business,
                                    especially through hosting 104 video lessons
                                    in their library. This opportunity allowed
                                    me to share my expertise with a global
                                    audience of supply management professionals
                                    and students, establishing me as a
                                    recognized subject matter expert. It's been
                                    a first-rate success, aligning with my
                                    motivation to share knowledge with
                                    like-minded professionals.”
                                </p>
                                <p className="text-new-color text-lg font-bold Poppins">
                                    James Moran, <br />
                                    Procurement Expert, US
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 mt-28 lg:gap-12 gap-16">
                        <div className="flex justify-center lg:flex-row flex-col items-center lg:items-start gap-10">
                            <div className="flex-shrink-0">
                                <img
                                    src={Mask_four}
                                    alt="Mask_three"
                                    className="w-[236px] h-[236px]"
                                />
                            </div>
                            <div className="space-y-5 w-full lg:max-w-2xl">
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Top Level
                                </h1>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    Learn how our platform supports established
                                    procurement professionals in sharing their
                                    knowledge and becoming influential thought
                                    leaders in the industry
                                </p>
                                <p className="text-new-color text-lg font-normal Poppins">
                                    Are you an established procurement
                                    professional looking to share your knowledge
                                    and become a thought leader in the field? We
                                    are excited to showcase inspiring stories of
                                    top-level procurement professionals who have
                                    leveraged our platform to share their
                                    expertise and make a lasting impact through
                                    thought leadership.
                                </p>
                                {/* <Buttonlink
                                    onClick={()=>scrollToContent('success_stories',2000)}
                                    Text={"Get To Know"}
                                    Lasticon={<BsArrowRight size={23} />}
                                    className="py-2 text-xl border-2 self-start Poppins primary px-5 border--primary rounded-full flex justify-between items-center lg:w-60"
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-10 relative mt-72" id="success_stories">
                <div className="absolute lg:block hidden -top-60 left-28">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="144"
                        height="144"
                        viewBox="0 0 144 144"
                        fill="none"
                    >
                        <circle
                            cx="72"
                            cy="72"
                            r="57"
                            fill="white"
                            stroke="#B5E2FF"
                            strokeWidth="30"
                        />
                    </svg>
                </div>
                <div className="absolute lg:block hidden -top-52 left-96">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -top-52 right-96">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -top-44 right-44">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="46"
                        viewBox="0 0 46 46"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1849)">
                            <path
                                d="M19.3502 6.30676L18.3777 17.5267L7.15768 16.5542C4.35269 16.3111 2.0666 18.2325 1.82347 21.0375C1.58034 23.8425 3.50174 26.1286 6.30673 26.3717L17.5267 27.3442L16.5542 38.5642C16.3111 41.3692 18.2325 43.6553 21.0375 43.8984C23.8425 44.1415 26.1285 42.2201 26.3717 39.4152L27.3442 28.1952L38.5642 29.1677C41.3692 29.4108 43.6553 27.4894 43.8984 24.6844C44.1415 21.8794 42.2201 19.5933 39.4151 19.3502L28.1951 18.3777L29.1677 7.15771C29.4108 4.35272 27.4894 2.06663 24.6844 1.8235C21.8794 1.58037 19.5933 3.50177 19.3502 6.30676Z"
                                fill="#B5E2FF"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1849">
                                <rect
                                    width="42.2327"
                                    height="42.2327"
                                    fill="white"
                                    transform="translate(0 42.075) rotate(-85.0461)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden bottom-[15%] right-1/2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="absolute lg:block hidden -bottom-28 left-28">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_757_1845)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_757_1845">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
                    <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={Saheena}
                                alt="Saheena"
                                className="w-[109px] h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Saheena
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Assistant Marketing Manager
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                When I first started, I felt like a fish out of
                                water. The role was new, and I had to learn
                                everything from scratch. But you know what they
                                say, nothing worth having comes easy! I threw
                                myself into the job with all the determination I
                                could muster, and slowly but surely, I began to
                                excel. Sure, there were challenges along the
                                way. Procurement can be a stressful field, but
                                the adrenaline rush and excitement of the job
                                kept me motivated. I wanted to learn and grow,
                                and I did just that.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                I succeeded in this profession without prior
                                knowledge of Procurement through continuous
                                learning and development. So, how did I do it? I
                                didn't let my lack of knowledge hold me back.
                            </p>
                            <p className="text-lg Poppins text-new-color font-bold">
                                "If you are considering a career in Procurement,
                                I encourage you to give it a chance. It may not
                                be something you have considered before, but it
                                could lead you to great success and fulfillment,
                                just like it has for me."
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={AvnipBW}
                                alt="AvnipBW"
                                className="w-[109px] h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Avnip
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Operation Executive
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                It wasn't until I joined Procurement League as
                                an intern two years ago that I realized my true
                                calling. At the time, I knew very little about
                                procurement, but the Procurement League team
                                quickly provided me with a platform to learn and
                                grow.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                Each day, I found myself diving deeper into the
                                procurement world, implementing my newfound
                                knowledge on tasks and projects. The team at
                                Procurement League exposed me to various
                                projects and fields, including research, data
                                management, data mining, spend analysis, etc. I
                                was thrilled to work on these diverse projects,
                                which helped me learn more every day.
                            </p>
                            <p className="text-lg Poppins text-new-color font-bold">
                                I owe much to Procurement League and its
                                incredible team, who have provided me with a
                                platform to showcase my abilities and achieve my
                                career goals.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={JamesBW}
                                alt="JamesBW"
                                className="w-[109px] h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    James
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Procurement and Supplychain Coach
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                I have over 25+ years of experience in the
                                procurement industry, but despite all my
                                knowledge and expertise, I faced rejection due
                                to my age. That's when I came across the
                                Procurement League. This company recognizes the
                                value of experienced professionals like me and
                                provides equal opportunities to everyone,
                                regardless of age or background.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                I joined the Procurement League as a gig
                                contractor, and my life has improved. The
                                company has allowed me to showcase my skills and
                                all the support and guidance needed to excel in
                                my field. One thing I love about the Procurement
                                League is that it's not just a company; it's a
                                community. As a community leader, I can share my
                                knowledge and experience with others, provide
                                advice and solutions, and answer queries. It's a
                                great feeling to be able to help others and make
                                a positive impact on their lives.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                In conclusion, the Procurement League has not
                                only given me a chance to prove myself but has
                                also provided me with the platform to share my
                                knowledge with others. I'm grateful for this
                                fantastic opportunity, and I hope more companies
                                follow in their footsteps by recognizing the
                                value of experienced professionals and providing
                                equal opportunities to everyone.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl h-max xl:-mt-20 lg:-mt-40 shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={KaurBW}
                                alt="KaurBW"
                                className="w-[109px] h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Kaur
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Procurement Expert
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                I am proud to be a partner at Procurement
                                League, a company that shares my passion for
                                innovation and creativity in procurement. I have
                                taken a unique approach to procurement
                                consultancy and helped shape a more progressive
                                way of doing things.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                What sets me apart from others is my creative
                                outlook toward procurement. I always look beyond
                                the numbers and find innovative ways to optimize
                                processes, reduce costs, and improve client
                                outcomes. This unique approach helped me stand
                                out in the industry and also helped me become a
                                role model for many women looking to break into
                                the field.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                As a partner at Procurement League, I have taken
                                it upon myself to mentor and guide other women
                                in the industry, helping them to become their
                                best selves. Also, everyone has the potential to
                                be a great procurement expert.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                I am a partner at Procurement League and a
                                thought leader in the field. I always seek ways
                                to share my knowledge with others through
                                podcast appearances or speaking at industry
                                events. Procurement League always supported me
                                and provided me with the platform to contribute
                                to the community by teaching them how to stand
                                out from the crowd and become thought leaders.
                                Also, helping people see procurement differently
                                and inspiring them to bring their creativity to
                                the table.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl h-max xl:-mt-44 lg:-mt-52 shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={Joanna}
                                alt="Joanna"
                                className="w-[109px] h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Joanna
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Supply Chain Director
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                I am incredibly grateful for the opportunity to
                                work alongside Rajiv Gupta and Hamza Kamal as we
                                led the Procurement League to new heights.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                One of the highlights of my experience was the
                                opportunity to mentor Helen Melian. Watching her
                                grow and develop her skills in procurement was
                                truly rewarding, and I felt privileged to have
                                played a part in her success. Together with the
                                Procurement League team, we tackled various
                                challenges and projects, leveraging our
                                expertise to drive innovation and creativity in
                                the procurement industry. Working with such a
                                dedicated and passionate team made each day a
                                joy, and I felt honored to be part of it all.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                Looking back on my time with Procurement League,
                                it was a transformative experience that pushed
                                me to grow professionally and personally. I'll
                                always be grateful for the opportunities,
                                friendships, and memories that came with it.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl lg:mt-8 h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={HamzaBW}
                                alt="HamzaBW"
                                className="w-[109px] h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Hamza
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Strategic Advisor
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                I'm thrilled to share my journey with you all and hopefully inspire fellow procurement professionals along the way. My name is Hamza, and I've had the privilege of serving as a Procurement Category Manager in the hospital and healthcare industry.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                My background in accounting coupled with my experience in Finance and Sales has provided me with a unique perspective in approaching procurement projects. I've always believed in thinking outside the box and incorporating the entire business spectrum into procurement strategies. This holistic approach has not only led to successful projects but has also garnered appreciation from stakeholders across the board.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                I'm truly grateful for the support and opportunities that the Procurement League has provided me along the way. The resources, networking opportunities, and collective wisdom shared within this community have been invaluable in shaping my success story.
                            </p>
                            <p className="text-lg Poppins text-new-color font-bold">
                                “Procurement - It's a career that demands strategic thinking, adaptability, and a passion for driving change. Working with the Procurement League feels like being part of a dynamic community where collaboration knows no bounds.”
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl lg:mt-8 h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-start">
                            <img
                                src={Zoran}
                                alt="Zoran"
                                className="w-[109px] rounded-full grayscale h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Zoran Kecman
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    General Manager UAE Supply Chain
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                My experience on the Procurement League podcast was nothing short of exceptional. From start to finish, the entire operation was seamless, thanks to the professionalism and dedication of the Procurement League team.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                Not only did we have the pleasure of engaging in a dynamic discussion with James Moran, a seasoned procurement professional, but we also found Procurement League to be an excellent platform for gaining valuable knowledge and expanding our professional network.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                Beyond the podcast, Procurement League has proven to be an invaluable research and media partner, consistently delivering high-quality content and fostering meaningful collaborations. Their commitment to advancing the procurement profession is evident in everything they do, making them a trusted ally in our journey towards success.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl lg:-mt-80 h-max shadow-[0px_4px_40px_#0000001a] relative px-4 py-8">
                        <div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="72"
                                height="54"
                                viewBox="0 0 72 54"
                                fill="none"
                            >
                                <path
                                    d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <div className="lg:flex-row flex-col flex gap-5 items-center">
                            <img
                                src={Gerald}
                                alt="Gerald"
                                className="w-[109px] rounded-full grayscale h-[109px] object-cover"
                            />
                            <div>
                                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color">
                                    Gerald R. Ford
                                </h1>
                                <p className="text-lg Poppins text-new-color font-bold lg:pt-5">
                                    Chief Visionary Officer
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 space-y-5">
                            <p className="text-lg Poppins text-new-color font-normal">
                                At QCsolver Inc., we pride ourselves on sourcing top-notch supply chain documents and summaries. This isn't just a claim—it's a verified fact, backed by meticulous analysis. When I share content from Procurement League on LinkedIn every Wednesday, it consistently garners the highest impressions, likes, comments, and followers within my network. Clearly, their content strikes a chord with a vast audience.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                Both Procurement League and QCsolver Inc. share a common belief in the power of knowledge sharing to drive professional advancement. Our collaborative efforts are truly making an impact, fostering a culture of learning and cooperation within the procurement community.
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal">
                                At QCsolver Inc., we're constantly seeking exceptional content to share, and Procurement League is a steadfast source we can always rely on. Thank you, Procurement League, for your valuable contributions!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-28 container -mb-36">
                <Knowledgepocket isImageHide={false} />
            </div>
        </div>
    );
};

export default SuccessStoryPage;
