import { gql } from "@apollo/client";

export default gql`
    mutation web_forget_password($email: String!) {
        web_forget_password(email: $email){
            message
            status
        }
    }
`;
