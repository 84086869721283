import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BsPlusLg } from "react-icons/bs";

export default function ProfileFaq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
                <p className="Poppins text-lg font-normal text-[#16283A]">My Profile</p>
                <div className="grid grid-cols-12 place-items-center gap-5">
                    <div className="divide-y col-span-12">
                        <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    What payment methods do you accept?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    We accept major credit cards (Visa, MasterCard, American Express) and
                                    PayPal for secure and convenient transactions.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Are there any hidden fees or extra costs?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    No, our pricing is transparent. The course price you see on the landing page
                                    is the final amount you'll pay. There are no hidden fees or additional costs.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Do you offer any discounts for multiple courses?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    Yes, we have special discounts for students who enroll in multiple courses.
                                    Check our bundles and promotions section for more information.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Is there a refund policy if I'm not satisfied with the course?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    Absolutely! We offer a 30-day money-back guarantee. If you find that the
                                    course doesn't meet your expectations, just let us know, and we'll refund
                                    your payment.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel5"}
                            onChange={handleChange("panel5")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Are there any time limitations to accessing the course content?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    No, once you purchase a course, you have lifetime access to the course
                                    materials and any updates we make in the future.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel6"}
                            onChange={handleChange("panel6")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Do you provide certificates upon course completion?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    Yes, upon successful completion of a course, you'll receive a downloadable
                                    and shareable certificate to showcase your achievement.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel7"}
                            onChange={handleChange("panel7")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Can I switch courses after purchase?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    If you cannot switch courses directly, you can contact our support team, and
                                    they will be happy to assist you with making any necessary changes.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel8"}
                            onChange={handleChange("panel8")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    How do I get technical support if I encounter issues during the
                                    course?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    We offer comprehensive technical support through our help center and
                                    dedicated support team. Simply reach out to us, and we'll assist you
                                    promptly.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel9"}
                            onChange={handleChange("panel9")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Can I access the course on mobile devices?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    Yes, we have Procurement League mobile APP, you can download it from Play
                                    store.
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel10"}
                            onChange={handleChange("panel10")}
                            className="p-2 m-0 overflow-hidden !shadow-none"
                        >
                            <AccordionSummary
                                expandIcon={
                                    <BsPlusLg className="primary" size={24} />
                                }
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                    Do you offer corporate or group pricing for businesses and
                                    organizations?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="Poppins text-lg font-normal text-new-color">
                                    Yes, we have corporate and group pricing options available. Contact our
                                    support team to discuss your requirements and receive a customized quote.
                                </p>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
            </div>
        </>
    );
}
