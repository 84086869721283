import { Buttonlink } from "components";
import React, { useEffect, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import noWifi from "assets/lotties/no_wifi.json";
import lottie from "lottie-web";

export default function Noconnection() {
  const _noWifi = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: _noWifi.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: noWifi,
      rendererSettings: {},
    });

    return () => {
      lottie.destroy();
    };
  }, []);
  return (
    <div className="flex flex-col items-center justify-center">
      <div ref={_noWifi} className="lg:h-[172px] h-[82px]" />
      <p className="text-center lg:text-[20px] text-[16px] darkGray">
        No Internet Connection
      </p>

      <p className="text-center gray text-[13px]">
        Could not connected to the network
      </p>
      <p className="text-center gray text-[13px]">
        Please check and try again.
      </p>
      <Buttonlink
        Text="Go to Home"
        Icon={<IoIosArrowBack className="primary" />}
        to="/"
        className="text-[14px] primary hover:primary flex items-center rounded-xl px-4 hover:opacity-80 mt-5"
      />
    </div>
  );
}
