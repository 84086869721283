import { gql } from "@apollo/client";

// if error is true then selected user question is liked by auth user

export default gql`
    query UserLikesThisQuestion($question_id:ID!){
        userLikesThisQuestion(question_id:$question_id){
            message
            error
        }
    }
`;
