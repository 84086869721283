import React, { useState, useEffect } from "react";
import Profilesidebar from "../../../components/profile/profilesidebar";
import { gql, useMutation, useQuery } from "@apollo/client";
import UPDATE_OR_CREATE_COMPANY from "../../../graphql/mutations/createCompanyMutation";
import DELETE_COMPANY from "../../../graphql/mutations/deleteCompany";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { FiChevronDown, FiEdit } from "react-icons/fi";
import { BsNodePlusFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { seo } from "helpers/seo";
import { connect } from "react-redux";
import { Query } from "@apollo/client/react/components";
import { Noconnection, Postskeleton } from "components";
import { extractValidationErrors } from "../../../helpers/extractValidationErrors";

const ProfileCompany = ({ authUser }) => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [linkedInProfileError, setLinkedInProfileError] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [history, setHistory] = useState("");
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const GET_COMPANIES = gql`
    query Auth_User_Info {
      me {
        id
        username
        firstname
        lastname
        is_pro
        is_active
        is_subscribe
        is_authorized
        profile_photo
        tagline
        email
        address
        country
        timezone
        description
        timestamp
        color
        is_follower
        last_login
        companies(first: 10) {
          data {
            id
            title
            address
            city
            state
            country
            history
            postal_code
            linked_in_profile
            is_active
          }
          paginatorInfo {
            total
          }
        }
      }
    }
  `;

  const [updateOrCreateCompany] = useMutation(UPDATE_OR_CREATE_COMPANY);
  const [deleteCompany] = useMutation(DELETE_COMPANY);

  useEffect(() => {
    seo({
      title: "Company Profile | Procurement League ",
    });
  }, []);
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateURL = (url) => {
    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "address") {
      setAddress(value);
      if (value == '') {
        setAddressError("");
        return;
      }
      if (!validateEmail(value)) {
        setAddressError("Invalid email address");
      } else {
        setAddressError("");
      }
    } else if (name === "linkedInProfile") {
      setLinkedInProfile(value);
      if (value == '') {
        setLinkedInProfileError("");
        return;
      }
      if (!validateURL(value)) {
        setLinkedInProfileError("Invalid LinkedIn profile URL");
      } else {
        setLinkedInProfileError("");
      }
    }
  };


  const handleEditorChange = (event) => {
    setHistory(event.target.value);
  };

  const handleSubmit = (id) => {
    setLoading(true);
    if (loading) {
      return null;
    }
    if (title !== "" && address !== "" && linkedInProfile !== "") {
      updateOrCreateCompany({
        variables: {
          company_id: id,
          title: title,
          address: address,
          history: history,
          link: linkedInProfile,
        },
      })
        .then((response) => {
          if (response.data.createCompany) {
            setTitle("");
            setAddress("");
            setHistory("");
            setLinkedInProfile("");
            setUpdated(true);
            setLoading(false);
          }
          notify("Record inserted successfully!!", "success");
        })
        .catch((error) => {
          const validationErrors = extractValidationErrors(error);
          setLoading(false);
          validationErrors.forEach((errorValue) => {
            notify(errorValue, "error");
          });
        });

    } else {
      setLoading(false);
      notify("Please fill in all the required fields", "error");
    }
  };

  const handleDelete = (id) => {
    // Set loading to true when delete button is clicked
    if (loading) {
      return;
    }
    setLoading(true);
    deleteCompany({
      variables: {
        id: id,
      },
    })
      .then((response) => {
        if (response.data.deleteCompany.id != null) {
          setUpdated(true);
          notify("Company Removed Successfully", "success");
        }
        // else {
        //   notify(response.data.deleteCompany.slug, "error");
        // }
      })
      .catch((error) => {
        extractValidationErrors(error);
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the response is received
      });
  };


  const notify = (message = "", type = "error") => {
    const options = { position: "bottom-right" };

    // Determine the toast function based on the type parameter
    const toastFunction = type === "error" ? toast.error : toast.success;

    // Call the appropriate toast function with the message and options
    toastFunction(message, options);
  };


  return (
    <div className="container">
      <ToastContainer />
      <Query query={GET_COMPANIES}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <Postskeleton />;
          }
          if (error) {
            extractValidationErrors(error);
            return <Noconnection />;
          }
          if (updated) {
            refetch();
            setTimeout(() => {
              setUpdated(false);
            });
          }
          if (data) {
            const authUser = data.me;
            return (
              <div className="grid gap-4 lg:grid-cols-7">
                <Profilesidebar authUser={authUser} />
                <div className="lg:col-span-5">
                  <div className="space-y-5">
                    {data.me.companies.data &&
                      data.me.companies.data.length <= 3 ? (
                      <Accordion className="p-0 overflow-hidden important:shadow--sm rounded--xl">
                        <AccordionSummary
                          expandIcon={
                            <FiChevronDown className="ml-5 black" size={20} />
                          }
                          className="hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-x-3">
                            <div className="flex items-center justify-center rounded-full h-9 w-9 bg--primary white">
                              <BsNodePlusFill size={16} />
                            </div>
                            <h4 className="text-lg Medium">Add New Company</h4>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="p-3 space-y-5 border-t">
                            <div>
                              <div className="flex items-center justify-between">
                                <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                  COMPANY NAME
                                </label>
                              </div>
                              <input
                                className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                maxLength={60}
                                value={title}
                                onChange={handleInputChange}
                                name="title"
                              />
                            </div>
                            <div>
                              <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                COMPANY EMAIL
                              </label>
                              <input
                                className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                maxLength={60}
                                name="address"
                                value={address}
                                onChange={handleInputChange}
                                error={!!addressError}
                              />
                              <p className="text-red-500 Regular pt-4">{addressError}</p>
                            </div>
                            <div>
                              <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                COMPANY ADDRESS
                              </label>
                              <input
                                className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                maxLength={80}
                                value={history}
                                onChange={handleEditorChange}
                              />
                            </div>
                            <div>
                              <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                COMPANY LINKEDIN
                              </label>
                              <input
                                className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                maxLength={60}
                                name="linkedInProfile"
                                value={linkedInProfile}
                                onChange={handleInputChange}
                                error={!!linkedInProfileError} // Add error prop to show error state
                                helperText={linkedInProfileError}
                              />
                            </div>
                            <button
                              type="submit"
                              disabled={addressError || linkedInProfileError || loading || !title || !address || !linkedInProfile}
                              className={`${(addressError || linkedInProfileError || loading || !title || !address || !linkedInProfile) ? "opacity-30 cursor-not-allowed" : ""
                                } flex items-center justify-center w-full mx-auto border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-11 rounded--xl lg:w-1/2`}
                              onClick={() => handleSubmit(null)}
                            >
                              Add Company Profile
                            </button>

                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Alert severity="info">
                        You reached the limit and can't add more companies
                      </Alert>
                    )}
                    <div className="flex items-center justify-center gap-x-3">
                      <div className="flex-grow divider" />
                      <span className="fs-13">View Your Existing Company</span>
                      <div className="flex-grow divider" />
                    </div>
                    {data.me?.companies?.data ? (
                      data.me?.companies?.data.map((company) => (
                        <Accordion
                          className="p-0 overflow-hidden important:shadow--sm rounded--xl"
                          key={company.id}
                        >
                          <AccordionSummary
                            expandIcon={
                              <FiChevronDown className="ml-5 black" size={20} />
                            }
                            className="hover:bg-gray-100"
                          >
                            <div className="flex items-center gap-x-3">
                              <div className="flex items-center justify-center rounded-full h-9 w-9 bg--primary white">
                                <FiEdit size={16} />
                              </div>
                              <h4 className="text-lg Medium">{company.title}</h4>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="p-3 space-y-5 border-t">
                              <div>
                                <div className="flex items-center justify-between">
                                  <input
                                    type="hidden"
                                    value={company.id}
                                    name="company_id"
                                  />
                                  <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                    COMPANY NAME
                                  </label>
                                </div>
                                <input
                                  className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                  maxLength={60}
                                  value={company.title}
                                  onChange={handleInputChange}
                                  name="title"
                                  disabled
                                />
                              </div>
                              <div>
                                <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                  COMPANY EMAIL
                                </label>
                                <input
                                  className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                  maxLength={60}
                                  value={company.address}
                                  onChange={handleInputChange}
                                  name="address"
                                  disabled
                                />
                              </div>
                              <div>
                                <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                  COMPANY ADDRESS
                                </label>
                                <input
                                  disabled
                                  maxLength={60}
                                  className="w-full p-3 bg-gray-200 border-0 h-24 Regular rounded-xl"
                                  value={company.history}
                                  onChange={handleInputChange}
                                  name="history"
                                ></input>
                              </div>
                              <div>
                                <label className="lg:ml-1 fs-13 Regular mb-1.5 flex">
                                  COMPANY LINKEDIN
                                </label>
                                <input
                                  disabled
                                  className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                                  maxLength={60}
                                  value={company.linked_in_profile}
                                  onChange={handleInputChange}
                                  name="linkedInProfile"

                                />
                              </div>
                              <button
                                disabled={loading}
                                className={`${loading ? "opacity-30 cursor-not-allowed" : ""
                                  } flex items-center justify-center w-full mx-auto border-0 shadow-none white hover:color-white Regular bg--danger hover:bg--danger focus:bg--danger hover:opacity-70 h-11 rounded--xl lg:w-1/2`}
                                onClick={() => handleDelete(company.id)}

                              >
                                Delete Company Profile
                              </button>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    ) : null}
                  </div>
                </div>
              </div>
            );
          }
        }}
      </Query>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(ProfileCompany);
