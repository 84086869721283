import { combineReducers } from "redux";
import CourseReviewsReducer from "./courseReviewsReducer";
import AuthUserReducer from "./authUserReducer";
import DiscussionSearchReducer from "./DiscussionSearchReducer";
import CourseDiscussionReducer from "./courseReviewsReducer";
import CourseReducer from "./courseReducer";

const rootReducer = combineReducers({
  CourseReviewsReducer,
  AuthUserReducer,
  DiscussionSearchReducer,
  CourseDiscussionReducer,
  CourseReducer
});
export default rootReducer;
