import React, { useState, useEffect } from "react";
import Profilesidebar from "../../components/profile/profilesidebar";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import client from "../../graphql/client";
import UPDATE_USER_PROFILE from "../../graphql/mutations/updateUserMutation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { seo } from "helpers/seo";
import { connect } from "react-redux";
import { AUTH_USER } from "../../config/constants";
import AuthUserAction from "../../store/actions/authUserAction";
import ME from "../../graphql/queries/me";
import { Query } from "@apollo/client/react/components";
import { Noconnection, Pagenotfound, Postskeleton, Profilenavigation } from "components";
import { extractValidationErrors } from "../../helpers/extractValidationErrors";

function Profile(props) {
  const [formData, setFormData] = useState({
    username: props.authUser?.username,
    firstname: props.authUser?.firstname,
    lastname: props.authUser?.lastname,
    tagline: props.authUser?.tagline,
    address: props.authUser?.address,
    description: props.authUser?.description,
    loading: false,
    disabled: true,
    refetchData: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let newObject = localStorage.getItem(AUTH_USER);
    let authUser = JSON.parse(newObject);
    seo({
      title: `${authUser?.firstname} ${authUser?.lastname} | Profile`,
    });
  }, []);

  useEffect(() => {
    if (props.authUser.username !== formData.username) {
      const {
        username,
        firstname,
        lastname,
        address,
        description,
        tagline,
      } = props.authUser;
      setFormData({
        ...formData,
        username,
        firstname,
        lastname,
        address,
        description,
        tagline,
      });
    }
  }, [props.authUser, formData.username]);

  const handleInputChange = (event) => {
    if (event.target.name == 'description') {
      const isNumber = /^\d+$/.test(event.target.value);
      console.log('isNumber', isNumber);
      if (!isNumber) {
        if (event.target.value !== '')
        {
          return;
        }
        
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      disabled: false,
    });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      firstname,
      lastname,
      tagline,
      address,
      description,
      username,
    } = formData;
    setFormData({ ...formData, loading: true });

    client
      .mutate({
        mutation: UPDATE_USER_PROFILE,
        variables: {
          username,
          firstname,
          lastname,
          address,
          tagline,
          description,
        },
      })
      .then((response) => {
        if (response.data.updateUser?.id) {
          AuthUserAction.getAuthUserInformation().then((res) => {
            setFormData({
              ...formData,
              loading: false,
              disabled: true,
              refetchData: true,
            });
            notify("Record updated successfully", "success");
          });
        }
      })
      .catch((error) => {
        extractValidationErrors(error).map((error) => notify(error, "error"));
        setFormData({ ...formData, loading: false });
      });
  };

  const notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    toast.success(message, { position: "bottom-right" });
  };

  return (
    <div className="container">
      <Query query={ME}>
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading) return <Postskeleton />;

          if (error) {
            extractValidationErrors(error).map((err) => {
              notify(err, "error")
            });

            return (
              <div>
                <Noconnection />
              </div>
            );
          }
          if (formData.refetchData) {
            refetch();
            setTimeout(() => {
              setFormData({
                ...formData,
                refetchData: false,
              });
            });
          }
          if (data) {
            let authUser = data.me;
            return (
              <>
                <ToastContainer />
                <div className="grid gap-4 lg:grid-cols-7">
                  <Profilesidebar
                    authUser={props.authUser}
                    reloadData={(v) => {
                      props.handleUpdateAuthUserKeyValue(v)
                      setFormData({ ...formData, refetchData: true })
                    }}
                  />
                  <div className="lg:col-span-5">
                    <form onSubmit={handleOnSubmit}>
                      <div className="p-3 border rounded-xl lg:p-8">
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4">
                          <div className="flex flex-col items-center justify-center p-3 shadow--sm rounded-xl">
                            <p className="primary">Questions</p>
                            <h2 className="text-3xl Medium">
                              {authUser.questions &&
                                authUser.questions.paginatorInfo &&
                                authUser.questions.paginatorInfo.total}
                            </h2>
                          </div>
                          <div className="flex flex-col items-center justify-center p-3 shadow--sm rounded-xl">
                            <p className="primary">Answers</p>
                            <h2 className="text-3xl Medium">
                              {authUser.answers &&
                                authUser.answers.paginatorInfo &&
                                authUser.answers.paginatorInfo.total}
                            </h2>
                          </div>
                          <div className="flex flex-col items-center justify-center p-3 shadow--sm rounded-xl">
                            <p className="primary">Followers</p>
                            <h2 className="text-3xl Medium">
                              {authUser.followers &&
                                authUser.followers.paginatorInfo &&
                                authUser.followers.paginatorInfo.total}
                            </h2>
                          </div>
                          <div className="flex flex-col items-center justify-center p-3 shadow--sm rounded-xl">
                            <p className="primary">Companies</p>
                            <h2 className="text-3xl Medium">
                              {authUser.companies &&
                                authUser.companies.paginatorInfo &&
                                authUser.companies.paginatorInfo.total}
                            </h2>
                          </div>
                        </div>
                        <div className="my-4 divider" />
                        <div className="mb-5">
                          <div className="flex items-center justify-between">
                            <label className="block mb-1 lg:ml-2 Regular">
                              Email
                            </label>
                            <span className="fs-11 primary">
                              Your email is not visible to the community
                            </span>
                          </div>
                          <input
                            placeholder="Email"
                            className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            defaultValue={authUser.email}
                            readOnly
                          />
                        </div>
                        <div className="mb-5">
                          <label className="block mb-1 lg:ml-2 Regular">
                            User Name
                          </label>
                          <input
                            placeholder="First Name"
                            className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            defaultValue={authUser.username}
                            name="username"
                            readOnly
                          />
                        </div>
                        <div className="mb-5">
                          <label className="block mb-1 lg:ml-2 Regular">
                            First Name
                          </label>
                          <input
                            placeholder="First Name"
                            className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            value={formData.firstname}
                            onChange={(event) => handleInputChange(event)}
                            name="firstname"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="block mb-1 lg:ml-2 Regular">
                            Last Name
                          </label>
                          <input
                            placeholder="Last Name"
                            className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            value={formData.lastname}
                            onChange={(event) => handleInputChange(event)}
                            name="lastname"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="block mb-1 lg:ml-2 Regular">
                            Address
                          </label>
                          <input
                            placeholder="Address"
                            className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            value={formData.address}
                            onChange={(event) => handleInputChange(event)}
                            name="address"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="block mb-1 lg:ml-2 Regular">
                            Phone Number
                          </label>
                          <input
                            placeholder="Phone Number"
                            className="w-full px-3 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            maxLength={16}
                            value={formData.description}
                            onChange={(event) => handleInputChange(event)}
                            name="description"
                          />
                        </div>
                        <TextareaAutosize
                          minRows={5}
                          className="w-full p-3 border-2 shadow-none outline-none form-control rounded--xl"
                          value={formData.tagline}
                          onChange={(event) => handleInputChange(event)}
                          name="tagline"
                        />

                        {formData.loading ? (
                          <div className="flex items-center justify-center w-full mt-4 border-0 shadow-none white hover:color-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-11 rounded--xl">
                            <div className="w-5 h-5 border-b-2 rounded-full animate-spin border-white-1000" />
                          </div>
                        ) : (
                          <button
                            disabled={formData.disabled}
                            type={"submit"}
                            className={`${formData.disabled
                              ? "bg--gray hover:bg--gray focus:bg--gray"
                              : "bg--primary hover:bg--primary focus:bg--primary"
                              } flex items-center justify-center white hover:color-white Regular hover:opacity-70 h-11 rounded--xl border-0 shadow-none mt-4 w-full`}>
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </>
            );
          } else {
            return <Pagenotfound />;
          }
        }}
      </Query>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleUpdateAuthUserKeyValue: (value) =>
      dispatch({ type: "UPDATE_AUTH_USER_PROPERTY", payload: { key: "profile_photo", value: value } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
