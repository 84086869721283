import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

export default function ActivationEmail() {
  return (
    <div className="flex items-center justify-center h-screen px-4 py-12 bg-gray-100 sm:px-6 lg:px-8">
      <div className="w-full max-w-xl space-y-8">
        <div className="report-box">
          <div className="px-5 py-20 mt-5 overflow-hidden intro-y box">
            <div className="flex flex-col items-center space-y-5">
              <h1 className="mb-0 text-4xl Medium lg:text-3xl success">
                Thank You
              </h1>
              <p className="text-xl text-center text-gray-500 md:text-xl">
                Your account is created check your email to activate your
                account
              </p>

              <Link
                to={"/"}
                className="flex items-center h-12 px-10 text-yellow-900 bg-gradient-to-r from-yellow-500 to-yellow-400 hover:text-yellow-900 rounded-xl hover:opacity-60">
                <IoIosArrowBack /> Go to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
