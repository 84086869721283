import React, { Component } from "react";
import Slider from "react-slick";
import { BsArrowRight, BsArrowLeft, BsPlayFill } from "react-icons/bs";
import Courses from "assets/images/Courses.webp";
import Discussion from "assets/images/Discussion.webp";
import Group from "assets/images/Group.webp";
import Product from "assets/images/Product.webp";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 top-1/3 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
      onClick={onClick}>
      <BsArrowRight size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 top-1/3 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
      onClick={onClick}>
      <BsArrowLeft size={30} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

class videotips extends Component {
  state = {
    open: false,
    tutorilas: [
      {
        id: 1,
        pathname: Courses,
        src: "https://www.youtube.com/watch?v=CG0enQyaCl4&feature=youtu.be",
        name: "An UnSkilling Initiative For Procurement Professionals",
      },
      {
        id: 2,
        pathname: Discussion,
        src: "https://www.youtube.com/watch?v=FTH0SWjKrKQ&ab_channel=ProcurementLeague",
        name: "How To Initiate A Discussion On Procurement League App",
      },
      {
        id: 3,
        pathname: Group,
        src: "https://www.youtube.com/watch?v=fzMoFvDCTDk&ab_channel=ProcurementLeague",
        name: "How To Create Group on Procurement League App",
      },
      {
        id: 4,
        pathname: Product,
        src: "https://www.youtube.com/watch?v=56hacj5aNXs&ab_channel=ProcurementLeague",
        name: "How To Buy A Single Item on Procurement League App",
      },
    ],
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 624,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };


    return (
      <>
        <div className="relative blog-posts slider-left equal-height">
          <Slider {...settings} className="important:static px-10">
            {this.state.tutorilas.map((tutorial) => (
              <div className="h-full p-5 pt-3" key={tutorial.id}>
                <a
                  target="_blank"
                  alt="Intro"
                  rel="noreferrer"
                  href={tutorial.src}
                  className="block bg-white p-5 h-full transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl">
                  <div className="relative">
                    <img
                      alt={tutorial.name}
                      src={tutorial.pathname}
                      className="object-contain object-top w-full mx-auto h-44 rounded-xl"
                    />
                    <div className="absolute bottom-0 flex items-center justify-center w-full h-full duration-500 ease-in-out cursor-pointer overlay:bg--gray-200 hover:color-white-all hover:bg--primary hover:bg--gray-400 rounded-t-xl">
                      <div className="flex items-center justify-center m-2 bg-white rounded-full cursor-pointer hover:bg--primary h-11 w-11">
                        <BsPlayFill className="-mr-1 black" size={22} />
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-4">
                    <p className="Poppins font-normal primary lg:text-[18px] md:text-[18px] text-[15px]">{tutorial.name}</p>
                  </div>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}

export default videotips;