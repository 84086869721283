import * as React from "react";
import { TiMessages } from "react-icons/ti";
import Buttonlink from "../buttonlink";

export default function getintouch(props) {
  return (
    <Buttonlink
      Text="Get in touch for your requirements"
      Icon={
        <div className="flex items-center justify-center mr-3 -ml-2 bg-white rounded-full shadow-lg lg:h-16 lg:w-16 h-14 w-14 ">
          <TiMessages size={22} className="primary" />
        </div>
      }
      to={{
        pathname: "/contact-us",
        state: { header: props.header },
      }}
      className="transition duration-500 whitespace-nowrap ease-in-out transform hover:-translate-y-1 hover:scale-110 flex items-center text-[16px] shadow-lg justify-center hover:bg--primary bg--primary hover:color-white-all hover:color-white Regular white h-11 pr-5 rounded-[0.75rem] border-0 mt-5"
    />
  );
}
