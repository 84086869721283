import React from "react";
import ProfileFaq from "../profileFaqs";
import TechnicalFaq from "../technicalFaqs";
import ProcureFaq from "../procureFaqs";
import DiscussionFaq from "../discussionFaqs";

export default function faqscategory() {
    return (
        <>
            <div className="space-y-28">
                <ProfileFaq />
                <TechnicalFaq />
                <ProcureFaq />
                {/* <DiscussionFaq /> */}
            </div>
        </>
    );
}
