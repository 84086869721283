import { getTextLength } from "helpers";
import { useState } from "react";
import { Link } from "react-router-dom";

export const Description = ({ question, props }) => {
	const [see, setSee] = useState(getTextLength(question?.question) > 200);
	return (
		<div
			to={{
				pathname: `/discussions/${question.slug}`,
				state: {
					filters: props.selecedFilters,
					topics: props.selecedTopics,
				},
			}}
		>
			<Link
				to={{
					pathname: `/discussions/${question.slug}`,
					state: {
						filters: props.selecedFilters,
						topics: props.selecedTopics,
					},
				}}
				dangerouslySetInnerHTML={{ __html: question?.question }}
				className={`Poppins-all text-new-color font-normal text-lg-all ${
					see && "truncate line-clamp-3"
				}`}
			/>
			{see && (
				<button
                    className="hover:primary-all-text"
					onClick={(e) => {
						e.stopPropagation();
						setSee(false);
					}}
				>
					...see more
				</button>
			)}
		</div>
	);
};
