import { gql } from "@apollo/client";

export default gql`
    mutation DeleteCompany($id:ID!){
        deleteCompany(id:$id){
            id
            slug
        }
    }
`;
