import React, { Component, forwardRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.css";
import { BsImage } from "react-icons/bs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import client from "../../../graphql/client";
import { gql } from "@apollo/client";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import Auth from "../../../Auth";
const auth = new Auth();

class AttachmentsOption extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      files: [],
    };
  }

  _onChange = (event) => {
    // this.setState({
    //   files: event.target.files
    // })
    this.props.parentCallback(event.target.files);
  };

  _uploadImage = (e) => {
    this.fileInput.current.click();
  };

  render() {
    // console.log(this.state.files)
    return (
      <div className="AttachmentsOption toolbar-icon">
        <BsImage className="text-color" onClick={this._uploadImage} />
        <input
          className="hidden opacity-0"
          ref={this.fileInput}
          type="file"
          name="user[image]"
          multiple={true}
          onChange={this._onChange}
        />
      </div>
    );
  }
}

class DraftEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState:
        props.title?.length > 0
          ? EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(props.title))
          )
          : EditorState.createEmpty(),
      suggestions: [],
    };
  }

  onEditorStateChange = (editorState) => {
    const { maxTextLimit, path } = this.props;
    const contentState = editorState.getCurrentContent();
    const contentText = contentState.getPlainText();
    const maxLimit = maxTextLimit || 1500; // Simplified nullish coalescing operator

    this.setState({
      editorState,
    });
    this.props.onChange(draftToHtml(convertToRaw(contentState)));

    if (path === "post_question") {
      this.props.maximumCharacter(contentText.length);
    } else {
      if (contentText.length > maxLimit) {
        const limitedContentState = ContentState.createFromText(
          contentText.substring(0, maxLimit)
        );
        const limitedEditorState = EditorState.push(
          editorState,
          limitedContentState,
          "insert-characters"
        );
        this.setState({
          editorState: limitedEditorState,
        });
      }
    }
  };

  componentDidMount() {
    if (auth.isAuthenticated()) {
      this.getUsers();
    }

  }

  SEARCH_USERS = gql`
    query UserSearch {
      UserSearch(first: 200, orderBy: [{ column: "created_at", order: DESC }]) {
        data {
          username
        }
      }
    }
  `;

  getUsers = () => {
    client
      .query({
        query: this.SEARCH_USERS,
      })
      .then((result) => {
        // console.log("result", result);

        let newArray = [];

        result.data.UserSearch.data.map((user) => {
          newArray.push({
            text: user.username,
            value: user.username,
            url: process.env.REACT_APP_WEB_URL + "/profile/" + user.username,
          });
        });
        this.setState({ suggestions: newArray });
      })
      .catch((error) => {
        extractValidationErrors(error);
        console.log("error", error);
      });
  };

  clearEditor = () => {
    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromText("")
      ),
    });
  };

  setEditorState = (blocks, entityMap) => {
    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.title?.text ? this.props.title?.text : ""),
          entityMap
        )
      ),
    });
  };

  render() {
    const { editorState } = this.state;
    const { path } = this.props;
    return (
      <div className={this.props.className}>
        {path === "question_details" ? (
          <Editor
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            placeholder={this.props.placeholder}
            wrapperClassName="wrapper-class"
            editorClassName="h-72 px-4"
            toolbarClassName="toolbar-class justify-content-center p-2 mb-0 border-left-0 border-right-0 border-top-0 border-bottom-0 w-max"
            toolbar={{
              options: ["inline", "list", "remove"],
              inline: {
                className: "toolbar-icon",
                inDropdown: false,
                options: ["bold", "italic", "underline"],
              },
              list: {
                className: "toolbar-icon",
                inDropdown: false,
                options: ["unordered", "ordered"],
              },
              /*link: {
                  className: "toolbar-icon",
                  popupClassName: "toolbar-popUp",
                  inDropdown: false,
                  link: { className: "toolbar-icon" },
                  unlink: { className: "toolbar-icon" },
                  options: ["link", "unlink"],
                },*/
              remove: { className: "toolbar-icon" },
            }}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: this.state.suggestions,
            }}
          />
        ) : (
          <Editor
            toolbarCustomButtons={[
              // <VideOption {...this.props} />,
              <AttachmentsOption {...this.props} />,
            ]}
            editorState={editorState}
            // onChange={this.props.onChange}
            onEditorStateChange={this.onEditorStateChange}
            placeholder="Type your discussion Description..."
            wrapperClassName="wrapper-class p-0  rounded--xl "
            editorClassName="editor-class pl-3 pr-3"
            toolbarClassName="toolbar-class justify-content-center p-2 mb-0 border-left-0 border-right-0 border-top-0 border-bottom-0"
            toolbar={{
              options: ["inline", "list", "remove"],
              inline: {
                className: "toolbar-icon",
                inDropdown: false,
                options: ["bold", "italic", "underline"],
              },
              list: {
                className: "toolbar-icon",
                inDropdown: false,
                options: ["unordered", "ordered"],
              },
              image: {
                className: "toolbar-icon",
                popupClassName: "toolbar-popUp",
                urlEnabled: false,
                uploadEnabled: true,
                alignmentEnabled: true,
                // uploadCallback: uploadImageCallBack,
                previewImage: true,
                inputAccept:
                  "image/gif,image/jpeg,image/jpeg,image/png,image/svg",
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: "300px",
                  width: "300px",
                },
              },
              // link: {
              //   inDropdown: false,
              //   className: undefined,
              //   component: undefined,
              //   popupClassName: undefined,
              //   dropdownClassName: undefined,
              //   showOpenOptionOnHover: true,
              //   defaultTargetOption: '_self',
              //   options: ['link', 'unlink'],
              //   link: { icon: link, className: undefined },
              //   unlink: { icon: unlink, className: undefined },
              //   linkCallback: undefined
              // }
              /*link: {
                  className: "toolbar-icon",
                  popupClassName: "toolbar-popUp",
                  inDropdown: false,
                  link: { className: "toolbar-icon" },
                  unlink: { className: "toolbar-icon" },
                  options: ["link", "unlink"],
                },*/
              remove: { className: "toolbar-icon" },
            }}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: this.state.suggestions,
            }}
          />
        )}
      </div>
    );
  }
}

export default DraftEditor;
