import React from "react";
import advisory from "../../assets/images/industries/advisory.webp";

const accordionData = [
    {
        id: "panel5",
        title: "Data-Driven Insights",
        content: [
            "Price Benchmarking: From market analysis to competitive pricing strategies",
            "Surveys: From sector-specific questions to valuable industry insights",
            "Data Analysis: From raw data to actionable Biotech & Pharma intelligence",
        ],
    },
    {
        id: "panel7",
        title: "Uncover Your Competitive Edge",
        content: [
            "Competitor Analysis & Benchmarking: From market mapping to strategic positioning",
            "Sales Enablement Documentation: From scientific data to compelling sales content",
            "Brand Strategy and Development: From sector dynamics to resonant brand identity",
        ],
    },
    {
        id: "panel6",
        title: "Navigating Market Dynamics",
        content: [
            "Market Research and Analysis: From Biotech & Pharma trends to risk-mitigated opportunities",
        ],
    },
];

const Advisory = () => {
    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="h-full">
                    <img
                        src={advisory}
                        alt="advisory"
                        className="object-cover h-full w-full"
                    />
                </div>
            </div>
            <div className="lg:col-span-8 md:col-span-6 col-span-12">
                <h1 className="lg:text-5xl text-4xl Poppins font-light text-new-color pb-5">Advisory: Your Biotech Growth Partner</h1>
                <p className="font-normal Poppins text-lg text-new-color mb-8">
                    We simplify success in the competitive Biotech world. Through tailored strategies and innovative marketing, we'll boost your brand and products. <br /> <span className="text-lg font-bold">
                    One team, complete solutions.</span>
                </p>
                <p className="success text-3xl Poppins font-light pb-3">
                    Our Specialized Services
                </p>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                    {accordionData.map((item) => (
                        <div key={item.id}>
                            <p className="success text-3xl Poppins font-light pb-3">
                                {item.title}
                            </p>
                            <ul className="list-disc">
                                {item.content.map((content, index) => (
                                    <li key={index} className="text-lg Poppins font-normal text-new-color">
                                        <span className="text-lg Poppins font-bold text-new-color">
                                            {content.split(":")[0]}:
                                        </span>{" "}
                                        {content.split(":")[1]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Advisory;
