import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import Subtract from "assets/images/Subtract.png";
import Supplier from "assets/images/SubmitOffer/Supplier.png";
import Practitioner from "assets/images/SubmitOffer/Practitioner.png";
import Research from "assets/images/SubmitOffer/Research.png";
import banner from "assets/images/SubmitOffer/submitoffer.webp";
import Search from "assets/images/SubmitOffer/Search.png";
import { Buttonlink, Knowledgepocket, SupplierSubmitPopup } from "components";
import { scrollToContent } from "helpers/ScrollToContent";
import { Link } from "react-router-dom";

const SubmitOfferPage = () => {

  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="pb-28 -mt-[50px] bg--lightBlue">
        <div className="lg:bg-transparent md:bg-transparent bg-black">
          <div className="lg:hidden md:hidden relative">
            <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
          </div>
          <div className="lg:h-[90vh] md:h-[100vh] SubmitOfferBanner relative">
            <div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
              <p className="text-[#FBE4D3] Poppins font-normal">
                Connect. Learn. Thrive Together
              </p>
              <p className="white font-light Poppins lg:text-[48px] md:text-[48px] md:leading-[60px] leading-[36px] text-[30px]">
                Amplify Your Opportunities, Submit Your Offer Today
              </p>
              <p className="white Poppins font-normal">
                Take advantage of our Submit Offer service to showcase your
                expertise and offerings. Whether you're a supplier or a skilled
                practitioner, submitting your offer opens doors to potential
                clients, gig projects, and exciting research collaborations
              </p>
              <div className="h-px bg-white mt-3" />
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                <button
                  onClick={() => scrollToContent("contentSection", 600)}
                  className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                >
                  <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                  <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                    Discover <BsArrowDown size={23} />
                  </span>
                </button>
              </div>
            </div>
            <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
              <img
                src={Subtract}
                alt="Subtract"
                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
              />
            </div>
          </div>
        </div>
        <div className="mt-28 container relative">
          <div className="absolute left-0 -top-20 lg:block hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
            >
              <circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF" />
            </svg>
          </div>
          <div className="absolute right-0 -top-20 lg:block hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="108"
              height="108"
              viewBox="0 0 108 108"
              fill="none"
            >
              <g clipPath="url(#clip0_820_3502)">
                <path
                  d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_820_3502">
                  <rect
                    width="81"
                    height="81"
                    fill="white"
                    transform="translate(0 33.8882) rotate(-24.7319)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5"
            id="contentSection"
          >
            <div className="bg-white relative rounded-3xl px-10 py-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
              <div className="space-y-5">
                <img
                  src={Supplier}
                  alt="Supplier"
                  className="w-[66px] h-[62px]"
                />
                <p className="Poppins font-light text-[30px] leading-6">
                  As a Supplier
                </p>
                <p className="font-normal text-lg Poppins text-new-color">
                  Expand your reach and increase your sales by becoming a
                  trusted supplier on our platform. Submit your offer now and
                  tap into a wide network of potential customers.
                </p>
                <button onClick={()=>setIsOpen(true)} className="font-normal !m-0 !p-0 !text-lg Poppins primary">
                  Submit Your Offer {">>"}
                </button>
              </div>
            </div>
            <div className="bg-white relative rounded-3xl px-10 py-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
              <div className="space-y-5">
                <img
                  src={Practitioner}
                  alt="Practitioner"
                  className="w-[58px] h-[58px]"
                />
                <p className="Poppins font-light text-[30px] leading-6">
                  As a Practitioner
                </p>
                <p className="font-normal text-lg Poppins text-new-color">
                  Leverage our platform to connect with clients, showcase your
                  expertise, and unlock gig projects. Submit your offer now and
                  seize new opportunities.
                </p>
                <Link to={'/contact-us'} className="font-normal text-lg Poppins primary">
                  Join Us {">>"}
                </Link>
              </div>
            </div>
            <div className="bg-white relative rounded-3xl px-10 py-5 mt-16 shadow-[0px_4px_20px_0px_#0000001A]">
              <div className="space-y-5">
                <img
                  src={Research}
                  alt="Research"
                  className="w-[66px] h-[57px]"
                />
                <p className="Poppins font-light text-[30px] leading-6">
                  Join Research
                </p>
                <p className="font-normal text-lg Poppins text-new-color">
                  Collaborate with top researchers, contribute to cutting-edge
                  projects, and shape the future. Submit your interest to join
                  our research community and embark on exciting scientific
                  endeavors.
                </p>
                <Link to={'/join-research'} className="font-normal text-lg Poppins primary">
                  Submit Your Interest to Join Research {">>"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-28 container">
        <div className="bg-white relative custom-shadow rounded-3xl px-10 py-5 w-full lg:max-w-5xl mx-auto">
          <div className="space-y-5 w-full lg:max-w-2xl">
            <p className="font-light text-3xl primary Poppins">
              Hire Experts: Join Our Team for Gig Projects
            </p>
            <p className="font-bold text-new-color text-lg text-new-color Poppins">
              Are you a skilled professional looking for exciting gig projects
              in the procurement industry?
            </p>
            <p className="font-normal text-lg Poppins text-new-color">
              Join our team of experts and collaborate with us on a range of
              engaging projects. Showcase your expertise and contribute to
              meaningful initiatives with industry leaders. Enjoy the
              flexibility of gig-based work while leveraging our platform for
              project opportunities.
            </p>
            <Buttonlink
              to={"/hire-expert"}
              Text={"Meet Some Our Experts"}
              Lasticon={<BsArrowDown size={23} />}
              className="py-2 lg:w-80 whitespace-nowrap text-lg font-normal hover:opacity-80 bg--primary self-start Poppins !hover:text-white !text-white px-5 rounded-full flex justify-between items-center"
            />
          </div>
          <div className="h-[317px] lg:block hidden w-[250px] absolute -right-16 top-1/3">
            <img src={Search} alt="Search" className="w-full h-full" />
          </div>
        </div>
      </div>
      <div className="lg:mt-44 mt-28 container -mb-36">
        <Knowledgepocket isImageHide={true} />
      </div>
      <SupplierSubmitPopup isOpen={isOpen} closePopup={()=>setIsOpen(false)} />
    </>
  );
};

export default SubmitOfferPage;
