import React, { useState } from "react";
import { BsPlayFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import encryptfunction from "../../../helpers/Encrypt";
import { Signinpopup } from "components";
import noPhoto from "assets/images/no-photo.jpg"


const ClassCard = ({ _class, type }) => {
    const [openModal, setOpenModal] = useState(false);
    const handleError = (event) => {
        event.target.src = noPhoto;
    };
    
    return (
        <>
            <Link
                to={{
                    pathname: `${
                        type === "upcoming" || type === "expired"
                            ? "/event/"
                            : type === "item_based"
                                ? "/product/"
                                : "/classes/"
                    }${encryptfunction(_class.id)}/${_class.course_slug}`,
                    state: { item: _class, type: type },
                }}
                className="block bg-white p-5 h-full transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl"
            >
                <div className="relative">
                    <img
                        src={_class?.course_cover_pic}
                        onError={handleError}
                        className="object-fill w-full mx-auto h-44 rounded-t-xl"
                        alt="Class Cover"
                    />
                    <div className="absolute bottom-0 flex items-center justify-center w-full h-full group duration-500 ease-in-out cursor-pointer overlay:bg--gray-200 all hover:bg-orange hover:bg-opacity-70 rounded-t-xl">
                        <div className="flex items-center justify-center m-2 bg-white hover:bg--primary rounded-full cursor-pointer h-11 w-11">
                            <BsPlayFill className="-mr-1 black" size={22} />
                        </div>
                    </div>
                </div>
                <div className="px-3 py-4">
                    {type !== "" && (
                        <div className="flex flex-wrap gap-2 mb-3">
                            {_class?.categories?.data?.map((category, index) => (
                                <div
                                    key={index}
                                    className="px-3 py-1 bg-gray-200 rounded-full"
                                >
                                    <p className="fs-11 Poppins">{category.name}</p>
                                </div>
                            ))}
                        </div>
                    )}
                    <h4 className="mb-1 primary Poppins fs-20 font-normal">{_class.course_name}</h4>
                    <div className="flex items-center justify-between">
                        {type === "item_based" && (
                            <p className="z-10 flex items-center text-black Poppins fs-14">
                                ${_class.course_objective}
                            </p>
                        )}
                    </div>
                </div>
            </Link>
            {openModal && (
                <Signinpopup
                    open={openModal}
                    closePopup={() => setOpenModal(false)}
                    protected={false}
                />
            )}
        </>
    );
};

export default ClassCard;
