import { gql } from "@apollo/client";

export default gql`
    query newsletters($first: Int!, $orderBy: [OrderByClause!]) {
        newsletters(first: $first, orderBy: $orderBy) {
            data {
                id
                category_id
                title
                slug
                description
                thumbnail
                created_at
                category{
                    id
                    name
                }
            }
        }
    }
`;
