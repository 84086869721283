import { Buttonlink, IsLoader, Noconnection, Socialshare } from "components";
import React, { useEffect } from "react";
import { IoIosArrowBack, IoMdShareAlt } from "react-icons/io";
import { BsArrowLeft, BsArrowRight, BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import { useState } from "react";
import encryptfunction from "../../helpers/Encrypt";
import convertToSlug from "../../helpers/convertToSlug";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import ACTION_PRODUCT from "../../graphql/SgQueries/SgProductActionsMutation";
import { toast, ToastContainer } from "react-toastify";
import GET_PRODUCT_BY_ID from "../../graphql/SgQueries/SgProductById";
import { Query } from "@apollo/client/react/components";
import heart from "assets/images/heart.png";
import heartColor from "assets/images/heartColor.png";
import DialogBox from "./DialogBox";
import Auth from "../../Auth";
import Slider from "react-slick";
const auth = new Auth();

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-0 top-[40%] z-10 flex items-center justify-center cursor-pointer slick-arrows next"
      onClick={onClick}>
      <BsArrowRight size={30}  strokeWidth={1.2}className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute z-10 top-[40%] left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
      onClick={onClick}>
      <BsArrowLeft size={30} strokeWidth={1.2} className={onClick !== null ? 'primary' : 'gray'} />
    </div>
  );
}

function SellerProductDetail(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notify = (message = "", type = "error") => {
    if (type === "error") {
      return toast.error(message, { position: "bottom-right" });
    }
    toast.success(message, { position: "bottom-right" });
  };

  const [shareBtn, setShareBtn] = useState(false);
  const [disbled, setdisbled] = useState(false);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  // console.log("props", props.history)
  // let product = props.history.location.state.product;
  // console.log("product", product)
  // console.log("props.id", props.id)

  /*    const updateCache = (cache, {data}) => {
        const existingData = cache.readQuery({
            query: GET_PRODUCT_BY_ID,
            variables: {id: props?.id}
        });
        console.log("existingData",existingData)
        // const newData = data.productActions;
        // cache.writeQuery({
        //     query: GET_PRODUCT_BY_ID,
        //     variables: {id: props?.id},
        //     data: {sgProductByID: {productActions: [newData, ...existingData.sgProductByID.productActions]}}
        // });
    };*/

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  
  const [ProductAction] = useMutation(ACTION_PRODUCT, {
    // update: updateCache,
    onCompleted(data) {
      // console.log("useMutationnnn", data);
    },
  });

  let variables = { id: props.id };
  return (
    <div className="container">
      <ToastContainer />
      <Buttonlink
        Text="back"
        Icon={<IoIosArrowBack />}
        onClick={() =>
          props?.history ? props.history.goBack() : props.history.go("/sellers")
        }
        className="flex items-center justify-center px-4 mb-3 rounded-full Regular bg--lightGray h-9 darkGray w-28 hover:darkGray hover:opacity-80"
      />
      <Query
        query={GET_PRODUCT_BY_ID}
        variables={variables}
        fetchPolicy={"cache-and-network"}
        id={2}
      >
        {({ loading, error, data, fetchMore, refetch }) => {
          if (loading)
            return (
              <div className="h-[80vh] flex items-center justify-center">
                <IsLoader className="w-32 h-32 mx-auto " />
              </div>
            );
          if (error) {
            return (
              <div>
                <Noconnection />
              </div>
            );
          }
          if (disbled) {
            refetch();
            setdisbled(false);
          }
          if (data) {
            // let user = data.sgUser;
            let product = data?.sgProductByID;
            // console.log("product", product)
            return (
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-7">
                <div className="mt-5 lg:col-span-3 md:col-span-2">
                  <div className="p-5 rounded-lg">
                    {
                      product?.attachments?.length === 0 ?
                      product?.type === "mp4" ||
                      product?.type === "mov" ||
                      product?.type === "webm" ||
                      product?.type === "avi" ||
                      product?.type === "mpeg4" ? (
                      <video
                        width="750"
                        height="700"
                        className="h-[300px] object-contain"
                        controls
                        controlsList="nodownload"
                      >
                        <source
                          src={process.env.REACT_APP_MRO_URL + "/" + product.attachment}
                          type={"video/" + product?.type}
                        />
                      </video>
                      ) : (
                        <div>
                          <img
                            alt="Knowledge"
                            src={process.env.REACT_APP_MRO_URL + "/" + product.attachment}
                            className="h-[300px] w-[750px] object-contain"
                          />
                        </div>
                        ) :
                        <Slider {...settings} className="relative mt-6 lg:px-10 px-[14px]">
                          {product?.attachments?.map((attachment,index) => (
                             attachment?.type === "mp4" ||
                             attachment?.type === "mov" ||
                             attachment?.type === "webm" ||
                             attachment?.type === "avi" ||
                             attachment?.type === "mpeg4" ? (
                             <video
                               width="750"
                               height="700"
                               key={index}
                               className="h-[300px] object-contain"
                               controls
                               controlsList="nodownload"
                             >
                               <source
                                 src={process.env.REACT_APP_MRO_URL + "/" + attachment.path}
                                 type={"video/" + attachment?.type}
                               />
                             </video>
                             ) : (
                               <div key={index}>
                                 <img
                                   alt="Knowledge"
                                   src={process.env.REACT_APP_MRO_URL + "/" + attachment.path}
                                   className="h-[300px] w-[750px] object-contain"
                                 />
                               </div>
                               )
                          ))}
                        </Slider>
                    }
                    {/*
                  <img
                    src={MRO_URL + "/" + product.attachment}
                    className="h-[300px] object-contain"
                    alt={product.name}
                  /> */}
                    {
                      auth.isAuthenticated() ?
                        product?.user?.email !== props.authUser?.email &&
                        <Buttonlink
                          href={product.in_stock ? `${process.env.REACT_APP_SOURCING_GENIE}/auto-login/${props.authUser?.cross_token}/buyer/${props.authUser.sg_badge ? 1 : 0}` : true}
                          Text="Post RFQ"
                          className={`${product.in_stock ? "hover:bg--primary focus:bg--primary bg--primary" : "hover:bg--gray focus:bg--gray bg--gray"} hover:text-white  flex items-center justify-center w-28 mx-auto mt-10 border-0 rounded-lg shadow-none  white hover:white  hover:opacity-70 h-11 Regular`}
                        /> :
                        <Buttonlink
                          to={"/login"}
                          Text="Post RFQ"
                          className="hover:bg--primary hover:text-white focus:bg--primary bg--primary flex items-center justify-center w-28 mx-auto mt-10 border-0 rounded-lg shadow-none  white hover:white  hover:opacity-70 h-11 Regular"
                        />
                    }
                  </div>
                </div>
                <div className="space-y-5 lg:col-span-4 md:col-span-3">
                  <h1 className="text-2xl capitalize Medium important:leading-tight">
                    {product?.name}
                  </h1>
                  <p className="text-sm darkGray">{product?.description}</p>
                  <div className="flex flex-wrap items-center gap-2 p-5">
                    {product?.categories.map((category) => {
                      return (
                        <div key={category?.id}>
                          <div className="inline-block px-5 py-2 bg-gray-200 rounded-lg hover:opacity-90 intro-x">
                            <p className="fs-13">{category?.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-around mt-3">
                    <div className="flex items-center cursor-pointer gap-x-1 ">
                      {product?.product_actions?.length !== 0 &&
                        product?.product_actions.filter(
                          (o) => o.user_id === parseInt(props.authUser?.id)
                        ).length > 0 ? (
                        product?.product_actions
                          .filter(
                            (o) => o.user_id === parseInt(props?.authUser?.id)
                          )
                          .map((action) => {
                            return action.liked ? (
                              <img
                                alt="likes"
                                src={heartColor}
                                className="object-contain w-5 h-5 lg:h-6 lg:w-6"
                                onClick={() => {
                                  ProductAction({
                                    variables: {
                                      id: action.id,
                                      product_id: product?.id,
                                      liked: false,
                                    },
                                  });
                                }}
                              />
                            ) : (
                              <img
                                alt="likes"
                                src={heart}
                                className="object-contain w-5 h-5 lg:h-6 lg:w-6"
                                onClick={() => {
                                  ProductAction({
                                    variables: {
                                      id: action.id,
                                      product_id: product?.id,
                                      liked: true,
                                    },
                                  });
                                }}
                              />
                            );
                          })
                      ) : (
                        <img
                          alt="likes"
                          src={heart}
                          className="object-contain w-5 h-5 lg:h-6 lg:w-6"
                          onClick={() => {
                            ProductAction({
                              variables: {
                                product_id: product?.id,
                                liked: true,
                              },
                            });
                            disbled(true);
                          }}
                        />
                      )}
                      <p className="gray fs-13">
                        {product?.product_actions[0]?.product_like ?? 0}
                      </p>
                    </div>

                    <div className="flex items-center justify-center cursor-pointer gap-x-1 ">
                      {product?.product_actions.length !== 0 &&
                        product?.product_actions.filter(
                          (o) => o.user_id === parseInt(props?.authUser?.id)
                        ).length > 0 ? (
                        product.product_actions
                          .filter(
                            (o) => o.user_id === parseInt(props?.authUser?.id)
                          )
                          .map((action) => {
                            return action.book_mark ? (
                              <BsFillBookmarkFill
                                className="w-4 h-4 cursor-pointer lg:h-5 lg:w-5 primary"
                                size={17}
                                onClick={() =>
                                  ProductAction({
                                    variables: {
                                      id: action.id,
                                      product_id: product?.id,
                                      book_mark: false,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <BsBookmark
                                className="w-4 h-4 cursor-pointer gray lg:h-5 lg:w-5  hover:text-[#f58020]"
                                onClick={() =>
                                  ProductAction({
                                    variables: {
                                      id: action?.id,
                                      product_id: product?.id,
                                      book_mark: true,
                                    },
                                  })
                                }
                              />
                            );
                          })
                      ) : (
                        <BsBookmark
                          className="w-4 h-4 cursor-pointer gray lg:h-5 lg:w-5 hover:text-[#f58020]"
                          size={17}
                          onClick={() => {
                            ProductAction({
                              variables: {
                                product_id: product?.id,
                                book_mark: true,
                              },
                            });
                          }}
                        />
                      )}
                    </div>

                    <div className="flex items-center justify-end">
                      {product.in_stock ? (
                        <span className="text-green-500 Regular">In Stock</span>
                      ) : (
                        <span className="text-red-500 rounded Regular">
                          Out of Stock
                        </span>
                      )}
                    </div>
                    <div className="flex items-center justify-center cursor-pointer">
                      {/* <BsShare
                        size={18}
                        className="hover:text-[#f58020]"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${WEB_URL}/seller/${encryptfunction(
                              product.id
                            )}/product/${convertToSlug(product.name)}`
                          );
                          notify("Link Copied", "success");
                        }}
                      /> */}
                      <Buttonlink
                        onClick={() => setShareBtn(!shareBtn)}
                        className={`bg--lightGray hover:bg--lightGray focus:bg--lightGray fs-12 Regular flex items-center justify-center h-9 rounded--full darkGray hover:darkGray hover:opacity-80 border-0 shadow-none
                        ${shareBtn ? "w-28" : "w-28"} `}
                        Text="Share"
                        Lasticon={
                          <IoMdShareAlt className="ml-2 darkGray" size={15} />
                        }
                      />
                    </div>
                  </div>
                  {/*<div className="flex -space-x-1 overflow-hidden">
                        <div className="flex items-center mr-4 cursor-pointer gap-x-1">
                            <BsSuitHeartFill className="primary" size={20}/>
                            <p className="text-[14px] primary">22</p>
                        </div>
                        <img
                            className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
                            src={Hamza}
                            alt="Hamza"
                        />
                    </div>*/}

                  {shareBtn && (
                    <div className="my-3 ">
                      <Socialshare
                        size={30}
                        url={new URL(`${process.env.REACT_APP_WEB_URL}/seller/${encryptfunction(product.id)}/product/${convertToSlug(product.name)}`)}
                        title={product.name}
                        subject={product.name}
                        quote={product.name}
                        source={process.env.REACT_APP_WEB_URL}
                        via={process.env.REACT_APP_WEB_URL}
                        summary={{
                          __html: product.description,
                        }}
                        hashtags={[product.name, "Sourcing_Genie"]}
                      />{" "}
                    </div>
                  )}
                </div>
                <DialogBox show={open} close={onClose} />
              </div>
            );
          }
        }}
      </Query>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});
export default connect(mapStateToProps, null)(SellerProductDetail);
