import { gql } from "@apollo/client";

export default gql`
    mutation CreateAnswerComment($question_id:Int!,$answer_id:Int!,$reply:String!){
        createAnswerComment(
            question_id:$question_id,
            answer_id:$answer_id
            reply:$reply,
        ){
            id
            comment
            questions{
                id
                user_id
            }
        }
    }
`;
