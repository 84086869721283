export const GET_OPEN_COURSE_REVIEWS = "GET_OPEN_COURSE_REVIEWS";
export const GET_AUTH_USER_INFORMATION = "GET_AUTH_USER_INFORMATION";
export const SELECTED_TAGS = "SELECTED_TAGS";
export const TYPE_EMAIL = "TYPE_EMAIL";
export const REMOVE_TAGS = "REMOVE_TAGS";
export const GET_AUTH_USER_SOCKET = "GET_AUTH_USER_SOCKET";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const SELECTED_USER = "SELECTED_USER";
export const TYPE_SEARCH = "TYPE_SEARCH";
export const COURSE_DISCUSSION = "COURSE_DISCUSSION";
export const COURSE_RECALL = "COURSE_RECALL";
export const GET_SINGLE_CLASS_INFORMATION = "GET_SINGLE_CLASS_INFORMATION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const REMOVE_QUESTION_ANSWER = "REMOVE_QUESTION_ANSWER";
export const REMOVE_QUESTION_ANSWER_COMMENT = "REMOVE_QUESTION_ANSWER_COMMENT";
export const AUTH_USER_TOKEN = "AUTH_USER_TOKEN";
export const UPDATE_AUTH_USER_PROPERTY = "UPDATE_AUTH_USER_PROPERTY ";
