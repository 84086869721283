import React, { useState, useEffect, useRef } from "react";
import { Buttonlink, Signinpopup } from "components";
import "./styles.css";
import client from "../../../../graphql/client";
import ADD_DISCUSSION from "../../../../graphql/mutations/addToDiscussionMutation";
import Becomeapropopup from "components/allcomponents/becomeapropopup";
import EnrollPopup from "components/allcomponents/enrollPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { IoSaveOutline, IoTrashOutline } from "react-icons/io5";
import { connect } from "react-redux";
import Auth from "../../../../Auth";
import { gql } from "@apollo/client";

const auth = new Auth();

const UPDATE_DISCUSSION_MUTATION = gql`
    mutation course_decision_update(
        $id:ID!
        $message:String!
        $is_active:Boolean
    ){
        course_decision_update(
            id: $id
            message:$message
            is_active:$is_active
        ){
            id
            course_id
            user_id
            message
            is_author
            user{
                id
            }
            course{
                id
            }
        }
    }
`;

const DELETE_DISCUSSION_MUTATION = gql`
    mutation course_decision_delete($id:ID!){
        course_decision_delete(id:$id){
            id
            course_id
            user_id
            message
            is_author
            user{
                id
            }
            course{
                id
            }
        }
    }`;

function PublicDiscussion(props) {
    const [textMsg, setTextMsg] = useState("");
    const [loading, setLoading] = useState(props.loading);
    const [becomePro, setBecomePro] = useState(false);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [editing, setEditing] = useState(0);
    const inputRef = useRef(null);

    useEffect(() => {
        if (textMsg.length > 1 && disabled) {
            setDisabled(false);
        }
    }, [textMsg, disabled]);

    const handleAddDiscussion = () => {
        const { course, authUser } = props;

        let author = false;
        author = authUser.id === course.users.data[0].id;

        if (textMsg === "") {
            setDisabled(true);
            // this.notify("Type the message first.", "error");
        } else {
            setLoading(true);
            setDisabled(true);

            client
                .mutate({
                    mutation: ADD_DISCUSSION,
                    variables: {
                        user_id: parseInt(authUser.id),
                        course_id: parseInt(course.id),
                        message: textMsg,
                        is_author: author,
                    },
                })
                .then((res) => {
                    // console.log("res", res);
                    setLoading(false);
                    setTextMsg("");
                    setDisabled(true);
                    props.handleClassReset(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setDisabled(false);
                });
        }
    };

    const handleDeleteDiscussion = (discussionObject) => {
        // console.log("discussionObject", discussionObject)
        client
            .mutate({
                mutation: DELETE_DISCUSSION_MUTATION, // Replace with the actual mutation definition
                variables: {
                    id: discussionObject.id,
                },
                optimisticResponse: (vars) => {
                    return {
                        id: discussionObject.id,
                        __typename: "CourseDecisionQuestion",
                    };
                },
                update: (cache, result) => {
                    // console.log(result);
                    const deletedCourseDecisionQuestion = cache.identify(
                        result.data.course_decision_delete
                    );
                    // console.log("deletedQuestion", deletedQuestion);
                    cache.modify({
                        fields: {
                            CourseDecision: (existingQuestionRefs) => {
                                // console.log("existingQuestionRefs", existingQuestionRefs);
                                return existingQuestionRefs?.data?.filter((questionRef) => {
                                    return cache.identify(questionRef) !== deletedCourseDecisionQuestion;
                                });
                            },
                        },
                    });
                    cache.evict({ id: deletedCourseDecisionQuestion });
                },
            })
            .then((res) => {
                // console.log("Discussion deleted:", res);
                // Perform any additional actions after successful deletion
            })
            .catch((error) => {
                console.error("Error deleting discussion:", error);
            });
    }

    const handleEditDiscussion = (discussionObject) => {
        // console.log("discussionObject", discussionObject)
        setEditing(discussionObject.id);
        setTextMsg(discussionObject.message); // Set the message in the input field
        inputRef.current.focus(); // Focus the input field
    }

    const saveEditedDiscussion = () => {
        client
            .mutate({
                mutation: UPDATE_DISCUSSION_MUTATION, // Replace with the actual mutation definition
                variables: {
                    id: editing,
                    message: textMsg,
                },
            })
            .then((res) => {
                setEditing(0);
                setTextMsg("")
                // console.log("Discussion updated:", res);
                // Perform any additional actions after successful update
            })
            .catch((error) => {
                console.error("Error updating discussion:", error);
            });
    }

    const notify = (message = "", type = "error") => {
        if (type === "error") {
            return toast.error(message, { position: "bottom-right" });
        }
        toast.success(message, { position: "bottom-right" });
    };

    const { course, type, authUser } = props;
    return (
        <div className="">
            <ToastContainer />
            <h4 className="Medium text-[16px] mb-3">Discussion</h4>
            <div className="relative p-3 border rounded-xl">
                <p className="mb-2 Light gray fs-14">
                    Welcome to the discussion room!
                </p>
                {!auth.isAuthenticated() ? (
                    <div
                        className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full border cursor-pointer overlay:bg--gray-200 rounded-xl">
                        <span
                            onClick={() => setOpenModal(true)}
                            className="flex items-center px-5 py-3 border-0 rounded-md shadow-none h-11 bg--primary hover:text-white hover:bg-primary focus:bg-primary Regular hover:opacity-90 white ">
                            Please Login
                        </span>
                    </div>
                ) : auth.isAuthenticated() && type === "" ? null : authUser.is_pro &&
                    !course.is_enroll ? (
                    <div
                        className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full border cursor-pointer overlay:bg--gray-200 rounded-xl">
                        <span
                            onClick={() => window.scrollTo(0, 10)}
                            className="flex items-center px-5 py-3 border-0 rounded-md shadow-none h-11 bg--primary hover:text-white hover:bg-primary focus:bg-primary Regular hover:opacity-90 white ">
                            {type === "item_based" ? "Purchase first" : "Enroll first"}
                        </span>
                    </div>
                ) : course.is_enroll ? null : (
                    <div
                        className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full border overlay:bg--gray-200 rounded-xl">
                        <Link
                            to="/pricing"
                            className="flex items-center px-5 py-3 border-0 rounded-md shadow-none h-11 bg--primary hover:text-white hover:bg-primary focus:bg-primary Regular hover:opacity-90 white ">
                            Become a pro
                        </Link>
                    </div>
                )}

                <div
                    className={
                        type === "item_based" || type === "" || (authUser.is_pro && course.is_enroll) ? "" : "discussion-blur"
                        /*? course.is_enroll
                          ? ""
                          : "discussion-blur"
                        : type === ""
                        ? ""
                        : authUser.is_pro
                        ? course.is_enroll
                          ? ""
                          : "discussion-blur"
                        : "discussion-blur"*/
                    }>
                    <div className="overflow-auto h-97" id="scrollbar">
                        {course.course_decision.map((discussion) =>
                            discussion.is_author ? (
                                <div
                                    className="flex justify-between items-start mb-2"
                                    key={discussion.id}>
                                    <p className="Light fs-14">
                                        <p className="inline mr-1 Medium primary">
                                            <div className="inline-block w-2 h-2 mr-1 rounded-full bg--primary" />
                                            {`${discussion.user.firstname} ${discussion.user.lastname}:`}
                                        </p>
                                        {discussion.message}
                                    </p>
                                    {
                                        authUser.id === discussion.user.id && discussion.is_author &&
                                        <div className={"flex gap-2 items-center"}>
                                            <FaEdit className={'primary cursor-pointer'} size={15}
                                                onClick={() => handleEditDiscussion(discussion)} />
                                            <IoTrashOutline className={'danger cursor-pointer'} size={15}
                                                onClick={() => handleDeleteDiscussion(discussion)} />
                                        </div>
                                    }
                                </div>
                            ) : (
                                <div
                                    className="flex justify-between items-start mb-2"
                                    key={discussion.id}>
                                    <p className="Light fs-14">
                                        <p className="inline mr-1 Medium">{`${discussion.user.firstname} ${discussion.user.lastname}:`}</p>
                                        {discussion.message}
                                    </p>
                                    {authUser?.id === discussion.user.id ? (
                                        <div className="flex gap-2 items-center">
                                            <FaEdit
                                                className="primary cursor-pointer"
                                                size={15}
                                                onClick={() => handleEditDiscussion(discussion)}
                                            />
                                            <IoTrashOutline
                                                className="danger cursor-pointer"
                                                size={15}
                                                onClick={() => handleDeleteDiscussion(discussion)}
                                            />
                                        </div>
                                    ) : course?.users?.data.some((o) => o.id === authUser.id) ? (
                                        <IoTrashOutline
                                            className="danger cursor-pointer"
                                            size={15}
                                            onClick={() => handleDeleteDiscussion(discussion)}
                                        />
                                    ) : null}
                                </div>
                            )
                        )}
                    </div>
                    <div className="flex items-center pt-2">
                        <input
                            ref={inputRef}
                            placeholder="Send a message"
                            className="w-full px-3 mr-2 bg-gray-200 border-0 h-11 Regular rounded-xl"
                            maxLength={150}
                            value={textMsg}
                            onChange={(e) => setTextMsg(e.target.value)}
                        />
                        <Buttonlink
                            disabled={disabled}
                            onClick={() => {
                                editing ? saveEditedDiscussion() :
                                    type === ""
                                        ? handleAddDiscussion()
                                        : type === "item_based"
                                            ? course.is_enroll
                                                ? handleAddDiscussion()
                                                : setBecomePro(true)
                                            : authUser.is_pro
                                                ? course.is_enroll
                                                    ? handleAddDiscussion()
                                                    : setIsEnrolled(true)
                                                : setBecomePro(true);
                            }}
                            Text={loading ? "Sending..." : "Chat"}
                            className={`${disabled
                                ? "bg--gray hover:bg--gray"
                                : "bg--primary hover:bg--primary focus:bg--primary"
                                } h-11  white px-5  Regular hover:opacity-70 rounded--xl border-0 shadow-none`}
                        />
                    </div>
                    <span className="text-gray-400">{textMsg.length} / 150</span>
                </div>
            </div>
            <Becomeapropopup
                item={course}
                type={type}
                show={becomePro}
                onHide={() => setBecomePro(false)}
            />
            <EnrollPopup
                show={isEnrolled}
                onHide={() => setIsEnrolled(false)}
            />
            {openModal && (
                <Signinpopup
                    open={openModal}
                    closePopup={() => setOpenModal(false)}
                />
            )}
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleClassReset: (data) =>
            dispatch({ type: "COURSE_RECALL", payload: data }),
    };
};

export default connect(null, mapDispatchToProps)(PublicDiscussion);
