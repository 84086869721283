import React, { useEffect } from "react";
import SubtractWhite from "../../assets/images/Subtract-white.png";
import banner from "../../assets/images/trainers/banner.webp";
import { Knowledgepocket, Loader, Noconnection } from "components";
import { Query } from "@apollo/client/react/components";
import our_trainers from "../../graphql/queries/ourTrainers";

const Trainners = () => {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="-mt-[50px]">
				<div className="lg:bg-transparent md:bg-transparent bg-black">
					<div className="lg:hidden md:hidden relative">
						<img
							src={banner}
							alt="banner"
							className="object-cover w-full h-full object-center"
						/>
					</div>
					<div className="lg:h-[90vh] md:h-[100vh] TrainnersBanner relative">
						<div className="flex w-full z-10 max-w-3xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
							<p className="text-[#FBE4D3] Poppins font-normal">
								Procurement League Trainers
							</p>
							<p className="white font-light Poppins lg:text-[48px] md:text-[48px] text-[30px] leading-[55px]">
								Meet our experts : Procurement And Supply Chain Trainers
							</p>
							<div className="h-px bg-white my-3" />
							<p className="white Poppins font-normal">
								Empowering Your Team with Specialized Knowledge and Expertise
							</p>
						</div>
						<div className="bottom-0 z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
							<img
								src={SubtractWhite}
								alt="SubtractWhite"
								className="object-cover w-full left-1/2 -translate-x-1/2 relative"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="container mt-28 relative">
				<div className="absolute lg:block hidden right-0 -top-28">
					<svg
						width="40"
						height="40"
						viewBox="0 0 112 112"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_30_843)">
							<path
								d="M47.0543 15.3363L44.6894 42.6202L17.4055 40.2553C10.5845 39.6641 5.02539 44.3364 4.43417 51.1574C3.84295 57.9784 8.51526 63.5375 15.3362 64.1287L42.6201 66.4936L40.2552 93.7775C39.664 100.598 44.3363 106.158 51.1573 106.749C57.9783 107.34 63.5374 102.668 64.1287 95.8468L66.4936 68.5629L93.7775 70.9278C100.598 71.519 106.158 66.8467 106.749 60.0257C107.34 53.2047 102.668 47.6456 95.8467 47.0544L68.5628 44.6895L70.9277 17.4056C71.519 10.5846 66.8466 5.02546 60.0257 4.43423C53.2047 3.84301 47.6455 8.51532 47.0543 15.3363Z"
								fill="#B5E2FF"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_30_843">
								<rect
									width="102.698"
									height="102.698"
									fill="white"
									transform="translate(0 102.315) rotate(-85.0461)"
								></rect>
							</clipPath>
						</defs>
					</svg>
				</div>
				<div className="absolute lg:block hidden right-20 -top-20">
					<svg
						width="39"
						height="39"
						viewBox="0 0 39 39"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="19.5" cy="19.5" r="19.5" fill="#B5E2FF"></circle>
					</svg>
				</div>
				<div className="absolute lg:block hidden left-[20%] -top-32">
					<svg
						width="108"
						height="108"
						viewBox="0 0 108 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_2_811)">
							<path
								d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
								fill="#FFEFE5"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_2_811">
								<rect
									width="81"
									height="81"
									fill="white"
									transform="translate(0 33.8882) rotate(-24.7319)"
								></rect>
							</clipPath>
						</defs>
					</svg>
				</div>
				<div className="absolute lg:block hidden -left-[20%] bottom-0">
					<svg
						width="108"
						height="108"
						viewBox="0 0 108 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_2_811)">
							<path
								d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
								fill="#FFEFE5"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_2_811">
								<rect
									width="81"
									height="81"
									fill="white"
									transform="translate(0 33.8882) rotate(-24.7319)"
								></rect>
							</clipPath>
						</defs>
					</svg>
				</div>
				<div className="absolute lg:block hidden -right-[10%] bottom-1/2">
					<svg
						width="108"
						height="108"
						viewBox="0 0 108 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_2_811)">
							<path
								d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
								fill="#FFEFE5"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_2_811">
								<rect
									width="81"
									height="81"
									fill="white"
									transform="translate(0 33.8882) rotate(-24.7319)"
								></rect>
							</clipPath>
						</defs>
					</svg>
				</div>
				<div className="absolute lg:block hidden right-[10%] -bottom-32">
					<svg
						width="108"
						height="108"
						viewBox="0 0 108 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_2_811)">
							<path
								d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
								fill="#FFEFE5"
							></path>
						</g>
						<defs>
							<clipPath id="clip0_2_811">
								<rect
									width="81"
									height="81"
									fill="white"
									transform="translate(0 33.8882) rotate(-24.7319)"
								></rect>
							</clipPath>
						</defs>
					</svg>
				</div>

				<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-3 lg:gap-y-20 gap-y-10">
					<Query query={our_trainers}>
						{({ loading, error, data }) => {
							if (loading) {
								return (
                                    <div className="lg:col-span-3 md:col-span-2 col-span-1"><Loader /></div>
                                )
							}
							if (error) {
								return (
									<div>
										<Noconnection />
									</div>
								);
							}
							return (
								<>
									{(data?.alltrainers || [])?.map((trainner, index) => {
										return (
											<div
												key={index}
												className="bg-white space-y-5 rounded-3xl custom-shadow p-8 relative"
											>
												<div className="absolute w-[72px] h-[54px] inset-x-0 -top-8 mx-auto">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="72"
														height="54"
														viewBox="0 0 72 54"
														fill="none"
													>
														<path
															d="M9.55414 27.538C11.9809 24.3363 15.8217 22.909 20.2166 22.909C28.3376 22.909 34.2038 28.4445 34.2038 37.7796C34.2038 46.5553 28.7197 54.0003 17.1975 54.0003C6.61146 54.0003 0 46.3625 0 33.9607C0 17.3735 9.87898 4.35456 27.7452 0.0534811C28.7962 -0.197255 29.9618 0.458516 30.3248 1.50003L30.6879 2.54155C31.051 3.58307 30.4968 4.6053 29.4459 4.9139C17.6561 8.25061 10.5096 15.5027 8.73248 27.2487C8.57962 28.3288 8.88535 28.4252 9.55414 27.538ZM47.3503 27.538C49.7771 24.3363 53.6178 22.909 58.0127 22.909C66.1338 22.909 72 28.4445 72 37.7796C72 46.5553 66.5159 54.0003 54.9936 54.0003C44.4076 54.0003 37.7962 46.3625 37.7962 33.9607C37.7962 17.3735 47.6752 4.35456 65.5605 0.0534811C66.6115 -0.197255 67.7771 0.458516 68.1401 1.50003L68.5032 2.54155C68.8662 3.58307 68.3121 4.6053 67.2611 4.9139C55.4713 8.25061 48.3248 15.5027 46.5478 27.2487C46.3758 28.3288 46.7006 28.4252 47.3503 27.538Z"
															fill="#FF6600"
														/>
													</svg>
												</div>
												<div className="flex flex-col">
													<h1 className="text-3xl Poppins font-normal">
														{trainner.name}
													</h1>
													<p className="Poppins text-lg font-bold text-new-color">
														{trainner.title}
													</p>
												</div>
												<p className="text-new-color text-lg font-normal Poppins">
													{trainner.bio}
												</p>
												{trainner.company && (
													<p className="font-bold text-lg Poppins text-new-color">
														Company: {trainner.company}
													</p>
												)}
											</div>
										);
									})}
								</>
							);
						}}
					</Query>
				</div>
			</div>
			<div className="container mt-28 -mb-36">
				<Knowledgepocket />
			</div>
		</>
	);
};

export default Trainners;
