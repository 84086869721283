import {
  Resourcecard,
  Nocontent,
  Noconnection,
  Blogskeleton,
  SalePopup, BigSaleBanner,
} from "components";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Query } from "@apollo/client/react/components";
import GET_PRODUCTS from "../../graphql/queries/getClasses";
import PROTECTED_PRODUCTS from "../../graphql/queries/getMinimalClasses";
import Auth from "../../Auth";
import { seo } from "helpers/seo";
import { BsDot } from "react-icons/bs";

const auth = new Auth();

class AdditionalResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    seo({
      title: "Prodcuts | Procurement League",
    });
  }

  render() {
    let variables = {};
    let type = "item_based";
    variables.typeInfo = type;
    return (
      <div>
        <div className="container">
          <Query
            query={auth.isAuthenticated() ? GET_PRODUCTS : PROTECTED_PRODUCTS}
            variables={variables}>
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) {
                return (
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-2">
                    <Blogskeleton />
                    <Blogskeleton />
                    <Blogskeleton />
                    <Blogskeleton />
                  </div>
                );
              }
              if (error)
                return (
                  <div>
                    <Noconnection />
                  </div>
                );

              if (data.length < 1) {
                <Nocontent />;
              }
              if (data !== undefined) {
                return (
                  <>
                    <Resourcecard
                      products={
                        auth.isAuthenticated()
                          ? data.courses.data
                          : data.open_courses.data
                      }
                      type={type}
                    />
                    {data && (
                      <div className="flex justify-end mt-4 ">
                        <Link
                          to={{
                            pathname: `/search/${type.toLowerCase()}/all`,
                            state: {
                              type: type,
                            },
                          }}
                          className="flex items-center black Regular">
                          Load more <IoIosArrowForward className="ml-2" />
                        </Link>
                      </div>
                    )}
                  </>
                );
              }
            }}
          </Query>
          <BigSaleBanner />
          {/* <SalePopup from={'product'} /> */}
        </div>
      </div>
    );
  }
}

export default AdditionalResource;
