import {gql} from "@apollo/client";

export default gql`
    query get_banner_voucher{
        get_banner_voucher{
            id
            voucher
            discount
            voucher_id
            plan_id
            plan{
                id
                validity
                price
            }
        }
    }
`;
