import { gql } from "@apollo/client";

export default gql`
    query SgUser($id: ID!) {
  sgUser(id: $id) {
    id
    firstname
    lastname
    username
    tagline
    is_seller
    email
    plan_id
    product_count
    profile_photo
    categories {
      id
      name
    }
    remaining_counts{
        used_products
        total_products
        used_videos
        total_videos
    }
    addresses {
      id
      name
      addressType
      address_1
      address_2
      city
      state_id
      zip_code
      country_code
      phone
      email
      is_primary
    }
    sellerReviews {
      id
      comment
      buyer {
        id
        firstname
        lastname
        username
        profile_photo
      }
    }
    products(first: 1) {
      data {
        id
        name
        slug
      }
      paginatorInfo {
        total
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
}
`;
