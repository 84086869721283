import React, { Component } from "react";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import "./styles.css";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer slick-arrows next w-9 h-9"
      onClick={onClick}>
      <BsChevronRight size={18} className="gray" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer right-12 slick-arrows prev w-9 h-9"
      onClick={onClick}>
      <BsChevronLeft size={18} className="gray" />
    </div>
  );
}

class expertslick extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <div className="relative">
        <div className="container">
          <h4 className="mb-4 text-2xl lg:text-3xl Medium">Experts</h4>
        </div>
        <Slider {...settings} className="important:static">
          <div className="p-2 pt-3 Experts-Slick">
            <div className="overflow-hidden">
              <div className="relative flex items-center justify-center w-full mr-10 overflow-hidden shadow--sm rounded-xl h-96">
                <img
                  alt="Experts"
                  src="https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI5fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  className="object-cover w-full h-full "
                />
                <div className="absolute z-10 flex flex-col items-center w-full h-full transition duration-500 ease-in-out cursor-pointer Experts-overlay justify-evenly">
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    Answer
                  </button>
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    New question
                  </button>
                </div>
              </div>
              <h4 className="mt-3 text-xl text-center Medium">Experts</h4>
            </div>
          </div>

          <div className="p-2 pt-3 Experts-Slick">
            <div className="overflow-hidden">
              <div className="relative flex items-center justify-center w-full mr-10 overflow-hidden shadow--sm rounded-xl h-96">
                <img
                  alt="Experts"
                  src="https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI5fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  className="object-cover w-full h-full "
                />
                <div className="absolute z-10 flex flex-col items-center w-full h-full transition duration-500 ease-in-out cursor-pointer Experts-overlay justify-evenly">
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    Answer
                  </button>
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    New question
                  </button>
                </div>
              </div>
              <h4 className="mt-3 text-xl text-center Medium">Experts</h4>
            </div>
          </div>

          <div className="p-2 pt-3 Experts-Slick">
            <div className="overflow-hidden">
              <div className="relative flex items-center justify-center w-full mr-10 overflow-hidden shadow--sm rounded-xl h-96">
                <img
                  alt="Experts"
                  src="https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI5fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  className="object-cover w-full h-full "
                />
                <div className="absolute z-10 flex flex-col items-center w-full h-full transition duration-500 ease-in-out cursor-pointer Experts-overlay justify-evenly">
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    Answer
                  </button>
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    New question
                  </button>
                </div>
              </div>
              <h4 className="mt-3 text-xl text-center Medium">Experts</h4>
            </div>
          </div>

          <div className="p-2 pt-3 Experts-Slick">
            <div className="overflow-hidden">
              <div className="relative flex items-center justify-center w-full mr-10 overflow-hidden shadow--sm rounded-xl h-96">
                <img
                  alt="Experts"
                  src="https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI5fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  className="object-cover w-full h-full "
                />
                <div className="absolute z-10 flex flex-col items-center w-full h-full transition duration-500 ease-in-out cursor-pointer Experts-overlay justify-evenly">
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    Answer
                  </button>
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    New question
                  </button>
                </div>
              </div>
              <h4 className="mt-3 text-xl text-center Medium">Experts</h4>
            </div>
          </div>

          <div className="p-2 pt-3 Experts-Slick">
            <div className="overflow-hidden">
              <div className="relative flex items-center justify-center w-full mr-10 overflow-hidden shadow--sm rounded-xl h-96">
                <img
                  alt="Experts"
                  src="https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI5fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  className="object-cover w-full h-full "
                />
                <div className="absolute z-10 flex flex-col items-center w-full h-full transition duration-500 ease-in-out cursor-pointer Experts-overlay justify-evenly">
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    Answer
                  </button>
                  <button
                    type="button"
                    className="bg-white border-0 shadow-none rounded--xl primary h-11 w-75 Regular hover:primary--text hover:opacity-80">
                    New question
                  </button>
                </div>
              </div>
              <h4 className="mt-3 text-xl text-center Medium">Experts</h4>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default expertslick;
