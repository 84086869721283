import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import Banner from "../../assets/images/industries/bannerbg.webp";
import applogo from "../../assets/images/industries/applogo.webp";
import contactgroup from "../../assets/images/industries/contactgroup.webp";
import plus from "../../assets/images/industries/plus.webp";
import {
    Advisory,
    CaseStudies,
    ContactUsCard,
    ContentHub,
    IndustrySlider,
    TechnologyService,
} from "components";
import SubtractWhite from "../../assets/images/Subtract-white.png";
import { scrollToContent } from "helpers/ScrollToContent";
import { useLocation } from "react-router-dom";

const Industries = () => {
    const [expanded, setExpanded] = React.useState(false);
    const { state } = useLocation();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (state?.caseStudy) {
            scrollToContent('caseStudies', 1500)
        } else {
            window.scrollTo(0, 0);
        }
    }, []);

    const boxShadow = {
        boxShadow: "0 1px 15px 1px rgba(0,0,0,.08), 0 1px 6px rgba(0,0,0,.08)",
    };

    return (
        <div className="pb-28 -mt-[50px]">
            <div className="lg:bg-transparent md:bg-transparent bg-black">
                <div className="lg:hidden md:hidden">
                    <img src={Banner} alt="Banner" />
                </div>
                <div className="lg:h-[90vh] md:h-[100vh] IndustriesBanner relative">
                    <div className="flex relative z-10 flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                        <p className="text-[#FBE4D3] Poppins font-normal">
                            Growth Solutions
                        </p>
                        <div className="w-full max-w-5xl">
                            <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Boost Your Pharma & Biotech
                            </p>
                            <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                Revenue With Data-Powered Solution
                            </p>
                        </div>
                        <p className="white Poppins w-full max-w-3xl font-normal text-lg">
                            We don't just connect you with Pharma buyers; we
                            know the Pharma industry inside and out, and we're
                            here to be your trusted ally.
                        </p>
                        <div className="h-px w-full max-w-3xl bg-white mt-3" />
                        <div className="grid w-full max-w-3xl lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                            <button onClick={() => scrollToContent('contentSection', 1000)} className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                    Discover <BsArrowDown size={23} />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                        <img
                            src={SubtractWhite}
                            alt="Subtract"
                            className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                        />
                    </div>
                </div>
            </div>
            <div className="pt-10 container mx-auto lg:relative">
                <div className="absolute lg:block hidden left-1/4 top-0">
                    <svg
                        width="108"
                        height="108"
                        viewBox="0 0 108 108"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_2_811)">
                            <path
                                d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                fill="#FFEFE5"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2_811">
                                <rect
                                    width="81"
                                    height="81"
                                    fill="white"
                                    transform="translate(0 33.8882) rotate(-24.7319)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="container" id="contentSection">
                <div className="flex justify-center relative items-center flex-col mt-20">
                    <div className="lg:w-60 w-48 h-16">
                        <img
                            src={applogo}
                            className="w-full h-full object-contain"
                            alt="applogo"
                        />
                    </div>
                    <div className="mt-5">
                        <h1 className="capitalize Poppins font-light text-new-color text-center lg:text-5xl text-4xl">
                            Connect with{" "}
                            <strong className="lg:text-5xl font-bold text-new-color text-4xl">
                                5000+
                            </strong>{" "}
                            biotech and pharma <br /> decision-makers for
                            targeted B2B <br /> exposure to the right buyers.
                        </h1>
                        <div className="w-full max-w-4xl flex flex-col justify-center mx-auto mt-10">
                            <h4 className="primary text-3xl font-light Poppins">
                                Connect with Industry Leaders and Decision
                                Makers
                            </h4>
                            <p className="pt-3 font-normal text-lg text-new-color Poppins">
                                Connect with key decision makers and industry
                                leaders in the pharma and biotech sector. Forge
                                valuable partnerships and expand your network to
                                drive growth and innovation.
                            </p>
                            <p className="font-bold text-new-color mt-3 text-lg Poppins">
                                Explore our comprehensive lists of contacts
                                across various hierarchies within the pharma and
                                biotech sectors.
                            </p>
                            <div>
                                <button
                                    onClick={() => scrollToContent('caseStudies', 1500)}
                                    className="mt-5 bg--primary font-normal text-white flex items-center justify-between px-4 Poppins text-[20px] hover:bg--primary gap-3 hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12"
                                >
                                    <span>View Case Studies </span>
                                    <BsArrowDown color="white" size={22} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-80 h-80 absolute -left-[100px] bottom-0 lg:block hidden">
                        <img src={plus} alt="plus" />
                    </div>
                </div>
            </div>
            <div className="bg--lightBlue py-10 mt-44 relative">
                <div className="w-full max-w-7xl mx-auto -mt-40">
                    <div className="industies-biotech lg:h-[300px]">
                        <div className="h-full bg-white/10 w-full lg:px-20 py-10 px-10 flex flex-col gap-5 justify-center">
                            <p className="text-center Poppins text-3xl white font-light">
                                "8 out of 10 Biotech & Pharma Lifesciences
                                Industries Suppliers are <br /> currently
                                missing out on effective marketing strategies
                            </p>
                            <p className="text-center white w-full max-w-4xl font-normal Poppins text-lg mx-auto">
                                It's time to step into the spotlight and seize
                                your opportunity to be among the exclusive 20%
                                that are revolutionizing their approach. With
                                our transformative solutions, you can break free
                                from the norm, outshine the competition, and
                                position yourself as a trailblazer in the
                                industry.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-5xl lg:mt-20 mt-10 mx-auto space-y-8">
                    <h1 className="text-center Poppins lg:text-5xl text-4xl font-light">
                        Our Exclusive Pharma Solutions
                    </h1>
                    <p className="text-center max-lg:px-8 text-3xl Poppins font-light text-new-color">
                        Struggling with limited time and specialized skills for
                        your Biotech or Pharma content marketing? We understand
                        the unique challenges you face in establishing a strong
                        digital presence.
                    </p>
                    <p className="text-center max-lg:px-8 text-lg Poppins font-light text-new-color">
                        That's where we come in. We do research and craft
                        engaging content tailored to your industry. Join us to
                        enhance your marketing efforts and succeed without the
                        need for extra resources. Elevate your marketing
                        effortlessly with us - no extra resources required!
                    </p>
                </div>
            </div>
            <div
                className="container mt-20 lg:space-y-20 space-y-10"
                id="scrollToDiscover"
            >
                <ContentHub
                    boxShadow={boxShadow}
                    expanded={expanded}
                    handleChange={handleChange}
                />
                <Advisory
                    boxShadow={boxShadow}
                    expanded={expanded}
                    handleChange={handleChange}
                />
            </div>
            <div
                className="py-10 mt-20 bg-[#233862] relative"
                id="caseStudies"
            >
                <div className="absolute bottom-[17%] right-0 lg:block hidden">
                    <svg
                        width="170"
                        height="488"
                        viewBox="0 0 339 488"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M487 244.388C487 378.869 377.981 487.888 243.5 487.888C109.019 487.888 0 378.869 0 244.388C0 109.906 109.019 0.887695 243.5 0.887695C377.981 0.887695 487 109.906 487 244.388Z"
                            fill="#FFFAEF"
                            fillOpacity="0.1"
                        />
                    </svg>
                </div>
                <div className="absolute -top-44 left-[15%] lg:block hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="268"
                        height="268"
                        viewBox="0 0 268 268"
                        fill="none"
                    >
                        <path
                            d="M268 134C268 208.006 208.006 268 134 268C59.9938 268 0 208.006 0 134C0 59.9938 59.9938 0 134 0C208.006 0 268 59.9938 268 134Z"
                            fill="#FFFAEF"
                            fillOpacity="0.1"
                        />
                    </svg>
                </div>
                <CaseStudies />
            </div>
            <div className="mt-20 container">
                <TechnologyService />
            </div>
            <div className="mt-20 container">
                <div className="grid grid-cols-12 gap-8">
                    <div
                        style={boxShadow}
                        className="flex justify-center items-center flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-10"
                    >
                        <h1 className="p-0 m-0 success-new Poppins font-semibold lg:text-[100px] text-[70px]">
                            2x
                        </h1>
                        <div className="flex-grow flex flex-col justify-start items-center">
                            <p className="text-3xl Poppins font-normal text-new-color">
                                Increase
                            </p>
                            <p className="my-2 text-lg Poppins font-normal text-center text-new-color">
                                in email open rate
                            </p>
                        </div>
                    </div>
                    <div
                        style={boxShadow}
                        className="flex justify-center items-center flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-10"
                    >
                        <h1 className="p-0 m-0 success-new Poppins font-semibold lg:text-[100px] text-[70px]">
                            3x
                        </h1>
                        <div className="flex-grow flex flex-col justify-start items-center">
                            <p className="text-3xl Poppins font-normal text-new-color">
                                Increase
                            </p>
                            <p className="my-2 text-lg Poppins font-normal text-center text-new-color">
                                in organic website traffic/LinkedIn Followers
                            </p>
                        </div>
                    </div>
                    <div
                        style={boxShadow}
                        className="flex justify-center items-center flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-10"
                    >
                        <h1 className="p-0 m-0 success-new Poppins font-semibold lg:text-[100px] text-[70px]">
                            2x
                        </h1>
                        <div className="flex-grow flex flex-col justify-start items-center">
                            <p className="text-3xl Poppins font-normal text-new-color">
                                Increase
                            </p>
                            <p className="my-2 text-lg Poppins font-normal text-center text-new-color">
                                in brand visibility
                            </p>
                        </div>
                    </div>
                    <div
                        style={boxShadow}
                        className="flex justify-center items-center flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-10"
                    >
                        <h1 className="text-3xl font-light text-new-color Poppins text-center my-2">
                            Enhanced Networking Opportunities
                        </h1>
                    </div>
                </div>
            </div>
            <div className="pt-10 mt-20 bg--lightBlue">
                <h1 className="text-center lg:text-5xl text-4xl Poppins font-light text-new-color pb-3">
                    Procurement League’s Potential
                </h1>
                <div className="container">
                    <div className="lg:w-1/3 lg:h-1/4 md:w-1/2 md:h-1/2 mx-auto pt-5">
                        <img
                            src={contactgroup}
                            alt="contactgroup"
                            className="w-full h-full"
                        />
                    </div>
                    <div className="flex lg:flex-row md:flex-row flex-col gap-10 justify-center mt-10">
                        <div className="text-center">
                            <h6 className="pb-3 font-light Poppins text-3xl text-new-color">
                                Without Us
                            </h6>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Networking Challenges,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Visibility Gap,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Limited Market Reach,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Growth Limitations
                            </p>
                        </div>
                        <div className="text-center">
                            <h6 className="pb-3 font-light Poppins text-3xl text-new-color">
                                With Us
                            </h6>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Pharma and Biotech Advancement,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Exclusive Market Insights,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Elevated Industry Presence,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Customized Solutions,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Cost-Efficient Solutions,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Wider Audience Reach,
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Community Building
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Time-saving Research Solutions
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Exclusive Insights with Unique Data
                            </p>
                            <p className="text-lg Poppins text-new-color font-normal pb-3">
                                Unparalleled Global Coverage
                            </p>
                        </div>
                    </div>

                    <IndustrySlider />
                    <ContactUsCard type={"pharma"} />
                </div>
            </div>
        </div>
    );
};

export default Industries;
