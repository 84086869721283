import {
    BsBookmark,
    BsBookmarkFill,
    BsShare,
    BsSuitHeart,
    BsSuitHeartFill,
    BsThreeDotsVertical,
    BsThreeDots,
} from "react-icons/bs";
import {
    Signinpopup
} from "components";
import {Link} from "react-router-dom";
import {Menu, Transition} from "@headlessui/react";
import {Fragment, useState} from "react";
import {FiEdit} from "react-icons/fi";
import {AiOutlineDelete} from "react-icons/ai";
import encryptfunction from "../../helpers/Encrypt";
import convertToSlug from "../../helpers/convertToSlug";
import {gql, useMutation} from "@apollo/client";
import DELETE_PRODUCT from "../../graphql/mutations/deleteProduct";
import ACTION_PRODUCT from "../../graphql/SgQueries/SgProductActionsMutation";
import Auth from "../../Auth";
import React from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { FaHeart, FaRegHeart } from "react-icons/fa";

const auth = new Auth();

export default function Index(props) {
    const auth = new Auth();
    const [open, setOpen] = useState(false);
    const openSignIn = React.createRef();
    const ProductEdit = (product) => {
        // console.log(product);
        props.EditProduct(product)
    };

    const onClose = () => {
        setOpen(false);
    };
    const onImageDelete = (id) => {
        if (id) {
            let ImageDataObject = new FormData();
            let data = {};
            ImageDataObject.append("id", id);
            axios
                .post(`${process.env.REACT_APP_MRO_URL}/api/product/media/delete`, ImageDataObject)
                .then((res) => {
                    // console.log("res delete image", res);
                    if (res.data.success) {
                        data.id = parseInt(res.data.id);
                        ProductDelete({variables: data});
                    } else {
                        console.log("Image is not deleted");
                    }
                })
                .catch((error) => {
                    // return notify(error, "error");
                    console.log("image error", error);
                });
        }
        setOpen(false);
    };
    const deleteUpdate = (cache, {data}) => {
        const removeProduct = cache.identify(data.deleteProduct);
        cache.modify({
            fields: {
                SgProduct: (existingSgProductRefs) => {
                    // console.log("existingSgProductRefs", existingSgProductRefs);
                    return existingSgProductRefs?.data?.filter((productRef) => {
                        return cache.identify(productRef) !== removeProduct;
                    });
                },
            },
        });
        cache.evict({id: removeProduct});
    };
    const [ProductDelete] = useMutation(DELETE_PRODUCT, {
        update: deleteUpdate,
        onCompleted(data) {
            // console.log("useMutation", data);
            props.callback('deletedPro');
            /* const updatedList = [...deletedPro];
                  updatedList.push(parseInt(data.deleteProduct.id));
                  setdeletedPro(updatedList)
                  setTimeout(() => {
                      props.callback(deletedPro)
                  })*/
        },
    });
    const updateCache = (cache, {data: ProductAction}) => {
        // const existingData = cache.readQuery({
        //   query: GET_PRODUCT_BY_ID,
        //   variables: { id: props.product.id },
        // });
        // console.log("existingData", existingData);
        console.log("data", ProductAction);
        cache.writeQuery({
            query: gql`
                query sgProductByID($id: ID!) {
                    sgProductByID(id: $id) {
                        id
                        product_actions {
                            id
                            liked
                            user_id
                            product_like
                            book_mark
                            visit
                            product_id
                            created_at
                        }
                    }
                }
            `,
            data: {
                sgProductByID: {
                    __typename: "sgProductByID",
                    id: props.product.id,
                    product_actions: ProductAction.productActions,
                },
            },
            variables: {
                id: props.product.id,
            },
        });
        /*  const newData = data.productActions;
            cache.writeQuery({
                query: GET_PRODUCT_BY_ID,
                variables: {id: props?.id},
                data: {sgProductByID: {productActions: [newData, ...existingData.sgProductByID.productActions]}}
            });*/
    };
    const [ProductAction] = useMutation(ACTION_PRODUCT, {
        update: updateCache,
        onCompleted(data) {
            // console.log("useMutation", data);
            // setTimeout(()=>{
            //   props.callback(deletedPro)
            // })
        },
    });
    return (
        <>
            <div className="p-10 shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl" key={props?.product?.id}>
                <Link
                    to={{
                        pathname: `/seller/${encryptfunction(
                            props?.product?.id
                        )}/product/${convertToSlug(props?.product?.name ?? "none")}`,
                        state: {product: props.product},
                    }}
                    onClick={() =>
                        ProductAction({
                            variables: {
                                id: props.product?.product_actions[0]?.id ?? null,
                                product_id: props?.product?.id,
                                visit: true,
                            },
                        })
                    }
                    className="block"
                >
                    {props?.product.type === "mp4" ||
                    props?.product.type === "mov" ||
                    props?.product.type === "webm" ||
                    props?.product.type === "avi" ||
                    props?.product.type === "mpeg4" ? (
                        <video
                            width="750"
                            height="700"
                            className="object-cover h-[250px]  w-full "
                            controls
                            controlsList="nodownload"
                        >
                            <source
                                src={process.env.REACT_APP_MRO_URL + "/" + props?.product.attachment}
                                type={"video/" + props?.product.type}
                            />
                        </video>
                    ) : (
                        <div>
                            <img
                                alt="Knowledge"
                                src={process.env.REACT_APP_MRO_URL + "/" + props?.product.attachment}
                                className="w-full mx-auto h-[250px] object-cover"
                            />
                        </div>
                    )}

                    <div className="pt-3 h-[70px]">
                        <h4 className="mr-auto text-lg capitalize Poppins text-new-color text-ellipsis overflow-hidden whitespace-nowrap block">
                            {props?.product.name}
                        </h4>
                        <p className="text-sm text-new-color custom-truncate">
                            {props?.product.description}
                        </p>
                    </div>
                </Link>
                <div>
                    <div className="flex items-center justify-between mt-3">
                        <div className="flex items-center cursor-pointer gap-x-1 ">
                            {auth.isAuthenticated() && props.product?.product_actions ?
                                (
                                    props.product?.product_actions
                                        .map((action) => {
                                            return action.liked ? (
                                                <FaHeart 
                                                    key={action.id}
                                                    className="primary"
                                                    size={20}
                                                    onClick={() => {
                                                        ProductAction({
                                                            variables: {
                                                                id: action.id,
                                                                product_id: props?.product?.id,
                                                                liked: false,
                                                            },
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <FaRegHeart
                                                    key={action.id}
                                                    className="primary"
                                                    size={20}
                                                    onClick={() => {
                                                        ProductAction({
                                                            variables: {
                                                                id: action.id,
                                                                product_id: props?.product?.id,
                                                                liked: true,
                                                            },
                                                        });
                                                    }}
                                                />
                                            );
                                        })
                                ) : (
                                    <FaRegHeart
                                        className="primary"
                                        size={17}
                                        onClick={() => openSignIn.current.openPopup()}
                                    />
                                )}
                            <p className="text-sm primary">
                                {props.product?.product_actions[0]?.product_like ?? 0}
                            </p>
                        </div>
                        <div className="flex items-center justify-center cursor-pointer gap-x-1 ">
                            <BsShare
                                size={20}
                                className="primary"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${process.env.REACT_APP_WEB_URL}/seller/${encryptfunction(
                                            props.product.id
                                        )}/product/${convertToSlug(props?.product?.name ?? "none")}`
                                    );
                                    props.alert("Link Copied");
                                }}
                            />
                        </div>
                        <div className="flex items-center justify-center cursor-pointer gap-x-1 ">
                            {auth.isAuthenticated() && props.product?.product_actions ? (
                                props.product?.product_actions
                                    .map((action) => {
                                        return action.book_mark ? (
                                            <BsBookmarkFill
                                                className="primary"
                                                size={20}
                                                onClick={() =>
                                                    ProductAction({
                                                        variables: {
                                                            id: action.id,
                                                            product_id: props?.product?.id,
                                                            book_mark: false,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            <BsBookmark
                                                className="primary"
                                                size={20}
                                                onClick={() =>
                                                    ProductAction({
                                                        variables: {
                                                            id: action?.id,
                                                            product_id: props?.product?.id,
                                                            book_mark: true,
                                                        },
                                                    })
                                                }
                                            />
                                        );
                                    })
                            ) : (
                                <BsBookmark
                                    className="primary"
                                    size={17}
                                    onClick={() => openSignIn.current.openPopup()}
                                />
                            )}
                        </div>

                        {auth.isAuthenticated() &&
                            props.authUser?.email === props.user?.email && (
                                <div className="flex items-center justify-end cursor-pointer gap-x-1 ">
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button
                                                className="inline-flex justify-center w-full px-2 py-2 text-sm font-medium text-white rounded-full bg-gray-50 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                <BsThreeDotsVertical
                                                    className="w-5 h-5 primary rounded-full"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="z-[1] absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="px-2 py-2">
                                                    <Menu.Item>
                                                        <button
                                                            onClick={() => props.localState.edit ? props.callback('Please Update Previous Selected Product First') : ProductEdit(props.product)}
                                                            className="flex items-center w-full px-2 py-3 text-sm text-black rounded-md group hover:bg-gray-100 Regular gap-x-2">
                                                            <FiEdit size={17} aria-hidden="true"/>
                                                            Edit
                                                        </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button
                                                            onClick={() => setOpen(true)}
                                                            className="flex items-center w-full px-2 py-3 text-sm text-black rounded-md group hover:bg-gray-100 Regular gap-x-2"
                                                        >
                                                            <AiOutlineDelete size={17} aria-hidden="true"/>
                                                            Delete
                                                        </button>
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
                <div
                    className="flex items-center justify-center px-5 py-3 bg-gray-200 border-b rounded-t-lg border-gray-200">
                    <h4 className="text-lg Medium ">Do you want to delete this?</h4>
                </div>

                <div className={"px-8 py-5 "}>
                    <div className="flex items-center gap-8 justify-center">
                        <div>
                            <button
                                className="flex items-center justify-center w-[150px] mx-auto my-6 border-0 rounded-lg shadow-none bg--primary white hover:white hover:opacity-70 hover:bg--primary focus:bg--primary h-11 Regular"
                                onClick={() => onImageDelete(props.product.id)}
                            >
                                Confirm
                            </button>
                        </div>
                        <div onClick={onClose}>
                            <button
                                className="bg-gray-200 flex items-center justify-center w-[150px] mx-auto my-6 border-0 rounded-lg shadow-none h-11 Regular hover:opacity-70 ">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Signinpopup ref={openSignIn} protect={true}/>
        </>
    );
}
