import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

export default function Socialshare(props) {
  return (
    <div className="flex items-center justify-evenly gap-x-3">
      <EmailShareButton
        subject={props.subject}
        url={props.url}
        //   body={props.url}
      >
        <EmailIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </EmailShareButton>
      <FacebookShareButton
        quote={props.title}
        hashtag={props.hashtag}
        url={props.url}>
        <FacebookIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </FacebookShareButton>
      <WhatsappShareButton
        title={props.title}
        // separator={""}
        url={props.url}>
        <WhatsappIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </WhatsappShareButton>
      <TwitterShareButton
        title={props.title}
        via={props.via}
        hashtags={props.hashtags}
        url={props.url}>
        <svg {...props} width={props.size} height={props.size} className={`${props.className} text-white bg-black rounded-full p-1`} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
      </TwitterShareButton>

      <LinkedinShareButton
        title={props.title}
        // summary={props.summary}
        source={props.source}
        url={props.url}>
        <LinkedinIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </LinkedinShareButton>
    </div>
  );
}
