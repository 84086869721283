import React, { useEffect } from "react";
import { BsArrowDown } from "react-icons/bs";
import Subtract from "assets/images/Subtract-white.png";
import { IoMdCheckmark } from "react-icons/io";
import core from "assets/images/aboutus/core.png";
import transform from "assets/images/aboutus/transform.png";
import illustration from "assets/images/aboutus/illustration.png";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import AboutUsSlider from "./Slider";
import { scrollToContent } from "helpers/ScrollToContent";
import MobileBanner from "assets/images/aboutus/aboutus.webp"

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="lg:bg-transparent md:bg-transparent -mt-[50px] bg-black">
                <div className="lg:hidden md:hidden relative">
                    <img src={MobileBanner} alt="banner" className="object-cover w-full h-full object-center" />
                </div>
                <div className="lg:h-[90vh] md:h-[100vh] AboutUsBanner relative">
                    <div className="flex w-full max-w-4xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                        <p className="text-[#FBE4D3] Poppins font-normal">
                            About Us
                        </p>
                        <p className="white capitalize font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                            Welcome to Procurement League - where better
                            marketing is just the beginning!
                        </p>
                        <p className="white Poppins font-normal">
                            Platform where buyers meet sellers
                        </p>
                        <p className="white Poppins font-normal">
                            Think of us as your partner, here to make things
                            better and simpler for you. At Procurement League,
                            we are redefining the landscape of business
                            marketing, leading the charge in introducing
                            innovative methods across various industries.
                        </p>
                        <div className="h-px bg-white mt-3" />
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                            <button
                                onClick={() =>
                                    scrollToContent('contentSection', 1000)
                                }
                                className="group px-4 relative z-[9] hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 !font-normal Poppins rounded-full overflow-hidden bg--primary"
                            >
                                <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                    Discover <BsArrowDown size={23} />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="-bottom-px lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                        <img
                            src={Subtract}
                            alt="Subtract"
                            className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                        />
                    </div>
                </div>
            </div>
            <div
                id={'contentSection'}
                className="mt-28 container space-y-20 relative"
            >
                <div class="absolute lg:block hidden left-5 -top-16">
                    <svg
                        width="39"
                        height="39"
                        viewBox="0 0 39 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="19.5"
                            cy="19.5"
                            r="19.5"
                            fill="#B5E2FF"
                        ></circle>
                    </svg>
                </div>
                <div class="absolute lg:block hidden right-0 top-0">
                    <img
                        src={illustration}
                        alt="illustration"
                        className="w-full max-w-lg"
                    />
                </div>
                <div className="max-w-3xl w-full space-y-3">
                    <p className="text-3xl Poppins primary font-normal">
                        Who We Are
                    </p>
                    <p className="text-lg Poppins text-new-color font-normal">
                        <span className="text-lg Poppins text-new-color font-bold">Procurement League</span> stands out with its holistic
                        approach, seamlessly integrating expert content
                        creation, insightful guidance, and cutting-edge
                        community technology. From crafting compelling content
                        to fostering valuable connections and facilitating
                        business interactions, we cover the entire spectrum.
                    </p>
                    <p className="text-lg Poppins text-new-color font-normal">
                        <span className="text-lg Poppins text-new-color font-bold">Our mission</span> is to collaborate with you on your journey
                        towards transformative marketing experiences, all while
                        staying connected to the pulse of industry knowledge and
                        community participation.
                    </p>
                    <p className="text-lg Poppins text-new-color font-normal">
                        We also understand the intricacies of the <span className="text-lg Poppins text-new-color font-bold">Pharma sector </span>
                        and position ourselves as your trusted ally. Whether
                        you're facing challenges in biotech or Pharma content
                        marketing, we have the knowledge and skills to help you
                        establish a robust digital presence.
                    </p>
                </div>
                <div className="max-w-4xl w-full ml-auto relative">
                    <div class="absolute lg:block hidden left-0 -top-16">
                        <svg
                            width="39"
                            height="39"
                            viewBox="0 0 39 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="19.5"
                                cy="19.5"
                                r="19.5"
                                fill="#B5E2FF"
                            ></circle>
                        </svg>
                    </div>
                    <p className="text-3xl Poppins primary font-normal">
                        Why Opt for Our Expertise?
                    </p>
                    <ul className="space-y-3 !pt-3">
                        <li className="Poppins !text-lg text-new-color">
                            <span className="text-lg Poppins text-new-color font-bold">Industry-Tailored Expertise:</span> Benefit from our
                            specialized knowledge, tailored specifically to
                            address the unique challenges of your industry.
                        </li>
                        <li className="Poppins !text-lg text-new-color">
                            <span className="text-lg Poppins text-new-color font-bold">Timely Solutions, No Extra Resources:</span> Procurement
                            League delivers efficient solutions without
                            stretching your team thin.
                        </li>
                        <li className="Poppins !text-lg text-new-color">
                            <span className="text-lg Poppins text-new-color font-bold">Compelling, Research-Driven Content:</span> Our team
                            conducts thorough research to produce engaging,
                            industry-relevant content that resonates with your
                            audience.
                        </li>
                        <li className="Poppins !text-lg text-new-color">
                            <span className="text-lg Poppins text-new-color font-bold">Partners in Your Success:</span> Think of us as an
                            extension of your team.
                        </li>
                        <li className="Poppins !text-lg text-new-color">
                            <span className="text-lg Poppins text-new-color font-bold">Effortless Marketing Elevation:</span> Elevate your
                            marketing effortlessly with Procurement League.
                        </li>
                    </ul>
                </div>
                <div class="absolute lg:block hidden left-0 bottom-0">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="332"
                        height="332"
                        viewBox="0 0 332 332"
                        fill="none"
                    >
                        <path
                            d="M138.077 35.7591L130.434 123.939L42.2537 116.296C20.2087 114.385 2.24179 129.486 0.330993 151.531C-1.57981 173.576 13.5209 191.543 35.5659 193.453L123.746 201.097L116.103 289.277C114.192 311.322 129.293 329.289 151.338 331.199C173.383 333.11 191.349 318.01 193.26 295.965L200.903 207.784L289.084 215.428C311.129 217.338 329.095 202.238 331.006 180.193C332.917 158.148 317.816 140.181 295.771 138.27L207.591 130.627L215.234 42.4469C217.145 20.4018 202.045 2.435 180 0.524206C157.955 -1.38659 139.988 13.714 138.077 35.7591Z"
                            fill="#B5E2FF"
                            fillOpacity="0.7"
                        ></path>
                    </svg>
                </div>
            </div>
            <div className="mt-28 container">
                <div className="bg-[#233862] lg:h-[338px] lg:px-14 p-5 gap-3 flex lg:flex-nowrap flex-wrap justify-center items-center">
                    <img
                        src={core}
                        alt="core"
                        className="w-[225px] h-[217px]"
                    />
                    <div>
                        <p className="lg:text-5xl text-4xl pb-3 Poppins font-light text-white">
                            Our Core Values
                        </p>
                        <p className="text-white w-full max-w-5xl mx-auto text-lg">
                            At the heart of the Procurement League are our core
                            values. We are committed to integrity, innovation,
                            collaboration, and excellence. These values guide us
                            in every endeavor, ensuring that our clients receive
                            outstanding services and a partnership built on
                            trust and shared success.
                        </p>
                    </div>
                </div>
                <div className="mt-28 -mb-36">
                    <AboutUsSlider />
                </div>
            </div>
            <div className="bg--lightBlue">
                <div className="pt-56 container">
                    <h1 className="lg:text-5xl text-4xl Poppins font-light">
                        Life At Procurement League
                    </h1>
                    <div className="bg-white relative rounded-2xl p-5 mt-5 shadow-[0px_4px_20px_0px_#0000001A]">
                        <div className="divide-y">
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Diversity and Inclusion:
                                    </span>{" "}
                                    Join us in celebrating a culture that
                                    thrives on diverse perspectives. At
                                    Procurement League, we believe every team
                                    member's unique strengths contribute to our
                                    collective success.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Equal Opportunities:
                                    </span>{" "}
                                    Here, everyone—regardless of background or
                                    role—has the chance to shine. Empowerment is
                                    our jam, ensuring diverse talents contribute
                                    to our success.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Employee Development:
                                    </span>{" "}
                                    We're all about growth—professionally and
                                    personally. From top-notch training to
                                    wellness initiatives, we invest in creating
                                    a team that thrives.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Innovative Environment:
                                    </span>{" "}
                                    Step into a space where creativity and
                                    out-of-the-box thinking reign supreme. It's
                                    where ideas flourish, and collaboration
                                    knows no bounds.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Flexibility for Balance:
                                    </span>{" "}
                                    We get it—life is busy. That's why we value
                                    work-life balance with flexible policies,
                                    empowering you to manage both work and play
                                    effectively.
                                </p>
                            </div>
                            <div className="flex p-3 items-start gap-4 w-full">
                                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center border-2 border-[#B5E2FF]">
                                    <IoMdCheckmark color="#B5E2FF" size={24} />
                                </div>
                                <p className="text-lg Poppins">
                                    <span className="Poppins font-bold">
                                        Open & Transparent Communication Policy:
                                    </span>{" "}
                                    Our doors are wide open! We believe in open
                                    and transparent communication, a policy that
                                    leads to successful employer engagement
                                    tactics.
                                </p>
                            </div>
                        </div>
                        <div className="absolute -left-44 bottom-20  lg:block hidden">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="214"
                                height="214"
                                viewBox="0 0 214 214"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_820_3467)">
                                    <path
                                        d="M90.3545 29.8741L85.8218 82.1677L33.5282 77.6351C20.4548 76.5019 9.79988 85.4571 8.66671 98.5305C7.53355 111.604 16.4887 122.259 29.5621 123.392L81.8558 127.925L77.3231 180.218C76.1899 193.292 85.1451 203.947 98.2185 205.08C111.292 206.213 121.947 197.258 123.08 184.184L127.613 131.891L179.906 136.423C192.98 137.557 203.635 128.601 204.768 115.528C205.901 102.455 196.946 91.7997 183.872 90.6665L131.579 86.1338L136.111 33.8402C137.245 20.7668 128.289 10.1119 115.216 8.97869C102.143 7.84552 91.4877 16.8007 90.3545 29.8741Z"
                                        fill="#B5E2FF"
                                        fillOpacity="0.4"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_820_3467">
                                        <rect
                                            width="196.836"
                                            height="196.836"
                                            fill="white"
                                            transform="translate(0.167969 196.581) rotate(-85.0461)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="h-screen aboutus xl:h-[1000px] lg:h-[600px] mt-28">
                    <div className="flex justify-end items-center h-[calc(100%-150px)] flex-col px-5">
                        <div className="mb-4">
                            <svg
                                width="110"
                                height="83"
                                viewBox="0 0 110 83"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.5966 42.3267C18.3041 37.4056 24.172 35.2118 30.8864 35.2118C43.2935 35.2118 52.2558 43.72 52.2558 58.0683C52.2558 71.5569 43.8774 83 26.2739 83C10.1008 83 0 71.2605 0 52.1986C0 26.7036 15.0929 6.6931 42.3885 0.082202C43.9942 -0.303186 45.7749 0.704753 46.3296 2.3056L46.8843 3.90644C47.439 5.50728 46.5924 7.07848 44.9867 7.55281C26.9745 12.6814 16.0563 23.8281 13.3413 41.882C13.1077 43.5422 13.5748 43.6904 14.5966 42.3267ZM72.3408 42.3267C76.0483 37.4056 81.9161 35.2118 88.6306 35.2118C101.038 35.2118 110 43.72 110 58.0683C110 71.5569 101.622 83 84.018 83C67.845 83 57.7442 71.2605 57.7442 52.1986C57.7442 26.7036 72.8371 6.6931 100.162 0.082202C101.768 -0.303186 103.548 0.704753 104.103 2.3056L104.658 3.90644C105.212 5.50728 104.366 7.07848 102.76 7.55281C84.7479 12.6814 73.8296 23.8281 71.1146 41.882C70.8519 43.5422 71.3482 43.6904 72.3408 42.3267Z"
                                    fill="#FF6600"
                                />
                            </svg>
                        </div>
                        <h1 className="lg:text-[50px] text-[36px] lg:leading-[60px] mb-7 leading-10 font-light Poppins text-black">
                            What Employees Have To Say
                        </h1>
                        <p className="text-[#16283A] w-full max-w-4xl text-center mx-auto mb-5 Poppins font-normal text-lg">
                            “We completed our IT and Telecom sourcing projects,
                            and contract renewals on time without having to hire
                            a permanent FTE”
                        </p>
                        <p className="font-bold text-lg Poppins text-black">
                            Chief Procurement Officer Global Financial Leader,
                            US
                        </p>
                    </div>
                </div>
                <div className="container mt-28 md:-mb-44 -mb-36">
                    <div
                        className={`knowledge-pocket md:h-[400px] h-[350px] w-full relative`}
                    >
                        <img
                            src={transform}
                            alt="transform"
                            className="w-full h-full md:object-contain object-cover"
                        />
                        <div className="absolute w-full h-full inset-0">
                            <div className="pr-10 lg:pl-20 md:pl-20 pl-10 grid h-full grid-cols-12 gap-4">
                                <div className="col-span-12 space-y-4 h-full flex justify-center flex-col">
                                    <p className="white Poppins lg:text-6xl md:text-4xl text-2xl font-extralight lg:text-left md:text-left text-center">
                                        Let's Transform Together
                                    </p>
                                    <Link
                                        to={"/contact-us"}
                                        className="py-2 text-xl border-2 font-bold md:self-start Lato !hover:text-white !text-white px-5 border-white rounded-full flex justify-between items-center w-60"
                                    >
                                        Contact Us <BsArrowRight size={24} />{" "}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
