import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BsPlusLg } from "react-icons/bs";

export default function ProcureFaq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ProcureFaq = [
        {
            id: "panel1",
            title: "What are the advantages of online learning at ProcureClass?",
            description: `ProcureClass is an online learning platform of the future that
            blends time-tested pedagogy with innovative training experiences
            and executive content tailored to the busy lives of procurement
            professionals on the go. With academically robust and
            application-oriented frameworks, learners get a chance to build
            skills to help them navigate their first job or the most crucial
            career cusps. Our unique curriculum allows learners to build
            skills to succeed at every stage of their careers. Our
            development programs majorly target different career stages. It
            includes interactive elements like hands-on-tool workshops,
            industry mentorship, and practice exercises to apply course
            concepts.`,
        },
        {
            id: "panel2",
            title: "How does ProcureClass customize its programs to specific company needs?",
            description: `The ProcureClass is known for outcome-based
            online learning. At the beginning of the
            program, we benchmark learner skills to set the
            baseline. The benchmarking exercise considers a
            learner's specific context and workplace
            environment. The benchmark helps us map a
            learner's/team's progress throughout the
            program.`,
        },
        {
            id: "panel3",
            title: "How are ProcureClass's programs relevant for my organization or team's learning needs?",
            description: `ProcureClass's distinctive flagship programs are
            designed and tailored for first-time procurement
            professionals, high-performing early managers,
            women leaders, and more. Our unique program
            approach begins by benchmarking procurement
            skills to set a baseline, identify the correct
            program elements, and map progress throughout
            learning. Each of our programs is mapped to a
            specific set of Procurement Skills and the
            crucial learning outcomes that learners will
            develop by the end of their learning journey
            with ProcureClass.`,
        },
        {
            id: "panel4",
            title: "My employees have busy work schedules. How will online learning help them, and can the learning time be optimized for their work schedules?",
            description: `Our ProcureClass training programs are designed
            by keeping in mind that Procurement
            Professionals are busy and ambitious. Our online
            training program is self-paced, which can be
            completed at a learner's convenience. You can
            learn online no matter where in the world you
            are. We also provide live learning support on
            Procurement League App to ensure learners can
            ask questions or clarify doubts at any time
            during the learning journey.`,
        },
    ];

    return (
        <div className="bg-white rounded-3xl p-5 shadow-[0px_4px_40px_0px_#0000001A]">
            <p className="Poppins text-lg font-normal text-[#16283A]">Procure Class</p>
            <div className="grid grid-cols-12 place-items-center gap-5">
                <div className="divide-y col-span-12">
                    {ProcureFaq.map((item) => (
                        <div key={item.id}>
                            <Accordion
                                expanded={expanded === item.id}
                                onChange={handleChange(item.id)}
                                className="p-2 m-0 overflow-hidden !shadow-none"
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <BsPlusLg
                                            className="primary"
                                            size={24}
                                        />
                                    }
                                >
                                    <p className="text-lg Poppins leading-6 font-semibold text-new-color">
                                        {item.title}
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="Poppins text-lg text-new-color font-normal">
                                        {item.description}
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
