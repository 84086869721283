import React, { Component } from "react";

class whatget extends Component {
  render() {
    return (
      <div className="relative blog-posts">
        <div className="container">
          <h4 className="mb-10 text-3xl text-center Medium">
            What will you get
          </h4>
        </div>
        <div className="grid gap-4 lg:grid-cols-4 sm:grid-cols-2">
          <div className="overflow-hidden shadow--sm rounded-xl">
            <img
              src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
              className="object-cover object-top w-full mx-auto h-60"
              alt="headline"
            />
            <div className="p-4">
              <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
              <p className="my-3 Regular fs-13">
                Lillevik. Ground Level detailed training which I can correlate
                how.
              </p>
            </div>
          </div>
          <div className="overflow-hidden shadow--sm rounded-xl">
            <img
              src="https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcHV0ZXJ8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
              className="object-cover object-top w-full mx-auto h-60"
              alt="headline"
            />
            <div className="p-4">
              <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
              <p className="my-3 Regular fs-13">
                Lillevik. Ground Level detailed training which I can correlate
                how.
              </p>
            </div>
          </div>
          <div className="overflow-hidden shadow--sm rounded-xl">
            <img
              src="https://images.unsplash.com/photo-1634848507505-0451a2412aa6?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
              className="object-cover object-top w-full mx-auto h-60"
              alt="headline"
            />
            <div className="p-4">
              <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
              <p className="my-3 Regular fs-13">
                Lillevik. Ground Level detailed training which I can correlate
                how.
              </p>
            </div>
          </div>
          <div className="overflow-hidden shadow--sm rounded-xl">
            <img
              src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Y29tcHV0ZXJ8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
              className="object-cover object-top w-full mx-auto h-60"
              alt="headline"
            />
            <div className="p-4">
              <h4 className="mb-1 Medium fs-14 ">Some headline</h4>
              <p className="my-3 Regular fs-13">
                Lillevik. Ground Level detailed training which I can correlate
                how.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default whatget;
